import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-how-to-create-a-private-blockchain',
  templateUrl: './how-to-create-a-private-blockchain.component.html',
  styleUrls: ['./how-to-create-a-private-blockchain.component.css']
})
export class HowToCreateAPrivateBlockchainComponent implements OnInit {
  selectedLink:number;
  title =
    'How to Create a Private Blockchain Development?';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  
  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'How can you create private blockchains  for business? How much does it cost? Here is a handy tutorial to guide you, that will clear your doubts from scratch.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Create Private Blockchain, Private Blockchain, Private blockchain development, private and public blockchain, example of private blockchain',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'How to Create a Private Blockchain Development?',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/how-to-create-a-private-blockchain/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'How can you create private blockchains  for business? How much does it cost? Here is a handy tutorial to guide you, that will clear your doubts from scratch.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/how-to-create-a-private-blockchain/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'How to Create a Private Blockchain Development?',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'How can you create private blockchains  for business? How much does it cost? Here is a handy tutorial to guide you, that will clear your doubts from scratch.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/how-to-create-a-private-blockchain/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/how-to-create-a-private-blockchain.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }
  
}
