<!-- Our Process -->
<div class="our-procee-main">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="commontext_wrap text-center">
          <h2>Our Process <span>Blockchain Development</span></h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 cmb-5">
        <div class="our_process_col_grap">
          <h2>01</h2>
        </div>
        <div class="our_process_text_wrap right-wrap">
          <h3>Defining</h3>
          <p>
            Open-minded discussion on the needs and requirements to jot the plan
            of the product.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 cmb-5">
        <div class="our_process_col_grap">
          <h2>02</h2>
        </div>
        <div class="our_process_text_wrap">
          <h3>Designing</h3>
          <p>
            The most creative part of the deployment need creative inputs, and
            we infuse our unique touch.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 cmb-5">
        <div class="our_process_col_grap">
          <h2>03</h2>
        </div>
        <div class="our_process_text_wrap left-wrap">
          <h3>Development</h3>
          <p>
            Heading to the technical team to fetch the backend process and other
            integrations.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 cmb-5">
        <div class="our_process_col_grap">
          <h2>04</h2>
        </div>
        <div class="our_process_text_wrap right-wrap">
          <h3>Testing</h3>
          <p>
            And passing all the tests and reworks, the product is all set to go
            out in the market.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 cmb-5">
        <div class="our_process_col_grap">
          <h2>05</h2>
        </div>
        <div class="our_process_text_wrap left-wrap">
          <h3>Deployment</h3>
          <p>
            In this stage, we work out the possibilities and their technical
            notions for the better good.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Process End -->
<section class="bx-clients">
  <div class="container">
    <div class="row mb-sm-4 mb-1">
      <div class="col-lg-8 mx-auto">
        <div class="commontext_wrap cmb-50 client-section text-center">
          <h2>BlockchainX Clients</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="item-client">
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/ekta.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/reflex-finance.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/zuki.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/wafercoin.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/polkadog.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>    
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/starcard.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/starcard.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/starcard.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/l1x.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/l1x.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/l1x.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/columbus.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/columbus.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/columbus.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/merchant-token.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/merchant-token.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/merchant-token.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/bounty-temple.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/bounty-temple.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/bounty-temple.png" width="150" height="60" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
        </div>
        <div class="item-client-slide">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/ekta.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
              <!-- <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/ekta.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="100%" height="100%" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div> -->
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/reflex-finance.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
              <!-- <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/reflex-finance.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="100%" height="100%" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div> -->
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/zuki.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
              <!-- <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/zuki.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="100%" height="100%" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div> -->
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/wafercoin.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
              <!-- <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/wafercoin.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="100%" height="100%" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div> -->
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/polkadog.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
              <!-- <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/polkadog.png" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="100%" height="100%" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
              </div> -->
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/starcard.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/starcard.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/starcard.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/l1x.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/l1x.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/l1x.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/columbus.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/columbus.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/columbus.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/merchant-token.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/merchant-token.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/merchant-token.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/clients/bounty-temple.webp" />
                    <source type="image/png" srcset="assets-new/images/home/clients/bounty-temple.png" />
                    <img loading="lazy" data-src="assets-new/images/home/clients/bounty-temple.png" width="130" height="30" class="img-fluid" alt="our clients" title="our clients" />
                  </picture>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="casestudies">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="casetudies_common">
          <div class="commontext_subhead">
            <h3>Case Studies<span class="dots">.</span></h3>
          </div>
          <div class="casestudies_para">
            <p>
              This part holds the legacy of BlockchainX Tech. Establishing all
              that we have worked out for our clients as a Blockchain
              development company is brought down here. This gives an ideology
              of our expertise and could bring your idea to life and hold the
              top priority here.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-7 case-list">
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>EKTA</h3>
                  <div class="case-picture">
                    <img loading="lazy"
                      src="assets-new/images/home/case/ekta-1.png"
                      alt="ekta"
                      class="img-fluid"
                      width="150"
                      height="120"
                      
                    />
                  </div>
                </div>
                <div class="deploy-link">
                  <a href="ektachain" target="_blank"
                rel="noopener noreferrer">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  A decentralized ecosystem built from scratch, along with the
                  ability to perform everything and anything. Being a blockchain
                  itself, it renders and supports DAO, and has its own
                  marketplace and token to interact with. It explores endless
                  possibilities and bridges the physical world and the
                  blockchain era.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Minosis</h3>
                  <div class="case-picture">
                    <img loading="lazy"
                      src="assets-new/images/home/case/minosis.png"
                      alt="Minosis"
                      class="img-fluid p-0"
                      width="150"
                      height="120"
                    />
                  </div>
                </div>

                <div class="deploy-link">
                  <a href="minosis" target="_blank"
                rel="noopener noreferrer">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Minosis is a crypto mining startup that allows users to
                  participate in pools to earn virtual currency. In a unique
                  way, Minosis miners can earn Bitcoin regardless of their mine
                  and even achieve block rewards. Despite simplifying
                  transaction's cost, the payments are accepted as native tokens
                  or Bitcoins.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Zuki</h3>
                  <div class="case-picture">
                    <img loading="lazy"
                      src="assets-new/images/home/case/zuki.png"
                      alt="ZUKI"
                      class="img-fluid p-0"
                      width="150"
                      height="120"
                    />
                  </div>
                </div>

                <div class="deploy-link">
                  <a href="zuki" target="_blank"
                rel="noopener noreferrer">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p>
                Zuki is a P2E gaming platform built on a decentralized economy
                application. A community-oriented space that performs building
                characters, virtual goods, and metaverse structures all in one.
                Furthermore, the P2E mechanism is utilized to generate financial
                benefits for the players drawing a wider crowd.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Starcard Sports</h3>
                  <div class="case-picture">
                    <img loading="lazy"
                      src="assets-new/images/home/case/star-card-sports.png"
                      alt="Starcard Sports"
                      class="img-fluid"
                      width="150"
                      height="120"
                    />
                  </div>
                </div>

                <div class="deploy-link">
                  <a href="starcard-sports-games" target="_blank"
                rel="noopener noreferrer">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  This is a play-to-earn decentralized gaming arena developed to
                  connect real-world players through digital gaming. This game
                  is based on football along with metaverse abilities drawing
                  income. With the ability to monetize, it inherits an immersive
                  experience that challenges traditional gaming platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Tech Stak Update -->
<section class="business-stak">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="common-sub-heading">
          <h4>We Vitalize<span class="dots">.</span></h4>
        </div>
        <h3 class="main-sub-heading">On Business-Class Technical Stack</h3>
        <div class="business-stak-list-section">
          <div class="list-one">
            <!-- <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/frameworks.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/frameworks.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/frameworks.png" width="100%" height="100%" class="img-fluid" alt="Frameworks" title="Frameworks" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/frameworks-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/frameworks-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/frameworks-dark.png" width="100%" height="100%" class="img-fluid" alt="Frameworks" title="Frameworks" />
              </picture>
            </div> -->
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger.png" width="253" height="50" class="img-fluid" alt="Hyperledger" title="Hyperledger" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-dark.png" width="253" height="50" class="img-fluid" alt="Hyperledger" title="Hyperledger" />
              </picture>
            </div>
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/caliber.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/caliber.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/caliber.png" width="185" height="54" class="img-fluid" alt="Caliber" title="Caliber" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/caliber-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/caliber-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/caliber-dark.png" width="185" height="54" class="img-fluid" alt="Caliber" title="Caliber" />
              </picture>
            </div>
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger-composer.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger-composer.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-composer.png" width="235" height="51" class="img-fluid" alt="composer" title="composer" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger-composer-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger-composer-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-composer-dark.png" width="235" height="51" class="img-fluid" alt="composer" title="composer" />
              </picture>
            </div>
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger-explorer.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger-explorer.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-explorer.png" width="217" height="43" class="img-fluid" alt="explorer" title="explorer" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger-explorer-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger-explorer-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-explorer-dark.png" width="217" height="43" class="img-fluid" alt="explorer" title="explorer" />
              </picture>
            </div>
          </div>
          <div class="list-one">
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/sawtooth.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/sawtooth.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/sawtooth.png" width="232" height="50" class="img-fluid" alt="sawtooth" title="sawtooth" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/sawtooth-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/sawtooth-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/sawtooth-dark.png" width="232" height="50" class="img-fluid" alt="sawtooth" title="sawtooth" />
              </picture>
            </div>
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/iroha.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/iroha.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/iroha.png" width="175" height="48" class="img-fluid" alt="iroha" title="iroha" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/iroha-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/iroha-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/iroha-dark.png" width="175" height="48" class="img-fluid" alt="iroha" title="iroha" />
              </picture>
            </div>
            <div class="list-images col-6 col-md-3">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/fabric.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/fabric.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/fabric.png" width="174" height="49" class="img-fluid" alt="fabric" title="fabric" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/fabric-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/fabric-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/fabric-dark.png" width="174" height="49" class="img-fluid" alt="fabric" title="fabric" />
              </picture>
            </div>
          </div>
          <div class="step-two-stak">
            <div class="list-one-alt">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/java.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/java.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/java.png" width="47" height="64" class="img-fluid mob-res-stak" alt="java" title="java" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/java-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/java-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/java-dark.png" width="47" height="64" class="img-fluid mob-res-stak" alt="java" title="java" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/stak/js.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/js.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/js.png" width="55" height="56" class="img-fluid mob-res-stak" alt="javascript" title="javascript" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/node.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/node.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/node.png" width="131" height="40" class="img-fluid" alt="node" title="node" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/node-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/node-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/node-dark.png" width="131" height="40" class="img-fluid" alt="node" title="node" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/stak/net.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/net.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/net.png" width="55" height="56" class="img-fluid mob-res-stak" alt=".net" title=".net" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/stak/ruby.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/ruby.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/ruby.png" width="143" height="44" class="img-fluid" alt="ruby" title="ruby" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/solidity.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/solidity.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/solidity.png" width="32" height="50" class="img-fluid mob-res-stak" alt="solidity" title="solidity" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/solidity-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/solidity-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/solidity-dark.png" width="32" height="50" class="img-fluid mob-res-stak" alt="solidity" title="solidity" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture *ngIf="isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/python.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/python.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/python.png" width="171" height="46" class="img-fluid " alt="python" title="python" />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source type="image/webp" srcset="assets-new/images/home/stak/python-dark.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/python-dark.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/python-dark.png" width="171" height="46" class="img-fluid " alt="python" title="python" />
              </picture>
            </div>
            <div class="list-one-alt">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/stak/go.webp" />
                <source type="image/png" srcset="assets-new/images/home/stak/go.png" />
                <img loading="lazy" data-src="assets-new/images/home/stak/go.png" width="88" height="34" class="img-fluid " alt="go" title="go" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Tech Stak Update -->

<section class="features">
  <div class="container">
    <div class="row mb-4">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>We Are Featured<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>On Hyperledger Blockchain Showcase</h2>
          </div>
          <div class="deploy-link">
            <a href="hyperledger-blockchain-development-services">
              <span>Read More</span>
              <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="feature_text_wrap">
          <p>
            With hands-on experience in developing decentralized solutions and
            their components we stand out among the other NFT development
            solution providers, as we particularize on quality and
            customization. Focusing on our vision, we have been associated with
            real-world problems, providing ultimate solutions.Being featured on
            the Hyperledger Fabric Blockchain Showcase.
          </p>
          <div class="content-link">
            <a
              href="https://www.hyperledger.org/learn/blockchain-showcase?profile=eatzilla"
              target="_blank"
                rel="noopener noreferrer"
            >
              <span>More about hyperledger</span>
              <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="feature_text_wrap">
          <p>
            Our product Eatzila is the first-ever food delivery application on
            the blockchain network. We have built it to solve the major trauma
            in the industry, solving issues in substandard delivery and
            management, maintaining transparency in discounts, and others.
            Alongside we are extended as a premium Metaverse development company
            heading to the larger outreach across the continents. Our R&D team
            heads through every possible nuance to dig for the perfect solutions
            for our client. Our substantial motive is to revolve around this
            world as a sustainable web3 solution facilitator.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
