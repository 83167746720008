import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FooterServiceService } from '../service/footer-service.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  title = '';
  isDarkmode: boolean = false;

  constructor(
    private footerservice: FooterServiceService,
  ) {
  }

  ngOnInit(): void {

    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      this.isDarkmode = response;
    });
  }

  }
