import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../shared/canonical.service';
import { newsData } from '../news/news-data';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.css']
})
export class AuthorComponent implements OnInit {
  public newsdata: Array<{ [key: string]: any }> = [];
  title =
  'Meet Our Author: Unveiling the Creative Mind Behind the Words';
constructor(
  public router: Router,
  private titleService: Title,
  private meta: Meta,
  private canonicalService: CanonicalService,
  private footservice:FooterServiceService,
  @Inject(DOCUMENT) private doc: Document
) {}

isDarkmode:boolean=false;
ngOnInit(): void {
  //darkmode services 
  this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
    if (response) {
      this.isDarkmode = response;
    }
    else {
      this.isDarkmode = response;
    }
  });
      this.newsdata=newsData
      this.titleService.setTitle(this.title);
  this.meta.updateTag({
    name: 'description',
    content:
      'Embark on a tech frontier with insightful writing. From decentralized ledgers to crypto advancements, explore a spectrum of innovation, unravelling complexities with clarity and expertise.',
  });
  this.meta.updateTag({
    name: 'keywords',
    content: "",
  });
  this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
  this.meta.updateTag({ name: 'robots', content: 'index, follow' });

  this.meta.updateTag({
    property: 'og:title',
    content:
      'Meet Our Author: Unveiling the Creative Mind Behind the Words',
  });
  this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
  this.meta.updateTag({
    property: 'og:url',
    content: 'https://www.blockchainx.tech/author/yokesh-sankar/',
  });
  this.meta.updateTag({
    property: 'og:description',
    content:
      'Embark on a tech frontier with insightful writing. From decentralized ledgers to crypto advancements, explore a spectrum of innovation, unravelling complexities with clarity and expertise.',
  });
  this.meta.updateTag({ property: 'og:type', content: 'Website' });
  this.meta.updateTag({
    property: 'og:image',
    content:
      'https://www.blockchainx.tech/assets-new/images/og/blockchainx-og.jpg',
  });

  this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
  this.meta.updateTag({
    name: 'twitter:site',
    content: 'https://www.blockchainx.tech/author/yokesh-sankar/',
  });
  this.meta.updateTag({
    name: 'twitter:title',
    content:
      'Meet Our Author: Unveiling the Creative Mind Behind the Words',
  });
  this.meta.updateTag({
    name: 'twitter:description',
    content:
      'Embark on a tech frontier with insightful writing. From decentralized ledgers to crypto advancements, explore a spectrum of innovation, unravelling complexities with clarity and expertise.',
  });
  this.meta.updateTag({
    name: 'twitter:image',
    content:
      'https://www.blockchainx.tech/assets-new/images/og/blockchainx-og.jpg',
  });

  // Add Canonical tag
  this.canonicalService.setCanonicalURL(
    'https://www.blockchainx.tech/author/yokesh-sankar/'
  );

}

}
