<div class="meta-expert">
  <div class="our-expert-vector">
    <picture *ngIf="isDarkmode">
      <source type="image/webp" srcset="assets-new/images/home/deploy/deploy-vector.webp" />
      <source type="image/png" srcset="assets-new/images/home/deploy/deploy-vector.png" />
      <img loading="lazy" data-src="assets-new/images/home/deploy/deploy-vector.png" width="709" height="560" class="img-fluid" alt="expert-vector" title="expert-vector" />
    </picture>
    <picture *ngIf="!isDarkmode">
      <source type="image/webp" srcset="assets-new/images/home/deploy/deploy-vector-dark.webp" />
      <source type="image/png" srcset="assets-new/images/home/deploy/deploy-vector-dark.png" />
      <img loading="lazy" data-src="assets-new/images/home/deploy/deploy-vector-dark.png" width="709" height="560" class="img-fluid" alt="expert-vector" title="expert-vector" />
    </picture>
  </div>
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="our_experts">
          <h2><span>Deploy Your Web3 With</span>BlockchainX Tech</h2>
          <div class="text-center my-5">
            <a class="our_expert_talk" href="contact">Talk to our expert</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
