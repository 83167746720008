<div class="testimonial-section">
  <div class="container">
    <div class="row">
      <div class="caption text-center">
        <h1>
          <span><strong>Testimonials</strong></span>
        </h1>
      </div>
    </div>
    <div class="tesimonial-inside">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
          <div class="tetimonial-detail">
            <div class="client-picture">
              <img src="assets-new/images/testimonial/us.png" />
            </div>
            <h3 class="common-sub-heading-alt">US Crypto Bank</h3>
            <h4 class="test-sub-head">United States</h4>
            <div class="testimonial-head py-3">
              <h4><strong>Blockchain experts</strong></h4>
            </div>
            <div class="testimonial-para">
              <p>
                If you need anything done with Web3 , Blockchain, & cryptology,
                this is your team to make it happen.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
          <div class="tetimonial-detail">
            <div class="client-picture">
              <img src="assets-new/images/testimonial/wm.png" />
            </div>
            <h3 class="common-sub-heading-alt">Wall Street Mafia</h3>
            <h4 class="test-sub-head">United States</h4>
            <div class="testimonial-head py-3">
              <h4><strong>Very professional...</strong></h4>
            </div>
            <div class="testimonial-para">
              <p>
                These guys are very professional and fantastic coders. I've
                hired them for multiple projects. They are my go-to for all my
                blockchain and cryptocurrency needs.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
          <div class="tetimonial-detail">
            <div class="client-picture">
              <img src="assets-new/images/testimonial/john.png" />
            </div>
            <h3 class="common-sub-heading-alt">John Doe</h3>
            <h4 class="test-sub-head">United States</h4>
            <div class="testimonial-head py-3">
              <h4><strong>Top Caliber Developers...</strong></h4>
            </div>
            <div class="testimonial-para">
              <p>
                From the first day the team was very professional. We had set
                deadlines that were nearly impossible but they got it done. We
                requested a Launchpad, swap, and farming from the ground up,
                front/backend. Everything worked flawless and was properly
                tested. The team even helped out on the first few launches on
                the new Launchpad when they didnâ€™t have too. Very thankful and
                impressed.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
          <div class="tetimonial-detail">
            <div class="client-picture">
              <img src="assets-new/images/testimonial/john-2.png" />
            </div>
            <h3 class="common-sub-heading-alt">Jim Phipps</h3>
            <h4 class="test-sub-head">United States</h4>
            <div class="testimonial-head py-3">
              <h4><strong>BlockchainX are a professional...</strong></h4>
            </div>
            <div class="testimonial-para">
              <p>
                BlockchainX are a professional, competent and cost-effective
                team of crypto economists and coders, who deliver on time and
                within budget the products weâ€™ve commissioned them to build.
                Highly recommended. Will use again and again.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
