<section class="blog-main">
  <div class="green-vector"></div>
  <!-- <div class="container">
    <div class="blog-main-wrap"></div>
    <div class="row">
      <div class="blog-card-main">
        <div class="metaverse_heading">
          <h3>
            A <span>digital magazine</span> that promises
            <span class="d-block"></span>to inspiring stories from all
            disciplines.
          </h3>
        </div>
        <div class="col-lg-6 mx-auto">
          <div class="blog_search_wrap">
            <input
              type="text"
              class="form-control"
              placeholder="Click here to search for posts"
            />
            <div class="blog_search_icon_pos">
              <img src="assets-new/images/blog/search.svg" alt="search icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="text-center">
        <div class="blog_categories">
          <h3>Categories</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="blog_category_lists">
        <ul>
          <li><a href="">API</a></li>
          <li><a href="">Aptos</a></li>
          <li><a href="">Arbitrum</a></li>
          <li><a href="">Authentication</a></li>
          <li><a href="">Avalanche</a></li>
          <li><a href="">AWS Gamesparks</a></li>
          <li><a href="">AWS Lambda</a></li>
          <li><a href="">Blockchain</a></li>
        </ul>
        <ul>
          <li><a href="">BNB Smart Chain</a></li>
          <li><a href="">Business</a></li>
          <li><a href="">Code Walkthrough</a></li>
          <li><a href="">Cronos</a></li>
          <li><a href="">Dapps</a></li>
          <li><a href="">Dogechain</a></li>
          <li><a href="">Ethereum</a></li>
        </ul>
        <ul>
          <li><a href="">Gaming</a></li>
          <li><a href="">IPFS</a></li>
          <li><a href="">JavaScript</a></li>
          <li><a href="">Metaverse</a></li>
          <li><a href="">NFTs</a></li>
          <li><a href="">Nodes</a></li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="Slider-Blog">
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-12 col-12 mb-4">
          <div id="blogSlider" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#blogSlider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#blogSlider" data-bs-slide-to="1" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#blogSlider" data-bs-slide-to="2" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#blogSlider" data-bs-slide-to="3" aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#blogSlider" data-bs-slide-to="4" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#blogSlider" data-bs-slide-to="5" aria-label="Slide 5"></button>
            </div>
            <div class="carousel-inner" >
              <div class="carousel-item active" >
                <a href="https://www.blockchainx.tech/precious-metal-tokenization-guide/" class="blog-slider-img">
                  <img loading="lazy" src="assets-new/images/blog/precious-metal-token/precious-metals-tokenization.jpg" alt="Precious Metal Tokenization" title="Precious Metal Tokenization" class="img-fluid" width="856" height="522"/>
                </a>
              </div>
              <div class="carousel-item" >
                <a href="https://www.blockchainx.tech/erc20-token-development-companies/" class="blog-slider-img">
                  <img loading="lazy" src="assets-new/images/blog/top-erc20-token/erc20-token-development-companies.jpg" alt="ERC20 Token Development Companies" title="ERC20 Token Development Companies" class="img-fluid" width="856" height="522"/>
                </a>
              </div>
              <div class="carousel-item" >
                <a
                [routerLink]="[
                  '/create-your-own-crypto-token'
                ]" class="blog-slider-img"
              >
                <img
                  src="assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token-banner.jpg"
                  alt="8 Steps to Create Your Own Crypto Token: A Comprehensive Guide" class="img-fluid" width="840" height="512"
                /> 
              </a>
              </div>
              <div class="carousel-item" >
                <a [routerLink]="['/cross-chain-interoperability-protocol']" class="blog-slider-img">
                  <img
                    src="assets-new/images/blog/cross-chain-interoperability-protocol-banner.jpg"
                    alt="Cross Chain Interoperability Protocol" class="img-fluid" width="840" height="512"
                  />
                </a>
              </div>
              <div class="carousel-item " >
                <a [routerLink]="['/top-10-real-estate-tokenization-companies']" class="blog-slider-img">
                  <img
                    src="assets-new/images/blog/top-10-real-estate/real-estate-tokenization.jpg"
                    alt="Top 10 Real Estate Tokenization Companies" class="img-fluid" width="840" height="512"
                  />
                </a>
              </div>
              <div class="carousel-item">
                <a
                [routerLink]="[
                  '/blockchain-development-cost'
                ]" class="blog-slider-img"
              >
                <img
                  src="assets-new/images/blog/blockchain-development-cost/blockchain-development.jpg"
                  alt="How Much Does Blockchain Development Cost in 2024?" class="img-fluid" width="840" height="512"
                />
              </a>
              </div>
              <div class="carousel-item">
                <a
                [routerLink]="[
                  '/blockchain-using-abstraction-development'
                ]" class="blog-slider-img"
              >
                <img
                  src="assets-new/images/blog/blockchain-abstractions/blockchain-abstractions.jpg"
                  alt="Blockchain Abstraction Development" class="img-fluid" width="840" height="512"
                />
              </a>
              </div>
              <div class="carousel-item">
                <a
                [routerLink]="[
                  '/introduction-to-base-layer-2-blockchain-development'
                ]" class="blog-slider-img"
              >
                <img
                  src="assets-new/images/blog/base-layer-blockchain-development/layer-2-blockchain-development.jpg"
                  alt="Introduction to Base Layer 2 Blockchain Development" class="img-fluid" width="840" height="512"
                />
              </a>
              </div>
              <div class="carousel-item">
                <a
                [routerLink]="[
                  '/top-blockchain-development-companies-in-us'
                ]" class="blog-slider-img"
              >
                <img
                  src="assets-new/images/blog/blockchain-development-companies-in-us/top-blockchain-development-companies-in-us.jpg"
                  alt="Top 10 Blockchain Development Companies US" class="img-fluid" width="840" height="512"
                />
              </a>
              </div>
            </div>
          </div>
          <!-- <owl-carousel-o [options]="customOptions" id="blog_contact">
            <ng-template carouselSlide>
              <a [routerLink]="['/ai-token-definition']" class="blog-slider-img">
                <img src="assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg"
                  alt="AI Token" class="img-fluid" width="840" height="512" /> 
              </a>
            </ng-template>             
          </owl-carousel-o> -->
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="blog-contact">
            <div class="metaverse_heading">
              <h4><span>Newsletter</span></h4>
              <h5>
                Get all the latest posts
                <span class="d-block">delivered straight to your inbox.</span>
              </h5>
            </div>
            <div class="row">
              <div
                class="col-lg-12 col-md-10 col-sm-10 col-12 mx-auto text-center"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your email address"
                />
                <a class="blog_submit">Submit</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="blogs">
  <div class="orange-vector"></div>
  <div class="green-vector"></div>
  <div class="container">
    <div class="row mb-sm-4 mb-2">
      <div class="mx-auto">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap text-left">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
        </div>
      </div>
    </div>
    <!--blogs start-->

    <div class="row mb-4">
      <div class="col-lg-8 col-sm-8 order-1">
        <ng-container *ngIf="blogmenulist == 'All'">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngFor="let data of blogDetails; let i = index">
              <div class="blog_wrap">
                <a href="{{ data.link.href }}">
                  <img
                    src="{{ data.imgSrc }}"
                    alt="{{ data.imgAlt }}"
                    class="img-fluid list-blog-picture"
                    width="404"
                    height="215"
                  />
                  <h3>{{ data.title }}</h3>
                  <p>
                    {{ data.description }}
                  </p>
      
                  <div class="content-link">
                    <span>Read More</span
                    >
                    <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    <hr />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'BlockChain'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='blockchain'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'NFT'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='nft'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
            
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'Crypto'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='crypto'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'Metaverse'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='metaverse'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'DEFI'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='defi'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
            
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'Token'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='token'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
        
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
            
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'Crypto Token development'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='Crypto Token development'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
        
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
            
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'Ethereum Token Development'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='Ethereum Token Development'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
        
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
            
          </div>
        </ng-container>
        <ng-container *ngIf="blogmenulist == 'Asset Tokenization'">
          <div class="row">
            <ng-container *ngFor="let data of blogDetails; let i = index">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-4" *ngIf="data.category=='Asset Tokenization'">
                <div class="blog_wrap" >
                  <a href="{{ data.link.href }}">
                    <img
                      src="{{ data.imgSrc }}"
                      alt="{{ data.imgAlt }}"
                      class="img-fluid list-blog-picture"
                      width="404"
                      height="215"
                    />
                    <h3>{{ data.title }}</h3>
                    <p>
                      {{ data.description }}
                    </p>
        
                    <div class="content-link">
                      <span>Read More</span
                      >
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
        
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
            
          </div>
        </ng-container>
      </div>
      <div class="col-lg-4 col-sm-4 order-2">
        <div class="blog_wrap stickypsostion">
          <div class="metaverse_heading">
            <h5>Categories</h5>
          </div>
          <ul class="blog-category">
            <li *ngFor="let blogmenu of blogcatgory">
              <a [ngClass]="{activies: blogmenulist === blogmenu}" (click)="blogCategory(blogmenu)">{{blogmenu}} </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
  </div>
</section>

<!-- <section>
  <div class="blogs-list">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-5 col-8 mx-auto text-center">
          <a class="blog_submit cursor-pointer" (click)="showMore()"
            >Show more</a
          >
        </div>
      </div>
    </div>
  </div>
</section> -->  

<section class="section-service-alt-main">
<app-contactform></app-contactform>
</section>