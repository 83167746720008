<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Gaming Utility Token</strong></span> Development
                Company
              </h1>
              <p>
                Maximizing the user’s accessibility over your game with Gaming
                utility token development enhances the experience, multiplies
                revenue streams, and expands to wider communities.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/gaming-utility/gaming-utility.webp" />
              <source type="image/png" srcset="assets-new/images/gaming-utility/gaming-utility.png" />
              <img loading="lazy"  data-src="assets-new/images/gaming-utility/gaming-utility.png" width="500" height="500" class="img-fluid"  alt="Gaming Utility Token Development" title="Gaming Utility Token Development" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/gaming-utility/light-theme/gaming-utility.webp" />
              <source type="image/png" srcset="assets-new/images/gaming-utility/light-theme/gaming-utility.png" />
              <img loading="lazy"  data-src="assets-new/images/gaming-utility/light-theme/gaming-utility.png" width="500" height="500" class="img-fluid"  alt="Gaming Utility Token Development" title="Gaming Utility Token Development" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>What Is a Gaming Utility </span>Token
        Development Service?</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Token Development Service"
              title="Token Development Service"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Utility tokens provide additional value to tokens by increasing
            their access points and usage in any ecosystem. Similarly, Gaming
            Utility tokens are specifically designed and curated to support and
            expand the game's popularity in decentralized spaces. They are more
            focussed go beyond a crypto coin and have additional values.
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Alternates Payments in the future
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> More than a
                Crypto token
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Utilized in
                Crowdfunding
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> MAximizes
                User Range
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Popular Gaming Utility Tokens"
              title="Popular Gaming Utility Tokens" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular Gaming</span>
            Utility Tokens</h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/floki"
                    target="_blank"
                    rel="noopener noreferrer">FLOKI</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/gala"
                    target="_blank"
                    rel="noopener noreferrer">Gala</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/decentraland"
                    target="_blank"
                    rel="noopener noreferrer">Decentraland</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/the-sandbox"
                    target="_blank"
                    rel="noopener noreferrer">Sandbox</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span><a
                    href="https://www.coingecko.com/en/coins/pixels"
                    target="_blank"
                    rel="noopener noreferrer">Pixels</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/yield-guild-games"
                    target="_blank"
                    rel="noopener noreferrer">Yield Guild Games</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/hashflow">Hashflow</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Wider World
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Gaming Utility Token </span>Development Service
          Highlights</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Transaction Anonymity"
                title="Transaction Anonymity" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Transaction Anonymity </h4>
          <p>
            Being a blockchain-based gaming utility token all the transactions
            are recorded and safe, and it also ensures to maintain anonymity.
          </p>
          <!-- <div class="content-link">
                <a [routerLink]="['/contact']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow"
                       />
                  </picture>
                </a>
                <hr />
              </div> -->
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Globalized Reach"
                title="Globalized Reach" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Globalized Reach
          </h4>
          <p>
            Take your Gaming spectrum to a wider community with a Gaming utility
            token that does more than a usual crypto token, as empowering as it
            could be.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Crowdfunding" title="Crowdfunding"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Crowdfunding</h4>
          <p>
            A straightforward strategy to imply while raising funds, creating a
            gaming utility token can captivate for its multi personality and
            yield benefits on both sides.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Cost Effective Development"
                title="Cost Effective Development" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Cost Effective Development</h4>
          <p>
            We as your gaming utility token development company ensure to
            deliver technologically advanced and cost-effective solutions to
            enhance your game.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Customized Features"
                title="Customized Features" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Customized Features</h4>
          <p>
            Customize your gaming utility token to perform the task as you want,
            in the best way you want it performed with our customization
            services
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="What More?" title="What More?"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            What More?
          </h4>
          <p>
            The Gaming utility token holds access to products added to the
            system, sustains prices from falling, offers rewards, is open to
            upgrades, and provides fixed demand and supply.
          </p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>BlockchainX Your Gaming Utility </span>Token Development
            Company
          </h2>
          <p class="pb-3">
            From customizing your gaming utility token to ensuring its
            sustainability in the market with utmost security and efficiency, we
            at BlockchainX care to render you the best that your game is
            supported with. Our expert services and hands-on experience have
            spotlighted many projects and will never fail to gleam brighter for
            you.
          </p>
          <div class="whysection_text_wrap">
            <ul>
              <li>Expert technological consultants embarking possibility</li>
              <li>Experience developers with a heart to learn</li>
              <li>Breeding business across the globe</li>
              <li>24/7 support pre and post-development</li>
              <li>Transforming ideas into innovation</li>
            </ul>
          </div>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Gaming Utility Token Development Company"
              title="Gaming Utility Token Development Company" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is a Gaming utility token?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    A digital token representing an asset or a value with added
                    functionalities in a gaming network, which is unique on its
                    own is a gaming utility token.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What are the benefits of Gaming utility tokens?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Gaming utility token provides users with multiple benefits
                    for owning those, their added functionality levels up the
                    game entirely and makes it more efficient to interact in the
                    global space effectively.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  What are Gaming utility token development services included?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Gaming utility token development service induces token
                    ideation, technological empowerment, tokenomics, blockchain
                    integration, improved utility, rewards supply, token launch,
                    upgradation and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="404"
                    height="246"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Mark greater innings Of your Game
                with Gaming Utility Token development!</span></h3>
            <p>Lighting up your Gaming space with more traction and better
              responses, Connect to dwell in detail!</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->