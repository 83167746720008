import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-nft-vulnerability',
  templateUrl: './nft-vulnerability.component.html',
  styleUrls: ['./nft-vulnerability.component.css'],
})
export class NftVulnerabilityComponent implements OnInit {
  title = 'Know about NFTs Vulnerability and Security measures';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        "People are nervous about NFTs and raise the question, Are NFTs safe? In this blog, Let's read up on a few susceptibilities and security measures that you should be aware of about NFT.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'nft security, crypto for nft, on chain nft, nft training, nft networks, nft crypto platform, enterprise nft, nft crypto course, nft platform crypto, nft fundamentals, crypto nft platforms, crypto with nft, nft is crypto, the block nft, nft which crypto, smart contracts 101, nft and crypto difference, platforms for nft, nft for crypto, nft enterprise, smart contract security issues.',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Know about NFTs Vulnerability and Security measures',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/nft-vulnerability-and-security/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "People are nervous about NFTs and raise the question, Are NFTs safe? In this blog, Let's read up on a few susceptibilities and security measures that you should be aware of about NFT.",
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/nft-vulnerability-and-security.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/nft-vulnerability-and-security/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Know about NFTs Vulnerability and Security measures',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        "People are nervous about NFTs and raise the question, Are NFTs safe? In this blog, Let's read up on a few susceptibilities and security measures that you should be aware of about NFT.",
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/nft-vulnerability-and-security.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/nft-vulnerability-and-security/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/nft-vulnerability-and-security.html'
    );
    this.doc.head.appendChild(link);
  }
}
