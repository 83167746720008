import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-what-is-depin',
  templateUrl: './what-is-depin.component.html',
  styleUrls: ['./what-is-depin.component.css']
})
export class WhatIsDepinComponent implements OnInit {

  selectedLink:number;
  title =
    'Decentralized Physical Infrastructure Network (DePIN)';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Do you wonder what DePIN is? Here we have covered the in and out of the newly evolving decentralized physical infrastructure network that acts on the blockchain and token reward system of Web3. Explore in detail!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'what is DePIN, DePIN, decentralized physical infrastructure network',
    });
              

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Decentralized Physical Infrastructure Network (DePIN)',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/what-is-depin/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Do you wonder what DePIN is? Here we have covered the in and out of the newly evolving decentralized physical infrastructure network that acts on the blockchain and token reward system of Web3. Explore in detail!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/depin/depin-extravaganza.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/what-is-depin/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Decentralized Physical Infrastructure Network (DePIN)',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Do you wonder what DePIN is? Here we have covered the in and out of the newly evolving decentralized physical infrastructure network that acts on the blockchain and token reward system of Web3. Explore in detail!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/depin/depin-extravaganza.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/what-is-depin/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/what-is-depin.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }


}
