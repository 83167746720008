export const blogData = [
  {
    imgSrc:
      'assets-new/images/blog/precious-metal-token/precious-metals-tokenization.jpg',
    imgAlt: 'Precious Metal Tokenization',
    title:
      'Precious Metal Tokenization - Complete Guide On Transformative Investments',
    description:
      'Precious metal tokenization is a great opportunity to multiply with web3 intervention through blockchain and smart contract, Wondering how? Discover it here, tap to read. ',
    link: {
      href: '/precious-metal-tokenization-guide',
      text: 'Read More',
    },
    category:'Asset Tokenization',
  },
  {
    imgSrc:
      'assets-new/images/blog/top-erc20-token/erc20-token-development-companies.jpg',
    imgAlt: 'ERC20 Token Development Companies',
    title:
      'Top 15 ERC20 Token Development Companies',
    description:
      'Discover the top 15 ERC20 token development companies in the market that are trending in developing exclusive ERC20 tokens just for you. Read to explore them.',
    link: {
      href: '/erc20-token-development-companies',
      text: 'Read More',
    },
    category:'Ethereum Token Development',
  },
  {
    imgSrc:
      'assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token-banner.jpg',
    imgAlt: '8 Steps to Create Your Own Crypto Token: A Comprehensive Guide',
    title:
      '8 Steps to Create Your Own Crypto Token: A Comprehensive Guide',
    description:
      'Over the decade, the popularity and strength of cryptocurrencies have increased a lot, challenging the conventional ways of making',
    link: {
      href: '/create-your-own-crypto-token',
      text: 'Read More',
    },
    category:'Crypto Token development',
  },
  {
    imgSrc:
      'assets-new/images/blog/cross-chain-interoperability-protocol-banner.jpg',
    imgAlt: 'Cross Chain Interoperability Protocol',
    title:
      'Blockchain Smart Contract Development Company',
    description:
      'The advancement has been highly progressive in blockchain. This initiation of cross chain interoperability protocol is also a similar exuberant step that maximizes the potential of the blockchain network. Tap to learn more on it.',
    link: {
      href: '/cross-chain-interoperability-protocol',
      text: 'Read More',
    },
    category:'blockchain',
  },
  {
    imgSrc:
      'assets-new/images/blog/top-10-real-estate/real-estate-tokenization.jpg',
    imgAlt: 'Top 10 Real Estate Tokenization Companies',
    title:
      'Top 10 Real Estate Tokenization Companies',
    description:
      'Tokenization of real estate refers to the conversion of digital assets right into a shape that makes it simple for corporations to purchase, promote, and exchange them.',
    link: {
      href: '/top-10-real-estate-tokenization-companies',
      text: 'Read More',
    },
    category:'token',
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-development-cost/blockchain-development.jpg',
    imgAlt: 'How Much Does Blockchain Development Cost in 2024?',
    title:
      'How Much Does Blockchain Development Cost in 2024?',
    description:
      'If you are looking for secure and transparent transactions, Blockchain technology can be your safest companion. Apart from bringing improved transparency',
    link: {
      href: '/blockchain-development-cost',
      text: 'Read More',
    },
    category:'blockchain',
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-abstractions/blockchain-abstractions.jpg',
    imgAlt: 'Blockchain Abstraction Development',
    title:
      'Future Of Wallets With Blockchain Abstraction Development',
    description:
      'Blockchain technology has been rapidly gaining popularity for its decentralized and transparent nature and Ethereum has great traction over the same.',
    link: {
      href: '/blockchain-using-abstraction-development',
      text: 'Read More',
    },
    category:'blockchain',
  },
  {
    imgSrc:
      'assets-new/images/blog/base-layer-blockchain-development/layer-2-blockchain-development.jpg',
    imgAlt: 'An Introduction to Base Layer 2 Blockchain Development: Getting Started Guide',
    title:
      'An Introduction to Base Layer 2 Blockchain Development: Getting Started Guide',
    description:
      'Today, platforms like Ethereum, Bitcoin, and other Layer 1 blockchain have completely changed the way you think about digital wealth, decentralized applications, and online trust. ',
    link: {
      href: '/introduction-to-base-layer-2-blockchain-development',
      text: 'Read More',
    },
    category:'blockchain',
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-development-companies-in-us/top-blockchain-development-companies-in-us.jpg',
    imgAlt: 'Top 10 Blockchain Development Companies US',
    title:
      'The U.S Based 10 Top Blockchain Development Companies',
    description:
      'As the industry doubles in Billions and millions, blockchain Development has become an overall demand in the market.',
    link: {
      href: '/top-blockchain-development-companies-in-us',
      text: 'Read More',
    },
    category:'blockchain',
  },
  {
    imgSrc:
      'assets-new/images/blog/depin/depin-extravaganza.jpg',
    imgAlt: 'DePIN Extravaganza',
    title:
      'DePIN - What Is Decentralized Physical Infrastructure Network?',
    description:
      'DePIN, or the Decentralized Physical Infrastructure Network, is a decentralized protocol using tokens to incentivize communities',
    link: {
      href: '/what-is-depin',
      text: 'Read More',
    },
    category:'token',
  },
  {
    imgSrc:
      'assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg',
    imgAlt: 'AI Token',
    title:
      'AI Token - The Whats Of It!',
    description:
      ' AI tokens are the new revolution in the crypto market. Artificial intelligence is leveraging every sector, and eventually',
    link: {
      href: '/ai-token-definition',
      text: 'Read More',
    },
    category:'nft',
  },
  {
    imgSrc:
      'assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg',
    imgAlt: 'Real Estate Tokenization Enabling Endless Investment Opportunites.',
    title:
      'How does Real Estate Tokenization Revolutionize Investments?',
    description:
      ' Over the years, investment scales have been tremendously improving, and new avenues are blooming now and then with blockchain',
    link: {
      href: '/real-estate-tokenization-guide',
      text: 'Read More',
    },
    category:'blockchain',
  },
  {
    imgSrc:
      'assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.jpg',
    imgAlt: 'Top 10 Real World Asset Tokenization Companies 2024d',
    title:
      'Top 10 Real World Asset Tokenization Companies 2024',
    description:
      ' The very modern monetizing process, real-world asset tokenization, involves curating digital presence and value for real-world assets.',
    link: {
      href: '/top-real-world-asset-tokenization-companies',
      text: 'Read More',
    },
    category:'nft',
  },
  {
    imgSrc:
      'assets-new/images/blog/erc20-token-development-guide/erc20-token-development.jpg',
    imgAlt: 'ERC20 Token development With BlockchainX',
    title:
      'Complete Guide For ERC20 Token Development',
    description:
      ' In 2015, Fabian Vogelstellar created the ERC20 token standard which was a quick pick and gained instant popularity in the market. Its ability to interact',
    link: {
      href: '/erc20-token-development-guide',
      text: 'Read More',
    },
    category:'Ethereum Token Development',
  },
  {
    imgSrc:
      'assets-new/images/blog/real-world-asset-tokenization/banner.jpg',
    imgAlt: 'real-world-asset-tokenization',
    title:
      'Real World Asset Tokenization: Wider Door With Wider Possibilities',
    description:
      'Tokenizing real-world assets is a revolutionary process that involves converting tangible assets like real estate, art, and others with physician existence',
    link: {
      href: '/real-world-asset-tokenization-guide-2024',
      text: 'Read More',
    },
    category:'tokenize',
  },
  {
    imgSrc:
      'assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg',
    imgAlt: 'Private Blockchain Development',
    title:
      'How to Create a Private Blockchain For Intensify Business Security?',
    description:
      ' With blockchain dominating the entire digital ecosystem, and finance and technology taking over the world, digital investments have emerged as a captivating option, attracting the attention of technology-savvy investors and curious newcomers alike.',
    link: {
      href: '/how-to-create-a-private-blockchain',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/crypto-launchpad-development-steps.jpg',
    imgAlt: 'crypto-launchpad-development-steps',
    title:
      'Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad.',
    description:
      'Crypto launchpad development is a great opportunity to keep up and stay one step ahead in this booming crypto world. Launchpads are a major driving force in the evolution, extension, and funding of crypto projects.',
    link: {
      href: '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad',
      text: 'Read More',
    },
    category:'crypto'
  },
  {
    imgSrc:
      'assets-new/images/blog/nft-marketplace/navigating-the-digital-fontier.jpg',
    imgAlt: 'navigating-the-digital-fontier',
    title:
      'NFT Marketplace Development: A Comprehensive Analysis of Challenges & Imperative Considerations',
    description:
      'With the increasing use of NFTs (non-fungible tokens) across various industries, the demand for app.',
    link: {
      href: '/key-considerations-nft-marketplace-development',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg',
    imgAlt: 'erc20-token-standard',
    title: 'What is ERC20? A Guide to the Pinnacles of Ethereum Token Standard',
    description:
      'While this crypto revolution continues to change the virtual and the real world, the ERC20 token standards are playing a big part in it.',
    link: {
      href: '/what-is-erc20',
      text: 'Read More',
    },
    category:'Ethereum Token Development'
  },
  {
    imgSrc:
      'assets-new/images/blog/nft-business-use-cases/nfts-for-business.jpg',
    imgAlt: 'nfts-for-business',
    title: 'How to Leverage NFTs for Your Business',
    description:
      'The rapidly evolving digital landscape is transforming every aspect of our business in ways unimaginable.The game changer in that is Non-Fungible Tokens (NFTs). ',
    link: {
      href: '/nft-business-use-cases',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-based-identity-management/blockchain-in-government-and-the-public-sector.jpg',
    imgAlt: 'blockchain-based-identity-management',
    title:
      'Transforming Government and Public Sector: The Innovative Frontier of Blockchain-Based Identity Management ',
    description:
      'In the realm of a rapidly evolving digital landscape, the government and public sector confront the intricacies of managing identities securely and efficiently.',
    link: {
      href: '/blockchain-based-identity-management',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-identity-management-benefits/blockchain-based-digital-identity.jpg',
    imgAlt: 'blockchain-based-digital-identity',
    title:
      'Is Blockchain Based Identity Management Benefiting The Global Cause?',
    description:
      "'Who are you?' with a lot of perspectives to answer, for a person in this world. In general,  it's one person with different identities to match different social groups.",
    link: {
      href: '/blockchain-identity-management-benefits',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-impact-on-digital-identity.jpg',
    imgAlt: 'blockchain-for-digital-identity',
    title: 'Security Enhancement of Digital Identity Using Blockchain',
    description:
      'Digital Identity Management (DIDM) is a system that connects individuals with their online identities. We can say that this is one of the oldest and toughest problems in the digital world.',
    link: {
      href: '/blockchain-impact-on-digital-identity',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-based-land-registry-system/blockchain-based-land-registry.jpg',
    imgAlt: 'blockchain-based-land-registry-system',
    title: 'Blockchain-Based Land Registry System: An Ideational Framework',
    description:
      'Android is long and strange journey from the initial cupcake to today is a mystery to the world. Similarly, Blockchain started its journey in 2009, created many revolutions',
    link: {
      href: '/blockchain-based-land-registry-system',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-traceability/blockchain-traceability.jpg',
    imgAlt: 'blockchain-traceability',
    title:
      'A Comprehensive Guide to Blockchain Traceability in Supply Chain Management',
    description:
      'In recent years, blockchain technology has gained significant recognition for its potential to revolutionize various industries. One area that has captured',
    link: {
      href: '/blockchain-traceability-supply-chain-management',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-empowers-crowdfunding-benefits-drawbacks/crowdfunding.jpg',
    imgAlt: 'crowdfunding',
    title:
      'Pros and Cons of Crowdfunding on the Blockchain: A Comprehensive Analysis',
    description:
      'Blockchain technology is transforming everything around us, and it is no exception. Currently, 40 million people, or around 0.5% of the world population, use blockchain technology.',
    link: {
      href: '/blockchain-empowers-crowdfunding-benefits-drawbacks',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/nft-royalties-how-they-work/nft-royalties.jpg',
    imgAlt: 'What are NFT Royalties And How To Earn From Them',
    title: 'What Are NFT Royalties? How Do They Work?',
    description:
      'Royalties, we can refer to this as remuneration paid in cash or else one of the most important tools in the wider financial landscape. A royalty is money paid by one party to another party for using their assets.',
    link: {
      href: '/nft-royalties-how-they-work',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/build-harmonic-nft/build-nft-music.jpg',
    imgAlt:
      'NFT Took Over The Music Industry Within Its Decentralized Ecosystem',
    title: 'Build Your Own Harmonic NFT Music Marketplace',
    description:
      'The web3 world is enlarging over time, new things are coming up with new improvements. With cryptocurrency in 2021, NFTs bombed the scenario, opening space to every Creator in the house.',
    link: {
      href: '/build-harmonic-nft-music-marketplace',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/leverage-trading-defi-lending/leverage-trading-defi-lending-borrowing.jpg',
    imgAlt:
      'Leverage Trading in Crypto: A Great Strategy to Increase Your Profit',
    title:
      'Leverage Trading in Crypto: A Great Strategy to Increase Your Profit ',
    description:
      'Leverage trading is now becoming a buzzword in the digital market. You know about the DeFi lending and borrowing process, but leveraged trading is the crypto trading that is gaining popularity today.',
    link: {
      href: '/leverage-trading-defi-lending-borrowing',
      text: 'Read More',
    },
    category:'defi'
  },
  {
    imgSrc:
      'assets-new/images/blog/revolutionizing-live-events-of-the-future.jpg',
    imgAlt: 'NFT Ticketing Could Change the Future of Live Events',
    title: 'NFT Ticketing Holds the Future of Live Events',
    description:
      'NFT tickets are now the buzzword in digital town. Non-fungible tokens are one of the giant drivers of Web3 adoption. One of their latest use cases is NFT ticketing.',
    link: {
      href: '/nft-ticketing-holds-future-live-events',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/fashion-brands-establishing-position-web3.jpg',
    imgAlt:
      'Why fashion brands are quickly establishing their position in Web 3.0.',
    title:
      'Why fashion brands are quickly establishing their position in Web 3.0.',
    description:
      ' Decentralization: As the world becomes more decentralized, fashion brands need to move into the metaverse as quickly as possible to secure their positions. Web3 fashion best advantage is that it enables consumers to buy and sell products directly to each other without the need for intermediaries. ',
    link: {
      href: '/fashion-brands-establishing-position-web3',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/five-best-nft-wallets-store-transact-digital-collectibles.jpg',
    imgAlt: 'five-best-nft-wallets-store-transact-digital-collectibles',
    title:
      '5 Best NFT Wallet To Safely Store and Transact Digital Collectibles',
    description:
      'Everything needs a safe. NFTs and other digital collectibles are similar to any other asset that one could hold and own in the real world.',
    link: {
      href: '/five-best-nft-wallets-store-transact-digital-collectibles',
      text: 'Read More',
    },
    category:'crypto'
  },
  {
    imgSrc:
      'assets-new/images/blog/decentralized-applications-the-future-of-iot.jpg',
    imgAlt: 'decentralized-applications-the-future-of-IoT',
    title: 'Decentralized Applications Revolutionizing The Future Of IoT ',
    description:
      'Did we think of advancements And betterment in any sector would pause once it reaches its maxims? Never right. No perfection ends and there is no end for demanding more.',
    link: {
      href: '/decentralized-applications-revolutionizing-future-iot',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/complete-guide-for-nft/developed-successful-nft-marketplace.png',
    imgAlt: 'developed-successful-nft-marketplace',
    title: 'Complete Guide To NFT Marketplace Development',
    description:
      "Have you heard of people buying Vladimir Putin's art, digital pets, or an NBA moment? But do you know where these items come from or how they are purchased?",
    link: {
      href: '/nft-marketplace-development-complete-guide',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/top10-metaverse/top-10-metaverse-case.jpg',
    imgAlt: 'ten-metaverse-use-cases-examples-for-business',
    title: 'The 10 Intriguing Metaverse Use cases and Examples for Business',
    description:
      'As visionary business and IT leaders, it is essential to stay aware of the ever-evolving technological landscape. Among the emerging trends, the metaverse stands out as a phenomenon deserving your close attention.',
    link: {
      href: '/ten-metaverse-use-cases-examples-for-business',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc:
      'assets-new/images/blog/social-token/social-token-development.jpg',
    imgAlt: 'social-token-how-used',
    title: 'What Is A Social Token? How Are They Used?',
    description:
      'At the moment, cryptocurrencies have become common, everyone turns around either invested in a project or is building a new project via experimenting with web3 and its capabilities.',
    link: {
      href: '/social-token-how-used',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc:
      'assets-new/images/blog/create-permissioned-blockchain/permissioned-blockchain.png',
    imgAlt: 'create-permissioned-blockchain',
    title: 'How To Create Permissioned Blockchain?',
    description:
      'Today everything and anything is twisted into the hands of the users. And when we say blockchain the very first thing coming to mind is transparency and decentralization.',
    link: {
      href: '/create-permissioned-blockchain',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/create-nft-marketplace-polygon.jpg',
    imgAlt: 'create-nft-marketplace-polygon',
    title: 'How to Create an NFT Marketplace on Polygon?',
    description:
      'Since the arrival of NFTs (non-fungible tokens), they have gained a large user base, and people have begun to believe in them. There have been many concerns raised about NFT over time',
    link: {
      href: '/create-nft-marketplace-polygon',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg',
    imgAlt: 'ai-in-web3-gaming-and-metaverse',
    title: 'AI And Blockchain In Web3 Revolutionizing Gaming And Metaverse',
    description:
      'In the 1990s, the introduction of the internet, and the evolution of something beyond reality kick-started the movement. There is something new every day.',
    link: {
      href: '/ai-blockchain-web3-revolutionizing-gaming-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc:
      'assets-new/images/blog/decentralized-identities-blockchain/decentralized-identities.jpg',
    imgAlt: 'decentralized-identities-beginners-guide',
    title:
      'All You Need to Know About Decentralized Identities: A Beginner Guide.',
    description:
      'While web3 technology is creating wonders in all fields, the digital identity management sector is not far behind. Digital identity is decentralized using the fruitfulness',
    link: {
      href: '/decentralized-identities-beginners-guide',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchain-in-supply-chain-management.jpg',
    imgAlt: 'blockchain-in-supply-chain-management',
    title: 'Blockchain In Supplychain Management Is A Trendsetting Web3 Duo',
    description:
      'What accelerating a business Its a conversion ratio, right? And what makes it convincing for the audience? Being legit, reliable, and more trust-built factors. If so, how do we achieve it in the centralized process with too much control and negotiation norms? Get it transparent and more efficient.',
    link: {
      href: '/blockchain-in-supply-chain-management',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/blog/create-your-nft-marketplace-on-avalanche/nft-marketplace-on-avalanche.jpg',
    imgAlt: 'nft-marketplace-on-avalanche',
    title: 'How to Create Your NFT Marketplace on Avalanche',
    description:
      ' The most popular form of cryptocurrencies, NFT - the non-fungible token has a massive market in the web3 space. New ideologies are coming in and forth into the market. The best part is their symbolic abilities and the transparency maintained in the scene.',
    link: {
      href: '/create-your-nft-marketplace-on-avalanche',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-marketplace-development-solana.jpg',
    imgAlt: 'nft-marketplace-development-solana',
    title: 'How to create NFT marketplace in Solana',
    description:
      'The market is superfluous with digital expansion. Every business, out in the world, every nook and corner, someone or the other is exploring the abilities of web3. Undoubtedly NFT has a huge capability in space.',
    link: {
      href: '/nft-marketplace-development-solana',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/create-metaverse-platform.png',
    imgAlt: 'create-metaverse-platform',
    title:
      'How to create your own metaverse platform -  BlockchainX Development Method',
    description:
      ' A single day is only wholly done with collaborating digitally, and we never consider things to pass well. Digitization and technological adaptations are not like the pills that we depend on for our day-to-day lifestyle.',
    link: {
      href: '/create-metaverse-platform',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/erc-1155.jpg',
    imgAlt: 'ERC1155 – Explore the ERC-1155 Token Standard',
    title: 'ERC1155 – Explore the ERC-1155 Token Standard',
    description:
      'If you understand what an non-fungible-token-development NFT is and that the abbreviation of "Non-fungible token" no longer confuses you, you are ready to delve deeper, which is necessary before learning about ERC-1155.',
    link: {
      href: '/erc1155-token-standard',
      text: 'Read More',
    },
    category:'Ethereum Token Development'
  },
  {
    imgSrc: 'assets-new/images/blog/everything-to-know-about-phygital-nft.jpg',
    imgAlt: 'Phygital NFTs',
    title:
      'What is Phygital NFTs? Blurring the Line between the Physical and Digital World',
    description:
      ' The term Phygital is hardly new, the Australian agency Momentum coined the phrase. Physical and digital combine together to form phygital, physical digital.',
    link: {
      href: '/guide-to-phygital-nfts',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/how-to-create-an-nft-marketplace.jpg',
    imgAlt: 'NFT Marketplace',
    title: 'How to Create NFT Marketplace? - Complete Development Process',
    description:
      'The Non-fungible tokens, NFTs are the major reason for the growing popularity and widespread attention of the blockchain technologies...',
    link: {
      href: '/how-to-create-an-nft-marketplace',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/technology-in-healthcare.jpg',
    imgAlt: 'Blockchain Healthcare Data Management',
    title: 'What is Blockchain Replication in Healthcare Data Management?',
    description:
      'Blockchain or ledger technology is an emerging technology being applied for creating innovative solutions in various sectors, including healthcare...',
    link: {
      href: '/healthcare-data-management',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-music.jpg',
    imgAlt: 'Things to explore about the music NFT marketplace',
    title: 'Things to explore about the music NFT marketplace',
    description:
      ' Music NFTs drastically change the way of modern music in many ways. Musicians and artists are now selling and getting benefits from their pieces of artwork in NFT auctions. ',
    link: {
      href: '/nft-music-marketplace',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-royal.jpg',
    imgAlt: 'NFT Royalties',
    title:
      'What are NFT Royalties and how do they benefit the business in 2023?',
    description:
      'When NFTs first emerged, one of the most exciting conversations was about how they would support artists...',
    link: {
      href: '/nft-royalties-and-how-they-work',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/how-does-auction-work-for-nfts.jpg',
    imgAlt: 'NFT Aution',
    title: 'What Is NFT Auction Sale And How Does It Work?',
    description:
      'The digital world has been highly captivating and ideally tracing a lot of signs up over the years, almost every day...',
    link: {
      href: '/nft-auctions',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockchain-role-ai-meet-the-world-metaverse.jpg',
    imgAlt: 'Blockchain Meet AI',
    title: 'Blockchain and the role of AI meet in the world of the Metaverse ',
    description:
      'The Metaverse is a combination of "meta," meaning "virtual, transcendence," and "verse,"a backformation from "universe.',
    link: {
      href: '/blockchain-role-ai-meet-the-world-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/metaverse-virtual-world.jpg',
    imgAlt: 'Metaverse Virtual World',
    title:
      'The Ultimate Guide on How to Build a Metaverse Virtual World in 2023',
    description:
      'If the last few decades were the internet era, the coming decades will be for the Metaverse. The Metaverse will make the virtual world more interactive, immersive, and collaborative than the internet. ',
    link: {
      href: '/how-to-create-your-own-metaverse-virtual-world',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc:
      'assets-new/images/blog/blockbuster-metaverse-platforms-of-2023.jpg',
    imgAlt: 'Top Ten Metaverse Platform ',
    title: 'Top Ten Metaverse Platform You Should Watch Out for in 2023 ',
    description:
      'As the world seen in dreams becomes a reality today, there is no doubt that the metaverse is the feature universe....',
    link: {
      href: '/best-metaverse-platform-to-know-about-in-2023',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/build-virtual-world.jpg',
    imgAlt: 'Unreal Engine Jotting Your Virtual World ',
    title: 'Unreal Engine Jotting Your Virtual World  ',
    description:
      'The creative nuances with endurance are the next stage of evolution, happening with Digitalisation and technology...',
    link: {
      href: '/build-virtual-world-with-unreal-engine',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/know-the-key-features-of-metaverse.jpg',
    imgAlt: 'Understanding Metaverse ',
    title:
      'Understanding Metaverse: Key features of the Metaverse you need to know in 2023',
    description:
      'The new world of the metaverse has many meanings and many definitions. It is amazing that some of the things that started as jokes are becoming a part of our lives.',
    link: {
      href: '/know-the-key-features-of-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-practices.jpg',
    imgAlt: 'NFT Security Tips and Best in 2023',
    title: 'A Complete Guide to Follow - NFT Security Tips and Best in 2023',
    description:
      'NFTs have caught the attention of both crypto experts and new users. Non-fungible tokens popularity raises after they were released to the general market in 2021.',
    link: {
      href: '/nft-security-tips-and-best-practices',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-like-cryptopunks.jpg',
    imgAlt: 'CryptoPunks',
    title: 'Create and Pile Up Your Own NFT Collections like CryptoPunks',
    description:
      '2021 was a revolutionary year of opportunities for art lovers, artists, and musicians in the digital world. Many of the NFTs have been released in the first week of the year',
    link: {
      href: '/create-nfts-like-cryptopunks',
      text: 'Read More',
    },
    category:'crypto'
  },
  {
    imgSrc: 'assets-new/images/blog/static-vs-dynamic-nfts.jpg',
    imgAlt: 'Static Vs Dynamic NFTs',
    title: 'Explore the major distinction between Static and Dynamic NFTs',
    description:
      'Non-fungible tokens (NFTs) are making their way into the mainstream after widespread adoption in the Web3 community...',
    link: {
      href: '/static-nfts-vs-dynamic-nfts',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchain-platforms.png',
    imgAlt: 'Blockchain Platforms',
    title: 'The Top Blockchain Platforms to explore in 2023',
    description:
      'Almost every industry in the world is being transformed by blockchain technology. In fact, just a few years ago, most people...',
    link: {
      href: '/top-blockchain-platforms-explore-in-2023',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/multifunctional-nft.jpg',
    imgAlt: 'MultiFunctional NFT',
    title: 'The Complete Guide to Creating Multifunctional Fractionalized NFTs',
    description:
      'NFTs are a huge hit right now and will only get bigger in the coming years. Many entrepreneurs from various industries have been enticed to invest in...',
    link: {
      href: '/multifunctional-fractionalization-nft',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/defi-platforms-to-consider-before-2023.png',
    imgAlt: 'DeFi Platforms',
    title: 'Best DeFi Platforms To Look Up Before 2023',
    description:
      'Decentralization in recent years brought in revolutionizing transformation in the ecosystem. Decentralized finances have a primary role to play here...',
    link: {
      href: '/best-defi-platforms-to-consider-before-entering-2023',
      text: 'Read More',
    },
    category:'defi'
  },
  {
    imgSrc: 'assets-new/images/blog/everything-about-nft-metaverse.jpg',
    imgAlt: 'Metaverse NFT Marketplace',
    title:
      'Explore the opportunities in virtual space at the Metaverse NFT Marketplace',
    description:
      'Have you noticed how much the world has changed over the last few years? Being a successful entrepreneur used to imply expanding...',
    link: {
      href: '/everything-about-nft-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc:
      'assets-new/images/blog/major-mistakes-to-cuttoff-in-nft-marketplace.jpg',
    imgAlt: 'NFT Marketplace',
    title: '6 Mistakes You Should Avoid When Developing an NFT Marketplace',
    description:
      'The NFT marketplace has gained popularity in recent times due to the proliferation of NFTs. NFT marketplace is a virtual place where you can trade, buy or sell digital tokens...',
    link: {
      href: '/6-mistakes-you-should-avoid-developing-nft-marketplace',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/metaverse-development-tools.jpg',
    imgAlt: 'Metaverse Developing Tools',
    title: 'Top 7 Enduring Technologies For Metaverse',
    description:
      'Metaverse is nothing new to us. Keeping technicalities aside, there is in everyone unsung dreams and imagination...',
    link: {
      href: '/7-enduring-technologies-for-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/metaverse-use-case.jpg',
    imgAlt: 'Case Benefits of Metaverse',
    title: '10 Best Use Cases And Benefits Of Metaverse',
    description:
      'All of us would have fantasized our kinda world and wondered about the endless possibilities with all the abilities to tune it for our ride...',
    link: {
      href: '/metaverse-use-case-and-benefits',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc:
      'assets-new/images/blog/how-to-create-your-own-nft-marketplace-2023.jpg',
    imgAlt: 'NFT Marketplace',
    title: 'How to Create your own NFT Marketplace in 2023',
    description:
      'You must have been wondering about this breakthrough with a significant increase in the digital economy from 2014 to 2022 ! Entrepreneurs are thriving with the launch of NFT and pondering the deployment of blockchain platforms...',
    link: {
      href: '/how-to-create-your-own-nft-marketplace-in-2023',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-staking.png',
    imgAlt: 'NFT Stacking',
    title:
      'Here what you need to know about NFT Stacking and how to earn money from it',
    description:
      'NFTs (Non-Fungible Tokens) are unique cryptographic tokens that exist on a blockchain network and cannot be replicated...',
    link: {
      href: '/what-is-nft-staking',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/what-is-iso-20022-banner.jpg',
    imgAlt: 'What is ISO 20022',
    title:
      'What Is ISO 20022 : How Will This New Data Standard Impact Your Crypto Industry?',
    description:
      'The COVID-19 pandemic changed many aspects of our lives, including how we shop and with that, transactions over digital platforms skyrocketed beyond what anyone could have imagined..',
    link: {
      href: '/what-is-iso-20022',
      text: 'Read More',
    },
    category:'crypto'
  },
  {
    imgSrc: 'assets-new/images/blog/stellar-consensus-protocol.jpg',
    imgAlt: 'Stellar Consensus Protocol',
    title: 'Detailing On Stellar Consensus Protocol',
    description:
      'Consensus is present in all aspects of life, amongst the human race, and, of course, the digital space cannot deny its dependence...',
    link: {
      href: '/stellar-consensus-protocol',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/solana.jpg',
    imgAlt: 'Stellar Consensus Protocol',
    title: 'Solana: The world first blockchain to use proof of history',
    description:
      'When comparing the prominent Ethereum blockchain, Salona is very fast and is the most high-throughput blockchain in the market. Let your next project start with Solana, a fast and innovative blockchain...',
    link: {
      href: '/solana-proof-of-history',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/dao.jpg',
    imgAlt: 'DAO in NFT',
    title: 'What is a DAO, and How does it relate to DAO in NFTs',
    description:
      'Spend any amount of time in the cryptoverse, and you will hear a lot about DAOs.From DAOs aiming to buy the US Constitution to NFT projects...',
    link: {
      href: '/dao-in-nft',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/metaverse-in-real-state.jpg',
    imgAlt: 'Metaverse in real estate',
    title: 'Metaverse In Real Estate: Complete In And Out',
    description:
      'The market is overflooded with a variety of projects and businesses that flex the benefits of Digitalization. Eventually, we also see the wider growth of Decentralization in the sector...',
    link: {
      href: '/metaverse-in-real-estate',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/hashgraph-vs-blockchain.png',
    imgAlt: 'Hashgraph vs Blockchain',
    title: 'HASHGRAPH VS BLOCKCHAIN – A Quick detailed Comparison',
    description:
      'Many blockchain alternatives are emerging as the world moves toward extremely transparent and highly robust distributed ledger technology (DLTs)...',
    link: {
      href: '/hashgraph-vs-blockchain',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/polkadot-parachain.jpg',
    imgAlt: 'Polkadot Parachain',
    title: 'Everything you need to know about Polkadot Parachain',
    description:
      'Starting with Bitcoin, the crypto world is now blooming with blockchain networks such as Ethereum, Corda, Cardano, Tron, Hyper-Ledger, Polkadot, etc. Among these, the Polkadot is everyone favorite as like its name...',
    link: {
      href: '/everything-you-need-to-know-polkadot-parachain',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/rust-in-blockchain.jpg',
    imgAlt: 'Rust In Blockchain',
    title: 'Implementations of Rust For Blockchain Networks',
    description:
      'Over the years, there is a massive evolution happening in the field of technology with newness spreading all over...',
    link: {
      href: '/implementation-of-rust-for-blockchain-network',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/defi-vs-cefi.png',
    imgAlt: 'DeFi vs CeFi',
    title:
      'DeFi vs CeFi : What are they and which one is suitable for your business',
    description:
      'Bitcoin and other digital currencies have created an entire ecosystem around blockchain technology. So the world argues that decentralized financial solutions will eventually replace traditional financial services...',
    link: {
      href: '/defi-vs-cefi',
      text: 'Read More',
    },
    category:'defi'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchain-in-healthcare.jpg',
    imgAlt: 'Blockchain In Healthcare',
    title:
      'What Are The Benefits Of Blockchain In the Healthcare Industry? - Discovering Its Wider Use Cases',
    description:
      'Technology is transforming to be the mother of livelihood. From being a collectively evolving embodiment, there have been amazing insights in the field of technology popping up...',
    link: {
      href: '/benefits-of-blockchain-in-healthcare',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/automotive-sector.jpg',
    imgAlt: 'Blockchain in Automotive Sector',
    title:
      'How Blockchain can solve the Top 3 problems in the Automotive Sector',
    description:
      'Most enterprises leverage the potential of blockchain technology. Its achievements are beyond our words, including facilitating...',
    link: {
      href: '/blockchain-solves-top-three-problems-in-automotive-sector',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/financial-service-industry.jpg',
    imgAlt: 'Blockchain in Financial Services',
    title:
      'Impact of Blockchain in Financial Services - Innovations Transform Industry',
    description:
      ' We all know that blockchain technology is reshaping the digital world. Its impact is noticeable in all industries, of which the financial services industry needs to be highlighted more...',
    link: {
      href: '/blockchain-innovations-can-transform-financial-industry',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchain-networks.jpg',
    imgAlt: 'Blockchain In Government Organizations',
    title: 'Blockchain In Government Organizations Is Bonafide With Triumph',
    description:
      'Digital sectors witnessing buzzing attention in the recent time. Apart from the fall in crypto value, there is a big revolution evolving silently in the global ecosystems...',
    link: {
      href: '/government-new-infrastructure-in-blockchain-network',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/telecom.jpg',
    imgAlt: 'Blockchain In Telecom Industry',
    title: 'Top 5 Trending Blockchain Use Cases In the Telecom Industry',
    description:
      ' As blockchain technology has become mainstream in recent years, it is changing the world completely. A great achievement has been achieved in many fields, especially the financial sector.',
    link: {
      href: '/five-blockchain-usecase-make-changes-in-telecom-industry',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/nine-nft.jpg',
    imgAlt: 'Top Nine Insightful Things in NFT Marketplace',
    title:
      'The Top Nine Insightful Things to Consider Before Starting an NFT Marketplace',
    description:
      ' NFT marketplace development company can help you create the ideal platform for your non-fungible tokens and NFTs are becoming popular...',
    link: {
      href: '/nft-market-place-insights',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/digital-identities.jpg',
    imgAlt: 'Blockchain Identity Management',
    title:
      'How Influencial Is Blockchain In Identity Managements In The Digital Era?',
    description:
      ' Each of us has our identity, not just us, from something Im typing out here to that even doesn’t exist has their kinda identifications for their existence in this so-called space...',
    link: {
      href: '/blockchain-influences-identity-management',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-gaming-feature.jpg',
    imgAlt: 'NFT Gaming',
    title: 'Know the Future of NFTs in the gaming world',
    description:
      ' We are sure that by this point, you have heard about NFTs. While some think NFTs are just a passing trend that..',
    link: {
      href: '/nft-gimmick-or-feature-of-gaming',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/adapt-invest-explore-metaverse.png',
    imgAlt: 'Invest Metaverse',
    title:
      'What can become reality? The Metaverse : A shared Virtual Environment',
    description:
      ' The Metaverse is undoubtedly a fancy word, but it includes much more. Get ready for a world of lights and noises, a reality that is different from your present one, and an internet future...',
    link: {
      href: '/adapt-invest-explore-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/web3-feature.jpg',
    imgAlt: 'Web3 Feature',
    title:
      'Everything you need to know about web3 - Is web3 the future of the internet?',
    description:
      ' The internet and its metamorphosis have never failed to capture public attention. A few years ago , no one could have predicted that virtual worlds would become a reality one day...',
    link: {
      href: '/everything-you-need-to-know-about-web3',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/comparision-of-sol-poly-eth.jpg',
    imgAlt: 'Solana-polygon-eth',
    title: 'Explore the detailed comparison of Solana Vs Polygon Vs Ethereum ',
    description:
      ' A few years ago, no one thought about how blockchain and cryptocurrency would change the world...',
    link: {
      href: '/comparison-of-solana-polygon-ethereum',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc:
      'assets-new/images/blog/metaverse-business-oppertunities-2022.jpg',
    imgAlt: 'Metaverse Business',
    title: 'Top 5 Profitable Metaverse Business opportunities in 2022',
    description:
      ' Technology in innovation is one of the formidable aspects of supporting business leaders in generating new products or business adaptations...',
    link: {
      href: '/profitable-metaverse-business-opportunities',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/primary-technologies-in-metaverse.jpg',
    imgAlt: 'Invest Metaverse',
    title: 'Know about the primary Technologies that power the Metaverse',
    description:
      'The Metaverse is one of the popular concepts that revolve around virtual objects and lands as well as 3D digital worlds...',
    link: {
      href: '/primary-technologies-in-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/10-big-brands-jumping-on-the-NFT.jpg',
    imgAlt: 'Ten big brands in NFT',
    title: '10 Big Brands Jumping On The NFT',
    description:
      ' NFTs (Non-Fungible Tokens) are one of the largest trends in the industry right now and are set to become an important performer in the trillion-dollar marketing industry by 2022...',
    link: {
      href: '/ten-big-brands-jumping-on-the-nft',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-vulnerability-and-security.jpg',
    imgAlt: 'NFT Vulnerability and Security',
    title: 'Know about NFTs Vulnerability and Security measures',
    description:
      ' NFTs, or non-fungible tokens, have caught the attention of consumers and businesses around the world. In a very short period, NFTs have gained huge popularity and have become...',
    link: {
      href: '/nft-vulnerability-and-security',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-in-sports-platform.jpg',
    imgAlt: 'NFT in Sports Platform',
    title: 'Complete Guide to NFT Sports Platforms development in 2022',
    description:
      ' Sports is something that has a great fan following across the globe. Therefore, the world celebrates and cheers the team on the success of their favorite team whereas they...',
    link: {
      href: '/nft-sports-platforms-development',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/flow-blockchain-ethereum.jpg',
    imgAlt: 'Ethereum flow in blockchain',
    title: 'Flow Blockchain Vs. Ethereum. Which Is Better For NFT Development?',
    description:
      ' Non-fungible tokens are blooming today, with even celebrities rushing to assign their songs, videos, photos, or even tweets as NFTs. The new concept promotes popularity to blockchain and...',
    link: {
      href: '/flow-blockchain-and-ethereum',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/women-role-blockchain.webp',
    imgAlt: 'Invest Metaverse',
    title: 'How can blockchain empower and enrich women lives in 2022?',
    description:
      ' When we look at the statistics, half of the employees of Binance are women. And in India, women take lead in the largest number of crypto investors...',
    link: {
      href: '/how-can-blockchain-empower-and-enrich-women',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-role-metaverse.jpg',
    imgAlt: 'NFT Role in Metaverse',
    title: 'NFTs And Their Role In The “Metaverse”',
    description:
      ' Many people will recognize NFTs merely as images of digital artworks or collectibles that they can sell for huge prices. However, this digital art in present times has brought up various new...',
    link: {
      href: '/nfts-and-their-role-in-the-metaverse',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-in-defi.jpg',
    imgAlt: 'Invest Metaverse',
    title: 'How are NFTs used In DeFi (Decentralized Finance)?',
    description:
      ' The two most important things in the existing crypto market are DeFi and NFT. Decentralized finance and non-fungible tokens are presently the two most prominent applications in...',
    link: {
      href: '/how-are-nfts-used-in-defi',
      text: 'Read More',
    },
    category:'defi'
  },
  {
    imgSrc: 'assets-new/images/blog/nft-market-place.jpg',
    imgAlt: 'NFT Marketplace',
    title: 'NFT Marketplace: Everything You Need To Know',
    description:
      ' The popularity of online marketplaces for digital collectibles is rising day by day. Now, NFT marketplaces are streaming the most wanted platforms in the crypto world....',
    link: {
      href: '/nft-marketplace-everything-you-need-to-know',
      text: 'Read More',
    },
    category:'nft'
  },
  {
    imgSrc: 'assets-new/images/blog/future-nft-applications-challenges.jpg',
    imgAlt: 'NFT Past, Present, Feature',
    title:
      'NFTs, the sensation that overcame the the stigma of crypto “Hype”: Past, Present, and Future',
    description:
      ' A new sensation in the blockchain world is so-called NFTs or non-fungible tokens. NFTs (non-fungible tokens) are very effective...',
    link: {
      href: '/future-nft-applications-challenges',
      text: 'Read More',
    },
    category:'crypto'
  },
  {
    imgSrc: 'assets-new/images/blog/substrate-01.webp',
    imgAlt: 'Know everything about substrate',
    title: 'Know everything about substrate',
    description:
      ' The substrate is a framework for the quick creation of complete blockchains and decentralized applications (dApps). It is an opensource technology used to build...',
    link: {
      href: '/know-everything-about-substrate',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/what-is-gamefi.jpg',
    imgAlt: 'Blockchain Games',
    title: 'What is GameFi? Diving into the world of Blockchain Games',
    description:
      ' From the earlier days of the video game industry, developers have aimed to create worlds as powerful as real life. While technological growth in graphics, computation, and games still...',
    link: {
      href: '/what-is-gamefi',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/metaverse-top-projects.jpg',
    imgAlt: 'Top 5 Metaverse Projects',
    title: 'Top 5 Metaverse Projects to keep on your radar in 2022',
    description:
      ' Metaverse is a developing sector and its a virtual world where we can associate, shop, work, visit art galleries and attend concerts many of the things we can do in the real world....',
    link: {
      href: '/top-5-metaverse-projects',
      text: 'Read More',
    },
    category:'metaverse'
  },
  {
    imgSrc: 'assets-new/images/blog/polkadot.jpg',
    imgAlt: 'Polkadot Technology',
    title: 'Polkadot, pushing the frontiers of blockchain technology',
    description:
      ' As we step into the next generation of blockchain technology, we have an extremely scalable and highly secured blockchain network called Polkadot...',
    link: {
      href: '/what-is-polkadot-blockchain-technology',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/top-defi-projects-2020.jpg',
    imgAlt: 'Top 6 DeFi Projects of 2020',
    title:
      'Top 6 DeFi Projects of 2020: The Hype, The Revolution, and Autonomy',
    description:
      'Defi has been grabbing a lot of eyeballs lately. In just two years, the projects have reaped their rewards of hard work. The tremendous...',
    link: {
      href: '/ethereum-defi-decentralized-projects',
      text: 'Read More',
    },
    category:'defi'
  },
  {
    imgSrc: 'assets-new/images/blog/use-cases-of-defi.jpg',
    imgAlt: 'Use Cases of Decentralized Finance',
    title: 'Major Use Cases of Decentralized Finance (DeFi) -BlockchainX',
    description:
      ' DeFi is the new contender in the fintech space and is gaining a tremendous amount of traction. The term DeFi means Decentralized ...',
    link: {
      href: '/decentralized-finance-defi-use-cases',
      text: 'Read More',
    },
    category:'defi'
  },
  // {
  //   imgSrc: 'assets-new/images/blog/bitcoin-2021.jpg',
  //   imgAlt: 'Bitcoin Investment',
  //   title: 'Why Bitcoin is still a good investment idea in 2021',
  //   description:
  //     ' Time in a time again, people we work with keep coming back to this question, asking us “Is Bitcoin a good investment still...',
  //   link: {
  //     href: '/how-to-invest-in-bitcoin',
  //     text: 'Read More',
  //   },
  //   category:'nft'
  // },
  {
    imgSrc: 'assets-new/images/blog/defi.webp',
    imgAlt: 'What is DeFi',
    title:
      'What can become reality? The Metaverse : A shared Virtual Environment',
    description:
      'Defi has been trending for a while now. The term has created a buzz in the financial market within its two years of launch. The results have been glorious enough to attract...',
    link: {
      href: '/what-is-defi-centralized-finance',
      text: 'Read More',
    },
    category:'defi'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchain-for-government.jpg',
    imgAlt: 'Blockchain in Government',
    title:
      'Blockchain in Government: A Step Towards a Better Tomorrow Bitcoin is still a good investment idea in 2021',
    description:
      ' The application of blockchain in government and public services can solve the problems that have been haunting the system for a long time...',
    link: {
      href: '/blockchain-for-government',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchainDtl.webp',
    imgAlt: 'Distributed Ledger',
    title:
      'Understanding the difference between Blockchain vs Distributed Ledger',
    description:
      ' Ever since the popularity of Bitcoin which started back in 2007, the concept of Blockchain chain technology has been one of the major trends in the tech world...',
    link: {
      href: '/blockchain-vs-distributed-ledger-technology',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/are-there-any-types-in-blockchain.png',
    imgAlt: 'Types of Blockchain ',
    title: '4 Types of Blockchain You Should Know About',
    description:
      'Blockchain has caused quite a buzz in the technological world. Although its first-ever application — Bitcoin — came to existence in 2009...',
    link: {
      href: '/types-of-blockchain',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/blockchain.jpg',
    imgAlt: 'Blockchain vs bitcoin',
    title: 'Difference between blockchain and bitcoin',
    description:
      ' Are you aware of the fact that blockchain and bitcoin are closely interrelated but they are the same thing...',
    link: {
      href: '/blockchain-vs-bitcoin',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/blog/9-myths-blockchain.png',
    imgAlt: '9 myths about blockchain',
    title: '9 Myths About Blockchain',
    description:
      ' Ever since the famous Bitcoin raised the urge for people to trade with digital currency, there has been a simultaneous escalation in the technology behind its success.',
    link: {
      href: '/9-myths-about-blockchain-technology',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/industries/blockchain-in-health-care.jpg',
    imgAlt: 'Blockchain in Healthcare',
    title: 'Blockchain in Healthcare',
    description:
      ' Blockchain technology is one of the most crucial and unruly technologies which industries are willing to adopt. The healthcare industry is pretty...',
    link: {
      href: '/blockchain-application-in-healthcare',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc:
      'assets-new/images/industries/blockchain-in-finance-and-marketing.jpg',
    imgAlt: 'Blockchain Technology in Banking and Finance',
    title: 'Blockchain Technology in Banking and Finance',
    description:
      ' In the year 2008, Blockchain came into existence. Bitcoin was the first digital cryptocurrency. As time passes by innumerable industries are recognizing the advantages of blockchain technology.',
    link: {
      href: '/blockchain-technology-in-banking-and-finance',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/industries/blockchain-in-insurance.jpg',
    imgAlt: 'Blockchain In Insurance ',
    title: 'Blockchain In Insurance',
    description:
      ' Insurance is a process that has been around since time immemorial at one level or another. Almost every generation has used...',
    link: {
      href: '/blockchain-in-insurance',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/industries/Blockchain-in-supplychain.jpg',
    imgAlt: 'What is Supply Chain Management',
    title: 'What is Supply Chain Management',
    description:
      ' A supply chain, in easier words, is called a distribution system. Supply chain management deals with planning the transit of the...',
    link: {
      href: '/what-is-supply-chain-management',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/industries/Blockchain-in-retail.jpg',
    imgAlt: 'Invest Metaverse',
    title: 'Blockchain In Retail',
    description:
      'The retail industry is responsible for making the product meet its final consumer. As simple as it sounds, the retail industry faces a lot of struggles like tight margins...',
    link: {
      href: '/blockchain-in-retail',
      text: 'Read More',
    },
    category:'blockchain'
  },
  {
    imgSrc: 'assets-new/images/industries/blockchain-in-gaming.jpg',
    imgAlt: 'Invest Metaverse',
    title: 'Blockchain In Game Development',
    description:
      ' Mario video games in the year 1981 became very famous among the game lovers by the name of Jumpman. In today’s world the Donkey Kong became popular globally and is considered as one...',
    link: {
      href: '/blockchain-game-development',
      text: 'Read More',
    },
    category:'blockchain'
  },
];
