<header>
  <!-- <div class="topbar">
    <div class="container-fluid">
          <div class="topbar-content">
            <div class="d-flex align-items-center">
              <p><a href="https://calendly.com/sparkouttech" target="_blank"
                rel="noopener noreferrer">Meet our <span>leadership</span> <br/>
                team at</a></p>
                <a href="https://calendly.com/sparkouttech" target="_blank"
                rel="noopener noreferrer">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/gitex-logo.webp" />

                    <source type="image/png" srcset="assets-new/images/gitex-logo.png" />

                    <img loading="lazy" data-src="assets-new/images/gitex-logo.png" width="128" height="51" alt="gitex logo" title="gitex logo" class="img-fluid" />
                  </picture> </a>
            </div>
            <h5><a href="https://calendly.com/sparkouttech" target="_blank"
                rel="noopener noreferrer">16<sup>th</sup> - 20<sup>th</sup> Oct 2023  <span>|</span> Booth No. H14-C20 (Hall 14) <span>|</span> Dubai World Trade Centre</a></h5>
            <a class="btn about-us" href="https://calendly.com/sparkouttech" target="_blank"
                rel="noopener noreferrer">Book a Call</a>
          </div>
    </div>
  </div> -->
  <nav class="navbar navbar-expand-lg" id="navbar_top">
    <div class="container-fluid px-0" id="header-full-width">
      <a class="navbar-brand me-0 logo py-0 d-block" [routerLink]="['/']">
        <img appLazyLoad="assets-new/images/logo-white.webp" width="240" height="74" alt="BlockchainX" class="img-fluid" *ngIf="!isDarkmode">
        <!-- <img
        loading="lazy"
          src="assets-new/images/logo-white.png"
          width="160"
          height="49"
          alt="BlockchainX"
          class="img-fluid"
          *ngIf="!isDarkmode"
        /> -->
        <img appLazyLoad="assets-new/images/logo-dark.webp" width="240" height="74" alt="BlockchainX" class="img-fluid" *ngIf="isDarkmode">
        <!-- <img
        loading="lazy"
          src="assets-new/images/logo-dark.png"
          width="160"
          height="50"
          alt="BlockchainX"
          class="img-fluid"
          *ngIf="isDarkmode"
        /> -->
        <!-- <img
        loading="lazy"
          src="assets-new/images/logo-dark.png"
          width="160"
          height="50"
          alt="BlockchainX"
          class="img-fluid light-dark-theme-logo"
        /> -->
      </a>

      <button
        class="btn btn-primary navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        aria-label="menu-button"
      >
        <span class=""><i class="bi bi-list"></i></span>
      </button>
      <div
        class="offcanvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <div id="offcanvasRightLabel">
            <a
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              class="navbar-brand"
              [routerLink]="['/']"
            >
              <img
                src="assets-new/images/logo-white.webp"
                width="160"
                height="49"
                alt="BlockchainX"
                class="img-fluid w-100"
                *ngIf="!isDarkmode"
              />
              <img
                src="assets-new/images/logo-dark.webp"
                width="160"
                height="49"
                alt="BlockchainX"
                class="img-fluid w-100"
                *ngIf="isDarkmode"
              />
            </a>
          </div>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
              <!-- Token -->
              <li
                class="nav-item dropdown"
                routerLinkActive
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tokens</a
                >
                <ul class="dropdown-menu dw-menu3 token-menubar">
                  <div class="row">
                    <div class="col-lg-4">
                      <ul>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/stablecoin-development-services/"
                            target="_blank"
                            class="dropdown-item"
                            >Stablecoin Development</a
                          >
                        </li>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/erc20-token-development/"
                            target="_blank"
                            class="dropdown-item"
                            >ERC20 Token</a
                          >
                        </li>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/bep20-token-development/"
                            target="_blank"
                            class="dropdown-item"
                            >BEP20 Token</a
                          >
                        </li>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/base-token-development/"
                            target="_blank"
                            class="dropdown-item"
                            >Base Token</a
                          >
                        </li>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/asset-backed-token-development/"
                            target="_blank"
                            class="dropdown-item"
                            >Asset Backed Token</a
                          >
                        </li>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/rebase-token-development/"
                            target="_blank"
                            class="dropdown-item"
                            >Rebase token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/meme-coin-development/"
                          target="_blank"
                            class="dropdown-item"
                            >Meme Coin Development</a
                          >
                        </li>
                      </ul>
                      </div>
                      <div class="col-lg-4">
                      <ul>
                        <li>
                          <a
                            href="https://www.blockchainx.tech/solana-meme-coin-development/"
                            target="_blank"
                            class="dropdown-item"
                            >Solana Meme Coin</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/gaming-utility-token-development/"
                          target="_blank"
                          class="dropdown-item"
                            >Gaming Utility Token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/gaming-governance-token-development/"
                          target="_blank"
                          class="dropdown-item"
                            >Gaming Governance Token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/brc20-token-development/"
                          target="_blank"
                          class="dropdown-item"
                            >BRC20 Token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/cat-themed-coins-development/"
                          target="_blank"
                          class="dropdown-item"
                            >Cat-Themed Coin</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/liquid-staking-platform-development/"
                          target="_blank"
                          class="dropdown-item"
                            >Liquid Staking Token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/pump-fun-clone-script/"
                          target="_blank"
                            class="dropdown-item"
                            >Pump Fun Clone Script</a
                          >
                        </li>
                      </ul>
                      </div>
                      <div class="col-lg-4">
                      <ul>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/politifi-token-development/"
                          target="_blank"
                            class="dropdown-item"
                            >Politifi Token Development</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/tron-token-development/"
                          target="_blank"
                            class="dropdown-item"
                            >TRC20 Token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/ico-development/"
                          target="_blank"
                            class="dropdown-item"
                            >ICO Coin</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/security-token-offering-services/"
                          target="_blank"
                            class="dropdown-item"
                            >STO Coin</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/token-development-company/"
                          target="_blank"
                            class="dropdown-item"
                            >Token Development</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/exchange-token-development/"
                          target="_blank"
                            class="dropdown-item"
                            >Exchange Token</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/ai-meme-coin-development-company/"
                          target="_blank"
                            class="dropdown-item"
                            >Ai Meme Coin Development</a
                          >
                        </li>
                      </ul>
                      </div> 
                    </div>
                </ul>
              </li>
              

              <!-- Dapps -->
              <li
                class="nav-item dropdown"
                routerLinkActive
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                Dapps</a
                >
                <ul class="dropdown-menu dw-menu3">
                  <div class="row">
                    <div class="col-lg-4">
                      <ul >
                        <div class="h-4">Token</div>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://www.blockchainx.finance/token-generator/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >ERC Token Generator</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/ready-made-ico-software/"
                          target="_blank"  
                            class="dropdown-item"
                            >Token Sale Platform
                          </a>
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/token-migration/"
                          target="_blank"  
                            class="dropdown-item"
                            >Token Migration
                          </a>
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/bridge-smart-contract-development/"
                          target="_blank"  
                            class="dropdown-item"
                           >Bridge Platform</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/uniswap-clone-script/"
                          target="_blank"  
                            class="dropdown-item"
                            >Uniswap Clone
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://www.blockchainx.finance/airdrop/"
                            target="_blank"
                rel="noopener noreferrer"
                            >Token Airdrop</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/white-label-launchpad-development/"
                          target="_blank"  
                            class="dropdown-item"
                            >Token Launchpad
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-4">
                      <ul>
                        <div class="token-tab h-4" style="visibility: hidden;">Token</div>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/defi-staking-platform/"
                          target="_blank"  
                            class="dropdown-item"
                            >DeFi Staking Platform
                          </a>
                        </li>
                        <!-- <li>
                          <a
                            class="dropdown-item"
                            [routerLink]="['/blockchain-lending-software-using-defi']"
                            >DeFi Lending Platform
                          </a>
                        </li> -->
                        <li>
                          <a
                          href="https://www.blockchainx.tech/defi-yield-farming-development/"
                          target="_blank"  
                            class="dropdown-item"
                            >DeFi Yield Farming
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://devuser.blockchainx.finance/locker/"
                            target="_blank"
                rel="noopener noreferrer"
                            >Token/LP Locker</a
                          >
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/cryptocurrency-wallet-development/"
                          target="_blank"  
                            class="dropdown-item"
                            >Crypto Wallet App
                          </a>
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/dao-development-company/"
                          target="_blank"  
                            class="dropdown-item"
                            >DAO Development
                          </a>
                        </li>
                        <li>
                          <a
                          href="https://www.blockchainx.tech/cryptocurrency-exchange-software-development/"
                          target="_blank"  
                            class="dropdown-item"
                            >Crypto Exchange Development
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-4">
                      <ul>
                        <div class="h-4">NFTs</div>
                  <li>
                    <a
                      href="https://www.blockchainx.tech/nft-staking-platform-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >NFT Staking
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.blockchainx.tech/nft-marketplace-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >NFT Marketplace</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="https://devuser.blockchainx.finance/nft-single-mint"
                      target="_blank"
                      >NFT Generator</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.blockchainx.tech/nft-gaming-platform-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >NFT Gaming Platform</a
                    >
                  </li>
                      </ul>
                    </div>
                  </div>



                </ul>
              </li>

              <!-- RWA -->
              <li
                class="nav-item dropdown"
                routerLinkActive
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  RWA</a
                >
                <ul class="dropdown-menu">
                  <li >
                    <a
                     href="https://www.blockchainx.tech/real-world-asset-tokenization/"
                      target="_blank"  
                      class="dropdown-item"
                      >Real World Asset Tokenization
                    </a>
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/real-estate-tokenization/"
                      target="_blank"  
                      class="dropdown-item"
                      >Real Estate Tokenization</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/gold-tokenization-development-company/"
                      target="_blank"  
                      class="dropdown-item"
                      >Gold Tokenization</a
                    >
                  </li>
                </ul>
              </li>

              <!-- Services -->
              <li
                class="nav-item dropdown"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services</a
                >
                <ul class="dropdown-menu dw-menu2">
                  <li >
                    <a
                      href="https://www.blockchainx.tech/non-fungible-token-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >NFT Development</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/web3-development-company/"
                      target="_blank"  
                      class="dropdown-item"
                      >Web3 Development</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/metaverse-development-company/"
                      target="_blank"  
                      class="dropdown-item"
                      >Metaverse Development</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/enterprise-blockchain-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >Enterprise Blockchain</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/smart-contract-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >Custom Smart Contract</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/crypto-currency-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >Crypto Currency Development</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/depin-development-company/"
                      target="_blank"  
                      class="dropdown-item"
                      >Depin Development Company</a
                    >
                  </li>
                </ul>
              </li>

              <!-- Enterprises -->
              <li
                class="nav-item dropdown"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Enterprise</a
                >
                <ul class="dropdown-menu dw-menu2">
                  <li >
                    <a
                     href="https://www.blockchainx.tech/hyperledger-blockchain-development-services/"
                      target="_blank"  
                      class="dropdown-item"
                      >Hyperledger Development</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/dapps-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >DAPPS development</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/hire-solidity-developers/"
                      target="_blank"  
                      class="dropdown-item"
                      >Solidity development</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/ipfs-blockchain/"
                      target="_blank"  
                      class="dropdown-item"
                      >IPFS Development</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/defi-development-company/"
                      target="_blank"  
                      class="dropdown-item"
                      >DeFi Development</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/corda-blockchain-development-services/"
                      target="_blank"  
                      class="dropdown-item"
                      >Corda Blockchain Development</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/blockchain-consulting-services/"
                      target="_blank"  
                      class="dropdown-item"
                      >Blockchain consulting</a
                    >
                  </li>
                </ul>
              </li>

              <!-- Industries -->
              <li
                class="nav-item dropdown"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Industries</a
                >
                <ul class="dropdown-menu dw-menu1">
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-application-in-healthcare/"
                      target="_blank"  
                      class="dropdown-item"
                      >Health Care</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-technology-in-banking-and-finance/"
                      target="_blank"  
                      class="dropdown-item"
                      >Finance/Investment</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-in-insurance/"
                      target="_blank"  
                      class="dropdown-item"
                      >Insurance</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-in-rental/"
                      target="_blank"  
                      class="dropdown-item"
                      >Rental</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/what-is-supply-chain-management/"
                      target="_blank"  
                      class="dropdown-item"
                      >Supply Chain</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-game-development/"
                      target="_blank"  
                      class="dropdown-item"
                      >Games Development</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-applications-agriculture/"
                      target="_blank"  
                      class="dropdown-item"
                      >Agriculture</a
                    >
                  </li>
                  <li >
                    <a
                      href="https://www.blockchainx.tech/blockchain-in-retail/"
                      target="_blank"  
                      class="dropdown-item"
                      >Retail</a
                    >
                  </li>
                </ul>
              </li>

              <!-- About -->
              <li
                class="nav-item dropdown"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About</a
                >
                <ul class="dropdown-menu">
                  <li >
                    <a
                     href="https://www.blockchainx.tech/about-us/"
                      target="_blank"
                      class="dropdown-item"
                      >About Us</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/blog/"
                      target="_blank"
                      class="dropdown-item"
                      >Blog</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/news/"
                      target="_blank"
                      class="dropdown-item"
                      >News</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/contact/"
                      target="_blank"
                      class="dropdown-item"
                      >Contact us</a
                    >
                  </li>

                  <li >
                    <a
                     href="https://www.blockchainx.tech/case-studies/"
                      target="_blank"
                      class="dropdown-item"
                      >Case Study</a
                    >
                  </li>
                  <!-- <li >
                    <a
                     href="https://www.blockchainx.tech/blockchain-in-retail/"
                      target="_blank" class="dropdown-item" [routerLink]="['/testimonial']"
                      >Testimonials</a
                    >
                  </li> -->
                  <li >
                    <a
                     href="https://www.blockchainx.tech/terms-and-conditions/"
                      target="_blank"
                      class="dropdown-item"
                      >Terms and conditions</a
                    >
                  </li>
                  <li >
                    <a
                     href="https://www.blockchainx.tech/privacy-policy/"
                      target="_blank"
                      class="dropdown-item"
                      >Privacy policy</a
                    >
                  </li>
                </ul>
              </li>

              <!-- <li
                >
                <div class="switches-main">
                  <div class="switches-container">
                    <input
                      type="radio"
                      id="light"
                      name="switchPlan"
                      value="light"
                      (click)="switchtheme()"

                    />
                    <input
                      type="radio"
                      id="dark"
                      name="switchPlan"
                      value="dark"
                      (click)="switchtheme()"
                      checked
                    />
                    <label for="light" class="light_moon"
                      ><i class="bi bi-brightness-high"></i
                    ></label>
                    <label for="dark" class="light_moon"
                      ><i class="bi bi-moon"></i
                    ></label>
                    <div class="switch-wrapper">
                      <div class="switch">
                        <div><i class="bi bi-brightness-high"></i></div>
                        <div><i class="bi bi-moon"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
              </li> -->

              <!-- <div class="d-flex align-items-center px-2">
                <div id="google_translate_element">
                  <div id=":0.targetLanguage">
                     <a aria-haspopup="true"  class="VIpgJd-ZVi9od-xl07Ob-lTBxed" href="#"></a> 
                    <i class="bi bi-translate"></i>
                  </div>
                </div>
              </div> -->
                
              <!-- Contact -->
              <li
                class="nav-item d-inline-flex align-items-center"
                id="menu-contact"
                >
                <a
                  class="nav-link"
                  title="contact"
                  data-bs-dismiss="offcanvas"
                  [routerLink]="['/contact']"
                >
                  Contact</a
                >
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>



<div class="social-share-main">
  <div class="share-icon" (click)="toggleView()">
    <i class="bi bi-share"></i>
  </div>

  <div class="sticky-social" [ngClass]="{'show': show}">
    <a href="mailto:contact@blockchainx.tech" target="_blank" rel="noopener noreferrer" class="social-blink" aria-label="contact-mail">
      <i class="bi bi-envelope"></i>
    </a>
    <a href="https://wa.me/7708889555" target="_blank" rel="noopener noreferrer" class="social-blink" aria-label="contact-whatsapp">
      <i class="bi bi-whatsapp"></i>
    </a>
    <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="social-blink" aria-label="contact-twitter">
      <i class="bi bi-telegram"></i>
    </a>
  </div>
</div>
