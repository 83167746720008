import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-real-estate',
  templateUrl: './real-estate.component.html',
  styleUrls: ['./real-estate.component.css']
})
export class RealEstateComponent implements OnInit {

  bitrixSnippetScript: SafeScript;

  title = 'Real Estate Tokenization Services';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService:CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {
    const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      // Perform a null check before calling parentNode.insertBefore
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
        // You can append the script to the <body> element or take another appropriate action.
        document.body.appendChild(s);
      }
    })(
      window,
      document,
      'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    )};</script>`;
    this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(
      `${scriptUrl}`
    );
  }

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Real Estate Tokenization Services is a fully customized end-to-end tokenization platform designed to improve asset management and create highly scalable business opportunities in the real estate industry.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Real estate tokenization development, Real estate tokenization services, Real estate tokenization development company, Real Estate Tokenization solutions, Real estate tokenization service, Create your own real estate tokenization, Launch your own Real Estate Tokenization, Develop your own Real Estate Tokenization, real estate tokenization platform development, real estate tokenization platform development company',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Real Estate Tokenization Services',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/real-estate-tokenization/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Real Estate Tokenization Services is a fully customized end-to-end tokenization platform designed to improve asset management and create highly scalable business opportunities in the real estate industry.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-estate-tokenization.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/real-estate-tokenization/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Real Estate Tokenization Services',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Real Estate Tokenization Services is a fully customized end-to-end tokenization platform designed to improve asset management and create highly scalable business opportunities in the real estate industry.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-estate-tokenization.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/real-estate-tokenization/'
    );
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}
