<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>P2E Gaming Governance </strong></span> Token
                Development
              </h1>
              <p>
                Web3 games are a great way to start your startup journey,
                leveraging a strong community of users. We help you launch your
                gaming governance token, turning your P2E games into a DAO
                ecosystem with superior user engagement.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/p2e-gaming/p2e-gaming.webp" />
              <source type="image/png" srcset="assets-new/images/p2e-gaming/p2e-gaming.png" />
              <img loading="lazy"  data-src="assets-new/images/p2e-gaming/p2e-gaming.png" width="500" height="500" class="img-fluid" alt="Gaming Governance Token Development" title="Gaming Governance Token Development" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/p2e-gaming/light-theme/p2e-gaming.webp" />
              <source type="image/png" srcset="assets-new/images/p2e-gaming/light-theme/p2e-gaming.png" />
              <img loading="lazy"  data-src="assets-new/images/p2e-gaming/light-theme/p2e-gaming.png" width="500" height="500" class="img-fluid" alt="Gaming Governance Token Development" title="Gaming Governance Token Development" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Gaming Governance Token"
              title="Gaming Governance Token"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <!-- <div class="metaverse_heading">
            <h3 class="pb-lg-5 pb-4 text-start"><span>Why is Gaming </span>Governance Token Important</h3>
          </div> -->
        <div class="whys_section mb-0">
          <h2 class="pb-lg-5 pb-4 text-start"><span>Why is Gaming
            </span>Governance Token Important</h2>
        </div>
        <div class="common-sub-heading">
          <p>
            Firstly, making a P2E game that’s fun to play is half the battle.
            The key is in bringing user engagement by giving users the right to
            make important decisions within your ecosystem. Secondly, this
            governance system has to be efficient, bug free, and tokenomically
            relevant. That’s where we come in.
          </p>
        </div>
        <!--   
          <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Alternates Payments in the future
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> More than a Crypto token
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Utilized in Crowdfunding 
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> MAximizes User Range
                </li>
              </ul>
            </div>
          </div> -->
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Trending ERC20 Token List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Gaming Governance Token Platforms"
              title="Gaming Governance Token Platforms"
              class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Some Popular
              Gaming</span> Governance Token Platforms</h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/axie-infinity">Axie
                    Infinity (AXS)</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/my-neighbor-alice">My
                    Neighbor Alice (ALICE)</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/apecoin">ApeCoin
                    (APE)</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/star-atlas-dao">Star
                    Atlas DAO (POLIS)</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/alien-worlds">Alien
                    Worlds (TLM)</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/heroes-of-mavia">Heroes
                    of Mavia (MAVIA)</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Gaming Governance Token </span>Development Service
          Highlights</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="DAO Consulting"
                title="DAO Consulting" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">DAO Consulting </h4>
          <p>
            Our Gaming Governance Token Development Service includes where you
            can leverage our expert team to create a stellar DAO economy.
          </p>
          <div class="content-link">
            <a [routerLink]="['/dao-development-company']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="NFT Marketplace"
                title="NFT Marketplace" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            NFT Marketplace
          </h4>
          <p>
            Sell your in-game items are NFTs on a customizable NFT Marketplace,
            fully branded to your project and build a strong community of
            collectors.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-marketplace-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Token Sale Dashboard"
                title="Token Sale Dashboard" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Sale Dashboard</h4>
          <p>
            Gaming projects are always expensive to build. Easily fundraise for
            your successful platform launch with our decentralized token sale
            dashboard.
          </p>
          <div class="content-link">
            <a [routerLink]="['/ready-made-ico-software']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="NFT Development"
                title="NFT Development" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">NFT Development</h4>
          <p>
            Create your NFTs in bulk and make your platform stand out with
            unique features for your NFTs through our Gaming Governance Token
            Development Services.
          </p>
          <div class="content-link">
            <a [routerLink]="['/non-fungible-token-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="UI/UX Design" title="UI/UX Design"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">UI/UX Design</h4>
          <p>
            User experience is the first step to great customer experience. Our
            expert team of designers help you create a user-centric UX and UI.
          </p>
          <div class="content-link">
            <a [routerLink]="['/ui-ux-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="NFT Marketplace"
                title="NFT Launchpad" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            NFT Launchpad
          </h4>
          <p>
            Sell your in-game collectibles as presale items even before you
            deploy them with our NFT Launchpad development services.
          </p>
          <div class="content-link">
            <a [routerLink]="['/white-label-launchpad-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-3">
          <h2>
            <span>Why Choose BlockchainX </span>for Gaming Governance Token
            Development
          </h2>
          <p>
            Since 2017, BlockchainX has been an industry leader in decentralized
            technologies, helping projects build user-centric blockchain
            products. Our experts can help you take your Gaming DAO from start
            to finish with the right solutions and consultation. Embark on your
            Gaming Governance Token Development journey with a world-leader in
            Web3 technologies.
          </p>
          <!-- <ul>
                <li>Expert technological consultants embarking possibility</li>
                <li>Experience developers with a heart to learn</li>
                <li>Breeding business across the globe</li>
                <li>24/7 support pre and post-development</li>
                <li>Transforming ideas into innovation</li>
              </ul> -->
          <div class="content-link my-3">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
        <!--<div class="whysection_text_wrap">
              
              <div class="content-link my-3">
                <a [routerLink]="['/contact']">
                  <span>Contact Us</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow"
                       />
                  </picture>
                </a>
                <hr />
              </div> 
            </div> -->
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Gaming Governance Token Development"
              title="Gaming Governance Token Development" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  How long does gaming governance token development take?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    You can launch your gaming governance token in 3-4 days.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  How are gaming governance tokens different from regular ERC20
                  tokens?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    While we use the ERC20 standard gaming governance token
                    development, these tokens can provide users with voting
                    rights inside your DAO.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Will any wallet support my gaming governance token?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    We use the ERC20 standard which means that your tokens will
                    run on any wallet that supports ERC20 tokens.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  Can I add additional features to my governance token?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Just like developing any ERC20 token, you can integrate
                    multiple features into your smart contracts with gaming
                    governance tokens development.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  How much does it cost to build a gaming governance token?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The cost for gaming governance token development depends on
                    the features you want to include. Connect with our expert
                    consultant to get started today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/blockchain-for-government']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/blockchain-for-government.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/blockchain-for-government.jpg"
                    width="344"
                    height="209"
                    alt="Blockchain in Government"
                    title="Blockchain in Government" />
                </picture>
                <h3>
                  Blockchain in Government: A Step Towards a Better Tomorrow
                  Bitcoin is still a good investment idea in 2021
                </h3>
                <p>
                  The application of blockchain in government and public
                  services can solve the problems that have....
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/blockchain-for-government']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-based-identity-management'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/blockchain-based-identity-management/blockchain-in-government-and-the-public-sector.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/blockchain-based-identity-management/blockchain-in-government-and-the-public-sector.jpg"
                    width="344"
                    height="209"
                    alt="Blockchain in Government and the Public Sector"
                    title="Blockchain in Government and the Public Sector" />
                </picture>
                <h3>
                  Blockchain in Government and the Public Sector - A Complete
                  Guide
                </h3>
                <p>
                  In the realm of a rapidly evolving digital landscape, the
                  government and public sector confront....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-based-identity-management'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/government-new-infrastructure-in-blockchain-network'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/blockchain-networks.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/blockchain-networks.jpg"
                    width="344"
                    height="209"
                    alt="Blockchain In Government Organizations"
                    title="  Blockchain In Government Organizations Is Bonafide With Triumph  " />
                </picture>
                <h3>
                  Blockchain In Government Organizations Is Bonafide With
                  Triumph
                </h3>
                <p>
                  Digital sectors witnessing buzzing attention in the recent
                  time. Apart from the fall in crypto value...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/government-new-infrastructure-in-blockchain-network'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Launch Your Gaming DAO
                Today</span></h3>
            <p>Connect with our Gaming Governance Token Development experts
              today and get started.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->