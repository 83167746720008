import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-nft-ticketing-holds-future-live-events',
  templateUrl: './nft-ticketing-holds-future-live-events.component.html',
  styleUrls: ['./nft-ticketing-holds-future-live-events.component.css'],
})
export class NftTicketingHoldsFutureLiveEventsComponent implements OnInit {
  title = 'NFT Ticketing Could Change the Future of Live Events. Here is how?';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Tokenizing event tickets on the blockchain is one of the most advanced use cases for NFTs to date. With so many events taking place globally daily, the potential for NFT ticketing is enormous. Many famous artists use NFTs as backstage passes, so NFT tickets will quickly become mainstream in the coming years.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'nft ticket marketplace, nft ticketing, nft ticket platform, nft as tickets, nft for tickets, nft for event tickets, nft ticketing solutions',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'NFT Ticketing Could Change the Future of Live Events. Here is how?',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/nft-ticketing-holds-future-live-events/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Tokenizing event tickets on the blockchain is one of the most advanced use cases for NFTs to date. With so many events taking place globally daily, the potential for NFT ticketing is enormous. Many famous artists use NFTs as backstage passes, so NFT tickets will quickly become mainstream in the coming years.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/revolutionizing-live-events-of-the-future.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/nft-ticketing-holds-future-live-events/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'NFT Ticketing Could Change the Future of Live Events. Here is how?',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Tokenizing event tickets on the blockchain is one of the most advanced use cases for NFTs to date. With so many events taking place globally daily, the potential for NFT ticketing is enormous. Many famous artists use NFTs as backstage passes, so NFT tickets will quickly become mainstream in the coming years.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/revolutionizing-live-events-of-the-future.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/nft-ticketing-holds-future-live-events/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/nft-ticketing-holds-future-live-events.html'
    );
    this.doc.head.appendChild(link);
  }
}
