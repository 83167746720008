import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-memestocks-surge-as-roaringkitty-returns',
  templateUrl: './memestocks-surge-as-roaringkitty-returns.component.html',
  styleUrls: ['./memestocks-surge-as-roaringkitty-returns.component.css']
})
export class MemestocksSurgeAsRoaringkittyReturnsComponent implements OnInit {

  title =
  'Roaring Kitties Setting Standard for new market revolution';
constructor(
  public router: Router,
  private titleService: Title,
  private meta: Meta,
  private canonicalService: CanonicalService,
  @Inject(DOCUMENT) private doc: Document
) {}
ngOnInit(): void {
  this.titleService.setTitle(this.title);
  this.meta.updateTag({
    name: 'description',
    content:
      "Have you ever thought of crypto tokens can be as influential they are today!? Roaring kitties token movement is creating new shifts and so many, check that out here.",
  });
  this.meta.updateTag({
    name: 'keywords',
    content: "Roaring Kitty Meme Coins,Roaring Kitties,Meme coins,Gamestops and AMC",
  });
  this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
  this.meta.updateTag({ name: 'robots', content: 'index, follow' });

  this.meta.updateTag({
    property: 'og:title',
    content:
      'Roaring Kitties Setting Standard for new market revolution',
  });
  this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
  this.meta.updateTag({
    property: 'og:url',
    content: 'https://www.blockchainx.tech/memestocks-surge-as-roaringkitty-returns/',
  });
  this.meta.updateTag({
    property: 'og:description',
    content:
      "Have you ever thought of crypto tokens can be as influential they are today!? Roaring kitties token movement is creating new shifts and so many, check that out here.",
  });
  this.meta.updateTag({ property: 'og:type', content: 'Website' });
  this.meta.updateTag({
    property: 'og:image',
    content:
      'https://blockchainx.tech/assets-new/images/news-details/memestocks/roaring-kitty-meme-coins.png',
  });

  this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
  this.meta.updateTag({
    name: 'twitter:site',
    content: 'https://www.blockchainx.tech/bitcoin-spot-etfs/',
  });
  this.meta.updateTag({
    name: 'twitter:title',
    content:
      'Roaring Kitties Setting Standard for new market revolution',
  });
  this.meta.updateTag({
    name: 'twitter:description',
    content:
      "Have you ever thought of crypto tokens can be as influential they are today!? Roaring kitties token movement is creating new shifts and so many, check that out here.",
  });
  this.meta.updateTag({
    name: 'twitter:image',
    content:
      'https://blockchainx.tech/assets-new/images/news-details/memestocks/roaring-kitty-meme-coins.png',
  });

  // Add Canonical tag
  this.canonicalService.setCanonicalURL(
    'https://www.blockchainx.tech/memestocks-surge-as-roaringkitty-returns/'
  );
  this.createLink();
}
createLink() {
  this.canonicalService.destroyLinkForAmpURL();
  let link: HTMLLinkElement = this.doc.createElement('link');
  link.setAttribute('rel', 'amphtml');
  link.setAttribute(
    'href',
    'https://www.blockchainx.tech/amp/memestocks-surge-as-roaringkitty-returns.html'
  );
  this.doc.head.appendChild(link);
}


}
