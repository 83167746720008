<section class="blog-detail">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 mb-4">
        <div class="blog-single-wrap">
          <img loading="lazy"
            src="assets-new/images/blog/9-myths-blockchain.png"
            alt="Blockchain is a Database"
            class="img-fluid"
            width="840"
            height="512"
          />

          <p>
            Ever since the famous Bitcoin raised the urge for people to trade
            with digital currency, there has been a simultaneous escalation in
            the technology behind its success. Blockchain thus became the recent
            muse of tech freaks. This frenzy is now taking over the business of
            existing entities in the web world to the next level by promising a
            rock solid security system.
          </p>
          <p>
            This strong security system is fuelled by the concept of
            decentralised database. Traditionally, a network aways holds its
            entire database in one place with single authority which requires
            huge running power to maintain and also makes it an easy target for
            hackers. To eliminate this risk, Bitcoin used the decentralised
            database or a distributed ledger system. In this method, the data
            and its transaction history are stored in unique Hash values which
            sits in a block and a series of blocks are created to form a chain
            which is secured by cryptographic principles. In this way, the
            ledger of a blockchain is entirely distributed among its users or
            peers in the network.
          </p>
          <p>
            Each time a new transaction is made, a new block is created and this
            has to be verified by a majority of peers in the network to make it
            valid. Hence there is no central authority and this makes it
            impossible for a hacker to beat the system’s capacity to nullify the
            security system. However, blockchain is widely misconceived in
            certain facts that are taken for granted when it comes to the
            underlying facts behind this famous technology.
          </p>

          <h2>Blockchain is a Database</h2>
          <p>
            Blockchain like any other technology, holds a database in its
            system. But the fact is that Blockchain is in-fact a DATA STRUCTURE.
            Furthermore, a typical database is also a data structure that stores
            information and is highly centralised. Whereas the data structure of
            a blockchain is decentralised and has distributed authority. So,
            depending upon the nature and the use of a website, either database
            or blockchain has to used.
          </p>

          <h2>Blockchain is Decentralised</h2>
          <p>
            Blockchain is famous because of its security system which routes
            back to a decentralised database or distributed ledger system. But,
            this technology can be structured either in a decentralised or
            centralised method. Decentralised database is one of the two types
            of blockchain. In this method the ledger is public and accessible to
            all the peers in the network. While this might not be necessary for
            all business nature, partial transparency can be achieved in
            centralised private blockchain where the access to the transaction
            ledger can be limited to certain peers in the network.
          </p>

          <h2>Blockchain is only P2P</h2>
          <p>
            There are three types of blockchain namely public, private and
            hybrid. Of the lot, public blockchain is most spoken of where the
            ledger is open to the peers in its network. While this might not be
            practical in all cases, blockchain technology doesn't necessarily
            have to be a peer-to-peer network, as a Private Blockchain can work
            with centralised database that doesn’t require peers. Whereas a
            hybrid can make the best combination of the other two types.
          </p>

          <h2>Blockchain is only for Cryptocurrency</h2>
          <p>
            Blockchain became extensively noticed because of Bitcoin’s fame, as
            it changed the business of digital currency transaction.However,
            this technology can be applied to a wide variety of industry
            applications like finance, defence, retail and so on. Blockchain is
            only a method that is used to eliminate the risk of hacking its
            network for fraudulent dealing. Hence, this method can be very
            useful in banking applications where a monetary transaction can take
            place in a highly secure platform.
          </p>

          <h2>Bitcoin is Blockchain</h2>
          <p>
            Bitcoin is a cryptocurrency or digital currency that is powered by
            Blockchain technology. Although, Bitcoin is the reason behind
            blockchain’s fame, it is simply an application of blockchain which
            used the decentralised ledger system to gain the trust of people in
            managing monetary transaction in the most secure way. Ethereum,
            another famous cryptocurrency, is also an example for blockchain’s
            application. Some famous companies using blockchain are FedEx, IBM,
            Walmart, Microsoft etc.
          </p>

          <h2>Blockchain is More Complex</h2>
          <p>
            As a technology, Blockchain can be easily build, but it is
            misunderstood as complex because of Bitcoin as it integrates many
            other technologies in the backend. Blockchain is merely an open
            source technology which has replaced the traditional intermediate
            transaction fee by collective consensus theory which verifies the
            transaction which accounts to a huge amount of security and speed in
            the network.
          </p>

          <h2>Blockchain Transactions Takes More Time</h2>
          <p>
            As Blockchain has distributed authority among its users or peers it
            takes more time for transactions to be validated. It uses a
            consensus algorithm which requires the conformity of over 51% of its
            users to confirm and validate a transaction or data that can be
            added to its network as it uses peer-to-peer network in
            decentralised method. This would mean that the peers spread across
            the globe should be active to complete the process as quick as
            possible. But if speed is a priority then Private Blockchain can be
            used where the peers can be limited.
          </p>

          <h2>Blockchain Needs More Power</h2>
          <p>
            The same reason as to blockchain needing more time to process a
            transaction or data due to its peer-to-peer networking system, it
            also requires huge power backup to validate the entry of new data
            through Consensus Algorithm.For example, Bitcoin uses POW - proof of
            work which takes time and take us a lot energy as it runs by entire
            set of peers, but Ethereum uses POS - proof of stake where a peer
            with higher stake can validate the and confirm transactions making
            this a comparatively quicker method.
          </p>

          <h2>Final Thoughts</h2>
          <p>
            After all, Blockchain is a new concept in the market with very many
            interesting applications which will resuscitate and revive some of
            the existing technology. Although there are many misconceptions that
            prevail, it can be easily sorted with a few examples. Understanding
            this technology can go a long way in the days to come. Hope this
            blog has cleared the air of confusion around the concept of
            Blockchain.
          </p>
        </div>
        <app-social></app-social>
      </div>
      <div class="col-lg-4 col-md-12 stick-blog">
        <app-blog-sidebar></app-blog-sidebar>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <app-comments></app-comments>
      </div>
    </div>
  </div>
</section>
<app-blog></app-blog>
<section class="section-service-alt-main">
  <app-contactform></app-contactform>
  </section>
