<section class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span>
                  <strong> Smart Contract-based </strong> Token Sale Dashboard
                </span>
              </h1>
              <p>
                Explore the global funding market with the perks of customized
                token sale Dapp. Expanding endless possibilities of Web3, meet
                the hitch and expectations of the meet eventually.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/ico-dashboard-script/ready-made-ico.webp
                " />

              <source
                type="image/png"
                srcset="
                assets-new/images/ico-dashboard-script/ready-made-ico.png
                " />

              <img loading="lazy"
                data-src="assets-new/images/ico-dashboard-script/ready-made-ico.png"
                width="450"
                height="450"
                class="img-fluid"
                alt="Ico/sto script"
                title="Ico/sto script" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/ico-dashboard-script/light-theme/ready-made-ico.webp
                " />

              <source
                type="image/png"
                srcset="
                assets-new/images/ico-dashboard-script/light-theme/ready-made-ico.png
                " />

              <img loading="lazy"
                data-src="assets-new/images/ico-dashboard-script/light-theme/ready-made-ico.png"
                width="450"
                height="450"
                class="img-fluid"
                alt="Ico/sto script"
                title="Ico/sto script" />
            </picture>
          </div>
        </div>
      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Banner Ends -->

<!-- Product About Section -->
<section class="section-service-alt" #about>
  <div class="develop-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>BlockchainX In <span>ICO Dashboard Script </span> Development</h3>
        </div>
        <div class="py-3">
          <p class="text-center">
            We render fully customized ICO/STO/ IEO Dashboard script development
            easing your step to growth.
          </p>
        </div>
      </div>
      <div class="row align-items-center product-about-detail">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center uni-clone">
          <picture>
            <source
              type="image/webp"
              srcset="
              assets-new/images/ico-dashboard-script/about-blockchainx-ico-dashboard-script.webp
              " />

            <source
              type="image/png"
              srcset="
              assets-new/images/ico-dashboard-script/about-blockchainx-ico-dashboard-script.png
              " />

            <img loading="lazy"
              data-src="assets-new/images/ico-dashboard-script/about-blockchainx-ico-dashboard-script.png"
              width="350"
              height="350"
              class="img-fluid"
              alt="Ico/sto dashboard script"
              title="Ico/sto dashboard script" />
          </picture>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <p>
            ICO tokens have positively shifted the expectation of decentralized
            finances in the mind of crypto startups and businesses. BlockchainX
            is here to help you ease the tedious fundraising campaign for
            business with our ICO dashboard script. A solution to time
            constraints and accessibility, we develop dashboards that are
            flexible, convenient, tech-savvy, and wisely contributing to your
            campaign needs.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>Read More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product About Section -->

<!-- Our Products -->
<section class="section-service-main">
  <div class="our-products">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>Token Sale</span> Demo</h3>
        </div>
        <p class="py-3 text-center">
          Check out our model multifacet ICO Dashboard platform
        </p>
      </div>
      <div class="row product-category-list">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="metaverse_subheading">
            <h3>Checkout our ICO Token Sale Dapp Demo</h3>
          </div>
          <p class="py-3">
            Securely works with smart contract, Multiple rounds management.
          </p>
          <div class="product-demo-image">
            <a href="https://presale.blockchainx.tech/" target="_blank"
              rel="noopener noreferrer">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/product/webp/token-sale-dashboard.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/token-sale-dashboard.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/token-sale-dashboard.png"
                  width="579"
                  height="341"
                  class="img-fluid py-2"
                  alt="ready-made-ico-software Demo"
                  title="ready-made-ico-software Demo" />
              </picture>
            </a>
          </div>
          <div class="content-link">
            <a href="https://presale.blockchainx.tech/" target="_blank"
              rel="noopener noreferrer">
              <span>View Demo</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="metaverse_subheading">
            <h3>ICO Token Sale Development Demo Video</h3>
          </div>
          <p class="py-3">
            Sale Timer control, supports purchase Native currency and tokens.
          </p>
          <div class="product-demo-videos">
            <video
              src="assets-new/images/nft/screens/videos/defi-crowdsale-platform-workflow.mp4"
              controls
              [autoplay]="false"
              width="100%"
              height="378"></video>
            <!-- <iframe
              class="product-demo-video"
              src="https://www.youtube.com/embed/la_8k6_rPQg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> -->
          </div>
          <div class="content-link">
            <a href="https://presale.blockchainx.tech/" target="_blank"
              rel="noopener noreferrer">
              <span>View Demo</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Products -->

<!-- Product Features -->
<section class="section-service-alt">
  <div class="product-feature">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            Features of <span> ICO Dashboard Script</span> Development Software
          </h3>
        </div>
        <p class="py-3 text-center">
          For a successful token sale fundraising campaigns play a vital role in
          expanding the global vicinity of the projects.
        </p>
      </div>
      <div class="row produt-feature-detail">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/kyc-verification.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/kyc-verification.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/kyc-verification.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="kyc-verification"
                title="kyc-verification" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">KYC verification</h4>
          <p>
            The ICO/ STO/ IEO dashboard scripts developed are backed by
            Blockchain-based KYC systems. On confirmation of information, trust
            is established.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/mlm-marketing.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/mlm-marketing.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/mlm-marketing.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="mlm-marketing"
                title="mlm-marketing" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">MLM Marketing</h4>
          <p>
            Crypto projects can be skeptical, while MLM strategies act as part
            of the process where the existing investor refers to new investors,
            ensuring and building trust.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/ico-stage-updates.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/ico-stage-updates.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/ico-stage-updates.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="ico-stage-updates"
                title="ico-stage-updates" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">ICO stage updates and settings</h4>
          <p>
            To keep investors in loop and hold their interests is the ultimate
            aim of your campaigns. To facilitate details and updates, a fully
            custom dashboard is helpful.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/referral-bonus.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/referral-bonus.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/referral-bonus.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="referral-bonus"
                title="referral-bonus" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Referral bonus</h4>
          <p>
            A Referral system in STO/ICO software scripts excites investors to
            enroll and refer other investors into the pool, where rewards are
            credited directly to Wallet.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/notification-and-newsletter.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/notification-and-newsletter.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/notification-and-newsletter.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="notification-and-newsletter"
                title="notification-and-newsletter" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">
            Notification and Newsletter Support
          </h4>
          <p>
            Our ICO scripts transcend instant notification to users and admin.
            Regular engagements and interaction with the periodic newsletter
            through email are key.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/email-and-sms-marketing.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/email-and-sms-marketing.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/email-and-sms-marketing.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="email-and-sms-marketing"
                title="email-and-sms-marketing" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Email and SMS marketing</h4>
          <p>
            Eliminating third-party marketing applications, and email and SMS
            marketing is a driving aspect of ICO/ STO campaigns. The dashboard
            sets & sends regular updates.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/buy-with-fiat-and-crypto.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/buy-with-fiat-and-crypto.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/buy-with-fiat-and-crypto.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="buy-with-fiat-and-crypto"
                title="buy-with-fiat-and-crypto" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Buy with Fiat and Crypto</h4>
          <p>
            Flexible payments ease transactions and tractions, among investors.
            Opportunities to buy tokens with fiat encourage all groups of people
            to invest.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/automated-and-manual-token-transfer.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/automated-and-manual-token-transfer.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/automated-and-manual-token-transfer.png"
                width="110"
                height="126"
                class="img-fluid py-2"
                alt="automated-and-manual-token-transfer"
                title="automated-and-manual-token-transfer" />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">
            Automated and Manual Token transfer
          </h4>
          <p>
            Transfer tokens without any hustle through our ICO/STO/IEO Dashboard
            scripts, backed with robust smart contracts, easing transactions,
            airdrops, etc.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Features -->

<!-- Why Choose STO -->
<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading">
      <h3>
        What Makes BlockchainX's
        <span> STO Dashboard Script Much Efficient?</span>
      </h3>
    </div>
    <div class="row acc-sec-list">
      <div class="col-lg-5 col-md-12 col-sm-12 col-12 text-center acc-image">
        <picture>
          <source
            type="image/webp"
            srcset="
            assets-new/images/ico-dashboard-script/why-choose-blockchainx-sto-dashboard-script.webp
            " />

          <source
            type="image/png"
            srcset="assets-new/images/ico-dashboard-script/why-choose-blockchainx-sto-dashboard-script.png" />

          <img loading="lazy"
            data-src="assets-new/images/ico-dashboard-script/why-choose-blockchainx-sto-dashboard-script.png"
            width="400"
            height="400"
            class="img-fluid"
            alt="Ico/sto dashboard script"
            title="Ico/sto dashboard script" />
        </picture>
      </div>
      <div class="col-lg-7 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                Multiple Crypto and Fiat Currency Support
              </button>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Facilitating your users with flexible payment options, our
                  STO/ ICO dashboards are multi supportive
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                Built Like a Tank
              </button>
            </div>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Security is important, BlockcahinX relay on this factor and
                  keenly develops your custom dashboards
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                Transaction History Download
              </button>
            </div>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Track every transaction is not easy, but we have worked with
                  efficiency to jot down every transaction
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
                Integrated wallet
              </button>
            </div>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Our ICO dashboard script is integrated with internal wallet
                  system supporting all fiat and cryptos
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive">
                White-label solution for STO token sale
              </button>
            </div>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  No worries, facilitate authenticity for your investors with
                  our customized script for your brand
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose STO -->

<!-- Why Choose STO -->
<div class="section-service-alt-padd">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading">
          <h3>
            Features Ruling The
            <span>ICO and STO Industry</span>
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/product/webp/custom-smart-contract.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/custom-smart-contract.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/custom-smart-contract.png"
                  class="img-fluid"
                  alt="custom-smart-contract"
                  title="custom-smart-contract"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Custom Smart Contract</h4>
            <p>
              BlockchainX STO/ ICO scripts allow users to create robust and
              custom smart contracts governing every aspect of the crowd sale.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/product/webp/custom-fund-planning.webp
                    " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/custom-fund-planning.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/custom-fund-planning.png"
                  class="img-fluid"
                  width="76"
                  height="76"
                  alt="custom-fund-planning"
                  title="custom-fund-planning" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Custom Fund Planning</h4>
            <p>
              State-of-the-art, campaign planning facility in your ICO dashboard
              development with BlockchainX raising opportunities.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/product/webp/bank-transfers.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/bank-transfers.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/bank-transfers.png"
                  class="img-fluid"
                  alt="bank-transfers"
                  title="bank-transfers"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              Bank Transfers and management
            </h4>
            <p>
              Multiple money management facilities including direct bank
              transfer with our STO dashboard script development.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/product/webp/detailed-reporting.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/detailed-reporting.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/detailed-reporting.png"
                  class="img-fluid"
                  alt="detailed-reporting"
                  title="detailed-reporting"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              Detailed reporting and analytics
            </h4>
            <p>
              The platforms shall support detailed records and reports for
              better analysis and to plan future campaigns accordingly.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/product/webp/accepts-cryptos-payments.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/accepts-cryptos-payments.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/accepts-cryptos-payments.png"
                  class="img-fluid"
                  alt="accepts-cryptos-payments"
                  title="accepts-cryptos-payments"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Accepts cryptos payments</h4>
            <p>
              An initiative to ease transactions, with flexible options we can
              avoid a lot of complexities by facilitating multiple options.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/product/webp/voting-and-profit.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/voting-and-profit.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/voting-and-profit.png"
                  class="img-fluid"
                  alt="voting-and-profit"
                  title="voting-and-profit"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Highly compatible nature</h4>
            <p>
              We develop ICO scripts compatible with various platforms and
              browsers across the global spectrum
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose STO -->

<!-- Admin Dashboard -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="commontext_wrap">
          <h2><span> Admin Dashboard Features</span></h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center admin-dash">
      <div class="col-lg-4 metmb-3">
        <div class="virtual_process cmb-5">
          <h2>01</h2>
          <h4>Admin notification management</h4>
          <p>
            This feature enables the Admin to receive instant and immediate
            notification on every happening of their ICO/STO dashboard.
          </p>
        </div>
        <div class="virtual_process">
          <h2>02</h2>
          <h4>Control on KYC verification management</h4>
          <p>
            Ensuring the righteousness of the platforms, through manual controls
            admins can permit and restrict users.
          </p>
        </div>
      </div>
      <div class="col-lg-4 metmb-3 text-center">
        <div class="our_dev_process" id="development-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
              assets-new/images/product/webp/admin-dashboard-features.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/product/admin-dashboard-features.png" />

            <img loading="lazy"
              data-src="assets-new/images/product/admin-dashboard-features.png"
              width="275"
              height="275"
              class="img-fluid"
              alt="Initial coin offering script"
              title="Initial coin offering script" />
          </picture>
        </div>
      </div>
      <div class="col-lg-4 virual-list">
        <div class="virtual_process cmb-5">
          <h2>03</h2>
          <h4>Token sale management</h4>
          <p>
            To conduct and manage each stage of your token sale, this admin
            dashboard in the ICO script we develop easily facilitates the
            needful.
          </p>
        </div>
        <div class="virtual_process">
          <h2>04</h2>
          <h4>Manually add tokens to user accounts</h4>
          <p>
            To reward users and participants, manual verifications can be done
            through the dashboard and send assurance to the receivers.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Admin Dashboard -->
<section class="section-service-alt">
  <app-blog></app-blog>
</section>
<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>
