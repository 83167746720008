<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong> Gold Tokenization Service </strong></span>
                Tapping Best Value
              </h1>
              <p>
                Investments, Asset safety, better returns - everything is sorted
                with Gold Tokenization service, Tap to know better!
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/gold-tokenization/gold-tokenization.webp" />
              <img loading="lazy"  data-src="assets-new/images/gold-tokenization/gold-tokenization.png" width="500" height="500" class="img-fluid"  alt="Gold Tokenization service" title="Gold Tokenization service" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/gold-tokenization/light-theme/gold-tokenization.webp" />
              <img loading="lazy"  data-src="assets-new/images/gold-tokenization/light-theme/gold-tokenization.png" width="500" height="500" class="img-fluid"  alt="Gold Tokenization service" title="Gold Tokenization service" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Why consider AI Meme start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Gold Tokenization </span>Development
        Services</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350"
              alt="Gold Tokenization Development Service"
              title="Gold Tokenization Development Service"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Gold stands upright not just as the oldest currency but blends along
            to stand as a stable, valuable, and standardized investment avenue
            with a blend of tradition and innovative twists. With the core idea
            to eradicate the difficulties in storage and security, Digital Gold
            is seizing the spotlight. With the ability to trace, Transact,
            transfer, and stake - Tokenizing digital gold in Blockchain
            platforms increases the potential of modern investments.
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Highly Secured Investment
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Compound
                Return On Investment
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Blockchain
                Backed Gold tokens
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Level Up
                Your Investment Prospectus
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list px-2 px-sm-0">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Trending ERC20 Token List"
              title="Trending ERC20 Token List" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular Gaming</span>
            Utility Tokens</h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> AABB Gold
                  Token
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> AurusGOLD
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> CACHE Gold
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Cash Telex
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> DigixGlobal
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Gold Coin
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Kinesis Gold
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Meld Gold by
                  Algorand
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> PAX Gold
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Perth Mint
                  Gold Token
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Highlights Of The<span> Gold Tokenization</span> Service
          Platform</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Eradicates Physical Risk"
                title="Eradicates Physical Risk" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Eradicates Physical Risk</h4>
          <p>
            There are multiple risks in storing physical gold as there are price
            fluctuations and lockers with no property safety, this digital asset
            tokenization of Gold is very helpful.
          </p>
          
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Highly Secured and Transparent"
                title="Highly Secured and Transparent" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Highly Secured and Transparent
          </h4>
          <p>
            Blockchain’s autonomous nature eliminates the interventions of
            third-party functions and requires no intermediaries because it’s
            secure and transparent to act on.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Faster Transactions"
                title="Faster Transactions" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Faster Transactions</h4>
          <p>
            Smart contracts ensure the transaction cycle is handled at ease,
            making it faster, secure, and more innovatively activated and
            unlocking the crux of activating lower fees.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Rising Liquidity"
                title="Rising Liquidity" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Rising Liquidity</h4>
          <p>
            With fractional ownership and the ability to trade for any other
            token or asset in the digital space, tokenizing gold eventually
            increases its liquidity, empowering returns.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Fractional Ownership"
                title="Fractional Ownership" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Fractional Ownership</h4>
          <p>
            Gold tokens can be purchased in any fractional unit, acting on the
            demand of fixed weightage. This encourages more investors to
            participate in the deal.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Increasing investor Base"
                title="Increasing investor Base" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Increasing investor Base
          </h4>
          <p>
            For investors who are aiming to build a versatile portfolio, these
            Gold tokenizations give them the option to make wider or smaller
            investments therefore increasing traffic.
          </p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Gold Tokenization</span> Development Company
          </h2>
          <p class="pb-3">
            In BlockchainX as we explore the Web3 space, we still are learning
            its new perspective, and the prospectus of building a decentralized
            structure that contributes to building a better economy. And
            eventually, this gold tokenization is a better digital investment
            anyone can vest on. We ensure to provide you with the utmost
            customized, secured, and efficient Gold tokenization service
            platform benefiting you in and out.
          </p>
          <div class="whysection_text_wrap">
            <ul>
              <li>Doing proper market research</li>
              <li>Advanced technological stacks</li>
              <li>Better alternative and consultation</li>
              <li>24/7 support pre and post-development </li>
            </ul>
          </div>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Gold Tokenization development company"
              title="Gold Tokenization development company" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is a gold tokenization service?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    From actual gold in the physical world, digitalizing the
                    same and tokenizing the value of the gold-backed
                    blockchain's immutable and interoperable properties on the
                    ledger structure is the Gold Tokenization service.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What does tokenized gold serve?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The main purpose of the Gold tokenization platform is to
                    tokenize the digital tokens, hold their value, and encourage
                    investors to purchase, stake, and make good returns out of
                    them.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Is it better to encrypt or tokenize gold?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Tokenization reduces data theft to a large extent while in
                    tokenization it's hard to get back to its original form. And
                    tokenization also does not use any key to unlock the data.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  Are there any Limits exposed for gold tokens?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Yes, there are limits exposed for gold tokens to maintain
                    the demand and supply in the market for Gold Tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/what-is-erc20']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/blog/what-is-erc20/erc20-token-standard.webp
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="erc20-token-standard"
                    title="erc20-token-standard" />
                </picture>
                <h3>
                  What is ERC20? A Guide to the Pinnacles of Ethereum Token
                  Standard
                </h3>
                <p>
                  While this crypto revolution continues to change the virtual
                  and the real world, the ERC20 token standards...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/what-is-erc20']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/erc1155-token-standard'
                ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/erc-1155.webp
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/erc-1155.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="erc-1155"
                    title="erc-1155" />
                </picture>
                <h3>
                  ERC1155 – Explore the ERC-1155 Token Standard
                </h3>
                <p>
                  If you understand what an non-fungible-token-development NFT
                  is and that the abbreviation...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/erc1155-token-standard'
                  ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/how-to-create-a-private-blockchain'
                ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.webp
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="private-blockchain-development"
                    title="private-blockchain-development" />
                </picture>
                <h3>
                  How to Create a Private Blockchain For Intensify Business
                  Security?
                </h3>
                <p>
                  With blockchain dominating the entire digital ecosystem, and
                  finance
                  and technology taking over the world...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/how-to-create-a-private-blockchain'
                  ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/crypto-launchpad-development-steps.webp
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/crypto-launchpad-development-steps.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="crypto-launchpad-development-steps"
                    title="crypto-launchpad-development-steps" />
                </picture>
                <h3>
                  Crypto Launchpad Development: Steps to Take Ahead of
                  Developing a Launchpad
                </h3>
                <p>
                  Crypto launchpad development is a great opportunity to keep up
                  and stay one step ahead in this booming...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                  ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Maximize Investments With
                Traditional yet Modernized Gold tokenizations</span></h3>
            <p>Expanding possibilities and new investment opportunities.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->