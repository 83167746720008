<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1 class="pb-3">
                <span><strong>Real World Asset </strong> <br> Tokenization
                  Services </span>
              </h1>
              <div class="common-sub-heading-alt pb-2">Actual Assets as Digital Securities</div>

              <p>
                Unlock the potential of your real assets in the digital world with our Real World Asset Tokenization services, maximizing utility and traction.
              </p>
              <!-- <div class="erc-link">
                <div class="content-link">
                  <a
                    href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                  </a>
                </div>
                <div class="content-link">
                  <a
                    href="https://www.blockchainx.tech/contact/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Email Us</span>
                    <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  </a>
                </div>
              </div> -->
              <div class="erc-link d-flex flex-column flex-sm-row">
                <div class="d-flex pt-3">
                    <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                      <span>Telegram</span>
                    </a>
                  </div>  
                <div class="d-flex pt-3">
                  <a href="https://www.blockchainx.tech/contact/" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                    <span>Email Us</span>
                  </a>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                    assets-new/images/real-world-asset-tokenization/real-world-banner.webp
                  " />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/real-world-banner.png"
                class="img-fluid"
                alt="Real world Asset Tokenization"
                title="Real World Asset Tokenization"
                width="450"
                height="450" />
            </picture>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="Scroll"
              title="Scroll" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section end -->

<!-- Why Choose Start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center">
      <div
        class="col-lg-5 mx-auto col-lg-6 col-sm-12 col-12 order-md-first order-last">
        <div class="bep-work-section text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-world-asset-tokenization/rwa-tokens.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-world-asset-tokenization/rwa-tokens.png"
              class="img-fluid"
              alt="Real World Asset Tokenization development"
              title="RWA Tokens Amplying their Potential With Digital Trends"
              width="450"
              height="450" />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-lg-6 col-sm-12 col-12 about-content">
        <div class="whys_section mb-0">
          <h2><span>Real World Asset Token</span> Development Company</h2>
          <p class="pb-3">
            Leveraging the power of Blockchain the potentials of real-world assets are amplified to grow and evolve in digital trends, while the sector showing a positive revolution, Real World Asset token development companies are becoming the need of the hour. At BlockchainX, we extend end-to-end customized services for your RWA tokenization, which gives a different look-through for actual assets. By digitizing them on blockchain networks as tokens, their value and their future are determined to expand, unlike the traditional methods. They bring multifold benefits to the projects and operations, investing in a Real-world asset token development company to carter your needs, securely, accurately, and elegantly - would be the best yield for your progress.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose End -->

<!-- Tokenization Demo Start -->
<section class="section-service-main">
  <!-- <div class="product-list-feature"> -->
  <div class="container">
    <div class="row">
      <div class="col-lg-6 metaverse_heading d-flex flex-column justify-content-center">
        <h3 class="text-start"><span>What is Real World </span> Asset Tokenization?</h3>
        <p class="py-3"> What is Real world Asset Tokenization?
          In this Video, you will learn What is RWA Tokenization, Its use cases,
          challenges, process and future of Real world Asset Tokenization.
        </p>
      </div>
      <div class="col-lg-6 d-flex justify-content-center">
        <video
          poster="assets-new/images/real-world-asset-tokenization/rwa-platform-img.webp"
          src="assets-new/images/real-world-asset-tokenization/videos/rwa-platform.mp4"
          controls
          [autoplay]="false"
          width="100%"
          preload="auto"
           >
          <track kind="captions"
            src="assets-new/images/real-world-asset-tokenization/videos/rwa-platform.mp4"
            srclang="en" label="English">
        </video>
      </div>
    </div>
  </div>
  <!-- </div> -->
</section>
<!-- Tokenization Demo End -->

<!-- Our Real World Asset Tokenization Services Offer You Start -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3><span>Real World Asset </span>Tokenization Services We Offer
            </h3>
        </div>
      </div>
    </div>
    <div class="row pt-5 pt-4 mt-md-2 mt-0 row-gap-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/offering-management.webp" />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/offering-management.png"
            class="img-fluid pb-lg-3 pb-2"
            alt="Offering Management"
            width="75"
            height="75" />
        </picture>
        <h5 class="erc-20-head pb-2">Offering Management Platform</h5>
        <p class="text-center">
          Our Management platform offers efficient administration, protection,
          dissemination, monitoring of digital promotions, etc, bringing
          precise control over the offerings.
        </p>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/compliance-management.webp" />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/compliance-management.png"
            class="img-fluid pb-lg-3 pb-2"
            alt="Compliance Management"
            width="75"
            height="75" />
        </picture>
        <div class="erc-20-head pb-2">Compliance Management Platform</div>
        <p class="text-center">
          Withholding the essential functionalities like conducting rigorous
          KYC, and AML checks, verifying investor's accreditation, and
          facilitating reports of capabilities, it’s mandatory.
        </p>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/token-marketplace.webp" />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/token-marketplace.png"
            class="img-fluid pb-lg-3 pb-2"
            alt="Token Marketplace"
            width="75"
            height="75" />
        </picture>
        <h5 class="erc-20-head pb-2">Token Marketplace</h5>
        <p class="text-center">
          Here tokens are digital currencies symbolizing ownership, whereas a
          marketplace is a platform that operates for buyers and sellers to
          interact with their tokens.
        </p>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/multi-signature.webp" />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/multi-signature.png"
            class="img-fluid pb-lg-3 pb-2"
            alt="Multi Signature"
            width="75"
            height="75" />
        </picture>
        <h5 class="erc-20-head pb-2">Multi-Signature (MultiSig) Provisions</h5>
        <p class="text-center">
          This introduces an additional level of security where multiple
          parties are expected to provide their approval to pass on a
          transaction on approval.
        </p>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/alternative-trading-system.webp" />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/alternative-trading-system.png"
            class="img-fluid pb-lg-3 pb-2"
            alt="Alternative Trading System"
            width="75"
            height="75" />
        </picture>
        <h5 class="erc-20-head pb-2">Alternative Trading System (ATS)</h5>
        <p class="text-center">
          ATS takes care of liquidity and facilitates the price discovery
          process for tokens seamlessly. It equalizes forces and encourages
          small investors' participation in the market.
        </p>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/multi-party-computation.webp" />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/multi-party-computation.png"
            class="img-fluid pb-lg-3 pb-2"
            alt="Multi Party Computation"
            width="75"
            height="75" />
        </picture>
        <h5 class="erc-20-head pb-2">Multi-Party Computation (MPC)</h5>
        <p class="text-center">
          As the information is distributed across multiple parties a
          cryptographic technique is used to safeguard sensitive information
          including private keys, transaction data, etc.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- Our Real World Asset Tokenization Services Offer You End -->

<!-- Our Real World Asset Tokenization Offerings Start -->
<div class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            <span>RWA Token </span> Development Services Provided At BlockchainX
          </h3>
        </div>
      </div>
      <div
        class="row types-of-token-detail justify-content-center real-world-check">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/unique-collectables.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/unique-collectables.png"
                class="img-fluid pb-3"
                alt="Unique Collectables"
                width="75"
                height="75" />
            </picture>
            <h5 class="erc-20-head-alt text-left h-4">Unique Collectables</h5>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                Electronic & medical devices </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                Automobiles </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Fine arts
                and virtual collectibles </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/precious-metals.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/precious-metals.png"
                class="img-fluid pb-3"
                alt="Precious Metals"
                width="75"
                height="75" />
            </picture>
            <h5 class="erc-20-head-alt text-left h-4">Precious Metals</h5>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Platinum
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Gold
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Silver
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/financial-instruments.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/financial-instruments.png"
                class="img-fluid pb-3"
                alt="Financial Instruments"
                width="75"
                height="75" />
            </picture>
            <h5 class="erc-20-head-alt text-left h-4">Financial Instruments</h5>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Real
                Estate </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Equities
                & Fixed income </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                Certificates </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/intangible-assets.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/intangible-assets.png"
                class="img-fluid pb-3"
                alt="Intangible Assets"
                width="75"
                height="75" />
            </picture>
            <h5 class="erc-20-head-alt text-left h-4">Intangible Assets</h5>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Patents
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Licenses
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                Trademarks </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/consumables.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/consumables.png"
                class="img-fluid pb-3"
                alt="consumables"
                width="75"
                height="75" />
            </picture>
            <h5 class="erc-20-head-alt text-left h-4">Consumables</h5>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Coffee
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Food and
                beverages </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                Pharmaceuticals </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/agriculture.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/agriculture.png"
                class="img-fluid pb-3"
                alt="agriculture"
                width="75"
                height="75" />
            </picture>
            <h5 class="erc-20-head-alt text-left h-4">Agriculture</h5>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Crop and
                Production
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Land
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> Equipment
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Real World Asset Tokenization Offerings End -->

<!-- Business Benefits  start -->
<div class="section-service-alt-padd">
  <div class="types-of-erc">
    <div class="container">
      <div class="row sm-pb-4 pb-2">
        <div class="metaverse_heading mx-auto">
          <h3> Intriguing Benefits of
            <span>Real World Asset Tokenization Services</span>
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/improved-liquidity.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/improved-liquidity.png"
                  class="img-fluid"
                  alt="Improved Liquidity"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Improved Liquidity</h5>
            <p>
              Tokenizing assets provided the power of fractionated ownership,
              providing the opportunity to divide assets into smaller tradable
              tokens. Thereby opening up for a broader investor range and
              increasing trading efficiency.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/global-market-access.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/global-market-access.png"
                  class="img-fluid"
                  alt="Global Market Access"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Global Market Access</h5>
            <p>
              Breaking the geographical barriers, RWA tokenization enables
              businesses to walk into the global market and interact with
              investors in a wider range. With cross-border transactions and
              expose to wider potential investors.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/enhanced-efficiency.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/enhanced-efficiency.png"
                  class="img-fluid"
                  alt="Enhanced Efficiency"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Enhanced Efficiency</h5>
            <p>
              Compared to traditional asset ownership and transfer policies,
              real-world asset tokenization reduces time and cost. This
              streamlined process of tokenization reduces administrative
              overheads, micro-management, etc bringing efficiency to
              operations.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/reflection.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/reflection.png"
                  class="img-fluid"
                  alt="Transparency On Par"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Transparency On Par</h5>
            <p>
              Involving blockchain into the scene, it's obvious there is going
              to be a lot of transparency and immutability. This level of
              transparency takes trust, and accountability and reduces the major
              risk factors in the process.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/fractional-ownership.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/fractional-ownership.png"
                  class="img-fluid"
                  alt="Fractional Ownership"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Fractional Ownership</h5>
            <p>
              By enabling smaller investment opportunities through fractional
              ownership, RWA tokenization is a good deal for any investor.
              Thereby bringing a wider investor group, global market, and open
              space for previously inaccessible retail investors.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/capital-access-fundraising.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/capital-access-fundraising.png"
                  class="img-fluid"
                  alt="Capital Access Fundraising"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Capital Access &
              Fundraising</h5>
            <p>
              With the ability to access the capital markets, asset tokenization
              allows businesses to easily raise funds by issuing security
              tokens. This represents ownership and rights for the underlying
              asset that expands future funding opportunities.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/asset-interoperability.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/asset-interoperability.png"
                  class="img-fluid"
                  alt="Asset Interoperability"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Asset Interoperability</h5>
            <p>
              By providing interoperability and creation of new financial
              products, Digital asset tokenization provides seamless transfer
              and integration of different assets on the digital platform in
              blockchain.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-world-asset-tokenization/automation-smart-contract.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/automation-smart-contract.png"
                  class="img-fluid"
                  alt="Automation Smart Contract"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h5 class="erc-20-head-alt text-left h-4">Automation & Smart Contract
              Functionality</h5>
            <p>
              By leveraging smart contracts that automate various asset aspects
              like dividends, distribution, voting rights, compliance
              enforcement, and others, it reduces manual interventions
              gradually.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Business Benefits end -->

<!-- Lifecycle of a Tokenized Security starts -->
<section class="section-service-main">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h3 class="text-center pb-0 mb-3 h-2">
            Process Involved In  <span>Real World Assets Tokenization  On Blockchain</span> 
          </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/real-world-asset-tokenization/real-world-asset-token.webp
              " />
            <img loading="lazy"
              data-src="assets-new/images/real-world-asset-tokenization/real-world-asset-token.png"
              width="500"
              height="500"
              class="img-fluid pb-md-0 pb-3"
              alt="Real World Asset Tokenization on Blockchain"
              title="Real World Asset Tokenization on Blockchain" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Let's set the tone</h5>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  Let us start off by discussing the business prospectus of your project in Real-world tokenization. It is important to understand your requirements, and expectations and line up to make our actions aligned to meet your expectations in real-world asset tokenization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Get the checks cleared </h5>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  From getting the nuances discussed, let us get into the auditing and financial consultation from our experts to understand jurisdictions, constraints, and other specifications that are specific to the owners, and ensure to frame things based on requirements. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Form the norms</h5>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p class="text-justify">
                While tokenizing commercial assets like real estate or any valuables by any chance, they would be owned by SPV/SPE (Special Purpose Vehicle/ Entity). This is created for the asset and we ensure they are secured and backed. 
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Blockchain Development</h5>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  Going further in our Real estate tokenization process, we now go ahead with building your blockchain or integrating the asset in the network of your choice. We also built your smart contracts and integrated them on top of the network to ensure efficient follow-up of automated operations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Evaluating the assets</h5>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  Our audit firm now scrutinizes the value of the asset. Post this the value of each token is determined and then taken to the token issuer for the price that's fixed. The asset value depends on the facilities and functionalities it brings to the table. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Dashboard for better views</h5>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  As the next step, we will build the Token dashboard for your reference. As the tokens are embedded with smart contracts, the dashboard will be designed and put together to provide information for the investors to manage the issue and easily manage funds.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Opening Asset Offering </h5>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  As a part of real-world asset tokenization, the tokens are now taken to Tokenized Asset Offerings (TAO) which are empowered by the smart contracts that allow the investors to purchase. This allows investors to easily buy RWA tokens that back legal approval and have a holistic approach. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h5 class="h-3">Taking RWA to Exchange listing</h5>
                </div>
              </div>
              <div class="casestudy_para border-0">
                <p class="text-justify">
                  The next step is to take the Real-world asset token through exchanges. As all the tokens will be listed on the security token exchange for the secondary sale, the provision for accredited investors allows them to directly sell the token.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
  </section>
<!-- Lifecycle of a Tokenized Security ends -->

<!-- How Nft Fields Work -->
<section class="section-service-alt">
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-12 mx-auto">
        <div class="metaverse_heading">
          <h3>What <span> Real World Asset Tokenization</span> Platform Package
            All About?</h3>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5 mb-lg-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/web3-usage.webp
              " />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/web3-usage.png"
            width="90"
            height="90"
            class="img-fluid royalty-fee pb-2"
            alt="Web3 Usage"
            title="Web3 Usage" />
        </picture>
        <div class="our_process_text_wrap">
          <h5 class="h-3">Web3 Usage</h5>
          <p>
            The real-world tokenization platform is a complete Web3 package that
            relies on blockchain and smart contracts.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5 mb-lg-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/multi-ledger-operability.webp
              " />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/multi-ledger-operability.png"
            width="90"
            height="90"
            class="img-fluid royalty-fee pb-2"
            alt="Multiledger Operability"
            title="Multiledger Operability" />
        </picture>
        <div class="our_process_text_wrap">
          <h5 class="h-3">Multi-Ledger Operability</h5>
          <p>
            With multi-ledger operability, there is more interoperability within
            the asset tokenization platform.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5 mb-lg-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/automated-reporting.webp
              " />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/automated-reporting.png"
            width="90"
            height="90"
            class="img-fluid royalty-fee pb-2"
            alt="Automated Reporting"
            title="Automated Reporting" />
        </picture>
        <div class="our_process_text_wrap">
          <h5 class="h-3">Automated Reporting</h5>
          <p>
            During unpredicted situations, the platform is designed to
            independently regulate authorities and act upon concern.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5 mb-lg-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/kyc-aml-verification.webp
                " />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/kyc-aml-verification.png"
            width="90"
            height="90"
            class="img-fluid royalty-fee pb-2"
            alt="KYC/AML Verification"
            title="KYC/AML Verification" />
        </picture>
        <div class="our_process_text_wrap">
          <h5 class="h-3">KYC/AML Verification</h5>
          <p>
            With a comprehensive KYC/AML verification feature the platform is
            coated and guarded with extra layers to enhance trust.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5 mb-lg-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/token-development.webp
                " />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/token-development.png"
            width="90"
            height="90"
            class="img-fluid royalty-fee pb-2"
            alt="Token Development"
            title="Token Development" />
        </picture>
        <div class="our_process_text_wrap">
          <h5 class="h-3">Token Development</h5>
          <p>
            Tokenizing RWA either as a fungible or non-fungible token, the
            platform eases the process and distributes it to users.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5 mb-lg-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/real-world-asset-tokenization/interoperable-provisions.webp
                " />

          <img loading="lazy"
            data-src="assets-new/images/real-world-asset-tokenization/interoperable-provisions.png"
            width="90"
            height="90"
            class="img-fluid royalty-fee pb-2"
            alt="Interoperable Provisions"
            title="Interoperable Provisions" />
        </picture>
        <div class="our_process_text_wrap">
          <h5 class="h-3">Interoperable Provisions</h5>
          <p>
            The RWA tokens issued by the platform work across any token exchange
            on the blockchain network with its interoperable nature.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- How Nft Fields Work -->

<!-- Asset Tokenization Examples Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="common-sub-heading sub-title">
          Asset Tokenization Examples<span class="dots">.</span>
        </div>

        <h3 class="main-sub-heading pb-lg-5 pb-4">
          Prominently Evolving <span>Asset Tokens</span> In The Network
        </h3>
        <div class="field-slider">
          <owl-carousel-o [options]="fieldOptions">
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/digital-asset-tokenization.webp
                          " />

                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/digital-asset-tokenization.png"
                        width="130"
                        height="130"
                        class="img-fluid pb-3"
                        alt="Digital Asset Tokenization"
                        title="Digital Asset Tokenization" />
                    </picture>
                  </div>
                  <h5 class="common-sub-heading h-4">
                    Digital Asset Tokenization
                  </h5>
                  <p>
                    A revolutionizing way that changed traditional asset
                    interactions. Real estate, Stocks, artworks, etc are
                    tokenized in the digital network with added value and better
                    ownership.
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/in-game-asset-tokenization.webp
                          " />

                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/in-game-asset-tokenization.png"
                        width="130"
                        height="130"
                        class="img-fluid pb-3"
                        alt="Game Asset Tokenization"
                        title="Game Asset Tokenization" />
                    </picture>
                  </div>
                  <h5 class="common-sub-heading h-4">
                    In-Game Asset Tokenization
                  </h5>
                  <p>
                    A niche that was benefited highly by this transformative
                    technology. In-game assets with the ability to buy, sell and
                    trade are driving the virtual economy with actual monetizing
                    ability.
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/real-world-asset-tokenization.webp
                          " />

                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/real-world-asset-tokenization.png"
                        width="130"
                        height="130"
                        class="img-fluid pb-3"
                        alt="Real World Asset Tokenization"
                        title="Real World Asset Tokenization" />
                    </picture>
                  </div>
                  <h5 class="common-sub-heading h-4">Real World Asset Tokenization
                  </h5>
                  <p>
                    Gets a step ahead pulling in the opportunities of converting
                    physical assets as digital tokens in the blockchain network.
                    This makes it more accessible for investors with fractional
                    ownership.
                  </p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Asset Tokenization Examples End -->

<!-- Asset Tokenization Vs.Token Sale Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="orange-vector"></div>
    <div class="row justify-content-center mb-5">
      <div class="col-lg-10">
        <div class="metaverse_heading">
          <h3>Asset Tokenization <span> Vs.</span> Token Sale </h3>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center">
        <p class="mb-sm-4 mb-3">
          The process of converting an asset’s ownership rights into digital
          tokens on a blockchain network is Asset tokenization. On the other
          hand, the process of selling tokens in the marketplace to raise funds
          for a particular project or business is a Token sale.
        </p>
        <p class="mb-5">
          Asset tokenization enables fractional ownership that contributes to
          increasing liquidity for investors. The token is a key agent in both
          concepts while their difference of purpose brings the change. Asset
          tokenization aims at enhancing value and accessibility while token
          sale entrusts bringing funds.
        </p>
        <div class="token-vs-btn">
          <a href="https://wa.me/7708889555" class="our_expert_talk"
            target="_blank"
            rel="noopener noreferrer">Connect With Whatsapp</a>
          <a href="https://www.blockchainx.tech/contact/"
            class="our_expert_talk">Talk With Our Experts</a>
        </div>
      </div>
    </div>

  </div>

</section>
<!-- Asset Tokenization Vs.Token Sale End -->

<!-- Our Real World Asset Tokenization Offerings Start -->
<div class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            <span>Different Sectors Availing </span>Real-World Asset Tokenization Services
          </h3>
        </div>
      </div>
      <div
        class="row types-of-token-detail justify-content-center real-world-check">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Real Estate</h5>
            <p>
              These are asset classes that can be illiquid and expensive to invest, by tokenizing this real estate it's easier to make investments even in a fractional manner. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Sports and Gaming Token</h5>
            <p>
              From in-game assets, items, and characters, an entire game is possible to tokenize as NFTs.Eventually Player rights, contracts, ownership, naming rights, etc can also be tokenized. These unique assets can be traded digitally.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Physical Assets</h4>
            <p>
              We are experts in the tokenizing range of physical collectibles from antique art pieces, creative structures, unique designs, electronic, automobile parts, medical devices, and more.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Rare Collectibles</h5>
            <p>
              From Diamonds, and unique rare artifacts, to extinguished plants, animal traces, cultural aspects of age-old communities, investigation in the new age, and many more rare collectibles can also be tokenized.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Precious Metals</h4>
            <p>
              In our Real World asset tokenization services we are well-versed in tokenizing real metals like diamond, gold, platinum, nickel,  and more on chain which brings more investment opportunities and removes the physical barriers in the market. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Luxury Items</h5>
            <p>
              Say you own a priceless perfume, a million-dollar handbag, a belt, or any car, building, and more, our excellent team of developers in our team can help you tokenize and make the best use of it. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Financial Instruments</h5>
            <p>
              All equity shares, real estate assets, certificates, fixed incomes like debt securities with fixed interest rate over a fixed period or any kind of financial assets are also tokenized on chain in the choice of network as required.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Intangible Assets</h5>
            <p>
              Intangible assets including patents, licenses, trademarks, royalties and others can also be tokenized. This eventually increases the community-centric nature of your project by distributing the IP rights.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Consumer goods</h5>
            <p>
              Food, beverages, pharmaceuticals, and other consumables can be tokenized which enhances the supply chain management making monitoring practices more simplified and easy for the producer and this also invites more traction around your products. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Agricultural Products</h5>
            <p>
              Agricultural products like crops, land, equipment, products, techniques, etc., can be tokenized on the chain. This has an evident way of attracting funding opportunities and investors for the industry. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h5 class="erc-20-head-alt h-4">Residential Mortgage-Backed Securities (RMBS)</h5>
            <p>
              These backed by a mortgage pool, Residential MBS is a residential mortgage, is a type of fixed income securities. It is often used by investors as an interest generator to hedge against risk.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Real World Asset Tokenization Offerings End -->

<!-- Clients Start -->
<section class="section-service-alt bx-client">
  <div class="container">
    <div class="row mb-md-5 mb-3">
      <div class="col-lg-8 mx-auto">
        <div class="metaverse_heading">
          <h3> <span>BlockchainX</span> Clients</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <!-- <div class="d-flex justify-content-center">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <a href="https://www.realworld.fi/" class="client-box"
              target="_blank"
              rel="noopener noreferrer">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/real-world.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/real-world.png"
                    width="150" height="60" class="img-fluid"
                    alt="RealWorld" title="RealWorld" />
                </picture>
              </div>
            </a>
          </ng-template>
          <ng-template carouselSlide>
            <a href="https://farmsent.io/" class="client-box" target="_blank"
              rel="noopener noreferrer">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/farmsent.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/farmsent.png"
                    width="150" height="60" class="img-fluid"
                    alt="Farmsent" title="Farmsent" />
                </picture>
              </div>
            </a>
          </ng-template>
          <ng-template carouselSlide>
            <a href="https://specialmetalx.com/" class="client-box"
              target="_blank"
              rel="noopener noreferrer">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/specialmetalx.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/specialmetalx.png"
                    width="150" height="60" class="img-fluid"
                    alt="Specialmetalx" title="Specialmetalx" />
                </picture>
              </div>
            </a>
          </ng-template>
          <ng-template carouselSlide>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/ekta.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/ekta.png"
                    width="150" height="60" class="img-fluid"
                    alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/reflex-finance.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/reflex-finance.png"
                    width="150" height="60" class="img-fluid"
                    alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/zuki.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/zuki.png"
                    width="150" height="60" class="img-fluid"
                    alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/wafercoin.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/wafercoin.png"
                    width="150" height="60" class="img-fluid"
                    alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/clients/polkadog.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/clients/polkadog.png"
                    width="150" height="60" class="img-fluid"
                    alt="our clients" title="our clients" />
                </picture>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        </div> -->
        <!-- <div class="item-client">
          <a href="https://www.realworld.fi/" class="client-box"
            target="_blank"
            rel="noopener noreferrer">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/real-world.png"
                  width="150" height="60" class="img-fluid" alt="RealWorld"
                  title="RealWorld" />
              </picture>
            </div>
          </a>
          <a href="https://farmsent.io/" class="client-box" target="_blank"
            rel="noopener noreferrer">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/farmsent.png"
                  width="150" height="60" class="img-fluid" alt="Farmsent"
                  title="Farmsent" />
              </picture>
            </div>
          </a>
          <a href="https://specialmetalx.com/" class="client-box"
            target="_blank"
            rel="noopener noreferrer">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/specialmetalx.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/specialmetalx.png"
                  width="150" height="60" class="img-fluid"
                  alt="Specialmetalx"
                  title="Specialmetalx" />
              </picture>
            </div>
          </a>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/ekta.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/reflex-finance.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/zuki.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/wafercoin.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/polkadog.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
            </div>
          </div>
        </div> -->
        <div class="item-client-slide client">
          <owl-carousel-o [options]="customOptions" >
            <ng-template carouselSlide>
              <a href="https://www.realworld.fi/" class="client-box"
                target="_blank"
                rel="noopener noreferrer">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/real-world.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/real-world.png"
                      width="150" height="60" class="img-fluid"
                      alt="RealWorld" title="RealWorld" *ngIf="!isDarkmode"/>
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/realworld-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/realworld-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="RealWorld" title="RealWorld" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <a href="https://farmsent.io/" class="client-box" target="_blank"
                rel="noopener noreferrer">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/farmsent.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/farmsent.png"
                      width="150" height="60" class="img-fluid"
                      alt="Farmsent" title="Farmsent" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/farmsent-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/farmsent-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Farmsent" title="Farmsent" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <a href="https://specialmetalx.com/" class="client-box"
                target="_blank"
                rel="noopener noreferrer">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/specialmetalx.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/specialmetalx.png"
                      width="150" height="60" class="img-fluid"
                      alt="Specialmetalx" title="Specialmetalx" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/specialmetalx-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/specialmetalx-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Specialmetalx" title="Specialmetalx" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/ekta.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/ekta.png"
                      width="150" height="60" class="img-fluid"
                      alt="our clients" title="our clients" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/ekta-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/ekta-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Ekta" title="Ekta" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/reflex-finance.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/reflex-finance.png"
                      width="150" height="60" class="img-fluid"
                      alt="our clients" title="our clients" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/reflex-finance-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/reflex-finance-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Our Clients" title="Our Clients" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/zuki.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/zuki.png"
                      width="150" height="60" class="img-fluid"
                      alt="our clients" title="our clients" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/zuki-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/zuki-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Our Clients" title="Our Clients" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/wafercoin.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/wafercoin.png"
                      width="150" height="60" class="img-fluid"
                      alt="our clients" title="our clients" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/waifer-coin-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/waifer-coin-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Our Clients" title="Our Clients" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="client-box">
                <div class="our-client">
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/polkadog.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/polkadog.png"
                      width="150" height="60" class="img-fluid"
                      alt="our clients" title="our clients" *ngIf="!isDarkmode" />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/home/clients/polkadog-lite.webp" />
                    <img loading="lazy"
                      data-src="assets-new/images/home/clients/polkadog-lite.png"
                      width="150" height="60" class="img-fluid"
                      alt="Our Clients" title="Our Clients" *ngIf="isDarkmode" />
                  </picture>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Clients End -->

<!-- Some Networks used for Tokenization Start -->
<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading">
      <h3> <span>Popular Blockchains used for </span> Real World Asset
        Tokenization</h3>
    </div>
    <div class="row process-erc justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <h5 class="h-4">Ethereum</h5>
            <p>Token Development</p>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/ethereum-eth.webp" />
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/ethereum-eth.png"
                width="60" height="60" alt="Etherum Eth" title="ethereum-eth"
                class="img-fluid">
            </picture>
            <p>
              A decentralized network supported by smart contracts, Etheruem is
              a wide choice for businesses involved in asset tokenization. Its
              robust ecosystem and wider adaptation make it a popular choice.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <h5 class="h-4">Polygon</h5>
            <p>Token Development</p>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                width="60" height="60" alt="Polkadot Bridge to Ethereum"
                title="Polkadot Bridge to Ethereum"
                class="img-fluid">
            </picture>
            <p>
              Polygon is a multichain network that is open for cross-network
              transactions. Its interoperability and features make it more
              convenient for RWA tokenization. Seeks more connectivity through a
              scalable environment.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <h5 class="h-4">Avalanche</h5>
            <p>Token Development</p>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/stellar-xlm.webp" />
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/stellar-xlm.png"
                width="60" height="60" alt="Stellar XLM" title="Stellar XLM"
                class="img-fluid">
            </picture>
            <p>
              A low-cost yet fast-paced blockchain network with the ability to
              cross border transactions, Avalanche creates and transfers
              tokenized assets - making it highly important to carry out
              seamless transactions.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <h5 class="h-4">Solana</h5>
            <p>Token Development</p>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/tezos-xtz.webp" />
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/tezos-xtz.png"
                width="60" height="60" alt="Texos XTZ" title="Texos XTZ"
                class="img-fluid">
            </picture>
            <p>
              Solana Blockchain Platform focuses on self-amendment and on-chain
              governance providing secured and upgraded infrastructure. This
              network is suitable for various ranges of asset tokenisation
              ensuring flexibility and adaptability.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <h5 class="h-4">BNB Chain</h5>
            <p>Token Development</p>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/cardano.webp" />
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/cardano.png"
                width="60" height="60" alt="cardano" title="cardano"
                class="img-fluid">
            </picture>
            <p>
              The BNB Chain is a highly secured and supportive ecosystem,
              helping with the creation of customer tokens making it capable of
              projects venturing into different niches via a scalable
              environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Some Networks used for Tokenization End -->

<!--web3 expert-->
<!-- <div class="section-service-alt">
  <div class="our-expert-vector">
    <picture>
      <source type="image/webp"
        srcset="assets-new/images/home/deploy/deploy-vector.webp" />
      <img loading="lazy"
        data-src="assets-new/images/home/deploy/deploy-vector.png" width="709"
        height="560" class="img-fluid" alt="expert-vector"
        title="expert-vector" />
    </picture>
  </div>
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row py-lg-5">
      <div class="col-lg-12">
        <div class="our_experts">
          <h2><span>Unleash The True Value Of Assets With Real-World Asset
              Tokenization</span>
          </h2>
          <div class="text-center my-5">
            <a class="our_expert_talk" href="contact">Connect NOW!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--end-->

<!--web3 expert-->
<div class="section-service-alt">
  <div class="our-expert-vector">
    <picture>
      <source type="image/webp"
        srcset="assets-new/images/home/deploy/deploy-vector.webp" />
      <img loading="lazy"
        data-src="assets-new/images/home/deploy/deploy-vector.png" width="480"
        height="560" class="img-fluid" alt="Expert Vector"
        title="Expert Vector" />
    </picture>
  </div>
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row py-lg-0">
      <div class="col-lg-12">
        <div class="our_experts py-0">
          <p class="h-2"><span>Unleash The True Value Of Assets With Real-World Asset
              Tokenization</span>
          </p>
          <div class="text-center my-5">
            <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Connect NOW!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end-->

<!-- Principles Start -->
<!-- <section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mb-5">
        <div class="c-animation">
          <div class="common-sub-heading sub-title">
            Principles <span class="dots">.</span>
          </div>
          <h3 class="main-sub-heading">
            <span>Cutting-edge</span> Blockchain Development Services.
          </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="green-vector"></div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h5 class="h-3">Technology-agnostic <span class="d-block">approach</span></h5>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              We can get your projects mainstream by integrating a business
              portal on the desired blockchain network, alongside also you
              build an exclusive network that can operate on its own.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h5 class="h-3">Ability to meet <span class="d-block">required
                deadlines</span></h5>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Our Comprehensive decentralized Solutions have reached out to
              almost every edge and curated a complete universe of abilities
              in the spaces with our improved technology, which shall be the
              future.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h5 class="h-3">100% challenge <span class="d-block">resolution</span></h5>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              In Decentralised finances - DeFi Development Services, we render
              services that cull out legibility, security, and reliability on
              DeFi, therefore being a canvas to the new digitized finance
              market.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h5 class="h-3">Full accountability <span class="d-block">and
                engagement</span></h5>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              From exchange to currencies, our complete decentralized
              solutions render smart contract services that are
              on-of-the-kind. Its fortune in terms of security, safety along
              with scalability.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h5 class="h-3">Responsiveness to client’s <span class="d-block">domain
                requirements</span></h5>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Our robust solutions from the hands of our experiment Blockchain
              development facilitators, will help you develop any token, in
              the standard that you call out. This includes NFTs, asset
              tokenization, DeFi, and others.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- Principles End -->

<div class="section-service-main">
  <div class="types-of-erc">
      <div class="container">
          <div class="row">
              <h3 class="main-sub-heading text-center pb-3"> 
                  <span>Why choose us for </span>Real-world asset token development?
              </h3>
              <!-- <p>We Provide Tailored Web3 Development for Any</p> -->
               <p class="text-center">
                BlockchainX with 7 plus years of experience in the industry has built the trait of providing A-grade solutions for your projects. And the team of young, charismatic developers contributes their heart and soul. Our specialization in Real-world asset tokenization is being considerate and giving you the exact solutions you dreamt of. Eventually, we also develop solutions that are designed and built exclusively for you, meeting your choices and supporting all kinds of upgrades post-launch. 
               </p>
          </div>
          <div
              class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-gap types-of-token-detail justify-content-center industry-web industry-type">
              <div class="col">
                  <div class="types-erc-detail  text-center">
                      <div class>
                          <picture>
                              <source
                                  type="image/webp"
                                  srcset="assets-new/images/real-world-asset-tokenization/succesful-launches.webp" />

                              <img loading="lazy"
                                  data-src="assets-new/images/real-world-asset-tokenization/succesful-launches.png"
                                  class="img-fluid"
                                  alt="Successful Project Launches"
                                  width="75"
                                  height="75" />
                          </picture>
                      </div>
                      <h5 class="erc-20-head-alt h-4">50 plus successful project launches</h5>
                  </div>
              </div>
              <div class="col">
                  <div class="types-erc-detail  text-center">
                      <div class>
                          <picture>
                              <source
                                  type="image/webp"
                                  srcset="assets-new/images/real-world-asset-tokenization/hands-on-experince.webp" />

                              <img loading="lazy"
                                  data-src="assets-new/images/real-world-asset-tokenization/hands-on-experince.png"
                                  class="img-fluid"
                                  alt="Hands On Experience"
                                  width="75"
                                  height="75" />
                          </picture>
                      </div>
                      <h5 class="erc-20-head-alt h-4">100% Hands-on experience on projects</h5>
                  </div>
              </div>
              <div class="col">
                  <div class="types-erc-detail  text-center">
                      <div class>
                          <picture>
                              <source
                                  type="image/webp"
                                  srcset="assets-new/images/real-world-asset-tokenization/learning-innovating.webp" />

                              <img loading="lazy"
                                  data-src="assets-new/images/real-world-asset-tokenization/learning-innovating.png"
                                  class="img-fluid"
                                  alt="Learning and innovating"
                                  width="75"
                                  height="75" />
                          </picture>
                      </div>
                      <h5 class="erc-20-head-alt h-4">Completely open to learning and innovating.</h5>
                  </div>
              </div>
              <div class="col">
                  <div class="types-erc-detail  text-center">
                      <div class>
                          <picture>
                              <source
                                  type="image/webp"
                                  srcset="assets-new/images/real-world-asset-tokenization/customized-solutions.webp" />

                              <img loading="lazy"
                                  data-src="assets-new/images/real-world-asset-tokenization/customized-solutions.png"
                                  class="img-fluid"
                                  alt="Secured and Customized Solutions"
                                  width="75"
                                  height="75" />
                          </picture>
                      </div>
                      <h5 class="erc-20-head-alt h-4">Secured and Customized solutions</h5>
                  </div>
              </div>
              <div class="col">
                  <div class="types-erc-detail  text-center">
                      <div class>
                          <picture>
                              <source
                                  type="image/webp"
                                  srcset="assets-new/images/real-world-asset-tokenization/unique-features.webp" />

                              <img loading="lazy"
                                  data-src="assets-new/images/real-world-asset-tokenization/unique-features.png"
                                  class="img-fluid"
                                  alt="Unique Features to Complement Operations"
                                  width="75"
                                  height="75" />
                          </picture>
                      </div>
                      <h5 class="erc-20-head-alt h-4">Unique features to complement operations</h5>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<!-- FAQ Start -->
<section class="section-service-main">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            Frequently Asked Question <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What Is Asset Tokenization?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The process of converting real-world physical assets such as
                    real estate, artworks, commodities, etc., as digital tokens
                    on the blockchain network. These tokens represent ownership
                    and facilities in transferring, providing fraction
                    ownership, liquidity, and more.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What Are The Benefits Of Asset Tokenization?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The major benefit of Real World Asset tokenization is that
                    it provides liquidity and eases trading, exchange, and
                    investments convent. Secondly, digital tokenization brings
                    transparency and security over tamper-proof ownership
                    records.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  How Do You Tokenize Assets?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The process in general refers to creating a digital
                    representation of assets on a blockchain network. This
                    process makes token buying and selling simple, opening a
                    wide arena for investors to act on.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  How Does RWA tokenization development work?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    RWA Tokenization works in a very similar manner to the other NFTs. Once the owner or custodian lists the asset in the platform, it is open for bidding, buying or however the buying mechanism is designed for the project. By paying the USDT or the crypto for the token, the buyer takes ownership of the asset. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  Which blockchain platforms support asset tokenization?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Almost every Blockchain network supports asset tokenizations. Usually, the tokens are developed on Ethereum, Binance, Hyperledger, Tetra, Solana, Arbitin, etc. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix">
                  What is real-world asset tokenization?
                </button>
              </div>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The process of bringing a digital entity for a real-world asset through tokenization in blockchain is called real-world asset tokenization. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSeven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven">
                  What is the market size of the real-world asset tokenization industry?
                </button>
              </div>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    $5 Billion is the market size of real-world asset tokenization, while if we include the trade finance gap, potential addressable market, and exclude stablecoins, this might be somewhere around $14 trillion.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingEight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight">
                  What are the benefits of asset tokenization?
                </button>
              </div>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The benefits of real-world asset tokenization include fractional ownership, broader financial investment opportunities, security over assets, transparency, and credibility. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-alt">
  <app-blog></app-blog>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>
<!-- Contact End -->
