<div class="techstack">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="casetudies_common">
          <div class="common-sub-heading sub-title">
            We Utilize<span class="dots">.</span>
          </div>
          <h3 class="main-sub-heading">Prime Technologies In Deployment</h3>
        </div>
      </div>
    </div>
    <div
      class="row row-cols-3 row-cols-sm-4 row-cols-md-6 row-cols-lg-7 my-5 justify-content-center"
    >
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/apecoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/apecoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/apecoin.png" alt="Apecoin" title="Apecoin" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/cosmos.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/cosmos.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/cosmos.png" alt="Cosmos" title="Cosmos" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/cronos.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/cronos.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/cronos.png" alt="Cronos" title="Cronos" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/daicoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/daicoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/daicoin.png" alt="Daicoin" title="Daicoin" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/decentraland.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/decentraland.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/decentraland.png" alt="Decentraland" title="Decentraland" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/elrond.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/elrond.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/elrond.png" alt="Elrond" title="Elrond" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/flow.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/flow.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/flow.png" alt="Flow" title="Flow" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/ftx.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/ftx.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/ftx.png" alt="FTX" title="FTX" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/hedera.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/hedera.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/hedera.png" alt="Hedera" title="Hedera" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/internet-computer.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/internet-computer.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/internet-computer.png" alt="Internet computer" title="Internet computer" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/filecoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/filecoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/filecoin.png" alt="Filecoin" title="Filecoin" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/kucoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/kucoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/kucoin.png" alt="KuCoin" title="KuCoin" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/monero.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/monero.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/monero.png" alt="Monero" title="Monero" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/nearprotocol.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/nearprotocol.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/nearprotocol.png" alt="Nearprotocol" title="Nearprotocol" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/polkadot.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/polkadot.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/polkadot.png" alt="Polkadot" title="Polkadot" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/sandbox.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/sandbox.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/sandbox.png" alt="Sandbox" title="Sandbox" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/stellar.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/stellar.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/stellar.png" alt="Stellar" title="Stellar" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/tezor.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/tezor.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/tezor.png" alt="Tezor" title="Tezor" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/unussedleo.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/unussedleo.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/unussedleo.png" alt="Unussedleo" title="Unussedleo" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/vechain.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/vechain.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/vechain.png" alt="Vechain" title="Vechain" class="img-fluid" width="80" height="80"  />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
