import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-web-development-company',
  templateUrl: './web-development-company.component.html',
  styleUrls: ['./web-development-company.component.css']
})
export class WebDevelopmentCompanyComponent implements OnInit {

  title = 'Web3 Development Company | Enterprise Web3 Development Services';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Building highly scalable dApps a leading web3 development company. Build web3 apps that scale as your business grows, with BlockchainX. Connect  to start today.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'web3 development company, web3 development services, web3 development solutions, web3 app development, Web3 development',
    });

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Web3 Development Company | Enterprise Web3 Development Services',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/web3-development-company/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Building highly scalable dApps a leading web3 development company. Build web3 apps that scale as your business grows, with BlockchainX. Connect  to start today.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/web3-development-company.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/web3-development-company/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Web3 Development Company | Enterprise Web3 Development Services',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Building highly scalable dApps a leading web3 development company. Build web3 apps that scale as your business grows, with BlockchainX. Connect  to start today.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/web3-development-company.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/web3-development-company/'
    );
  }

}
