import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-crypto-launchpad-development-steps',
  templateUrl: './crypto-launchpad-development-steps.component.html',
  styleUrls: ['./crypto-launchpad-development-steps.component.css'],
})
export class CryptoLaunchpadDevelopmentStepsComponent implements OnInit {
  title =
    'Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad.';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Launchpad is the best place to find authentic and verified crypto projects by streamlining growth, evolution, and funding. Be an early adopter of crypto-launchpad development projects to get the most out of them.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Crypto launchpad development, key features of crypto launchpad, crypto launchpad development solutions',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad.',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Launchpad is the best place to find authentic and verified crypto projects by streamlining growth, evolution, and funding. Be an early adopter of crypto-launchpad development projects to get the most out of them.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/crypto-launchpad-development-steps.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad.',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Launchpad is the best place to find authentic and verified crypto projects by streamlining growth, evolution, and funding. Be an early adopter of crypto-launchpad development projects to get the most out of them.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/crypto-launchpad-development-steps.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad.html'
    );
    this.doc.head.appendChild(link);
  }
}
