import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-sidebar',
  templateUrl: './news-sidebar.component.html',
  styleUrls: ['./news-sidebar.component.css']
})
export class NewsSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
