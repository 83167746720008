<div class="social-share">
  <div>
    <p class="pb-0 h-3">Share</p>
  </div>
  <div class="d-flex align-items-center gap-3">
    <share-buttons
      theme="circles-dark"
      [include]="['facebook', 'linkedin', 'twitter']"
      [showIcon]="true"
      [showText]="false"
      url="{{ link }}"
      description="Blockchainx"
      twitterAccount="Blockchainx"
      class="g-4"
    >
    </share-buttons>
  </div>
</div>

<!-- <share-buttons
  theme="modern-dark"
  [include]="[
    'copy',
    'facebook',
    'email',
    'messenger',
    'mix',
    'line',
    'linkedin',
    'pinterest',
    'print',
    'reddit',
    'sms',
    'telegram',
    'tumblr',
    'twitter',
    'viber',
    'vk',
    'xing',
    'whatsapp'
  ]"
  [showIcon]="true"
  [showText]="true"
  url="https://rodrigo.kamada.com.br/"
  description="Angular Share Buttons"
  twitterAccount="rodrigokamada"
  class="pt-5"
>
</share-buttons>

<share-buttons
  theme="modern-dark"
  [include]="[
    'copy',
    'facebook',
    'email',
    'messenger',
    'mix',
    'line',
    'linkedin',
    'pinterest',
    'print',
    'reddit',
    'sms',
    'telegram',
    'tumblr',
    'twitter',
    'viber',
    'vk',
    'xing',
    'whatsapp'
  ]"
  [showIcon]="false"
  [showText]="true"
  url="https://rodrigo.kamada.com.br/"
  description="Angular Share Buttons"
  twitterAccount="rodrigokamada"
  class="pt-5"
>
</share-buttons> -->
