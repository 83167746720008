<section class="banners-section" #about>
    <div class="banner_wrapper">
      <div class="container" id="common-relative">
        <div class="green-vector"></div>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div class="banner_contentWrapper">
                <div class="caption">
                  <h1>
                    <span><strong>Stablecoin  </strong></span> Development Company
                  </h1>
                  <p>
                    Next-gen stablecoin development services that hold the basics of your project strong and stable to sail through the hassles!
                  </p>

                  <div class="contact-link">
                    <a [routerLink]="['/contact']" class="home-link"
                      >Talk To Our Experts</a
                    >
                    <a [routerLink]="['/case-studies']" class="home-link-alt"
                      >View Case Study</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 text-center">
              <div class="banner-right-section">
                <picture *ngIf="!isDarkmode">
                  <source type="image/webp" srcset="assets-new/images/stablecoin/stablecoin-development.webp" />
                  <source type="image/png" srcset="assets-new/images/stablecoin/stablecoin-development.png" />
                  <img loading="lazy"  data-src="assets-new/images/stablecoin/stablecoin-development.png" width="500" height="500" class="img-fluid" alt="Stablecoin Development Company" title="Stablecoin Development Company" />
                </picture>
                <picture *ngIf="isDarkmode">
                  <source type="image/webp" srcset="assets-new/images/stablecoin/light-theme/stablecoin-development.webp" />
                  <source type="image/png" srcset="assets-new/images/stablecoin/light-theme/stablecoin-development.png" />
                  <img loading="lazy"  data-src="assets-new/images/stablecoin/light-theme/stablecoin-development.png" width="500" height="500" class="img-fluid" alt="Stablecoin Development Company" title="Stablecoin Development Company" />
                </picture>
              </div>
            </div>

        </div>
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img loading="lazy"

                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
      <div class="whys_section mb-0 text-center">
        <h2 class="pb-lg-5 pb-4"><span>Stablecoin </span>Development Services</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
          <div class="bep-work-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/why-should-you-choose.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/why-should-you-choose.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/why-should-you-choose.png"
                width="350" height="350" alt="Stablecoin Development Services"
                title="Stablecoin Development Services" class="BSC-Tokens-image img-fluid">
            </picture>
          </div>
        </div>
        <div
          class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <p>
                Stablecoins for projects are the first and most stabilized cryptocurrency that is designed to have a relatively stable price, throughout the pegged-to-commodity or currency period. It's regulated by the network algorithm. We customize stablecoin development service for your project and ensure to deliver high-quality service in rendering your token. We keenly take your stable coin development process, starting from the ideating concept development, testing, and deployment to ongoing support throughout the project.
            </p>
          </div>

          <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  No Volatile Risk
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> High Liquidity
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Widespread Integration
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Energy Efficient
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!-- End -->

<!-- Trending ERC20 Token List Start -->
<div class="section-service-main">
    <div class="tending-bep-20">
      <div class="container">
        <div class="row trending-token-list">
          <div class="col-lg-6 text-center order-lg-first order-last">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/trending-erc20-token-list.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/trending-erc20-token-list.png"
                width="405" height="405" alt="Stablecoin Development Solution"
                title="Stablecoin Development Solution" class="img-fluid mx-auto">
            </picture>
          </div>
          <div class="col-lg-6 mb-lg-0 mb-4">
            <h3 class="main-sub-heading text-start"><span>Famous Stablecoin </span> Development Solution</h3>

            <div class="row trending-bep">
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/uniswap-v3-polygon/">Uniswap V3</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/balancer-v2-polygon/">Balancer</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> TrueUSD
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> DAI
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> Binance USD
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> USD Coin
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> Tether
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> Frax (FRAX)
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> Pax Dollar (USDP)
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> Liquity USD (LUSD)
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex pt-3">
              <a [routerLink]="['/contact']" class="home-link-alt">Connect With
                Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>Stablecoin </span>Development Services</h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                <source type="image/png" srcset="assets-new/images/token images/project-consulting.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="Stablecoin Consulting" title="Stablecoin Consulting" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Stablecoin Consulting and advisory</h4>
            <p>
                Our very first step with Stablecoin development would be to kickstart with advisory services for the same, we ensure to guide you through the nuances of the market and make wise choices.
            </p>
            <div class="content-link">
                <a [routerLink]="['/blockchain-consulting-services']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="Stablecoin Development" title="Stablecoin Development" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Stablecoin Development
            </h4>
            <p>
                We customize your stablecoin for your wish and will. Ensure it supports your projects and contributes to scaling up in the market. From conceptualizing to launching your Stablecoin, we take care completely.
            </p>
            <div class="content-link">
                <a [routerLink]="['/token-development-company']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="Regulatory Compliance" title="Regulatory Compliance" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Regulatory Compliance </h4>
            <p>
                Our stablecoin solution evolves and navigates you through the regulatory landscape and ensures to meet that requirement and stay updated. This shall ensure your token is meeting the guidance and legal frameworks, KYC/AML procedures all that ease.
            </p>
            <div class="content-link">
                <a [routerLink]="['/']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Token Integration" title="Token Integration" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Token Integration and Wallet</h4>
            <p>
                Our expert developers and teams ensure to avail you of a seamless process in any integration as you demand and incorporate the perfect meter into your stablecoin. From wallet, exchange, and decentralized application, we customize services for you!
            </p>
            <div class="content-link">
                <a [routerLink]="['/cryptocurrency-wallet-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="Security and Audit Services" title="Security and Audit Services" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Security and Audit Services</h4>
            <p>
                We ensure the stablecoin development is secured, and safe and integrate high values in it. We get your stablecoin into a complete security audit identify vulnerabilities and work on it to strengthen to make it error-free.
            </p>
            <div class="content-link">
                <a [routerLink]="['/smart-contract-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Stablecoin Development Platform" title="Stablecoin Development Platform" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Stablecoin Development Platform
            </h4>
            <p>
                We also curate ICO platforms for different projects to list and raise funding, seamlessly trade, and transact on the globalized decentralized market with ease. We provide 100%customization in the same.
            </p>
            <div class="content-link">
                <a [routerLink]="['/ready-made-ico-software']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
      </div>
      
    </div>
</section>
<!-- Related Services -->

  <!-- why-choose-section -->
  <section class="section-service-main">
      <div class="green-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 metmb-3">
            <div class="whys_section mb-0">
              <h2>
                <span>BlockchainX Empowering Business With  </span>Stablecoin Development Services
              </h2>
              <p>
                As a stablecoin development company, we ensure to custom develop stable coins and also upgrade your stablecoin for your projects. Our expert blockchain specialists have hands-on experience in curating avant-grade tokens that back your business, help in terms of funding, and also stand up for your business in the global market. We ensure to provide 24/7 support with stable coin development services and provide on-time deliveries.
              </p>
              <!-- <ul>
                <li>Expert technological consultants embarking possibility</li>
                <li>Experience developers with a heart to learn</li>
                <li>Breeding business across the globe</li>
                <li>24/7 support pre and post-development</li>
                <li>Transforming ideas into innovation</li>
              </ul> -->
              <div class="pt-lg-3 pt-0">
                <a [routerLink]="['/contact']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <!-- <hr /> -->
              </div>
            </div>
            <div class="whysection_text_wrap">
            </div>
          </div>
          <div class="col-lg-6 metmb-3 Why-section">
            <div class="why_right_side">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                <img loading="lazy"
                  data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                  width="624"
                  height="521"
                  class="img-fluid"
                  alt="Stablecoin Development Company"
                  title="Stablecoin Development Company"
                   />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    Where is a stable coin used?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Stbalecoin is used in the crypto world to mitigate volatility for your project, Its value is pegged to be a stable asset and it provides a reliable value store for investors and users.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How to choose the right stablecoin development company?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        To choose the right stablecoin development company, make sure they have in hand experience in developing stablecoin, are open to customize, and learn and infuse new technology making your project stronger. BlockchainX can provide these premise services all in one. Connect to know more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    What are the different functionalities of a Stablecoin?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        They are used to transfer funds quickly and securely without losing their value, used in the DeFi applications, and provide liquidity and stability for your project in the crypto-verse.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="1024"
                    height="624"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="1024"
                    height="624"
                    class="img-fluid"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="1024"
                    height="624"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

  <!-- Contact Start -->
  <section class="section-service-alt">
    <div class="app-get-in-touch-form">
      <div class="container">
          <div class="contact-form">
              <div class="row reverse-colum">
                <div class="col-lg-5 col-md-12 contact-social-links">
                  <h3 class="main-sub-heading"><span>From customization to captivation, leverage on </span></h3>
                  <p>Our Stablecoin Development Services</p>
                  <div class="contact-main-list">
                    <div class="social-links">
                      <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            alt="linkedin"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            alt="contact-Whatsapp"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            alt="twitter"
                            width="40"
                            height="40"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                  <div class="contact-links">
                    <h4>Follow us on</h4>
                    <div class="social-links">
                      <a
                        href="https://www.linkedin.com/company/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="linkdin"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/linkdin.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/linkdin.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/linkdin.png"
                            alt="linkedin"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://www.instagram.com/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="instagram"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/instagram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/instagram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/instagram.png"
                            alt="instagram"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://twitter.com/Blockchainxtech"
                        target="_blank"
                rel="noopener noreferrer"
                        class="twitter"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/twitterx.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/twitterx.svg"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/twitterx.svg"
                            alt="twitter"
                            width="25"
                            height="25"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-md-12 contact-inputs">
                  <div class="getintouch getintochform bitrix-snippet-form">
                    <div [innerHTML]="bitrixSnippetScript"></div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  <!-- Contact End -->
