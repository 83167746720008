<!-- Banner Section -->
<div class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span>
                  <strong> Cryptocurrency Development </strong> Company</span
                >
              </h1>
              <p>
                Explore the potential of your project with our cryptocurrency development services, used to create your cryptocurrencies on the blockchain of your choice, embracing your ultimatum!
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/cryptocurrency/cryptocurrency-development-company.webp"  />
              <source type="image/png" srcset="assets-new/images/cryptocurrency/cryptocurrency-development-company.png" />
              <img loading="lazy" data-src="assets-new/images/cryptocurrency/cryptocurrency-development-company.png"  class="img-fluid" alt="Cryptocurrency Development-img" title="Cryptocurrency Development" width="400" height="400"/>
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/cryptocurrency/light-theme/cryptocurrency-development-company.webp"  />
              <source type="image/png" srcset="assets-new/images/cryptocurrency/light-theme/cryptocurrency-development-company.png" />
              <img loading="lazy" data-src="assets-new/images/cryptocurrency/light-theme/cryptocurrency-development-company.png"  class="img-fluid" alt="Cryptocurrency Development-img" title="Cryptocurrency Development" width="400" height="400"/>
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png"
            />
            <img loading="lazy"

              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!-- Why Choose DAO Platform Development start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 mx-auto col-12 order-lg-first order-last">
        <div class="bep-work-section pb-0 pt-lg-0 pt-3 text-center">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/cryptocurrency-development-company-2.webp"/>
            <source type="image/png" srcset="assets-new/images/cryptocurrency/cryptocurrency-development-company-2.png"/>
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/cryptocurrency-development-company-2.png"  class="img-fluid" alt="Cryptocurrency development company-img" title="Cryptocurrency development company" width="400"height="300">
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-12 about-content">
        <div class="whys_section mb-0">
          <h2><span>Cryptocurrency</span> Development Company</h2>
          <p>
            Cryptocurrency is revolutionizing the economy, and it may be the most significant revolution in the coming centuries. Crypto development has been prominently adopted by major business sectors that can be customized based on the need. This also branches out in the needs of online investment, transactions, tokenization, and much more. We provide assistance in delivering efficiently secured features that enable cutting-edge prospects. Our cryptocurrency developers are skilled in creating decentralized, distinct crypto tokens that give us a competitive advantage in the market.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose DAO Platform Development end -->

<!-- Types Of DAOs starts -->
<div class="section-service-alt-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>Explore Our Cryptocurrency </span> Development Services</h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                 <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-consulting-services.webp" />
                 <source type="image/png" srcset="assets-new/images/cryptocurrency/crypto-consulting-services.png" />
                 <img loading="lazy" data-src="assets-new/images/cryptocurrency/crypto-consulting-services.png"  class="img-fluid"
                 alt="Crypto Consulting-img" title="Crypto Consulting" width="75" height="75">
              </picture>

            </div>
            <h4 class="erc-20-head-alt text-left">
              Crypto Consultation
            </h4>
            <p>
              We offer crypto consulting services where our experts have years of experience, and expertise to provide first-class solutions and clarity
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-coin-development.webp" />
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-coin-development.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/crypto-coin-development.png"  class="img-fluid"
                alt="Crypto Coin-img" title="Crypto Coin" width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Crypto Coin Development</h4>
            <p>
              We have an experienced workforce to create the most latest in-demand crypto coins for your business meeting your peculiar needs.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-wallet-development.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/crypto-wallet-development.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/crypto-wallet-development.png"  class="img-fluid" alt="Crypto-wallet-img" title="Crypto-wallet" width="75" height="75" >
              </picture>

            </div>
            <h4 class="erc-20-head-alt text-left">Crypto Wallet Development</h4>
            <p>
              We develop customized cryptocurrency wallets with advanced security features for users to make instant payments, secure transactions and track things.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-exchange-development.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/crypto-exchange-development.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/crypto-exchange-development.png"  class="img-fluid" alt="Crypto Exchange-img" title="Crypto Exchange" width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              Crypto Exchange Development
            </h4>
            <p>
              The exchange platform gives the ability to include and expand your prospectus among different networks and ease transactions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/altcoin-development-service.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/altcoin-development-service.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/altcoin-development-service.png" class="img-fluid" alt="Altcoin Development-img" title="Altcoin Development"  width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              Altcoin Development 
            </h4>
            <p>
              Develop feature-rich altcoins that embed transparency, security, and trust at use.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/ico-development.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/ico-development.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/ico-development.png"  class="img-fluid" alt="ICO Development-img" title="ICO Development" width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">ICO Development</h4>
            <p>
              We provide end-to-end ICO development services including token development, crypto listing & other associated services.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/defi-development.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/defi-development.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/defi-development.png"  class="img-fluid" alt="DeFi Development-img" title="DeFi Development" width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">DeFi Development</h4>
            <p>
              Based on your requirement our decentralized finance developments proceed to contribute and meet your specific requirements like fundraising, securities, etc.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-listing-services.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/crypto-listing-services.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/crypto-listing-services.png"  class="img-fluid" alt="Crypto Listing-img" title="Crypto Listing" width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Crypto Listing</h4>
            <p>
              Our team helps you get your crypto coin listed quickly and easily, through a seamless experience.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/cryptocurrency/token-development.webp" />
                <source type="image/png" srcset="assets-new/images/cryptocurrency/token-development.png" />
                <img loading="lazy" data-src="assets-new/images/cryptocurrency/token-development.png"  class="img-fluid" alt="Token Development-img" title="Token Development" width="75" height="75" >
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Token Development</h4>
            <p>
              We help enterprises & businesses create compatible tokens and we have created tokens based on popular standards like ERC-20, BEP-20 & so on.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Types Of DAOs ends -->

  <div class="meta-expert">
    <div class="violet-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="our_experts"> 
            <h2><span>Cryptocurrency, is widely used to monetize, </span> reward, earn, and regulate, What is your need?
              We shall provide!</h2>
            <div class="text-center my-4">
              <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Build Your Project</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- Autonomous Network starts -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>
            What are the
            <span> Benefits of Creating Cryptocurrency </span> For Projects?
          </h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="process-image pt-30">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/crypto-users.webp" />
            <source type="image/png" srcset="assets-new/images/cryptocurrency/crypto-users.png" />
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/crypto-users.png"  class="img-fluid" alt="Crypto Users-img" title="Crypto Users" width="160" height="160" >
          </picture>
          <h4 class="erc-20-head">Crypto Users</h4>
          <p class="text-center">
            Increases customer traction through listing your token in public exchange that gains exposure among the public and a value in the market.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="process-image pt-30">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/non-crypto-users.webp" />
            <source type="image/png" srcset="assets-new/images/cryptocurrency/non-crypto-users.png" />
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/non-crypto-users.png"  class="img-fluid" alt="Non-Crypto Users-img" title="Non-Crypto Users" width="160" height="160" >
          </picture>
          <h4 class="erc-20-head">Non-Crypto Users</h4>
          <p class="text-center">
            This attracts new customers with high-tech coin development features and appropriate exchange solutions.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Autonomous Network ends -->

<!-- Autonomous Network starts -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>
            Our Approach With <span>Cryptocurrency Services</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="process-image pt-30">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/attention-to-securitu-feactures.webp" />
            <source type="image/png" srcset="assets-new/images/cryptocurrency/attention-to-securitu-feactures.png" />
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/attention-to-securitu-feactures.png" class="img-fluid" alt="Attention-to-security" title="Attention-to-security"  width="240" height="330" >
          </picture>
          <h4 class="erc-20-head">Attention to security features</h4>
          <p class="text-center">
            Cryptocurrency development requires the best security features. Our development team ensures security by utilizing the best features and auditing the code multiple times.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="process-image pt-30">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/technically-sound-squad.webp" />
            <source type="image/png" srcset="assets-new/images/cryptocurrency/technically-sound-squad.png" />
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/technically-sound-squad.png"  class="img-fluid" alt="Technically-sound-squad" title="Technically-sound-squad" width="327" height="286" >
          </picture>
          <h4 class="erc-20-head">Technically sound squad</h4>
          <p class="text-center">
            Our developers are certified engineers with extensive technical knowledge & experience in providing the most productive cryptocurrency services.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="process-image pt-30">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/availablity-and-support.webp" />
            <source type="image/png" srcset="assets-new/images/cryptocurrency/availablity-and-support.png" />
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/availablity-and-support.png"  class="img-fluid" alt="Availability-and-support" title="Availability-and-support" width="328" height="266" >
          </picture>
          <h4 class="erc-20-head">24*7 Availability and Support</h4>
          <p class="text-center">
            Our enthusiastic team is available to assist you 24*7. You can contact us to discuss your development ideas and receive immediate responses.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="process-image pt-30">
          <picture>
            <source type="image/webp" srcset="assets-new/images/cryptocurrency/customized-services.webp" />
            <source type="image/png" srcset="assets-new/images/cryptocurrency/customized-services.png" />
            <img loading="lazy" data-src="assets-new/images/cryptocurrency/customized-services.png"  class="img-fluid" alt="Customized-services" title="Customized-services" width="160" height="160" >
          </picture>
          <h4 class="erc-20-head">Customized services for each client</h4>
          <p class="text-center">
            Each business has a unique necessity & we strive to provide the most adaptable, customized, & high-end cryptocurrency development services for each client.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Autonomous Network ends -->

<!-- Development Services ends -->
<div class="section-service-alt-padd">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3>Execution <span>Process Of Cryptocurrency</span> Development</h3>
          </div>
        </div>
      </div>
      <!-- Fast Transactions Starts -->
      <div class="row align-items-center erc-processing">
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Discover Requirements</h4>
            <p>
              We understand your business goals, pain points, and priorities.  Through multiple brainstorming sessions, we assist in mapping your existing workflows & future goals.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Strategy and Solution Design</h4>
            <p>
              After gathering your requirements, we begin designing, prototyping, and user testing your platform or product at this stage.
            </p>
          </div>
        </div>

        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Development Phase</h4>
            <p>
              The process of developing a cryptocurrency, including jotting your algorithms, hash values & block exploration based on your approved designs, takes place in this stage.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Testing and QA</h4>
            <p>
              We continue our data-derived validation process and conduct extensive QA testing for each release of your product or platform to make sure the final product is market-ready.
            </p>
          </div>
        </div>

        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Launch and Maintenance</h4>
            <p>
              Your product or platform is now live. Our developers will publish your product in live environments once you approve it.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Support and Customer Service</h4>
            <p>
              We perform ongoing product and platform maintenance to optimize and provide real/fix support to ensure continuous improvement
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Fast Transactions ends  -->

<div class="meta-expert">
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="our_experts"> 
          <h2><span>Customize your Cryptocurrency tokens in the desired Blockchain</span></h2>
          <div class="text-center mt-lg-5 mt-4 mb-4">
            <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Connect now!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Product Features -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading">
          <h3><span>Crypto Token </span> Standards We Expertise</h3>
        </div>
      </div>
    </div>
    <div
      class="row row-cols-3 row-cols-sm-4 row-cols-md-6 row-cols-lg-7 justify-content-center tron-token-images"
    >
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/apecoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/apecoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/apecoin.png"  class="img-fluid" alt="erc-10" title="erc-10" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/cosmos.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/cosmos.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/cosmos.png"  class="img-fluid" alt="Cosmos" title="Cosmos" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/cronos.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/cronos.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/cronos.png"  class="img-fluid" alt="erc-223" title="erc-223" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/daicoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/daicoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/daicoin.png"  class="img-fluid" alt="erc-721" title="erc-721" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/decentraland.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/decentraland.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/decentraland.png"  class="img-fluid" alt="erc-827" title="erc-827" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/elrond.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/elrond.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/elrond.png"  class="img-fluid" alt="erc-1400" title="erc-1400" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/flow.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/flow.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/flow.png"  class="img-fluid" alt="erc-1155" title="erc-1155" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/ftx.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/ftx.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/ftx.png"  class="img-fluid" alt="trc-10" title="trc-10" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/hedera.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/hedera.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/hedera.png"  class="img-fluid" alt="trc-20" title="trc-20" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/internet-computer.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/internet-computer.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/internet-computer.png"  class="img-fluid" alt="trc-721" title="trc-721" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/filecoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/filecoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/filecoin.png"  class="img-fluid" alt="bep-20" title="bep-20" width="80" height="80" >
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source type="image/webp" srcset="assets-new/images/business-tech/kucoin.webp" />
            <source type="image/png" srcset="assets-new/images/business-tech/kucoin.png" />
            <img loading="lazy" data-src="assets-new/images/business-tech/kucoin.png"  class="img-fluid" alt="bep-1155" title="bep-1155" width="80" height="80" >
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Why Choose Us -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>
              BlockchainX for
              <span> Cryptocurrency Development?</span>
            </h2>
          </div>
          <div class="whysection_text_wrap">
            <p class="pb-3">
              Over the years, we have been dedicated to providing customized, solid, and functional solutions with the advancements of Blockchain. Our services expand from token development to crypto ecosystems in different blockchains. At BlockchainX we provide support to a wide range of businesses in a variety of industries. Our expert team manages tasks efficiently and effectively to ensure the best results.
            </p>
            <div class="whysection_text_wrap">
              <ul>
                <li>Providing 24 x 7 support</li>
                <li>Maintenance and upgrades</li>
                <li>Customization with specifications</li>
              </ul>
            </div>
            <div class="content-link">
              <a [routerLink]="['/contact']">
                <!-- <span>Read More</span> -->
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source type="image/webp" srcset="assets-new/images/metaverse/why_blockchain.webp" />
              <source type="image/png" srcset="assets-new/images/metaverse/why_blockchain.png" />
              <img loading="lazy" data-src="assets-new/images/metaverse/why_blockchain.png" class="img-fluid pt-0" alt="why-blockchainX" title="why-blockchainX" width="550" height="450" >
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Us -->

<section class="section-service-main">
<app-blog></app-blog>
</section>

<section class="section-service-alt">
<app-get-in-touch></app-get-in-touch>
</section>
