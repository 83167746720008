<!-- Banner Section -->
<div class="banners-section">
  
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> <strong> DAO Development </strong> Company</span>
              </h1>
              <p>
                Deploy decentralized control over the centralized management
                through DAO platform development services. A great investment to
                build financial collateral with captivating effects.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a
                    href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dao-development/dao-development-company.webp
                " />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dao-development/dao-development-company.png
                " />

              <img loading="lazy"
                data-src="assets-new/images/dao-development/dao-development-company.png"
                alt="Corda App Consulting"
                class="img-fluid"
                alt=" DAO Development Company"
                title="DAO Development Company"
                width="400"
                height="400" />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section ends -->
<!-- Why Choose DAO Platform Development start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 mx-auto col-md-6 col-sm-12 col-12">
        <div class="bep-work-section text-center">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/dao-Blockchain-development-platform.webp
              " />

            <source
              type="image/png"
              srcset="
                assets-new/images/dao-development/dao-Blockchain-development-platform.png
              " />

            <img loading="lazy"
              data-src="assets-new/images/dao-development/dao-Blockchain-development-platform.png"
              alt="Corda App Consulting"
              class="img-fluid"
              alt=" DAO Development Company"
              title=" DAO Development Company"
              width="400"
              height="400" />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading">DAO Blockchain Development Platform</h3>
          <p>
            The Decentralised Autonomous organization is a completely
            independent acting body, which has no centralized control, and the
            smart contract guides and guards their way in the web3 exploration.
            Meanwhile establishing trust among the users, customers, employees,
            and the community, DAO Blockchain Development is a fully functional
            body captivating investors' interests. Extending 100% transparency,
            mandatory voting rights, and automated services handling ability, we
            render customized DAO Platform development services.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose DAO Platform Development end -->
<!-- Development Services starts -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>
            Opportunities for developing <span>DAO platform software</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/consensus-algorithm.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/dao-development/consensus-algorithm.png" />

            <img loading="lazy"
              data-src="assets-new/images/dao-development/consensus-algorithm.png"
              alt="Consensus-Algorithm"
              class="img-fluid"
              title="Consensus-Algorithm"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Consensus Algorithm</h4>
          <p class="text-center">
            A completed DAO platform running on Solidity smart contract to enter
            the decentralized organization of governance.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/smart-contract-creation.webp
              " />

            <source
              type="image/png"
              srcset="
                assets-new/images/dao-development/smart-contract-creation.png
              " />

            <img loading="lazy"
              data-src="assets-new/images/dao-development/smart-contract-creation.png"
              alt="Smart contracts"
              class="img-fluid"
              title="Smart contracts"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Smart contracts</h4>
          <p class="text-center">
            Our Blockchain experts are very approachable anytime and always
            stick around to guide through on-chain within the run time.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/additional-services.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/dao-development/additional-services.png" />

            <img loading="lazy"
              data-src="assets-new/images/dao-development/additional-services.png"
              alt="additional services"
              class="img-fluid"
              title="additional services"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Additional services</h4>
          <p class="text-center">
            Meet the core value of your business opting to develop dApp in the
            DAO blockchain platform developed with us.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/member-reward-model.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/dao-development/member-reward-model.png" />

            <img loading="lazy"
              data-src="assets-new/images/dao-development/member-reward-model.png"
              class="img-fluid"
              alt="Member-Reward"
              title="Member-Reward"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Member Reward Model</h4>
          <p class="text-center">
            Hold the interest of the user with an attractive reward stream where
            member rewards are core in ur DAOdevelopment.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->
<div class="section-service-alt-padd">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3><span>DAO</span> Advantages</h3>
          </div>
        </div>
      </div>
      <!-- Fast Transactions Starts -->
      <div class="row align-items-center erc-processing justify-content-center">
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process text-center cmb-5">
            <h4>Decentralization</h4>
            <p class="text-center">
              DAO eliminates the centralized third-party governance and extends
              complete decentralized authority and decision-making opportunity
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process text-center">
            <h4>Transparency</h4>
            <p class="text-center">
              DAOs are blockchain powered, inheriting their immutability and
              security to record and manage data, extending transparency.
            </p>
          </div>
        </div>

        <div class="col-lg-6 metmb-3">
          <div class="virtual_process text-center cmb-5">
            <h4>Global Access</h4>
            <p class="text-center">
              Unlink traditional organizations, and DAO facilities over
              geographical limitations and have no barriers and their structures
              are very flexible.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process text-center">
            <h4>Community</h4>
            <p class="text-center">
              The DAO is most popular for its community involvement strategies
              and all participants are equally advantageous and important in
              co-creating the goal.
            </p>
          </div>
        </div>

        <div class="col-lg-6 metmb-3">
          <div class="virtual_process text-center">
            <h4>Active Stakes</h4>
            <p class="text-center">
              DAO provides active stakes to its members in the organization,
              making the future of DAO bigger by enhancing utility benefits to
              governance tokens.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Fast Transactions ends  -->
<!-- Types Of DAOs starts -->
<div class="section-service-alt-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>DAO Platform Development</span> Services</h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/dao-development/dao-token-development.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/dao-development/dao-token-development.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/dao-development/dao-token-development.png"
                  class="img-fluid"
                  alt="Member-Reward"
                  title="Member-Reward"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">DAO Token Development</h4>
            <p>
              We render DAO Token Development Services on blockchain technology,
              completely decentralized, and secured with voting authority.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/dao-development/dao-smart-contract-development.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/dao-development/dao-smart-contract-development.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/dao-development/dao-smart-contract-development.png"
                  class="img-fluid"
                  alt="Member-Reward"
                  title="Member-Reward"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              DAO Smart Contract Development
            </h4>
            <p>
              An innovative DAO Smart contract development service where we
              ensure automated processes run on predefined rules.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/dao-development/dao-enabled-nft-marketplace.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/dao-development/dao-enabled-nft-marketplace.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/dao-development/dao-enabled-nft-marketplace.png"
                  class="img-fluid"
                  alt="Member-Reward"
                  title="Member-Reward"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              DAO Enabled NFT Marketplace
            </h4>
            <p>
              DAO enabled NFT marketplace is a great emphasis on the demands and
              a great gig to rid red flags.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/dao-development/node-development-for-dao.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/dao-development/node-development-for-dao.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/dao-development/node-development-for-dao.png"
                  class="img-fluid"
                  alt="node-development-for-dao"
                  title="node-development-for-dao"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Node Development For DAO</h4>
            <p>
              As a leading DAO development company, we also render DAO node
              development used to vote in DAO that gains self sustainability.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/dao-development/dapp-development-for-dao.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/dao-development/dapp-development-for-dao.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/dao-development/dapp-development-for-dao.png"
                  class="img-fluid"
                  alt="dapp-development-for-dao"
                  title="dapp-development-for-dao"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Dapp Development for DAO</h4>
            <p>
              The Dapp Development for DAO functions like a wallet for users to
              access while trading on DAO and yield profits.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/dao-development/dao-blockchain-development.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/dao-development/dao-blockchain-development.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/dao-development/dao-blockchain-development.png"
                  class="img-fluid"
                  alt="dapp-development-for-dao"
                  title="dapp-development-for-dao"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              Dao Blockchain Development
            </h4>
            <p>
              Our DAO blockchain development services help business function in
              a completely decentralized and automated entity.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Types Of DAOs ends -->
<!-- Autonomous Network starts -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>
            Features of DAO <span>(Decentralized Autonomous Network)</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/dao-development/open-source-code.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/dao-development/open-source-code.png" />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/open-source-code.png"
              width="100"
              height="100"
              alt="Open-Source"
              title="Open-Source"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Open Source Code</h4>
          <p class="text-center">
            Facilitating the community by offering DAO source code publicly to
            the community and grow eventually.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/dao-development/dao-token.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/dao-development/dao-token.png" />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/dao-token.png"
              width="100"
              height="100"
              alt="DAO Token"
              title="DAO Token"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">DAO Token</h4>
          <p class="text-center">
            Providing financial and real economic progress the DAO governance
            token is capable of preaching surety.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/dao-development/smart-contracts.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/dao-development/smart-contracts.png" />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/smart-contracts.png"
              width="100"
              height="100"
              alt="Smart Contracts "
              title="Smart Contracts "
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Smart Contracts</h4>
          <p class="text-center">
            A trustless system is created with the smart contract elimination
            central body regulations to govern.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/blockchain-technology.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/dao-development/blockchain-technology.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/blockchain-technology.png"
              width="100"
              height="100"
              alt="Blockchain-Technology"
              title="Blockchain-Technology"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Blockchain Technology</h4>
          <p class="text-center">
            The security, immutability, and transparency policies of blockchain
            technology are inherited while developing your DAO.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Autonomous Network ends -->
<!-- Development Services starts -->
<div class="section-service-alt-main">
  <div class="our-process-er">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>Set up A <span>Decentralized Autonomous Organization</span></h3>
          </div>
        </div>
      </div>
      <div class="row erc-processing">
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>The Structure of the DAO</h4>
            <p>
              Analyzing the core structure would give more detail on the working
              of a decentralized autonomous organization.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Type of DAO</h4>
            <p>
              Either investment, protocol, Gran DAO module, or anything else.
              Based on its type the functions vary.
            </p>
          </div>
        </div>

        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Determine the Use Cases</h4>
            <p>
              Set up the use cases and features for it is the most crucial part
              of DAO development services.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Token Allocation to Rewards</h4>
            <p>
              From allotting the token, framing its supply, and rewards
              allocations, the calculation work is intense, to send it for the
              fundraising campaign.
            </p>
          </div>
        </div>

        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Creating a DAO</h4>
            <p>
              To create a DAO platform, either one can opt to involve DAO
              Development platform services from scratch or use templates and
              pre-set tools.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Treasury Community</h4>
            <p>
              By fixing the DAO treasury tool for the community, one is all set
              to get through the token distribution and funding process.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->
<!-- Autonomous Network starts -->
<div class="section-service-main-last">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>
            Launch Your <span>Decentralized Autonomous Organization</span> In
            Few Steps
          </h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/identify-requirement.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/dao-development/identify-requirement.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/identify-requirement.png"
              width="100"
              height="100"
              alt="Identify Requirement"
              title="Identify Requirement"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Identify Requirement</h4>
          <p class="text-center">
            Pick the requirements, core, and cause helps in jotting the
            priorities in the development process.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dao-development/smart-contract-creation.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/dao-development/smart-contract-creation.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/smart-contract-creation.png"
              width="100"
              height="100"
              alt="Smart contract creation"
              title="Smart contract creation"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Smart contract creation</h4>
          <p class="text-center">
            Curate secured smart contacts, and build the infrastructure of your
            DAO platform with our tech team.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/dao-development/testing-contracts.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/dao-development/testing-contracts.png" />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/testing-contracts.png"
              width="100"
              height="100"
              alt="Testing Contracts"
              title="Testing Contracts"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Testing Contracts</h4>
          <p class="text-center">
            Run through the subnet, and check for errors, scan the codes and
            contracts for vulnerabilities for final testing.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/dao-development/deployment.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/dao-development/deployment.png" />
            <img loading="lazy"
              data-src="assets-new/images/dao-development/deployment.png"
              width="100"
              height="100"
              alt="Deployment"
              title="Deployment"
              class="img-fluid" />
          </picture>
          <h4 class="erc-20-head">Deployment</h4>
          <p class="text-center">
            Will all of this be in place, launch your DAO Blockchain Development
            platform to the world, and bring in regular updates.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Autonomous Network ends -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>
              Choose BlockchainX As Your <span> DAO Development Company </span>
            </h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              We extended our utmost abilities in developing a secured,
              trust-worthy, flexible DAO platform that has endless abilities. As
              your DAO development company, our customization is your advantage
              and you could imply that. Believe, you are going to make the best
              investments ever in web3 as you deploy your DAO rendering our DAO
              development services.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Read More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png" />
              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                alt="why-blockchainX"
                title="why-blockchainX"
                class="img-fluid" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-service-main">
  <app-blog></app-blog>
</section>

<section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section>