import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-luxury-items-tokenization-development',
  templateUrl: './luxury-items-tokenization-development.component.html',
  styleUrls: ['./luxury-items-tokenization-development.component.css']
})
export class LuxuryItemsTokenizationDevelopmentComponent implements OnInit {

  title = 'Luxury item tokenization development expanding the potential of real assets';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Do the right thing for your assets and unique luxury items, increase their ROI with luxury item token development vitalizing on blockchain and decentralization. Connect to know more.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'luxury items tokenization, luxury items tokenization development, luxury items tokenization development company, luxury items tokenization solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Luxury item tokenization development expanding the potential of real assets',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/luxury-items-tokenization-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Do the right thing for your assets and unique luxury items, increase their ROI with luxury item token development vitalizing on blockchain and decentralization. Connect to know more.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-world-asset.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/luxury-items-tokenization-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Luxury item tokenization development expanding the potential of real assets',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Do the right thing for your assets and unique luxury items, increase their ROI with luxury item token development vitalizing on blockchain and decentralization. Connect to know more.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-world-asset.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/luxury-items-tokenization-development/'
    );
  }

}
