<section>
  <div class="section-bep-work">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="blog-author">
            <img
              loading="lazy"
              src="assets-new/images/blog/yokesh-sankar.png"
              alt="blog author"
              width="273"
              height="257"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-md-9">
          <div class="author-content">
            <h2>
              <span>Yokesh Sankar</span>
            </h2>
            <h5>Co - Founder and COO</h5>
            <p>
              Yokesh Sankar, the co-founder and Chief Operating Officer of Sparkout Tech, is a seasoned tech consultant Specializing in blockchain, cross-platform apps, fintech, and supply chain development.
            </p>
            <div class="author-network">
              <div>
                <a href="https://www.instagram.com/yokesh.sankar/" target="_blank"
                rel="noopener noreferrer"
                  ><img
                    src="assets-new/images/blog/author-insta.svg"
                    alt="instagram"
                    width="20"
                    height="20"
                /></a>
              </div>
              <div>
                <a href="https://in.linkedin.com/in/yokeshsankar" target="_blank"
                rel="noopener noreferrer"
                  ><img
                    src="assets-new/images/blog/author-linkedin.svg"
                    alt="linkedin"
                    width="20"
                    height="20"
                /></a>
              </div>
              <div>
                <a href="https://www.facebook.com/yokesh.shankar" target="_blank"
                rel="noopener noreferrer"
                  ><img
                    src="assets-new/images/blog/author-fb.svg"
                    alt="facebook"
                    width="20"
                    height="20"
                /></a>
              </div>
              <div>
                <a href="https://twitter.com/yokesh_sankar_v" target="_blank"
                rel="noopener noreferrer"
                  ><img
                    src="assets-new/images/blog/author-twit.svg"
                    alt="twitter"
                    width="20"
                    height="20"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <h1 class="text-center">
          <span>Yokesh Sankar</span>
        </h1>
         <div class="author-detail"></div> 
      </div> -->
      <!-- <div class="row pb-3">
        <p class="text-center">
          I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time to
          offer the best products for our clients without apprehensions at
          economic rates.
        </p>
      </div> -->
      <!-- <div class="row">
        <div class="author-network text-center">
          <div>
            <a href="https://www.instagram.com/yokesh.sankar/" target="_blank"
                rel="noopener noreferrer"
              ><img
                src="assets-new/images/blog/author-insta.svg"
                alt="instagram"
                width="20"
                height="20"
            /></a>
          </div>
          <div>
            <a href="https://in.linkedin.com/in/yokeshsankar" target="_blank"
                rel="noopener noreferrer"
              ><img
                src="assets-new/images/blog/author-linkedin.svg"
                alt="linkedin"
                width="20"
                height="20"
            /></a>
          </div>
          <div>
            <a href="https://www.facebook.com/yokesh.shankar" target="_blank"
                rel="noopener noreferrer"
              ><img
                src="assets-new/images/blog/author-fb.svg"
                alt="facebook"
                width="20"
                height="20"
            /></a>
          </div>
          <div>
            <a href="https://twitter.com/yokesh_sankar_v" target="_blank"
                rel="noopener noreferrer"
              ><img
                src="assets-new/images/blog/author-twit.svg"
                alt="twitter"
                width="20"
                height="20"
            /></a>
          </div>
        </div>
      </div> -->
      <div class="auth-blog">
        <div class="articles-header">
          <h3 class="header-title">
              Latest News
          </h3>
        </div>
        <div class="row">
          <ng-container
            *ngFor="let data of newsdata; let i = index;"
          >
            <div class="col-lg-12 mb-5"> 
              <div class="blog_wrap">
                <a href="{{ data.link.href }}" class="news-wrap">
                  <img
                    src="{{ data.imgSrc }}"
                    alt="{{ data.imgAlt }}"
                    class="img-fluid list-blog-picture"
                    width="382"
                    height="215"
                  />
                  <div class="news-content">
                  <h3>{{ data.title }}</h3>
                  <p>
                    {{ data.description }}
                  </p>
      
                  <div class="content-link">
                    <span>Read More</span
                    ><img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid ms-2"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid ms-2"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
      
                    <!-- <hr /> -->
                  </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
