<!-- Discuss Section Starts -->
<div class="main-discuss-section">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>

  <div class="row">
    <div class="col-lg-12 let-discuss">
      <div class="discus-section">
        <div class="caption">
          <h2 *ngIf="!path">
            Let Us <small>Together Build</small>
            <span> The Digitized <strong> Delegations</strong></span>
          </h2>
          <h2 *ngIf="path=='home-new'">
            Let Us <small>Together Build</small>
            <span> The Digitized <strong> Delegations</strong></span>
          </h2>
          <h2 *ngIf="path == 'metaverse-development-company'">
            Let's <small>Gather Along</small>
            <span> To Curate Creative <strong> Epitome</strong></span>
          </h2>
          <h2 *ngIf="path == 'pump-fun-clone-script'">
           <small></small>
            <span><strong> Customized Pump Fun Clone App Development</strong></span>
          </h2>
          <h2 *ngIf="path == 'erc20-token-development'">
            Let Us <small>Together Build</small>
            <span> The Digitized <strong> Delegations</strong></span>
          </h2>
          <h2 *ngIf="path == 'uniswap-clone-script'">
            Let Us <small>Together Build</small>
            <span> The Digitized <strong> Delegations</strong></span>
          </h2>
          <h2 *ngIf="path == 'about-us'">
            Let Us <small>Together Build</small>
            <span> The Digitized <strong> Delegations</strong></span>
          </h2>
        </div>
      </div>
      <div class="discus-detail">
        <h3 *ngIf="!path" class="common-sub-heading-alt" id="discuss-mission">
          Talk To Our Experts Now - Get Your <span> Mission Onboard! </span>
        </h3>
        <h3 *ngIf="path == 'home-new'" class="common-sub-heading-alt" id="discuss-mission">
          Talk To Our Experts Now - Get Your <span> Mission Onboard! </span>
        </h3>
        <h3
          *ngIf="path == 'metaverse-development-company'"
          class="common-sub-heading-alt"
          id="discuss-mission"
        >
          Connect With Our Experts <span>Enlighten Your Ideas With Us</span>
        </h3>
        <p
          *ngIf="path == 'pump-fun-clone-script'"
          class="common-sub-heading-alt pump"
          id="discuss-mission"
        >
          Launch your exclusively crafted Pump Fun clone app to mint, and trade exclusive meme coins.  Connect with our experts now  to deep dive into the main characteristics of the platform and detail its benefits through our services.
        </p>
        <h3
          *ngIf="path == 'solana-meme-coin-development'"
          class="common-sub-heading-alt"
          id="discuss-mission"
        >
          Utilize the perks of the network, and catch the trend with Solana  Meme coin development to build an interactive network.
        </h3>
        <h3
          *ngIf="path == 'erc20-token-development'"
          class="common-sub-heading-alt pump"
          id="discuss-mission"
        >
          Connect With Our Experts <span>Enlighten Your Ideas With Us</span>
        </h3>
        <h3
          *ngIf="path == 'uniswap-clone-script'"
          class="common-sub-heading-alt"
          id="discuss-mission"
        >
          Connect With Our Experts <span>Enlighten Your Ideas With Us</span>
        </h3>
        <h3
          *ngIf="path == 'about-us'"
          class="common-sub-heading-alt"
          id="discuss-mission"
        >
          Connect With Our Experts <span>Enlighten Your Ideas With Us</span>
        </h3>

        <div class="discuss-link">
          <a [routerLink]="['/contact']">
            <span>Connect now!</span>
            <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
          </a>
        </div>
        <div class="discus-vector">
          <picture>
            <source srcset="assets-new/images/home/services/discus.webp" type="image/webp">
            <source srcset="assets-new/images/home/services/discus.png" type="image/png">
            <img
            loading="lazy"
            src="assets-new/images/home/services/discus.png"
            class="img-fluid"
            width="593"
            height="391"
            alt="Discussion-vetor"
            title="Discussion-vetor"
          />
          </picture>
          
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Discuss Section Ends -->
