<div class="page-not-found">
  <div class="green-vector"></div>
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row">
      <div class="caption text-center pb-4">
        <h1>
          <span> <strong> Page Not Found </strong></span>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="not-found-picture text-center">
        <img loading="lazy"
          src="assets-new/images/404/404.png"
          class="img-fluid"
          width="500"
          height="500"
        />
      </div>
    </div>
    <div class="row">
      <div class="redirect-link text-center">
        <a [routerLink]="['/']" class="home-link-alt"> Back To Home </a>
      </div>
    </div>
  </div>
</div>
