import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-top-blockchain-development-companies-in-us',
  templateUrl: './top-blockchain-development-companies-in-us.component.html',
  styleUrls: ['./top-blockchain-development-companies-in-us.component.css']
})
export class TopBlockchainDevelopmentCompaniesInUsComponent implements OnInit {

  selectedLink:number;
  title =
    'Top 10 Blockchain Development Companies In The U.S';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Check out the top-ranked United States-based Blockchain Development company in the market helping businesses to grow and stand greater among the competitors. Tap to check now!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'top 10 blockchain development companies, Best blockchain development company, Blockchain development company, Blockchain development service, Blockchain development',
    });
              

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Top 10 Blockchain Development Companies In The U.S',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/top-blockchain-development-companies-in-us/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Check out the top-ranked United States-based Blockchain Development company in the market helping businesses to grow and stand greater among the competitors. Tap to check now!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/blockchain-development-companies-in-us/top-blockchain-development-companies-in-us.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/top-blockchain-development-companies-in-us/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Top 10 Blockchain Development Companies In The U.S',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Check out the top-ranked United States-based Blockchain Development company in the market helping businesses to grow and stand greater among the competitors. Tap to check now!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/blockchain-development-companies-in-us/top-blockchain-development-companies-in-us.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/top-blockchain-development-companies-in-us/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/top-blockchain-development-companies-in-us.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
