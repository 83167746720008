import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-cryptocurrency-launchpad-demo',
  templateUrl: './cryptocurrency-launchpad-demo.component.html',
  styleUrls: ['./cryptocurrency-launchpad-demo.component.css']
})
export class CryptocurrencyLaunchpadDemoComponent implements OnInit {

  title =
    'Crypto Launchpad Development With BlockchainX';

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Crypto launchpad development with a customized user-friendly interface ensuring to meet your fundraising model’s requirement and seamlessly carry out operations with BlockchainX. Tap to know more.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'crypto launchpad development, crypto launchpad development company, launchpad development services, white label crypto launchpad, defi token launchpad',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Crypto Launchpad Development With BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/white-label-launchpad-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Crypto launchpad development with a customized user-friendly interface ensuring to meet your fundraising model’s requirement and seamlessly carry out operations with BlockchainX. Tap to know more.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/crypto-launchpad-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/white-label-launchpad-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Crypto Launchpad Development With BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Crypto launchpad development with a customized user-friendly interface ensuring to meet your fundraising model’s requirement and seamlessly carry out operations with BlockchainX. Tap to know more.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/crypto-launchpad-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/white-label-launchpad-development/'
    );
  }

}
