import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-key-factors-insights-successful-nft-marketplace',
  templateUrl:
    './key-factors-insights-successful-nft-marketplace.component.html',
  styleUrls: [
    './key-factors-insights-successful-nft-marketplace.component.css',
  ],
})
export class KeyFactorsInsightsSuccessfulNftMarketplaceComponent
  implements OnInit
{
  selectedLink:number;
  title = 'NFT Marketplace Development: Everything You Need To Before Developing your Marketplace';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'NFT marketplace developments are one of the prominent choices of platform for any creator business that is trying to scale up in their niche. The success of NFTs is very evident. Are you a creative entrepreneur struggling to kickstart your NFT marketplace platform development? This blog is for you then!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'NFT marketplace development',
    }); 
    
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'NFT Marketplace Development: Everything You Need To Before Developing your Marketplace',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/nft-marketplace-development-complete-guide/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'NFT marketplace developments are one of the prominent choices of platform for any creator business that is trying to scale up in their niche. The success of NFTs is very evident. Are you a creative entrepreneur struggling to kickstart your NFT marketplace platform development? This blog is for you then!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'http://blockchainx.tech/assets-new/images/blog/complete-guide-for-nft/developed-successful-nft-marketplace.png',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/nft-marketplace-development-complete-guide/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'NFT Marketplace Development: Everything You Need To Before Developing your Marketplace',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'NFT marketplace developments are one of the prominent choices of platform for any creator business that is trying to scale up in their niche. The success of NFTs is very evident. Are you a creative entrepreneur struggling to kickstart your NFT marketplace platform development? This blog is for you then!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'http://blockchainx.tech/assets-new/images/blog/complete-guide-for-nft/developed-successful-nft-marketplace.png',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/nft-marketplace-development-complete-guide/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/nft-marketplace-development-complete-guide.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}
