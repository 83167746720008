import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-defi-crypto-wallet-software',
  templateUrl: './defi-crypto-wallet-software.component.html',
  styleUrls: ['./defi-crypto-wallet-software.component.css'],
})
export class DefiCryptoWalletSoftwareComponent implements OnInit {
  title =
    'Your partner for secure and innovative crypto wallet development';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}


  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Keep your digital assets in a highly secure, technically advanced blockchain-based cryptocurrency wallet. With our cryptocurrency wallet development services, you can start building the best and most efficient wallet system.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'crypto wallet development,cryptocurrency wallet development company,cryptocurrency wallet development services,crypto wallet app development,crypto wallet app development company,create crypto wallet,create crypto wallet app,creating a cryptocurrency wallet,crypto wallet app development,crypto wallet app development company,crypto wallet create,crypto wallet developer,white label cryptocurrency wallet,white label cryptocurrency wallet development',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Your partner for secure and innovative crypto wallet development ',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/cryptocurrency-wallet-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Keep your digital assets in a highly secure, technically advanced blockchain-based cryptocurrency wallet. With our cryptocurrency wallet development services, you can start building the best and most efficient wallet system.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/cryptocurrency-wallet-development-company.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/cryptocurrency-wallet-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Your partner for secure and innovative crypto wallet development',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Keep your digital assets in a highly secure, technically advanced blockchain-based cryptocurrency wallet. With our cryptocurrency wallet development services, you can start building the best and most efficient wallet system.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/cryptocurrency-wallet-development-company.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/cryptocurrency-wallet-development/'
    );
   }

  //  winkCrypto: OwlOptions = {
  //   loop: true,
  //   autoplay: true,
  //   slideBy: 1,
  //   dots: false,
  //   autoHeight: true,
  //   margin: 25,
  //   navText: [],
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  
  //     767: {
  //       items: 1,
  //     },
  
  //     1024: {
  //       items: 2,
  //     },
  
  //     1400: {
  //       items: 4,
  //     },
  //   },
  //   nav: false,
  // };

}
