<footer>
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="violet-vector"></div>
  <div class="full-width-footer-section">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12 foote-main-logo">
          <div class="footer_logo">
            <a href="/">
              <picture *ngIf="!isDarkmode">
                <source type="image/png"
                    srcset="assets-new/images/footer_logo.png" />
                <img
                  loading="lazy"
                  src="assets-new/images/footer_logo.png"
                  alt="BlockchainX"
                  class="img-fluid mb-4"
                  width="242"
                  height="75"
                />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/png"
                  srcset="assets-new/images/footer_logo-dark.png" />
                <img
                  loading="lazy"
                  src="assets-new/images/footer_logo-dark.png"
                  alt="BlockchainX"
                  class="img-fluid mb-4"
                  width="242"
                  height="75"
                />
              </picture>
            </a>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <ul class="sociallinks">
            <li>
              <a
                href="https://www.instagram.com/blockchainxtech/"
                target="_blank"
                rel="noopener noreferrer"
                alt="instagram"
                aria-label="Instagram"><i class="bi bi-instagram"></i></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/blockchainxtech/"
                target="_blank"
                rel="noopener noreferrer"
                alt="Linkedin"
                aria-label="Linkedin"><i class="bi bi-linkedin"></i></a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/blockchainxtech/"
                target="_blank"
                rel="noopener noreferrer"
                alt="facebook"
                aria-label="facebook"><i class="bi bi-facebook"></i></a>
            </li>
            <li>
              <a
                href="https://twitter.com/Blockchainxtech"
                target="_blank"
                rel="noopener noreferrer"
                alt="Twitter"
                aria-label="twitter">
                <!-- <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/twitterx.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/twitterx.svg"
                  />

                  <img loading="lazy"
                    data-src="assets-new/images/twitterx.svg"

                    alt="twitter"
                    width="32"
                    height="32"
                    class="contact-logo img-fluid"
                  />
                </picture> -->
                <i class="bi bi-twitter-x"></i>
              </a>
            </li>
            <li>
              <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" alt="Telegram"
                aria-label="telegram"><i class="bi bi-telegram"></i></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCUYbLRK5Sc6BjErGvyyT7Lg"
                target="_blank"
                rel="noopener noreferrer"
                alt="Youtube"
                aria-label="youtube"><i class="bi bi-youtube"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row mb-4 footer-links">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-list">
          <ul class="footer_links">
            <li>
              <a [routerLink]="['/ready-made-ico-software']">Token Sale
                Dapp</a>
            </li>
            <li>
              <a
                [routerLink]="['/blockchain-lending-software-using-defi']">DeFi
                Lending Software</a>
            </li>
            <li>
              <a [routerLink]="['/cryptocurrency-wallet-development']">Crypto
                Wallet App</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/crowdfunding-script']"
                >Crowdfunding Software</a
              >
            </li> -->
            <!-- <li>
              <a [routerLink]="['/cold-wallet-integration']"
                >Cold Wallet Integration</a
              >
            </li> -->
            <li>
              <a [routerLink]="['/token-migration']">Token Migration</a>
            </li>
            <li>
              <a [routerLink]="['/uniswap-clone-script']">Uniswap Clone</a>
            </li>
            <li>
              <a [routerLink]="['/bridge-smart-contract-development']">Bridge
                Smart Contract</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-list">
          <ul class="footer_links">
            <li>
              <a
                [routerLink]="['/crypto-currency-development']">Cryptocurrency
                Development</a>
            </li>
            <li>
              <a [routerLink]="['/ico-development']">ICO Coin Development</a>
            </li>
            <li>
              <a [routerLink]="['/security-token-offering-services']">STO Coin
                Development</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/ico-marketing-agency']"
                >ISO/STO Marketing</a
              >
            </li> -->
            <li>
              <a [routerLink]="['/erc20-token-development']">ERC20 Token
                Development</a>
            </li>
            <li>
              <a
                [routerLink]="[
                  '/cryptocurrency-exchange-software-development'
                ]">CEX Development
              </a>
            </li>
            <li>
              <a [routerLink]="['/bep20-token-development']">BEP20 Token
                Development</a>
            </li>
            <li>
              <a [routerLink]="['/tron-token-development']">TRC20 Token
                Development</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-list">
          <ul class="footer_links">
            <li>
              <a
                [routerLink]="[
                  '/hyperledger-blockchain-development-services'
                ]">Hyperledger Development</a>
            </li>
            <li>
              <a [routerLink]="['/smart-contract-development']">Smart Contract
                Development</a>
            </li>
            <li>
              <a [routerLink]="['/dapps-development']">DAPPS Development</a>
            </li>
            <li>
              <a [routerLink]="['/hire-solidity-developers']">Solidity
                Development</a>
            </li>
            <li>
              <a [routerLink]="['/ipfs-blockchain']">IPFS Development</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/hashgraph-development-services']"
                >Hashgraph Development</a
              >
            </li> -->
            <li>
              <a [routerLink]="['/defi-development-company']">DeFi
                Development</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/hire-solana-developers']"
                >Solana Development</a
              >
            </li> -->
            <li>
              <a [routerLink]="['/depin-development-company']">Depin
                Development Company</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-list">
          <ul class="footer_links">
            <li><a [routerLink]="['/blog']">Blog</a></li>
            <li><a [routerLink]="['/news']">News</a></li>
            <li>
              <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
            </li>
            <li>
              <a [routerLink]="['/terms-and-conditions']">Terms and
                Conditions</a>
            </li>
          </ul>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>Become a Partner</span>
                  <img
                    loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-copyright-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex align-items-center justify-content-between">
            <div class="copyrights">
              <p>Copyright &#169; {{year}} BlockchainX</p>
            </div>
            <div class="copyrights">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/dmca-protected.webp
                  " />

                <source
                  type="image/png"
                  srcset="assets-new/images/dmca-protected.png" />

                <img loading="lazy"
                  data-src="assets-new/images/dmca-protected.png"
                  width="200"
                  height="50"
                  class="img-fluid"
                  alt="dmca-protected"
                  title="dmca-protected" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="backtop" (click)="backtotop()">
    <i class="bi bi-arrow-up-right"></i>
  </div>
</footer>
