<section class="blog-detail">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 mb-4">
        <div class="blog-single-wrap">
          <img
          loading="lazy"
            src="assets-new/images/blog/blockchainDtl.webp"
            alt="Blockchain vs Distributed Ledger"
            class="img-fluid"
            width="840"
            height="420"
          />
          <h1>
            Understanding the difference between Blockchain vs Distributed
            Ledger
          </h1>
          <p>
            Ever since the popularity of Bitcoin which started back in 2007, the
            concept of Blockchain chain technology has been one of the major
            trends in the tech world. Following that, with the new wave of
            blockchain integration in major industries from 2017, the concept of
            Distributed Ledger Technology (DLT) has been the next trending
            topic. However, there has been a common misunderstanding that they
            are one and the same, but these two technologies are mutually
            inclusive at times and completely non dependent in other cases.
            Let’s go ahead and explore the scenario behind Blockchain and
            Distributed Ledger Technology to understand their nature and how
            they have their own differences.p>
          </p>
          <h2>What is Blockchain?</h2>
          <p>
            “Blockchain technology isn't just a more efficient way to settle
            securities. It will fundamentally change market structures, and
            maybe even the architecture of the Internet itself.” Abigail Johnson
          </p>
          <p>
            The famous digital currency that struck the market back in 2007 with
            a banging new authority in security, caused such an impacting wave
            in the cryptocurrency market by implementing the Blockchain
            technology . Blockchain is nothing but a data structure that is
            stored in the form of blocks and connected by cryptographic
            principles called as a chain. What this means in the non tech
            applications is that the blockchain does not work in the traditional
            method, rather implies a democratic method in handling its database.
            Here is where the term decentralised database comes in. In a typical
            network the entire database of all its users is stored in a single
            entity which consumes a lot of energy to perform which exchanging
            data as every single entry has to engage the main server. While in
            the case of a decentralised network every user works as a node which
            shares the network’s database in the form of blocks. So, in this
            scenario, when there is an exchange of data the entire nodal network
            works together eliminating the traditional system of holding a
            centralised database.
          </p>
          <p>
            This system became the source of trust among its users as the
            transaction ledger is shared among its users in the network, which
            made the ledger transparent in nature. This means that the users are
            granted access to the information in the database. Additionally,
            Blockchain became immutable to hackers and there is no single entity
            or server that could be crashed.
          </p>
          <p>
            This technology was very wisely implemented in the Bitcoin
            cryptocurrency to ensure its customers security as a prime trust
            factor. However in further advancements of Blockchain, this
            technology evolved into two types, namely public and private
            blockchain. The later type being the reason why major industry
            sectors are integrating Blockchain technology into their businesses.
          </p>

          <h2>Real World Application of Blockchain</h2>
          <p>
            Some of the applications of Blockchain platforms extend to more than
            just cryptocurrencies like Bitcoin include Ethereum, BigChainBD, IBM
            Fabric, Hydrachain, R3 Corda, Multichain, OpenChain, Chain Core, and
            many more.
          </p>

          <h2>What is DLT?</h2>
          <p>
            “The blockchain concept was pioneered within the context of
            crypto-currency Bitcoin, but engineers have imagined many other ways
            for distributed ledger technology to streamline the world. Stock
            exchanges and big banks, for example, are looking at blockchain-type
            systems as trading settlement platforms.” <b>Anthony Scaramucci</b>
          </p>
          <p>
            Distributed Ledger Technology popularly called the DLT is similar to
            the concept of a decentralised database that was briefly explained
            in the Blockchain Technology. This system simply implies that there
            is no centralised authority to hold the entire database and the
            network. The database is rather distributed in the network itself
            making it a collective authority for the database. This property
            also makes the DLT a comparatively more secure network than the
            traditional networking method. This was widely accepted and became
            increasingly popular as transaction ledger was completely
            transparent and accessible to all the users in the network making
            this a democratic system of handling the database. As the authority
            is distributed in this process there was lesser chance for hackers
            to interfere. Although a DLT sounds like an aspect of blockchain in
            the term where decentralised database comes in, a DLT relies on a
            similar principle and not the exact same. Here is how a DLT works.
            This technology doesn't necessarily construct a chain of blocks to
            store the information and share amongst users in the network, rather
            it creates many servers as opposed to a traditional system of a
            single server entity, which will engage in the transaction and then
            communicate to ensure the most accurate data record.
          </p>
          <p>
            However, this method was successfully implemented in Bitcoin in
            2007. This implies that Bitcoin used DLT in their technology among
            various other options. This makes DLT mutually inclusive of Bitcoin
            or Blockchain. So, DLT can exist outside of a Blockchain Technology.
            This invention has modified into many applications in a variety of
            industries across the world.
          </p>

          <h2>Real World Application of DLT</h2>
          <p>
            Some of the famous application of DLT systems include Digital Asset
            Holdings, Hedera Hashgraph, IOTA’s Tangle network, peaq, and many
            more.
          </p>
          <h2>Conclusion</h2>
          <p>
            The take away from this blog is that Blockchain Technology and a
            Distributed Ledger Technology are neither the same thing nor
            interchangeable. However, every Blockchain has a distributed ledger
            system , but not every DLT is a Blockchain. But both these
            technologies use the concept of decentralisation and consensus
            algorithm. With this breakthrough in the tech world, many industries
            are seen integrating these systems in there business and their
            applications are evolving and adapting to many more economic
            sectors.
          </p>
        </div>
        <app-social></app-social>
      </div>
      <div class="col-lg-4 col-md-12 stick-blog">
        <app-blog-sidebar></app-blog-sidebar>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <app-comments></app-comments>
      </div>
    </div>
  </div>
</section>
<app-blog></app-blog>
<section class="section-service-alt-main">
  <app-contactform></app-contactform>
  </section>
