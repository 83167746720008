<!-- Banner Section -->
<section class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1 class="pb-3">
                <span><strong>Real Estate </strong> Tokenization Services</span>
              </h1>
              <!-- <div class="common-sub-heading-alt pb-2">Expanding Opportunities
                For Real Assets</div> -->
              <p>
                BlockchainX, with the advent of AI, ML, AR, and VR, provides real estate tokenization services, tokenizing monuments, buildings, residential assets, etc., bringing exponential growth and expanded asset utility in real estate tokenization development.
              </p>
              
              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Connect With Us</a>
                <a href="mailto:contact@blockchainx.tech" rel="noopener noreferrer" class="home-link-alt">Check Our Demo</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                      assets-new/images/real-estate/real-estate-banner.webp
                    " />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/real-estate-banner.png"
                class="img-fluid"
                alt="Real Estate Tokenization Services"
                title="Real Estate Tokenization Services"
                width="500"
                height="500" />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- Banner Section end -->

<!-- Why Choose Start -->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="row reverse align-items-center">
      <div class="col-lg-5 mx-auto col-12">
        <div class="bep-work-section text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/real-estate-platform.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/real-estate-platform.png"
              class="img-fluid"
              alt="Real Estate Tokenization Development"
              title="Real Estate Tokenization Development"
              width="500"
              height="500" />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-12 about-content">
        <div class="whys_section mb-0">
          <h2><span>Real Estate Tokenization  </span> Platform Development</h2>
          <p class="pb-3">
            Embrace the new possibility of maximizing access to real estate investments through real estate tokenization platform development. A decentralized space for users to list their assets and trade them at their free will and eventually buy, sell, stake, rent, and lease concerning the profits. This platform eliminates the need for middlemen and third parties. With our expertise at BlockchainX, we curate the high-end, technologically well-advanced real estate tokenization platform that contributes to your growth and progress! 
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose End -->


<!-- Our Blockchain Smart Contracts Focused Industries -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-2">
            Real Estate Tokenization <span>Services We Provide</span>
          </h2>
          <p class="my-4">
            Our service offerings in the Real estate Tokenization platform extends and expands to different sectors, from residential belongings to commercial spaces. The investors and buyers choosing Real estate tokenization receive the perks of investing in a new relishing, that bring rewards and entries, allows fractional ownership authorities, owns spaces as securities and bonds, and much more! Yet this can be customized based on your requirements. 
          </p> 
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center gy-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/real-estate-token.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/real-estate-token.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Real Estate Token"
                title="Residential Real Estate Tokenization" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Residential Real Estate Tokenization</div>
          <p>
            Residential spaces like houses, villas, flats, dorms, etc can be tokenized. Exclusive infrastructural buildings can be given more attention to tokenize.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/commercial-real-estate-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/commercial-real-estate-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Commercial Real Estate Tokenization"
                title="Commercial Real Estate Tokenization" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Commercial Real Estate Tokenization</div>
          <p>
            Commercial space includes retail stores, multistory buildings, malls, office spaces, industries, hotels, and others, by tokenizing the opportunity to bring investors.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/family-property-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/family-property-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Supply Chain Logistics"
                title="Supply Chain Logistics" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Multi-family Property Tokenization</div>
          <p>
            By tokenizing a hereditary property on Blockchain the security it holds with ownership and authority maximizes and brings a good resale value.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/hospitality-real-estate-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/hospitality-real-estate-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Hospitality Real Estate Tokenization"
                title="Hospitality Real Estate Tokenization" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Hospitality Real Estate Tokenization</div>
          <p>
            As a part of real estate tokenization, we provide services to tokenize hospitality-related properties, like motels, hotels, resorts, stays, etc creating new digital experiences. 
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/land-development-projects-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/land-development-projects-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Land and Development Projects Tokenization"
                title="Land and Development Projects Tokenization" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Land and Development Projects Tokenization</div>
          <p>
            Land and development projects like smart cities, urban development, etc. can also be tokenized with our custom solutions.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/real-estate-lnvestment.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/real-estate-lnvestment.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Real Estate Investment Trusts"
                title="Real Estate Investment Trusts" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Real Estate Investment Trusts (REITs) Tokenization</div>
          <p>
            With the potential to be traded on different stock exchanges, the REITS tokenization will take your investments to new heights scaling ROI opportunities.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/fractional-ownership-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/fractional-ownership-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Fractional Ownership Tokenization"
                title="Fractional Ownership Tokenization" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Fractional Ownership Tokenization</div>
          <p>
            Real estate is a huge investment option, through real estate tokenization brings the opportunity to small-scale investment through fractional ownership.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/real-estate/rental-property-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/real-estate/rental-property-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Rental Property Tokenization"
                title="Rental Property Tokenization" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Rental Property Tokenization</div>
          <p>
            Real estate tokenization also extends to rental property, which is either listed properties or cash flow which increases liquidity.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Blockchain Smart Contracts Focused Industries -->


<section class="section-service-alt">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3 h-2">Real Estate <span>Tokenization Development Process</span></h2>
          <p>
            Our Real Estate Token Development Process is Streamlined in a manner to address and meet your requirements, which also includes feedback and working alongside.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source type="image/webp" srcset="assets-new/images/real-estate/real-estate-tokenization-developemnt-process.webp" />
            <img loading="lazy" data-src="assets-new/images/real-estate/real-estate-tokenization-developemnt-process.png" width="450" height="450" class="img-fluid pb-md-0 pb-3" alt="Real Estate Tokenization Development process" title="Real Estate Tokenization Development process" />
          </picture>
          <!-- <picture>
            <source type="image/webp" srcset="assets-new/images/real-estate/real-estate-tokenization-developemnt-process.webp" />
            <img loading="lazy" data-src="assets-new/images/real-estate/real-estate-tokenization-developemnt-process.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="Meme Coin Development Process" title="Meme Coin Development Process" *ngIf="isDarkmode" />
          </picture> -->
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3">1. Understanding and Gathering Requirements</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  By understanding the requirements, we gather your expectations in Real estate tokenization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3">2. Jotting the plan of action, and agreeing to the policies</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  We provide you with a plan of action including the technical integration aspects, once approved we proceed with development. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3" >3. Designing and getting approval for the Infrasture</div>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p>
                Once the plan of action is approved we curate the infrastructure, featu,res and functionalities of the real estate token. 
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3" >4. Building the technical aspects of the Real estate tokenization</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  We go ahead to bring in the technical aspects like smart contract development, integrations, IoT, and others stuff to enhance performance and security. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3" >5. Integrating with best interventions for scalability </div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  The next step is wallet integration, along with setting up the landing page for token listing and marketing purposes. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3">6. Testing for error and Launching in the Mainnet for Sale</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Nearing the final stage, we do end-to-end testing to ensure the token can function and exhibit its features correctly and mint the token. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3" >7. Open for user feedback and upgrade.</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Once the token is listed in the market for sales, based on the traction and feedback it receives, we also provide upgrade services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</section>

<section>
  <div class="meta-expert">
    <div class="violet-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="our_experts">
            <p><span>Democratize your ownership and asset </span>on the blockchain with Real estate Tokenization services</p>
            <div class="text-center my-4">
              <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Connect with our experts now!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Features Of Our Real Estate Tokenization Start -->
<section class="section-service-alt-padd">
  <div class="types-of-erc">
    <div class="container">
      <div class="row sm-pb-4 pb-2">
        <div class="metaverse_heading mx-auto">
          <h2>Features To Integrate our <span>Real Estate Tokenization Development company</span>
          </h2>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/user-profile.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/user-profile.png"
                  class="img-fluid"
                  alt="User profile"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">User Profile</div>
            <p>
              Unique profiles for each user with required fields to collect and
              gather user information.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/user-verification.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/user-verification.png"
                  class="img-fluid"
                  alt="User verification"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">User Verification</div>
            <p>
              To verify the participant in the Real estate tokenization platform
              ensure user authentication.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/land-ownership.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/land-ownership.png"
                  class="img-fluid"
                  alt="Land ownership"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Land Ownership NFTs</div>
            <p>
              Features to ensure the Land ownership NFTs for assets are
              digitized in the real estate NFT marketplace.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/token-offering-launchpad.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/token-offering-launchpad.png"
                  class="img-fluid"
                  alt="Token offering launchpad"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Token Offering Launchpad</div>
            <p>
              The real estate assets have to be tokenized in the launchpad, to
              get listed for sale and purchase.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/integrated-governance.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/integrated-governance.png"
                  class="img-fluid"
                  alt="Integrated governance"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left  h-4">Integrated Governance</div>
            <p>
              Governance is an integral part of any blockchain-backed platform,
              and we ensure to curate with care.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/built-in-wallet.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/built-in-wallet.png"
                  class="img-fluid"
                  alt="Built in wallet"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left  h-4">Built-in Wallet</div>
            <p>
              The wallet is a basic requirement that is used to store and
              transact assets, tokens, and NFTs from one another.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/payment-gateways.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/payment-gateways.png"
                  class="img-fluid"
                  alt="payment-gateways"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Payment Gateways</div>
            <p>
              Integrating multiple payment gateways gives your users a secure
              and convenient payment transaction process.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/secondary-marketplace.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/secondary-marketplace.png"
                  class="img-fluid"
                  alt="Secondary-Marketplace"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Secondary Marketplace</div>
            <p>
              This space serves as a secondary market for the purchased assets
              or tokens to be resold or listed for sale.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/dividend-payments.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/dividend-payments.png"
                  class="img-fluid"
                  alt="Dividend payments"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Dividend Payments</div>
            <p>
              Dividend Payment facilitates understanding the internal payment
              cut down that is happening.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/discussion-forums.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/discussion-forums.png"
                  class="img-fluid"
                  alt="Discussion forums"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Discussion Forums</div>
            <p>
              As the platform runs on a consensus mechanism, it helps in
              understanding the community better.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/user-profile.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/user-profile.png"
                  class="img-fluid"
                  alt="KYC/AML Integration"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">KYC/AML Integration</div>
            <p>
              Validate users and investors through a proper, and secured KYC/ AML verification, entrusts authenticity.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/user-verification.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/user-verification.png"
                  class="img-fluid"
                  alt="Detailed Analytics"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Detailed Analytics</div>
            <p>
              Get a glimpse of all your asset performance and their market status in one dashboard helping to plan wiser.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/real-estate/land-ownership.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/real-estate/land-ownership.png"
                  class="img-fluid"
                  alt="Fractional Ownership"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Fractional Ownership</div>
            <p>
              By providing buyers and investors with fractional ownership of real estate tokens, the chance of conversion is high.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->


<!-- Benefits Of Tokenizing Real Estate Start -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h2>Benefits of  Real Estate <span>Tokenization Development</span></h2>
        </div>
      </div>
    </div>
    <div class="row benefits-of-token">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/high-volume-liquidity.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/high-volume-liquidity.png"
              class="img-fluid"
              alt="High volume liquidity"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">High-volume Liquidity</div>
          <p class="text-center">
            With the perks of higher liquidity, tokenization of Real estate
            services improves buying and selling efficiently.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/fractional-ownership.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/fractional-ownership.png"
              class="img-fluid"
              alt="Fractional ownership"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Fractional Ownership</div>
          <p class="text-center">
            Make divided ownership where the huge real estate tokenized asset
            can be partitioned and sold which encourages wider participation.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/globalization.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/globalization.png"
              class="img-fluid"
              alt="Globalization"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Globalization</div>
          <p class="text-center">
            Attracting investors and property owners from the global forum,
            unrestricted participation opens wider opportunities.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/no-intermediaries.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/no-intermediaries.png"
              class="img-fluid"
              alt="no intermediaries"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">No Intermediaries</div>
          <p class="text-center">
            Eliminates the need for intermediaries, the investors and the asset
            owners can directly make and close deals.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/blockchain-immutability.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/blockchain-immutability.png"
              class="img-fluid"
              alt="Blockchain immutability"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Blockchain Immutability</div>
          <p class="text-center">
            Blockchain immutability in real estate tokenization projects ensures
            the records are well-kept and cannot be interrupted at any cause.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/instant-efficient.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/instant-efficient.png"
              class="img-fluid"
              alt="instant-efficient"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Instant, Efficient Transactions</div>
          <p class="text-center">
            Providing simplified transaction processes that are instant and
            efficient by all means encourages user participation.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/reduced-entry-cost.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/reduced-entry-cost.png"
              class="img-fluid"
              alt="Reduced entry cost"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Reduced Entry Cost</div>
          <p class="text-center">
            Cut down the external and mundane costs in terms of operations and
            initiation in the Real Estate Tokenization platform.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/highly-secure.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/highly-secure.png"
              class="img-fluid"
              alt="Highly secure"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Highly Secure</div>
          <p class="text-center">
            Blockchain’s integral quality is to render security and we customize
            security layers based on your requirements.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-estate/simplified-management.webp" />

            <img loading="lazy"
              data-src="assets-new/images/real-estate/simplified-management.png"
              class="img-fluid"
              alt="Simplified management"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head h-5">Simplified Management</div>
          <p class="text-center">
            Cut down management barriers in asset management by tokenizing real
            estate on the blockchain.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Benefits Of Tokenizing Real Estate End -->


<!-- Real Estate Tokenization Platform Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto text-center">
          <h2><span>Real Estate Tokenization </span> Projects Executed At BlockchainX</h2>
        </div>
      </div>
    </div>

    <div class="row mt-sm-5 mt-4 bx-clients real-estate-token">
      <div class="col-lg-10 mx-auto">
        <div class="item-client">
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/ekta.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
              <p class="text-center mt-2">EKTA</p>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/reflex-finance.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
              <p class="text-center mt-2">
                RealT
              </p>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/zuki.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
              <p class="text-center mt-2">BrickBlock</p>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/wafercoin.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
              <p class="text-center mt-2">Propy</p>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/polkadog.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
              <p class="text-center mt-2">Arrived</p>
            </div>
          </div>
          <div class="client-box">
            <div class="our-client">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/home/clients/starcard.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/home/clients/starcard.png"
                  width="150" height="60" class="img-fluid" alt="our clients"
                  title="our clients" />
              </picture>
              <p class="text-center mt-2">Figure</p>
            </div>
          </div>
          <!-- <div class="client-box">
              <div class="our-client">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/l1x.webp" />
                  <source type="image/png" srcset="assets-new/images/home/clients/l1x.png" />
                  <img loading="lazy"  data-src="assets-new/images/home/clients/l1x.png" width="100%" height="100%" class="img-fluid" alt="our clients" title="our clients" />
                </picture>
                <p class="text-center mt-2">Harbor</p>
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section>
  <div class="meta-expert">
    <div class="violet-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="our_experts">
            <p><span>Are you ready to tokenize your real estate assets? </span>Book a call with our expert consultant today to get started</p>
            <div class="text-center my-4">
              <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Connect with our experts now!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>

<!-- Real Estate Tokenization listing Offerings Start -->
<section class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h2>
              Real Estate <span>Tokenization solutions</span>
            </h2>
          </div>
        </div>
      </div>
      <div
        class="row types-of-token-detail justify-content-center real-estate-token">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <div class="erc-20-head-alt pb-0 h-4">Token Structure Developed on
              Ethereum, Hyperledger, or Stellar</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <div class="erc-20-head-alt pb-0 h-4">Involving Specialists in Blockchain
              and Smart Contract development</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <div class="erc-20-head-alt pb-0 h-4">Flexible to adapt to new market
              structures.</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <div class="pb-0  h-4">Highly experienced team with a professional
              approach</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <div class="pb-0  h-4">Improved revenue streams for business</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <h2 class="main-sub-heading text-center pb-0">Our Tokenization <span>Technology Stack</span></h2>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-5 types-of-token-detail justify-content-center industry-web row-gap">
      <div class="col">
        <div class="detail text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/angular.svg" class="img-fluid" alt="Angular" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/angular-light.svg" class="img-fluid" alt="Angular" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Angular</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/tailwind.svg" class="img-fluid" alt="Tailwind" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/tailwind-light.svg" class="img-fluid" alt="Tailwind" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Tailwind</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/node-js.svg" class="img-fluid" alt="Node JS" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/node-js-light.svg" class="img-fluid" alt="Node JS" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Node JS</p>
        </div>
      </div>
      <div class="col">
        <div class="detail text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/mongodb.svg" class="img-fluid" alt="MongoDB" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/mongodb-light.svg" class="img-fluid" alt="MongoDB" width="60" height="60" *ngIf="isDarkmode">
            <p class="erc-20-head-alt fw-semibold">MongoDB</p>
          </div>              
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/rust.svg" class="img-fluid" alt="Rust" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/rust-light.svg" class="img-fluid" alt="Rust" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Rust</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/hardhat.svg" class="img-fluid" alt="Hardhat" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/hardhat-light.svg" class="img-fluid" alt="Hardhat" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Hardhat</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/solana.svg" class="img-fluid" alt="Solana" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/solana-light.svg" class="img-fluid" alt="Solana" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Solana/SPL Token</p>
        </div> 
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/phantom.svg" class="img-fluid" alt="Phantom" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/phantom-light.svg" class="img-fluid" alt="Phantom" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Phantom</p>
        </div> 
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/pinata.svg" class="img-fluid" alt="Pinata" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/pinata-light.svg" class="img-fluid" alt="Pinata" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Pinata</p>
        </div>
      </div>
        <div class="col">
          <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
            <div>
              <img loading="lazy" src="assets-new/images/token-images/ipfs.svg" class="img-fluid" alt="IPFS" width="60" height="60" *ngIf="!isDarkmode">
              <img loading="lazy" src="assets-new/images/token-images/ipfs-light.svg" class="img-fluid" alt="IPFS" width="60" height="60" *ngIf="isDarkmode">
            </div>
            <p class="erc-20-head-alt fw-semibold">IPFS</p>
          </div>
        </div>
        <div class="col">
          <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
            <div>
              <img loading="lazy" src="assets-new/images/token-images/aws.svg" class="img-fluid" alt="AWS" width="60" height="60" *ngIf="!isDarkmode">
              <img loading="lazy" src="assets-new/images/token-images/aws-light.svg" class="img-fluid" alt="AWS" width="60" height="60" *ngIf="isDarkmode">
            </div>
            <p class="erc-20-head-alt fw-semibold">AWS</p>
          </div>
        </div> 
      </div>
    </div>
  </div>
</section>




<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Top Real Estate </span>Tokenization Development Company
          </h2>
          <p class="pb-3">
            BlockchainX with our expert team of professionals having hands-on experience developing custom blockchain ecosystems. We have worked on several projects including Real estate tokenization platforms, where we curate intricate spaces to represent, experience, buy, sell, and stake exclusive real estate tokens. Our expertise lies in
          </p>
          <div class="whysection_text_wrap">
            <ul>
              <li>Providing end-to-end support throughout the development phase.</li>
              <li>We are open to customizing and upgrading based on your needs.</li>
              <li>Provide and support consultation services.</li> 
              <li>24/7 support and on time applicant delivery.</li> 
              <li>Professional team ready to execute your needs.</li>
            </ul>
          </div>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Gold Tokenization development company"
              title="Gold Tokenization development company" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            Frequently Asked Question <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is Real Estate Tokenization?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Representing a real estate asset or its cash flow in the
                    blockchain network, the process involved is called Real
                    estate Tokenization. It helps increase liquidity, streamline
                    the process, and enable digital ownership and security.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What are the benefits of Tokenizing real estate assets for
                  Business?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The benefits of tokenizing real estate include improved
                    asset management, monitoring, and decentralized processing
                    invoking security, transparency, and interoperability.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Can real estate tokens be duplicated? If so how?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Real estate tokenization projects can be unique as their
                    smart contracts are curated so that the asset can only
                    perform the pre-defined set of operations and so they act.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  What is the tokenization of real estate development?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The process of converting real estate assets into digital tokens on the blockchain network is called Real Estate token development. This includes tokenizing any real estate properties from buildings, monuments, residential spaces, etc.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  What is the real estate tokenization market in 2024?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    3.5 billion USD is the Market capital of Real estate tokenization in 2024.
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="showFaq" class="faq-section"> 
            <div class="accordion-item">
              <div class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix">
                  Is tokenization the future of real estate?
                </button>
              </div>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Of course, there is no doubt that tokenization is going to take real estate to a new paradigm, while the future dilemma in the market shall also be influenced majorly by the same.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSeven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven">
                  How does real estate tokenization work?
                </button>
              </div>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The workings of Real Estate Tokenization are similar to those of other web3 tokens in the market. The asset is digitalized in the blockchain network while being an asset structure with huge investment, the option to fractionalize is available here, opening space to many micro-investments and growing its value digitally and organically. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingEight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight">
                  What is an example of tokenized real estate?
                </button>
              </div>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Tokenized real estate assets include tokenizing a traditional house, or a geographic location with limited access with Real estate tokenization, which provides ides opportunity to explore digitally. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingNine">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine">
                  How secure is real estate tokenization?
                </button>
              </div>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Blockchain extends its core feature which is security through transparency, its layers protect from data breaches, data manipulation, etc. Real estate tokenization with its ability to liquidate the illiquid asset, opens up space for more investors in a secured infrastructure.  
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTen">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen">
                  What is the future outlook for real estate tokenization?
                </button>
              </div>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The future of Real estate tokenization is positive, and the opportunities to grow are abundant. India expects to increase its GDP by 13% by 2030, and with the blockchain value in the market hitting USD 1 trillion, the future of course is profitable for Real estate tokenization.  
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div (click)="toggleFaq()" class="faq-button">
              <span *ngIf="!showFaq" class="fs-3">
                <i class="bi bi-mouse"></i>
              </span>
              <span *ngIf="showFaq" class="fs-4">
                <i class="bi bi-caret-up-fill"></i> 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Contact Start -->
<div class="app-get-in-touch-form section-service-main">
  <div class="container">
      <div class="contact-form">
          <div class="row">
            <div class="col-lg-6 col-md-12 contact-social-links">
              <h3 class="main-sub-heading"><span>Get In Touch</span></h3>
              <!-- <p>Our expert team is professionally well-equipped with hands-on experience as a blockchain development company. Connect with us; at BlockchainX, we curate your desired sustainable solution! </p> -->
              
              <!-- Calendly inline widget begin -->
              <div class="calendly-inline-widget" data-url="https://calendly.com/blockchainx-tech">
                <iframe src="https://calendly.com/blockchainx-tech?embed_domain=www.calendly-embed.com&amp;embed_type=Inline" width="100%" height="100%" frameborder="0" title="Select a Date &amp; Time - Calendly"></iframe>
              </div>
              
            </div>
            <div class="col-lg-6 col-md-12 contact-inputs">
              <div class="getintouch getintochform bitrix-snippet-form">
                <!-- <div #crmForm></div> -->
                <div [innerHTML]="bitrixSnippetScript"></div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
<!-- Contact End -->