import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-real-world-asset-tokenization-blog',
  templateUrl: './real-world-asset-tokenization-blog.component.html',
  styleUrls: ['./real-world-asset-tokenization-blog.component.css']
})
export class RealWorldAssetTokenizationBlogComponent implements OnInit {

  selectedLink:number;
  title =
    'Exploring the Potential of Real World Asset Tokenization';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Real world asset tokenization gives entirely a new form to monetization while providing the best value and worth of the asset. With both digital and real world existence RWA tokenization is opening up to new possibilities, opportunities, and avenues. Tap to learn in detail.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Real World Asset Tokenization, Real World Asset, Asset Tokenization Platform Development, Tokenization Platform Development, RWA Tokenization, Tokenizing Real World assets, Tokenization Real World Assets',
    });
              

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Exploring the Potential of Real World Asset Tokenization',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Real world asset tokenization gives entirely a new form to monetization while providing the best value and worth of the asset. With both digital and real world existence RWA tokenization is opening up to new possibilities, opportunities, and avenues. Tap to learn in detail.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/real-world-asset-tokenization/banner.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Exploring the Potential of Real World Asset Tokenization',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Real world asset tokenization gives entirely a new form to monetization while providing the best value and worth of the asset. With both digital and real world existence RWA tokenization is opening up to new possibilities, opportunities, and avenues. Tap to learn in detail.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/real-world-asset-tokenization/banner.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/real-world-asset-tokenization-guide-2024.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
