<section class="banners-section">


  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                Unleash Web3<small>.</small>
                <span> Arena Of <strong> Possibilities. </strong></span>
                <span>Collaborative Ecosystem.</span>
              </h1>
              <p>
                Our creative blockchain expertise equips businesses in the most
                reliable manner. It’s secured, alongside withholds supremacy
                among other contenders.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home-banner-left.webp" />
              <source type="image/png"
                srcset="assets-new/images/home-banner-left.png" />
              <img loading="lazy"
                data-src="assets-new/images/home-banner-left.png" width="542"
                height="502" class="img-fluid" alt="BlockchainX"
                title="BlockchainX" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center">
          <a
            (click)="
                about.scrollIntoView({ behavior: 'smooth', block: 'start' })
              ">

            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy" data-src="assets-new/images/home/scroll.png"
                width="25" height="48" class="img-fluid" alt="scroll-icon"
                title="scroll-icon" />
            </picture>
          </a>
        </div>
      </div>
    </div>
  </div>

</section>
<section class="about-main" #about>
  <app-about></app-about>
</section>
<section class="services-main">
  <app-services></app-services>
</section>
<section class="meta-main">
  <app-mataverse></app-mataverse>
</section>

<!--our process start-->
<div class="our_process_wrap">
  <app-ourprocess></app-ourprocess>
</div>
<!--our process end-->
<!--web3 expert-->
<app-expert></app-expert>
<!--end-->
<app-blog></app-blog>

<section class="section-service-main pt-0">
  <app-get-in-touch></app-get-in-touch>
</section>

<!--end-->
