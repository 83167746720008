import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-depin-development-company',
  templateUrl: './depin-development-company.component.html',
  styleUrls: ['./depin-development-company.component.css']
})
export class DepinDevelopmentCompanyComponent implements OnInit {

  title = 'DePIN (decentralized physical infrastructure network) Development - Solution';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Enhance the real-world infrastructure with the advent and contribution of decentralized physical infrastructure network  development with blockchain innovation.Tap to explore!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'DePIN, decentralized physical infrastructure networks DePIN solutions, DePIN development company, DePIN development, DePIN services, DePIN solution company,decentralized physical infrastructure network services, decentralized physical infrastructure network solutions',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'DePIN (decentralized physical infrastructure network) Development - Solution',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/depin-development-company/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Enhance the real-world infrastructure with the advent and contribution of decentralized physical infrastructure network  development with blockchain innovation.Tap to explore!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/depin-development-company.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/depin-development-company/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'DePIN (decentralized physical infrastructure network) Development - Solution',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Enhance the real-world infrastructure with the advent and contribution of decentralized physical infrastructure network  development with blockchain innovation.Tap to explore!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/depin-development-company.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/depin-development-company/'
    );
  }

}
