<!-- Banner Section -->
<section class="banners-section">
  
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>ICO Development Services</strong> Maximize Funding
                  Opportunities For Crypto Projects
                </span>
              </h1>

              <p>
                Incorporating an efficient fundraising system, our ICO
                development services employ an economical model with captivating
                customized features and functionality having scaling effects.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>

                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/maximize-fundraising.webp
                " />

              <source
                type="image/png"
                srcset="
                  assets-new\images\ico-development\maximize-fundraising.png
                " />

              <img loading="lazy"
                data-src="assets-new\images\ico-development\maximize-fundraising.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                class="img-fluid"
                width="500"
                height="501" />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Banner Section ends -->

<!-- Why Choose DAO Platform Development start -->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="row dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section text-center">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/ico-development/webp/diligently-orchestrated.webp
              " />

            <source
              type="image/png"
              srcset="
                assets-new/images/ico-development/diligently-orchestrated.png
              " />

            <img loading="lazy"
              data-src="assets-new/images/ico-development/diligently-orchestrated.png"
              class="img-fluid"
              alt="ICO-development-services "
              title="ICO-development-services"
              width="450"
              height="450" />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading text-start">
            Diligently Orchestrated ICO Development Services
          </h3>
          <p>
            The ICO - Initial Coin Offering is one of a kind fundraising
            mechanism used in the web3 ecosystem. This meets financial support
            for the projects and businesses to take their next growth. ICO is
            where crypto tokens are developed and distributed for the exchange
            of funds. With innovative campaigns, for a business to wild its
            needs and requirements is easy. We at BlockchainX undergo rigorous
            research, formulate strategy, plan your ICO development structure,
            and choose technologies, tools, and other volunteers for the
            progress of the project.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose DAO Platform Development end -->

<!-- STO Launch Methodology starts -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>Why Choose <span> ICO</span> For Fundraising?</h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture pb-1">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/ico-development/webp/global-outreach.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/ico-development/global-outreach.png" />

            <img loading="lazy"
              data-src="assets-new/images/ico-development/global-outreach.png"
              class="img-fluid pb-1"
              alt="Global-outreach"
              title="Global-outreach"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Community Centric</h4>
          <p class="text-center">
            Reaching out to global communities, there is no restriction to
            participating in ICO.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture pb-1">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/ico-development/webp/great-opportunities.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/ico-development/great-opportunities.png" />

            <img loading="lazy"
              data-src="assets-new/images/ico-development/great-opportunities.png"
              class="img-fluid pb-1"
              alt="Great Opportunities"
              title="Great Opportunities"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Promising returns</h4>
          <p class="text-center">
            With the popularity of crypto, the token with the right utility
            yields the best traffic
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture pb-1">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/ico-development/webp/easy-accessibility.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/ico-development/easy-accessibility.png" />

            <img loading="lazy"
              data-src="assets-new/images/ico-development/easy-accessibility.png"
              class="img-fluid pb-1"
              alt="Easy Accessibility"
              title="Easy Accessibility"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Easy Accessibility</h4>
          <p class="text-center">
            A very simple and common fundraising mechanism used in the we3
            space.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/ico-development/webp/large-funds.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/ico-development/large-funds.png" />

            <img loading="lazy"
              data-src="assets-new/images/ico-development/large-funds.png"
              class="img-fluid pb-1"
              alt="Large-Funds"
              title="Large-Funds"
              width="100"
              height="100" />
          </picture>
          <h4 class="erc-20-head">Large Funds</h4>
          <p class="text-center">
            With bigger profits, and the ability to hold for the future, ICO is
            one trusted method.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- STO Launch Methodology ends -->

<!-- Product Features -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>End To End <span>ICO Development </span> Services Includes</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/ico-business-flow.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/ico-development/ico-business-flow.png" />

              <img loading="lazy"
                data-src="assets-new/images/ico-development/ico-business-flow.png"
                class="img-fluid"
                alt="ICO-Business-Flow"
                title="ICO-Business-Flow"
                width="75"
                height="75" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">ICO Business Flow</h4>
          <p>
            We help you completely plan the ICO business flow for your project
            while sitting for ICO development services. From targets to
            achievements, we get plans for all.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/dashboard-design.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/ico-development/dashboard-design.png" />

              <img loading="lazy"
                data-src="assets-new/images/ico-development/dashboard-design.png"
                class="img-fluid"
                alt="Dashboard-Design"
                title="Dashboard-Design"
                width="75"
                height="75" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Dashboard Design</h4>
          <p>
            To be unique, innovative, and intuitive is our important stretched
            service we are precise about. Moreover, we ensure that the outlook
            matches your core.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/dashboard-design.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/ico-development/dashboard-design.png" />

              <img loading="lazy"
                data-src="assets-new/images/ico-development/dashboard-design.png"
                class="img-fluid"
                alt="Dashboard-Design"
                title="Dashboard-Design"
                width="75"
                height="75" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Development</h4>
          <p>
            Token development in the ICO development is the major part to build
            the financial ecosystem of your platform. Deciding its nature and
            functions.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/smartcontract-development.webp
                " />

              <source
                type="image/png"
                srcset="
                  assets-new/images/ico-development/smartcontract-development.png
                " />

              <img loading="lazy"
                data-src="assets-new/images/ico-development/smartcontract-development.png"
                class="img-fluid"
                alt="Smart Contract Development"
                title="Smart Contract Development"
                width="75"
                height="75" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Smart Contract Development</h4>
          <p>
            Smart contract development is a crucial part of the integration.
            Deploying secured and validated contracts to ensure the token's
            righteousness.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/whitepaper-and-website.webp
                " />

              <source
                type="image/png"
                srcset="
                  assets-new/images/ico-development/whitepaper-and-website.png
                " />

              <img loading="lazy"
                data-src="assets-new/images/ico-development/whitepaper-and-website.png"
                class="img-fluid"
                alt="Dashboard-Design"
                title="Dashboard-Design"
                width="75"
                height="75" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Whitepaper & Website</h4>
          <p>
            Detailing the whitepaper of the project and ICO gives a brief,
            meanwhile, the website is more like a virtual entity of the same.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/ico-development/webp/exchange-listing.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/ico-development/exchange-listing.png" />

              <img loading="lazy"
                data-src="assets-new/images/ico-development/exchange-listing.png"
                class="img-fluid"
                alt="Dashboard-Design"
                title="Dashboard-Design"
                width="75"
                height="75" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Exchange Listing</h4>
          <p>
            Heading further with exchange listing, it is evitable in terms of
            token popularity. The growing traffic hits good margins and pulls
            new visitors.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Features -->

<!-- Development Services starts -->
<section class="section-service-alt-main">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>Our <span>ICO Development</span> Approach</h3>
          </div>
        </div>
      </div>
      <div class="row align-items-center erc-processing">
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Gathering Requirements</h4>
            <p>
              Jotting down the requirements of the projects in your ICO token
              helps fix features.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Tokenomics</h4>
            <p>
              An incentive mechanism, dealing with the monetary policy of the
              token in terms of supply and demand.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>ICO Development</h4>
            <p>
              Finalizing the features and functionalities of your ICO token
              gives a proper structure and shape.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Security and Encryption Analysis</h4>
            <p>
              Decentralization and blockchain technology infusions give more
              assurance on security and encryption.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Exchange Listing</h4>
            <p>
              A part of the quoted securities is listed for exchange through
              initial public offerings increasing visibility.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Development Services ends -->

<!-- Why Choose DAO Platform Development start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/ico-development/webp/ico-on-ethereum.webp
              " />

            <source
              type="image/png"
              srcset="assets-new/images/ico-development/ico-on-ethereum.png" />

            <img loading="lazy"
              data-src="assets-new/images/ico-development/ico-on-ethereum.png"
              class="img-fluid"
              alt="ICO development companies"
              title="ICO development companies"
              height="400"
              width="400" />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading text-start">
            ICO Development On Ethereum
          </h3>
          <p>
            To understand most of the ICOs are created on Ethereum, but their
            standards vary from need to need. ERC20 is used for its
            compatibility. And Ethereum eventually optimizes account usage and
            easy transaction efficiency. Also, facilitate unparalleled
            transaction speed that is fluid despite high traffic. The
            compatibility of the chain is the main advantage. And we tend to
            offer you ICO development services in Ethereum and are also open to
            any other networks.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose DAO Platform Development end -->

<!-- Benefits -->
<section class="section-service-alt-main">
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>Versatile <span>ICO Token Development</span> Services</h3>
    </div>
    <div class="row acc-sec-list">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-image text-center">
        <picture>
          <source
            type="image/webp"
            srcset="
              assets-new/images/ico-development/webp/ico-token-development.webp
            " />

          <source
            type="image/png"
            srcset="assets-new/images/ico-development/ico-token-development.png" />

          <img loading="lazy"
            data-src="assets-new/images/ico-development/ico-token-development.png"
            class="img-fluid"
            alt="ICO token development"
            title="ICO token development"
            height="400"
            width="400" />
        </picture>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                ERC20
              </button>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>Ethereum standard token with interchangeable quality.</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                ERC721
              </button>
            </div>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Unique digital token, representing ownership of
                  non-fungibility.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                ERC1400
              </button>
            </div>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>Security token standard that yields consistency.</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
                EOS
              </button>
            </div>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Cryptocurrency was developed to support large-scale
                  applications.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive">
                Stellar
              </button>
            </div>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Used to create, send, and trade digital assets for any crypto,
                  and fiat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Benefits -->

<!-- Tech Stak Update -->
<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading">
      <h3>Latest Tech Stacks of <span>ICO Development</span></h3>
    </div>
    <div class="business-stak-list-section">
      <div class="metaverse_subheading">
        <h3 class="text-center">Blockchain Platform</h3>
      </div>
      <div class="row row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 align-items-center">
        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/frameworks.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/frameworks.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/frameworks.png"
                  width="200" height="73" class="img-fluid" alt="Frameworks"
                  title="Frameworks" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/frameworks.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/frameworks.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/frameworks.png"
                  width="200" height="73" class="img-fluid" alt="Frameworks"
                  title="Frameworks" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/caliber.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/caliber.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/caliber.png" width="185"
                  height="104" class="img-fluid" alt="Caliber"
                  title="Caliber" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/caliber.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/caliber.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/caliber.png" width="185"
                  height="54" class="img-fluid" alt="Caliber"
                  title="Caliber" />
              </picture>

            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger-composer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger-composer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-composer.png"
                  width="235" height="101" class="img-fluid" alt="Hyperledger Composer"
                  title="Hyperledger Composer" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-composer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-composer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger-composer.png"
                  width="235" height="101" class="img-fluid" alt="Hyperledger Composer"
                  title="Hyperledger Composer" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger-explorer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger-explorer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                  width="217" height="93" class="img-fluid"
                  alt="hyperledger-explorer" title="hyperledger-explorer" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-explorer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-explorer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger-explorer.png"
                  width="217" height="93" class="img-fluid"
                  alt="hyperledger-explorer" title="hyperledger-explorer" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="business-stak-list-section">
      <div class="metaverse_subheading">
        <h3 class="text-center">Front End Frame Works</h3>
      </div>
      <div class="row row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger.png"
                  width="253" height="100" class="img-fluid" alt="hyperledger"
                  title="hyperledger" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger.png"
                  width="253" height="100" class="img-fluid" alt="hyperledger"
                  title="hyperledger" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/sawtooth.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/sawtooth.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/sawtooth.png"
                  width="232" height="100" class="img-fluid" alt="sawtooth"
                  title="sawtooth" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/sawtooth.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/sawtooth.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/sawtooth.png"
                  width="232" height="100" class="img-fluid" alt="sawtooth"
                  title="sawtooth" />
                  </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/iroha.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/iroha.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/iroha.png" width="175"
                  height="98" class="img-fluid" alt="Iroha"
                  title="Iroha" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/iroha.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/iroha.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/iroha.png" width="175"
                  height="98" class="img-fluid" alt="Iroha"
                  title="Iroha" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/fabric.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/fabric.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/fabric.png" width="174"
                  height="99" class="img-fluid" alt="Fabric" title="Fabric" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/fabric.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/fabric.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/fabric.png" width="174"
                  height="99" class="img-fluid" alt="Fabric" title="Fabric" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="business-stak-list-section">
      <div class="metaverse_subheading">
        <h3 class="text-center">Programming Languages</h3>
      </div>
      <div class="row row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/caliber.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/caliber.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/caliber.png" width="185"
                  height="104" class="img-fluid" alt="Caliber"
                  title="Caliber" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/caliber.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/caliber.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/caliber.png" width="185"
                  height="54" class="img-fluid" alt="Caliber"
                  title="Caliber" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger.png"
                  width="253" height="100" class="img-fluid" alt="hyperledger"
                  title="hyperledger" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger.png"
                  width="253" height="100" class="img-fluid" alt="hyperledger"
                  title="hyperledger" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger-explorer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger-explorer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                  width="217" height="93" class="img-fluid"
                  alt="hyperledger-explorer" title="hyperledger-explorer" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-explorer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-explorer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger-explorer.png"
                  width="217" height="93" class="img-fluid"
                  alt="hyperledger-explorer" title="hyperledger-explorer" />
              </picture>
            </div>
          </div>
        </div>

        <!-- <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/stak/hyperledger.webp">
                <source type="image/png" srcset="assets-new/images/home/stak/hyperledger.png">
                <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger.png"  alt="Php" title="CPhp"  class="img-fluid"
                 width="75%" height="100%" />
              </picture>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
<!-- Tech Stak Update -->
<!-- why_choose_us starts -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>Why BlockchainX ?</h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              Our most demanded ICO development services are also open with
              customization abilities, and we ensure to deliver a tech-savvy
              solution meeting your requirements. As an ICO development company,
              we render cost-effective and efficient web3 solutions that help
              you set standards in the flourishing digitized market.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Read More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png" />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="why-blockchainX"
                title="why-blockchainX" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- why_choose_us ends-->

<section class="section-service-main">
  <app-blog></app-blog>
</section>

<section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section>