<section class="banners-section" #about>
  
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Bitcoin Layer 2 </strong></span> Development
                Services
              </h1>
              <p>
                Hire our expertise in Bitcoin Layer 2 development to experience
                decentralization, security, and transparency enhancing
                scalability and programmability at ease!
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/launch-your-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-images/launch-your-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-images/launch-your-token.png"
                width="500" height="500" class="img-fluid"
                alt="Stablecoin Development Company"
                title="Stablecoin Development Company" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>What Is Bitcoin </span>Layer2
        Development?</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Stablecoin Development Services"
              title="Stablecoin Development Services"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Bitcoin Layer 2 development is creating and implementing the
            secondary protocol or solution that is built on the top of the
            Bitcoin blockchain enhancing scalability, speed, functionality, and
            interoperability. Addressing the limitation of the bitcoin network,
            the Layer 2 blockchain instills the process of transactions in less
            time at lower cost and enables off-chain transactions and
            computation at ease. This also alleviates congestion and reduces
            transaction costs by allowing users to conduct transactions and
            execute smart contracts in the main Bitcoin network theory solving
            congestion. Layer2 Bitcoin Blockchain has expanded capability and
            usability making operation more efficient and versatile.
          </p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Trending ERC20 Token List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Stablecoin Development Solution"
              title="Stablecoin Development Solution" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Famous Bitcoin
            </span>Layer 2 Projects</h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Statechains
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Liquid Network
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> CKB Public
                  Chain
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Dovi
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Stacks
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Merlin Chain
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Bitcoin Layer 2 </span>Blockchain Development Process</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Research and Planning"
                title="Research and Planning" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Research and Planning</h4>
          <p>
            Starting off with understanding the requirements and the objectives
            of our clients, the Bitcoin layer2 solutions development process
            starts. We determine the technology, and do the research and market
            analysis.
          </p>
          <div class="content-link">
            <a [routerLink]="['/blockchain-consulting-services']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Design" title="Design"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Design
          </h4>
          <p>
            Heading forward, we curate a detailed design structure of the
            platform from interiors, and protocols to smart contract
            specifications and others, ensuring the solution is scalable,
            secured, and ensuring its highly user-friendly.
          </p>
          <div class="content-link">
            <a [routerLink]="['/token-development-company']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Development" title="Development"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Development</h4>
          <p>
            From the approved design, we write code for the Layer 2 solution
            which involves developing smart contracts, client software
            development, integrating the bitcoin blockchain, and so on.
          </p>
          <div class="content-link">
            <a [routerLink]="['/']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Testing" title="Testing"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Testing</h4>
          <p>
            After putting them all together, it's important to ensure there is
            coherence, integrity, security, and compatibility. Individual
            components are tested and in whole to ensure the flow and the
            breakdowns are turned into possibilities.
          </p>
          <div class="content-link">
            <a [routerLink]="['/cryptocurrency-wallet-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Deployment and Integration"
                title="Deployment and Integration" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Deployment and Integration</h4>
          <p>
            The solution is deployed in the Bitcoin network and testnet
            including Smart contract, and the software. Then integrated with
            Bitcoin applications, wallets, and services and ensures seamless
            operations with each other.
          </p>
          <div class="content-link">
            <a [routerLink]="['/smart-contract-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Monitoring and Maintenance"
                title="Monitoring and Maintenance" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Monitoring and Maintenance
          </h4>
          <p>
            We then monitor the performance of The Bitcoin layer 2 software
            developed, ensure its in perfect working, and leap in when required
            assistance and maintain its integrity, pitch in, when in need of
            assistance.
          </p>
          <div class="content-link">
            <a [routerLink]="['/ready-made-ico-software']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Develop Bitcoin Layer 2 </span>with BlockchainX
          </h2>
          <p>
            BlockchainX is a pioneer in blockchain development and related
            services, we are a team of a professional young team with the
            curiosity to learn and implement in our day-to-day aspect through
            our services. Form Token development, DeFi and Dex platform,
            Metaverse, NFT marketplace, and much more are still in the line. We
            create customized solutions that ease user experience and enhance
            security and trust. We also ensure to provide Bitcoin Layer 2
            development in the most dedicated, seamless, strong, and stable
            manner.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
        <div class="whysection_text_wrap">

          <!-- <div class="content-link my-3">
                <a [routerLink]="['/contact']">
                  <span>Contact Us</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow"
                       />
                  </picture>
                </a>
                <hr />
              </div> -->
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Stablecoin Development Company"
              title="Stablecoin Development Company" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What Is Bitcoin Layer 2?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The secondary framework or protocol layer built on top of
                    the Bitcoin blockchain is Bitcoin layer 2 development. It
                    has enhanced and improved scalability and functionality that
                    aims to address the limitations of the Bitcoin network.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What are the advantages of Layer2 Bitcoin development?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The advantages of Layer2 Bitcoin development include
                    Scalability, lower transaction cost, instant payment
                    privacy, interoperability, and much more in the system,
                    making it trustable and secure.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  What other services BlockhainX provides?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    At BlockchainX we provide end-to-end services concerning
                    blockchain development. From token development, IDO
                    platform, DEX and Defi platform, NFT marketplaces,
                    metaverse, AI, and much more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/what-is-depin']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/depin/depin-extravaganza.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/depin/depin-extravaganza.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/depin/depin-extravaganza.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="DePIN Extravaganza"
                    title="DePIN Extravaganza" />
                </picture>
                <h3>
                  DePIN - What Is Decentralized Physical Infrastructure Network?
                </h3>
                <p>
                  DePIN, or the Decentralized Physical Infrastructure Network,
                  is a decentralized protocol using tokens to incentivize...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/what-is-depin']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/ai-token-definition']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/all-about-ai-token/all-about-ai-token.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="AI Token"
                    title="AI Token" />
                </picture>
                <h3>
                  AI Token - The Whats Of It!
                </h3>
                <p>
                  AI tokens are the new revolution in the crypto market.
                  Artificial intelligence is leveraging every sector, and
                  eventually...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/ai-token-definition']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/real-estate-tokenization-guide']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Real Estate Tokenization"
                    title="Real Estate Tokenization Enabling Endless Investment Opportunites" />
                </picture>
                <h3>
                  How does Real Estate Tokenization Revolutionize Investments?
                </h3>
                <p>
                  Over the years, investment scales have been tremendously
                  improving, and new avenues are blooming now and then with
                  blockchain...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/real-estate-tokenization-guide']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/top-real-world-asset-tokenization-companies']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Top 10 Real World Asset Tokenization Companies 2024"
                    title="Top 10 Real World Asset Tokenization Companies 2024" />
                </picture>
                <h3>
                  Top 10 Real World Asset Tokenization Companies 2024
                </h3>
                <p>
                  The very modern monetizing process, real-world asset
                  tokenization, involves curating digital presence and value for
                  real-world assets...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/top-real-world-asset-tokenization-companies']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/erc20-token-development-guide']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/erc20-token-development-guide/erc20-token-development.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/erc20-token-development-guide/erc20-token-development.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/erc20-token-development-guide/erc20-token-development.jpg"
                    width="404"
                    height="245"
                    class="img-fluid"
                    alt="ERC20 Token development With BlockchainX"
                    title="ERC20 Token development With BlockchainX" />
                </picture>
                <h3>
                  Complete Guide For ERC20 Token Development
                </h3>
                <p>
                  In 2015, Fabian Vogelstellar created the ERC20 token standard
                  which was a quick pick and gained instant popularity...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/erc20-token-development-guide']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Invest in the ability to adopt
                Bitcoin technologies at the Earliest </span></h3>
            <p>With Bitcoin Layer2 Development for business and beyond
              operations!</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->