<section class="blogs">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="d-flex justify-content-between">
        <div class="common-sub-heading sub-title">
          Our Latest Blogs<span class="dots"></span>
        </div>
        <div class="deploy-link justify-content-end">
          <a [routerLink]="['/blog']">
            <!-- <span>Read More</span> -->
            <img
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode"
                />
                <img
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode"
                />
          </a>
        </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-bep20-token/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/create-bep20-token/create-bep20-token-on-the-binance-smart-chain.webp">
                  <img loading="lazy" src="assets-new/images/blog/create-bep20-token/create-bep20-token-on-the-binance-smart-chain.jpg" alt="Create BEP20 Token On the Binance Smart Chain" title="Create BEP20 Token On the Binance Smart Chain" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  8 Steps to Create BEP20 Token On the Binance Smart Chain
                </p>
                <p>
                  Are you ready to take the leap into the world of blockchain and launch a new digital asset? Knowing how to create BEP20 token on the Binance...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-bep20-token/">
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/asset-tokenization/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/asset-tokenization/asset-tokenization-on-blockchain.webp">
                  <img loading="lazy" src="assets-new/images/blog/asset-tokenization/asset-tokenization-on-blockchain.jpg" alt="Asset Tokenization On Blockchain" title="Asset Tokenization On Blockchain" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  Asset Tokenization On Blockchain Detailed Tutorial
                </p>
                <p>
                  The widespread establishment of blockchain and its co-relatives like Cryptocurrency, decentralised finances, developing decentralised...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/asset-tokenization/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/erc20-vs-trc20-vs-bep20/">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/erc20-vs-trc20-vs-bep20/erc20-vs-bep20-vs-trc20-banner.webp
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/erc20-vs-trc20-vs-bep20/erc20-vs-bep20-vs-trc20-banner.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="ERC20 vs Bep20 vs TRC20"
                    title="ERC20 vs Bep20 vs TRC20"
                  />
                </picture>
                <p class="h-3">
                  ERC20 vs TRC20 vs BEP20: A Comparison Guide
                </p>
                <p>
                  With a wide range of options and functions the crypto token industry provides businesses with versatile options. Most of the...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/erc20-vs-trc20-vs-bep20/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-a-meme-coin/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/create-a-meme-coin/create-a-meme-coin.webp">
                  <img loading="lazy" src="assets-new/images/blog/create-a-meme-coin/create-a-meme-coin.jpg" alt="Create a Meme Coin That Goes Viral: Insider Tips" title="Create a Meme Coin That Goes Viral: Insider Tips" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  7 Steps to Create a Meme Coin: Insider Tips for Crypto Success
                </p>
                <p>
                  The cryptocurrency space has expanded quickly, and among its many developments, meme coins have captured a lot of attention. Unlike...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-a-meme-coin/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/token-development-services-list/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/token-development-services/token-development-services.webp">
                  <img loading="lazy" src="assets-new/images/blog/token-development-services/token-development-services.jpg" alt=" Token Development Services List" title=" Token Development Services List" class="img-fluid" width="856" height="522"/>
                </picture>        
                <p class="h-3">
                  Token Development Services List: Customized For You
                </p>
                <p>
                  The crypto token economy along with its fandom is increasing with great evolution and yielding better results...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/token-development-services-list/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-solana-meme-coin/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/create-solana-meme/create-solana-meme-coin.webp">
                  <img loading="lazy" src="assets-new/images/blog/create-solana-meme/create-solana-meme-coin.jpg" alt="Create your own Solana meme coin" title="Create your own Solana meme coin" class="img-fluid" width="856" height="522"/>
                </picture>    
                <p class="h-3">
                  7 Steps To Create Your Solana Meme Coin  : Detailed Guide
                </p>
                <p>
                  With its volatile nature, Meme coin for its trend and engagement has caught the interest of many developers to create Solana meme coin...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-solana-meme-coin/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>          
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
