import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-erc20-token-development-new',
  templateUrl: './erc20-token-development-new.component.html',
  styleUrls: ['./erc20-token-development-new.component.css'],
})
export class Erc20TokenDevelopmentNewComponent implements OnInit {
  title = 'ERC20 Token Development Company - Blockchainx';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'The Ethereum Network has been prominent proving its potential in the blockchain era, with its efficiency in almost every sector. Invade into the trends and scale up your business in Web3 by choosing to Create ERC20 tokens with us. Learn more about our service and expertise here!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Create erc20 token, erc20 token development, erc20 token generator, erc20 token development company, erc20 token development service',
    });    
    
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'ERC20 Token Development Company - Blockchainx',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/erc20-token-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'The Ethereum Network has been prominent proving its potential in the blockchain era, with its efficiency in almost every sector. Invade into the trends and scale up your business in Web3 by choosing to Create ERC20 tokens with us. Learn more about our service and expertise here!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/erc-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/erc20-token-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'ERC20 Token Development Company - Blockchainx',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'The Ethereum Network has been prominent proving its potential in the blockchain era, with its efficiency in almost every sector. Invade into the trends and scale up your business in Web3 by choosing to Create ERC20 tokens with us. Learn more about our service and expertise here!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/erc-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/erc20-token-development/'
    );
  }
  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}
