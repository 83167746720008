<div class="comment-box-section">
  <div class="blog-single-wrap">
    <p class="pb-3 h-3">Comments</p>
  </div>
  <form>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        id="name"
        placeholder="Enter your name"
      />
    </div>
    <div class="form-group">
      <input
        type="email"
        class="form-control"
        id="email"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
    </div>
    <div class="form-group">
      <textarea
        class="form-control"
        id="comment"
        rows="3"
        placeholder="Enter your comment"
      ></textarea>
    </div>
    <button type="submit" class="btn btn-submit">Submit</button>
  </form>
</div>
