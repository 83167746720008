<section class="banners-section" #about>
    
    <div class="banner_wrapper">
        <div class="container" id="common-relative">
            <div class="green-vector"></div>
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div class="banner_contentWrapper">
                        <div class="caption">
                            <h1>
                                <span><strong> Blockchain IoT </strong> Software
                                    Solution</span>
                            </h1>
                            <p>
                                Redefining new gen applications with the utmost
                                realistic and duly response with Blockchain IoT
                                solutions with acute logic shifting business to
                                the global market efficiently.
                            </p>

                            <div class="contact-link">
                                <a [routerLink]="['/contact']"
                                    class="home-link">Talk To Our Experts</a>
                                <a [routerLink]="['/case-studies']"
                                    class="home-link-alt">View Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                    <div class="banner-right-section">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/launch-your-token.webp" />
                            <source type="image/png"
                                srcset="assets-new/images/token-images/launch-your-token.png" />
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/launch-your-token.png"
                                width="500" height="500" class="img-fluid"
                                alt="Blockchain IoT Software Solution"
                                title="Blockchain IoT Software Solution" />
                        </picture>
                    </div>
                </div>

            </div>
            <div class="row d-lg-block d-none">
                <div class="scroll-section text-center"
                    (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/home/scroll.webp" />
                        <source
                            type="image/png"
                            srcset="assets-new/images/home/scroll.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/home/scroll.png"
                            width="25"
                            height="48"
                            class="img-fluid"
                            alt="scroll-icon"
                            title="scroll-icon" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
        <div class="whys_section mb-0 text-center">
            <h2 class="pb-lg-5 pb-4"><span>Blockchain IoT</span> - The Duo</h2>
        </div>
        <div class="row align-items-center">
            <div
                class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
                <div class="bep-work-section">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/token-images/why-should-you-choose.webp">
                        <source type="image/png"
                            srcset="assets-new/images/token-images/why-should-you-choose.png">
                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-should-you-choose.png"
                            width="350" height="350"
                            alt="Blockchain IoT - The Duo"
                            title="Blockchain IoT - The Duo"
                            class="BSC-Tokens-image img-fluid">
                    </picture>
                </div>
            </div>
            <div
                class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
                <div class="common-sub-heading">
                    <p>
                        Integrating the Internet Of Things with Blockchain
                        networks involves more leverage on security,
                        transparency, and decentralization. A duo enhancing
                        security, reliability, security, privacy, and efficiency
                        all together to seamlessly manage communication and data
                        management. Blockchain IoT development brings together
                        the immutable nature of immutable ledgers and smart
                        contracts with connectivity and data collections
                        enabling an automated, decentralized decision-making
                        ecosystem. Capable of influencing varied industrial
                        sectors.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<section class="section-service-main">
    <div class="tending-bep-20">
        <div class="container">
            <div class="row trending-token-list">
                <div class="col-lg-6 text-center order-lg-first order-last">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
                        <source type="image/png"
                            srcset="assets-new/images/erc/trending-erc20-token-list.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/erc/trending-erc20-token-list.png"
                            width="405" height="405"
                            alt="Blockchain IoT Software Projects"
                            title="Blockchain IoT Software Projects"
                            class="img-fluid mx-auto">
                    </picture>
                </div>
                <div class="col-lg-6 mb-lg-0 mb-4">
                    <h3 class="main-sub-heading text-start"><span>Few Successful
                            Blockchain
                        </span>IoT Software Projects</h3>
                    <div class="row trending-bep">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://internetofthings.ibmcloud.com/?ref=bitsgap.com">IBM
                                        Watson IoT Platform</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://celestia.org/?ref=bitsgap.com">Celestia</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://5thscape.com/?ref=bitsgap.com">5thScape</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.helium.com/?ref=bitsgap.com">Helium</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://builtin.com/company/hypr">HYPR</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://builtin.com/company/xage-security">Xage
                                        Security</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex pt-3">
                        <a [routerLink]="['/contact']"
                            class="home-link-alt">Connect With
                            Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 metaverse_heading mx-auto">
                <h3><span>Industries Influenced By </span>Blockchain IoT
                    Software Development</h3>
            </div>
        </div>
        <div class="row types-of-token-detail">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/project-consulting.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token images/project-consulting.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/project-consulting.png"
                                width="75" height="75"
                                alt="Supply Chain"
                                title="Supply Chain"
                                class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Supply Chain</h4>
                    <p>
                        Blockchain IoT contributes to enhancing transparency and
                        traceability in the supply chain. From manufacture to
                        delivery the system updates with everything with utmost
                        authenticity.
                    </p>
                    <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/staking-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/staking-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/staking-platform.png"
                                width="75" height="75" alt="Smart Cities"
                                title="Smart Cities" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Smart Cities
                    </h4>
                    <p>
                        Facilitating efficient management in urban
                        infrastructure from energy grids, transportation
                        systems, waste management, and contributing toward
                        improved sustainability this technology is a star.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/farming-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/farming-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/farming-platform.png"
                                width="75" height="75" alt="Agriculture"
                                title="Agriculture" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Agriculture</h4>
                    <p>
                        With the advancement, IoT Blockchain development in
                        Agriculture ensures to provide on-point information from
                        weather forecast to resource optimization, a very
                        helpful technology to vest on.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/token-bridge.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/token-bridge.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/token-bridge.png"
                                width="75" height="75" alt="Healthcare"
                                title="Healthcare" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Healthcare</h4>
                    <p>
                        In secured storages, the sector can seamlessly transfer
                        data and provide an enhanced user experience with prompt
                        solutions utilizing blockchain IoT software in
                        healthcare institutions.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/layer-i-blockchain.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                                width="75" height="75" alt="Energy Management"
                                title="Energy Management" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Energy Management</h4>
                    <p>
                        By enabling peer-to-peer energy trading, grid
                        optimization, and renewable energy integration, the
                        software empowers consumers to buy and sell energy
                        directly at lower costs, promoting sustainability.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/nft-markeplace.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/nft-markeplace.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/nft-markeplace.png"
                                width="75" height="75"
                                alt="Insurance"
                                title="Insurance" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Insurance
                    </h4>
                    <p>
                        Blockchain IoT in Insurance is modeled to collect data
                        from IoT devices and allow insurers to offer
                        personalized policies based on individual behavior and
                        risk profiles.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 metmb-3">
                <div class="whys_section mb-0">
                    <h2>
                        <span>Blockchain IoT Development With BlockchainX</span>
                    </h2>
                    <p class="pb-3">
                        As we advance, the prime quality and nature of our
                        Experts at BlockchainX is that we tend to grow with
                        technology and advancement. Our curiosity for tech and
                        its attributes has made us successful in the year and
                        will also be so. At BlockchainX our services are
                        customized and tailored for your preference and use
                        case. Over 7 plus years in the sector we are truly
                        dedicated, involved, and passionate about creating a
                        creative solution for global problems with Blockchain
                        and other new-gen technology like AI, ML, IoT, AR/VR,
                        and much more!
                    </p>
                    <div class="pt-lg-3 pt-0">
                        <a [routerLink]="['/contact']">
                            <!-- <span>Read More</span> -->
                            <picture>
                                <source type="image/webp"
                                  srcset="assets-new/images/right-arrow.webp">
                                <source type="image/png"
                                  srcset="assets-new/images/right-arrow.png">
                                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                                   />
                              </picture>
                              <picture>
                                <source type="image/webp"
                                  srcset="assets-new/images/right-arrow-dark.webp">
                                <source type="image/png"
                                  srcset="assets-new/images/right-arrow-dark.png">
                                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                                   />
                              </picture>
                        </a>
                        <hr />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 metmb-3 Why-section">
                <div class="why_right_side">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                        <source
                            type="image/png"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                            width="624"
                            height="521"
                            class="img-fluid"
                            alt="Blockchain IoT Development With BlockchainX"
                            title="Blockchain IoT Development With BlockchainX" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
            <div class="green-vector"></div>
            <div class="violet-vector"></div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div class="common-sub-heading sub-title">
                        FAQ <span class="dots">.</span>
                    </div>
                    <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
                </div>
            </div>
            <div
                class="row align-items-center justify-content-center pt-md-5 pt-4">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne">
                                    What are the different use cases of
                                    Blockchain IoT solutions?
                                </button>
                            </div>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Blockchain IoT solutions are used in
                                        different Sectors, which include Supply
                                        Chain management, energy management,
                                        smart contract automation, asset
                                        tracking systems, healthcare, and more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    How is Blockchain IoT the best combo?
                                </button>
                            </div>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        In terms of security, transparency,
                                        decentralized qualities, and efficiency,
                                        IoT adds to the functioning and
                                        operation of Blockchain hence they
                                        reflect to provide an effective solution
                                        for day-to-day actions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree">
                                    How is the future of Blockchain IoT
                                    solutions?
                                </button>
                            </div>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Blockchain IoT has a promising future as
                                        the advancements in tech and its
                                        adaptation have been immense. It's just
                                        not the industries, but in our
                                        day-to-day life, they are very
                                        influential. Quality of sustainability,
                                        standardization, integration, and
                                        others, stand still and shall progress
                                        better.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
    <app-blog></app-blog>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
    <div class="app-get-in-touch-form">
        <div class="container">
            <div class="contact-form">
                <div class="row reverse-colum">
                    <div class="col-lg-5 col-md-12 contact-social-links">
                        <h3 class="main-sub-heading"><span>Tap on the ultimate
                                combo Blockchain IoT software
                                solutions</span></h3>
                        <p>With BlockchainX empower your operation in real
                            time!</p>
                        <div class="contact-main-list">
                            <div class="social-links">
                                <a href="mailto:contact@blockchainx.tech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="contact-mail">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/mail.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/mail.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/mail.png"
                                            alt="linkedin"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://wa.me/7708889555"
                                    target="_blank"
                                    rel="noopener noreferrer" class="whatsapp">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/whatsapp-icon.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/whatsapp-icon.png"
                                            alt="contact-Whatsapp"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://t.me/sparkouttechblockchainx"
                                    target="_blank"
                                    rel="noopener noreferrer" class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/telegram.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/telegram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/telegram.png"
                                            alt="twitter"
                                            width="40"
                                            height="40" />
                                    </picture>
                                </a>
                            </div>
                        </div>
                        <div class="contact-links">
                            <h4>Follow us on</h4>
                            <div class="social-links">
                                <a
                                    href="https://www.linkedin.com/company/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="linkdin">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/linkdin.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/linkdin.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/linkdin.png"
                                            alt="linkedin"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://www.instagram.com/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="instagram">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/instagram.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/instagram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/instagram.png"
                                            alt="instagram"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://twitter.com/Blockchainxtech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/twitterx.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/twitterx.svg" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/twitterx.svg"
                                            alt="twitter"
                                            width="25"
                                            height="25" />
                                    </picture>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 contact-inputs">
                        <div
                            class="getintouch getintochform bitrix-snippet-form">
                            <div [innerHTML]="bitrixSnippetScript"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact End -->