<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong> Launch the Next </strong></span> Meme Coin
                Moonshot
              </h1>
              <p>
                Take your meme coin project to new heights and thousands of
                holders. Our Meme Coin development service is your first step to
                capturing the crypto bulls.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/launch-your-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-images/launch-your-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-images/launch-your-token.png"
                width="500" height="500" class="img-fluid"
                alt="AI Meme Coin development"
                title="AI Meme Coin development" />
            </picture>
          </div>
        </div>

      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- Why consider AI Meme start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Why consider AI Meme </span>Coin
        Development?</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Meme Coin Development"
              title="Meme Coin Development" class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Meme Coins are no joke, in fact, these cryptocurrencies are some of
            the biggest opportunities in the whole of Web3. As a Meme Coin
            project owner, you have the chance to build a fun and collaborative
            community of netizens who support your venture from start to finish.
            AI Meme Coins are a sub niche to this trend with a lot of popularity
            among degens and serious investors alike. And, our AI Meme Token
            development service is geared towards helping you tame the bulls.
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Customized Tokens
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Trading Bot
                Integration
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> MEV Bot
                Protection
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Audited
                Codes
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Trending ERC20 Token List Start -->
<section class="section-service-main">
  <div class="tending-bep-20 pb-xl-4">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3 class="popular-ai">
            <span>Popular AI Meme</span>
            Coin Projects
          </h3>
        </div>
      </div>
      <div class="row client-carousel">
        <div class="col-lg-12 mx-auto">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/ai-meme-coin/bytecoin.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/ai-meme-coin/bytecoin.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/ai-meme-coin/bytecoin.png"
                    width="auto" height="auto" class="img-fluid" alt="byte"
                    title="byte" />
                </picture>
                <h4>Byte</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/ai-meme-coin/corgiai.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/ai-meme-coin/corgiai.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/ai-meme-coin/corgiai.png"
                    width="auto" height="auto" class="img-fluid" alt="corgi ai"
                    title="corgi ai" />
                </picture>
                <h4>Corgi AI</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/ai-meme-coin/grokai.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/ai-meme-coin/grokai.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/ai-meme-coin/grokai.png"
                    width="auto" height="auto" class="img-fluid" alt="grok"
                    title="grok" />
                </picture>
                <h4>Grok</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/ai-meme-coin/kizuna.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/ai-meme-coin/kizuna.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/ai-meme-coin/kizuna.png"
                    width="auto" height="auto" class="img-fluid" alt="kizuna"
                    title="kizuna" />
                </picture>
                <h4>Kizuna</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/ai-meme-coin/nyxia.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/ai-meme-coin/nyxia.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/ai-meme-coin/nyxia.png"
                    width="auto" height="auto" class="img-fluid" alt="nyxia ai"
                    title="nyxia ai" />
                </picture>
                <h4>Nyxia AI</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/ai-meme-coin/turbo.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/ai-meme-coin/turbo.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/ai-meme-coin/turbo.png"
                    width="auto" height="auto" class="img-fluid" alt="turbo"
                    title="turbo" />
                </picture>
                <h4>Turbo</h4>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Our Complete<span> AI Meme Coin</span> Development Offering</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Project Consulting"
                title="Project Consulting" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Project Consulting</h4>
          <p>
            Let our expert web3 consultants guide you through the entire process
            of AI Meme Coin development.
          </p>
          <div class="content-link">
            <a [routerLink]="['/blockchain-consulting-services']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Staking Platform"
                title="Staking Platform" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Staking Platform
          </h4>
          <p>
            Launch a staking campaign to invite more users into your AI Meme
            Coin community, and reduce sell-offs.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-staking-platform-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Farming Platform"
                title="Farming Platform" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Farming Platform</h4>
          <p>
            Similar to Staking, Farming campaigns allow you to incentivize users
            to provide liquidity to your AI Meme Coin.
          </p>
          <div class="content-link">
            <a [routerLink]="['/defi-yield-farming-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Token Bridge" title="Token Bridge"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Bridge</h4>
          <p>
            Leverage the user base of multiple blockchains by making your AI
            Meme Coin cross-chain on any EVM network.
          </p>
          <div class="content-link">
            <a [routerLink]="['/']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Layer 1 Blockchain"
                title="Layer 1 Blockchain" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Layer 1 Blockchain</h4>
          <p>
            Build your own Layer 1 Blockchain Network for your AI Meme Coin
            project and tackle the market like Shibarium.
          </p>
          <div class="content-link">
            <a [routerLink]="['/']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="NFT Marketplace"
                title="NFT Marketplace" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            NFT Marketplace
          </h4>
          <p>
            Launch an NFT collection with your AI Meme Coin as the currency of
            exchange and bring more liquidity to your token.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-marketplace-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-3">
          <h2>
            Why Choose BlockchainX for <span>AI Meme Coin Development</span>
          </h2>
          <p>
            As an AI Meme Coin Development Company, we have been helping web3
            startups build innovative solutions for their projects since 2017.
            AI Meme Coins are a fun and simple way for startups to bring their
            ideas to the market. However, building a secure token that works
            exactly as you want it to requires the capable hands of experts and
            optimized code. BlockchainX is the perfect partner for startups that
            want a hassle-free beginning to their project journey.
          </p>
          <div class="content-link my-3">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
        <!-- <div class="whysection_text_wrap">
              <ul>
                <li>Experts in creating crypto tokens in various blockchains</li>
                <li>6 Years of experience in token development service</li>
                <li>Our developers are skilled, equipped, and at the same time open to learning</li>
                <li>Provide crypto exchange provide ERC20 tokens with the highest quality standard</li>
              </ul>
              
              <div class="content-link my-3">
                <a [routerLink]="['/contact']">
                  <span>Contact Us</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div> -->
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="AI Meme Coin development"
              title="AI Meme Coin development" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  How is AI Meme Token Development different from regular Meme
                  Coin Development?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    AI Meme Coins are often utility tokens for larger AI
                    projects. While Meme Coins are considered a fun way to build
                    communities, AI Meme Coins offer utility to this trendy
                    crypto niche.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  How long does it take to build an AI Meme Coin?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    AI Meme Token Development can take anywhere from 5-7 days to
                    program, test, and deploy on the Mainnet.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Will the AI Model run on-chain?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Blockchains are not capable enough to run complex programs
                    like AI/ML Models. Instead, the AI components run off-chain
                    and any relevant data that needs to go on chain goes through
                    an Oracle to the smart contract.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  Does an AI Meme Coin work on regular DEXs?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    We use the ERC20 standard for AI Meme Coin development. This
                    means that it will work on any Automated Market Maker-based
                    DEXs like Uniswap and PancakeSwap.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  Will AI Meme Coins work on any wallet?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Since these tokens are built using the ERC20 standard, you
                    can store and transact them using any popular web3 wallet
                    that supports the ERC20 token standard.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/what-is-erc20']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/blog/what-is-erc20/erc20-token-standard.webp
                    " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="erc20-token-standard"
                    title="erc20-token-standard" />
                </picture>
                <h3>
                  What is ERC20? A Guide to the Pinnacles of Ethereum Token
                  Standard
                </h3>
                <p>
                  While this crypto revolution continues to change the virtual
                  and the real world, the ERC20 token standards...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/what-is-erc20']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/erc1155-token-standard'
                ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/erc-1155.webp
                    " />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/erc-1155.jpg
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/erc-1155.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="erc-1155"
                    title="erc-1155" />
                </picture>
                <h3>
                  ERC1155 – Explore the ERC-1155 Token Standard
                </h3>
                <p>
                  If you understand what an non-fungible-token-development NFT
                  is and that the abbreviation...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/erc1155-token-standard'
                  ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/how-to-create-a-private-blockchain'
                ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.webp
                    " />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="private-blockchain-development"
                    title="private-blockchain-development" />
                </picture>
                <h3>
                  How to Create a Private Blockchain For Intensify Business
                  Security?
                </h3>
                <p>
                  With blockchain dominating the entire digital ecosystem, and
                  finance
                  and technology taking over the world...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/how-to-create-a-private-blockchain'
                  ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/crypto-launchpad-development-steps.webp
                    " />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/crypto-launchpad-development-steps.jpg
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/crypto-launchpad-development-steps.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="crypto-launchpad-development-steps"
                    title="crypto-launchpad-development-steps" />
                </picture>
                <h3>
                  Crypto Launchpad Development: Steps to Take Ahead of
                  Developing a Launchpad
                </h3>
                <p>
                  Crypto launchpad development is a great opportunity to keep up
                  and stay one step ahead in this booming...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                  ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Launch Your AI Meme Coin
                Today!</span></h3>
            <p>Get in touch with our AI Meme Coin Development experts today to
              start your project’s journey.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer"
                  class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer"
                  class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->