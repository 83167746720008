<!-- Banner Section -->
<div class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                  <span><strong>Solana Blockchain </strong> <br> Development Services</span>
                </h1>
                <!-- <div class="common-sub-heading-alt pb-2">Solana Blockchain Development Services</div> -->
  
                <p>
                    Acing transaction speed and guarding vulnerability, our Solana Blockchain Development Service contributes towards developing an immersively safe, secure, and fast ecosystem to conveniently run through your operations.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a
                      href="https://t.me/sparkouttechblockchainx"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>Telegram</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                  <div class="content-link">
                    <a
                      href="https://www.blockchainx.tech/contact/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>Email Us</span>
                      <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 d-lg-block d-none">
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/solana-blockchain/metaverse-solana-sol.webp
                  " />

              <source
                type="image/png"
                srcset="
                    assets-new/images/solana-blockchain/metaverse-solana-sol.png
                  " />

              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/metaverse-solana-sol.png"
                class="img-fluid"
                alt="Solana Blockchain"
                title="Solana Blockchain Development Services"
                width="500"
                height="500" />
            </picture>
    
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/metaverse-solana-lt.webp" />
                <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/metaverse-solana-lt.png" />
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/metaverse-solana-lt.png"
                class="img-fluid"
                alt="Solana Blockchain"
                title="Solana Blockchain Development Services"
                width="500"
                height="500" />
            </picture>
          </div>
        </div>
  
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner Section end -->

  <!-- Why Choose Start -->
<section class="section-service-alt" #about>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 mx-auto col-12 order-md-first order-last">
          <div class="bep-work-section text-center">
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/solana-blockchain/blockchain-development-noteworthy.webp
                  " />

              <source
                type="image/png"
                srcset="
                    assets-new/images/solana-blockchain/blockchain-development-noteworthy.png
                  " />

              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/blockchain-development-noteworthy.png"
                class="img-fluid"
                alt="Solana Blockchain Development"
                title="What Makes Solana Blockchain Development Noteworthy?"
                width="500"
                height="500" />
            </picture>
    
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/solana-blockchain/blockchain-development-noteworthy-lt.webp
                  " />

              <source
                type="image/png"
                srcset="
                    assets-new/images/solana-blockchain/blockchain-development-noteworthy-lt.png
                  " />

              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/blockchain-development-noteworthy-lt.png"
                class="img-fluid"
                alt="Solana Blockchain Development"
                title="What Makes Solana Blockchain Development Noteworthy?"
                width="500"
                height="500" />
            </picture>
          </div>
        </div>
        <div class="col-lg-6 col-12 about-content">
          <div class="whys_section mb-0">
            <h2><span>What Makes Solana Blockchain </span> Development Noteworthy?</h2>
            <p class="pb-3">
                Through a simple establishment, Solana Blockchain has caught the interest of decentralised app developers making it more effective and efficient. By choosing to develop Solana Smart contract, Dapps benefited in multiple ways, while Transaction speed and security are prominent features. The Solana blockchain contract codes are written in RUST, a comparatively complicated programming language that itself makes the ecosystem more secure and challenges the outer world.
            </p>
            <p class="pb-3">
                Solana Blockchain development has its own innate trait concentrating on scalability and void congestion, making it revolutionary. With the capability to process 71,00,000 transactions per second at an average of 176 bytes, creating possibilities to engage a fast-growing global ecosystem with creative engaging features. Solana Blockchain solutions can grow and strengthen the decentralized financial system, and the network supports DeFi, NFTs, Web3, and more in the crypto space. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Why Choose End -->

  <!-- Benefits Of Tokenizing Real Estate Start -->
<section class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3><span>Solana Blockchain </span>Development Services At BlockchainX</h3>
          </div>
        </div>
      </div>
      <div class="row benefits-of-token">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/solana-blockchain-consultation.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/solana-blockchain-consultation.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/solana-blockchain-consultation.png"
                class="img-fluid"
                alt="Solana Blockchain Consultation icon"
                title="Solana Blockchain Consultation"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Solana Blockchain Consultation</h4>
            <p class="text-center">
                From the basics to innovating new strategies, our experts provide you with in-and-out explanations, detailing, and consultation for your Solana project. Providing technical and market support enhances your scalability.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/decentralized-app-development.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/decentralized-app-development.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/decentralized-app-development.png"
                class="img-fluid"
                alt="Decentralized App Development icon"
                title="Decentralized App Development"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Decentralized App Development</h4>
            <p class="text-center">
                Our Solana blockchain decentralized application development service is customized to meet your operational requirements in a way that stands extraordinary among the other Blockchain platforms with good ROI.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/solana-nft-marketplace.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/solana-nft-marketplace.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/solana-nft-marketplace.png"
                class="img-fluid"
                alt="Solana NFT Marketplace icon"
                title="Solana NFT Marketplace"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Solana NFT Marketplace</h4>
            <p class="text-center">
                Our expert team in Solana blockchain services provides ultimate solutions for your creatives, pulling traction, monetization, and adding value to the ecosystem with an NFT marketplace optimized to ease minting/mining, trading, and staking NFTs seamlessly.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/defi-development.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/defi-development.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/defi-development.png"
                class="img-fluid"
                alt="DeFi Development icon"
                title="DeFi Development"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">DeFi Development</h4>
            <p class="text-center">
                From P2P decentralized applications to DEXes and other payment gateways BlockchainX’s DeFi development is at service to build you a world-class premium platform easing our transaction anytime empowering the ecosystem.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/spl-token-development.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/spl-token-development.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/spl-token-development.png"
                class="img-fluid"
                alt="SPL Token Development icon"
                title="SPL Token Development"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">SPL Token Development </h4>
            <p class="text-center">
                The Solana program liberty (SPL) is a standard Solana token that we help you build in case of need, also assets listed here are SLP, securing your assets with the power of Solana in the DApps, decentralized exchanges.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/exchange-platform.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/exchange-platform.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/exchange-platform.png"
                class="img-fluid"
                alt="Exchange Platform icon"
                title="Exchange Platform"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Exchange Platform</h4>
            <p class="text-center">
                Serum is a unique protocol of Solana that is used to integrate Solana into your Exchanges or to create an Exchange platform that facilitates cross-chain swaps. This ensured to deliver optimum orders to the user app and ease of Exchange seamlessly. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/solana-wallet-development.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/solana-wallet-development.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/solana-wallet-development.png"
                class="img-fluid"
                alt="Solana Wallet Development icon"
                title="Solana Wallet Development"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Solana Wallet Development</h4>
            <p class="text-center">
                Solana blockchain development solutions also include creating wallets for web and mobile. This allows the users to conveniently make payments, securely handle funds and regulate complaints.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/development-and-maintenance.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/development-and-maintenance.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/development-and-maintenance.png"
                class="img-fluid"
                alt="Development and Maintenance icon"
                title="Development and Maintenance"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Development and Maintenance</h4>
            <p class="text-center">
                We contribute towards developing and building your Solana node and effectively maintain nodes in the Solana mainnet that make you the validator and contributor of the network, and earn SOL.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/solana-blockchain/solana-smart-contract-development-and-audit.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/solana-blockchain/solana-smart-contract-development-and-audit.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/solana-blockchain/solana-smart-contract-development-and-audit.png"
                class="img-fluid"
                alt="Solana Smart Contract Development"
                title="Solana Smart Contract Development and Audit"
                width="75"
                height="75" />
            </picture>
            <h4 class="erc-20-head">Solana Smart Contract Development and Audit</h4>
            <p class="text-center">
                Our Solana blockchain development solution ensures to provide smart contract development with our proficient developers mastering Rust and C+ programming and we extend complete auditing services that can effectively automate your operations.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Benefits Of Tokenizing Real Estate End -->

<!-- Asset Tokenization Vs.Token Sale Start -->
<section class="section-service-alt">
    <div class="container">
      <div class="orange-vector"></div>
      <div class="row justify-content-center mb-5">
        <div class="col-lg-10">
          <div class="metaverse_heading">
            <h3><span>Hire Our Dedicated Solana Development Team To Speed Up and Scale Your Operations! </span></h3>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="token-vs-btn">
            <a href="https://wa.me/7708889555" class="our_expert_talk"
              target="_blank"
              rel="noopener noreferrer">Connect With Whatsapp</a>
            <a href="https://www.blockchainx.tech/contact/"
              class="our_expert_talk">Talk With Our Experts</a>
          </div>
        </div>
      </div>
  
    </div>
  </section>
  <!-- Asset Tokenization Vs.Token Sale End -->

  <!-- Asset Tokenization Vs.Token Sale Start -->
<section class="section-service-main">
    <div class="container">
      <div class="orange-vector"></div>
      <div class="row justify-content-center mb-5">
        <div class="col-lg-10">
          <div class="metaverse_heading">
            <h3><span>Solana Blockchain Development Process</span></h3>
          </div>
        </div>
      </div>
      <div class="text-center">
        <picture *ngIf="!isDarkmode">
          <source
            type="image/webp"
            srcset="
            assets-new/images/solana-blockchain/solana-blockchain-development-process.webp
              " />

          <source
            type="image/png"
            srcset="
                assets-new/images/solana-blockchain/solana-blockchain-development-process.png
              " />

          <img loading="lazy"
            data-src="assets-new/images/solana-blockchain/solana-blockchain-development-process.png"
            class="img-fluid"
            alt="Solana Blockchain Development Process"
            title="Solana Blockchain Development Process"
            width="1200"
            height="600" />
        </picture>
        <picture *ngIf="isDarkmode">
          <source
            type="image/webp"
            srcset="
            assets-new/images/solana-blockchain/solana-blockchain-development-process-lt.webp
              " />

          <source
            type="image/png"
            srcset="
                assets-new/images/solana-blockchain/solana-blockchain-development-process-lt.png
              " />

          <img loading="lazy"
            data-src="assets-new/images/solana-blockchain/solana-blockchain-development-process-lt.png"
            class="img-fluid"
            alt="Solana Blockchain Development Process"
            title="Solana Blockchain Development Process"
            width="1200"
            height="600" />
        </picture>
      </div>
    </div>
  </section>
  <!-- Asset Tokenization Vs.Token Sale End -->

    <!-- Asset Tokenization Vs.Token Sale Start -->
<section class="section-service-alt">
    <div class="container">
      <div class="orange-vector"></div>
      <div class="row justify-content-center mb-5">
        <div class="col-lg-10">
          <div class="metaverse_heading">
            <h3><span>Tech Stack, We Use In Solana </span>Blockchain Development Solution</h3>
          </div>
        </div>
      </div>
      <div>
        <ul class="tech-list">
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/aave-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Aave Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/algorand.svg" alt="Algorand Development" width="75" height="75" class="img-fluid">
                <h3>Algorand Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/avalanche-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Avalanche Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/solana-nft-marketplace.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Solana NFT  Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/blockchain-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Blockchain Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/bsc-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>BSC Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/nft-marketplace-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>NFT  Marketplace Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/eos-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>EOS Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/solidity-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Solidity Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/hyperledger-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Hyperledger Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/cardano-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Cardano Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/enterprise-blockchain-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Enterprise Blockchain Development </h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/neo-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Neo Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/polkadot-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Polkadot Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/polygon-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Polygon Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/solana-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Solana Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/stellar-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Stellar Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/tezos-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Tezos Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/corda-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Corda Development</h3>
            </li>
            <li class="tech-item">
                <img src="assets-new/images/solana-blockchain/crypto-exchange-development.svg" alt="Aave
                Development" width="75" height="75" class="img-fluid">
                <h3>Crypto Exchange Development</h3>
            </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- Asset Tokenization Vs.Token Sale End -->

  <!-- Related Services -->
<section class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="metaverse_heading text-center">
            <h3 class="mb-3"><span>Solana Blockchain </span>Development Benefits</h3>
            <!-- <p>
              We curated crypto tokens with the utmost efficient value added
              functions that empower and improve business operations in the
              blockchain network.
            </p> -->
          </div>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/solana-blockchain/improved-scalability.webp">
                <source type="image/png"
                  srcset="assets-new/images/solana-blockchain/improved-scalability.png">
                <img loading="lazy"
                  data-src="assets-new/images/solana-blockchain/improved-scalability.png"
                  width="75" height="75" alt="Improved Scalability" title="Improved Scalability"
                  class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Improved Scalability</h4>
            <p>
                Innate scalability properties of Solana blockchain makes it ideal for Dapp and DAO development.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/solana-blockchain/cost-efficiency.webp">
                <source type="image/png"
                  srcset="assets-new/images/solana-blockchain/cost-efficiency.png">
                <img loading="lazy"
                  data-src="assets-new/images/solana-blockchain/cost-efficiency.png"
                  width="75" height="75" alt="Cost Efficiency" title="Cost Efficiency"
                  class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Cost Efficiency
            </h4>
            <p>
                Nominal costs involved in solana blockchain, make it the affordable choice for developing an ecosystem.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/solana-blockchain/improved-speed.webp">
                <source type="image/png"
                  srcset="assets-new/images/solana-blockchain/improved-speed.png">
                <img loading="lazy"
                  data-src="assets-new/images/solana-blockchain/improved-speed.png"
                  width="75" height="75" alt="Improved Speed" title="Improved Speed"
                  class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Improved Speed</h4>
            <p>
                With the ability to process 50,000 tps, the network is an effective choice for DAO, Defi, DEX development.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/solana-blockchain/security-and-reliability.webp">
                <source type="image/png"
                  srcset="assets-new/images/solana-blockchain/security-and-reliability.png">
                <img loading="lazy"
                  data-src="assets-new/images/solana-blockchain/security-and-reliability.png"
                  width="75" height="75" alt="Security And Reliability" title="Security And Reliability"
                  class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Security And Reliability</h4>
            <p>
                The ecosystem does not compromise on security terms, that takes accountability and makes it more reliable. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/solana-blockchain/efficient-ecosystem-integration.webp">
                <source type="image/png"
                  srcset="assets-new/images/solana-blockchain/efficient-ecosystem-integration.png">
                <img loading="lazy"
                  data-src="assets-new/images/solana-blockchain/efficient-ecosystem-integration.png"
                  width="75" height="75" alt="Efficient Ecosystem Integration" title="Efficient Ecosystem Integration"
                  class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Efficient Ecosystem Integration</h4>
            <p>
                The cost of gas involved is less and highly eco friendly network, that is best for building an efficient interactive platform. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/solana-blockchain/developer-friendly-platforms.webp">
                <source type="image/png"
                  srcset="assets-new/images/solana-blockchain/developer-friendly-platforms.png">
                <img loading="lazy"
                  data-src="assets-new/images/solana-blockchain/developer-friendly-platforms.png"
                  width="75" height="75" alt="Developer-Friendly Platforms"
                  title="Developer-Friendly Platforms" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Developer-Friendly Platforms
            </h4>
            <p>
                Developing using Rust language, the developers find it easy and convenient to develop friendly platforms effortlessly.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/solana-blockchain/great-community-support.webp">
                  <source type="image/png"
                    srcset="assets-new/images/solana-blockchain/great-community-support.png">
                  <img loading="lazy"
                    data-src="assets-new/images/solana-blockchain/great-community-support.png"
                    width="75" height="75" alt="Great Community Support" title="Great Community Support"
                    class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Great Community Support</h4>
              <p>
                Solana with its ability to perform effectively, has a good community out there that is supportive and pro active.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/solana-blockchain/minimizing-network-congestion.webp">
                  <source type="image/png"
                    srcset="assets-new/images/solana-blockchain/minimizing-network-congestion.png">
                  <img loading="lazy"
                    data-src="assets-new/images/solana-blockchain/minimizing-network-congestion.png"
                    width="75" height="75" alt="Minimizing Network Congestion"
                    title="Minimizing Network Congestion" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">
                Minimizing Network Congestion
              </h4>
              <p>
                The ability to perform quick and accurate, avoids the need for congestion in the network, and ensures a smooth flow. 
              </p>
            </div>
          </div>
      </div>
    </div>
  </section>
  <!-- Related Services -->

  <!-- Principles Start -->
<section class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5">
          <div class="c-animation">
            <div class="common-sub-heading">
              <h4>Choose us<span class="dots">.</span></h4>
            </div>
            <h3 class="main-sub-heading">
                Choose BlockchainX For Solana Blockchain Development
            </h3>
            <p>
                BlockchainX is a pioneer with 7 years of experience in Web3 and blockchain development, contributing towards many successful projects and actively supporting in the global spectrum. We strive to innovate and customize your requirements and contribute towards business and operation in almost every industry. Our expertise includes,
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="green-vector"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>SDKs and Frameworks</h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Our highly equipped developers provide support with designing Solana-specific SDKs, Frameworks, tools, command interfaces, programming models, etc through Rust.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <span>Read More</span>
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Deep Ecosystem Knowledge</h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                We develop tools and utilities for the web3 ecosystem utilizing Solana blockchain development, with our intricate blockchain knowledge.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <span>Read More</span>
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Vast Projects</h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                We take up projects worldwide and globally support operations through our robust, technologically upgraded, and creative solutions
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <span>Read More</span>
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Versatility in development</h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                We don't stick to one, but we explore, experiment, and learn through developing different solutions, from DeFo to NFT and metaverse, decentralized gaming, and more.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['dao-development-company']">
                  <span>Read More</span>
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Collaborations</h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Our collaborations are not restricted by time but by your needs. Based on your requirements and needs we can co-work and collaborate long and short term. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <span>Read More</span>
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- Principles End -->

<!-- Contact Start -->
<div class="app-get-in-touch-form section-service-main">
    <div class="container">
        <div class="contact-form">
            <div class="c-animation mb-0 mb-lg-3">
                <div class="common-sub-heading">
                  <h4>Choose us<span class="dots">.</span></h4>
                </div>
                <h3 class="main-sub-heading">
                    Ace your game with Solana Blockchain development
                </h3>
              </div>
            <div class="row">
              <div class="col-lg-6 col-md-12 contact-social-links">
                <!-- Calendly inline widget begin -->
                <div class="calendly-inline-widget" data-url="https://calendly.com/blockchainx-tech">
                  <iframe src="https://calendly.com/blockchainx-tech?embed_domain=www.calendly-embed.com&amp;embed_type=Inline" width="100%" height="100%" frameborder="0" title="Select a Date &amp; Time - Calendly"></iframe>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 contact-inputs">
                <div class="getintouch getintochform bitrix-snippet-form">
                  <!-- <div #crmForm></div> -->
                  <div [innerHTML]="bitrixSnippetScript"></div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
  <!-- Contact End -->

  <!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              Frequently Asked Question <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What is your approach toward upgrading Solana Blockchain Development?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Based on the requirement, we sit together to plan on the Solana blockchain development, work through it, and provide you with the needed support in upgrading your game efficiently.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    What’s the highlighting quality of Solana Blockchain Development services?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        In unveiling fast, quick, and secured transactions, we ensure the Solana Blockchain privacy and security base is locked tight and ensure seamless operation in our Solana blockchain development services.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    What is the cost of Solana Blockchain Development Solutions?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The cost of the Solana blockchain development solution would be based on the different features, additional advancements, and tech stacks based on your needs, and subsequently determining that cost will fluctuate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

  <!-- Blog Start -->
<section class="section-service-main">
    <app-blog></app-blog>
  </section>
  <!-- Blog End -->