<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Modular Blockchain</strong></span> Development
                Company
              </h1>
              <p>
                Multi-layered architecture enabling operations through
                distinguished specialization, Modular Blockchain development
                bidding off monolithic blockchain approach.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/launch-your-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-images/launch-your-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-images/launch-your-token.png"
                width="500" height="500" class="img-fluid"
                alt="Modular Blockchain Development Company"
                title="Modular Blockchain Development Company" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>What Is Modular Blockchain</span>
        Development?</h2>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Modular Blockchain Development"
              title="Modular Blockchain Development"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            An upgraded version of the monolithic blockchain model, Modular
            Blockchain development introduces the multi-layer architecture that
            divides each primary task into specialized functions where they are
            assigned in different layers. This is comparatively the most
            scalable, customizable, and efficient system without compromising on
            decentralization or security.
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Asset-based tokens have high liquidity
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Secured to
                amplify in the marriage.
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Decentralized solution with immutable ledger
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Maximized
                returns
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Modular Blockchain Projects"
              title="Modular Blockchain Projects" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular Modular
              Blockchain </span> Projects In The Market</h3>
          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span><a
                    href="https://blog.bake.io/what-is-arbitrum-arb-how-solve-ethereum-scalability/?trk=article-ssr-frontend-pulse_little-text-block"
                    target="_blank"
                    rel="noopener noreferrer">Arbitrum</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://blog.bake.io/optimism-layer-two-solution-ethereum-fundamental-values/?trk=article-ssr-frontend-pulse_little-text-block"
                    target="_blank"
                    rel="noopener noreferrer">Optimism</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://celestia.org/?ref=blog.bake.io&trk=article-ssr-frontend-pulse_little-text-block"
                    target="_blank"
                    rel="noopener noreferrer">Celestia</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://blog.bake.io/what-is-polkadot/?trk=article-ssr-frontend-pulse_little-text-block"
                    target="_blank"
                    rel="noopener noreferrer">Polkadot</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://blog.bake.io/what-is-cosmos/?trk=article-ssr-frontend-pulse_little-text-block"
                    target="_blank"
                    rel="noopener noreferrer">Cosmos</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://blog.bake.io/why-starknet-is-the-future-of-layer-2/?trk=article-ssr-frontend-pulse_little-text-block"
                    target="_blank"
                    rel="noopener noreferrer">Starknet</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Working Mechanism Of Modular </span>Blockchain
          Development</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Execution" title="Execution"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Execution</h4>
          <p>
            This involves processing the transactions in the system and ensuring
            everything is recorded.
          </p>
          <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Settlement" title="Settlement"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Settlement
          </h4>
          <p>
            The next is the settlement that secures the transaction’s
            destination and ensures it reaches there.
          </p>
          <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Consensus" title="Consensus"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Consensus</h4>
          <p>
            The consensus mechanism validates the transaction's authenticity in
            the process of Modular Blockchain
          </p>
          <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Asset-backed token Wallet"
                title="Asset-backed token Wallet" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Data Availability</h4>
          <p>
            Data availability is the storage space of transaction data allowing
            flexibility, and efficiency.
          </p>
          <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Choose BlockchainX For Your </span>Modular Blockchain Solution
          </h2>
          <p class="pb-3">
            BlockchainX is an experienced blockchain development company with a
            progressive market. We are keen on upgrading and implementing new
            techniques in the process. We kept learning and experimenting which
            gave a lot of opportunities to collaborate and innovate in the web3
            spaces. Our modular blockchain development services are also
            customized and knitted for you and your requirements. We cater to
            the utmost perfection needs of your project in a pitch-perfect
            manner.
          </p>
          <!-- <div class="whysection_text_wrap">
              <ul>
                <li>7plus years of experience in Web3 development </li>
                <li>50+ successful project </li>
                <li>Providing 1005 customizing </li>
                <li>Experienced developer with sheer knowledge.</li>
              </ul>
            </div> -->
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Modular Blockchain Solution"
              title="Modular Blockchain Solution" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is Modular blockchain development?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    A transformation from a monolithic approach to multiple
                    layered blockchain for each functionality is a Modular
                    Blockchain making the operations more convenient and
                    scalable.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What is the cost of developing a Modular blockchain?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The cost of developing your modular blockchain depends on
                    the customization and advanced functionalities you require.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  What are the services you provide with
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    We at Blockchain develop customized blockchain solutions
                    with decentralized properties. From Token development, NFT
                    marketplace development, DeFi platforms, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="404"
                    height="246"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Scale your opportunities with new
                possibilities employing</span></h3>
            <p>Our Modular blockchain development services.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->