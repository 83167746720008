import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-real-estate-tokenization-guide',
  templateUrl: './real-estate-tokenization-guide.component.html',
  styleUrls: ['./real-estate-tokenization-guide.component.css']
})
export class RealEstateTokenizationGuideComponent implements OnInit {

  selectedLink:number;
  title =
    'Real Estate Tokenization - Guide To Fractional Ownership';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Real Estate Tokenization is making a huge mark in the blockchain world. With rapid growth and underlying opportunities, there are possible scales to amplify. Check our Real estate tokenization guide.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'real estate tokenization, real estate tokenization development, tokenized real estate, real estate tokenization platform',
    });
              

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Real Estate Tokenization - Guide To Fractional Ownership',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/real-estate-tokenization-guide/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Real Estate Tokenization is making a huge mark in the blockchain world. With rapid growth and underlying opportunities, there are possible scales to amplify. Check our Real estate tokenization guide.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/real-estate-tokenization-guide/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Real Estate Tokenization - Guide To Fractional Ownership',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Real Estate Tokenization is making a huge mark in the blockchain world. With rapid growth and underlying opportunities, there are possible scales to amplify. Check our Real estate tokenization guide.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/real-estate-tokenization-guide/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/real-estate-tokenization-guide.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
