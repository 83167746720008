<section class="section-service-main">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-10 mx-auto">
              <div class="whys_section mb-0 text-center">
                <h2 class="text-center pb-0 mb-3">
                  Client <span> Success Stories </span>
                </h2>
                <p>
                  Catch up on our client's success stories at BlockchainX. We have
                  worked with various clients and on various projects in blockchain
                  platform development. And here are some of the top projects in which
                  we were involved.
                </p>
              </div>
            </div>
          </div>

          <div class="sec-about" #about>
            <div class="violet-vector"></div>
            <div class="container">
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-first order-last">
                  <div>
                    <video
                      src="assets-new/video/blockchain-development-company.mp4"
                      controls
                      [autoplay]="false"
                      width="95%">
                      <track
                        kind="captions"
                        src="assets-new/video/blockchain-development-company.mp4"
                        srclang="en"
                        label="English" />
                    </video>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">DSFR</p>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">DSFR</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div>
                      <video
                        src="assets-new/video/blockchain-development-company.mp4"
                        controls
                        [autoplay]="false"
                        width="95%">
                        <track
                          kind="captions"
                          src="assets-new/video/blockchain-development-company.mp4"
                          srclang="en"
                          label="English" />
                      </video>
                    </div>
                  </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-first order-last">
                  <div>
                    <video
                      src="assets-new/video/blockchain-development-company.mp4"
                      controls
                      [autoplay]="false"
                      width="95%">
                      <track
                        kind="captions"
                        src="assets-new/video/blockchain-development-company.mp4"
                        srclang="en"
                        label="English" />
                    </video>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">DSFR</p>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">DSFR</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div>
                      <video
                        src="assets-new/video/blockchain-development-company.mp4"
                        controls
                        [autoplay]="false"
                        width="95%">
                        <track
                          kind="captions"
                          src="assets-new/video/blockchain-development-company.mp4"
                          srclang="en"
                          label="English" />
                      </video>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-12">
                    <div class="token-vs-btn text-center">
                        <a href="https://wa.me/7708889555" class="our_expert_talk d-inline-block"
                          target="_blank"
                          rel="noopener noreferrer">TASK WITH OUR EXPERTS</a>
                      </div>
                </div>  
              </div>
            </div>
          </div>
    </div>
</section>