import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-let-discuss',
  templateUrl: './let-discuss.component.html',
  styleUrls: ['./let-discuss.component.css'],
})
export class LetDiscussComponent implements OnInit {
  isDarkmode: Boolean=false;
  public path: string;

  constructor(
    private route: ActivatedRoute,
    private footerservice: FooterServiceService,) {}

  ngOnInit(): void {
    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.path = this.route.snapshot?.url[0]?.path;
    console.log(this.route.snapshot?.url[0]?.path);
  }
}
