import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-nft-marketplace-development-new',
  templateUrl: './nft-marketplace-development-new.component.html',
  styleUrls: ['./nft-marketplace-development-new.component.css'],
})
export class NftMarketplaceDevelopmentNewComponent implements OnInit {
  title = 'NFT Marketplace Development Company - BlockchainX';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'NFTs, today are a buzz in the market with their extended use cases in both, the digital and real world. With such hype in the market, row your business with our intricate NFT marketplace development service helping you ease growth and popularity. Connect now!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'nft marketplace development, nft marketplace development company, nft marketplace development services, nft marketplace development solutions, nft marketplace platform development',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'NFT Marketplace Development Company - BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/nft-marketplace-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'NFTs, today are a buzz in the market with their extended use cases in both, the digital and real world. With such hype in the market, row your business with our intricate NFT marketplace development service helping you ease growth and popularity. Connect now!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/nft-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/nft-marketplace-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'NFT Marketplace Development Company - BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'NFTs, today are a buzz in the market with their extended use cases in both, the digital and real world. With such hype in the market, row your business with our intricate NFT marketplace development service helping you ease growth and popularity. Connect now!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/nft-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/nft-marketplace-development/'
    );
  }
  marketplaceOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    dots: false,
    autoHeight: true,
    margin: 25,
    navText: ["<i class='bi bi-arrow-left'></i>","<i class='bi bi-arrow-right'></i>"],
    responsive: {
      0: {
        items: 1,
      },

      767: {
        items: 1,
      },

      1024: {
        items: 1,
      },

      1366: {
        items: 1,
      },
    },
    nav: true,
  };

  fieldOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    dots: false,
    autoHeight: true,
    margin: 25,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1024: {
        items: 3,
      },

      1366: {
        items: 3,
      },
    },
    nav: false,
  };

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };
}
