<section>
  <div class="section-bep-work">
    <div class="container">
      <div class="row">
        <h1>
          <span>TERMS AND CONDITIONS</span>
        </h1>
        <div class="col-12 about-content">
          <div class="com-sub-heading">
            <h4>Greetings from the team of BlockchainX</h4>
            <p>
              The following is a broad outline of the terms and conditions for
              the clients accessing to the information provided in the website
              of
              <strong>BlockchainX</strong> .
            </p>
            <p>
              We suggest you to read through these rules and regulations to
              ensure a clear understanding from either ends while signing for up
              for yet another amazing blockchain project together.
            </p>
            <p>
              <strong>BlockchainX</strong> Headquarters is located and operating
              from India.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>TERMINOLOGY</h4>
            <p>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements.
            </p>
            <p>
              ‘Client’, ‘You’ and ‘Your’ refers to you, the person who is
              accessing this website and willing to develop a platform in
              blockchain, while the terms ’The Company’, ‘Ourselves’, ‘We’,
              ‘Our’ and ‘Us’, refers to our Company, BlockchainX. ‘Party’,
              ‘Parties’, or ‘Us’, refers to both the client and the company. All
              terms stated here, refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalisation
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>COOKIES</h4>
            <p>
              We employ the use of cookies, like most other interactive
              websites. The use of cookies lets us retrieve the user’s details
              for each visit and enable the functionality of certain areas to
              make it easier for people visiting our website. Some of our
              affiliate/advertising partners may also use cookies. By accessing
              BlockchainX’s website, you have hereby agreed to use cookies in
              agreement with the BlockchainX’s privacy policy.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>LICENSE</h4>
            <p>
              BlockchainX and/or its licensors own the intellectual property
              rights for all material on BlockchainX website. All intellectual
              property rights are reserved. You may access this from
              www.blockchainx.tech.com for your personal use, subjected to
              restrictions set in these terms and conditions.
            </p>
            <p>
              <strong>You may not</strong>
            </p>
            <p>1. Republish material from www.blockchainx.tech.com .</p>
            <p>
              2. Sell, rent or sub-license material from
              www.blockchainx.tech.com .
            </p>
            <p>
              3. Reproduce, duplicate or copy material from
              www.blockchainx.tech.com .
            </p>
            <p>4. Redistribute content from www.blockchainx.tech.com .</p>
          </div>

          <div class="com-sub-heading">
            <h4>REMARKS AND COMMENTS</h4>
            <p>
              For the convenience of the Clients, parts of this website offers
              an opportunity for users to post and exchange opinions and
              information in certain areas of the website. BlockchainX does not
              filter, edit, publish or review such comments posted prior to
              their presence on the website. Hence, comments do not reflect the
              views or opinions of BlockchainX, its agents and/or affiliates.
              Comments reflect the views and opinions of the person who post
              them. To the extent permitted by applicable laws BlockchainX shall
              not be liable for the comments or for any liability, damages or
              expenses caused and/or suffered as a result of any use of and/or
              posting of and/or appearance of the comments that appear on this
              website.
            </p>
            <p>
              BlockchainX reserves all rights to monitor the comments and remove
              any comment which can be considered inappropriate, offensive or
              cause breach of these Terms and Conditions.
            </p>
            <p>
              <strong>You warrant and represent that:</strong>
            </p>
            <p>
              1. You are entitled to post comments on our website, provided you
              have all necessary licenses and consents to do so.
            </p>
            <p>
              2. The comments may not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party.
            </p>
            <p>
              3. The comments may not contain any defamatory, libellous,
              offensive, indecent or otherwise unlawful material which is an
              invasion of privacy.
            </p>
            <p>
              4. The comments may not be used to solicit or promote business or
              custom or present commercial unlawful activities.
            </p>

            <p>
              You hereby grant BlockchainX a non-exclusive license to use,
              reproduce, edit and authorise others to use, reproduce and edit
              any of your comments in any and all forms, formats or media.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>HYPERLINKS</h4>
            <p class="">
              <strong
                >The following organisations may link to our Website without
                prior written approval</strong
              >
            </p>
            <p>1. Government agencies.</p>
            <p>2. Search engines.</p>
            <p>3. News organisations,</p>
            <p>
              4. Online directory distributors may link to our Website in the
              same manner as they hyperlink to the Websites of other listed
              businesses.
            </p>
            <p>
              5. System wide Accredited Businesses except soliciting non-profit
              organisations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </p>

            <p>
              These organisations may link to our home page, publications or
              other information so long as the link: (a) is not in any way
              deceptive; (b) does not falsely imply sponsorship, endorsement or
              approval of the linking party and its products and/or services;
              and (c) fits within the context of the linking party’s site.
            </p>
            <p>
              <strong
                >We may consider and approve other link requests from the
                following types of organisations
              </strong>
            </p>
            <p>
              1. Commonly known consumer and/or business information sources.
            </p>
            <p>2. dot.com community sites.</p>
            <p>3. Associations or other groups representing charities.</p>
            <p>4. Online directory distributors</p>
            <p>5. Internet portals.</p>
            <p>6. Accounting, law and consulting firms.</p>
            <p>7. Educational institutions and trade associations.</p>

            <p>
              We will approve link requests from these organisations if we
              decide that: (a) the link would not make us look unfavourably to
              ourselves or to our accredited businesses; (b) the organisation
              does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of
              BlockchainX and (d) the link is in the context of general resource
              information.
            </p>
            <p>
              If you are one of the organisations listed in paragraph 2 above
              and are interested in linking to our website, you can write an
              e-mail to us at contact@blockchainx.tech.com . Please include your
              name, your organisation name, contact information as well as the
              URL of your site, a list of any URLs from which you intend to link
              to our Website, and a list of the URLs on our site to which you
              would like to link. You will receive a reply from our end within a
              period of 21 days from the date of enquiry. Approved organisations
              may hyperlink to our Website as follows
            </p>

            <p>1. By use of our corporate name.</p>
            <p>2. By use of the uniform resource locator being linked to.</p>
            <p>
              3. By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party’s site.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>LOGO</h4>
            <p class="">
              No use of BlockchainX’s logo or other artwork will be allowed for
              linking without a trademark license agreement.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>I FRAMES</h4>
            <p>
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our Website.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>CONTENT LIABILITY</h4>
            <p>
              We shall not be held responsible for any content that appears on
              your We shall not be held responsible for any content that appears
              on your Website. You agree to protect and defend us against all
              claims that is rising on your Website. No link(s) should appear on
              any website that may be interpreted as libellous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>RESERVATION OF RIGHTS</h4>
            <p>
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amend these terms and conditions and it’s linking policy at any
              time.
            </p>
            <p>
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly. We do not ensure that the
              information on this website is correct, we do not warrant its
              completeness or accuracy; nor do we promise to ensure that the
              website remains available or that the material on the website is
              kept up to date.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>DISCLAIMER</h4>
            <p>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website.
            </p>
            <p>
              <strong>Nothing in this disclaimer will</strong>
            </p>

            <p>
              1. Limit or exclude our or your liability for death or personal
              injury.
            </p>
            <p>
              2. Limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </p>
            <p>
              3. Limit any of our or your liabilities in any way that is not
              permitted under applicable law.
            </p>
            <p>
              4. Exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </p>

            <p>
              The limitations and prohibitions of liability set in this section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph, (b) govern all The limitations and prohibitions of
              liability set in this section and elsewhere in this disclaimer:
              (a) are subject to the preceding paragraph, (b) govern all
              liabilities arising under the disclaimer, including liabilities
              arising in contract, in tort and for breach of statutory duty.
            </p>
            <p>
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
