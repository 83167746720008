import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-mica-regulation',
  templateUrl: './mica-regulation.component.html',
  styleUrls: ['./mica-regulation.component.css']
})
export class MicaRegulationComponent implements OnInit {

  title =
  'EU MiCA Regulations Sets Global Standards for Crypto';
constructor(
  public router: Router,
  private titleService: Title,
  private meta: Meta,
  private canonicalService: CanonicalService,
  @Inject(DOCUMENT) private doc: Document
) {}
ngOnInit(): void {
  this.titleService.setTitle(this.title);
  this.meta.updateTag({
    name: 'description',
    content:
      "EU MiCA Regulations have a clear-cut impact on global Crypto regulations, let break down its major approach toward the market.",
  });
  this.meta.updateTag({
    name: 'keywords',
    content: "mica regulation,eu mica,mica eu regulation,mica regulation summary,eu mica regulation,crypto mica,Crypto mica regulation",
  });
  this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
  this.meta.updateTag({ name: 'robots', content: 'index, follow' });

  this.meta.updateTag({
    property: 'og:title',
    content:
      'EU MiCA Regulations Sets Global Standards for Crypto',
  });
  this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
  this.meta.updateTag({
    property: 'og:url',
    content: 'https://www.blockchainx.tech/eu-mica-regulations-unfolds-direction-segway/',
  });
  this.meta.updateTag({
    property: 'og:description',
    content:
      "EU MiCA Regulations have a clear-cut impact on global Crypto regulations, let break down its major approach toward the market.",
  });
  this.meta.updateTag({ property: 'og:type', content: 'Website' });
  this.meta.updateTag({
    property: 'og:image',
    content:
      'https://blockchainx.tech/assets-new/images/news-details/eu-mica/eu-mica-regulations.png',
  });

  this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
  this.meta.updateTag({
    name: 'twitter:site',
    content: 'https://www.blockchainx.tech/eu-mica-regulations-unfolds-direction-segway/',
  });
  this.meta.updateTag({
    name: 'twitter:title',
    content:
      'EU MiCA Regulations Sets Global Standards for Crypto',
  });
  this.meta.updateTag({
    name: 'twitter:description',
    content:
      "EU MiCA Regulations have a clear-cut impact on global Crypto regulations, let break down its major approach toward the market.",
  });
  this.meta.updateTag({
    name: 'twitter:image',
    content:
      'https://blockchainx.tech/assets-new/images/news-details/eu-mica/eu-mica-regulations.png',
  });

  // Add Canonical tag
  this.canonicalService.setCanonicalURL(
    'https://www.blockchainx.tech/eu-mica-regulations-unfolds-direction-segway/'
  );
  this.createLink();
}
createLink() {
  this.canonicalService.destroyLinkForAmpURL();
  let link: HTMLLinkElement = this.doc.createElement('link');
  link.setAttribute('rel', 'amphtml');
  link.setAttribute(
    'href',
    'https://www.blockchainx.tech/amp/eu-mica-regulations-unfolds-direction-segway.html'
  );
  this.doc.head.appendChild(link);
}

}
