<div class="thank-section">
    <div class="container">
      <div class="row">
        <div class="caption text-center">
          <h1>
            <span><strong>Thank you for reaching out to us</strong></span>
          </h1>
          <p>
            We have received your inquiry and one of our team members will get back to you within 1 business day.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="caption">
            <span class="tick">&#x2713;</span>
            <p class="text-center connect-para">
                If you need immediate assistance, you can connect with us on:
            </p>
        </div>
        <div class="erc-link d-flex flex-column flex-sm-row">
            <div class="d-flex pt-3">
                <a href="https://wa.me/7708889555" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                  <span>CONNECT ON WHATSAPP</span>
                </a>
              </div>  
            <div class="d-flex pt-3">
              <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                <span>CONNECT ON TELEGRAM</span>
              </a>
            </div>
        </div>
      </div>
    </div>
</div>