<div class="case-inner-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 col-12">
        <div class="case-main-banner">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/case/ekta-banner.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/case/ekta-banner.png" />

            <img loading="lazy"
              data-src="assets-new/images/case/ekta-banner.png"
              class="img-fluid"
              width="840"
              height="504"
              alt="Ekta"
              title="Ekta" />
          </picture>
        </div>
        <div class="case-study-content">
          <h4>Blockchain Ecosystem</h4>
          <div class="metaverse_subheading">
            <h3>EKTA Marketplace is here to comprehend real world fantasy</h3>
          </div>
          <div class="second-head">
            <div class="metaverse_subheading">
              <h3>Ekta Marketplace - Scratch to Success</h3>
            </div>
          </div>
          <div class="case-study-main-content">
            <p>
              Our partnership with the EKTA team was centred on envisioning
              their desire and making it a reality. We contributed to the
              development of the EKTA chain's mainnet with EVM (Ethereum virtual
              machine) compatibility. It is also capable of bridging with other
              EVM-based blockchains, such as BSC.
            </p>

            <h4>Business Goal</h4>
            <div class="metaverse_subheading">
              <h3>The Ultimate Goal Of Ekta Marketplace</h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/ekta-1.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/ekta-1.png" />

                <img loading="lazy"
                  data-src="assets-new/images/case/ekta-1.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="Ekta"
                  title="Ekta" />
              </picture>
              <div class="green-vector"></div>
            </div>

            <p>
              When talking about the goal of the NFT marketplace of Ekta, it's
              pretty much going to be the expectation of the Ekta team. The team
              aims at the world of blockchain. Unlike specified in one genre, or
              explore in one stream of decentralization, Ekta aims to facilitate
              its users the utmost opportunity to excel and streamline income in
              the row.
            </p>

            <div class="metaverse_subheading">
              <h3>Intricacies Present In Developing Ekta</h3>
            </div>
            <p>
              The idea of Ekta itself had socio-economic implications to deal
              with. The concept of decentralizing physical world aspects is a
              challenge with no limitations. The main attribute expected in Ekta
              marketplace was, the NFt minted outside the marketplace in Ekta
              chain to be reflected in the wallet and the profile of the
              marketplace which made it on par. The economical status of the
              user has a major role to play. The poverty range is widening day
              by day, and policymakers play a key role here. The accessibility
              of financial opportunities is restricted to a few. Democracy is
              never balanced, as most of the biggest opportunities to gain
              financial freedom are stuck in the hands of wealthy minorities.
              The heightening climatic changes are a topic for discussion, as we
              consider and still keep exploiting natural resources if they are
              inexhaustible. The ownership of property is also essential at the
              time of transfer. Its un-streamlined policies still stop many from
              owning their own piece of land.
            </p>
            <h4>The Solution</h4>
            <div class="metaverse_subheading">
              <h3>Ekta Marketplace Is Here To Comprehend Real World Fantasy</h3>
            </div>
            <div class="case-inner-image second-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/ekta-2.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/ekta-2.png" />

                <img loading="lazy"
                  data-src="assets-new/images/case/ekta-2.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="Ekta"
                  title="Ekta" />
              </picture>
              <div class="orange-vector"></div>
            </div>
            <p>
              Like stumbling with multiple problems approaching in multiple
              directions, so the solution has multiple other ways to get rid of.
              Moreover, solving every problem is a never-ending story, but
              giving opportunities to twirl as you wish is a real chance to
              explore. Ekta Marketplace is a solution that is more an
              opportunity provider than an ecosystem, allowing users to flaunt
              their fantasy of living in the fancy world of Decentralization
              networks.
            </p>
            <div class="metaverse_subheading">
              <h3>Detailing The Workflow Of Ekta NFT Marketplace</h3>
            </div>
            <p class="pb-0">
              The home page of Ekta marketplace is a creative collage of
              features and functionalities that self-explains its presence and
              purpose. It has differentiated spaces to list, mint, and trade
              NFTs. Along with wallet options. The Ekta marketplace carries its
              transactions and functions forwards through the Ekta coin.
            </p>

            <p class="pb-0">
              Being created exclusively for creators and verified personalities
              to showcase their projects and facilitate seamless trade along
              with fundraising chances. The platform has a unique user interface
              to facilitate the needful. The user can list and trade their
              collectibles, purchase Ekta coins, and connect their wallet to
              ease transactions.
            </p>

            <p>
              The Admin dashboard of Ekta is truly a gallery that supports and
              facilitates the requirements.
            </p>
            <ul>
              <li>
                <strong> User Management </strong> - furnish user details
                including name, email id, wallet address, profile photo, and
                much more.
              </li>
              <li>
                <strong> Partner Management</strong> - similarly list details of
                the tied-up partner in the spaces with precise information.
              </li>
              <li>
                <strong> Collection management</strong> - gives individual
                details of the assets. Including name, symbol, URL, address,
                collection image, Action, and more. The Action button takes the
                admin through a complete go-through on the details of the
                collection, its sale, and resale, type of sales, and more.
              </li>
              <li>
                <strong> Settings </strong> - manages the revenue wallet,
                multiple login management, blacklist wallets that don't make any
                transaction all that based out of.
              </li>
              <li>
                <strong> Features </strong> - includes information on the
                collections for users to check in and get a brief.
              </li>
              <li>
                <strong> Banners </strong> - these help in dynamic platform
                management allowing the admin to manage current highlights
                easily.
              </li>
              <li>
                <strong> Category</strong> - this helps in channelizing the
                assets based on their purpose. Categories include games, real
                estate, etc.
              </li>
              <li>
                <strong> NFT registry</strong> - one main part that is accessed
                through a registry contract that facilitates the fee setting per
                collection.
              </li>
              <li>
                <strong> Private registry</strong> - more like a launchpad that
                creates collections without NFT and steps out for pre-sale to
                mint a particular number of NFTs for a particular amount.
              </li>
              <li>
                <strong> Learning </strong> - in built a blogging space to
                create content for collections with titles, images, and others.
                Coming to the Partners dashboard of Ekta marketplace, the login
                is done directly with a meta-mask wallet address and there are
                no such things as user name, password, etc. once the partner
                enters their wallet address their particular dashboard opens
                with the following.
              </li>
              <li>
                <strong> Collection management </strong> - the collection
                created under this wallet is listed here. The user can create
                ERC721 and ERC1155 token standards. The information furnished
                goes to the database, while few are extracted for contract and
                IPFS.
              </li>
              <li>
                <strong> NFT management</strong> - NFTs created under specific
                collections are listed here along with their sales status,
                actions, status, sales type, etc. The approval to sell the
                minted NFTs in the platform is got beforehand from the admin.
                And then it proceeds to the sale for the user to choose its sale
                type among fixed, bid, and offer.
              </li>
              <li>
                <strong> Private Sale </strong> - this space furnishes details
                on private sales for fundraising projects through the
                collection. Here the NFTs are whitelisted to only the particular
                people.
              </li>
              <li>
                <strong> Bulk Mint </strong> - in order to create and send the
                promised NFTs to the buyer in the funding process, the time is
                minimized with this. It has two purposes here: Create mint and
                List mint.
              </li>
            </ul>
            <p class="pb-0">
              The users who show interest in the collections can make their
              claim in the presale that will be open. And the NFTs received in
              the wallet in return can be traded back. Apart from that the
              collection can also create and sell other NFTs. everything is
              listed and showcased on the home page of the Ekta marketplace.
              Based on the sale type, the list of attributes, its features,
              description, and everything will be detailed. And the buyer can
              make transfers accordingly.
            </p>

            <p>
              There are few third-party integrations in the marketplace
              enhancing the best for transactions. The users can seamlessly act
              upon the platform with their credit card and debit card
              transaction, centralized exchanges, and also decentralized
              exchanges. With successful transactions, the buyer profile
              projects a detailed and classified profile of their purchases. The
              details on every NFT and its price graphs are all clearly
              showcased in the details of the NFT that accounts for everything.
            </p>
            <div class="metaverse_subheading">
              <h3>Design Screens</h3>
            </div>
            <div class="case-inner-image third-image" id="alt-inner-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/ekta-3.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/ekta-3.png" />

                <img loading="lazy"
                  data-src="assets-new/images/case/ekta-3.png"
                  class="img-fluid"
                  width="840"
                  height="645"
                  alt="Ekta"
                  title="Ekta" />
              </picture>
              <div class="violet-vector"></div>
            </div>
            <h4>Unique Features</h4>
            <div class="metaverse_subheading">
              <h3>Extended Abilities Of Ekta Marketplace Like Nowhere</h3>
            </div>
            <div class="case-inner-image third-image" id="alt-inner-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/ekta-4.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/ekta-4.png" />

                <img loading="lazy"
                  data-src="assets-new/images/case/ekta-4.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="Ekta"
                  title="Ekta" />
              </picture>
            </div>
            <p class="pt-0">
              The Ekta marketplace is one of a kind, and being developed from
              scratch, we had the liberty to put different attributes on its
              functionalities to test if things work out or not. From there we
              have brought in the listed features and functions that were
              demanded and dedicated to this one exclusive platform.
            </p>
            <div class="row">
              <div class="col-lg-5 tec-list">
                <ul>
                  <li>Bulk Minting</li>
                  <li>Collections Sale</li>
                  <li>Extempore database</li>
                </ul>
              </div>
              <div class="col-lg-2"></div>
              <div class="col-lg-5 tec-list-alt">
                <ul>
                  <li>Partnership dashboard</li>
                  <li>Batch mint</li>
                  <li>Wallet based log-in</li>
                </ul>
              </div>
            </div>
            <div class="tech-stak">
              <h4>Tech Stacks</h4>
              <div class="metaverse_subheading">
                <h3>
                  A great app needs the right tools and technology to perform
                </h3>
              </div>
              <div class="row mt-4">
                <div class="col-lg-3 col-md-6 col-6 pb-lg-0 pb-sm-5 pb-4 text-center">
                  <h3>Front End</h3>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/case/angular.webp">
                      <source type="image/png"
                        srcset="assets-new/images/case/angular.png">
                      <img loading="lazy"
                        data-src="assets-new/images/case/angular.svg" width="70"
                        height="70" alt="Angular" title="Angular"
                        class="img-fluid" />
                    </picture>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pb-lg-0 pb-sm-5 pb-4 text-center">
                  <h3 class="">Back End</h3>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/case/nodejs.webp">
                      <source type="image/png"
                        srcset="assets-new/images/case/nodejs.png">
                      <img loading="lazy"
                        data-src="assets-new/images/case/nodejs.svg" width="70"
                        height="70" alt="Node js" title="Node js"
                        class="img-fluid" />
                    </picture>
                </div>
                <div class="col-lg-3 col-md-6 col-6 text-center">
                  <h3 class="text-center">Smart Contracts</h3>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/case/solidity-2.webp">
                      <source type="image/png"
                        srcset="assets-new/images/case/solidity-2.png">
                      <img loading="lazy"
                        data-src="assets-new/images/case/solidity-2.svg"
                        width="70" height="70" alt="solidity" title="solidity"
                        class="img-fluid" />
                    </picture>
                </div>
                <div class="col-lg-3 col-md-6 col-6 text-center">
                  <h3 class="text-center">Smart Contracts</h3>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/case/mongodb.webp">
                      <source type="image/png"
                        srcset="assets-new/images/case/mongodb.png">
                      <img loading="lazy"
                        data-src="assets-new/images/case/mongodb.svg" width="70"
                        height="70" alt="solidity" title="mongodb"
                        class="img-fluid" />
                    </picture>
                </div>
              </div>
            </div>
            <h4>Results</h4>
            <div class="metaverse_subheading">
              <h3>Ekta Marketplace Explore Beyond And Further</h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/ekta-5.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/ekta-5.png" />

                <img loading="lazy"
                  data-src="assets-new/images/case/ekta-5.png"
                  class="img-fluid"
                  width="840"
                  height="505"
                  alt="Ekta"
                  title="Ekta" />
              </picture>
              <div class="green-vector"></div>
            </div>
            <p class="pb-0">
              The idea behind Ekta Marketplace's development was to create a
              canvas for a progressive decentralized ecosystem. Where users can
              enter and exploit the advantages of blockchain networks as they
              trade, collect, stake NFTs, and perform activities that are
              similar to real-world capabilities.
            </p>

            <p class="pb-0">
              As the EKTA token plays the administrative role here, every
              transaction in and out is carried through it. The ERC20 standard
              tokens are compatible and the environment is bridgeable at the
              same time, in addition to better operability. Apart from this, the
              NFT marketplace of Ekta is also open for the wider creative
              economy to expose their creatives and also raise funds via
              collection listing.
            </p>

            <p>
              The platform is also open for users to connect with any of their
              web3 wallets and participate in the wide range of protocols
              available. What more? Ekta Marketplace lays a path for us to
              explore the future with pro-created earning avenues.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="company-detail">
          <div class="detail-section-one">
            <h4>Company</h4>
            <h3 class="pb-0">Ekta</h3>
          </div>
          <div class="detail-section-two">
            <h4>Team Members</h4>
            <ul>
              <li>Berwin Tanco</li>
              <li>Yog Shrusti</li>
              <li>Jason Zheng</li>
            </ul>
          </div>
          <div class="detail-section-two">
            <h4>Website</h4>
            <h3 class="pb-0">www.ekta.io</h3>
          </div>
          <div class="detail-section-two">
            <h4>Country</h4>
            <h3 class="pb-0">BLAY</h3>
          </div>
          <div class="detail-section-two">
            <h4>Business Type</h4>
            <h3 class="pb-0">Layer1 Blockchain, NFT Marketplace Development</h3>
          </div>
          <div class="detail-section-three">
            <h4>Follow us on</h4>
            <h3>Blockchain Ecosystem</h3>
            <ul class="d-flex align-items-center gap-4">
              <li>
                <a
                  href="https://www.ekta.io/"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="global"
                  aria-label="global"><i class="bi bi-globe"></i></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ektachain/"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="instagram"
                  aria-label="Instagram"><i class="bi bi-instagram"></i></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/EktaChain"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="Twitter"
                  aria-label="twitter">
                  <i class="bi bi-twitter-x"></i>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="section-service-alt-main">
    <app-contactform></app-contactform>
  </section>
