<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>The Next Big Cat Coin</strong></span> Community
                Could Be Yours
              </h1>
              <p>
                Cat-Theme Coins are the next big thing in crypto after the
                success of popular memecoins like Shiba, Pepe, and Slerf. Launch
                your own cat-themed coin through our expert team, including
                secure and robust smart contracts.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/launch-your-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-images/launch-your-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-images/launch-your-token.png"
                width="500" height="500" class="img-fluid" alt="Cat coin"
                title="Cat coin" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Why should you consider Cat-Themed</span>
        Coin Development?</h2>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Cat-Themed Coin Development"
              title="Cat-Themed Coin Development"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Cat memes are popular with crypto users because it's a niche that
            cat lovers can relate to. There is a large community of cat lovers
            that you can funnel into your project’s community. Our cat-themed
            coin development services allows you to capitalize on this trending
            team with an expert team of developers and consultants to bring real
            utility to your memecoin project.
          </p>
        </div>

        <!-- <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Premium security
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Undeniable security
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Improved scalability 
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Interoperable ability
                </li>
              </ul>
            </div>
          </div> -->
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405"
              alt="Popular Projects of cat-themed coins"
              title="Popular Projects of cat-themed coins"
              class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular Projects of
            </span> cat-themed coins</h3>
          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span><a
                    href="https://www.coingecko.com/en/coins/popcat"
                    target="_blank"
                    rel="noopener noreferrer">Popcat</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/mog-coin"
                    target="_blank"
                    rel="noopener noreferrer">Mog Coin</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/wen-solana"
                    target="_blank"
                    rel="noopener noreferrer">Wen</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/toshi"
                    target="_blank"
                    rel="noopener noreferrer">Toshi</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/catcoin"
                    target="_blank"
                    rel="noopener noreferrer">Catcoin</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/bobacat"
                    target="_blank"
                    rel="noopener noreferrer">BobaCat</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Various Token Services </span>We Provide Here</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Token Sale Platform"
                title="Token Sale Platform" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Sale Platform</h4>
          <p>
            Create a dedicated token sale page for your cat-themed coin and
            easily raise funds for your project’s marketing and development.
          </p>
          <div class="content-link">
            <a [routerLink]="['/ready-made-ico-software']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Decentralized Exchange"
                title="Decentralized Exchange" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            NFT Marketplace
          </h4>
          <p>
            Launch an NFT collection through your own branded marketplace and
            attract more active members to your community.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-marketplace-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Token Staking" title="Token Staking"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Staking</h4>
          <p>
            Launching Staking campaigns are a great way to incentivize community
            engagement and member participation.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-staking-platform-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="NFT Staking" title="NFT Staking "
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">NFT Staking </h4>
          <p>
            Staking through NFTs allows users to collect additional incentives
            in your project tokens and promotes NFT sales from your marketplace.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-staking-platform-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Cross-Chain Bridge"
                title="Cross-Chain Bridge" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Cross-Chain Bridge</h4>
          <p>
            Leverage user engagement from multiple blockchains by making your
            token cross-chain using our bridging solution.
          </p>
          <div class="content-link">
            <a [routerLink]="['/']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Decentralized Exchange"
                title="Decentralized Exchange" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Decentralized Exchange
          </h4>
          <p>
            Launching a Decentralized Exchange (DEX) platform is a great way to
            expand your roadmap and spread your revenue sources.
          </p>
          <div class="content-link">
            <a [routerLink]="['/cryptocurrency-exchange-software-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Why Choose BlockchainX For Cat-Themed Coin Development</span>
          </h2>
          <p class="pb-3">
            We are an experienced blockchain development company serving
            startups and enterprises building secure solutions. Our consultants
            and developers have the expertise you need to launch a
            technologically sound cat-themed coin project. Additionally, working
            with us allows you to leverage our network of partners to take your
            project to new heights and marketcaps.
          </p>
          <!-- <div class="whysection_text_wrap">
              <ul>
                <li>Customized Token development solutions</li>
                <li>100% audited and error-free codes</li>
                <li>24/7 support</li>
                <li>70plus successful blockchain projects</li>
              </ul>
            </div> -->
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Cat-Themed Coin Development"
              title="Cat-Themed Coin Development" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  How quickly can I launch a cat-themed coin?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    A cat-themed memecoin takes around 2-3 days to develop,
                    test, and launch on the Mainnet. But tokens with complex
                    smart contracts can take longer to develop.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  How much liquidity do I need to launch a cat-themed coin?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    You can get started with a few thousand dollars in
                    liquidity. Running a presale for your cat coin can help you
                    ease this process. You can connect with our expert
                    consultants to get in-depth guidance on the whole process.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  How can I improve the chances of success for my project?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The best way to improve your memecoins standing is to build
                    an ecosystem around your token and add utility to it.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  Do I need a large team to run my project?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    You can easily get started by hiring our team, this would be
                    much more cost effective than hiring resources on-shore.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  How secure will my cat-themed coin be?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    We follow industry best practices when programming smart
                    contracts to ensure that your cat-themed coins are secure
                    and gas-efficient.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="404"
                    height="246"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Launch Your Cat-Themed Coin With
                Us</span></h3>
            <p>Not sure how to get started with cat coin development? Connect
              with our expert consultant today and get started.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->