import { Directive, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { BlogMainComponent } from './blog-main/blog-main.component';
import { AdaptInvestMetaverseComponent } from './blog-single-detail/adapt-invest-metaverse/adapt-invest-metaverse.component';
import { AdvantagesOfNftComponent } from './blog-single-detail/advantages-of-nft/advantages-of-nft.component';
import { AiMeetMetaverseComponent } from './blog-single-detail/ai-meet-metaverse/ai-meet-metaverse.component';
import { AvoidMistakeMarketplaceComponent } from './blog-single-detail/avoid-mistake-marketplace/avoid-mistake-marketplace.component';
import { BestDefiPlatformsComponent } from './blog-single-detail/best-defi-platforms/best-defi-platforms.component';
import { BestMetaverseKnowAboutComponent } from './blog-single-detail/best-metaverse-know-about/best-metaverse-know-about.component';
import { BlockchainEmpowerWomenComponent } from './blog-single-detail/blockchain-empower-women/blockchain-empower-women.component';
import { BlockchainForGovernmentComponent } from './blog-single-detail/blockchain-for-government/blockchain-for-government.component';
import { BlockchainHealthcareComponent } from './blog-single-detail/blockchain-healthcare/blockchain-healthcare.component';
import { BlockchainIdentityManagementComponent } from './blog-single-detail/blockchain-identity-management/blockchain-identity-management.component';
import { BlockchainInfrastructureComponent } from './blog-single-detail/blockchain-infrastructure/blockchain-infrastructure.component';
import { BlockchainInnovationComponent } from './blog-single-detail/blockchain-innovation/blockchain-innovation.component';
import { BlockchainRustComponent } from './blog-single-detail/blockchain-rust/blockchain-rust.component';
import { BlockchainSolvesProblemsComponent } from './blog-single-detail/blockchain-solves-problems/blockchain-solves-problems.component';
import { BlockchainVsBitcoinComponent } from './blog-single-detail/blockchain-vs-bitcoin/blockchain-vs-bitcoin.component';
import { BlockchainVsDistributedLedgerComponent } from './blog-single-detail/blockchain-vs-distributed-ledger/blockchain-vs-distributed-ledger.component';
import { BlogSingleDetailComponent } from './blog-single-detail/blog-single-detail.component';
import { BuildMetaverseWorldComponent } from './blog-single-detail/build-metaverse-world/build-metaverse-world.component';
import { BuildVirtualWorldComponent } from './blog-single-detail/build-virtual-world/build-virtual-world.component';
import { CreateMetaversePlatformComponent } from './blog-single-detail/create-metaverse-platform/create-metaverse-platform.component';
import { CreateNftMarketplaceComponent } from './blog-single-detail/create-nft-marketplace/create-nft-marketplace.component';
import { DefiProjectsComponent } from './blog-single-detail/defi-projects/defi-projects.component';
import { DefiVsCefiComponent } from './blog-single-detail/defi-vs-cefi/defi-vs-cefi.component';
import { EnduringTechnologiesComponent } from './blog-single-detail/enduring-technologies/enduring-technologies.component';
import { Erc115Component } from './blog-single-detail/erc115/erc115.component';
import { EverythingAboutMetaverseComponent } from './blog-single-detail/everything-about-metaverse/everything-about-metaverse.component';
import { EverythingAboutNftMarketplaceComponent } from './blog-single-detail/everything-about-nft-marketplace/everything-about-nft-marketplace.component';
import { EverythingAboutSubstrateComponent } from './blog-single-detail/everything-about-substrate/everything-about-substrate.component';
import { EverythingAboutWeb3Component } from './blog-single-detail/everything-about-web3/everything-about-web3.component';
import { FiveBlockchainUseCaseComponent } from './blog-single-detail/five-blockchain-use-case/five-blockchain-use-case.component';
import { FlowBlockchainComponent } from './blog-single-detail/flow-blockchain/flow-blockchain.component';
import { FutureOfNftApplicationsComponent } from './blog-single-detail/future-of-nft-applications/future-of-nft-applications.component';
import { FuturesOfMetaverseComponent } from './blog-single-detail/futures-of-metaverse/futures-of-metaverse.component';
import { HashgraphVsBlockchainComponent } from './blog-single-detail/hashgraph-vs-blockchain/hashgraph-vs-blockchain.component';
import { HealthcareManagementComponent } from './blog-single-detail/healthcare-management/healthcare-management.component';
import { HowToCreateNftMarketplaceComponent } from './blog-single-detail/how-to-create-nft-marketplace/how-to-create-nft-marketplace.component';
import { HyperledgerFabricComponent } from './blog-single-detail/hyperledger-fabric/hyperledger-fabric.component';
import { KeyFeatureOfMetaverseComponent } from './blog-single-detail/key-feature-of-metaverse/key-feature-of-metaverse.component';
import { MetaverseBusinessComponent } from './blog-single-detail/metaverse-business/metaverse-business.component';
import { MetaverseInRealestateComponent } from './blog-single-detail/metaverse-in-realestate/metaverse-in-realestate.component';
import { MetaverseUseCaseBenefitsComponent } from './blog-single-detail/metaverse-use-case-benefits/metaverse-use-case-benefits.component';
import { MultifunctionalNftComponent } from './blog-single-detail/multifunctional-nft/multifunctional-nft.component';
import { MythsAboutBlockchainComponent } from './blog-single-detail/myths-about-blockchain/myths-about-blockchain.component';
import { NftAutionsComponent } from './blog-single-detail/nft-autions/nft-autions.component';
import { NftBrandsComponent } from './blog-single-detail/nft-brands/nft-brands.component';
import { NftCryptopunksComponent } from './blog-single-detail/nft-cryptopunks/nft-cryptopunks.component';
import { NftEverytingAboutNftMetaversesComponent } from './blog-single-detail/nft-everyting-about-nft-metaverses/nft-everyting-about-nft-metaverses.component';
import { NftGimmickComponent } from './blog-single-detail/nft-gimmick/nft-gimmick.component';
import { NftInsightsComponent } from './blog-single-detail/nft-insights/nft-insights.component';
import { NftMusicMarketplaceComponent } from './blog-single-detail/nft-music-marketplace/nft-music-marketplace.component';
import { NftRoleInMetaverseComponent } from './blog-single-detail/nft-role-in-metaverse/nft-role-in-metaverse.component';
import { NftRoyaltiesComponent } from './blog-single-detail/nft-royalties/nft-royalties.component';
import { NftSecurityTipsComponent } from './blog-single-detail/nft-security-tips/nft-security-tips.component';
import { NftSportsComponent } from './blog-single-detail/nft-sports/nft-sports.component';
import { NftStakingComponent } from './blog-single-detail/nft-staking/nft-staking.component';
import { NftUsedInDefiComponent } from './blog-single-detail/nft-used-in-defi/nft-used-in-defi.component';
import { NftVulnerabilityComponent } from './blog-single-detail/nft-vulnerability/nft-vulnerability.component';
import { PhygitalNftComponent } from './blog-single-detail/phygital-nft/phygital-nft.component';
import { PolkadotParachainComponent } from './blog-single-detail/polkadot-parachain/polkadot-parachain.component';
import { PrimaryTechnologyInMetaverseComponent } from './blog-single-detail/primary-technology-in-metaverse/primary-technology-in-metaverse.component';
import { SolanaPolygonEthComponent } from './blog-single-detail/solana-polygon-eth/solana-polygon-eth.component';
import { SolanaProofComponent } from './blog-single-detail/solana-proof/solana-proof.component';
import { StaticVsDynamicNftComponent } from './blog-single-detail/static-vs-dynamic-nft/static-vs-dynamic-nft.component';
import { StellarConsensusProtocolComponent } from './blog-single-detail/stellar-consensus-protocol/stellar-consensus-protocol.component';
import { TopBlockchainPlatformsComponent } from './blog-single-detail/top-blockchain-platforms/top-blockchain-platforms.component';
import { TopMetaverseProjectsComponent } from './blog-single-detail/top-metaverse-projects/top-metaverse-projects.component';
import { TypesOfBlockchainComponent } from './blog-single-detail/types-of-blockchain/types-of-blockchain.component';
import { UseCasesOfDefiComponent } from './blog-single-detail/use-cases-of-defi/use-cases-of-defi.component';
import { WhatIsDaoComponent } from './blog-single-detail/what-is-dao/what-is-dao.component';
import { WhatIsDefiComponent } from './blog-single-detail/what-is-defi/what-is-defi.component';
import { WhatIsGamefiComponent } from './blog-single-detail/what-is-gamefi/what-is-gamefi.component';
import { WhatIsIsoComponent } from './blog-single-detail/what-is-iso/what-is-iso.component';
import { WhatIsPolkadotComponent } from './blog-single-detail/what-is-polkadot/what-is-polkadot.component';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { EktachainComponent } from './case-studies/ektachain/ektachain.component';
import { MinosisComponent } from './case-studies/minosis/minosis.component';
import { StarcardSportsGamesComponent } from './case-studies/starcard-sports-games/starcard-sports-games.component';
import { ZukiComponent } from './case-studies/zuki/zuki.component';
import { ContactComponent } from './contact/contact.component';
import { DappsDevelopmentComponent } from './enterprise/dapps-development/dapps-development.component';
import { DefiDevelopmentComponent } from './enterprise/defi-development/defi-development.component';
import { HashgraphDevelopmentComponent } from './enterprise/hashgraph-development/hashgraph-development.component';
import { HyperledgerDevelopmentComponent } from './enterprise/hyperledger-development/hyperledger-development.component';
import { IpfsDevelopmentComponent } from './enterprise/ipfs-development/ipfs-development.component';
import { SmartContractDevelopmentComponent } from './enterprise/smart-contract-development/smart-contract-development.component';
import { SolanaDevelopmentComponent } from './enterprise/solana-development/solana-development.component';
import { SolidityDevelopmentComponent } from './enterprise/solidity-development/solidity-development.component';
import { HomeComponent } from './home/home.component';
import { AgricultureComponent } from './industries/agriculture/agriculture.component';
import { FinanceComponent } from './industries/finance/finance.component';
import { GameDevelopmentComponent } from './industries/game-development/game-development.component';
import { HealthcareComponent } from './industries/healthcare/healthcare.component';
import { InsuranceComponent } from './industries/insurance/insurance.component';
import { RentalComponent } from './industries/rental/rental.component';
import { RetailComponent } from './industries/retail/retail.component';
import { SupplyChainComponent } from './industries/supply-chain/supply-chain.component';
import { MetaverseDevelopmentCompanyComponent } from './metaverse-development/metaverse-development.component';
import { NftGamingPlatformDevelopmentComponent } from './nft/nft-gaming-platform-development/nft-gaming-platform-development.component';
import { NonFungibleComponent } from './nft/non-fungible/non-fungible.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BridgeSmartContractComponent } from './products/bridge-smart-contract/bridge-smart-contract.component';
// import { ColdWalletIntegrationComponent } from './products/cold-wallet-integration/cold-wallet-integration.component';
// import { CrowdfundingScriptComponent } from './products/crowdfunding-script/crowdfunding-script.component';
import { DefiCryptoWalletSoftwareComponent } from './products/defi-crypto-wallet-software/defi-crypto-wallet-software.component';
// import { DefiLendingSoftwareComponent } from './products/defi-lending-software/defi-lending-software.component';
import { IcoDashboardScriptComponent } from './products/ico-dashboard-script/ico-dashboard-script.component';
import { TokenMigrationComponent } from './products/token-migration/token-migration.component';
import { UniswapCloneComponent } from './products/uniswap-clone/uniswap-clone.component';
import { BlockchainConsultingComponent } from './services/blockchain-consulting/blockchain-consulting.component';
import { CardanoBlockchainDevelopmentComponent } from './services/cardano-blockchain-development/cardano-blockchain-development.component';
import { CordaBlockchainDevelopmentComponent } from './services/corda-blockchain-development/corda-blockchain-development.component';
import { CryptoCurrencyDevelopmentComponent } from './services/crypto-currency-development/crypto-currency-development.component';
import { CryptocurrencyExchangeDevelopmentComponent } from './services/cryptocurrency-exchange-development/cryptocurrency-exchange-development.component';
import { DaoDevelopmentComponent } from './services/dao-development/dao-development.component';
import { DefiStakingPlatformComponent } from './services/defi-staking-platform/defi-staking-platform.component';
import { DefiYieldFarmingComponent } from './services/defi-yield-farming/defi-yield-farming.component';
import { IcoCoinDevelopmentComponent } from './services/ico-coin-development/ico-coin-development.component';
// import { IcoMarketingComponent } from './services/ico-marketing/ico-marketing.component';

import { StoCoinDevelopmentComponent } from './services/sto-coin-development/sto-coin-development.component';
import { TronTokenDevelopmentComponent } from './services/tron-token-development/tron-token-development.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { MarketplacesolanaComponent } from './blog-single-detail/marketplacesolana/marketplacesolana.component';
import { NftmarketplaconavalancheComponent } from './blog-single-detail/nftmarketplaconavalanche/nftmarketplaconavalanche.component';
import { BlockchainxInSupplyChainComponent } from './blog-single-detail/blockchainx-in-supply-chain/blockchainx-in-supply-chain.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { BepNewComponent } from './services/bep-new/bep-new.component';
import { DecentralizedIdentityInBlockchainComponent } from './blog-single-detail/decentralized-identity-in-blockchain/decentralized-identity-in-blockchain.component';
import { AiInWeb3GamingAndMetaverseComponent } from './blog-single-detail/ai-in-web3-gaming-and-metaverse/ai-in-web3-gaming-and-metaverse.component';
import { Erc20TokenDevelopmentNewComponent } from './services/erc20-token-development-new/erc20-token-development-new.component';
import { CreateNftMarketplacePolygonComponent } from './blog-single-detail/create-nft-marketplace-polygon/create-nft-marketplace-polygon.component';
import { CreatePermissionedBlockchainComponent } from './blog-single-detail/create-permissioned-blockchain/create-permissioned-blockchain.component';
import { SocialTokenHowUsedComponent } from './blog-single-detail/social-token-how-used/social-token-how-used.component';
import { TenMetaverseUseCasesExamplesForBusinessComponent } from './blog-single-detail/ten-metaverse-use-cases-examples-for-business/ten-metaverse-use-cases-examples-for-business.component';
import { AuthorComponent } from './author/author.component';
import { KeyFactorsInsightsSuccessfulNftMarketplaceComponent } from './blog-single-detail/key-factors-insights-successful-nft-marketplace/key-factors-insights-successful-nft-marketplace.component';
import { DecentralizedApplicationsRevolutionizingComponent } from './blog-single-detail/decentralized-applications-revolutionizing/decentralized-applications-revolutionizing.component';
import { FiveBestNftWalletsStoreComponent } from './blog-single-detail/five-best-nft-wallets-store/five-best-nft-wallets-store.component';
import { FashionBrandsEstablishingPositionWeb3Component } from './blog-single-detail/fashion-brands-establishing-position-web3/fashion-brands-establishing-position-web3.component';
import { NftTicketingHoldsFutureLiveEventsComponent } from './blog-single-detail/nft-ticketing-holds-future-live-events/nft-ticketing-holds-future-live-events.component';
import { LeverageTradingDefiLendingBorrowingComponent } from './blog-single-detail/leverage-trading-defi-lending-borrowing/leverage-trading-defi-lending-borrowing.component';
import { BuildHarmonicNftMusicMarketplaceComponent } from './blog-single-detail/build-harmonic-nft-music-marketplace/build-harmonic-nft-music-marketplace.component';
import { NftRoyaltiesHowTheyWorkComponent } from './blog-single-detail/nft-royalties-how-they-work/nft-royalties-how-they-work.component';
import { BlockchainEmpowersCrowdfundingBenefitsComponent } from './blog-single-detail/blockchain-empowers-crowdfunding-benefits/blockchain-empowers-crowdfunding-benefits.component';
import { BlockchainTraceabilitySupplyChainManagementComponent } from './blog-single-detail/blockchain-traceability-supply-chain-management/blockchain-traceability-supply-chain-management.component';
import { BlockchainBasedLandRegistrySystemComponent } from './blog-single-detail/blockchain-based-land-registry-system/blockchain-based-land-registry-system.component';
import { BlockchainImpactOnDigitalIdentityComponent } from './blog-single-detail/blockchain-impact-on-digital-identity/blockchain-impact-on-digital-identity.component';
import { BlockchainIdentityManagementBenefitsComponent } from './blog-single-detail/blockchain-identity-management-benefits/blockchain-identity-management-benefits.component';
import { BlockchainBasedIdentityManagementComponent } from './blog-single-detail/blockchain-based-identity-management/blockchain-based-identity-management.component';
import { NftBusinessUseCasesComponent } from './blog-single-detail/nft-business-use-cases/nft-business-use-cases.component';
import { NftStakingPlatformDemoComponent } from './products/nft-staking-platform-demo/nft-staking-platform-demo.component';
import { WhatIsErc20Component } from './blog-single-detail/what-is-erc20/what-is-erc20.component';
import { NftMarketplaceComponent } from './blog-single-detail/nft-marketplace/nft-marketplace.component';
import { NftMarketplaceDevelopmentNewComponent } from './nft/nft-marketplace-development-new/nft-marketplace-development-new.component';
import { CryptoLaunchpadDevelopmentStepsComponent } from './blog-single-detail/crypto-launchpad-development-steps/crypto-launchpad-development-steps.component';
import { HowToCreateAPrivateBlockchainComponent } from './blog-single-detail/how-to-create-a-private-blockchain/how-to-create-a-private-blockchain.component';
import { WebDevelopmentCompanyComponent } from './enterprise/web-development-company/web-development-company.component';
import { EnterpriseBlockchainDevelopmentComponent } from './enterprise/enterprise-blockchain-development/enterprise-blockchain-development.component';
import { RealWorldAssetTokenizationComponent } from './enterprise/real-world-asset-tokenization/real-world-asset-tokenization.component';
import { NewsComponent } from './news/news.component';
import { HomeNewComponent } from './home-new/home-new.component';
import { RealWorldAssetTokenizationBlogComponent } from './blog-single-detail/real-world-asset-tokenization-blog/real-world-asset-tokenization-blog.component';
import { Web3EmpoweredCommunicationPlatformNewsComponent } from './news-details/web3-empowered-communication-platform-news/web3-empowered-communication-platform-news.component';
import { BitcoinSpotEtfsComponent } from './news-details/bitcoin-spot-etfs/bitcoin-spot-etfs.component';
import { RealEstateComponent } from './industries/real-estate/real-estate.component';
import { Erc20TokenDevelopmentGuideComponent } from './blog-single-detail/erc20-token-development-guide/erc20-token-development-guide.component';
import { TopRealWorldAssetTokenizationCompaniesBlogComponent } from './blog-single-detail/top-real-world-asset-tokenization-companies-blog/top-real-world-asset-tokenization-companies-blog.component';
import { HomeV2Component } from './home-v2/home-v2.component';
import { CryptocurrencyLaunchpadDemoComponent } from './products/cryptocurrency-launchpad-demo/cryptocurrency-launchpad-demo.component';
import { RealEstateTokenizationGuideComponent } from './blog-single-detail/real-estate-tokenization-guide/real-estate-tokenization-guide.component';
import { AiTokenDefinitionComponent } from './blog-single-detail/ai-token-definition/ai-token-definition.component';
import { AiMemeCoinComponent } from './services/ai-meme-coin/ai-meme-coin.component';
import { TokenDevelopmentCompanyComponent } from './products/token-development-company/token-development-company.component';
import { DepinDevelopmentCompanyComponent } from './enterprise/depin-development-company/depin-development-company.component';
import { RebaseTokenDevelopmentComponent } from './services/rebase-token-development/rebase-token-development.component';
import { WhatIsDepinComponent } from './blog-single-detail/what-is-depin/what-is-depin.component';
import { GamingUtilityTokenComponent } from './services/gaming-utility-token/gaming-utility-token.component';
import { GamingGovernanceTokenComponent } from './services/gaming-governance-token/gaming-governance-token.component';
import { GoldTokenizationComponent } from './services/gold-tokenization/gold-tokenization.component';
import { SocialfiPlatformDevelopmentComponent } from './services/socialfi-platform-development/socialfi-platform-development.component';
import { StablecoinDevelopmentServicesComponent } from './services/stablecoin-development-services/stablecoin-development-services.component';
import { RestakingPlatformComponent } from './services/restaking-platform/restaking-platform.component';
import { GamefiDevelopmentComponent } from './services/gamefi-development/gamefi-development.component';
import { PlayToEarnComponent } from './services/play-to-earn/play-to-earn.component';
import { AssetBackedTokenComponent } from './services/asset-backed-token/asset-backed-token.component';
import { Brc20TokenComponent } from './services/brc20-token/brc20-token.component';
import { MoveToEarnComponent } from './services/move-to-earn/move-to-earn.component';
import { ExchangeTokenDevelopmentComponent } from './services/exchange-token-development/exchange-token-development.component';
import { LiquidStakingPlatformDevelopmentComponent } from './services/liquid-staking-platform-development/liquid-staking-platform-development.component';
import { OnChainIdentitySolutionsComponent } from './services/on-chain-identity-solutions/on-chain-identity-solutions.component';
import { BaseTokenComponent } from './services/base-token/base-token.component';
import { ParallelizedEvmComponent } from './services/parallelized-evm/parallelized-evm.component';
import { CatThemedCoinsComponent } from './services/cat-themed-coins/cat-themed-coins.component';
import { SolanaMemeCoinDevelopmentComponent } from './services/solana-meme-coin-development/solana-meme-coin-development.component';
import { ZkTechDevelopmentServiceComponent } from './services/zk-tech-development-service/zk-tech-development-service.component';
import { SubstrateBlockchainComponent } from './services/substrate-blockchain/substrate-blockchain.component';
import { ModularBlockchainComponent } from './services/modular-blockchain/modular-blockchain.component';
import { TopBlockchainDevelopmentCompaniesInUsComponent } from './blog-single-detail/top-blockchain-development-companies-in-us/top-blockchain-development-companies-in-us.component';
import { OpStackRollupsComponent } from './services/op-stack-rollups/op-stack-rollups.component';
import { PolygonZkevmDevelopmentComponent } from './services/polygon-zkevm-development/polygon-zkevm-development.component';
import { CoinstoreExchangeCloneScriptComponent } from './services/coinstore-exchange-clone-script/coinstore-exchange-clone-script.component';
import { PolitifiTokenDevelopmentComponent } from './services/politifi-token-development/politifi-token-development.component';
import { PolygonCdkComponent } from './services/polygon-cdk/polygon-cdk.component';
import { AutomatedMarketMakerComponent } from './services/automated-market-maker/automated-market-maker.component';
import { MevProtectionComponent } from './services/mev-protection/mev-protection.component';
import { FanTokenComponent } from './services/fan-token/fan-token.component';
import { IntroductionToBaseLayerBlockchainDevelopmentComponent } from './blog-single-detail/introduction-to-base-layer-blockchain-development/introduction-to-base-layer-blockchain-development.component';
import { BitcoinLayerDevelopmentComponent } from './services/bitcoin-layer-development/bitcoin-layer-development.component';
import { BlockchainAbstractionComponent } from './blog-single-detail/blockchain-abstraction/blockchain-abstraction.component';
import { MemestocksSurgeAsRoaringkittyReturnsComponent } from './news-details/memestocks-surge-as-roaringkitty-returns/memestocks-surge-as-roaringkitty-returns.component';
import { ParachainDevelopmentCompanyComponent } from './services/parachain-development-company/parachain-development-company.component';
import { BlockchainIotComponent } from './services/blockchain-iot/blockchain-iot.component';
import { OrdinalsNftComponent } from './services/ordinals-nft/ordinals-nft.component';
import { DesocAppComponent } from './services/desoc-app/desoc-app.component';
import { LayerZeroComponent } from './services/layer-zero/layer-zero.component';
import { LiquidRestakingGovernanceTokensComponent } from './services/liquid-restaking-governance-tokens/liquid-restaking-governance-tokens.component';
import { DerivativesComponent } from './services/derivatives/derivatives.component';
import { BlockchainDevelopmentCostComponent } from './blog-single-detail/blockchain-development-cost/blockchain-development-cost.component';
// import { BlockchainDevelopmentCompanyUsaComponent } from './services/blockchain-development-company-usa/blockchain-development-company-usa.component';
import { TopRealEstateTokenizationCompaniesComponent } from './blog-single-detail/top-real-estate-tokenization-companies/top-real-estate-tokenization-companies.component';
import { MicaRegulationComponent } from './news-details/mica-regulation/mica-regulation.component';
import { SolanaBlockchainDevelopmentComponent } from './enterprise/solana-blockchain-development/solana-blockchain-development.component';
import { environment } from 'src/environments/environment';
import { BannerComponent } from './shared/banner/banner.component';
import { LuxuryItemsTokenizationDevelopmentComponent } from './enterprise/luxury-items-tokenization-development/luxury-items-tokenization-development.component';
import { CrossChainInteroperabilityProtocolComponent } from './blog-single-detail/cross-chain-interoperability-protocol/cross-chain-interoperability-protocol.component';
import { BlockchainDevelopmentCompanyUaeComponent } from './services/blockchain-development-company-uae/blockchain-development-company-uae.component';
import { BlockchainDevelopmentCompanyInSpainComponent } from './services/blockchain-development-company-in-spain/blockchain-development-company-in-spain.component';
import { BlockchainDevelopmentCompanyFranceComponent } from './services/blockchain-development-company-france/blockchain-development-company-france.component';
import { BlockchainDevelopmentCompanyUsaComponent } from './services/blockchain-development-company-usa/blockchain-development-company-usa.component';
import { MemeCoinDevelopmentComponent } from './services/meme-coin-development/meme-coin-development.component';
import { PumpFunCloneScriptComponent } from './services/pump-fun-clone-script/pump-fun-clone-script.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { Erc20TokenDevelopmentCompaniesComponent } from './blog-single-detail/erc20-token-development-companies/erc20-token-development-companies.component';
import { CreateYourOwnCryptoTokenComponent } from './blog-single-detail/create-your-own-crypto-token/create-your-own-crypto-token.component';
import { PreciousMetalTokenizationComponent } from './blog-single-detail/precious-metal-tokenization/precious-metal-tokenization.component';
import { LaunchYourMemeCoinComponent } from './blog-single-detail/launch-your-meme-coin/launch-your-meme-coin.component';
import { CreateSolanaMemeCoinComponent } from './blog-single-detail/create-solana-meme-coin/create-solana-meme-coin.component';
import { CreateAMemeCoinComponent } from './blog-single-detail/create-a-meme-coin/create-a-meme-coin.component';
import { TokenDevelopmentServicesComponent } from './blog-single-detail/token-development-services/token-development-services.component';
import { Erc20VsTrc20VsBep20Component } from './blog-single-detail/erc20-vs-trc20-vs-bep20/erc20-vs-trc20-vs-bep20.component';
import { AssetTokenizationComponent } from './blog-single-detail/asset-tokenization/asset-tokenization.component';
import { BitcoinStakingProtocolDevelopmentComponent } from './bitcoin-staking-protocol-development/bitcoin-staking-protocol-development.component';
import { LiquidStakingProtocolDevelopmentComponent } from './liquid-staking-protocol-development/liquid-staking-protocol-development.component';
import { TestimonialsNewComponent } from './testimonials-new/testimonials-new.component';
import { RealestateTokenizationOnBlockchainComponent } from './blog-single-detail/realestate-tokenization-on-blockchain/realestate-tokenization-on-blockchain.component';
import { CreateBep20TokenComponent } from './blog-single-detail/create-bep20-token/create-bep20-token.component';
import { OndoFinanceCloneScriptComponent } from './ondo-finance-clone-script/ondo-finance-clone-script.component';
import { BondTokenizationServicesComponent } from './bond-tokenization-services/bond-tokenization-services.component';

const routes: Routes = [
  // { path: '', component: HomeComponent },
  // { path: 'home-new/', component: HomeNewComponent },

  { path: '', component: HomeV2Component },

  { path: 'metaverse-development-company', redirectTo: '/metaverse-development-company/', pathMatch: 'full' },
  { path: 'metaverse-development-company/', component: MetaverseDevelopmentCompanyComponent },

  { path: 'blockchain-application-in-healthcare', redirectTo: '/blockchain-application-in-healthcare/', pathMatch: 'full' },
  { path: 'blockchain-application-in-healthcare/', component: HealthcareComponent },

  { path: 'blockchain-technology-in-banking-and-finance', redirectTo: '/blockchain-technology-in-banking-and-finance/', pathMatch: 'full' },
  { path: 'blockchain-technology-in-banking-and-finance/', component: FinanceComponent },

  { path: 'blockchain-in-insurance', redirectTo: '/blockchain-in-insurance/', pathMatch: 'full' },
  { path: 'blockchain-in-insurance/', component: InsuranceComponent },

  { path: 'blockchain-in-rental', redirectTo: '/blockchain-in-rental/', pathMatch: 'full' },
  { path: 'blockchain-in-rental/', component: RentalComponent },

  { path: 'what-is-supply-chain-management', redirectTo: '/what-is-supply-chain-management/', pathMatch: 'full' },
  { path: 'what-is-supply-chain-management/', component: SupplyChainComponent },

  { path: 'blockchain-game-development', redirectTo: '/blockchain-game-development/', pathMatch: 'full' },
  { path: 'blockchain-game-development/', component: GameDevelopmentComponent },

  { path: 'blockchain-applications-agriculture', redirectTo: '/blockchain-applications-agriculture/', pathMatch: 'full' },
  { path: 'blockchain-applications-agriculture/', component: AgricultureComponent },

  { path: 'blockchain-in-retail', redirectTo: '/blockchain-in-retail/', pathMatch: 'full' },
  { path: 'blockchain-in-retail/', component: RetailComponent },

  { path: 'contact', redirectTo: '/contact/', pathMatch: 'full' },
  { path: 'contact/', component: ContactComponent },

  { path: 'case-studies', redirectTo: '/case-studies/', pathMatch: 'full' },
  { path: 'case-studies/', component: CaseStudiesComponent },

  { path: 'testimonial', redirectTo: '/testimonial/', pathMatch: 'full' },
  { path: 'testimonial/', component: TestimonialsComponent },

  { path: 'terms-and-conditions', redirectTo: '/terms-and-conditions/', pathMatch: 'full' },
  { path: 'terms-and-conditions/', component: TermsAndConditionsComponent },

  { path: 'privacy-policy', redirectTo: '/privacy-policy/', pathMatch: 'full' },
  { path: 'privacy-policy/', component: PrivacyPolicyComponent },

  { path: 'hyperledger-blockchain-development-services', redirectTo: '/hyperledger-blockchain-development-services/', pathMatch: 'full' },
  { path: 'hyperledger-blockchain-development-services/', component: HyperledgerDevelopmentComponent },

  { path: 'dapps-development', redirectTo: '/dapps-development/', pathMatch: 'full' },
  { path: 'dapps-development/', component: DappsDevelopmentComponent },

  { path: 'hire-solidity-developers', redirectTo: '/hire-solidity-developers/', pathMatch: 'full' },
  { path: 'hire-solidity-developers/', component: SolidityDevelopmentComponent },

  { path: 'ipfs-blockchain', redirectTo: '/ipfs-blockchain/', pathMatch: 'full' },
  { path: 'ipfs-blockchain/', component: IpfsDevelopmentComponent },

  { path: 'hashgraph-development-services', redirectTo: '/hashgraph-development-services/', pathMatch: 'full' },
  { path: 'hashgraph-development-services/', component: HashgraphDevelopmentComponent },

  { path: 'defi-development-company', redirectTo: '/defi-development-company/', pathMatch: 'full' },
  { path: 'defi-development-company/', component: DefiDevelopmentComponent },

  { path: 'crypto-currency-development', redirectTo: '/crypto-currency-development/', pathMatch: 'full' },
  { path: 'crypto-currency-development/', component: CryptoCurrencyDevelopmentComponent },

  { path: 'ico-development', redirectTo: '/ico-development/', pathMatch: 'full' },
  { path: 'ico-development/', component: IcoCoinDevelopmentComponent },

  { path: 'security-token-offering-services', redirectTo: '/security-token-offering-services/', pathMatch: 'full' },
  { path: 'security-token-offering-services/', component: StoCoinDevelopmentComponent },

  // { path: 'ico-marketing-agency', redirectTo: '/ico-marketing-agency/', pathMatch: 'full' },
  // { path: 'ico-marketing-agency/', component: IcoMarketingComponent },

  { path: 'cryptocurrency-exchange-software-development', redirectTo: '/cryptocurrency-exchange-software-development/', pathMatch: 'full' },
  { path: 'cryptocurrency-exchange-software-development/', component: CryptocurrencyExchangeDevelopmentComponent },

  { path: 'tron-token-development', redirectTo: '/tron-token-development/', pathMatch: 'full' },
  { path: 'tron-token-development/', component: TronTokenDevelopmentComponent },

  { path: 'corda-blockchain-development-services', redirectTo: '/corda-blockchain-development-services/', pathMatch: 'full' },
  { path: 'corda-blockchain-development-services/', component: CordaBlockchainDevelopmentComponent },

  { path: 'cardano-ada-blockchain-development', redirectTo: '/cardano-ada-blockchain-development/', pathMatch: 'full' },
  { path: 'cardano-ada-blockchain-development/', component: CardanoBlockchainDevelopmentComponent },

  { path: 'dao-development-company', redirectTo: '/dao-development-company/', pathMatch: 'full' },
  { path: 'dao-development-company/', component: DaoDevelopmentComponent },

  { path: 'defi-staking-platform', redirectTo: '/defi-staking-platform/', pathMatch: 'full' },
  { path: 'defi-staking-platform/', component: DefiStakingPlatformComponent },

  { path: 'defi-yield-farming-development', redirectTo: '/defi-yield-farming-development/', pathMatch: 'full' },
  { path: 'defi-yield-farming-development/', component: DefiYieldFarmingComponent },

  { path: 'nft-gaming-platform-development', redirectTo: '/nft-gaming-platform-development/', pathMatch: 'full' },
  { path: 'nft-gaming-platform-development/', component: NftGamingPlatformDevelopmentComponent },

  { path: 'ready-made-ico-software', redirectTo: '/ready-made-ico-software/', pathMatch: 'full' },
  { path: 'ready-made-ico-software/', component: IcoDashboardScriptComponent },

  // { path: 'blockchain-lending-software-using-defi', redirectTo: '/blockchain-lending-software-using-defi/', pathMatch: 'full' },
  // { path: 'blockchain-lending-software-using-defi/', component: DefiLendingSoftwareComponent },

  { path: 'cryptocurrency-wallet-development', redirectTo: '/cryptocurrency-wallet-development/', pathMatch: 'full' },
  { path: 'cryptocurrency-wallet-development/', component: DefiCryptoWalletSoftwareComponent },

  // { path: 'crowdfunding-script', redirectTo: '/crowdfunding-script/', pathMatch: 'full' },
  // { path: 'crowdfunding-script/', component: CrowdfundingScriptComponent },

  // { path: 'cold-wallet-integration', redirectTo: '/cold-wallet-integration/', pathMatch: 'full' },
  // { path: 'cold-wallet-integration/', component: ColdWalletIntegrationComponent },

  { path: 'uniswap-clone-script', redirectTo: '/uniswap-clone-script/', pathMatch: 'full' },
  { path: 'uniswap-clone-script/', component: UniswapCloneComponent },

  { path: 'token-migration', redirectTo: '/token-migration/', pathMatch: 'full' },
  { path: 'token-migration/', component: TokenMigrationComponent },

  { path: 'bridge-smart-contract-development', redirectTo: '/bridge-smart-contract-development/', pathMatch: 'full' },
  { path: 'bridge-smart-contract-development/', component: BridgeSmartContractComponent },

  { path: 'zuki', redirectTo: '/zuki/', pathMatch: 'full' },
  { path: 'zuki/', component: ZukiComponent },

  { path: 'ektachain', redirectTo: '/ektachain/', pathMatch: 'full' },
  { path: 'ektachain/', component: EktachainComponent },

  { path: 'blockchain-consulting-services', redirectTo: '/blockchain-consulting-services/', pathMatch: 'full' },
  { path: 'blockchain-consulting-services/', component: BlockchainConsultingComponent },

  { path: 'starcard-sports-games', redirectTo: '/starcard-sports-games/', pathMatch: 'full' },
  { path: 'starcard-sports-games/', component: StarcardSportsGamesComponent },

  { path: 'minosis', redirectTo: '/minosis/', pathMatch: 'full' },
  { path: 'minosis/', component: MinosisComponent },

  { path: 'about-us', redirectTo: '/about-us/', pathMatch: 'full' },
  { path: 'about-us/', component: AboutUsComponent },

  { path: 'hire-solana-developers', redirectTo: '/hire-solana-developers/', pathMatch: 'full' },
  { path: 'hire-solana-developers/', component: SolanaDevelopmentComponent },

  { path: 'blog', redirectTo: '/blog/', pathMatch: 'full' },
  { path: 'blog/', component: BlogMainComponent },

  { path: 'non-fungible-token-development', redirectTo: '/non-fungible-token-development/', pathMatch: 'full' },
  { path: 'non-fungible-token-development/', component: NonFungibleComponent },

  { path: 'crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad', redirectTo: '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/', pathMatch: 'full' },
  { path: 'crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/', component: CryptoLaunchpadDevelopmentStepsComponent },

  { path: '9-myths-about-blockchain-technology', redirectTo: '/9-myths-about-blockchain-technology/', pathMatch: 'full' },
  { path: '9-myths-about-blockchain-technology/', component: MythsAboutBlockchainComponent },

  { path: 'decentralized-applications-revolutionizing-future-iot', redirectTo: '/decentralized-applications-revolutionizing-future-iot/', pathMatch: 'full' },
  { path: 'decentralized-applications-revolutionizing-future-iot/', component: DecentralizedApplicationsRevolutionizingComponent },

  { path: 'decentralized-identities-beginners-guide', redirectTo: '/decentralized-identities-beginners-guide/', pathMatch: 'full' },
  { path: 'decentralized-identities-beginners-guide/', component: DecentralizedIdentityInBlockchainComponent },

  { path: 'ai-blockchain-web3-revolutionizing-gaming-metaverse', redirectTo: '/ai-blockchain-web3-revolutionizing-gaming-metaverse/', pathMatch: 'full' },
  { path: 'ai-blockchain-web3-revolutionizing-gaming-metaverse/', component: AiInWeb3GamingAndMetaverseComponent },

  { path: 'blockchain-vs-bitcoin', redirectTo: '/blockchain-vs-bitcoin/', pathMatch: 'full' },
  { path: 'blockchain-vs-bitcoin/', component: BlockchainVsBitcoinComponent },

  { path: 'types-of-blockchain', redirectTo: '/types-of-blockchain/', pathMatch: 'full' },
  { path: 'types-of-blockchain/', component: TypesOfBlockchainComponent },

  { path: 'blockchain-vs-distributed-ledger-technology', redirectTo: '/blockchain-vs-distributed-ledger-technology/', pathMatch: 'full' },
  { path: 'blockchain-vs-distributed-ledger-technology/', component: BlockchainVsDistributedLedgerComponent },

  { path: 'hyperledger-fabric-v2', redirectTo: '/hyperledger-fabric-v2/', pathMatch: 'full' },
  { path: 'hyperledger-fabric-v2/', component: HyperledgerFabricComponent },

  { path: 'blockchain-for-government', redirectTo: '/blockchain-for-government/', pathMatch: 'full' },
  { path: 'blockchain-for-government/', component: BlockchainForGovernmentComponent },

  { path: 'what-is-defi-centralized-finance', redirectTo: '/what-is-defi-centralized-finance/', pathMatch: 'full' },
  { path: 'what-is-defi-centralized-finance/', component: WhatIsDefiComponent },

  { path: 'decentralized-finance-defi-use-cases', redirectTo: '/decentralized-finance-defi-use-cases/', pathMatch: 'full' },
  { path: 'decentralized-finance-defi-use-cases/', component: UseCasesOfDefiComponent },

  { path: 'ethereum-defi-decentralized-projects', redirectTo: '/ethereum-defi-decentralized-projects/', pathMatch: 'full' },
  { path: 'ethereum-defi-decentralized-projects/', component: DefiProjectsComponent },

  { path: 'what-is-polkadot-blockchain-technology', redirectTo: '/what-is-polkadot-blockchain-technology/', pathMatch: 'full' },
  { path: 'what-is-polkadot-blockchain-technology/', component: WhatIsPolkadotComponent },

  { path: 'advantages-of-nft', redirectTo: '/advantages-of-nft/', pathMatch: 'full' },
  { path: 'advantages-of-nft/', component: AdvantagesOfNftComponent },

  { path: 'know-everything-about-blockchain-metaverse', redirectTo: '/know-everything-about-blockchain-metaverse/', pathMatch: 'full' },
  { path: 'know-everything-about-blockchain-metaverse/', component: EverythingAboutMetaverseComponent },

  { path: 'top-5-metaverse-projects', redirectTo: '/top-5-metaverse-projects/', pathMatch: 'full' },
  { path: 'top-5-metaverse-projects/', component: TopMetaverseProjectsComponent },

  { path: 'what-is-gamefi', redirectTo: '/what-is-gamefi/', pathMatch: 'full' },
  { path: 'what-is-gamefi/', component: WhatIsGamefiComponent },

  { path: 'know-everything-about-substrate', redirectTo: '/know-everything-about-substrate/', pathMatch: 'full' },
  { path: 'know-everything-about-substrate/', component: EverythingAboutSubstrateComponent },

  { path: 'future-nft-applications-challenges', redirectTo: '/future-nft-applications-challenges/', pathMatch: 'full' },
  { path: 'future-nft-applications-challenges/', component: FutureOfNftApplicationsComponent },

  { path: 'nft-marketplace-everything-you-need-to-know', redirectTo: '/nft-marketplace-everything-you-need-to-know/', pathMatch: 'full' },
  { path: 'nft-marketplace-everything-you-need-to-know/', component: EverythingAboutNftMarketplaceComponent },

  { path: 'how-are-nfts-used-in-defi', redirectTo: '/how-are-nfts-used-in-defi/', pathMatch: 'full' },
  { path: 'how-are-nfts-used-in-defi/', component: NftUsedInDefiComponent },

  { path: 'nfts-and-their-role-in-the-metaverse', redirectTo: '/nfts-and-their-role-in-the-metaverse/', pathMatch: 'full' },
  { path: 'nfts-and-their-role-in-the-metaverse/', component: NftRoleInMetaverseComponent },

  { path: 'how-can-blockchain-empower-and-enrich-women', redirectTo: '/how-can-blockchain-empower-and-enrich-women/', pathMatch: 'full' },
  { path: 'how-can-blockchain-empower-and-enrich-women/', component: BlockchainEmpowerWomenComponent },

  { path: 'flow-blockchain-and-ethereum', redirectTo: '/flow-blockchain-and-ethereum/', pathMatch: 'full' },
  { path: 'flow-blockchain-and-ethereum/', component: FlowBlockchainComponent },

  { path: 'nft-sports-platforms-development', redirectTo: '/nft-sports-platforms-development/', pathMatch: 'full' },
  { path: 'nft-sports-platforms-development/', component: NftSportsComponent },

  { path: 'nft-vulnerability-and-security', redirectTo: '/nft-vulnerability-and-security/', pathMatch: 'full' },
  { path: 'nft-vulnerability-and-security/', component: NftVulnerabilityComponent },

  { path: 'ten-big-brands-jumping-on-the-nft', redirectTo: '/ten-big-brands-jumping-on-the-nft/', pathMatch: 'full' },
  { path: 'ten-big-brands-jumping-on-the-nft/', component: NftBrandsComponent },

  { path: 'primary-technologies-in-metaverse', redirectTo: '/primary-technologies-in-metaverse/', pathMatch: 'full' },
  { path: 'primary-technologies-in-metaverse/', component: PrimaryTechnologyInMetaverseComponent },

  { path: 'comparison-of-solana-polygon-ethereum', redirectTo: '/comparison-of-solana-polygon-ethereum/', pathMatch: 'full' },
  { path: 'comparison-of-solana-polygon-ethereum/', component: SolanaPolygonEthComponent },

  { path: 'everything-you-need-to-know-about-web3', redirectTo: '/everything-you-need-to-know-about-web3/', pathMatch: 'full' },
  { path: 'everything-you-need-to-know-about-web3/', component: EverythingAboutWeb3Component },

  { path: 'the-future-of-metaverse', redirectTo: '/the-future-of-metaverse/', pathMatch: 'full' },
  { path: 'the-future-of-metaverse/', component: FuturesOfMetaverseComponent },

  { path: 'adapt-invest-explore-metaverse', redirectTo: '/adapt-invest-explore-metaverse/', pathMatch: 'full' },
  { path: 'adapt-invest-explore-metaverse/', component: AdaptInvestMetaverseComponent },

  { path: 'nft-gimmick-or-feature-of-gaming', redirectTo: '/nft-gimmick-or-feature-of-gaming/', pathMatch: 'full' },
  { path: 'nft-gimmick-or-feature-of-gaming/', component: NftGimmickComponent },

  { path: 'blockchain-influences-identity-management', redirectTo: '/blockchain-influences-identity-management/', pathMatch: 'full' },
  { path: 'blockchain-influences-identity-management/', component: BlockchainIdentityManagementComponent },

  { path: 'nft-market-place-insights', redirectTo: '/nft-market-place-insights/', pathMatch: 'full' },
  { path: 'nft-market-place-insights/', component: NftInsightsComponent },

  { path: 'five-blockchain-usecase-make-changes-in-telecom-industry', redirectTo: '/five-blockchain-usecase-make-changes-in-telecom-industry/', pathMatch: 'full' },
  { path: 'five-blockchain-usecase-make-changes-in-telecom-industry/', component: FiveBlockchainUseCaseComponent },

  { path: 'government-new-infrastructure-in-blockchain-network', redirectTo: '/government-new-infrastructure-in-blockchain-network/', pathMatch: 'full' },
  { path: 'government-new-infrastructure-in-blockchain-network/', component: BlockchainInfrastructureComponent },

  { path: 'blockchain-innovations-can-transform-financial-industry', redirectTo: '/blockchain-innovations-can-transform-financial-industry/', pathMatch: 'full' },
  { path: 'blockchain-innovations-can-transform-financial-industry/', component: BlockchainInnovationComponent },

  { path: 'blockchain-solves-top-three-problems-in-automotive-sector', redirectTo: '/blockchain-solves-top-three-problems-in-automotive-sector/', pathMatch: 'full' },
  { path: 'blockchain-solves-top-three-problems-in-automotive-sector/', component: BlockchainSolvesProblemsComponent },

  { path: 'benefits-of-blockchain-in-healthcare', redirectTo: '/benefits-of-blockchain-in-healthcare/', pathMatch: 'full' },
  { path: 'benefits-of-blockchain-in-healthcare/', component: BlockchainHealthcareComponent },

  { path: 'defi-vs-cefi', redirectTo: '/defi-vs-cefi/', pathMatch: 'full' },
  { path: 'defi-vs-cefi/', component: DefiVsCefiComponent },

  { path: 'implementation-of-rust-for-blockchain-network', redirectTo: '/implementation-of-rust-for-blockchain-network/', pathMatch: 'full' },
  { path: 'implementation-of-rust-for-blockchain-network/', component: BlockchainRustComponent },

  { path: 'everything-you-need-to-know-polkadot-parachain', redirectTo: '/everything-you-need-to-know-polkadot-parachain/', pathMatch: 'full' },
  { path: 'everything-you-need-to-know-polkadot-parachain/', component: PolkadotParachainComponent },

  { path: 'hashgraph-vs-blockchain', redirectTo: '/hashgraph-vs-blockchain/', pathMatch: 'full' },
  { path: 'hashgraph-vs-blockchain/', component: HashgraphVsBlockchainComponent },

  { path: 'metaverse-in-real-estate', redirectTo: '/metaverse-in-real-estate/', pathMatch: 'full' },
  { path: 'metaverse-in-real-estate/', component: MetaverseInRealestateComponent },

  { path: 'dao-in-nft', redirectTo: '/dao-in-nft/', pathMatch: 'full' },
  { path: 'dao-in-nft/', component: WhatIsDaoComponent },

  { path: 'solana-proof-of-history', redirectTo: '/solana-proof-of-history/', pathMatch: 'full' },
  { path: 'solana-proof-of-history/', component: SolanaProofComponent },

  { path: 'stellar-consensus-protocol', redirectTo: '/stellar-consensus-protocol/', pathMatch: 'full' },
  { path: 'stellar-consensus-protocol/', component: StellarConsensusProtocolComponent },

  { path: 'what-is-iso-20022', redirectTo: '/what-is-iso-20022/', pathMatch: 'full' },
  { path: 'what-is-iso-20022/', component: WhatIsIsoComponent },

  { path: 'what-is-nft-staking', redirectTo: '/what-is-nft-staking/', pathMatch: 'full' },
  { path: 'what-is-nft-staking/', component: NftStakingComponent },

  { path: 'how-to-create-your-own-nft-marketplace-in-2023', redirectTo: '/how-to-create-your-own-nft-marketplace-in-2023/', pathMatch: 'full' },
  { path: 'how-to-create-your-own-nft-marketplace-in-2023/', component: CreateNftMarketplaceComponent },

  { path: 'metaverse-use-case-and-benefits', redirectTo: '/metaverse-use-case-and-benefits/', pathMatch: 'full' },
  { path: 'metaverse-use-case-and-benefits/', component: MetaverseUseCaseBenefitsComponent },

  { path: '7-enduring-technologies-for-metaverse', redirectTo: '/7-enduring-technologies-for-metaverse/', pathMatch: 'full' },
  { path: '7-enduring-technologies-for-metaverse/', component: EnduringTechnologiesComponent },

  { path: '6-mistakes-you-should-avoid-developing-nft-marketplace', redirectTo: '/6-mistakes-you-should-avoid-developing-nft-marketplace/', pathMatch: 'full' },
  { path: '6-mistakes-you-should-avoid-developing-nft-marketplace/', component: AvoidMistakeMarketplaceComponent },

  { path: 'everything-about-nft-metaverse', redirectTo: '/everything-about-nft-metaverse/', pathMatch: 'full' },
  { path: 'everything-about-nft-metaverse/', component: NftEverytingAboutNftMetaversesComponent },

  { path: 'best-defi-platforms-to-consider-before-entering-2023', redirectTo: '/best-defi-platforms-to-consider-before-entering-2023/', pathMatch: 'full' },
  { path: 'best-defi-platforms-to-consider-before-entering-2023/', component: BestDefiPlatformsComponent },

  { path: 'multifunctional-fractionalization-nft', redirectTo: '/multifunctional-fractionalization-nft/', pathMatch: 'full' },
  { path: 'multifunctional-fractionalization-nft/', component: MultifunctionalNftComponent },

  { path: 'top-blockchain-platforms-explore-in-2023', redirectTo: '/top-blockchain-platforms-explore-in-2023/', pathMatch: 'full' },
  { path: 'top-blockchain-platforms-explore-in-2023/', component: TopBlockchainPlatformsComponent },

  { path: 'static-nfts-vs-dynamic-nfts', redirectTo: '/static-nfts-vs-dynamic-nfts/', pathMatch: 'full' },
  { path: 'static-nfts-vs-dynamic-nfts/', component: StaticVsDynamicNftComponent },

  { path: 'create-nfts-like-cryptopunks', redirectTo: '/create-nfts-like-cryptopunks/', pathMatch: 'full' },
  { path: 'create-nfts-like-cryptopunks/', component: NftCryptopunksComponent },

  { path: 'nft-security-tips-and-best-practices', redirectTo: '/nft-security-tips-and-best-practices/', pathMatch: 'full' },
  { path: 'nft-security-tips-and-best-practices/', component: NftSecurityTipsComponent },

  { path: 'know-the-key-features-of-metaverse', redirectTo: '/know-the-key-features-of-metaverse/', pathMatch: 'full' },
  { path: 'know-the-key-features-of-metaverse/', component: KeyFeatureOfMetaverseComponent },

  { path: 'build-virtual-world-with-unreal-engine', redirectTo: '/build-virtual-world-with-unreal-engine/', pathMatch: 'full' },
  { path: 'build-virtual-world-with-unreal-engine/', component: BuildVirtualWorldComponent },

  { path: 'best-metaverse-platform-to-know-about-in-2023', redirectTo: '/best-metaverse-platform-to-know-about-in-2023/', pathMatch: 'full' },
  { path: 'best-metaverse-platform-to-know-about-in-2023/', component: BestMetaverseKnowAboutComponent },

  { path: 'how-to-create-your-own-metaverse-virtual-world', redirectTo: '/how-to-create-your-own-metaverse-virtual-world/', pathMatch: 'full' },
  { path: 'how-to-create-your-own-metaverse-virtual-world/', component: BuildMetaverseWorldComponent },

  { path: 'blockchain-role-ai-meet-the-world-metaverse', redirectTo: '/blockchain-role-ai-meet-the-world-metaverse/', pathMatch: 'full' },
  { path: 'blockchain-role-ai-meet-the-world-metaverse/', component: AiMeetMetaverseComponent },

  { path: 'nft-auctions', redirectTo: '/nft-auctions/', pathMatch: 'full' },
  { path: 'nft-auctions/', component: NftAutionsComponent },

  { path: 'nft-royalties-and-how-they-work', redirectTo: '/nft-royalties-and-how-they-work/', pathMatch: 'full' },
  { path: 'nft-royalties-and-how-they-work/', component: NftRoyaltiesComponent },

  { path: 'nft-music-marketplace', redirectTo: '/nft-music-marketplace/', pathMatch: 'full' },
  { path: 'nft-music-marketplace/', component: NftMusicMarketplaceComponent },

  { path: 'healthcare-data-management', redirectTo: '/healthcare-data-management/', pathMatch: 'full' },
  { path: 'healthcare-data-management/', component: HealthcareManagementComponent },

  { path: 'how-to-create-an-nft-marketplace', redirectTo: '/how-to-create-an-nft-marketplace/', pathMatch: 'full' },
  { path: 'how-to-create-an-nft-marketplace/', component: HowToCreateNftMarketplaceComponent },

  { path: 'guide-to-phygital-nfts', redirectTo: '/guide-to-phygital-nfts/', pathMatch: 'full' },
  { path: 'guide-to-phygital-nfts/', component: PhygitalNftComponent },

  { path: 'erc1155-token-standard', redirectTo: '/erc1155-token-standard/', pathMatch: 'full' },
  { path: 'erc1155-token-standard/', component: Erc115Component },

  { path: 'profitable-metaverse-business-opportunities', redirectTo: '/profitable-metaverse-business-opportunities/', pathMatch: 'full' },
  { path: 'profitable-metaverse-business-opportunities/', component: MetaverseBusinessComponent },

  { path: 'create-metaverse-platform', redirectTo: '/create-metaverse-platform/', pathMatch: 'full' },
  { path: 'create-metaverse-platform/', component: CreateMetaversePlatformComponent },

  { path: 'nft-marketplace-development-solana', redirectTo: '/nft-marketplace-development-solana/', pathMatch: 'full' },
  { path: 'nft-marketplace-development-solana/', component: MarketplacesolanaComponent },

  { path: 'create-your-nft-marketplace-on-avalanche', redirectTo: '/create-your-nft-marketplace-on-avalanche/', pathMatch: 'full' },
  { path: 'create-your-nft-marketplace-on-avalanche/', component: NftmarketplaconavalancheComponent },

  { path: 'blockchain-in-supply-chain-management', redirectTo: '/blockchain-in-supply-chain-management/', pathMatch: 'full' },
  { path: 'blockchain-in-supply-chain-management/', component: BlockchainxInSupplyChainComponent },

  { path: 'bep20-token-development', redirectTo: '/bep20-token-development/', pathMatch: 'full' },
  { path: 'bep20-token-development/', component: BepNewComponent },

  { path: 'erc20-token-development', redirectTo: '/erc20-token-development/', pathMatch: 'full' },
  { path: 'erc20-token-development/', component: Erc20TokenDevelopmentNewComponent },

  { path: 'create-nft-marketplace-polygon', redirectTo: '/create-nft-marketplace-polygon/', pathMatch: 'full' },
  { path: 'create-nft-marketplace-polygon/', component: CreateNftMarketplacePolygonComponent },

  { path: 'create-permissioned-blockchain', redirectTo: '/create-permissioned-blockchain/', pathMatch: 'full' },
  { path: 'create-permissioned-blockchain/', component: CreatePermissionedBlockchainComponent },

  { path: 'social-token-how-used', redirectTo: '/social-token-how-used/', pathMatch: 'full' },
  { path: 'social-token-how-used/', component: SocialTokenHowUsedComponent },

  { path: 'ten-metaverse-use-cases-examples-for-business', redirectTo: '/ten-metaverse-use-cases-examples-for-business/', pathMatch: 'full' },
  { path: 'ten-metaverse-use-cases-examples-for-business/', component: TenMetaverseUseCasesExamplesForBusinessComponent },

  { path: 'nft-marketplace-development-complete-guide', redirectTo: '/nft-marketplace-development-complete-guide/', pathMatch: 'full' },
  { path: 'nft-marketplace-development-complete-guide/', component: KeyFactorsInsightsSuccessfulNftMarketplaceComponent },

  { path: 'five-best-nft-wallets-store-transact-digital-collectibles', redirectTo: '/five-best-nft-wallets-store-transact-digital-collectibles/', pathMatch: 'full' },
  { path: 'five-best-nft-wallets-store-transact-digital-collectibles/', component: FiveBestNftWalletsStoreComponent },

  { path: 'fashion-brands-establishing-position-web3', redirectTo: '/fashion-brands-establishing-position-web3/', pathMatch: 'full' },
  { path: 'fashion-brands-establishing-position-web3/', component: FashionBrandsEstablishingPositionWeb3Component },

  { path: 'nft-ticketing-holds-future-live-events', redirectTo: '/nft-ticketing-holds-future-live-events/', pathMatch: 'full' },
  { path: 'nft-ticketing-holds-future-live-events/', component: NftTicketingHoldsFutureLiveEventsComponent },

  { path: 'leverage-trading-defi-lending-borrowing', redirectTo: '/leverage-trading-defi-lending-borrowing/', pathMatch: 'full' },
  { path: 'leverage-trading-defi-lending-borrowing/', component: LeverageTradingDefiLendingBorrowingComponent },

  { path: 'build-harmonic-nft-music-marketplace', redirectTo: '/build-harmonic-nft-music-marketplace/', pathMatch: 'full' },
  { path: 'build-harmonic-nft-music-marketplace/', component: BuildHarmonicNftMusicMarketplaceComponent },

  { path: 'nft-royalties-how-they-work', redirectTo: '/nft-royalties-how-they-work/', pathMatch: 'full' },
  { path: 'nft-royalties-how-they-work/', component: NftRoyaltiesHowTheyWorkComponent },

  { path: 'blockchain-empowers-crowdfunding-benefits-drawbacks', redirectTo: '/blockchain-empowers-crowdfunding-benefits-drawbacks/', pathMatch: 'full' },
  { path: 'blockchain-empowers-crowdfunding-benefits-drawbacks/', component: BlockchainEmpowersCrowdfundingBenefitsComponent },

  { path: 'blockchain-traceability-supply-chain-management', redirectTo: '/blockchain-traceability-supply-chain-management/', pathMatch: 'full' },
  { path: 'blockchain-traceability-supply-chain-management/', component: BlockchainTraceabilitySupplyChainManagementComponent },

  { path: 'blockchain-based-land-registry-system', redirectTo: '/blockchain-based-land-registry-system/', pathMatch: 'full' },
  { path: 'blockchain-based-land-registry-system/', component: BlockchainBasedLandRegistrySystemComponent },

  { path: 'blockchain-impact-on-digital-identity', redirectTo: '/blockchain-impact-on-digital-identity/', pathMatch: 'full' },
  { path: 'blockchain-impact-on-digital-identity/', component: BlockchainImpactOnDigitalIdentityComponent },

  { path: 'blockchain-identity-management-benefits', redirectTo: '/blockchain-identity-management-benefits/', pathMatch: 'full' },
  { path: 'blockchain-identity-management-benefits/', component: BlockchainIdentityManagementBenefitsComponent },

  { path: 'blockchain-based-identity-management', redirectTo: '/blockchain-based-identity-management/', pathMatch: 'full' },
  { path: 'blockchain-based-identity-management/', component: BlockchainBasedIdentityManagementComponent },

  { path: 'nft-business-use-cases', redirectTo: '/nft-business-use-cases/', pathMatch: 'full' },
  { path: 'nft-business-use-cases/', component: NftBusinessUseCasesComponent },

  { path: 'nft-staking-platform-development', redirectTo: '/nft-staking-platform-development/', pathMatch: 'full' },
  { path: 'nft-staking-platform-development/', component: NftStakingPlatformDemoComponent },

  { path: 'what-is-erc20', redirectTo: '/what-is-erc20/', pathMatch: 'full' },
  { path: 'what-is-erc20/', component: WhatIsErc20Component },

  { path: 'key-considerations-nft-marketplace-development', redirectTo: '/key-considerations-nft-marketplace-development/', pathMatch: 'full' },
  { path: 'key-considerations-nft-marketplace-development/', component: NftMarketplaceComponent },

  { path: 'nft-marketplace-development', redirectTo: '/nft-marketplace-development/', pathMatch: 'full' },
  { path: 'nft-marketplace-development/', component: NftMarketplaceDevelopmentNewComponent },

  { path: 'how-to-create-a-private-blockchain', redirectTo: '/how-to-create-a-private-blockchain/', pathMatch: 'full' },
  { path: 'how-to-create-a-private-blockchain/', component: HowToCreateAPrivateBlockchainComponent  },

  { path: 'web3-development-company', redirectTo: '/web3-development-company/', pathMatch: 'full' },
  { path: 'web3-development-company/', component: WebDevelopmentCompanyComponent  },

  { path: 'enterprise-blockchain-development', redirectTo: '/enterprise-blockchain-development/', pathMatch: 'full' },
  { path: 'enterprise-blockchain-development/', component: EnterpriseBlockchainDevelopmentComponent  },

  { path: 'real-world-asset-tokenization', redirectTo: '/real-world-asset-tokenization/', pathMatch: 'full' },
  { path: 'real-world-asset-tokenization/', component: RealWorldAssetTokenizationComponent  },

  { path: 'real-world-asset-tokenization-guide-2024', redirectTo: '/real-world-asset-tokenization-guide-2024/', pathMatch: 'full' },
  { path: 'real-world-asset-tokenization-guide-2024/', component: RealWorldAssetTokenizationBlogComponent },

  { path: 'top-real-world-asset-tokenization-companies', redirectTo: '/top-real-world-asset-tokenization-companies/', pathMatch: 'full' },
  { path: 'top-real-world-asset-tokenization-companies/', component: TopRealWorldAssetTokenizationCompaniesBlogComponent },

  { path: 'news', redirectTo: '/news/', pathMatch: 'full' },
  { path: 'news/', component: NewsComponent  },

  { path: 'web3-empowered-communication-platform', redirectTo: '/web3-empowered-communication-platform/', pathMatch: 'full' },
  { path: 'web3-empowered-communication-platform/', component: Web3EmpoweredCommunicationPlatformNewsComponent  },

  { path: 'memestocks-surge-as-roaringkitty-returns', redirectTo: '/memestocks-surge-as-roaringkitty-returns/', pathMatch: 'full' },
  { path: 'memestocks-surge-as-roaringkitty-returns/', component: MemestocksSurgeAsRoaringkittyReturnsComponent },

  { path: 'author/yokesh-sankar', redirectTo: '/author/yokesh-sankar/', pathMatch: 'full' },
  { path: 'author/yokesh-sankar/', component: AuthorComponent },

  { path: 'bitcoin-spot-etfs', redirectTo: '/bitcoin-spot-etfs/', pathMatch: 'full' },
  { path: 'bitcoin-spot-etfs/', component: BitcoinSpotEtfsComponent },

  { path: 'real-estate-tokenization', redirectTo: '/real-estate-tokenization/', pathMatch: 'full' },
  { path: 'real-estate-tokenization/', component: RealEstateComponent },

  { path: 'real-estate-tokenization-guide', redirectTo: '/real-estate-tokenization-guide/', pathMatch: 'full' },
  { path: 'real-estate-tokenization-guide/', component: RealEstateTokenizationGuideComponent },

  { path: 'erc20-token-development-guide', redirectTo: '/erc20-token-development-guide/', pathMatch: 'full' },
  { path: 'erc20-token-development-guide/', component: Erc20TokenDevelopmentGuideComponent },

  { path: 'white-label-launchpad-development', redirectTo: '/white-label-launchpad-development/', pathMatch: 'full' },
  { path: 'white-label-launchpad-development/', component: CryptocurrencyLaunchpadDemoComponent },

  { path: 'ai-token-definition', redirectTo: '/ai-token-definition/', pathMatch: 'full' },
  { path: 'ai-token-definition/', component: AiTokenDefinitionComponent },

  { path: 'ai-meme-coin-development-company', redirectTo: '/ai-meme-coin-development-company/', pathMatch: 'full' },
  { path: 'ai-meme-coin-development-company/', component: AiMemeCoinComponent },

  { path: 'rebase-token-development', redirectTo: '/rebase-token-development/', pathMatch: 'full' },
  { path: 'rebase-token-development/', component: RebaseTokenDevelopmentComponent },

  { path: 'gaming-utility-token-development', redirectTo: '/gaming-utility-token-development/', pathMatch: 'full' },
  { path: 'gaming-utility-token-development/', component: GamingUtilityTokenComponent },

  { path: 'gaming-governance-token-development', redirectTo: '/gaming-governance-token-development/', pathMatch: 'full' },
  { path: 'gaming-governance-token-development/', component: GamingGovernanceTokenComponent },

  { path: 'stablecoin-development-services', redirectTo: '/stablecoin-development-services/', pathMatch: 'full' },
  { path: 'stablecoin-development-services/', component: StablecoinDevelopmentServicesComponent },

  { path: 'token-development-company', redirectTo: '/token-development-company/', pathMatch: 'full' },
  { path: 'token-development-company/', component: TokenDevelopmentCompanyComponent },

  { path: 'depin-development-company', redirectTo: '/depin-development-company/', pathMatch: 'full' },
  { path: 'depin-development-company/', component: DepinDevelopmentCompanyComponent },

  { path: 'what-is-depin', redirectTo: '/what-is-depin/', pathMatch: 'full' },
  { path: 'what-is-depin/', component: WhatIsDepinComponent },

  { path: 'gold-tokenization-development-company', redirectTo: '/gold-tokenization-development-company/', pathMatch: 'full' },
  { path: 'gold-tokenization-development-company/', component: GoldTokenizationComponent },

  { path: 'socialfi-platform-development', redirectTo: '/socialfi-platform-development/', pathMatch: 'full' },
  { path: 'socialfi-platform-development/', component: SocialfiPlatformDevelopmentComponent },

  { path: 'restaking-platform-development', redirectTo: '/restaking-platform-development/', pathMatch: 'full' },
  { path: 'restaking-platform-development/', component: RestakingPlatformComponent },

  { path: 'gamefi-development-company', redirectTo: '/gamefi-development-company/', pathMatch: 'full' },
  { path: 'gamefi-development-company/', component: GamefiDevelopmentComponent },

  { path: 'play-to-earn-development', redirectTo: '/play-to-earn-development/', pathMatch: 'full' },
  { path: 'play-to-earn-development/', component: PlayToEarnComponent },

  { path: 'asset-backed-token-development', redirectTo: '/asset-backed-token-development/', pathMatch: 'full' },
  { path: 'asset-backed-token-development/', component: AssetBackedTokenComponent },

  { path: 'brc20-token-development', redirectTo: '/brc20-token-development/', pathMatch: 'full' },
  { path: 'brc20-token-development/', component: Brc20TokenComponent },

  { path: 'move-to-earn-development-company', redirectTo: '/move-to-earn-development-company/', pathMatch: 'full' },
  { path: 'move-to-earn-development-company/', component: MoveToEarnComponent },

  { path: 'exchange-token-development', redirectTo: '/exchange-token-development/', pathMatch: 'full' },
  { path: 'exchange-token-development/', component: ExchangeTokenDevelopmentComponent },

  { path: 'liquid-staking-platform-development', redirectTo: '/liquid-staking-platform-development/', pathMatch: 'full' },
  { path: 'liquid-staking-platform-development/', component: LiquidStakingPlatformDevelopmentComponent },

  { path: 'on-chain-identity-solutions', redirectTo: '/on-chain-identity-solutions/', pathMatch: 'full' },
  { path: 'on-chain-identity-solutions/', component: OnChainIdentitySolutionsComponent },

  { path: 'base-token-development', redirectTo: '/base-token-development/', pathMatch: 'full' },
  { path: 'base-token-development/', component: BaseTokenComponent },

  { path: 'parallelized-evm-development', redirectTo: '/parallelized-evm-development/', pathMatch: 'full' },
  { path: 'parallelized-evm-development/', component: ParallelizedEvmComponent },

  { path: 'solana-meme-coin-development', redirectTo: '/solana-meme-coin-development/', pathMatch: 'full' },
  { path: 'solana-meme-coin-development/', component: SolanaMemeCoinDevelopmentComponent },

  { path: 'zk-tech-development-service', redirectTo: '/zk-tech-development-service/', pathMatch: 'full' },
  { path: 'zk-tech-development-service/', component: ZkTechDevelopmentServiceComponent },

  { path: 'cat-themed-coins-development', redirectTo: '/cat-themed-coins-development/', pathMatch: 'full' },
  { path: 'cat-themed-coins-development/', component: CatThemedCoinsComponent },

  { path: 'substrate-blockchain-development-company', redirectTo: '/substrate-blockchain-development-company/', pathMatch: 'full' },
  { path: 'substrate-blockchain-development-company/', component: SubstrateBlockchainComponent },

  { path: 'modular-blockchain-development-company', redirectTo: '/modular-blockchain-development-company/', pathMatch: 'full' },
  { path: 'modular-blockchain-development-company/', component: ModularBlockchainComponent },

  { path: 'top-blockchain-development-companies-in-us', redirectTo: '/top-blockchain-development-companies-in-us/', pathMatch: 'full' },
  { path: 'top-blockchain-development-companies-in-us/', component: TopBlockchainDevelopmentCompaniesInUsComponent },

  { path: 'op-stack-rollups-development', redirectTo: '/op-stack-rollups-development/', pathMatch: 'full' },
  { path: 'op-stack-rollups-development/', component: OpStackRollupsComponent },

  { path: 'polygon-zkevm-development-company', redirectTo: '/polygon-zkevm-development-company/', pathMatch: 'full' },
  { path: 'polygon-zkevm-development-company/', component: PolygonZkevmDevelopmentComponent },

  { path: 'coinstore-exchange-clone-script', redirectTo: '/coinstore-exchange-clone-script/', pathMatch: 'full' },
  { path: 'coinstore-exchange-clone-script/', component: CoinstoreExchangeCloneScriptComponent },

  { path: 'politifi-token-development', redirectTo: '/politifi-token-development/', pathMatch: 'full' },
  { path: 'politifi-token-development/', component: PolitifiTokenDevelopmentComponent },

  { path: 'polygon-cdk-development', redirectTo: '/polygon-cdk-development/', pathMatch: 'full' },
  { path: 'polygon-cdk-development/', component: PolygonCdkComponent },

  { path: 'automated-market-maker-development', redirectTo: '/automated-market-maker-development/', pathMatch: 'full' },
  { path: 'automated-market-maker-development/', component: AutomatedMarketMakerComponent },

  { path: 'mev-protection-development-solution', redirectTo: '/mev-protection-development-solution/', pathMatch: 'full' },
  { path: 'mev-protection-development-solution/', component: MevProtectionComponent },

  { path: 'fan-token-development', redirectTo: '/fan-token-development/', pathMatch: 'full' },
  { path: 'fan-token-development/', component: FanTokenComponent },

  { path: 'introduction-to-base-layer-2-blockchain-development', redirectTo: '/introduction-to-base-layer-2-blockchain-development/', pathMatch: 'full' },
  { path: 'introduction-to-base-layer-2-blockchain-development/', component: IntroductionToBaseLayerBlockchainDevelopmentComponent },

  { path: 'bitcoin-layer-2-development', redirectTo: '/bitcoin-layer-2-development/', pathMatch: 'full' },
  { path: 'bitcoin-layer-2-development/', component: BitcoinLayerDevelopmentComponent },

  { path: 'blockchain-using-abstraction-development', redirectTo: '/blockchain-using-abstraction-development/', pathMatch: 'full' },
  { path: 'blockchain-using-abstraction-development/', component: BlockchainAbstractionComponent },

  { path: 'parachain-development-company', redirectTo: '/parachain-development-company/', pathMatch: 'full' },
  { path: 'parachain-development-company/', component: ParachainDevelopmentCompanyComponent },

  { path: 'blockchain-iot-development', redirectTo: '/blockchain-iot-development/', pathMatch: 'full' },
  { path: 'blockchain-iot-development/', component: BlockchainIotComponent },

  { path: 'ordinals-nft-marketplace-development', redirectTo: '/ordinals-nft-marketplace-development/', pathMatch: 'full' },
  { path: 'ordinals-nft-marketplace-development/', component: OrdinalsNftComponent },

  { path: 'decentralized-social-app-development', redirectTo: '/decentralized-social-app-development/', pathMatch: 'full' },
  { path: 'decentralized-social-app-development/', component: DesocAppComponent },

  { path: 'layerzero-development', redirectTo: '/layerzero-development/', pathMatch: 'full' },
  { path: 'layerzero-development/', component: LayerZeroComponent },

  { path: 'liquid-restaking-token-development', redirectTo: '/liquid-restaking-token-development/', pathMatch: 'full' },
  { path: 'liquid-restaking-token-development/', component: LiquidRestakingGovernanceTokensComponent },

  { path: 'derivatives-development', redirectTo: '/derivatives-development/', pathMatch: 'full' },
  { path: 'derivatives-development/', component: DerivativesComponent },

  { path: 'blockchain-development-cost', redirectTo: '/blockchain-development-cost/', pathMatch: 'full' },
  { path: 'blockchain-development-cost/', component: BlockchainDevelopmentCostComponent },

  // { path: 'blockchain-development-company-usa', redirectTo: '/blockchain-development-company-usa/', pathMatch: 'full' },
  // { path: 'blockchain-development-company-usa/', component: BlockchainDevelopmentCompanyUsaComponent },

  { path: 'top-10-real-estate-tokenization-companies', redirectTo: '/top-10-real-estate-tokenization-companies/', pathMatch: 'full' },
  { path: 'top-10-real-estate-tokenization-companies/', component: TopRealEstateTokenizationCompaniesComponent },

  { path: 'eu-mica-regulations-unfolds-direction-segway', redirectTo: '/eu-mica-regulations-unfolds-direction-segway/', pathMatch: 'full' },
  { path: 'eu-mica-regulations-unfolds-direction-segway/', component: MicaRegulationComponent },
  { path: 'smart-contract-development', redirectTo: '/smart-contract-development/', pathMatch: 'full' },
  { path: 'smart-contract-development/', component: SmartContractDevelopmentComponent },
  { path: 'solana-blockchain-development', redirectTo: '/solana-blockchain-development/', pathMatch: 'full'},
  { path: 'solana-blockchain-development/', component: SolanaBlockchainDevelopmentComponent },

  { path: 'banner', redirectTo: '/banner/', pathMatch: 'full'},
  { path: 'banner/', component: BannerComponent },
  { path: 'luxury-items-tokenization-development', redirectTo: '/luxury-items-tokenization-development/', pathMatch: 'full'},
  { path: 'luxury-items-tokenization-development/', component: LuxuryItemsTokenizationDevelopmentComponent},

  { path: 'cross-chain-interoperability-protocol', redirectTo: '/cross-chain-interoperability-protocol/', pathMatch: 'full'},
  { path: 'cross-chain-interoperability-protocol/', component: CrossChainInteroperabilityProtocolComponent},
  
  { path: 'home-old', redirectTo: '/home-old/', pathMatch: 'full' },
  { path: 'home-old/', component: HomeComponent, data: { version: 'old' } },

  { path: 'home-new', redirectTo: '/home-new/', pathMatch: 'full' },
  { path: 'home-new/', component: HomeNewComponent, data: { version: 'new' } },

  { path: 'thank-you', redirectTo: '/thank-you/', pathMatch: 'full' },
  { path: 'thank-you/', component: ThankYouComponent, data: { version: 'new' } },

  ...(environment.enableBlockchainDevelopmentCompanyUae ?[
  { path: 'blockchain-development-company-uae', redirectTo: '/blockchain-development-company-uae/', pathMatch: 'full' },
  { path: 'blockchain-development-company-uae/', component: BlockchainDevelopmentCompanyUaeComponent, data: { version: 'new' } },
  ]: [] ),

  ...(environment.enableBlockchainDevelopmentCompanyInSpain ?[
  { path: 'blockchain-development-company-in-spain', redirectTo: '/blockchain-development-company-in-spain/', pathMatch: 'full' },
  { path: 'blockchain-development-company-in-spain/', component: BlockchainDevelopmentCompanyInSpainComponent, data: { version: 'new' } },
   ]: [] ),

   ...(environment.enableBlockchainDevelopmentCompanyFrance ?[
   { path: 'blockchain-development-company-france', redirectTo: '/Blockchain-development-company-france/', pathMatch: 'full' },
   { path: 'blockchain-development-company-france/', component: BlockchainDevelopmentCompanyFranceComponent, data: { version: 'new' } },
  ]: [] ),

  ...(environment.enableBlockchainDevelopmentCompanyUsa ?[
  { path: 'blockchain-development-company-usa', redirectTo: '/Blockchain-development-company-usa/', pathMatch: 'full' },
   { path: 'blockchain-development-company-usa/', component: BlockchainDevelopmentCompanyUsaComponent, data: { version: 'new' } },
  ]: [] ),
  
  {path: 'meme-coin-development', redirectTo: '/meme-coin-development/', pathMatch:'full'},
  {path: 'meme-coin-development/', component:MemeCoinDevelopmentComponent, data: { version: 'new' }},
  
  {path: 'pump-fun-clone-script', redirectTo: '/pump-fun-clone-script/', pathMatch:'full'},
  {path: 'pump-fun-clone-script/', component:PumpFunCloneScriptComponent, data: { version: 'new' }},

  {path: 'erc20-token-development-companies', redirectTo: '/erc20-token-development-companies/', pathMatch:'full'},
  {path: 'erc20-token-development-companies/', component:Erc20TokenDevelopmentCompaniesComponent, data: { version: 'new' }},

  {path: 'create-your-own-crypto-token', redirectTo:'/create-your-own-crypto-token/', pathMatch:'full'},
  {path: 'create-your-own-crypto-token/', component:CreateYourOwnCryptoTokenComponent, data: { version: 'new' }},

  {path: 'precious-metal-tokenization-guide', redirectTo:'/precious-metal-tokenization-guide/', pathMatch:'full'},
  {path: 'precious-metal-tokenization-guide/', component:PreciousMetalTokenizationComponent, data: { version: 'new' }},

  {path: 'launch-your-own-meme-coin', redirectTo:'/launch-your-own-meme-coin/', pathMatch:'full'},
  {path: 'launch-your-own-meme-coin/', component:LaunchYourMemeCoinComponent, data: { version: 'new' }},

  {path: 'create-solana-meme-coin', redirectTo:'/create-solana-meme-coin/', pathMatch:'full'},
  {path: 'create-solana-meme-coin/', component:CreateSolanaMemeCoinComponent, data: { version: 'new' }},

  {path: 'create-a-meme-coin', redirectTo:'/create-a-meme-coin/', pathMatch:'full'},
  {path: 'create-a-meme-coin/', component:CreateAMemeCoinComponent, data: { version: 'new' }},
  
  {path: 'token-development-services-list', redirectTo:'/token-development-services-list/', pathMatch:'full'},
  {path: 'token-development-services-list/', component:TokenDevelopmentServicesComponent, data: { version: 'new' }},

  {path: 'erc20-vs-trc20-vs-bep20', redirectTo:'/erc20-vs-trc20-vs-bep20/', pathMatch:'full'},
  {path: 'erc20-vs-trc20-vs-bep20/', component:Erc20VsTrc20VsBep20Component, data: { version: 'new' }},

  {path: 'asset-tokenization', redirectTo:'/asset-tokenization/', pathMatch:'full'},
  {path: 'asset-tokenization/', component:AssetTokenizationComponent, data: { version: 'new' }},

  {path: 'bitcoin-staking-protocol-development', redirectTo:'/bitcoin-staking-protocol-development/', pathMatch:'full'},
  {path: 'bitcoin-staking-protocol-development/', component:BitcoinStakingProtocolDevelopmentComponent, data: { version: 'new' }},

  {path: 'liquid-staking-protocol-development', redirectTo:'/liquid-staking-protocol-development/', pathMatch:'full'},
  {path: 'liquid-staking-protocol-development/', component:LiquidStakingProtocolDevelopmentComponent, data: { version: 'new' }},
  
  ...(environment.enableTestimonialsNewComponent ? [
  {path: 'testimonials-new', redirectTo:'/testimonials-new/', pathMatch:'full'},
  {path: 'testimonials-new/', component:TestimonialsNewComponent, data: { version: 'new' }},
  ]: [] ),

  ...(environment.enableRealestateTokenizationOnBlockchainComponent ? [
  {path: 'realestate-tokenization-on-blockchain', redirectTo:'/realestate-tokenization-on-blockchain/', pathMatch:'full'},
  {path: 'realestate-tokenization-on-blockchain/', component:RealestateTokenizationOnBlockchainComponent, data: { version: 'new' }},
  ]: [] ),

  {path: 'create-bep20-token', redirectTo:'/create-bep20-token/', pathMatch:'full'},
  {path: 'create-bep20-token/', component:CreateBep20TokenComponent, data: { version: 'new' }},

  ...(environment.enableOndoFinanceCloneScriptComponent ? [
  {path: 'ondo-finance-clone-script', redirectTo:'/ondo-finance-clone-script/', pathMatch:'full'},
  {path: 'ondo-finance-clone-script/', component:OndoFinanceCloneScriptComponent, data: { version: 'new' }},
  ]: [] ),

  ...(environment.enableBondTokenizationServicesComponent ? [
  {path: 'bond-tokenization-services', redirectTo:'/bond-tokenization-services/', pathMatch:'full'},
  {path: 'bond-tokenization-services/', component:BondTokenizationServicesComponent, data: { version: 'new' }},
  ]: [] ),

  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
