<section class="blog-detail">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 mb-4">
                <div class="blog-single-wrap">
                    <picture>
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.webp
                          "
                          type="image/webp"
                        />
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg
                          "
                          type="image/jpg"
                        />
                        <img
                          loading="lazy"
                          data-src="assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg"
                          alt="Private Blockchain Development"
                          title="Private Blockchain Development - Aiding Business"
                          width="840"
                          height="511"
                          class="img-fluid"
                        />
                      </picture>
                    <h1>Private Blockchain Development - Aiding Business</h1>
                    <p>
                        Blockchain dominating the entire digital ecosystem digital investments have emerged as a captivating option attracting the attention of technology savvy investors and curious newcomers alike. Building blockchains for data security, private blockchains is the right choice. They provide additional layers of protection for the data stored and meet the regulatory requirements. This can be the main driving factor behind many choosing to create private blockchains.
                    </p>

                    <p>
                        Additionally these blockchain network implementations give businesses complete control over who has to access the network. But with secure and scalable blockchain network great promise, big responsibility and the growing popularity of digital investments has earned significant security concerns. It's safe to say that managing digital assets and protecting your hard earned money is one's top priority. If so, the hero of this story – is the private blockchain.
                    </p>

                    <p>
                        Without much further ado let's deep dive into the vast topic of what and how to create your white labeled private blockchain to enhance the security of your business.
                    </p>

                    <h2 id="item-1"
                        [ngClass]="selectedLink===1?'scrollHeading':'scrollheadingone'">What is a Private Blockchain?</h2>

                    <p>
                        It is a decentralized and distributed digital ledger that operates within a controlled ecosystem and is accessible only to trusted participants - public blockchain being very open to all this private blockchain is a more exclusive and secure environment. This is perfect for businesses and organizations seeking confidentiality and control over their data.
                    </p>

                    <picture>
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-1.webp
                          "
                          type="image/webp"
                        />
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-1.jpg
                          "
                          type="image/jpg"
                        />
                        <img
                          loading="lazy"
                          data-src="assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-1.jpg"
                          class="img-fluid" alt="What is a Private Blockchain?"
                          title="What is a Private Blockchain?"
                          width="840"
                          height="834"
                          class="img-fluid"
                        />
                      </picture>

                    <p>
                        For example financial institutions use this technology to enable secure and efficient cross border transactions between authorized parties, improving operational efficiency while ensuring regulations.
                    </p>

                    <p>
                        For companies these blockchains permit the network to be confidential and all the participants must be given detailed permission to contribute. This access control protects sensitive details from unauthorized entry and external handling data leading to malware techniques and thefts.
                    </p>

                    <h2 id="item-2"
                    [ngClass]="selectedLink===2?'scrollHeading':'scrollheadingone'">Working on private Blockchain</h2>

                    <p>
                        Similar to public blockchains private blockchains operate as a decentralized and distributed digital ledger. However they are restricted to a specific grouping of permitted participants and not openly available for the public. Only a certain set of participants are permitted to access and contribute to the network.
                    </p>

                    <p>
                        This network for businesses use consensus algorithms. They ensure agreement between authorized nodes regarding the validity and order of transactions.  This results in efficient and effective consensus processes. This leads to faster transaction confirmation times and data processing which is quicker when compared to public blockchains.
                    </p>

                    <p>
                        This blockchain uses an access control mechanism to limit participation in the consensus process of the trusted entities. This system validates transactions and adds them to the blockchain thereby increasing overall security and privacy. This is particularly suited to industries that needs to protect sensitive data from public access. Offering a permissioned network, faster transaction processing and customizable governance, enables enterprises in a variety of industries to leverage distributed ledger technology while protecting sensitive information.
                    </p>

                    <div class="discus-detail p-md-3 p-2 text-center mb-sm-4 mb-2">
                        <h3 class="common-sub-heading-alt pb-0">
                            Too many thoughts about where to start your Private blockchain development?  We are here to help you,
                        </h3>
                
                        <div class="discuss-link mb-4">
                          <a [routerLink]="['/contact']">
                            <span class="pe-0">Connect now!</span>
                            <!-- <img
                                    src="assets-new/images/right-arrow.png"
                                    alt="link-arrow"
                                    class="img-fluid mb-0"
                                    width="50"
                                    height="15"
                                    *ngIf="!isDarkmode"
                                  />
                                  <img
                                    src="assets-new/images/right-arrow-dark.png"
                                    alt="link-arrow"
                                    class="img-fluid mb-0"
                                    width="50"
                                    height="15"
                                    *ngIf="isDarkmode"
                                  /> -->
                          </a>
                        </div>
                      </div>

                      <h2 id="item-3"
                        [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">Benefits of Implementing Private Blockchain For Business
                    </h2>
                    <p>
                        Businesses can improve data security, privacy and collaboration while facilitating scalability through Private blockchain. Let's take a look at the various benefits of this technology for businesses:
                    </p>
                    <picture>
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-2.webp
                          "
                          type="image/webp"
                        />
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-2.jpg
                          "
                          type="image/jpg"
                        />
                        <img
                          loading="lazy"
                          data-src="assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-2.jpg"
                          alt="Benefits Private Blockchain"
                          title="Benefits of Implementing Private Blockchain"
                          width="840"
                          height="487"
                          class="img-fluid"
                        />
                      </picture>

                    <div class="h4">1. In depth Security</div>
                    <p>
                        This blockchain network provides better security than public blockchains by limiting participation to authorized entities thereby reducing the risk of unauthorized access, data manipulation and malicious activity. These blockchains use permitted networks that subject participants to verification and validation thereby establishing a high level of trust within the network. These are ideal solutions for protecting sensitive information given the use of various cryptographic techniques such as digital signatures capable of ensuring data integrity.
                    </p>

                    <div class="h4">2. Strong Performance and Cost Optimization</div>
                    <p>
                        One of the most sought after benefits of this innovative technology is cost savings. They streamline business processes by clearing intermediates and automating belief. It speeds up transactions, reduces errors and minimizes delays. It helps in optimizing cost by cutting down on middle man fees and time consuming processes.
                    </p>

                    <div class="h4">3. Improved Transparency</div>
                    <p>
                        A shared ledger is created by this network and is not transferable. Also transactions are time stamped and connected to make an unchangeable log. This clarity assures that everyone on the network has the exact details decreasing the chance of conflicts. In industries such as healthcare and finance this technology makes regulatory compliance and auditability easier and increases trust and ensuring compliance.
                    </p>

                    <div class="h4">4. Improves Data Privacy</div>
                    <p>
                        One of the most sought after advantages of private blockchain development is improved data privacy. Businesses present particular exposure permitting companies to choose what details to communicate with distinct stakeholders while preserving confidentiality - this level of privacy assures that sensitive details are just communicated with official parties decreasing the chance of unauthorized entry or data violations. This permits organizations to mitigate concerns about data abuse and ensure keeping with privacy rules such as GDPR and HIPAA.
                    </p>

                    <div class="h4">5. Seamless Collaboration</div>
                    <p>
                        This technology enables secured and efficient conspiracy by presenting the corps with a trusted and transferred infrastructure for data relations. They allow businesses to create a secure network of trusted partners that eliminates the need for intermediaries. Also real-time tracking of transactions builds trust and improves collaboration between partners.
                    </p>

                    <div class="h4">6. High Level of Scalability and Customization</div>

                    <p>
                        It provides scalability and flexibility to meet the expanding needs of businesses - these blockchains are optimized to handle high transaction volumes ensuring that business performance is not compromised. Companies can customize and implement smart contracts according to their operations, improving operational efficiency. Also seamless integration with living techniques enables a smooth evolution to blockchain technology without disrupting continued procedures.
                    </p>

                        <h2 id="item-4"
                        [ngClass]="selectedLink===4?'scrollHeading':'scrollheadingone'">The Core Logics To Create Private Blockchain
                    </h2>

                    <p>
                        Creating a private blockchain is a sure thing that allows businesses and organizations to secure and efficiently manage their private data and transactions. Below is the guide to build on-demand private blockchain for your business.
                    </p>

                    <picture>
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-4.webp
                          "
                          type="image/webp"
                        />
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-4.jpg
                          "
                          type="image/jpg"
                        />
                        <img
                          loading="lazy"
                          data-src="assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-4.jpg"
                          alt="Build Private Blockchain"
                          title="The Core Logics To Create Private Blockchain"
                          width="840"
                          height="374"
                          class="img-fluid"
                        />
                    </picture>

                    <div class="h4">Illustrate the Purpose</div>
                    <p>
                        This stage of private blockchain development involves determining the specific use case and goals. This allows the development team to tailor the blockchain solution to your unique business requirements and effectively understand the challenges of the intended application.
                    </p>

                    <div class="h4">Choose the Consensus Algorithm</div>
                    <p>
                        As private blockchain developers choose a consensus algorithm that fits your needs such as Proof of Authority (PoA), Practical Byzantine Fault Tolerance  - PBFT), or Delegated Proof of Stake - DPoS.
                    </p>

                    <div class="h4">Build the Network</div>
                    <p>
                        A permissioned network is established at this stage with authorized participants serving as nodes and validating transactions.
                    </p>

                    <div class="h4">Choose the Blockchain platform</div>
                    <p>
                        To create a private blockchain solution choosing a suitable blockchain platform is important which offers the desired features that are going to align with your business needs.
                    </p>

                    <div class="h4">Develop the Smart Contracts</div>
                    <p>
                        After choosing the desired blockchain platform it is now time to create & deploy smart contracts. These automate the tasks and processes inside the private network.
                    </p>

                    <div class="h4">Execute Data Encryption and Test the Blockchain</div>
                    <p>
                        Strong encryption is needed to protect data in transit at rest as well as ensure information integrity and privacy.
                    </p>

                    <div class="h4">Deploy and Maintain the Network</div>
                    <p>
                        During this phase of private blockchain development the network will be deployed and protocols will be established for ongoing maintenance, updates and backup procedures.
                    </p>

                    <h2 id="item-5"
                    [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">Best Practices to Include When Creating a Private Blockchain</h2>
                    <p>
                        For a private blockchain to work successfully it has to follow certain rules. Let us analyze what they are:
                    </p>

                    <picture>
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-6.webp
                          "
                          type="image/webp"
                        />
                        <source
                          srcset="
                          assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-6.jpg
                          "
                          type="image/jpg"
                        />
                        <img
                          loading="lazy"
                          data-src="assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-6.jpg"
                          class="img-fluid" alt="BWhen Creating a Private Blockchain"
                          title="The Core Logics To Create Private Blockchain"
                          width="840"
                          height="654"
                        />
                    </picture>

                    <div class="h4">Select a consensus algorithm</div>
                    <p>
                        When you choose an algorithm for a node contract it's important to choose with top care based on your  business needs.
                    </p>

                    <div class="h4">Put to use</div>
                    <p>
                        One of the good practices for creating a private blockchain is to use a permissioned network. To increase security and scalability. It is important to ensure that only permissioned people with the correct credentials can participate in the network.
                    </p>

                    <div class="h4">Strong encryption</div>
                    <p>
                        To protect your blockchain from hackers use strong encryption techniques for data in transit and at rest. Also follow secure key management protocols.
                    </p>

                    <div class="h4">Network resilience</div>
                    <p>
                        We need to establish a strong network infrastructure that can handle potential problems. This includes implementing a well defined disaster recovery plan and performing regular backups and protocols to deal with possible attacks.
                    </p>

                    <div class="h4">Employ smart contracts</div>
                    <p>
                        Self executing smart contracts can be implemented to automate the execution of contracts and provide security and integrity to transactions.
                    </p>

                    <div class="h4">Verify compliance</div>
                    <p>
                        To maintain legal compliance for creating a private blockchain it is important to employ relevant laws and regulations. This includes anti money laundering and know your customer requirements.
                    </p>

                    <div class="h4">Constantly test and audit</div>
                    <p>
                        Periodic tests and audits are necessary to provide the correct functioning and protection of the blockchain. It is advised to complete penetration testing, vulnerability scanning and code audits before performance.
                    </p>

                    <div class="h4">Associate with a trusted blockchain development firm</div>
                    <p>
                        Consider partnering with a <a [routerLink]="['/']">blockchain development company</a> like BlockchainX that delivers private blockchain development and has good experience structuring and deploying many successful assignments. Our tech experts can make a private blockchain while managing and customizing all the challenges to fulfill your business needs.
                    </p>

                    <h2 id="item-6"
                        [ngClass]="selectedLink===6?'scrollHeading':'scrollheadingone'">Here are Some Leading Examples In Private Blockchains</h2>

                        <p>
                            As blockchain technology grows, many industries will explore and create a private blockchain to meet specific business needs and improve efficiency and security. This will enable businesses to improve data sharing and improve stakeholder trust.
                        </p>
                        <picture>
                            <source
                              srcset="
                              assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-3.webp
                              "
                              type="image/webp"
                            />
                            <source
                              srcset="
                              assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-3.jpg
                              "
                              type="image/jpg"
                            />
                            <img
                              loading="lazy"
                              data-src="assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-3.jpg"
                              class="img-fluid" alt="Leading Private Blockchain"
                              title="Leading Examples In Private Blockchains"
                              width="840"
                              height="275"
                            />
                        </picture>
                        <p>
                            Before we talk about how to create a private blockchain let's look at some examples and their applications:
                        </p>

                        <div class="h4">Hyperledger Fabric</div>
                        <p>
                            <a [routerLink]="['/hyperledger-blockchain-development-services']">Hyperledger Fabric</a> is a leading example of a private blockchain framework used as a foundation for developing blockchain based applications or networks. It comes with advanced productivity tools for building, managing and running blockchain solutions. This platform enables reliable data transfer, exchange and workflow automation behind the perimeter of distributed ledger technology and blockchain.
                        </p>

                        <div class="h4">Corda</div>
                        <p>
                            <a [routerLink]="['/corda-blockchain-development-services']">Corda</a> allows the building of interoperability blockchain networks that transact in controlled privacy. It also allows businesses to develop shared IT solutions using highly secure JVM based technologies. It distributes data only to those who need it, i.e. authorized users, instead of displaying it globally. Corda provides transparent models that share only relevant transaction objects with users who need to know about the transaction.
                        </p>

                        <div class="h4">Quorum</div>
                        <p>
                            Information about the financial sector is managed by multiple organizations, which makes them suffer from a lack of transparency, information control and security. Quorum is a system that provides private control over the blockchain through automation that can be personalized according to needs.
                        </p>
                        <p>
                            This private blockchain platform is faster than Bitcoin and Ethereum and can handle more than 150 transactions per second. Many large businesses use Quorum to eliminate transaction pricing, better privacy and better asset management.
                        </p>

                          <h2 id="item-7"
                          [ngClass]="selectedLink===7?'scrollHeading':'scrollheadingone'">Use Cases of Private Blockchain</h2>
                          <picture>
                            <source
                              srcset="
                              assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-5.webp
                              "
                              type="image/webp"
                            />
                            <source
                              srcset="
                              assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-5.jpg
                              "
                              type="image/jpg"
                            />
                            <img
                              loading="lazy"
                              data-src="assets-new/images/blog/how-to-create-a-private-blockchain/inside-image-5.jpg"
                              class="img-fluid" alt="Use Cases of Private Blockchain"
                              title="Use Cases of Private Blockchain"
                              width="840"
                              height="333"
                            />
                        </picture>

                          <div class="h4">1. Supply Chain Management</div>
                          <p>
                            One of the most sought after private blockchain applications is their potential to revolutionize supply chain operations for businesses. They provide a secure and transparent platform that effectively reduces incidents of fraud and counterfeit products and ensures the traceability of goods.
                          </p>

                          <div class="h4">2. Financial Services</div>
                          <p>
                            Private blockchains can streamline cross-border payments, facilitate secure data sharing between financial institutions and speed up the settlement process.
                          </p>

                          <div class="h4">3. Healthcare Sector</div>
                          <p>
                            Private blockchains contribute in securely sharing patient data thereby improving interoperability and ensuring compliance with all the data privacy regulations.
                          </p>

                          <div class="h4">4. Intellectual Property Rights</div>
                          <p>
                            Private blockchains play a critical role in protecting your copyrights and patents by providing a secure ownership and licensing management platform for you.
                          </p>

                          <div class="h4">5. Identity Management</div>
                          <p>
                            Identity management is one of the popular private <a [routerLink]="['/dapps-development']">blockchain applications</a> that have helped businesses streamline their behavior. Private blockchains pave the way for secure digital identity verification that can reduce identity theft and fraud in online transactions.
                          </p>

                          <div class="h4">6. Real Estate</div>
                          <p>
                            In real estate private blockchains can increase property transaction transparency and streamline transfers that can mitigate fraud and ensure efficient property management.
                          </p>

                          <div class="h4">7. Insurance</div>
                          <p>
                            In the insurance sector private blockchains facilitate automated claims processing, improve data accuracy and detect fraud.
                          </p>

                          <div class="discus-detail p-md-3 p-2 text-center mb-sm-4 mb-2">
                            <h3 class="common-sub-heading-alt pb-0">
                                A secured and safe private blockchain network, just a call away from you
                            </h3>                   
                            <div class="discuss-link mb-4">
                              <a [routerLink]="['/contact']">
                                <span class="pe-0">Book Now to Connect!</span>
                              </a>
                            </div>
                          </div>

                          <h2 id="item-8"
                        [ngClass]="selectedLink===8?'scrollHeading':'scrollheadingone'">Conclusion:</h2>
                          <p>
                            The main effect of blockchain is that it prioritizes enabling trust between the user and the business. Private blockchain development offers heaps of benefits for businesses. They improve security, efficiency and data privacy while facilitating collaboration and scalability. From protecting sensitive information to increasing transparency, private blockchains are helping companies to skyrocket in the digital age and achieve new milestones.
                          </p>
                          <p>
                            Blockchain is best suited for applications that require a "source of privacy and truth" because it is tamper proof due to private blockchain uses. To create a private blockchain is a great solution for companies to achieve data privacy and control the sharing of crucial information when collaborating with external business partners.
                          </p>
                          <p>
                            Assess your business needs with a private blockchain that may be right for you to achieve your goals and stay ahead of the competition. When there are many parties involved private blockchain platforms work best. Private blockchain is still a developing technology with no restrictions. If you don't want to learn everything then go to a private blockchain development company and develop your unique one.
                          </p>
   
                </div>

                <app-social></app-social>

            </div>
            <div class="col-lg-4 col-md-12">
                <!-- <app-blog-sidebar></app-blog-sidebar> -->
                <div class="blog-right-side table-content">
                    <div class="table-content-main">
                        <div class="metaverse_heading">
                            <h4 class="pb-0"><span>Table of Contents</span></h4>
                        </div>
                        <div class="categories_list_view table-contens">
                            <ul>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-1"
                                        (click)="addClass(1)">What is a Private Blockchain?</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-2"
                                        (click)="addClass(2)">Working on private Blockchain</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-3"
                                        (click)="addClass(3)">Benefits of Implementing Private Blockchain For Business</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-4"
                                        (click)="addClass(4)">The Core Logics To Create Private Blockchain</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-5"
                                        (click)="addClass(5)">Best Practices to Include When Creating a Private Blockchain</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-6"
                                        (click)="addClass(6)">Here are Some Leading Examples In Private Blockchains</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-7"
                                        (click)="addClass(7)">Use Cases of Private Blockchain</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/#item-8"
                                        (click)="addClass(8)">Conclusion</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-author-wrap">
                        <div class="blog-author-detail">
                          <div>
                            <img
                              src="assets-new/images/blog/yokesh-shankar.svg"
                              alt="blog author"
                              width="150"
                              height="150"
                            />
                          </div>
                          <div>
                            <div class="metaverse_heading">
                              <h4><span>About Author</span></h4>
                            </div>
                            <h5>Yokesh Sankar</h5>
                            <div class="author-social-connect">
                              <ul>
                                <li>
                                  <a
                                    href="https://www.instagram.com/yokesh.sankar/"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="instagram"
                                    aria-label="Instagram"><i class="bi bi-instagram"></i></a>
                                </li>
                                <li>
                                  <a
                                    href="https://in.linkedin.com/in/yokeshsankar"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="Linkedin"
                                    aria-label="Linkedin"><i class="bi bi-linkedin"></i></a>
                                </li>
                                <li>
                                  <a
                                    href="https://www.facebook.com/yokesh.shankar"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="facebook"
                                    aria-label="facebook"><i class="bi bi-facebook"></i></a>
                                </li>
                                <li>
                                  <a
                                    href="https://twitter.com/yokesh_sankar_v"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="Twitter"
                                    aria-label="twitter">
                                    <i class="bi bi-twitter-x"></i>
                                  </a>
                                </li>
                  
                              </ul>
                            </div>
                          </div>
                        </div>
                        <p>
                          I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time to
                          offer the best products for our clients without apprehensions at
                          economic rates.
                        </p>
                      </div>
                      <div class="social-shares">
                        <div>
                          <a href="https://twitter.com/Blockchainxtech" target="_blank"
                                  rel="noopener noreferrer"
                            >Follow us on Twitter
                            <span class="mx-2 cyan-300"
                              ><i class="bi bi-twitter-x color cyan-300"></i></span
                          ></a>
                        </div>
                      </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                <app-comments></app-comments>
            </div>
        </div>
    </div>
</section>
<app-blog></app-blog>
<section class="section-service-alt-main">
    <app-contactform></app-contactform>
    </section>