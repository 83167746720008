<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Hashgraph Development </strong> Services</span>
              </h1>

              <p>
                Integrate your project with blockchain network through a trusted
                blockchain consulting service. Leverage and taste the
                fruitfulness of blockchain technology.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a
                    href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                rel="noopener noreferrer"
                  >
                    <span>Telegram</span>
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/hashgraph/hashgraph-development-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/hashgraph/hashgraph-development-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/hashgraph-development-services.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Hashgraph-Development-Services"
              />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png"
            />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!--What is Hashgraph? start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row reverse dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/what-is-hashgraph.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/what-is-hashgraph.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/what-is-hashgraph.png"
              width="400"
              height="400"
              class="img-fluid"
              alt="What-is-Hashgraph"
            />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content"
      >
        <div class="common-sub-heading">
          <h3 class="main-sub-heading text-start">What is Hashgraph?</h3>
          <p>
            BlockchainX has a team of expert consultants who deliver the
            best-customized blockchain consulting services. We guide you in
            analyzing your project-specific requirement and consult you on a
            best-fit blockchain application for your project that enables you to
            achieve your business success. As the best blockchain consulting
            service provider, we help you in every way to reap the full benefits
            of blockchain technology that can make your business successful and
            lead in the tech world.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- What is Hashgraph? end -->

<!-- Product Service Page -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Why <span>Hedera Hashgraph?</span></h3>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/fairness.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/fairness.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/fairness.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Fairness"
            />
          </picture>
          <h4 class="erc-20-head">Fairness</h4>
          <p class="text-center">
            You can deploy your token easily with our ERC20 token generator by
            connecting your Metamask web3 wallet. Simply install the browser
            plugin from chrome or firefox.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/security.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/security.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/security.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Security"
            />
          </picture>
          <h4 class="erc-20-head">Security</h4>
          <p class="text-center">
            Add the token name, symbol, total supply, decimals, and you’re done!
            You can now deploy your very own token using our ERC20 token
            generator.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/performance.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/performance.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/performance.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Performance"
            />
          </picture>
          <h4 class="erc-20-head">Performance</h4>
          <p class="text-center">
            Now, all you need to do is hit deploy, confirm the transaction on
            your Metamask wallet and pay the gas fees. Your token is now on the
            blockchain.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/uniqueness.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/uniqueness.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/uniqueness.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Uniqueness"
            />
          </picture>
          <h4 class="erc-20-head">Uniqueness</h4>
          <p class="text-center">
            Now, all you need to do is hit deploy, confirm the transaction on
            your Metamask wallet and pay the gas fees. Your token is now on the
            blockchain.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Product Features -->
<div class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Hedera <span>Hashgraph Development</span> Services</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/hashgraph/custom-hedera-hashgraph-app-development.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/hashgraph/custom-hedera-hashgraph-app-development.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/custom-hedera-hashgraph-app-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Custom Hedera Hashgraph App Development"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Custom Hedera Hashgraph App Development
          </h4>
          <p>
            Our ERC20 token generator service gives you access to expert
            Solidity developers. ERC20 is the global standard for tokens in
            blockchain, and compatible with all EVM chains.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/hashgraph/hedera-hashgraph-app-consulting.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/hashgraph/hedera-hashgraph-app-consulting.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/hedera-hashgraph-app-consulting.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Hashgraph app consulting"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Hedera Hashgraph App Consulting
          </h4>
          <p>
            ERC721 is a Non-fungible Token standard and is used across multiple
            EVM-based blockchains. Create your own NFTs with our Ethereum token
            development service.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/hashgraph/user-experience-and-technical-design.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/hashgraph/user-experience-and-technical-design.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/user-experience-and-technical-design.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="User Experience"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            User Experience and Technical Design
          </h4>
          <p>
            ERC777 ERC20 token development can handle most requirements but if
            your project has complex functions, then ERC777 is the best option.
            This standard is completely compatible.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/hashgraph/hedera-hashgraph-platform-integration.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/hashgraph/hedera-hashgraph-platform-integration.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/hedera-hashgraph-platform-integration.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Hedera Hashgraph Platform Integration"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Hedera Hashgraph Platform Integration
          </h4>
          <p>
            ERC223 focuses on security and is suitable for complex projects. It
            implements features such as transaction security such as; when
            sending tokens to invalid addresses,
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/hashgraph/smart-contracts-development.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/hashgraph/smart-contracts-development.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/smart-contracts-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Smart Contracts Development"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Smart Contracts Development</h4>
          <p>
            ERC1155 brings semi-fungibility to the NFT game. You can now create
            your own NFT collections using a single smart contract. A flexible
            solution for specialized projects.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/hashgraph/tokenized-assets.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/hashgraph/tokenized-assets.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/hashgraph/tokenized-assets.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Tokenized Assets"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Tokenized Assets</h4>
          <p>
            ERC827 is one of the latest upgrades to ERC20 and implements many
            improvements. One of them being that you can now transact not only
            values but also data.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Features -->

<!-- Hashgraph Features -->
<div class="section-service-alt-main">
  <div class="container">
    <div class="row mb-5 hash-feature-one">
      <div class="metaverse_heading mx-auto">
        <h3><span>Hashgraph</span> Features</h3>
      </div>
    </div>
    <div class="row align-items-center hash-feature">
      <div class="col-lg-4 metmb-3">
        <div class="virtual_process cmb-5">
          <h4>Better security</h4>
          <p>
            The users can create a virtual version of themselves, a replica that
            can be creative and customized, however.
          </p>
        </div>
        <div class="virtual_process">
          <h4>Faster transactions</h4>
          <p>
            The platform supports tokenizing unique assets from art collectibles
            to real estate for users to act & monetize.
          </p>
        </div>
      </div>
      <div class="col-lg-4 metmb-3">
        <div class="our_dev_process text-center" id="development-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/hashgraph-features.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/hashgraph-features.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/hashgraph-features.png"
              width="275"
              height="275"
              class="img-fluid"
              alt="development"
            />
          </picture>
        </div>
      </div>
      <div class="col-lg-4 virual-list">
        <div class="virtual_process cmb-5">
          <h4>Gossip Protocol</h4>
          <p>
            Connecting NFT wallets in platforms support quick, safe, and secured
            transactions and safe storage.
          </p>
        </div>
        <div class="virtual_process">
          <h4>Virtual voting</h4>
          <p>
            Players can build and sell in-game assets in metaverse marketplaces
            bringing name, fame, and returns.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  Hashgraph Features  -->

<!-- Pros and Cons with Hashgraph Cryptocurrency -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Pros and Cons with <span>Hashgraph Cryptocurrency</span></h3>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/fast.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/fast.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/fast.png"
              width="150"
              height="150"
              class="img-fluid"
              alt="Fast"
            />
          </picture>
          <h4 class="erc-20-head">Fast</h4>
          <p class="text-center">
            You can deploy your token easily with our ERC20 token generator by
            connecting your Metamask web3 wallet. Simply install the browser
            plugin from chrome or firefox.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/fair.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/fair.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/fair.png"
              width="150"
              height="150"
              class="img-fluid"
              alt="Fair"
            />
          </picture>
          <h4 class="erc-20-head">Fair</h4>
          <p class="text-center">
            Add the token name, symbol, total supply, decimals, and you’re done!
            You can now deploy your very own token using our ERC20 token
            generator.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/hashgraph/secure.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/hashgraph/secure.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/hashgraph/secure.png"
              width="150"
              height="150"
              class="img-fluid"
              alt="Secure"
            />
          </picture>
          <h4 class="erc-20-head">Secure</h4>
          <p class="text-center">
            Now, all you need to do is hit deploy, confirm the transaction on
            your Metamask wallet and pay the gas fees. Your token is now on the
            blockchain.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section-service-main">
<app-blog></app-blog>
</section>

<section class="section-service-alt-padd">
  <app-contactform></app-contactform>
  </section>
