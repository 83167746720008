import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-blockchain-based-land-registry-system',
  templateUrl: './blockchain-based-land-registry-system.component.html',
  styleUrls: ['./blockchain-based-land-registry-system.component.css'],
})
export class BlockchainBasedLandRegistrySystemComponent implements OnInit {
  title = 'Blockchain For Land Registration - A Conceptional Framework';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'The boom in blockchain technology has become an eminent solution to prevent land registry corruption and manipulation. This immutable digital ledger is a secure way to digitalize land ownership and goes beyond protecting your property rights. It allows you to borrow, repay and sell your land assets if you need to raise capital to increase your financial opportunities.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'blockchain-based land registry system, blockchain-based land registry, blockchain-based land registration system, blockchain for land registration, blockchain-based land registration',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Blockchain For Land Registration - A Conceptional Framework',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/blockchain-based-land-registry-system/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'The boom in blockchain technology has become an eminent solution to prevent land registry corruption and manipulation. This immutable digital ledger is a secure way to digitalize land ownership and goes beyond protecting your property rights. It allows you to borrow, repay and sell your land assets if you need to raise capital to increase your financial opportunities.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/blockchain-based-land-registry-system/blockchain-based-land-registry.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/blockchain-based-land-registry-system/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Blockchain For Land Registration - A Conceptional Framework',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'The boom in blockchain technology has become an eminent solution to prevent land registry corruption and manipulation. This immutable digital ledger is a secure way to digitalize land ownership and goes beyond protecting your property rights. It allows you to borrow, repay and sell your land assets if you need to raise capital to increase your financial opportunities.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/blockchain-based-land-registry-system/blockchain-based-land-registry.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/blockchain-based-land-registry-system/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/blockchain-based-land-registry-system.html'
    );
    this.doc.head.appendChild(link);
  }
}
