<section>
  <div class="section-bep-work">
    <div class="container">
      <div class="row">
        <h1>
          <span>PRIVACY POLICY</span>
        </h1>
        <div class="col-12 about-content">
          <div class="com-sub-heading">
            <h4>Information On The Website</h4>
            <p>
              <strong>BlockchainX</strong> respects your privacy and is
              committed to protecting the information you provide us through
              this website. We shall not sell or distribute user information to
              third parties unless it is legally required. We gather user
              information in order to serve your needs and respond to your
              requests.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>Data Security Compliance</h4>
            <p>
              The security and privacy of your data is a crucial part of
              <strong>BlockchainX’s </strong> business. This statement provides
              our clients, the assurance and our commitment to information &
              data security compliance. We respect your online privacy and
              recognise your need for appropriate protection and management of
              any personally identifiable information (Personal Information) you
              share with us. blockchainX focuses on continual improvement of its
              privacy policy.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>User Information</h4>
            <p>
              We collect information about our web visitors indirectly through
              our Internet access logs. When you access blockchainX, the
              browser’s domain name and Internet address is automatically
              collected and placed in our Internet access logs. We use this
              information to learn about which sections, pages and information
              website visitor’s access.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>Personal Information</h4>
            <p>
              Personal Information means any information that may be used to
              identify an individual, including, but not limited to, a first and
              last name, a home or other physical address and an email address
              or other contact information, whether at work or at home. In
              general, you can visit blockchainX web pages without telling us
              who you are or revealing any Personal Information about yourself.
            </p>
            <p>
              If you choose to provide us with your Personal Information on the
              web, we may transfer that Information within blockchainX or to
              thirdparty service providers, across borders, and from your
              country or jurisdiction to other countries or jurisdictions around
              the world. <strong>BlockchainX</strong> strives to comply with all
              applicable laws around the globe that are designed to protect your
              privacy. Although legal requirements may vary from country to
              country, blockchainX intends to adhere to the principles set forth
              in this Online Privacy Policy even if, in connection with the
              above, we transfer your Personal Information from your country to
              countries that may not require an "adequate" level of protection
              for your Personal Information. In other words, our goal is to
              provide protection for your Personal Information no matter where
              that Personal Information is collected, transferred, or retained.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>Cookies</h4>
            <p>
              <strong>BlockchainX </strong> website may use cookies to assist in
              producing overall site visitor statistics. Cookies, by themselves,
              cannot be used to find out the identity of any user unless they
              specifically tell us who they are. If you wish to, you can disable
              cookies on your computer by changing the settings in preferences
              or options menu in your browser.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>Disclosure Of User Information</h4>
            <p>
              <strong>BlockchainX</strong> does not rent, sell, or share
              personal information about you with non-affiliated party or
              companies. If you have submitted user information to us through an
              e-mail, blockchainX maintains your security by ensuring that the
              information is only distributed within our team members who are
              all responsible for responding to your requests either directly or
              indirectly.
            </p>
            <p>
              <strong
                >We may disclose information in the following circumstances
              </strong>
            </p>

            <p>
              1. We provide the information to trusted partners who work on
              behalf of or with blockchainX under extremely strict
              confidentiality agreements.
            </p>
            <p>
              2. We respond to subpoenas, court orders, or legal process, or to
              establish or exercise our legal rights or defend against legal
              claims.
            </p>
            <p>
              3. We believe it is necessary to share information in order to
              investigate, prevent, or take action regarding illegal activities,
              suspected fraud, situations involving potential threats to the
              physical safety of any person, or as otherwise required by law.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>Links To Third Party Sites</h4>
            <p>
              <strong>BlockchainX</strong> website may provide links to other
              third party websites. We are not responsible for the contents or
              policies of these websites. These links are provided to you for
              convenience purposes only and blockchainX is not liable for
              inaccuracy of any information on such sites. These sites may send
              their own cookies to users, or otherwise collect data or solicit
              personal information. If the <strong>BlockchainX </strong> website
              links you to any third-party website, we make no representation as
              to the existence, sufficiency, accuracy or completeness of their
              Privacy Policy.
            </p>
          </div>

          <div class="com-sub-heading">
            <h4>Intellectual Property</h4>
            <p>
              The products, technology and services described in this site may
              be the subject of intellectual property rights reserved by
              blockchainX or other third parties. Nothing contained herein shall
              be construed as conferring to you in any manner, whether by
              implication, estoppel or otherwise, any license, title or
              ownership of or to any intellectual property right of
              <strong>BlockchainX</strong> or any third party
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
