import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute,Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-agriculture',
  templateUrl: './agriculture.component.html',
  styleUrls: ['./agriculture.component.css'],
})
export class AgricultureComponent implements OnInit {
  selectedLink:number;

  title = 'Blockchain in Agriculture - BlockchainX';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        "Blockchain in agriculture has various advantages in terms of food safety, traceability, supply chain, and logistics. BlockchainX's blockchain agriculture supply chain solution bridges the gap.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Blockchain in agriculture , blockchain agriculture supply chain',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Blockchain in Agriculture - BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/blockchain-applications-agriculture/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Blockchain in agriculture has various advantages in terms of food safety, traceability, supply chain, and logistics. BlockchainX's blockchain agriculture supply chain solution bridges the gap.",
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/industries/blockchain-in-agriculture.png',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/blockchain-applications-agriculture/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Blockchain in Agriculture - BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        "Blockchain in agriculture has various advantages in terms of food safety, traceability, supply chain, and logistics. BlockchainX's blockchain agriculture supply chain solution bridges the gap.",
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/industries/blockchain-in-agriculture.png',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/blockchain-applications-agriculture/'
    );
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
