<div class="case-inner-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 col-12">
        <div class="case-main-banner">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/case/zuki/zuki.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/case/zuki/zuki.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/case/zuki/zuki.png"
              class="img-fluid"
              width="840"
              height="504"
              alt="zuki-company"
            />
          </picture>
        </div>
        <div class="case-study-content">
          <h4>Blockchain Ecosystem</h4>
          <div class="metaverse_subheading">
            <h3>
              Zuki - The Game Show In Snow Shooting Its Players To Stardom With
              Awesome Gains
            </h3>
          </div>

          <div class="case-study-main-content">
            <p class="pb-0">
              Zuki is a P2E gaming platform built on a decentralized economy
              application and is community-oriented. Building characters,
              virtual goods, and metaverse structures take place on this
              platform. Also, the Play to Earn mechanism is used to generate
              financial benefits for the players, which sets them apart from
              other games.
            </p>
            <p class="pb-0">
              Zuki is built to meet users' entertainment desires and, most
              importantly, prioritize users' interests. Most of the NFT games
              available now have repetitive basic gameplay which makes playing
              monotonous. However, each game in Zuki will be unique based on the
              player lineup and the team's abilities and strategies.
            </p>
            <p class="pb-0">
              In addition, Blockchain and NFT can solve problems related to the
              gaming industry that traditional games cannot deal with. The
              connection between players and game publishers has been balanced
              owing to blockchain technology, and gamers are no longer as
              dependent on one another as they once were. Here, you can become
              not only a player but also a good game creator. The difference
              between Zuki and other games lies in that line of point.
            </p>
            <p class="pb-0">
              Zuki has its own native token called Zuki, which users can use to
              play the game, and these tokens can be earned as rewards. You can
              buy and sell these tokens. Users of this app can directly edit and
              delete previously recorded activities in as many subsections as
              they want. To make it simple for users, we choose EVM.
            </p>

            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/ekta-1.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/ekta-1.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/ekta-1.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="zuki-company"
                />
              </picture>
              <div class="green-vector"></div>
            </div>
            <h4 class="pt-4">A visual outline of the project plan</h4>
            <div class="metaverse_subheading">
              <h3 class="metacerse-head-thre">Business Plan</h3>
            </div>
            <ul>
              <li>
                <strong> Product Discovery Workshop </strong> - This is the
                first phase of this project, where the client and our project
                team start collaborating with each other to share their ideas
                and needs. It involves both parties developing a shared
                understanding and building on each other's techniques to solve a
                unique problem and this product discovery workshop is part of
                the planning posture in the Zuki game.
              </li>
              <li>
                <strong> Design </strong> - At this stage, we turn ideas into
                designs (UI/UX). Initial design stages include paper prototyping
                and testing in various high-fidelity prototyping. Here we focus
                on describing several steps and discoveries that directly
                contributed to key features or emerged with subtle and notable
                design insights and we worked on staking and website platforms.
              </li>
              <li>
                <strong> Development </strong> - We have developed a custom
                landing page and Staking platform for the ZUKI team. The staking
                platform was done by three teams. Custom Smart Contract for the
                staking platform, Front end team integrated Web3 wallets, entire
                staking platform was extensively tested by the Testing team
                using automated and manual techniques.
              </li>
              <li>
                <strong> Deployment </strong> - At this stage, we turn ideas
                into designs (UI/UX). Initial design stages include paper
                prototyping and testing in various high-fidelity prototyping.
                Here we focus on describing several steps and discoveries that
                directly contributed to key features or emerged with subtle and
                notable design insights and we worked on staking and website
                platforms.
              </li>
            </ul>

            <h4 class="pt-lg-4">Challenges & Motives</h4>
            <h3>The Pain Points</h3>
            <p class="py-2">
              Let us take a look at why Zuki is relevant and what problems we
              have currently.
            </p>

            <ul>
              <li>
                <strong> Complicated Gameplay </strong> - Most games in the
                blockchain space have complicated gameplay which acts as a
                barrier to entry.
              </li>
              <li>
                <strong> Design </strong> - Users are just players, they have no
                control over how the games work and cannot contribute.
              </li>
              <li>
                <strong> Focus on hyper-realistic gameplay. </strong> - Most
                games in the space focus on hyper-realistic gameplay which
                sometimes is not for everyone.
              </li>
            </ul>

            <h4 class="pt-5">The Solution</h4>
            <div class="metaverse_subheading">
              <h3>
                A P2E Gaming Ecosystem that gives power back to the players
              </h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/zuki/a-p2e-gaming-ecosystem-that-gives-power-back-to-the.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/zuki/a-p2e-gaming-ecosystem-that-gives-power-back-to-the.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/zuki/a-p2e-gaming-ecosystem-that-gives-power-back-to-the.png"
                  class="img-fluid"
                  width="840"
                  height="366"
                  alt="zuki-company"
                />
              </picture>
              <div class="green-vector"></div>
            </div>

            <p class="pb-0">
              Zuki is quickly different it’s a vision in the Play-to-Earn space.
              The major challenges in front of us were that existing games were
              extremely complex to play and users have no say in how the games
              should work. Zuki has changed this scenario by allowing users to
              create their own puzzles and quizzes where both game creators and
              players can earn rewards in Zuki tokens.
            </p>
            <p>
              The Zuki team already had a good team to work on game development.
              So, it was our job to make sure that the decentralized aspects
              were up to the mark. We Worked on 3 major aspects of Zuki:
            </p>

            <ul>
              <li>Zuki utility token.</li>
              <li>Landing Page.</li>
              <li>Staking Platform.</li>
            </ul>

            <p>
              We also put a dedicated blockchain consultant for the Zuki project
              to devise and plan the ecosystem and tokenomics.
            </p>

            <div class="metaverse_subheading">
              <h3>Unique Features</h3>
            </div>
            <div class="case-inner-image first-image" id="alt-inner-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/zuki/unique-features.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/zuki/unique-features.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/zuki/unique-features.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="zuki-company"
                />
              </picture>
              <div class="green-vector"></div>
            </div>
            <div class="row pt-4">
              <div class="col-lg-5 tec-list">
                <ul>
                  <li>Fully decentralized platform.</li>
                  <li>Monetize your gaming skill.</li>
                  <li>Earn tokens as rewards.</li>
                </ul>
              </div>
              <div class="col-lg-2"></div>
              <div class="col-lg-5 tec-list-alt">
                <ul>
                  <li>Better exposure.</li>
                  <li>Turn your ideas into games.</li>
                  <li>Engage with the community.</li>
                </ul>
              </div>
            </div>

            <h4 class="pt-4">Tech Stacks</h4>
            <div class="metaverse_subheading">
              <h3>
                A great app needs the right tools and technology to perform
              </h3>
            </div>
            <div class="tech-stak">
              <h3 class="text-center">Front End</h3>
              <div class="tech-list">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/html5.webp">
                  <source type="image/png" srcset="assets-new/images/case/html5.png">
                  <img loading="lazy" data-src="assets-new/images/case/html5.png" class="img-fluid" width="70" height="70" alt="HTML"
                  title="HTML"/>
                </picture>
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/css.webp">
                  <source type="image/png" srcset="assets-new/images/case/css.png">
                  <img loading="lazy" data-src="assets-new/images/case/css.png" class="img-fluid" width="70" height="70" alt="css"
                  title="css"/>
                </picture>
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/angular.webp">
                  <source type="image/png" srcset="assets-new/images/case/angular.png">
                  <img loading="lazy" data-src="assets-new/images/case/angular.png" class="img-fluid" width="70" height="70" alt="angular"
                  title="angular"/>
                </picture>
              </div>
            </div>

            <div class="tech-stak">
              <h3 class="text-center">Back End</h3>
              <div class="tech-list">
                <img loading="lazy"
                  src="assets-new/images/case/nodejs.png"
                  class="img-fluid"
                  width="70"
                  height="70"
                  alt="node"
                />
              </div>
            </div>

            <div class="tech-stak">
              <h3 class="text-center">Smart Contracts</h3>
              <div class="tech-list">
                <img loading="lazy"
                  src="assets-new/images/case/solidity-2.png"
                  class="img-fluid"
                  width="70"
                  height="70"
                  alt="solidity"
                />
              </div>
            </div>

            <div class="metaverse_subheading">
              <h3 class="met-sub-head">Results</h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/zuki/results.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/zuki/results.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/zuki/results.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="zuki-company"
                />
              </picture>
              <div class="green-vector"></div>
            </div>
            <p class="pb-0">
              Zuki is undoubtedly a game-changer in the P2E gaming space. On
              successful delivery of all the deliverables, Zuki launched a
              staking campaign on the Ethereum network. Currently, the Zuki team
              is working on the gaming platform, which will be launched in late
              2022 to early 2023.
            </p>

            <p>
              In the next phases, Zuki looks to expand its audience further and
              integrate blockchain into the gaming platform.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="company-detail">
          <div class="detail-section-one">
            <h4>Company</h4>
            <h3 class="pb-0">Zuki</h3>
          </div>
          <div class="detail-section-two">
            <h4>Team Members</h4>
            <ul>
              <li>Jim Phipps</li>
            </ul>
          </div>
          <div class="detail-section-two">
            <h4>Website</h4>
            <h3 class="pb-0">
              <a href="https://zuki.app" target="_blank"
                rel="noopener noreferrer">www.zuki.app</a>
            </h3>
          </div>
          <div class="detail-section-two">
            <h4>Country</h4>
            <h3 class="pb-0">USA</h3>
          </div>
          <div class="detail-section-two">
            <h4>Business Type</h4>
            <h3 class="pb-0">P2E gaming, Staking, NFT Marketplace</h3>
          </div>
          <div class="detail-section-three">
            <h4>Follow us on</h4>
            <h3>Blockchain & Staking</h3>
            <ul class="d-flex align-items-center gap-4">
              <li>
                <a
                  href="https://www.ekta.io/"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="global"
                  aria-label="global"><i class="bi bi-globe"></i></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ektachain/"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="instagram"
                  aria-label="Instagram"><i class="bi bi-instagram"></i></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/EktaChain"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="Twitter"
                  aria-label="twitter">
                  <i class="bi bi-twitter-x"></i>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-service-alt-main">
  <app-contactform></app-contactform>
  </section>
