<!-- About Section Start starts -->
<div class="section-about" id="about">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row align-items-center">

      <div class="col-lg-7 col-md-12 col-sm-12 col-12 mx-auto">
        <div class="demo-video-section px-2">
          <!-- <iframe class="demo-video" target="_blank"
                rel="noopener noreferrer" src="https://www.youtube.com/embed/KBFYcNXThMM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
          <video src="assets-new/video/blockchain-development-company.mp4"
            controls [autoplay]="false" width="95%"></video>
        </div>
        <!-- <div class="about-picture">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-6">
              <div class="about-inner-image text-center">
                <img loading="lazy"
                  alt="Creative"
                  class="img-fluid"
                  width="100%"
                  height="100%"
                  
                />
                <div class="about-sub-heading pt-2 pb-0">Creative</div>
              </div>
              <div class="about-inner-image text-center">
                <img loading="lazy"
                  src="assets-new/images/home/security.png"
                  alt="Security"
                  class="img-fluid"
                  width="100%"
                  height="100%"
                  
                />
                <div class="about-sub-heading pt-2 pb-0">Secure</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-6">
              <div class="about-inner-image text-center">
                <img loading="lazy"
                  src="assets-new/images/home/solid.png"
                  alt="Solid"
                  class="img-fluid"
                  width="100%"
                  height="100%"
                  
                />
                <div class="about-sub-heading pt-2 pb-0">Solid</div>
              </div>
              <div class="about-inner-image text-center">
                <img loading="lazy"
                  src="assets-new/images/home/transparent-2.png"
                  alt="Transparency"
                  class="img-fluid"
                  width="100%"
                  height="100%"
                  
                />
                <div class="about-sub-heading pt-2 pb-0">Transparent</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-lg-5 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h4>We Are <span class="dots">.</span></h4>
        </div>
        <h3 class="main-sub-heading">BlockchainX Tech</h3>
        <p>
          A completely bootstrapped Blockchain development company with 4+ years
          of track record under our belt. The popularity of web3 economies and
          their future is our inspiration to build cutting-edge solutions for
          Web3 start-ups. To startle among the digital fusion we strive to
          create a stirring impact with our tech maestros, who are the backbone
          in rendering cutting-edge Blockchain app development services.
        </p>
        <div class="content-link" id="border-none">
          <a href="/assets-new/pdf/PITCHDECK.pdf" download>
            <span>Download Here</span> <i class="bi bi-download"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- About Section Start ends -->
