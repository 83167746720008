<section class="banners-section" #about>
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Solana Meme Coin </strong></span>Development 
              </h1>
              <p>Kickstart your Solana Meme Coin Development with BlockchainX. We create secure, audited SPL tokens, ready for market and maximizing your coins impact.</p>
              <div class="contact-link">
                <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="home-link">Telegram</a>
                <a href="https://www.blockchainx.tech/contact/" target="_blank" class="home-link-alt">Talk with Our Experts</a>
              </div> 
              <!-- <div class="erc-link d-flex flex-column flex-sm-row gap-3">
                <div class="d-flex pt-3">
                    <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                      <span>Telegram</span>
                    </a>
                  </div>  
                <div class="d-flex pt-3">
                  <a href="https://www.blockchainx.tech/contact/" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                    <span>Talk with Our Experts</span>
                  </a>
                </div>
              </div> -->
            </div>        
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/launch-your-solona/launch-your-solana.webp" />
              <source type="image/png" srcset="assets-new/images/launch-your-solona/launch-your-solana.png" />
              <img loading="lazy"  data-src="assets-new/images/launch-your-solona/launch-your-solana.png" width="500" height="500" class="img-fluid" alt="Solana Meme Coin Development Company" title="solana meme coin development company" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/launch-your-solona/light-theme/launch-your-solana.webp" />
              <source type="image/png" srcset="assets-new/images/launch-your-solona/light-theme/launch-your-solana.png" />
              <img loading="lazy"  data-src="assets-new/images/launch-your-solona/light-theme/launch-your-solana.png" width="500" height="500" class="img-fluid" alt="Solana Meme Coin Development Company" title="solana meme coin development company" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Why should you start </span>Solana Meme Coin Development?</h2>
    </div>
    <div class="row d-flex align-items-center">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <img loading="lazy" data-src="assets-new/images/token-images/why-should-you-choose.png" width="350" height="350" alt="Solana Meme Coin Development" title="Solana Meme Coin Development" class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>Memecoins are the best way to test your crypto ideas on the market and start community building in web3. Some memecoins even go to billions of dollars in market cap and thousands of holders. Memecoins are also a way for you to attract more users to your startup idea and create an economy for your project. If you’re trying to get your crypto startup idea off the ground, let BlockchainX guide you through the process of Solana Meme Coin Development from end-to-end.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metaverse_heading">
        <h3 class="text-start">Exploring the possibilities of <span>Solana Memecoins</span></h3>
        <p class="py-3"> Solana Memecoins have made headlines in the crypto space recently and many projects are jumping on the bandwagon. Lets take a closer look at what Solana Memecoins are all about.</p>
      </div>
      <div class="col-lg-6">
        <video poster="assets-new/images/token-images/solana-meme-coin.webp" src="assets-new/images/token-images/solana-meme-coin.mp4" controls [autoplay]="false" width="100%">
          <track kind="captions" src="assets-new/images/token-images/solana-meme-coin.mp4" srclang="en" label="English">
        </video>
      </div>
    </div>
  </div>
</section>
<section class="section-service-alt">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp" srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <img loading="lazy" data-src="assets-new/images/erc/trending-erc20-token-list.png" width="405" height="405" alt="solana meme coin development solution" title="solana meme coin development solution" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular Solana </span>Meme Coins in 2024</h3>
          <p>With $7,084,248,553 market capital the Solana Meme coin market stands on top and here are some Popular Solana Meme Coin projects in 2024</p>
          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/bonk-memecoin.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/bonk-memecoin.png" class="img-fluid" width="30" height="30" alt="Bonk Memecoin logo" title="Bonk Memecoin"/>
                  </picture>
             Bonk Memecoin</li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/dogwifhat.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/dogwifhat.png" class="img-fluid" width="30" height="30" alt="dogwifhat logo" title="dogwifhat (WIF)"/>
                  </picture>
                dogwifhat (WIF) </li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/solama.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/solama.png" class="img-fluid" width="30" height="30" alt="Solama logo" title="Solama"/>
                </picture> Solana</li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/pump-fun.webp">
                  <img loading="lazy" data-src="assets-new/images/token-images/pump-fun.png" class="img-fluid" width="30" height="30" alt="Pump.fun logo" title="Pump.fun"/>
              </picture> Pump.fun</li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/book-of-meme.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/book-of-meme.png" class="img-fluid" width="30" height="30" alt="Book of Meme logo" title="Book of Meme (BOME)"/>
                </picture> Book of Meme (BOME)</li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/maga-trump.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/maga-trump.png" class="img-fluid" width="30" height="30" alt="MAGA TRUMP logo" title="MAGA (TRUMP)"/>
                </picture> MAGA (TRUMP)</li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/ponke.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/ponke.png" class="img-fluid" width="30" height="30" alt="Ponke logo" title="Ponke"/>
                </picture> Ponke</li>
                <li class="d-flex gap-sm-3 gap-2">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/myro.webp">
                  <img loading="lazy" data-src="assets-new/images/token-images/myro.png" class="img-fluid" width="30" height="30" alt="Myro logo" title="Myro"/>
              </picture> Myro (MYRO)</li>
                <li class="d-flex gap-sm-3 gap-2">
                   <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/popcat.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/popcat.png" class="img-fluid" width="30" height="30" alt="Popcat logo" title="Popcat"/>
                   </picture>
                   Popcat</li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/neiro.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/neiro.png" class="img-fluid" width="30" height="30" alt="neiro logo" title="neiro"/>
                   </picture>
                 Neiro (NEIRO)</li>
                <li class="d-flex gap-sm-3 gap-2">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/slerf.webp">
                    <img loading="lazy" data-src="assets-new/images/token-images/slerf.png" class="img-fluid" width="30" height="30" alt="Slerf logo" title="Slerf"/>
                   </picture>
                Slerf (SLERF) </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a href="https://www.blockchainx.tech/contact/" class="home-link-alt">Connect With Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main meta-discuss">
  <div class="container py-0">
  <div class="main-discuss-section">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>   
    <div class="row">
      <div class="col-lg-12 let-discuss">
        <div class="discus-section">
          <div class="caption">
            <!-- <h2>
              <small></small>
               <span><strong> Customized Pump Fun Clone App Development</strong></span>
             </h2> -->
          </div>
        </div>
          <div class="discus-detail">
            <p class="common-sub-heading-alt pump"  id="discuss-mission">
              Utilize the perks of the network, and catch the trend with Solana Meme coin development to build an interactive network.
            </p>
          <div class="d-flex pt-3">
            <a href="https://www.blockchainx.tech/contact/" class="home-link-alt">Connect now!</a>
          </div>
          <div class="discus-vector">
            <picture>
              <source srcset="assets-new/images/meme-coin-development/icons/dicuss.webp" type="image/webp">
              <img
              loading="lazy"
              src="assets-new/images/meme-coin-development/icons/dicuss.png"
              class="img-fluid"
              width="500"
              height="391"
              alt="Discussion vetor"
              title="Discussion vetor"
            />
            </picture>
            
          </div>
        </div>
      
    </div>
  </div>
</div>
</div>     
</section>
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Solana Meme Coin </span>Development Services</h3>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
              <img loading="lazy"  data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="Project Consulting" title="Project Consulting" class="img-fluid">
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-5">Project Consulting</div>
          <p>Our expert consultants guide you through the complete process of Solana Meme Coin development, including tokenomics.</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
              <img loading="lazy"  data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="NFT Marketplace Development" title="NFT Marketplace Development" class="img-fluid">
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-5">NFT Marketplace Development</div>
          <p>Launch an NFT Marketplace as part of your Solana Meme Coin project and show users that you have a strong roadmap for growth.</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
              <img loading="lazy"  data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="Token Sale Platform" title="Token Sale Platform" class="img-fluid">
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-5">Token Sale Platform</div>
          <p>Launch a token sale and raise funds for your Solana Meme Coin project through a customized token sale dashboard in your branding.</p>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
              <img loading="lazy"  data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Cross-Chain Bridge" title="Cross-Chain Bridge" class="img-fluid">
            </picture>
          </div>
          <h5 class="erc-20-head-alt text-left">Cross-Chain Bridge</h5>
          <p>Don’t be left out from the rest of the pack. Take your memcoin cross-chain and capture the market volume from EVM chains.</p>
          <div class="content-link">
            <a href="https://bridge.blockchainx.tech/" target="_blank" rel="noopener noreferrer">
              <span>View More</span>
              <picture>
                <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode" />
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode" />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div> -->
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
      <div class="types-erc-detail">
        <div class="top-icon">
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
            <img loading="lazy"  data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="DEX Consultation" title="DEX Consultation" class="img-fluid">
          </picture>
        </div>
        <div class="erc-20-head-alt text-left h-5">Governance Token</div>
        <p>Launch a robust governance token that gives its holders voting rights inside your Liquid Staking DAO through our liquid staking platform development.</p>
      </div>
      </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token mb-0">
      <div class="types-erc-detail">
        <div class="top-icon">
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
            <img loading="lazy"  data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Audited Smart Contract" title="Audited Smart Contract" class="img-fluid">
          </picture>
        </div>
        <div class="erc-20-head-alt text-left h-5">Cross-Chain Bridge</div>
        <p>Don’t be left out from the rest of the pack. Take your memcoin cross-chain and capture the market volume from EVM chains.</p>
      </div>
    </div>
    </div>
  </div>
</section>
<section class="section-service-main lifecycle-sec d-none d-md-block">
  <div class="container">
    <div class="row mb-sm-5 pb-4">
      <div class="metaverse_heading mx-auto">
        <h4 class="fw-semibold text-center">Our<span> Workflow In Solana </span> Meme Coin Development</h4>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <div class="token-wrap first-four w-75">
        <div class="token-wrap-inner d-flex flex-wrap">
          <div class="column token-column position-relative one-second w-50  token-upper-iconbox first">
            <div class="token-column-inner-one h-100">
              <div class="icon-wrapper">
                <img loading="lazy" src="assets-new/images/token-images/requirement-gathering.svg" class="img-fluid" alt="Requirement Gathering" width="44" height="44" *ngIf="!isDarkmode">
                <img loading="lazy" src="assets-new/images/token-images/requirement-gathering-lite.svg" class="img-fluid" alt="Requirement Gathering" width="44" height="44" *ngIf="isDarkmode">
              </div>
              <h5>Consultation For Requirements</h5>
              <p>We start your Solana Meme coin development by first gathering your insights, and ideas, and understanding your requirements for the project. The purpose, your roadmap, and expectations are collected.</p>
            </div>
          </div>
          <div class="column token-column position-relative one-second w-50  token-upper-iconbox second">
            <div class="token-column-inner-two h-100">
              <div class="icon-wrapper">
                <img loading="lazy" src="assets-new/images/token-images/design-and-planning.svg" class="img-fluid" alt="Design and Planning" width="44" height="44" *ngIf="!isDarkmode" >
                <img loading="lazy" src="assets-new/images/token-images/design-and-planning-lite.svg" class="img-fluid" alt="Design and Planning" width="44" height="44" *ngIf="isDarkmode" >
              </div>
              <h5>Getting Better Visionary</h5>
              <p>Going further we get your idea into a better structure with wireframe and infrastructure with our creative team. Conceptualize features, working of the token, define its functionality, and more.</p>
            </div>
          </div>
          <div class="column token-column position-relative one-second w-50 token-lower-iconbox first">
            <div class="token-column-inner-three h-100">              
              <div class="icon-wrapper">
                <img loading="lazy" src="assets-new/images/token-images/lanuch-meme-coin.svg" class="img-fluid" alt="Deployment on Mainnet" width="44" height="44" *ngIf="!isDarkmode" >
                <img loading="lazy" src="assets-new/images/token-images/lanuch-meme-coin-light.svg" class="img-fluid" alt="Deployment on Mainnet" width="44" height="44" *ngIf="isDarkmode" >
              </div>
              <h5>Launch your Meme Coin</h5>
              <p>With all the testing done, the meme coin is ready to launch. With proper marketing strategies, you can pull off a great bandwidth for your token sale. Post the launch we extend our maintenance support in terms of upgrades.</p>
            </div>
          </div>
          <div class="column token-column position-relative one-second w-50 token-lower-iconbox second">
            <div class="token-column-inner-four h-100">
              <div class="icon-wrapper">
                <img loading="lazy" src="assets-new/images/token-images/deployment-phase.svg" class="img-fluid" alt="Testing on Testnet" width="44" height="44" *ngIf="!isDarkmode" >
                <img loading="lazy" src="assets-new/images/token-images/deployment-phase-light.svg" class="img-fluid" alt="Testing on Testnet" width="44" height="44" *ngIf="isDarkmode" >
              </div>
              <h5>Deployment Phase</h5>
              <p>Here the token testing, dashboard testing, and corrections happen. Based on the performance and responses, the alterations are made to ensure there is a smooth workflow without bugs and interruptions.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="token-wrap last-fifth w-25">
        <div class="token-wrap-inner d-flex flex-wrap">
          <div class="column token-column position-relative one middle-iconbox">
            <div class="token-column-inner-five h-100">
              <div class="icon-wrapper">
                <img loading="lazy" src="assets-new/images/token-images/development-phase.svg" class="imd-fluid" alt="Coding on Testnet" width="44" height="44" *ngIf="!isDarkmode" >
                <img loading="lazy" src="assets-new/images/token-images/development-phase-light.svg" class="imd-fluid" alt="Coding on Testnet" width="44" height="44" *ngIf="isDarkmode" >
              </div>
              <h5>Development Phase</h5>
              <p>In the development phase of the meme coin on Solana, we bring in blockchain, smart contract integrations, user interfaces, and all other components, and put them together to bring the face of the token.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section >
  <div class="meta-expert position-relative">
    <div class="violet-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="our_experts">
            <p class="pb-3"><span class="d-block fw-normal">Bring in more interactions, engagements, and better </span> 
            </p>
            <div class="text-center my-4">
              <a class="our_expert_talk" href="https://www.blockchainx.tech/contact">Book Your Call now! </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <h2 class="main-sub-heading text-center pb-0">Advantages of 
            <span>Solana Meme Coin Development</span>
        </h2>
      </div>
      <div class="row types-of-token-detail justify-content-center gy-5">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/higher-throught-out.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/higher-throught-out.png" class="img-fluid" alt="Higher throughput" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Higher throughput</div>
            <p>The captivating feature of the Solana network is its high transaction speed; it can process approximately 3,000 TPS.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/analysis.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/analysis.png" class="img-fluid" alt="Comparatively Lower cost" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Comparatively Lower cost</div>
            <p>One other interesting attribute of Solana blockchian is its capability to process transactions at a lower cost when compared with other networks.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/strong-ecosystem.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/strong-ecosystem.png" class="img-fluid" alt="Strong Ecosystem" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Strong Ecosystem</div>
            <p>With its popularity, the expansion in the Solana ecosystem makes it grow stronger for developing decentralized applications.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/software-testing.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/software-testing.png" class="img-fluid" alt="Software Testing" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Developer Friendly</div>
            <p>The Language RUST used in Solana development is easy for developers, and the tool ranges are highly creative and apt for meme coin development on Solana.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/secured-and-customized-solutions.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/secured-and-customized-solutions.png" class="img-fluid" alt="Secured and customized solutions" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Protective environment</div>
            <p>The security encryption in the Solana network is great, that the combination of PoH, and PoS adds more value and dependability.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/supportive-community.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/supportive-community.png" class="img-fluid" alt="Supportive Community" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Supportive Community</div>
            <p>The Solana community has a wide range of active participants and this continues to grow as their ecosystem grows.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/highly-customizable.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/highly-customizable.png" class="img-fluid" alt="Highly Customizable" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Highly Customizable</div>
            <p>Solana Meme coin development is the right choice if you are building on-chain applications for everyday use cases such as building a fun economy around your business through meme coins.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/multifaceted-use-cases.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/multifaceted-use-cases.png" class="img-fluid" alt="Token Development" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Multifaceted Use Cases</div>
            <p>The meme coin developed on Solana can be integrated with varied use cases based on need and dedicated operation accordingly.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/build-efficient-smart-contract.webp" />
                <img loading="lazy" data-src="assets-new/images/token-images/build-efficient-smart-contract.png" class="img-fluid" alt="Build Efficient Smart Contract" width="75" height="75" />
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-5">Build Efficient Smart Contract</div>
            <p>Our team builds robust and high-quality smart contracts that are tailored to your project requirements and optimized for maximum efficiency in terms of transaction cost.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-alt">
  <div class="types-of-erc text-center">
    <div class="container">
      <div class="build-web3-solution rounded-3">
        <div class="row justify-content-center mb-3">
          <div class="col-lg-10">
            <p class="main-sub-heading text-center">Amplified Growth with  <span>Solana Meme Coin</span></p>
          </div>
        </div>
        <div class="d-flex pt-3 justify-content-center">
          <a href="https://www.blockchainx.tech/contact/" class="home-link-alt">Connect with our Experts.</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <h2 class="main-sub-heading text-center pb-0">Our  <span>Tech stack of</span> Solana meme coin development</h2>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-5 types-of-token-detail justify-content-center industry-web row-gap">
      <div class="col">
        <div class="detail text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/angular.svg" class="img-fluid" alt="Angular" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/angular-light.svg" class="img-fluid" alt="Angular" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Angular</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/tailwind.svg" class="img-fluid" alt="Tailwind" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/tailwind-light.svg" class="img-fluid" alt="Tailwind" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Tailwind</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/node-js.svg" class="img-fluid" alt="Node JS" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/node-js-light.svg" class="img-fluid" alt="Node JS" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Node JS</p>
        </div>
      </div>
      <div class="col">
        <div class="detail text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/mongodb.svg" class="img-fluid" alt="MongoDB" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/mongodb-light.svg" class="img-fluid" alt="MongoDB" width="60" height="60" *ngIf="isDarkmode">
            <p class="erc-20-head-alt fw-semibold">MongoDB</p>
          </div>              
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/rust.svg" class="img-fluid" alt="Rust" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/rust-light.svg" class="img-fluid" alt="Rust" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Rust</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/hardhat.svg" class="img-fluid" alt="Hardhat" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/hardhat-light.svg" class="img-fluid" alt="Hardhat" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Hardhat</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/solana.svg" class="img-fluid" alt="Solana" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/solana-light.svg" class="img-fluid" alt="Solana" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Solana/SPL Token</p>
        </div> 
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/phantom.svg" class="img-fluid" alt="Phantom" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/phantom-light.svg" class="img-fluid" alt="Phantom" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Phantom</p>
        </div> 
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-images/pinata.svg" class="img-fluid" alt="Pinata" width="60" height="60" *ngIf="!isDarkmode">
            <img loading="lazy" src="assets-new/images/token-images/pinata-light.svg" class="img-fluid" alt="Pinata" width="60" height="60" *ngIf="isDarkmode">
          </div>
          <p class="erc-20-head-alt fw-semibold">Pinata</p>
        </div>
      </div>
        <div class="col">
          <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
            <div>
              <img loading="lazy" src="assets-new/images/token-images/ipfs.svg" class="img-fluid" alt="IPFS" width="60" height="60" *ngIf="!isDarkmode">
              <img loading="lazy" src="assets-new/images/token-images/ipfs-light.svg" class="img-fluid" alt="IPFS" width="60" height="60" *ngIf="isDarkmode">
            </div>
            <p class="erc-20-head-alt fw-semibold">IPFS</p>
          </div>
        </div>
        <div class="col">
          <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
            <div>
              <img loading="lazy" src="assets-new/images/token-images/aws.svg" class="img-fluid" alt="AWS" width="60" height="60" *ngIf="!isDarkmode">
              <img loading="lazy" src="assets-new/images/token-images/aws-light.svg" class="img-fluid" alt="AWS" width="60" height="60" *ngIf="isDarkmode">
            </div>
            <p class="erc-20-head-alt fw-semibold">AWS</p>
          </div>
        </div> 
      </div>
    </div>
  </div>
</section>
<section class="section-service-alt">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-3">
          <div class="h-4 ps-0 pb-4"><span class="d-block">Why Choose BlockchainX for </span>Solana Meme Coin Development?</div>
          <p>We offer a premium Solana Meme Coin Development Service with experienced rust developers. With our complete package, you get access to our entire team of architects, developers, and consultants to take your Meme Coin startup to the new heights and capture the bull run.</p>
        </div>
          <div class="whysection_text_wrap pt-2 pt-sm-3 pt-md-4">
            <div class="row token d-flex">
              <div class="col-md-6 projects">
                <ul>
                  <li class="d-flex fw-normal"><span><i class="bi bi-check-circle-fill"></i></span>100+ Experts</li>
                  <li class="d-flex fw-normal"><span><i class="bi bi-check-circle-fill"></i></span>Experienced Architects</li>
                </ul>
              </div>
              <div class="col-md-6 projects">
                <ul>
                  <li class="d-flex fw-normal"><span><i class="bi bi-check-circle-fill"></i></span>Certified Developers</li>
                  <li class="d-flex fw-normal"><span><i class="bi bi-check-circle-fill"></i></span>Industry Partnerships</li>
                </ul>
              </div>
            </div>    
          </div>
          <div class="contact-link">
            <a href="https://www.blockchainx.tech/contact/" class="home-link">Connect with Our Experts</a>
          </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section d-flex align-items-center justify-content-center">
        <div class="why_right_side">
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-images/why-choose-blockchain.webp" />
            <img loading="lazy" data-src="assets-new/images/token-images/why-choose-blockchain.png" width="450" height="450" class="img-fluid" alt="Solana Meme Coin Development Service" title="Solana Meme Coin Development Service"  />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="container">
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">Frequently Asked Question<span class="dots">.</span></div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> How is Solana Meme Coin Development different from other chain development?</button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>While Meme Coins are popular on other chains such as Ethereum, Solana has emerged as a new standard with great volume. Unlike EVM chains, Solana smart contracts are written in Rust and are a bit more complicated to build.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> How long does Solana Meme Coin Development take?</button>
              </div>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Building a Solana Meme Coin can take anywhere from 5-7 days. This includes writing the smart contract, testing on the Devnet, and launching on the Mainnet.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Will any Web3 wallet my Solana Meme Coin?</button>
              </div>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Solana SPL tokens are supported on popular Web3 wallets like Trust Wallet and Phantom wallet. Metamask has also recently launched an update to support and transact Solana tokens.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"> Will my Solana Meme Coin work on any DEX Exchange?</button>
              </div>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>The Popular DEX on Solana is Raydium. DEXs like Uniswap and PancakeSwap currently only support EVM chains and have not released support for Solana tokens.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"> Are Solana Meme Coins secure?</button>
              </div>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Solana SPL token smart contracts are written in Rust which is considered to be a highly capable programming language with options to protect from data leakage. While Meme Coins smart contracts don’t have to support much data, this could be useful if you plan to build a platform associated with your Solana Meme Coin.</p>
                </div>
              </div>
            </div>
          <div *ngIf="showFaq" class="faq-section">  
            <div class="accordion-item">
              <div class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"> What are the future trends in Solana meme coin development?</button>
              </div>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>There is a high demand for Rust developers as the craze over Solana expands with meme coin development as it was this network that gave a completely altered perspective which is positive. This buzz is expected to roll out more curiously in the following days.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSeven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"> Do I need to trademark my meme coin's name and mascot?</button>
              </div>
              <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Yes, of course, you will need the trademark for your meme coin name and mascot, as it would stand as an identifier and embodiment for your projects.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingEight">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight"> How can I ensure the security of my Solana meme coin?</button>
              </div>
              <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>SkyKnight Meme scan is an impeccable tool that could help you in this case, which ensures the security and credibility of your Solana meme coin.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingNine">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine"> What are some examples of successful meme coins?</button>
              </div>
              <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Dogecoin, Memecoin, Pepe coin, Shiba Inu, and Diverse, are a few successful meme coins.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTen">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen"> Why choose Solana for developing a meme coin?</button>
              </div>
              <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Solana network with the ability to perform transactions quickly, safely, and secure makes it a go-to option to develop Meme coins. Also, the network has proven to be high-performing and involves low transaction cost.</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        <div class="d-flex justify-content-center">
          <div (click)="toggleFaq()" class="faq-button">
            <span *ngIf="!showFaq" class="fs-3">
              <i class="bi bi-mouse"></i>
            </span>
            <span *ngIf="showFaq" class="fs-4">
              <i class="bi bi-caret-up-fill"></i> 
            </span>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-alt">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <p class="h-3">Blog<span class="dots">.</span></p>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <p class="h-2">Our Latest Blogs</p>
          </div>
          <div class="deploy-link">
            <a href="https://www.blockchainx.tech/blog">
              <img loading="lazy" src="assets-new/images/right-arrow.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="!isDarkmode" />
              <img loading="lazy" src="assets-new/images/right-arrow-dark.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/comparison-of-solana-polygon-ethereum/">
                <picture>
                  <source type="image/jpg" srcset=" assets-new/images/blog/comparision-of-sol-poly-eth.jpg "/>
                  <img loading="lazy" data-src="assets-new/images/blog/comparision-of-sol-poly-eth.jpg" width="404" height="209" alt="Solana Vs Polygon Vs Ethereum" title="Explore the detailed comparison of Solana Vs Polygon Vs Ethereum"/>
                </picture>
                <p class="blog-head">Explore the detailed comparison of Solana Vs Polygon Vs Ethereum</p>
                <p>A few years ago, no one thought about how blockchain and cryptocurrency would change the ...</p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/what-is-depin/">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="!isDarkmode" />
                  <img loading="lazy" src="assets-new/images/right-arrow-dark.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="isDarkmode" />
                </a>
                <hr/>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/nft-marketplace-development-solana">
                <picture>
                  <source type="image/jpg" srcset=" assets-new/images/blog/nft-marketplace-development-solana.jpg"/>
                  <img loading="lazy" data-src="assets-new/images/blog/nft-marketplace-development-solana.jpg" width="404" height="246" class="img-fluid" alt="NFT marketplace in Solana" title="How to create NFT marketplace in Solana"/>
                </picture>
                <p class="blog-head">How to create NFT marketplace in Solana</p>
                <p>The market is superfluous with digital expansion. Every business, out in the world, every nook and corner...</p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/ai-token-definition/">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="!isDarkmode" />
                  <img loading="lazy" src="assets-new/images/right-arrow-dark.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="isDarkmode" />
                </a>
                <hr/>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/solana-proof-of-history/">
                <picture>
                  <source type="image/jpg" srcset=" assets-new/images/blog/solana/banner.jpg "/>
                  <img loading="lazy" data-src="assets-new/images/blog/solana/banner.jpg" width="404" height="247" class="img-fluid" alt="blockchain to use proof of history" title="The world's first blockchain to use proof of history"/>
                </picture>
                <p class="blog-head">Solana: The world's first blockchain to use proof of history</p>
                <p>When comparing the prominent Ethereum blockchain, Salona is very fast and is the most high-throughput ....</p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/real-estate-tokenization-guide">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="!isDarkmode" />
                  <img loading="lazy" src="assets-new/images/right-arrow-dark.png" alt="Arrow" class="img-fluid" width="50" height="15" *ngIf="isDarkmode" />
                </a>
                <hr/>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <p class="main-sub-heading pb-4"><span>Launch Your Solana Meme Coin Today!</span></p>
            <p>Begin your startup journey with an established Solana Meme Coin Development Company.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech"  target="_blank" rel="noopener noreferrer" class="contact-mail" >
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/contact/webp/mail.webp"/>
                    <img loading="lazy" data-src="assets-new/images/contact/mail.png" alt="linkedin" width="32" height="32"/>
                  </picture>
                </a>
                <a href="https://wa.me/7708889555" target="_blank" rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/contact/webp/whatsapp-icon.webp"/>
                    <img loading="lazy" data-src="assets-new/images/contact/whatsapp-icon.png" alt="Contact Whatsapp" width="32" height="32"/>
                  </picture>
                </a>
                <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="twitter" >
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/contact/webp/telegram.webp"/>
                    <img loading="lazy" data-src="assets-new/images/contact/telegram.png" alt="twitter" width="40" height="40"/>
                  </picture>
                </a>
              </div>
            </div>
            <div class="contact-links">
              <p class="pb-3">Follow us on</p>
              <div class="social-links">
                <a href="https://www.linkedin.com/company/blockchainxtech/" target="_blank" rel="noopener noreferrer" class="linkdin">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/contact/webp/linkdin.webp"/>
                    <img loading="lazy" data-src="assets-new/images/contact/linkdin.png" alt="linkedin" width="22" height="22"/>
                  </picture>
                </a>
                <a href="https://www.instagram.com/blockchainxtech/" target="_blank" rel="noopener noreferrer"class="instagram">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/contact/webp/instagram.webp"/>
                    <img loading="lazy" data-src="assets-new/images/contact/instagram.png" alt="instagram" width="22" height="22"/>
                  </picture>
                </a>
                <a href="https://twitter.com/Blockchainxtech" target="_blank" rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/contact/webp/twitterx.webp"/>
                    <img loading="lazy" data-src="assets-new/images/contact/twitterx.svg" alt="twitter" width="25" height="25"/>
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>