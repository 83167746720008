<!-- Services Section Starts -->
<div class="services-section home-service-list">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-12 col-12">
        <div class="c-animation">
          <div class="common-sub-heading">
            <h4>We Facilitate <span class="dots">.</span></h4>
          </div>
          <h3 class="main-sub-heading">
            Cutting-edge Blockchain Development Services.
          </h3>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-12">
        <div class="c-animation">
          <p>
            Ruling out the Blockchain industry with starstruck solutions from
            our expertise. We facilitate heterogeneous solutions, exposing your
            dream to reality.
          </p>
          <div class="content-link" id="border-none">
            <a href="/assets-new/images/home/blockchainx-Products-&-services.pdf" download>
              <span>Download Here</span>
              <i class="bi bi-download"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="services-detail">
        <div class="green-vector"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-4 col-md-5 col-sm-12 col-12 service-heading common_page_head"
            >
              <h3>Blockchain <span class="d-block">Integrations</span></h3>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                We can get your projects mainstream by integrating a business
                portal on the desired blockchain network, alongside also you
                build an exclusive network that can operate on its own.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['smart-contract-development']">
                  <span>Read More</span>
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-4 col-md-5 col-sm-12 col-12 service-heading common_page_head"
            >
              <h3>DApps <span class="d-block">Development</span></h3>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Our Comprehensive decentralized Solutions have reached out to
                almost every edge and curated a complete universe of abilities
                in the spaces with our improved technology, which shall be the
                future.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['dapps-development']">
                  <span>Read More</span>
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-4 col-md-5 col-sm-12 col-12 service-heading common_page_head"
            >
              <h3>DeFi <span class="d-block">Development</span></h3>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                In Decentralised finances - DeFi Development Services, we render
                services that cull out legibility, security, and reliability on
                DeFi, therefore being a canvas to the new digitized finance
                market.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['defi-staking-platform']">
                  <span>Read More</span>
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-4 col-md-5 col-sm-12 col-12 service-heading common_page_head"
            >
              <h3>DAO <span class="d-block">services</span></h3>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                From exchange to currencies, our complete decentralized
                solutions render smart contract services that are
                on-of-the-kind. Its fortune in terms of security, safety along
                with scalability.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['dao-development-company']">
                  <span>Read More</span>
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-4 col-md-5 col-sm-12 col-12 service-heading common_page_head"
            >
              <h3>Asset <span class="d-block">Tokenization</span></h3>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Our robust solutions from the hands of our experiment Blockchain
                development facilitators, will help you develop any token, in
                the standard that you call out. This includes NFTs, asset
                tokenization, DeFi, and others.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['erc20-token-development']">
                  <span>Read More</span>
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-4 col-md-5 col-sm-12 col-12 service-heading common_page_head"
            >
              <h3>Launchpad <span class="d-block">Development</span></h3>
            </div>
            <div class="col-lg-8 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                We shall help you curate intricate launchpad solutions for
                crowdfunding, wallets, payments, etc. The most prominent
                assurance from our side is to deliver on-time services for all
                our products.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['white-label-launchpad-development']">
                  <span>Read More</span>
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-0">
    <app-let-discuss></app-let-discuss>
  </div>
</div>

<div class="nft-marketplace-section">
  <div class="container">
    <div class="row">
      <div class="nft-heading-section" id="deploy">
        <div class="nft-heading-section-alt">
          <div class="common-sub-heading">
            <h4>We Deploy<span class="dots">.</span></h4>
          </div>
          <h3 class="main-sub-heading">
            Ideal Custom NFT Ecosystem Development
          </h3>
        </div>
        <div class="nft-heading-section-alt">
          <div class="deploy-link">
            <a [routerLink]="['nft-marketplace-development']">
              <span>Read More</span>
              <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="nft_market">
                <div class="item-image">
                  <img loading="lazy"
                    src="assets-new/images/home/services/nft-2.png"
                    alt="NFT Trading Marketplace"
                    class="img-fluid"
                    width="200"
                    height="200"
                  />
                </div>
                <h4 class="common-sub-heading-alt">NFT Trading Marketplace</h4>
                <p>
                  Our exclusive customization delivers a fully functional
                  ecosystem with the ability to interact apart from trading.
                </p>
                <div class="content-link">
                  <a [routerLink]="['nft-marketplace-development']">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="nft_market">
                <div class="item-image">
                  <img loading="lazy"
                    src="assets-new/images/home/services/nft-3.png"
                    alt="NFT Creation Platform"
                    class="img-fluid"
                    width="200"
                    height="200"
                  />
                </div>
                <h4 class="common-sub-heading-alt">NFT Creation Platform</h4>
                <p>
                  Among our Blockchain development services, NFT creation holds
                  an integral part. Ensuring a safe minting process.
                </p>
                <div class="content-link">
                  <a [routerLink]="['non-fungible-token-development']">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="nft_market">
                <div class="item-image">
                  <img loading="lazy"
                    src="assets-new/images/home/services/nft-4.png"
                    alt="NFT Gaming Platform"
                    class="img-fluid"
                    width="200"
                    height="200"
                  />
                </div>
                <h4 class="common-sub-heading-alt">NFT Gaming Platform</h4>
                <p>
                  As Play-to-earn ecosystems as they gain popularity, our
                  decentralized gaming solutions are capable enough to draw
                  income with fun.
                </p>
                <div class="content-link">
                  <a [routerLink]="['nft-gaming-platform-development']">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="nft_market">
                <div class="item-image">
                  <img loading="lazy"
                    src="assets-new/images/home/services/nft-1.png"
                    alt="Multifacet NFT Platform"
                    class="img-fluid"
                    width="200"
                    height="200"
                  />
                </div>
                <h4 class="common-sub-heading-alt">Multifacet NFT Platform</h4>
                <p>
                  NFT is beyond art and almost every sector is digitalized
                  today. One of our expertise is building multifaceted NFT
                  solutions.
                </p>
                <div class="content-link">
                  <a [routerLink]="['non-fungible-token-development']">
                    <span>Read More</span>
                    <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
<!-- Services Section Ends -->
