import { Component, OnInit } from '@angular/core';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isDarkmode: Boolean=false;
  year:any;
  constructor(private footerservice: FooterServiceService) {}

  ngOnInit(): void {

    let date=new Date();
    this.year=date.getFullYear();

    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    })

  }


  backtotop() {
    window.scrollTo(0, 0);
  }
}
