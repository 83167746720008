import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-enterprise-blockchain-development',
  templateUrl: './enterprise-blockchain-development.component.html',
  styleUrls: ['./enterprise-blockchain-development.component.css']
})
export class EnterpriseBlockchainDevelopmentComponent implements OnInit {

  
  title = 'Enterprise blockchain development Empowering Business Through Secured Access';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Enterprise blockchain is a network that will eventually provide transparency and restrictions. layered control that gives ease of accessibility yet is a secure and efficient option for businesses to live into. Learn more in detail about enterprise blockchain development here!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'enterprise blockchain, enterprise blockchain development, enterprise blockchain development company, enterprise blockchain solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Enterprise blockchain development Empowering Business Through Secured Access',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/enterprise-blockchain-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Enterprise blockchain is a network that will eventually provide transparency and restrictions. layered control that gives ease of accessibility yet is a secure and efficient option for businesses to live into. Learn more in detail about enterprise blockchain development here!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/enterprise-blockchain-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/enterprise-blockchain-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Enterprise blockchain development Empowering Business Through Secured Access',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Enterprise blockchain is a network that will eventually provide transparency and restrictions. layered control that gives ease of accessibility yet is a secure and efficient option for businesses to live into. Learn more in detail about enterprise blockchain development here!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/enterprise-blockchain-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/enterprise-blockchain-development/'
    );
  }

}
