<!-- Banner Section -->
<section class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                    Leading <span>Blockchain Development</span> Company in Spain | BlockchinaX
                </h1>
                <!-- <div class="common-sub-heading-alt pb-2">Expanding Opportunities
                  For Real Assets</div> -->
                <p class="text-justify">
                    We curate highly equipped decentralized solutions meeting global standards, helping you efficiently nurture your business in the digital arena of decentralization. From Fintech to agriculture, manufacturing, retail, and much more, as a Blockchain Development Company in Spain, our blockchain solutions are technically high-end, ensuring to perform exceptionally disciplined in a linear movement.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a
                      href="/contact">
                      <span>Connect with our Experts now!</span>
                      <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="mobile-app-page-form bitrix-snippet-form position-relative" id="consultation-form">
              <div class="form-title">
                <p class="h3">Book a Free <span>Consultation</span></p>
              </div>
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
  
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="Scroll"
                title="Scroll" />
            </picture>
          </div>
        </div>
  
      </div>
    </div>
  </section>
  <!-- Banner Section end -->
  
  <!-- Why Choose Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            Get to know our Expertise as a <span>Blockchain Development</span> Company In Spain
          </h2>
          <p>
            BlockchainX with profound knowledge and hands-on experience in blockchain development, here spotlighting the top feature to answer Why choose us as your Blockchain development partner in Spain.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/blockchain-app-development-process-includes.png"
              width="500"
              height="500"
              class="img-fluid pb-md-0 pb-3"
              alt="Blockchain App Development Process Includes"
              title="Blockchain App Development Process Includes" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/field.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/field.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/field.png"
                  class="img-fluid"
                  alt="Marketing"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Prompt Market Researches</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    We ensure to provide exceptional, relevant research and consultation assistance that promptly relies on providing better results. As a leading Blockchain development company in Spain, we ensure to address your market and captivate with decentralized solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/real-estate.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/real-estate.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/real-estate.png"
                  class="img-fluid"
                  alt="Marketing"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Tailor Made Solutions</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    We are experts at providing 100% customized decentralized solutions that are built from scratch addressing your requirements and needs to meet your expectations. And everything we integrate and bring in will support the progress of your operations. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/education.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/education.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/education.png"
                  class="img-fluid"
                  alt="Marketing"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Versatile Niches and Real World</h3>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p class="text-justify">
                Incorporating real-world use cases, our services extend to a variety of niches, from healthcare, education, and finance, to gaming, entertainment, and real estate, including retail, supply chain, and more. Our blockchain solutions ensure that you meet your needs more effectively and serve your particular needs. 
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/fashion.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/fashion.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/fashion.png"
                  class="img-fluid"
                  alt="Marketing"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Building Complete Infrastructure</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    From consultation to erecting the structure, and making it perform for the global audience, as a blockchain development company in Spain we curate intricate, robust, efficient, and cost-effective decentralized solutions as a full-fledged end-tot-end package that support you throughout your progress.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/game-development.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/game-development.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/game-development.png"
                  class="img-fluid"
                  alt="Marketing"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Audited Smart Contract </h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    Smart contracts in BlockchainX are writing codes that undergo various levels of testing and execution before being deployed and brought into actual action to ensure their credibility. Our codes are audited, we also provide smart contract auditing, and report generation services that help you in upgrading the same.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/marketing.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/marketing.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/marketing.png"
                  class="img-fluid"
                  alt="Marketing"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Innovative Integrations</h3>
                </div>
              </div>
              <div class="casestudy_para border-0">
                <p class="text-justify">
                    Get the perfect subordinates supporting your operations, by involving us - a Blockchain development company in Spain, providing end-to-end development solutions. We are open to advancing and leveling up your operation with new in technology like AI, VR, AR, ML, IoT, and others.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
  </section>
  <!-- Why Choose End -->
  
  <!-- Start -->
  <section class="section-service-main p-0">
    <div class="meta-expert">
      <div class="violet-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="our_experts">
              <h2><span>Scale-up your Opportunities with Blockchain</span> Development Services in UAE with Our Possibilities.</h2>
              <p class="text-center pb-3">
                Don’t miss out on your chance to explore the potential of Blockchain in your Business, and we as the leading blockchain development company in Spain can help you achieve it.
              </p>
              <div class="text-center my-4">
                <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Make Haste, Connect Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </section>
    <!-- End -->
    <div class="section-service-alt" #about>
      <div class="container">
        <div class="row dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/interplanetary-file-system-blockchain.webp" />
    
                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png" />
    
                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="IPFS Blockchain Development Services"
                  title="IPFS Blockchain Development Services" />
              </picture>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">
                Leading Tokenization Services in Spain
              </h3>
              <p class="text-justify">
                As a promising Blockchain development company in Spain, our tokenization services in Spain expand to tokenizing crypto coins to NFTs and other Real-world assets. We also extend our services in curating tokens on different blockchain networks, which include Ethereum, Hyperledger, Solana, Tron, and much more.
              </p>
              <p class="text-justify">
                Our blockchain tokenization services are highly customized and ensure they are brought to the right use, with relevant utility. Our tokenization services also extend to upgrading pre-existing tokens for better accessibility and usage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  
    <div class="section-service-main">
      <div class="container">
        <div class="row reverse dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Best ICO Services Provider in Spain</h3>
              <p class="text-justify">
                From Token development to listing and bringing the apt liquidity, Our ICO services stand out and its customizable. The best part of us coming in as your tech partner or blockchain development company in Spain is we facilitate 100% customization, and ensure the operations are smooth and effective.
              </p>
              <p class="text-justify">
                Through ICO services, the solution helps you benefit in terms of fundraising for your projects. Majorly concentrates on token sale platform development, afull-fledged process from designing the token to fixing its use cases, choice of network, listing, and so on, we ensure to provide a full-fledged service package.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />
    
                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/why-IPFS.png" />
    
                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="Why IPFS Development Services"
                  title="Why IPFS Development Services" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- End -->
  
  <!-- Start -->
  <div class="section-service-alt">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Innovative DApps Development Company in Spain</h3>
            <p class="text-justify">
                The future of day-to-day applications is going to be taken over by decentralized solutions. With the ability to serve immutability, accuracy, transparency, and effective operation along with the ease of efficiently performing functions, our DApps solution caters for all the sector and industry.
            </p>
            <p class="text-justify">
                Our customized decentralized solution built in our blockchain development company in Spain extends services in developing decentralized exchanges, staking platforms, NFT marketplace, and also contributes to developing a complete decentralized ecosystem.  
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- End -->
    <div class="section-service-main">
      <div class="container">
        <div class="row reverse dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Expert Smart Contracts Development in Spain</h3>
              <p class="text-justify">
                Smart contracts are self-executing codes that manage to perform particular tasks on a decentralized platform when meeting criteria. For them to be rightly coded, simply, yet to be well defined in terms of operations is mandatory to carry out operations at ease. Our smart contract expert in our blockchain development company in Spain curates highly qualified smart contracts. 
              </p>
              <p class="text-justify">
                Our smart contract development service includes smart contract development, Smart contract audits, smart contract report generation, and upgradation, also we ensure the smart contracts we develop are error-free and meet the priority to perform effectively. 
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />
    
                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/why-IPFS.png" />
    
                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="Why IPFS Development Services"
                  title="Why IPFS Development Services" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section-service-alt">
        <div class="container">
          <div class="call-to-main">
              <div class="orange-vector"></div>
            <div class="row justify-content-center mb-5">
              <div class="col-lg-10">
                <div class="metaverse_heading">
                  <h3><span>Lead Your Sector,</span> Avail Our Blockchain Development Services </h3>
                </div>
              </div>
            </div>
      
            <div class="row">
              <div class="col-lg-12 text-center">
                <!-- <p class="mb-sm-4 mb-3">
                  The process of converting an asset’s ownership rights into digital
                  tokens on a blockchain network is Asset tokenization. On the other
                  hand, the process of selling tokens in the marketplace to raise funds
                  for a particular project or business is a Token sale.
                </p> -->
                <p class="mb-5">
                  Looking for an Expert Blockchain development company in Spain? You are in the right place, and let's get started now,
                </p>
                <div class="token-vs-btn">
                  <a href="https://wa.me/7708889555" class="our_expert_talk"
                    target="_blank"
                    rel="noopener noreferrer">Connect with Us now!</a>
                </div>
              </div>
            </div>
          </div>
      
        </div>
      
      </section>
      
    
    <div class="section-service-main">
      <div class="container">
        <div class="row dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />
    
                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />
    
                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                  width="300"
                  height="300"
                  class="img-fluid"
                  alt="How IPFS Works"
                  title="How IPFS Works" />
              </picture>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Trusted Blockchain Development Agency in Spain</h3>
              <p class="text-justify">
                With 8 years of experience in the field of Blockchain development, we have actively worked and worked with multiple projects, in different domains across the world. As a pioneer and experienced blockchain development company in Spain, we curate end-to-end solutions that are decentralized and meet your requirements.
              </p>
              <p class="text-justify">
                Our qualified team of developers are excited and motivated to explore new-gen technologies and their dedication has paved the way to the success we now prevail. Together we ensure to give the best blockchain solution from us which is custom-built to meet your expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      
  <!-- Start -->
  <div class="section-service-alt">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Advanced Layer 1 and Layer 2 Blockchain Solutions in Spain</h3>
            <p class="text-justify">
                Layer 1 and 2 blockchain development is all Solutions built to make the network more efficient and non-jarring. With our experts at the blockchain development company in Spain, we built dedicated solutions that efficiently cater to your needs in the layer 1 and 2 blockchains. Eventually improves your operations, handles multiple transactions and still ensures to stay calm.
            </p>
            <p class="text-justify">
                We extend our support in all means in developing layer 1 and layer 2 networks making it robust and healthy to operate and ensuring doubling the security and ease of integration is smooth.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/why-IPFS.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Why IPFS Development Services"
                title="Why IPFS Development Services" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-service-main">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Premier cryptocurrency development company in Spain</h3>
            <p class="text-justify">
                Our cryptocurrency development service in Spain includes stablecoin development, governing tokens, in-game token development, crypto tokens for funding, and much more. We have experience in curating versatile cryptocurrency for different niches and purposes. And they have been sustainable in the market with significant growth.
            </p>
            <p class="text-justify">
                Including Ethereum, BSC, hyper ledger, Tether, Moonbean, and another network we provide crypto development services that ensure liquidity, funding, and scalability in the market.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- End -->
    <div class="section-service-alt">
      <div class="types-of-erc">
          <div class="container">
              <div class="row">
                  <h3 class="main-sub-heading text-center pb-0">
                    Our <span>Blockchain Solution</span> Impact Across Varied Industries In Spain
                  </h3>
                  <!-- <p>We Provide Tailored Web3 Development for Any</p> -->
              <div
                  class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 types-of-token-detail justify-content-center industry-web">
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/field.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/field.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/field.png"
                                      class="img-fluid"
                                      alt="Field"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Renewable Energy</h4>
                          <p>
                            By involving a Blockchain development company in Spain like us in your Renewable energy sector, the amount of traceability and accessibility that you could bring is massive. From understanding the supply range, seeing the present resource availability makes it efficient to make required arrangements and resource distribution effectively.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/real-estate.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/real-estate.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/real-estate.png"
                                      class="img-fluid"
                                      alt="Real Estate"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Technology and Innovation</h4>
                          <p>
                            As the Tech industry is massively growing with new interventions, Blockchain integrations bring a massive shift in amplifying operations. Our customized solutions and innovation would help you better organize and efficiently run your business through the platform.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/education.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/education.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/education.png"
                                      class="img-fluid"
                                      alt="Education"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Agritech and FoodTech</h4>
                          <p>
                            Agriculture and food, being the primary sectors, the involvement of blockchain technology in the scene can bring more accessibility. As a blockchain development company in Spain, we curate tailored platforms to get your agri-tech and food-tech-related IoT strong and reliable.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/fashion.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/fashion.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/fashion.png"
                                      class="img-fluid"
                                      alt="Fashion"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Healthcare and Biotechnology</h4>
                          <p>
                            The medicinal field on the whole is swiftly evolving and the quantity of data involved in it is massive. Through our blockchain solution, we cater to effective decentralized solutions meeting the requirements of managing mass data effectively and promptly with blockchain’s immutable ledge concepts.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/marketing.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/marketing.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/marketing.png"
                                      class="img-fluid"
                                      alt="Marketing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Real Estate and PropTech</h4>
                          <p>
                            The real estate market is a high-demanding, profit-making sector. Our team of Blockchain developers in Spain provides robust blockchain solutions effectively. This makes data tracking, analyzing progress, streamlining rental collections, and doing much more becomes simplified.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/game-development.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/game-development.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/game-development.png"
                                      class="img-fluid"
                                      alt="Game Development"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Tourism and Hospitality</h4>
                          <p>
                            Bringing in a decentralized immutable ledger through blockchain in hospitality and tourism, the non-linear data tracking becomes organized, and secured, and makes it more accessible for all. Eventually, there is a lot of innovation that can be brought through blockchain and AI into the tourism industry.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/e-commerce.webp" />
  
                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/e-commerce.png" />
  
                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/e-commerce.png"
                                      class="img-fluid"
                                      alt="E-commerce"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">E-commerce and Digital Retail</h4>
                          <p>
                            From automating inventories to getting precise stock analysis, and ensuring there is no loss of data, Blockchain can do wonders in the E-commerce and digital retail industry giving the buyer and seller more credibility as the intervention of man-made errors is considerably less.  
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  <section class="section-service-main">
    <div class="types-of-erc text-center">
        <div class="container">
            <div class="build-web3-solution">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-10">
                        <h3 class="main-sub-heading text-center">Embark your Web3 journey with our Customized   
                            <span>Blockchain Development Service in Spain</span>
                        </h3>
                    </div>
                </div>
                <p>
                    Let the world talk about Spain with your Invasion in Web3 and we as your customized Blockchain Development Company in Spain shall pave to your success! 
                </p>
                <div class="discuss-link">
                    <a href="/contact"><span>Connect With Our Team Of Experts Now!</span>
                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            Client <span> Success Stories </span>
          </h2>
          <p>
            Catch up on our client's success stories at BlockchainX. We have
            worked with various clients and on various projects in blockchain
            platform development. And here are some of the top projects in which
            we were involved.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="client-success">
          <owl-carousel-o [options]="clientSuccess">
            <ng-template carouselSlide>
              <div class="row pb-lg-3 pb-0">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Farmsent</h3>
                    <p class="pb-lg-3 pb-0">
                      Agriculture-based blockchain business solution. Connecting
                      farmers and customers directly benefits both in terms of
                      quality, price, and profit. Farmsent drives the
                      agriculture sector to the forefront with transparency and
                      agility.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <!-- <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/farmsent.webp"
                    />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/farmsent.png"
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/farmsent.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Farmsent"
                      title="Farmsent"

                    />
                  </picture> -->

                  <picture>
                    <source
                      srcset="
                      assets-new/images/home-v2/farmsent-400.webp 400w,
                      assets-new/images/home-v2/farmsent-600.webp 600w,
                      assets-new/images/home-v2/farmsent-800.webp 800w,
                      assets-new/images/home-v2/farmsent-1000.webp 1000w,
                      assets-new/images/home-v2/farmsent-1200.webp 1200w,
                      assets-new/images/home-v2/farmsent-1400.webp 1400w,
                      assets-new/images/home-v2/farmsent-1600.webp 1600w,
                      assets-new/images/home-v2/farmsent-1800.webp 1800w,
                      assets-new/images/home-v2/farmsent-2000.webp 2000w"
                      sizes="(min-width: 800px) 400px, 95vw"
                      type="image/webp" />
                    <source
                      srcset="
                      assets-new/images/home-v2/farmsent-400.png 400w,
                      assets-new/images/home-v2/farmsent-600.png 600w,
                      assets-new/images/home-v2/farmsent-800.png 800w,
                      assets-new/images/home-v2/farmsent-1000.png 1000w,
                      assets-new/images/home-v2/farmsent-1200.png 1200w,
                      assets-new/images/home-v2/farmsent-1400.png 1400w,
                      assets-new/images/home-v2/farmsent-1600.png 1600w,
                      assets-new/images/home-v2/farmsent-1800.png 1800w,
                      assets-new/images/home-v2/farmsent-2000.png 2000w"
                      sizes="(min-width: 800px) 400px, 95vw"
                      type="image/png" />
                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/farmsent-400.png"
                      class="img-fluid pb-md-0 pb-4"
                      width="400"
                      height="237"
                      alt="Farmsent"
                      title="Farmsent" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Real World NFT</h3>
                    <p class="pb-lg-3 pb-0">
                      Tokenizing real-world assets into NFTs is a trending
                      project. With the perks of transparency, fractional
                      ownership, and multiple revenue streaming options, there
                      is high traction in the market among investors and owners.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/real-world.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/real-world.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/real-world.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Real world"
                      title="Real world" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Layer One X</h3>
                    <p class="pb-lg-3 pb-0">
                      A community for developers to interact with fellow
                      developers, project holders, and investors. The platform's
                      ability to actively connect and interact drives the
                      community with consistent engagement.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/l1x.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/l1x.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/l1x.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Layer One X"
                      title="Layer One X" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Elfin Games</h3>
                    <p class="pb-lg-3 pb-0">
                      A hub of web3 games for users to explore and expand the
                      Play to Earn economy.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/elfin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/elfin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/elfin.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Elfin Games"
                      title="Elfin Games" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">BOBNet</h3>
                    <p class="pb-lg-3 pb-0">
                      Meme coin market intelligence helps users identify trading
                      trends and maximize their profits eventually.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/bobnet.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/bobnet.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/bobnet.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="BOBNet"
                      title="BOBNet" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Big Time Coin</h3>
                    <p class="pb-lg-3 pb-0">
                      The real estate tokenization platform benefits investors,
                      rentals, users, and the entire community with different
                      modules of operations.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/big-time.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/big-time.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/big-time.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Big Time Coin"
                      title="Big Time Coin" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Ekta</h3>
                    <p class="pb-lg-3 pb-0">
                      A decentralized ecosystem built with the ability to
                      perform everything and anything. Being a blockchain
                      itself, it renders and supports DAO and has its own
                      marketplace and token to interact with.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/ekta.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/ekta.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/ekta.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Ekta"
                      title="Ekta" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">StarCard Sports Games</h3>
                    <p class="pb-lg-3 pb-0">
                      A play-to-earn decentralized gaming arena was developed to
                      connect real-world players through digital gaming. This
                      game is based on football, along with metaverse abilities
                      that draw income.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/starcard.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/starcard.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/starcard.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="StarCard Sports Games"
                      title="StarCard Sports Games" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Bounty Temple</h3>
                    <p class="pb-lg-3 pb-0">
                      The play-to-earn gaming community for gaming enthusiasts
                      to explore the endless possibilities of the web3 arena,
                      providing opportunities to monetize and maximize benefits.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/bounty.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/bounty.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/bounty.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Bounty Temple"
                      title="Bounty Temple" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Zukiverse</h3>
                    <p class="pb-lg-3 pb-0">
                      Zuki is a P2E gaming platform that is a community-oriented
                      space that builds characters, virtual goods, puzzle games,
                      quizzes, and metaverse structures all in one.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/zuki.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/zuki.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/zuki.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Zukiverse"
                      title="Zukiverse" />
                  </picture>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5">
          <div class="c-animation">
            <div class="common-sub-heading sub-title">
              Principles <span class="dots">.</span>
            </div>
            <h3 class="main-sub-heading">
                Our Working Procedures As A <span>Blockchain Development</span> Company In Spain
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="green-vector"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Gathering Basics  - <span class="d-block">The Initial Phase</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                From understanding the need for you to reach, we stick keen and close to understanding your requirements and expectations. Eventually, we proceed with giving our reflections, and do a second phase of requirement gathering that are numerical and statistical.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Papers To Prototype - <span class="d-block">Pre-Development Phase</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Moving from papers to layouts and layering where we come up with a complete setup and suggestions that can be altered. As a blockchain development company in Spain, We ensure to meet your style and resonate with your vibe.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Building Structure  -  <span class="d-block"> Architectural Phase</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Then we go further with curating the structure of the platform on a wireframe, ensuring it meets the choice of network and requirements you want to be met from our side. The wire gives a detailed structure of the operation and execution of the platform.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Customized Level Ups - <span class="d-block">Infrastructuring</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                From the wireframes, based on your suggestions, we proceed ahead to build the blockchain platform to serve your purpose. Based on the trends and dynamics of the market, we bring in better operational features to support and enhance the experience. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['dao-development-company']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Amplifying The Possibilities - <span class="d-block">Integrational Phase</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                By incorporating more efficient tech integration into the platform we ensure its subordinates the operations and contribute to effective performance. From the choice of blockchain network to integrating smart contract and security systems they ensure to meet the needful.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Trial Run - <span class="d-block">Testing Phase</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                As the blockchain platform is now brought together into a structure, at our blockchain development company in Spain, we make sure of the functions and operations being tested to ensure the platform performs well. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Global Release - <span class="d-block">Final Launch</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Post the Beta testing in the test net, once things are rectified, and receive your satisfactory approval we launch the Blockchain platform in the mainnet for the world. And help you get things on track. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Building Strength - <span class="d-block">Maintenance and Support</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Post the main launch based on how the market receives the blockchain platform, we also provide support on upgrading it effectively.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
<section class="section-service-main">
  <div class="container">
    <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
      <div class="commontext_wrap">
        <h2 class="text-start">Connect With Our Tech Geek and Blockchian Specialist - Yokesh Shankar COO</h2>
        <p>A vibrant person with sheer knowledge on Blockchain trends and evolution around Web3. His expertise in the niche branches to different sectors from fintech, and supply chain to manufacturing, agriculture and more.<a class="hire-link" href="https://www.blockchainx.tech/contact/" target="_blank">Book your call now!</a></p>
      </div>

      <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
        <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
          <div>
            <img loading="lazy"
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150" class="img-fluid rounded-circle"/>
          </div>
          <div class="para-text">
            <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span>
            <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="py-2"
                  rel="noopener noreferrer">Co - Founder and COO</a></span>
           <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="pb-2"
                  rel="noopener noreferrer">Blockchain Consultant</a></span></p>
            <a href="mailto:contact@blockchainx.tech" target="_blank"
              rel="noopener noreferrer">contact@blockchainx.tech</a>
          </div>
        </div>
        <div class="discuss-link pt-0">
          <a [routerLink]="['/contact']">
            <span>Connect With Us!</span>
            <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
          </a>
        </div>
      </div>
      <div>
        <h2 class="text-start">Hire Blockchain Developer At BlockchainX</h2>
        <p>
            Our curious team of tech enthusiasts, have hands-on experience in blockchain development and actively contributed to our successful projects. We are open for you to Hire them as your Blockchain Developer directly.
        </p>
      </div>
    </div>
  </div>
</section>

  <!-- why-choose-section -->
    <!-- End -->
  
  <!-- FAQ Start -->
  <section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionEx">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    How Can Blockchain Benefit My Business?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      Blockchain brings in an immutable, transparent, and secure environment for your business to perform effectively. From a centralized authority, it takes you to a decentralized ecosystem where the authority, control, and accessibility are customized for you and your business. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How can I start blockchain development?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        If you wanna start your blockchain or web3 journey now, I would simply say “Connect with us!” Let us together co-create a masterpiece in the digital evolution. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Can I create my own blockchain?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        Of Course, you can create your own blockchain network. With our expert blockchain development consultation in Spain, we can provide you with the best solution to create your own network. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    What is the cost to build a blockchain?
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        The cost involved in developing or building a blockchain depends on external factors and choices that are very subjective to the clients' needs and requirements. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive">
                    What is the Future of Blockchain Technology?
                  </button>
                </div>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        As the trend evolves and the technology upgrades, there will be new updates and better contributions from subordinate technologies like AI, AR VR, ML, and much more that can contribute vastly to Blockchain. Eventually, as the global market prospers, blockchain evolution continues.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix">
                    What are the different Industries that benefited from Blockchain?
                  </button>
                </div>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        There is no such barrier to entry, blockchain welcomes almost every sector, from agriculture, production, and manufacturing to gaming, education, finance, and more.  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>
  <!-- FAQ End -->
  
  
  <!-- Contact Start -->
  <section class="section-service-main">
    <app-blog></app-blog>
</section>

<section class="section-service-main">
    <app-get-in-touch></app-get-in-touch>
</section>
  <!-- Contact End -->
    <!-- Contact End -->