<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span
                  ><strong>DeFi Development </strong>
                  Company</span
                >
              </h1>

              <p>
                Step into the future of finance & scale up your business with
                our DeFi development services. Our technical experts at
                BlockchainX will assist you in gaining knowledge and exploring
                the potential of DeFi. Begin your journey right now!
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/decentralized-finance-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/decentralized-finance-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/decentralized-finance-development.png"
                class="img-fluid"
                alt="DeFi Development"
                title="DeFi Development"
                width="500"
                height="500"

              />
            </picture>
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/light-theme/decentralized-finance-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/light-theme/decentralized-finance-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/light-theme/decentralized-finance-development.png"
                class="img-fluid"
                alt="DeFi Development"
                title="DeFi Development"
                width="500"
                height="500"

              />
            </picture>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png"
            />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends  -->

<!-- DeFi Development Company -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row reverse dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/defi-development-company.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/defi-development-company.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/defi-development-company.png"
              class="img-fluid"
              alt="Decentralized Finance Development"
              title="Decentralized Finance Development"
              width="500"
              height="500"

            />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="whys_section mb-0">
          <h2><span>Decentralized Finance</span> Development</h2>
          <p>
            As a leading DeFi development company, we help you build secure &
            robust DeFi platforms and solutions for a wide range of use cases
            across industries. Our full-cycle DeFi development services cover a
            wide range of highly desirable DeFi solutions that provide greater
            transparency, from dApps & wallets to DEXs & tokens. Our BlockchainX
            experts work with you to capture new opportunities in the world of
            decentralized finance, and we are a thriving extended partner,
            providing comprehensive DeFi development services.
        </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- DeFi Development Company -->

<!-- Our DeFi Development Services -->
<div class="section-service-alt-main">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>Our <span>DeFi Development</span> Services</h3>
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center justify-content-center process-erc">
         <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center process-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/defi-dapps-development.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/defi-dapps-development.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/defi-dapps-development.png"
              class="img-fluid"
              alt="DeFi dApps Development"
              title="DeFi dApps Development"
              width="100"
              height="100"

            />
          </picture>
          <h4 class="process-head py-3">DeFi dApps Development</h4>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center process-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/defi-smart-contracts-development.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/defi-smart-contracts-development.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/defi-smart-contracts-development.png"
              class="img-fluid"
              alt="DeFi Smart Contract Development"
              title="DeFi Smart Contract Development"
              width="100"
              height="100"

            />
          </picture>
          <h4 class="process-head py-3">DeFi Smart Contracts Development</h4>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center process-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/deFi-wallet-development.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/deFi-wallet-development.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/deFi-wallet-development.png"
              class="img-fluid"
              alt="DeFi Wallet Development"
              title="DeFi Wallet Development"
              width="100"
              height="100"

            />
          </picture>
          <h4 class="process-head py-3">DeFi Wallet Development</h4>
        </div>
         <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center process-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/defi-wallet-development-to.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/defi-wallet-development-to.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/defi-wallet-development-to.png"
              class="img-fluid"
              alt="DeFi Wallet Development"
              title="DeFi Wallet Development"
              width="100"
              height="100"

            />
          </picture>
          <h4 class="process-head py-3">
            DeFi Wallet Development TO DeFi Lending Platform Development
          </h4>
        </div>
         <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center process-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/defi-token-development.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/defi-token-development.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/defi-token-development.png"
              class="img-fluid"
              alt="DeFi Token Development"
              title="DeFi Wallet Development"
              width="100"
              height="100"

            />
          </picture>
          <h4 class="process-head py-3">DeFi Token Development</h4>
        </div>
         <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center process-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/defi-development-company/defi-exchange-development.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/defi-development-company/defi-exchange-development.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/defi-development-company/defi-exchange-development.png"
              class="img-fluid"
              alt="DeFi Exchange Development"
              width="100"
              height="100"

            />
          </picture>
          <h4 class="process-head py-3">DeFi Exchange Development</h4>
        </div>
      </div> -->

      <div class="row erc-processing">
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/defi-dapps-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/defi-dapps-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/defi-dapps-development.png"
                class="img-fluid pb-2"
                alt="DeFi dApps Development"
                title="DeFi dApps Development"
                width="110"
                height="110"

              />
            </picture>

            <h4>DeFi dApps Development</h4>
            <p>
              We offer best DeFi Dapp development services which eliminate
              third-party involvement & our Dapps are built on DeFi protocols
              for increased transparency.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/defi-smart-contracts-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/defi-smart-contracts-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/defi-smart-contracts-development.png"
                class="img-fluid pb-2"
                alt="DeFi Smart Contract Development"
                title="DeFi Smart Contract Development"
                width="110"
                height="110"

              />
            </picture>
            <h4>DeFi Smart Contracts Development</h4>
            <p>
              We build DeFi smart contracts that are free from potential
              vulnerabilities & can work automatically based on predefined
              conditions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/deFi-wallet-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/deFi-wallet-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/deFi-wallet-development.png"
                class="img-fluid pb-2"
                alt="DeFi Wallet Development"
                title="DeFi Wallet Development"
                width="110"
                height="110"

              />
            </picture>
            <h4>DeFi Wallet Development</h4>
            <p>
              Our team builds DeFi wallets & ensures traders have control over
              their funds & secure their data without relying on a third party.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/defi-wallet-development-to.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/defi-wallet-development-to.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/defi-wallet-development-to.png"
                class="img-fluid pb-2"
                alt="DeFi Wallet Development"
                title="DeFi Wallet Development"
                width="110"
                height="110"

              />
            </picture>
            <h4>
              DeFi Wallet Development TO DeFi Lending Platform Development
            </h4>
            <p>
              We create a safe & permissionless DeFi lending platform that
              allows users to supply & lock funds, which can be borrowed by
              paying interest.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/defi-token-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/defi-token-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/defi-token-development.png"
                class="img-fluid pb-2"
                alt="DeFi Token Development"
                title="DeFi Wallet Development"
                width="110"
                height="110"

              />
            </picture>
            <h4>DeFi Token Development</h4>
            <p>
              We work with you to create & launch a DeFi token from scratch. We
              have expertise in security & equipment tokens for various
              blockchain platforms.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/defi-exchange-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/defi-exchange-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/defi-exchange-development.png"
                class="img-fluid pb-2"
                alt="DeFi Exchange Development"
                width="110"
                height="110"

              />
            </picture>
            <h4>DeFi Exchange Development</h4>
            <p>
              We build a DeFi exchange to help you trade DeFi tokens and manage
              DeFi wallets, as well as give users control over privacy & data.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our DeFi Development Services -->

<!-- Benefits of DeFi Development -->
<div class="section-service-alt-padd">
  <div class="our-process-erc">
    <div class="container">
      <div class="row our-solid-common">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>Benefits of <span>DeFi Development</span></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center erc-processing">
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Highly Secure</h4>
            <p>
              A peer-to-peer mechanism in blockchain networks can prevent the
              chances of data breaches & abrupt shutdowns.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Interoperability</h4>
            <p>
              Our DeFi development team integrates multiple DeFi apps into a
              single module, making it a user centric platform.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Automation</h4>
            <p>
              The use of smart contracts eliminates the need for manual
              intervention, allowing for full-fledged automation.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process">
            <h4>Pseudonymous Transactions</h4>
            <p>
              A distributed ledger network ensures the anonymity of user
              transactions & does not reveal the user's identity.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Benefits of DeFi Development -->

<!-- Product Features -->
<div class="section-service-alt-main">
  <div class="product-feature">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>DeFi Use Cases</span> BlockchainX Specializes In</h3>
        </div>
        <p class="py-3 text-center">
          Our uniswap clone script allows you to create a decentralized exchange
          with all the features of popular DEX platforms.
        </p>
      </div>
      <div class="row produt-feature-detail">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/payments.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/payments.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/payments.png"
                class="img-fluid pb-3"
                alt="DeFi Exchange Development"
                title="DeFi Exchange Development"
                width="110"
                height="110"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Payments</h4>
          <p>
            Secure, fast & affordable for domestic & global payment
            transactions, including cryptocurrency transactions, without the use
            of middlemen.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/lending-and-borrowing.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/lending-and-borrowing.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/lending-and-borrowing.png"
                class="img-fluid pb-3"
                alt="Lending and Borrow"
                title="Lending and Borrow"
                width="110"
                height="110"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Lending and borrowing</h4>
          <p>
            Highly accessible & cost-effective P2P cryptocurrency loans with no
            middlemen & it offers borrowers fast access to funds & investment
            opportunities for lenders.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/cryptocurrency-exchange.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/cryptocurrency-exchange.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/cryptocurrency-exchange.png"
                class="img-fluid pb-3"
                width="110"
                height="110"
                alt="Cryptocurrency exchange"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Cryptocurrency exchange</h4>
          <p>
            Peer-to-peer cryptocurrency exchange that is quick and secure with
            full user control over owned funds & market-established conversion
            rates.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/fundraising.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/fundraising.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/fundraising.png"
                class="img-fluid pb-3"
                width="110"
                height="110"
                alt="Fundraising"
                title="Fundraising"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Fundraising</h4>
          <p>
            Gaining access on global audience will speed up fundrasing.
            Immutable record of financial transactions to maintain trust across
            crowdfunding projects.
          </p>
        </div>
      </div>
      <div class="row produt-feature-detail">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/crypto-asset-management.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/crypto-asset-management.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/crypto-asset-management.png"
                class="img-fluid pb-3"
                width="110"
                height="110"
                alt="Crypto asset management"
                title="Crypto asset management"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Crypto asset management</h4>
          <p>
            Streamlined management, quick and easy transfer of crypto assets.
            The creating, managing, and trading of derivative assets.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/defi-insurance.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/defi-insurance.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/defi-insurance.png"
                class="img-fluid pb-3"
                width="110"
                height="110"
                alt="DeFi insurance"
                title="DeFi insurance"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">DeFi insurance</h4>
          <p>
            To mitigate technical and financial risks, protect crypto assets
            against crypto volatility and security vulnerabilities.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/yield-farming.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/yield-farming.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/yield-farming.png"
                class="img-fluid pb-3"
                width="110"
                height="110"
                alt="Yield farming"
                title="Yield farming"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Yield farming</h4>
          <p>
            Earning passive income by temporarily locking up crypto assets in
            the liquidity pools of multiple DeFi platforms
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="product-feature-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/defi-development-company/staking.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/defi-development-company/staking.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/defi-development-company/staking.png"
                class="img-fluid pb-3"
                width="110"
                height="110"
                alt="Staking"
                title="Staking"

              />
            </picture>
          </div>
          <h4 class="common-sub-heading-alt">Staking</h4>
          <p>
            Crypto funds are locked up to secure the blockchain network, which
            employs the proof-of-stake consensus protocol in return for rewards.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Features -->

<!-- business-tech-stack -->
<div class="section-service-alt-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading">
          <h3>Our Technology <span>Expertise</span></h3>
        </div>
      </div>
    </div>
    <div
      class="row row-cols-3 row-cols-sm-3 row-cols-md-6 row-cols-lg-7 justify-content-center text-center app-dapps-common"
    >
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/apecoin.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/apecoin.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/apecoin.png"
              alt="Apecoin"
              title="Apecoin"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/cosmos.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/cosmos.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/cosmos.png"
              alt="Cosmos"
              title="Cosmos"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/cosmos.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/cosmos.png"
            />

            <img loading="lazy"
            data-src="assets-new/images/business-tech/cosmos.png"
            alt="Cosmos"
            title="Cosmos"
            width="80"
            height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/daicoin.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/daicoin.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/daicoin.png"
              alt="Daicoin"
              title="Daicoin"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/decentraland.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/decentraland.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/decentraland.png"
              alt="Decentraland"
              title="Decentraland"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/elrond.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/elrond.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/elrond.png"
              alt="Elrond"
              title="Elrond"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/flow.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/flow.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/flow.png"
              alt="Flow"
              title="Flow"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/ftx.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/ftx.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/ftx.png"
              alt="FTX"
              title="FTX"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/hedera.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/hedera.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/hedera.png"
              alt="Hedera"
              title="Hedera"
              width="80"
              height="80"

            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- business-tech-stack -->

<!-- Benefits -->
<div class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>Why Choose Us as Your <span>DeFi Development Company</span></h3>
    </div>
    <div class="row acc-sec-list">
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center acc-image"
      >
      <picture>
        <source
          type="image/webp"
          srcset="assets-new/images/erc/webp/erc-token.webp"
        />

        <source
          type="image/png"
          srcset="assets-new/images/erc/erc-token.png"
        />

        <img loading="lazy"
          data-src="assets-new/images/erc/erc-token.png"
          width="587"
          height="443"
          class="img-fluid"
          alt="Why-choose-defi-development company"
          title="Why-choose-defi-development company"

        />
      </picture>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Technical Process
              </button>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  We exclusively work with blockchain technologies & we
                  specialize in one thing and excel at it.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Expert Team
              </button>
            </div>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  Our experts help to fine-tune your offering & suggest ideal
                  tech approach & set up communities
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Rapid Development
              </button>
            </div>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  We deliver customized products & our well-planned roadmap
                  ensures smooth & rapid development.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Complete Support
              </button>
            </div>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  Our work does not end with release of the product. We offer
                  complete post-delivery services that focus on your growth.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Meaningful Outcomes
              </button>
            </div>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  We make a visible difference. We ensure that you get the most
                  out of your investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Benefits -->
<section class="section-service-main">
<app-blog></app-blog>
</section>
<!-- <section class="section-service-alt">
<app-get-in-touch></app-get-in-touch>
</section> -->
<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>
