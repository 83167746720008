import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FooterServiceService } from '../service/footer-service.service';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.css']
})
export class HeaderNewComponent implements OnInit {
    Theme='dark-theme';
    isDarkmode:boolean=false;
    constructor(@Inject(DOCUMENT) private document:Document, private renderer:Renderer2, private footservice:FooterServiceService){}
    ngOnInit(): void {
      this.intializetheme();
    }
    switchtheme(){
      this.isDarkmode= !this.isDarkmode;
      console.log(this.isDarkmode);
      this.footservice.toggleLogo(this.isDarkmode)
      this.document.body.classList.replace(this.Theme, this.Theme === 'dark-theme' ?(this.Theme='light-theme') :(this.Theme='dark-theme')  );
    }
    intializetheme=():void=>this.renderer.addClass(this.document.body,this.Theme);

    public isCollapsed = false;
    menuBtn(event:any){
     event.classList.toggle('bi-x');
   }

  //  onClick(){
  //   this.route.navigate(['/contact-us'])
  //  }
  //  onClick2(){
  //   this.route.navigate(['/development-methodology'])
  //  }
   @HostListener('document:click')
  clickout() {
  }
  }

  export type Theme = 'light-mode' | 'dark-mode';
