<section class="blogs">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="d-flex justify-content-between">
        <div class="common-sub-heading sub-title">
          Our Latest Blogs<span class="dots">.</span>
        </div>
        <div class="deploy-link justify-content-end">
          <a [routerLink]="['/blog']">
            <!-- <span>Read More</span> -->
            <img
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode"
                />
                <img
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode"
                />
          </a>
        </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/precious-metal-tokenization-guide/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/precious-metal-token/precious-metals-tokenization.webp">
                  <img loading="lazy" src="assets-new/images/blog/precious-metal-token/precious-metals-tokenization.jpg" alt="Precious Metal Tokenization" title="Precious Metal Tokenization" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  Precious Metal Tokenization - Complete Guide On Transformative Investments
                </p>
                <p>
                  However, this 21st century is all about crypto tokens, blockchain, and its relatives, the hype that real-world asset tokenization gained...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/erc20-token-development-companies/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/erc20-token-development-companies/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/top-erc20-token/erc20-token-development-companies.webp">
                  <img loading="lazy" src="assets-new/images/blog/top-erc20-token/erc20-token-development-companies.jpg" alt="ERC20 Token Development Companies" title="ERC20 Token Development Companies" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  Top 15 ERC20 Token Development Companies
                </p>
                <p>
                  25.74% growth in the past 7 days, while the market is highly favorable for better progression. But what is it? If you land it to be Cryptocurrency...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/erc20-token-development-companies/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-your-own-crypto-token/">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token-banner.webp
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token-banner.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="8 Steps to Create Your Own Crypto Token: A Comprehensive Guide"
                    title="8 Steps to Create Your Own Crypto Token: A Comprehensive Guide"
                  />
                </picture>
                <p class="h-3">
                  8 Steps to Create Your Own Crypto Token: A Comprehensive Guide
                </p>
                <p>
                  Over the decade, the popularity and strength of cryptocurrencies have increased a lot, challenging the conventional ways of making...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-your-own-crypto-token/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/cross-chain-interoperability-protocol']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/cross-chain-interoperability-protocol-banner.webp
                    "
                  />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/cross-chain-interoperability-protocol-banner.jpg
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/cross-chain-interoperability-protocol-banner.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="Cross Chain Interoperability Protocol"
                    title="Cross Chain Interoperability Protocol"
                  />
                </picture>
                <p class="h-3">
                  Cross chain Interoperability Protocol Enriching Blockchain Communications
                </p>
                <p>
                  Blockchain, a vibrant and evolving technology. With decentralization, it's been pulling a lot of traction and eventually getting better...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/cross-chain-interoperability-protocol/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/top-10-real-estate-tokenization-companies']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/top-10-real-estate/real-estate-tokenization.webp
                    "
                  />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/top-10-real-estate/real-estate-tokenization.jpg
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/top-10-real-estate/real-estate-tokenization.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="Top 10 Real Estate Tokenization Companies"
                    title="Top 10 Real Estate Tokenization Companies"
                  />
                </picture>
                <p class="h-3">
                  Top 10 Real Estate Tokenization Companies
                </p>
                <p>
                  Tokenization of real estate refers to the conversion of digital assets right into a shape that makes it simple for corporations...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/top-10-real-estate-tokenization-companies']">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/blockchain-development-cost']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/blockchain-development-cost/blockchain-development.webp
                    "
                  />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/blockchain-development-cost/blockchain-development.jpg
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/blockchain-development-cost/blockchain-development.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="Blockchain Development"
                    title="How Much Does Blockchain Development Cost in 2024?"
                  />
                </picture>
                <p class="h-3">
                  How Much Does Blockchain Development Cost in 2024?
                </p>
                <p>
                  If you are looking for secure and transparent transactions, Blockchain technology can be your safest companion. Apart ...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/blockchain-development-cost']">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/blockchain-using-abstraction-development']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/blockchain-abstractions/blockchain-abstractions.webp
                    "
                  />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/blockchain-abstractions/blockchain-abstractions.jpg
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/blockchain-abstractions/blockchain-abstractions.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="Blockchain Abstraction-img"
                    title="Blockchain Abstraction Development"
                  />
                </picture>
                <p class="h-3">
                  Future Of Wallets With Blockchain Abstraction Development
                </p>
                <p>
                  Blockchain technology has been rapidly gaining popularity for its decentralized and transparent nature and Ethereum has great...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/blockchain-using-abstraction-development']">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/introduction-to-base-layer-2-blockchain-development']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/base-layer-blockchain-development/layer-2-blockchain-development.webp
                    "
                  />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/base-layer-blockchain-development/layer-2-blockchain-development.jpg
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/base-layer-blockchain-development/layer-2-blockchain-development.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="An Introduction to Base Layer 2 Blockchain Development: Getting Started Guide"
                    title="An Introduction to Base Layer 2 Blockchain Development: Getting Started Guide"
                  />
                </picture>
                <p class="h-3">
                  An Introduction to Base Layer 2 Blockchain Development: Getting Started Guide
                </p>
                <p>
                  Today, platforms like Ethereum, Bitcoin, and other Layer 1 blockchain have completely changed the way you think about digital...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/introduction-to-base-layer-2-blockchain-development']">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template> 
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
