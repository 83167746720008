export const newsData = [
  {
    imgSrc:
      'assets-new/images/news-details/eu-mica/eu-mica-regulations.png',
    imgAlt: 'Crypto MiCA regulations 2024 Unfolds Direction and Segway',
    title:
      'Crypto MiCA regulations 2024 Unfolds Direction and Segway',
    description:
      ' MiCA - Market in Crypto Assets  Regulations from the European Union is the first jurisdiction that was tailored focussing crypto law, that flashes clarity on legal certainty and challenges in compliance and other global implications. ',
    link: {
      href: '/eu-mica-regulations-unfolds-direction-segway',
      text: 'Read More',
    },
  },
  {
    imgSrc:
      'assets-new/images/news-details/memestocks/roaring-kitty-meme-coins.png',
    imgAlt: 'Roaring Kitty Meme Coins Meowing Louder Than Gamestops and AMC',
    title:
      'Roaring Kitty Meme Coins Meowing Louder Than Gamestops and AMC',
    description:
      ' There is a recent shift in the meme stocks, while Gamestops and AMC entertainment are performing high, the Roaring Kitties (ROAR)as anticipated by Keith Gill - an Online Persona are stepping the pedestal with upward momentum.',
    link: {
      href: '/memestocks-surge-as-roaringkitty-returns',
      text: 'Read More',
    },
  },
    {
      imgSrc:
        'assets-new/images/news-details/web3-empowered/web3-communication-platforms.png',
      imgAlt: 'web3-communication-platforms',
      title:
        'Remodeled Web3 Communication Platforms Reverting Control Back To Users',
      description:
        ' Blockchain technology is the revolutionary of the era, acting upon the key issues of interoperability, data ownership, and privacy. New ideators are transforming into brands that establish possibilities, empower, and provide users—what is theirs is theirs.',
      link: {
        href: '/web3-empowered-communication-platform',
        text: 'Read More',
      },
    },
    {
      imgSrc:
        'assets-new/images/news-details/bitcoin-spot-etfs/etf.jpg',
      imgAlt: 'best ETF',
      title:
        'BTC Heading the Best ETFs Parade While ETH In Que',
      description:
        ' So, value claims The Bitcoin spot ETF on January 31, 2024, brought in a massive inflow of $197 million into ETFs, and it extended for four consecutive days.',
      link: {
        href: '/bitcoin-spot-etfs',
        text: 'Read More',
      },
    }
   
  ];
  