import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-fashion-brands-establishing-position-web3',
  templateUrl: './fashion-brands-establishing-position-web3.component.html',
  styleUrls: ['./fashion-brands-establishing-position-web3.component.css'],
})
export class FashionBrandsEstablishingPositionWeb3Component implements OnInit {
  title = 'Web3 Fashion Redefining Fashion Trends';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private footservice:FooterServiceService,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
        //darkmode services 
        this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
          if (response) {
            this.isDarkmode = response;
          }
          else {
            this.isDarkmode = response;
          }
        });
        
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'For leading fashion brands, understanding the market demand and customer expectations is a driving strategy to use. And thats when you decide, when do you take your brand into Web3 fashion?',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'web3 fashion,fashion in web3, fashion brand in web3, fashion web3',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Web3 Fashion Redefining Fashion Trends',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/fashion-brands-establishing-position-web3/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'For leading fashion brands, understanding the market demand and customer expectations is a driving strategy to use. And thats when you decide, when do you take your brand into Web3 fashion?',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/fashion-brands-establishing-position-web3.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/fashion-brands-establishing-position-web3/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Web3 Fashion Redefining Fashion Trends',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'For leading fashion brands, understanding the market demand and customer expectations is a driving strategy to use. And thats when you decide, when do you take your brand into Web3 fashion?',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/fashion-brands-establishing-position-web3.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/fashion-brands-establishing-position-web3/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/fashion-brands-establishing-position-web3.html'
    );
    this.doc.head.appendChild(link);
  }
}
