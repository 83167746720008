import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from './../../shared/canonical.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
})
export class InsuranceComponent implements OnInit {
  selectedLink:number;
  title =
    "Potential Benefits and Use Cases of Blockchain in Insurance";
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Blockchain in insurance is transforming the industry by providing myriad benefits in the form of lower costs, improved customer experiences, enhanced productivity, increased transparency, and more.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'blockchain in insurance, blockchain technology in insurance, blockchain technology in insurance industry, blockchain application in insurance',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        "Potential Benefits and Use Cases of Blockchain in Insurance",
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/blockchain-in-insurance/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Blockchain in insurance is transforming the industry by providing myriad benefits in the form of lower costs, improved customer experiences, enhanced productivity, increased transparency, and more.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/industries/blockchain-in-insurance.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/blockchain-in-insurance/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        "Potential Benefits and Use Cases of Blockchain in Insurance",
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Blockchain in insurance is transforming the industry by providing myriad benefits in the form of lower costs, improved customer experiences, enhanced productivity, increased transparency, and more.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/industries/blockchain-in-insurance.jpg',
    });

       // Add Canonical tag
       this.canonicalService.setCanonicalURL(
        'https://www.blockchainx.tech/blockchain-in-insurance/'
      );
      this.createLink();
    }
    createLink() {
      this.canonicalService.destroyLinkForAmpURL();
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'amphtml');
      link.setAttribute(
        'href',
        'https://www.blockchainx.tech/amp/blockchain-in-insurance.html'
      );
      this.doc.head.appendChild(link);
    }
    addClass(id:number){
      this.selectedLink=id;
    }
}
