import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-realestate-tokenization-on-blockchain',
  templateUrl: './realestate-tokenization-on-blockchain.component.html',
  styleUrls: ['./realestate-tokenization-on-blockchain.component.css']
})
export class RealestateTokenizationOnBlockchainComponent implements OnInit {

  selectedLink:number;
  title ='Real Estate Tokenization on Blockchain';

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;

  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'How can tokenization of real estate using blockchain technology be beneficial? Here is a detailed run-through to tap into its nuances. Check it out now!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'tokenization of real estate using blockchain technology, tokenization of real estate, tokenized real estate',
    });

    
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Real Estate Tokenization on Blockchain',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/realestate-tokenization-on-blockchain/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'How can tokenization of real estate using blockchain technology be beneficial? Here is a detailed run-through to tap into its nuances. Check it out now!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/asset-tokenization/asset-tokenization-on-blockchain.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/realestate-tokenization-on-blockchain/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Real Estate Tokenization on Blockchain',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'How can tokenization of real estate using blockchain technology be beneficial? Here is a detailed run-through to tap into its nuances. Check it out now!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/asset-tokenization/asset-tokenization-on-blockchain.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/realestate-tokenization-on-blockchain/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/realestate-tokenization-on-blockchain.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}