import {
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-mev-protection',
  templateUrl: './mev-protection.component.html',
  styleUrls: ['./mev-protection.component.css']
})
export class MevProtectionComponent implements OnInit {


  bitrixSnippetScript: SafeScript;

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private doc: Document
  ) {
    const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      // Perform a null check before calling parentNode.insertBefore
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
        // You can append the script to the <body> element or take another appropriate action.
        document.body.appendChild(s);
      }
    })(
      window,
      document,
      'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    )};</script>`;
    this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(
      `${scriptUrl}`
    );
  }


  isDarkmode:boolean=false;
  title = 'For Secured Fair Transaction MEV Protection Development';

  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        "No more unfair pricing for transactions from the miner side, now it's regulated and screened with the help of MEV Protection solutions developed to make fair trade and transactions in the blockchain space. Tap to know more.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'mev protection,mev protection services,mev protection development,mev protection solution,mev protection development solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'For Secured Fair Transaction MEV Protection Development',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/mev-protection-development-solution/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
      "No more unfair pricing for transactions from the miner side, now it's regulated and screened with the help of MEV Protection solutions developed to make fair trade and transactions in the blockchain space. Tap to know more.",
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/mev-protection.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/mev-protection-development-solution/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'For Secured Fair Transaction MEV Protection Development',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
      "No more unfair pricing for transactions from the miner side, now it's regulated and screened with the help of MEV Protection solutions developed to make fair trade and transactions in the blockchain space. Tap to know more.",
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/mev-protection.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/mev-protection-development-solution/'
    );
  }
  
  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
