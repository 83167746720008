<!-- Banner Section Starts -->
<section class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span>
                  <strong> Crypto Wallet </strong> Development
                </span>
              </h1>
              <p>
                Empower Transactions now with secured, customized, and efficient Crypto Wallet development Services at BlockchainX, ready to build based on your operational requirements.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/webp/cryptocurrency-wallet-development-company.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/cryptocurrency-wallet-development-company.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/cryptocurrency-wallet-development-company.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="build-your-own-bitcoin-wallet"
                title="build-your-own-bitcoin-wallet" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="
                assets-new/images/product/light-theme/cryptocurrency-wallet-development-company.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/product/light-theme/cryptocurrency-wallet-development-company.png" />

              <img loading="lazy"
                data-src="assets-new/images/product/light-theme/cryptocurrency-wallet-development-company.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="build-your-own-bitcoin-wallet"
                title="build-your-own-bitcoin-wallet" />
            </picture>
          </div>
        </div>
      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Banner Ends -->

<!-- What is ERC20 start-->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Our Crypto Wallet App </span>Development Concentrates On</h2>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-5 text-center mx-auto order-lg-first order-last">
        <div class="bep-work-section pb-0">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Custom Blockchain Development"
              title="Custom Blockchain Development" class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="common-sub-heading pb-lg-0 pb-sm-5 pb-4">
          <p>
            As a leading cryptocurrency wallet development company, we aim to make your crypto wallet ease the process and build efficient control over its tokens and assets, manage transactions, ensure security, and make it handy for the global audience. With our years of experience in curating decentralized solutions, we eye on the current trends, and try to incorporate the best of it. Our crypto wallet development services for you, specifically serve the purpose as you define, to know more, Connect with our team of experts now!
          </p>
            <div class="content-link">
              <a
                  href="https://www.blockchainx.tech/contact">
                  <span>Book Your Call Now!</span>
                  <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                  <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Benefits Of Tokenizing Real Estate Start -->
<section class="section-service-main">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="metaverse_heading mx-auto">
                  <h3>World-Class Features <span>of Our Crypto Wallet App</span></h3>
              </div>
          </div>
      </div>
      <div class="row benefits-of-token">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
              <div class="erc-about-picture">
                  <picture>
                      <source
                          type="image/webp"
                          srcset="assets-new/images/real-estate/high-volume-liquidity.webp" />

                      <source
                          type="image/png"
                          srcset="assets-new/images/real-estate/high-volume-liquidity.png" />

                      <img loading="lazy"
                          data-src="assets-new/images/real-estate/high-volume-liquidity.png"
                          class="img-fluid"
                          alt="Multi-Factor Authentication"
                          width="75"
                          height="75" />
                  </picture>
                  <h4 class="erc-20-head pt-sm-0 pt-2">Multi-Factor Authentication</h4>
                  <p class="text-center">
                    Despite the complete security of blockchain-based software, we provide advanced security with multi-factor authentication.
                  </p>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
              <div class="erc-about-picture">
                  <picture>
                      <source
                          type="image/webp"
                          srcset="assets-new/images/real-estate/fractional-ownership.webp" />

                      <source
                          type="image/png"
                          srcset="assets-new/images/real-estate/fractional-ownership.png" />

                      <img loading="lazy"
                          data-src="assets-new/images/real-estate/fractional-ownership.png"
                          class="img-fluid"
                          alt="Transaction History"
                          width="75"
                          height="75" />
                  </picture>
                  <h4 class="erc-20-head pt-sm-0 pt-2">Transaction History</h4>
                  <p class="text-center">
                    You can easily access all transaction history and manage your finances with a publicly distributed ledger.
                  </p>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
              <div class="erc-about-picture">
                  <picture>
                      <source
                          type="image/webp"
                          srcset="assets-new/images/real-estate/globalization.webp" />

                      <source
                          type="image/png"
                          srcset="assets-new/images/real-estate/globalization.png" />

                      <img loading="lazy"
                          data-src="assets-new/images/real-estate/globalization.png"
                          class="img-fluid"
                          alt="Wallet Backup"
                          width="75"
                          height="75" />
                  </picture>
                  <h4 class="erc-20-head pt-sm-0 pt-2">Wallet Backup</h4>
                  <p class="text-center">
                    Backup all of your cryptocurrency wallet data to ensure that you never lose access to your digital assets or cryptocurrencies.
                  </p>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
              <div class="erc-about-picture">
                  <picture>
                      <source
                          type="image/webp"
                          srcset="assets-new/images/real-estate/no-intermediaries.webp" />

                      <source
                          type="image/png"
                          srcset="assets-new/images/real-estate/no-intermediaries.png" />

                      <img loading="lazy"
                          data-src="assets-new/images/real-estate/no-intermediaries.png"
                          class="img-fluid"
                          alt="Automated Session Logout"
                          width="75"
                          height="75" />
                  </picture>
                  <h4 class="erc-20-head pt-sm-0 pt-2">Automated Session Logout</h4>
                  <p class="text-center">
                    Integrating an automatic session logout feature into your wallet lets you keep your data secure and prevents harmful hacks.
                  </p>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
              <div class="erc-about-picture">
                  <picture>
                      <source
                          type="image/webp"
                          srcset="assets-new/images/real-estate/blockchain-immutability.webp" />

                      <source
                          type="image/png"
                          srcset="assets-new/images/real-estate/blockchain-immutability.png" />

                      <img loading="lazy"
                          data-src="assets-new/images/real-estate/blockchain-immutability.png"
                          class="img-fluid"
                          alt="Multi-Network Support"
                          width="75"
                          height="75" />
                  </picture>
                  <h4 class="erc-20-head pt-sm-0 pt-2">Multi-Network Support</h4>
                  <p class="text-center">
                    The ability to switch between networks makes it compatible in terms of development, usage, and port update.
                  </p>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
              <div class="erc-about-picture">
                  <picture>
                      <source
                          type="image/webp"
                          srcset="assets-new/images/real-estate/instant-efficient.webp" />

                      <source
                          type="image/png"
                          srcset="assets-new/images/real-estate/instant-efficient.png" />

                      <img loading="lazy"
                          data-src="assets-new/images/real-estate/instant-efficient.png"
                          class="img-fluid"
                          alt="Importing Wallets"
                          width="75"
                          height="75" />
                  </picture>
                  <h4 class="erc-20-head pt-sm-0 pt-2">Importing Wallets</h4>
                  <p class="text-center">
                    To be effective and facilitate wallet imports is a must, with private scanning, key securing importing accounts ensure a better user experience. 
                  </p>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- Benefits Of Tokenizing Real Estate End -->

<!-- Contact Us Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
      <div class="commontext_wrap">
        <h2 class="text-start">Transform Your Crypto Experience with Our Custom Wallet Development Services</h2>
      </div>

      <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
        <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
          <div>
            <img
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150" class="img-fluid rounded-circle" />
          </div>
          <div class="para-text">
            <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span> <span class="d-block fw-semibold">Co - Founder and COO</span> </p>
            <a href="mailto:contact@blockchainx.tech" target="_blank"
              rel="noopener noreferrer">contact@blockchainx.tech</a>
          </div>
        </div>
        <div class="discuss-link pt-0">
          <a [routerLink]="['/contact']">
            <span>Contact Us</span>
            <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Real Estate Tokenization listing Offerings Start -->
<section class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3>
             <span>The Crypto Wallet </span> App Development Process
            </h3>
          </div>
        </div>
      </div>
      <div
        class="row types-of-token-detail justify-content-center real-estate-token">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt pb-0">Get started with jamming ideas together</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt pb-0">Jot down the key results and outcomes</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt pb-0">Draft the outline on the Functioning of the crypto wallet</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Pick the right blockchain of your choice</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Curate the design wireframe and get approval</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Develop the customized crypto wallet app</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Integrate the cryptocurrency wallet Blockchain</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Do a keen Smart contract audit for the app</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Test the crypto wallet developed until right</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="pb-0">Launch and come back for maintenance, and update!</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Launchpad Platform -->
<section class="section-service-alt"> 
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3><span>Perks of Cryptocurrency</span> Wallet Development Company</h3>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-center process-image">
        <picture>
          <source
            type="image/webp"
            srcset="
            assets-new/images/erc/webp/process-1.webp
            " />

          <source
            type="image/png"
            srcset="assets-new/images/erc/process-1.png" />

          <img loading="lazy"
            data-src="assets-new/images/erc/process-1.png"
            class="img-fluid"
            alt="Automatic Session Logout"
            title="Automatic Session Logout"
            width="100"
            height="100" />
        </picture>
        <h4 class="erc-20-head-alt">Automatic Session Logout</h4>
        <p class="text-center">
          Keeping security as a top priority, each session logs out automatically, ensuring that all transactions are secure every time.
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-center process-image">
        <picture>
          <source
            type="image/webp"
            srcset="
            assets-new/images/erc/webp/process-2.webp
            " />

          <source
            type="image/png"
            srcset="assets-new/images/erc/process-2.png" />

          <img loading="lazy"
            data-src="assets-new/images/erc/process-2.png"
            class="img-fluid"
            alt="Inflation Control"
            title="Inflation Control"
            width="100"
            height="100" />
        </picture>
        <h4 class="erc-20-head-alt">Inflation Control</h4>
        <p class="text-center">
          Cryptocurrency with limited supply, has a high contribution in controlling Inflation, as the wallet takes due control of supply.
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-center process-image">

        <picture>
          <source
            type="image/webp"
            srcset="
            assets-new/images/erc/webp/process-3.webp
            " />

          <source
            type="image/png"
            srcset="assets-new/images/erc/process-3.png" />

          <img loading="lazy"
            data-src="assets-new/images/erc/process-3.png"
            class="img-fluid"
            alt="Security"
            title="Security"
            width="100"
            height="100" />
        </picture>
        <h4 class="erc-20-head-alt">Security</h4>
        <p class="text-center">
          Crypto wallets are highly resilient, and protected by the power of decentralization, making things seamless and undisturbed.
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-center process-image">
        <picture>
          <source
            type="image/webp"
            srcset="
            assets-new/images/erc/webp/process-4.webp
            " />

          <source
            type="image/png"
            srcset="assets-new/images/erc/process-4.png" />

          <img loading="lazy"
            data-src="assets-new/images/erc/process-4.png"
            class="img-fluid"
            alt="Accessibility For Dapps"
            title="Accessibility For Dapps"
            width="100"
            height="100" />
        </picture>
        <h4 class="erc-20-head-alt">Accessibility For Dapps</h4>
        <p class="text-center">
          Decentralized cryptocurrency wallets developed on blockchain to provide easy and unrestricted access to Decentralized apps. 
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Launchpad Platform -->

<!-- Product List Feature -->
<section class="section-service-main">
  <div class="product-list-feature">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading text-center">
          <h3><span>Watch Our Crypto Wallet </span>App Demo</h3>
          <p class="py-3">
            Are you Looking to create a compact and seamless web3 Wallet app? BX Finance’s predeveloped Wink Wallet crypto wallet app solution is the exact start path for your project, easier and simple to use.
          </p>
        </div>
      </div>
      <div class="row metmb-3 video-section-top">
        <div class="col-lg-5">
          <div class="metaverse_subheading">
            <h3 class="video-heading">Top Features:</h3>

            <ul class="demo-list">
              <li><i class="bi bi-check-lg"></i> Supports all EVM Chains</li>
              <li><i class="bi bi-check-lg"></i> In App DAPP Browser</li>
              <li>
                <i class="bi bi-check-lg"></i> Scan QR code to send crypto
              </li>
              <li><i class="bi bi-check-lg"></i> Transaction History</li>
              <li><i class="bi bi-check-lg"></i> NFTs support</li>
              <li><i class="bi bi-check-lg"></i> Transfer NFTs, Import NFTs</li>
              <li>
                <i class="bi bi-check-lg"></i> Gas fees optimization/control
              </li>
              <li>
                <i class="bi bi-check-lg"></i> Mobile Phone Biometric Security
              </li>
              <li>
                <i class="bi bi-check-lg"></i> Supports Native currencies and
                Tokens
              </li>
              <li>
                <i class="bi bi-check-lg"></i> Mnemonics, Export Private Key,
                Import wallet
              </li>
            </ul>

            <div class="content-link">
              <a
                href="https://play.google.com/store/apps/details?id=com.blockchainx.winkwallet "
                target="_blank"
                rel="noopener noreferrer">
                <span>View Demo</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-7 orders-1">
          <div class="video_right_side">
            <a
              href="https://play.google.com/store/apps/details?id=com.blockchainx.winkwallet "
              target="_blank"
              rel="noopener noreferrer">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                assets-new/images/product/webp/cryptocurrency-wallet-demo.webp
                " />

                <source
                  type="image/png"
                  srcset="assets-new/images/product/cryptocurrency-wallet-demo.png" />

                <img loading="lazy"
                  data-src="assets-new/images/product/cryptocurrency-wallet-demo.png"
                  width="579"
                  height="341"
                  class="img-fluid"
                  alt="cryptocurrency-wallet-demo"
                  title="cryptocurrency-wallet-demo" />
              </picture>
            </a>
          </div>
          <!-- <owl-carousel-o [options]="winkCrypto" id="winkCrypto">
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/wink-crypto-wallet.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/wink-crypto-wallet.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/wink-crypto-wallet.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Wink crypto wallet" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/quick-secure.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/quick-secure.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/quick-secure.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Quick secure" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/designed-multiple-accounts.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/designed-multiple-accounts.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/designed-multiple-accounts.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Designed multiple accounts" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/send-your-payments.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/send-your-payments.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/send-your-payments.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Wink crypto wallet" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/set-fees.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/set-fees.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/set-fees.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Set fees" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/trade-your-crypto-asset.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/trade-your-crypto-asset.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/trade-your-crypto-asset.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Trade your crypto asset" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="item-img">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/product/webp/receive-token.webp"
                    />
  
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/product/receive-token.jpg
                      " />
  
                    <img loading="lazy"
                      data-src="assets-new/images/product/receive-token.jpg"
                      class="img-fluid"
                      width="166"
                      height="296"
                      alt="Receive token" />
                  </picture>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product List Feature -->

<!-- why-choose-section -->
<section class="section-service-alt">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Choose BlockchainX For </span>Crypto wallet Development
          </h2>
          <p>
            BlockchainX is a full-fledged development company actively engaged in curating decentralized and web3 products that shape the world. As a Cryptocurrency wallet development services provider, we offer end-to-end cryptocurrency development services for all business sizes. We have a team of dedicated cryptocurrency developers who can easily handle even the most complex projects. We are trained, equipped, and trained to make things simplified, easy, and scalable in the market, and eventually gather good traction. Hire us as your team, and take your stand in the crypto spaces right and clear. 
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/metaverse/why_blockchain.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/metaverse/why_blockchain.png" />

              <img loading="lazy"
              src="assets-new/images/metaverse/why_blockchain.png"
              width="550"
              height="450"
              class="img-fluid"
              alt="why-blockchainX"
              title="why-blockchainX" />

          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-main">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            Frequently Asked Question <span class="dots">.</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  Can you customize crypto wallet development?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Yes of course, it's very simple and common to customize a crypto wallet app, BlockchainX can seamlessly contribute to this development process.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Can I develop a crypto wallet for my crypto project?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    It is very much possible to curate a crypto wallet to handle the Decentralized finances of your crypto project, this shall add to the efficiency of your ecosystem. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  What is the time and cost involved in developing a crypto wallet app?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The cost and time involved in developing your crypto wallet app completely determines on the choice of your preference with respect to technology, features, advanced integration, etc. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<section class="section-service-alt">
  <app-blog></app-blog>
</section>

<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>