import {
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-exchange-token-development',
  templateUrl: './exchange-token-development.component.html',
  styleUrls: ['./exchange-token-development.component.css']
})
export class ExchangeTokenDevelopmentComponent implements OnInit {


  bitrixSnippetScript: SafeScript;

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private doc: Document
  ) {
    const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      // Perform a null check before calling parentNode.insertBefore
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
        // You can append the script to the <body> element or take another appropriate action.
        document.body.appendChild(s);
      }
    })(
      window,
      document,
      'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    )};</script>`;
    this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(
      `${scriptUrl}`
    );
  }


  isDarkmode:boolean=false;
  title = 'Exchange Tokens Development | Multi Purpose Utility For Exchanges';

  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Exchange token development refers to creating versatile, secured, and the most reliable tokens developed to serve centralized exchanges. Creating an exchange token for your cex with us. Connect to know more. ',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Exchange-based tokens, Exchange-based tokens development company, Exchange-based tokens development service, Exchange-based tokens platform development',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Exchange Tokens Development | Multi Purpose Utility For Exchanges',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/exchange-token-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Exchange token development refers to creating versatile, secured, and the most reliable tokens developed to serve centralized exchanges. Creating an exchange token for your cex with us. Connect to know more. ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/exchange-based-tokens-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/exchange-token-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Exchange Tokens Development | Multi Purpose Utility For Exchanges',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Exchange token development refers to creating versatile, secured, and the most reliable tokens developed to serve centralized exchanges. Creating an exchange token for your cex with us. Connect to know more. ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/exchange-based-tokens-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/exchange-token-development/'
    );
  }
  
  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
