<!-- Banner Section -->
<div class="banners-section">
  
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> Create Your <strong> BEP20 Tokens</strong> on BSC </span>
              </h1>
              <!-- <h4 class="common-sub-heading-alt">
                  Bep20 token Development Company
                </h4> -->

              <p>
                Step decisively into the remunerative world of DeFi by launching
                your own BEP20 Token on the BNB Smart Chain network. With our
                comprehensive BEP20 token development services, Enter the realm
                of decentralized finance with an expert team!1234
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a href="https://www.blockchainx.tech/contact/">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/bep20/create-bep20-tokens.webp" />
              <img loading="lazy"
                data-src="assets-new/images/bep20/create-bep20-tokens.png"
                width="395" height="395" alt="Create bep20 token"
                title="Create bep20 token" class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/bep20/light-theme/create-bep20-tokens.webp" />
              <img loading="lazy"
                data-src="assets-new/images/bep20/light-theme/create-bep20-tokens.png"
                width="395" height="395" alt="Create bep20 token"
                title="Create bep20 token" class="img-fluid">
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="Scroll"
              title="Scroll" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!-- iframe start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center">
      <iframe src="https://devuser.blockchainx.finance/new-token-generator"
        frameborder="0" width="100%" height="1220"></iframe>
    </div>
  </div>
</div>
<!-- iframe end -->

<!-- how dose bep20 work start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 text-center mx-auto col-md-12 order-lg-first order-last">
        <div class="bep-work-section pb-0">
          <picture *ngIf="!isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/bep20/what-is-bep20-token.webp">
            <img loading="lazy"
              data-src="assets-new/images/bep20/what-is-bep20-token.png"
              width="323" height="490" alt="Bep20 token create"
              title="Bep20 token create" class="img-fluid BSC-Tokens-image">
          </picture>
          <picture *ngIf="isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/bep20/light-theme/what-is-bep20-token.webp">
            <img loading="lazy"
              data-src="assets-new/images/bep20/light-theme/what-is-bep20-token.png"
              width="323" height="490" alt="Bep20 token create"
              title="Bep20 token create" class="img-fluid BSC-Tokens-image">
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading">What is BEP20 Token Development?</h3>
          <p>Launch your own token with the help of BlockchainX.</p>
          <p class="pt-2">
            BlockchainX is the top BEP20 Token development company, providing
            Binance Smart Chain-based BEP20 token development services to create
            the best BEP20 tokens in the market, with their functionality mostly
            executed on the BNB blockchain. We have a vibrant team of blockchain
            experts with extensive expertise and talent to build unique BEP20
            tokens. We help you kickstart the token with the help of the
            extended BEP20 token development services provided by our industry
            experts.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- how dose bep20 work end -->

<div class="section-service-main">
  <div class="project-list">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 token-developer text-center order-lg-first order-last">
          <picture *ngIf="!isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/bep20/bep20-tokens.webp">
            <img loading="lazy"
              data-src="assets-new/images/bep20/bep20-tokens.png" width="400"
              height="400" alt="Bep20 token create" title="Bep20 token create"
              class="img-fluid mx-auto">
          </picture>
          <picture *ngIf="isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/bep20/light-theme/bep20-tokens.webp">
            <img loading="lazy"
              data-src="assets-new/images/bep20/light-theme/bep20-tokens.png" width="400"
              height="400" alt="Bep20 token create" title="Bep20 token create"
              class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12 col-12 align-items-center">
          <div class="token d-flex gap-md-4 gap-0">
            <div class="col-lg-6 col-md-6 col-sm-12 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Experienced Token Developers
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Unique
                  Agile Development
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> 100%
                  Quality Guarantee
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Secure
                  Codebase
                </li>
              </ul>
            </div>
          </div>

          <div class="country-list">
            <div class="row justify-content-end">
              <div
                class="col-lg-4 col-md-4 col-sm-4 col-12 project-inner text-center px-0">
                <p>50+<span>Country</span></p>
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-4 col-12 project-inner text-center px-0">
                <p>300+<span>Projects Delivered</span></p>
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-4 col-12 project-inner-alt text-center px-0">
                <p>350+<span>Total Projects</span></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-12 bep-20-list d-flex align-items-center py-3 mx-auto progress-bar-main">
              <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                <p class="progress-head pb-2">Number Of Clients</p>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="max-width: 100%"></div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <p class="pr-sub-head">200+</p>
              </div>
            </div>
            <div
              class="col-lg-12 bep-20-list d-flex align-items-center py-3 mx-auto progress-bar-main">
              <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                <p class="progress-head pb-2">Delivered Projects</p>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="max-width: 100%"></div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <p class="pr-sub-head">250+</p>
              </div>
            </div>
            <div
              class="col-lg-12 bep-20-list d-flex align-items-center py-3 mx-auto progress-bar-main">
              <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                <p class="progress-head pb-2">No.of Employees</p>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="max-width: 100%"></div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <p class="pr-sub-head">75+</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--BEP20 Token  Development Services-->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading">
        <h3>Our <span>BEP20 Token </span> Development Services</h3>
      </div>
    </div>

    <div class="nft-development-slider">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">BEP20 Token Development</h4>
            <p>
              Tokenization on the BNB Smart Chain blockchain creates new
              business prospects. We can assist you with the creation of both
              fungible and non-fungible tokens.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">
              BEP20 Token Minting and Burning
            </h4>
            <p>
              We can create an unlimited amount of mintable BNB tokens. To
              combat token value inflation, token owners can either mint tokens
              or burn excess tokens.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">
              Custom Smart contract development
            </h4>
            <p>
              Our custom smart contract development services, tailored to your
              needs, enable you to bring your unique BEP20 token idea to life on
              the Binance Smart Chain.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">BEP20 Token Listing</h4>
            <p>
              List the created BEP20 tokens on CoinMarketcap, CoinGecko, Dex
              exchange, Centralized exchange in order to enhance their
              popularity & make them available to investors worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- BEP20 Token Development Services -->
<!--BEP20 Token  Development Products -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading">
        <h3>Our <span>BEP20 Token </span> Development Products</h3>
      </div>
    </div>

    <div class="row justify-content-center bep20-development">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
        <div class="nft_devlopment_market">
          <h4 class="common-sub-heading-alt">BEP20 Token Migration</h4>
          <p>
            Our Blockchain developers will migrate your tokens from one
            blockchain to another, including Ethereum, Tron, EOS, and BNB.
          </p>
          <div class="item-header">
            <a
              href="https://www.blockchainx.tech/token-migration"
              target="_blank"
              rel="noopener noreferrer">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/bep20-token-migration.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/bep20-token-migration.png"
                  width="579" height="341" alt="BEP20 Token Migration"
                  title="Bep20 token create" class="img-fluid">
              </picture>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
        <div class="nft_devlopment_market">
          <h4 class="common-sub-heading-alt">Bep20 token bridge development</h4>
          <p>
            Seamlessly connect your BEP20 tokens across multiple blockchain
            networks with our cutting-edge BEP20 token bridge development,
            enabling efficient interoperability & expanded liquidity.
          </p>
          <div class="item-header">
            <a
              href="https://www.blockchainx.tech/bridge-smart-contract-development"
              target="_blank"
              rel="noopener noreferrer">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/bep20-token-bridge-development.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/bep20-token-bridge-development.png"
                  width="579" height="341"
                  alt="BEP20 Token Bridge Development"
                  title="BEP20 Token Bridge Development" class="img-fluid">
              </picture>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
        <div class="nft_devlopment_market">
          <h4 class="common-sub-heading-alt">Bep20 token sale ICO</h4>
          <p>
            Launch your own BEP20 token & raise funds through a successful ICO,
            unlocking the potential for investor participation.
          </p>
          <div class="item-header">
            <a
              href=" https://www.blockchainx.tech/ready-made-ico-software"
              target="_blank"
              rel="noopener noreferrer">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/bep20-token-sale-ICO.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/bep20-token-sale-ICO.png"
                  width="579" height="341" alt="BEP20 Token Sale ICO"
                  title="BEP20 Token Sale ICO" class="img-fluid">
              </picture>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
        <div class="nft_devlopment_market">
          <h4 class="common-sub-heading-alt">BEP20 Token Wallet Development</h4>
          <p>
            We provide secure customized token wallets for storing and managing
            BNB tokens created according to the BEP20 standard.
          </p>
          <div class="item-header">
            <a
              href="https://www.blockchainx.tech/cryptocurrency-wallet-development"
              target="_blank"
              rel="noopener noreferrer">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/bep20-token-wallet-development.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/bep20-token-wallet-development.png"
                  width="579" height="341"
                  alt="BEP20 Token Wallet Development"
                  title="BEP20 Token Wallet Development" class="img-fluid">
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEP20 Token  Development Products -->
<!-- ERC20 Token Development Process Start-->
<section class="section-service-alt">
  <div class="container">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="common-sub-heading">
        <h4>Our Process</h4>
      </div>
      <h3 class="main-sub-heading text-start">
        BEP20 Token Development Process
      </h3>
    </div>
    <div class="process-development-image text-center">
      <picture *ngIf="!isDarkmode">
        <source type="image/webp"
          srcset="assets-new/images/erc/process-development.webp">
        <img loading="lazy"
          data-src="assets-new/images/erc/process-development.png" width="1109"
          height="405" alt="ERC20 Token Development Process"
          title="ERC20 Token Development Process" class="img-fluid">
      </picture>
      <picture *ngIf="isDarkmode">
        <source type="image/webp"
          srcset="assets-new/images/erc/light-theme/process-development.webp">
        <img loading="lazy"
          data-src="assets-new/images/erc/light-theme/process-development.png" width="1109"
          height="406" alt="ERC20 Token Development Process"
          title="ERC20 Token Development Process" class="img-fluid">
      </picture>
    </div>
  </div>
</section>
<!-- ERC20 Token Development Process End-->
<!--Various Ethereum Token Development on Various ERC Standards Start -->

<!-- Benefits of BEP20 Token Development-->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading">
        <h3>Benefits of<span> BEP20 Token</span> Development</h3>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row align-items-center justify-content-center erc-processing">
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/fundraise.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/fundraise.png" width="100"
                height="100" alt="fundraise" title="fundraise"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Fundraise</h4>
            <p>
              BEP20 tokens can be used for fundraising purposes through ICOs &
              projects can raise funds from investors globally.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/staking.webp">
              <img loading="lazy" data-src="assets-new/images/bep20/staking.png"
                width="100" height="100" alt="Staking" title="Staking"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Staking</h4>
            <p>
              BEP20 tokens can enable staking methods, which let token holders
              participate in network validation and generate passive income.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/validated.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/validated.png" width="100"
                height="100" alt="Validated" title="Validated"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Validated</h4>
            <p>
              BEP20 tokens are validated via the BNB's consensus method. This
              validation ensures the transaction's integrity and security.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/utility.webp">
              <img loading="lazy" data-src="assets-new/images/bep20/utility.png"
                width="100" height="100" alt="Utility" title="Utility"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Utility</h4>
            <p>
              Make your BEP20 tokens serve as a medium of exchange within DApps
              and platforms. They can be used to get access to services, provide
              practical utility, and increase user engagement.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/decentralized.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/decentralized.png" width="100"
                height="100" alt="decentralized" title="decentralized"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Decentralized</h4>
            <p>
              These tokens are part of a decentralized blockchain network,
              promoting transparency, security & removing the need for
              intermediaries.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/collateralized.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/collateralized.png"
                width="100" height="100" alt="Collateralized"
                title="Collateralized" class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Collateralized</h4>
            <p>
              Your BEP20 tokens can be used as collateral in DeFi applications,
              enabling borrowing, lending, or providing liquidity.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 metmb-3">
        <div class="d-flex align-items-center bep-list-check mb-0">
          <div class="col-sm-2 text-center bep-20-picture">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/issuance.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/issuance.png" width="100"
                height="100" alt="issuance" title="issuance" class="img-fluid">
            </picture>
          </div>
          <div class="col-sm-10 virtual_process pb-3">
            <h4>Issuance</h4>
            <p>
              BEP20 token development allows for the creation and issuance of
              custom tokens, tailoring the tokenomics to the project's specific
              needs and objectives.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Benefits -->

<!-- Requirements to kickstart BEP20 Token Development Service-->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading">
        <h3 class="text-center">
          Requirements to kickstart<span> BEP20 Token </span>Development Service
        </h3>
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/bep20/token-name.webp">
          <img loading="lazy" data-src="assets-new/images/bep20/token-name.png"
            width="100" height="100" alt="Token name" title="Token name"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">Token name</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/bep20/token-symbol.webp">
          <img loading="lazy"
            data-src="assets-new/images/bep20/token-symbol.png" width="100"
            height="100" alt="Token symbol" title="Token symbol"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">Token Symbol</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/bep20/token-supply.webp">
          <img loading="lazy"
            data-src="assets-new/images/bep20/token-supply.png" width="100"
            height="100" alt="Token supply" title="Token supply"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">Token Supply</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/bep20/token-decimal.webp">
          <img loading="lazy"
            data-src="assets-new/images/bep20/token-decimal.png" width="100"
            height="100" alt="Token Decimal" title="Token Decimal"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">Token decimal</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/bep20/token-blockchain.webp">
          <img loading="lazy"
            data-src="assets-new/images/bep20/token-blockchain.png" width="100"
            height="100" alt="Token Blockchain" title="Token Blockchain"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">Token blockchain</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/bep20/token-features-planned.webp">
          <img loading="lazy"
            data-src="assets-new/images/bep20/token-features-planned.png"
            width="100" height="100" alt="Token Features Plan"
            title="Token features planned" class="img-fluid">
        </picture>
        <h4 class="process-head py-3">Token features planned</h4>
      </div>
    </div>
  </div>
</div>
<!-- Requirements to kickstart BEP20 Token Development Service-->

<!-- Basic Features starts -->
<section class="section-service-main">
  <div class="container">
    <div class="our-process-erc">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>Basic <span>BEP20 Token</span> Development Features</h3>
        </div>
      </div>
    </div>
    <!-- Ideation Starts -->
    <div class="row erc-processing justify-content-center gy-4">
      <div class="col-lg-4 col-12 metmb-3">
        <div class="virtual_process mine-section">
          <!-- <h2>01</h2> -->
          <h4>Mine</h4>
          <p>
            Once the tokens are created, they can be minted to increase the
            total supply of the token at any time.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-12 metmb-3">
        <div class="virtual_process mine-section">
          <!-- <h2>02</h2> -->
          <h4>Capped</h4>
          <p>
            The number of tokens to be created should be supplied. Once the
            limit is reached, the minting cannot take place.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-12 metmb-3">
        <div class="virtual_process mine-section">
          <!-- <h2>03</h2> -->
          <h4>Burn</h4>
          <p>
            Tokens are burned to reduce the overall supply in the market.
            Burning tokens can increase demand & potentially drive up their
            price.
          </p>
        </div>
      </div>
    </div>
    <!-- Ideation Ends -->
  </div>
</section>
<!-- Basic Features end -->

<!-- Medium Features starts -->
<section class="section-service-alt">
  <div class="container">
    <div class="our-process-erc">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>Medium <span>BEP20 Token</span> Development Features</h3>
        </div>
      </div>
    </div>
    <!-- Ideation Starts -->
    <div class="row erc-processing justify-content-center">
      <div class="col-lg-12 col-12 metmb-3">
        <div class="d-flex align-items-center erc-processing-content">
          <div class="col-sm-9">
            <div class="virtual_process cmb-5">
              <!-- <h2>01</h2> -->
              <h4>Blacklist</h4>
              <p>
                Enhance token security by blacklisting suspicious addresses and
                preventing them from interacting with the token.
              </p>
            </div>
          </div>
          <div class="col-sm-3 text-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/blacklist.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/blacklist.png" width="100"
                height="100" alt="blacklist" title="blacklist"
                class="img-fluid">
            </picture>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-12 metmb-3">
        <div class="d-flex align-items-center erc-processing-content">
          <div class="col-sm-9">
            <div class="virtual_process">
              <!-- <h2>02</h2> -->
              <h4>Whitelist</h4>
              <p>
                Create a restricted and exclusive environment by whitelisting
                specified addresses that are allowed to interact with the token.
              </p>
            </div>
          </div>
          <div class="col-sm-3 text-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/whitelist.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/whitelist.png" width="100"
                height="100" alt="whitelist" title="whitelist"
                class="img-fluid">
            </picture>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-12 metmb-3">
        <div class="d-flex align-items-center erc-processing-content">
          <div class="col-sm-9">
            <div class="virtual_process cmb-5">
              <!-- <h2>03</h2> -->
              <h4>Reflection</h4>
              <p>
                Create passive income for token holders based on their holdings
                by automatically redistributing a percentage of transaction fees
                to all holders.
              </p>
            </div>
          </div>
          <div class="col-sm-3 text-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/reflection.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/reflection.png" width="100"
                height="100" alt="reflection" title="reflection"
                class="img-fluid">
            </picture>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-12 metmb-3">
        <div class="d-flex align-items-center erc-processing-content">
          <div class="col-sm-9">
            <div class="virtual_process">
              <!-- <h2>04</h2> -->
              <h4>Taxation</h4>
              <p>
                Deploy transaction fees to fund various initiatives, such as
                liquidity provision, charity, marketing efforts, development
                wallets, etc.
              </p>
            </div>
          </div>
          <div class="col-sm-3 text-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/bep20/taxation.webp">
              <img loading="lazy"
                data-src="assets-new/images/bep20/taxation.png" width="100"
                height="100" alt="taxation" title="taxation" class="img-fluid">
            </picture>
          </div>
        </div>
      </div>
    </div>
    <!-- Ideation Ends -->
  </div>
</section>
<!-- Medium Features end -->

<!-- Advanced Features starts -->
<section class="section-service-main">
  <div class="container">
    <div class="our-process-erc">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>Advanced <span>BEP20 Token</span> Development Features</h3>
        </div>
      </div>
    </div>
    <div class="row erc-processing justify-content-center">
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>01</h2> -->
          <h4>Upgradable</h4>
          <p>
            Add the option to upgrade the token contract, which will allow for
            future improvements, bug fixes & scalability without affecting the
            token's ecosystem.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>02</h2> -->
          <h4>Taxations on DEXes</h4>
          <p>
            Implement transaction taxes tailored to decentralised exchanges
            (DEXes), ensuring a fair and long-term r pevenue source for the
            tokenroject.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>03</h2> -->
          <h4>Sell limit in DEXes</h4>
          <p>
            Set a maximum sell limit for DEX token transactions to prevent
            large-scale token sales and promote price stability.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>04</h2> -->
          <h4>Buy limit in DEXes</h4>
          <p>
            Set a maximum buy limit for token transactions on DEXes to prevent
            overbuying and maintain market equilibrium.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>05</h2> -->
          <h4>Pause/Unpause</h4>
          <p>
            Allow the contract owner to temporarily pause or unpause token
            transfers, acting as a safety net in an emergency or unforeseen
            occurrence.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>06</h2> -->
          <h4>Rebase</h4>
          <p>
            Introduce a rebase mechanism that automatically adjusts token supply
            based on external factors such as market conditions or reference
            price to ensure token value.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>07</h2> -->
          <h4>Auto staking token</h4>
          <p>
            Create a token with built-in automatic staking features that will
            allow holders to earn rewards just by keeping the token in their
            wallet.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12 metmb-3 cmb-5">
        <div class="virtual_process">
          <!-- <h2>08</h2> -->
          <h4>Time-bound</h4>
          <p>
            Implement time-bound features such as vesting schedules or
            time-locked features that limit some token actions until certain
            criteria or deadlines are met.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Advanced Features end -->

<!-- Development Services starts -->
<div class="section-service-alt">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>
              Different Types of Binance Smart Chain
              <span> (BEP20) Token</span> Development
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center erc-processing">
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5 h-100">
            <div class="d-flex align-items-center bep-sect-list h-100">
              <div class="col-sm-5 heading">
                <h4>Utility Token</h4>
              </div>
              <div class="col-sm-7 texting">
                <p>
                  Using the BEP20 token standard, it is possible to develop
                  utility tokens that work with your platform (e.g., internal
                  payments).
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5 h-100">
            <div class="d-flex align-items-center bep-sect-list h-100">
              <div class="col-sm-5 heading">
                <h4>Governance Tokens</h4>
              </div>
              <div class="col-sm-7 texting">
                <p>
                  This BEP20 token standard can be used to create governance
                  tokens. Token holders can vote on changes to your platform
                  using governance tokens.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5 h-100">
            <div class="d-flex align-items-center bep-sect-list h-100">
              <div class="col-sm-5 heading">
                <h4>Stable Coins</h4>
              </div>
              <div class="col-sm-7 texting">
                <p>
                  Build BEP20 stablecoins pegged to a stable asset or fiat
                  currency to ensure continuous value & reliable transactions
                  within the BNB ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5 h-100">
            <div class="d-flex align-items-center bep-sect-list h-100">
              <div class="col-sm-5 heading">
                <h4>Reward Token</h4>
              </div>
              <div class="col-sm-7 texting">
                <p>
                  Design BEP20 reward tokens to incentivize user engagement and
                  contributions, distributing rewards based on predefined
                  activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->

<!-- About Section Start starts -->
<div class="section-service-alt-main" id="about">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 mx-auto col-md-6 col-sm-12 col-12">
        <div class="about-picture text-center">
          <picture *ngIf="!isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/bep20/award-winning-bx-project.webp">
            <img loading="lazy"
              data-src="assets-new/images/bep20/award-winning-bx-project.png"
              width="450" height="460" alt="Award Winning BX Project"
              title="Award Winning BX Project" class="img-fluid mx-auto">
          </picture>
          <picture *ngIf="isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/bep20/light-theme/award-winning-bx-project.webp">
            <img loading="lazy"
              data-src="assets-new/images/bep20/light-theme/award-winning-bx-project.png"
              width="450" height="460" alt="Award Winning BX Project"
              title="Award Winning BX Project" class="img-fluid mx-auto">
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h4>We Are <span class="dots">.</span></h4>
        </div>
        <h3 class="main-sub-heading">
          Award Winning BX Project Launch Assistance
        </h3>
        <p>
          In BX Project Launch Assistance, We pride ourselves on our expertise
          and track record of successfully launching innovative and
          groundbreaking projects in the blockchain space. With our
          comprehensive range of services and industry-leading knowledge, we
          provide exceptional support to ensure a smooth and successful project
          launch. Leveraging our extensive experience, we offer tailored
          assistance to guide projects from concept to execution, covering all
          crucial aspects of the launch process.
        </p>
        <div class="content-link" id="border-none">
          <a href="/assets-new/pdf/PITCHDECK.pdf" download>
            <span>Download Here</span> <i class="bi bi-download"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- About Section Start ends -->

<!-- why_choose_us starts -->
<div class="section-service-alt-main">
  <section class="why_choose_us">
    <div class="why-alt-section light-background">
      <div class="green-vector"></div>
      <div class="orange-vector"></div>
      <div class="container choosing-section">
        <div class="row align-items-center">
          <div class="col-lg-6 metmb-3">
            <div class="whys_section mb-2">
              <h2>
                Why Choose BlockchainX as
                <span> A BEP20 Token Development Company?</span>
              </h2>
            </div>
            <div class="whysection_text_wrap">
              <p>
                BlockchainX, a reputable BEP20 Token Development Company,
                employs highly competent token developers who excel at creating
                tokens on multiple blockchain platforms. We help crypto and
                blockchain startups by supporting ground-breaking BEP20 token
                development solutions that have a positive influence around the
                globe. BlockchainX offers a full range of BEP20 token
                development services to help you leverage Blockchain technology
                to grow your business.
              </p>
              <div class="content-link my-3">
                <a href="https://www.blockchainx.tech/contact/">
                  <span>Read More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </div>
          <div class="col-lg-6 metmb-3 Why-section">
            <div class="why_right_side">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/metaverse/why_blockchain.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/metaverse/why_blockchain.png"
                  width="550"
                  height="450"
                  class="img-fluid"
                  alt="Why BlockchainX"
                  title="Why BlockchainX" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- why_choose_us ends-->

<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center mb-3">
          <picture *ngIf="!isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/bep20/trending-bep20-token-list.webp" />


            <img loading="lazy"
              data-src="assets-new/images/bep20/trending-bep20-token-list.png"
              width="396"
              height="396"
              class="img-fluid"
              alt="Trending BEP20 Token List"
              title="Trending BEP20 Token List" />
          </picture>
          <picture *ngIf="isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/bep20/light-theme/trending-bep20-token-list.webp" />


            <img loading="lazy"
              data-src="assets-new/images/bep20/light-theme/trending-bep20-token-list.png"
              width="396"
              height="396"
              class="img-fluid"
              alt="Trending BEP20 Token List"
              title="Trending BEP20 Token List" />
          </picture>
        </div>
        <div class="col-lg-6">
          <h3 class="main-sub-heading">Trending BEP20 Tokens List</h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> BUSD
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> CAKE
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> BUX
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> BAKE
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> ALPHA
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> SAFEMOON
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> WBNB
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> BURGER
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a href="https://www.blockchainx.tech/contact/" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Tech Stak Update -->
<section class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading">
      <h3>Our <span>Bep20 clients</span></h3>
    </div>
    <div class="business-stak-list-section">
      <!-- <div class="metaverse_subheading">
        <h3 class="text-center">Blockchain Platform</h3>
      </div> -->
      <div
        class="row row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 align-items-center justify-content-center">
        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/alpha-omega-coins.webp">
                <img loading="lazy" data-src="assets-new/images/bep20/alpha-omega-coins.png" width="214" height="55" alt="Alpha Omega Coins" title="Alpha Omega Coins" class="img-fluid" *ngIf="!isDarkmode" />
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/aoc-finance-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/aoc-finance-lite.png" width="214" height="55" alt="Alpha Omega Coins" title="Alpha Omega Coins" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/farmsent.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/farmsent.png" width="208"
                  height="37" alt="farmsent" title="farmsent"
                  class="img-fluid"  *ngIf="!isDarkmode" >
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/farmsent-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/farmsent-lite.png" width="208" height="37" alt="farmsent" title="farmsent" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/columbus.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/columbus.png" width="183"
                  height="58" alt="columbus" title="columbus"
                  class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/columbus-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/columbus-lite.png" width="183" height="58" alt="Columbus" title="Columbus" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/reflex.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/reflex.png" width="218"
                  height="44" alt="reflex" title="reflex" class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/reflex-finance-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/reflex-finance-lite.png" width="218" height="44" alt="reflex" title="reflex" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/polkadog.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/polkadog.png" width="183"
                  height="62" alt="polkadog" title="polkadog"
                  class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/polkadog-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/polkadog-lite.png" width="183" height="62" alt="polkadog" title="polkadog" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/crypto-energy.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/crypto-energy.png"
                  width="208" height="40" alt="Cryptoenergy"
                  title="Cryptoenergy" class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/cryptoenergy-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/cryptoenergy-lite.png" width="208" height="40" alt="Cryptoenergy" title="Cryptoenergy" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/sea-swap-global.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/sea-swap-global.png"
                  width="183" height="38" alt="Sea Swap Global"
                  title="Sea Swap Global" class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/sea-swap-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/sea-swap-lite.png" width="183" height="38" alt="Sea Swap Global" title="Sea Swap Global" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/planet-bulls.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/planet-bulls.png"
                  width="217" height="61" alt="Planet Bulls"
                  title="Planet Bulls" class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/planetbulls-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/planetbulls-lite.png" width="217" height="61" alt="Planet Bulls" title="Planet Bulls" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/bigtime.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/bigtime.png" width="189"
                  height="70" alt="bigtime" title="bigtime" class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/big-time-crypto-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/big-time-crypto-lite.png" width="189" height="70" alt="bigtime" title="bigtime" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/minosis.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/minosis.png" width="172"
                  height="65" alt="minosis" title="minosis" class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/minosis-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/minosis-lite.png" width="189" height="70" alt="minosis" title="minosis" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/wemp.webp">
                <img loading="lazy" data-src="assets-new/images/bep20/wemp.png"
                  width="134" height="35" alt="wemp" title="wemp"
                  class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/wemp-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/wemp-lite.png" width="134" height="35" alt="wemp" title="wemp" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/faith.webp">
                <img loading="lazy" data-src="assets-new/images/bep20/faith.png"
                  width="164" height="67" alt="faith" title="faith"
                  class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/faith-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/faith-lite.png" width="164" height="67" alt="faith" title="faith" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/bep20/waifer-coin.webp">
                <img loading="lazy"
                  data-src="assets-new/images/bep20/waifer-coin.png" width="208"
                  height="40" alt="Waifer Coin" title="Waifer Coin"
                  class="img-fluid" *ngIf="!isDarkmode">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets-new/images/bep20/waifer-coin-lite.webp"/>
                <img loading="lazy" data-src="assets-new/images/bep20/waifer-coin-lite.png" width="208" height="40" alt="Waifer Coin" title="Waifer Coin" class="img-fluid" *ngIf="isDarkmode" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Tech Stak Update -->

<section class="section-service-main">
  <app-blog></app-blog>
</section>

<section class="section-service-alt">
  <app-get-in-touch></app-get-in-touch>
</section>
