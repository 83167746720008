<section class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> <strong> Blockchain </strong> Development Company </span>
              </h1>
              <p>
                As new possibilities emerge with web3, here is your chance to grab the best deal for your upgrade with blockchain development services from BlockchainX.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk to our
                  Experts</a>
                <a [routerLink]="['/contact']" class="home-link-alt">Connect
                  with us!</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 text-center d-lg-block d-none">
          <div class="banner-right-section">
            <!-- <picture>
              <source
                type="image/webp"
                srcset="
                assets-new/images/home-v2/blockchain-development-376.webp 1199w,
                assets-new/images/home-v2/blockchain-development-451.webp 1399w,
                assets-new/images/home-v2/blockchain-development.webp 1920w
                "
              />
              <source
                type="image/jpg"
                srcset="
                assets-new/images/home-v2/blockchain-development-376.jpg 1199w,
                assets-new/images/home-v2/blockchain-development-451.jpg 1399w,
                assets-new/images/home-v2/blockchain-development.jpg 1920w
                "
              />
              <img 
              loading="lazy"
              src="assets-new/images/home-v2/blockchain-development.jpg"
              width="500"
              height="500"
              alt="BlockchainX"
              title="BlockchainX"
              class="img-fluid"
                *ngIf="!isDarkmode" />
            </picture> -->
            <img 
            loading="lazy"
            src="assets-new/images/home-v2/blockchain-development.webp"
            width="500"
            height="500"
            alt="BlockchainX"
            title="BlockchainX"
            class="img-fluid" *ngIf="!isDarkmode"/>
            <!-- <picture>
              <source type="image/webp"
                srcset=" assets-new/images/home-v2/light-theme/blockchain-development-dark.webp">
              <source type="image/jpg"
                srcset=" assets-new/images/home-v2/light-theme/blockchain-development-dark.jpg">
              <img loading="lazy"
                data-src=" assets-new/images/home-v2/light-theme/blockchain-development-dark.jpg"
                width="500"
              height="500"
              alt="BlockchainX"
              title="BlockchainX"
              class="img-fluid"
                *ngIf="isDarkmode" />
            </picture> -->
            <img loading="lazy"
                src="assets-new/images/home-v2/light-theme/blockchain-development-dark.webp"
                width="500"
              height="500"
              alt="BlockchainX"
              title="BlockchainX"
              class="img-fluid" *ngIf="isDarkmode" />
          </div>
        </div>
      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- About Start -->
<section class="section-service-alt">
  <div class="sec-about" #about>
    <div class="violet-vector"></div>
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-7 col-md-12 col-sm-12 col-12 order-lg-first order-last">
          <div class="px-lg-2">
            <video
              src="assets-new/video/blockchain-development-company.mp4"
              controls
              [autoplay]="false"
              width="95%">
              <track
                kind="captions"
                src="assets-new/video/blockchain-development-company.mp4"
                srclang="en"
                label="English" />
            </video>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading sub-title">
            We Are A <span class="dots">.</span>
          </div>
          <div class="whys_section mb-0">
            <h2><span>Blockchain App</span> Development Company</h2>
            <p>
              Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.<br/>
              With efficient flow and effective functioning from tokenomics to transparent transactions, we take over all varied blockchain based development services
            </p>
          </div>
          <div class="content-link" id="border-none">
            <a href="assets-new/pdf/PITCHDECK.pdf" download>
              <span>Download Here</span> <i class="bi bi-download"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- About Ends -->

<!-- Vertical Tab Start -->
<section class="section-vertical">
  <div class="container-fluid">
    <div class="row mb-lg-5 mb-3">
      <div class="col-lg-10">
        <div class="common-sub-heading sub-title">
          Our Solutions Are Executed With<span class="dots">.</span>
        </div>
        <div class="whys_section mb-0">
          <h2><span>Cutting-Edge Blockchain </span> Development Services</h2>
          <!-- <p>
            Our services secure your business and shape it in all 360° so that it can compete with new technology and overshadow its competitors' standing ahead of all. We render end-to-end blockchain development, which includes:
          </p> -->
        </div>
      </div>
    </div>
    <div class="vertical-tab">
      <div class="vertical-main d-none d-lg-flex">
        <ul class="nav nav-tabs" id="myTab">
          <li class="nav-item">
            <a href="#rwa" class="nav-link active" data-bs-toggle="tab">RWA</a>
          </li>
          <li class="nav-item">
            <a href="#cryptowallet" class="nav-link" data-bs-toggle="tab">Crypto
              Wallet</a>
          </li>
          <li class="nav-item">
            <a href="#cryptoexchange" class="nav-link"
              data-bs-toggle="tab">Crypto Exchange</a>
          </li>
          <li class="nav-item">
            <a href="#tokendevelopment" class="nav-link"
              data-bs-toggle="tab">Bep20 and Erc20 Token</a>
          </li>
          <li class="nav-item">
            <a
              href="#enterpriseblockchain"
              class="nav-link"
              data-bs-toggle="tab">Enterprise Blockchain</a>
          </li>
          <li class="nav-item">
            <a href="#tokensale" class="nav-link" data-bs-toggle="tab">Token
              Sale Dapp (ICO & SDO)</a>
          </li>
          <li class="nav-item">
            <a href="#cryptocurrency" class="nav-link"
              data-bs-toggle="tab">Cryptocurrency Development</a>
          </li>
          <li class="nav-item">
            <a href="#blockchainconsult" class="nav-link"
              data-bs-toggle="tab">Smart Contract Development</a>
          </li>
        </ul>
      </div>
      <div class="tab-content accordion" id="myTabContent">
        <div class="tab-pane fade show active accordion-item" id="rwa">
          <div class="accordion-header d-lg-none" id="headingOnes">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#rwaOnes"
              aria-expanded="true"
              aria-controls="rwaOnes">
              RWA
            </button>
          </div>
          <div
            id="rwaOnes"
            class="accordion-collapse collapse show"
            aria-labelledby="rwaOnes"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/rwa.webp" />
                      <source
                        type="image/svg"
                        srcset="assets-new/images/home-v2/rwa.svg" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/rwa.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="RWA"
                        title="RWA" />
                    </picture>
                    <span class="d-lg-block d-none">RWA</span>
                  </div>
                  <p class="tab-card-para pb-4">
                    Real-world asset platform development in Blockchain is a
                    process that involves actual assets whose authority and
                    ownership are expanded by tokenizing in Blockchain networks.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/real-world-asset-tokenization']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="cryptowallet">
          <div class="accordion-header d-lg-none" id="headingTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#rwaTwo"
              aria-expanded="false"
              aria-controls="rwaTwo">
              Crypto Wallet
            </button>
          </div>
          <div
            id="rwaTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/crypto-wallet.webp" />
                      <source
                        type="image/svg"
                        srcset="assets-new/images/home-v2/crypto-wallet.svg" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/crypto-wallet.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Crypto Wallet"
                        title="Crypto Wallet" />
                    </picture>
                    <span class="d-lg-block d-none">Crypto Wallet</span>
                  </div>
                  <p class="tab-card-para pb-4">
                    Blockchain app development service that is used to make
                    crypto storage and transactions easy and simple. The wallet
                    acts as a host to store, hold, and transact crypto assets
                    that expand your opportunities among DeFi in a secured,
                    fast, and immutable manner.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/cryptocurrency-wallet-development']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="cryptoexchange">
          <div class="accordion-header d-lg-none" id="headingThird">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#rwaThird"
              aria-expanded="true"
              aria-controls="rwaThird">
              Crypto Exchange
            </button>
          </div>
          <div
            id="rwaThird"
            class="accordion-collapse collapse"
            aria-labelledby="headingThird"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/crypto-exchange.webp" />
                      <source
                        type="image/svg"
                        srcset="assets-new/images/home-v2/crypto-exchange.svg" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/crypto-exchange.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Crypto Exchange"
                        title="Crypto Exchange" />
                    </picture>
                    <span class="d-lg-block d-none">Crypto Exchange</span>
                  </div>
                  <p class="tab-card-para pb-4">
                    Blockchain software development services that are
                    dedicatedly curated and customized to exchange crypto assets
                    for one another on blockchain networks contribute to
                    developing your decentralized networks.
                  </p>
                  <div class="deploy-link">
                    <a
                      [routerLink]="[
                        '/cryptocurrency-exchange-software-development'
                      ]">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="tokendevelopment">
          <div class="accordion-header d-lg-none" id="bepFourth">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#rwaFourth"
              aria-expanded="true"
              aria-controls="rwaFourth">
              Bep20 and Erc20 Token
            </button>
          </div>
          <div
            id="rwaFourth"
            class="accordion-collapse collapse"
            aria-labelledby="bepFourth"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/bep20-erc20-token.webp
                        " />
                      <source
                        type="image/svg"
                        srcset="assets-new/images/home-v2/bep20-erc20-token.svg" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/bep20-erc20-token.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Bep20 and Erc20 Token"
                        title="Bep20 and Erc20 Token" />
                    </picture>
                    <span class="d-lg-block d-none">Bep20 and Erc20 Token</span>
                  </div>
                  <p class="tab-card-para pb-4">
                    Based on your business and its requirements, we shall curate
                    BEB20 and ERC20 tokens under our blockchain development
                    services, which are used to meet the financial crunch and
                    scale up efficiently.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/erc20-token-development']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="enterpriseblockchain">
          <div class="accordion-header d-lg-none" id="headingFifth">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#rwaFifth"
              aria-expanded="true"
              aria-controls="rwaFifth">
              Enterprise Blockchain
            </button>
          </div>
          <div
            id="rwaFifth"
            class="accordion-collapse collapse"
            aria-labelledby="headingFifth"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/enterprise-blockchain.webp
                        " />
                      <source
                        type="image/svg"
                        srcset="
                          assets-new/images/home-v2/enterprise-blockchain.svg
                        " />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/enterprise-blockchain.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Enterprise Blockchain"
                        title="Enterprise Blockchain" />
                    </picture>
                    <span class="d-lg-block d-none">Enterprise Blockchain</span>
                  </div>
                  <p class="tab-card-para pb-4">
                    A type of blockchain development that is permissioned and,
                    at the same time, works based on predefined contracts. We at
                    BlockchainX help you curate proficient solutions for backing
                    your business.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/enterprise-blockchain-development']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="tokensale">
          <div class="accordion-header d-lg-none" id="headingSixth">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#rwaSixth"
              aria-expanded="true"
              aria-controls="rwaSixth">
              Token Sale Dapp (ICO & SDO)
            </button>
          </div>
          <div
            id="rwaSixth"
            class="accordion-collapse collapse"
            aria-labelledby="headingSixth"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/token-sale-dapp.webp" />
                      <source
                        type="image/svg"
                        srcset="assets-new/images/home-v2/token-sale-dapp.svg" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/token-sale-dapp.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Token Sale Dapp"
                        title="Token Sale Dapp" />
                    </picture>
                    <span class="d-lg-block d-none">
                      Token Sale Dapp (ICO & SDO)
                    </span>
                  </div>
                  <p class="tab-card-para pb-4">
                    As a promising and industry-leading blockchain app
                    development company, we extend our end-to-end service in
                    token sale Dapp development, where our experts develop a
                    full-fledged initial Dex offering platform.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/white-label-launchpad-development']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="cryptocurrency">
          <div class="accordion-header d-lg-none" id="headingSeventh">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeventh"
              aria-expanded="true"
              aria-controls="collapseSeventh">
              Cryptocurrency Development
            </button>
          </div>
          <div
            id="collapseSeventh"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeventh"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/crypto-currency-development.webp
                        " />
                      <source
                        type="image/svg"
                        srcset="
                          assets-new/images/home-v2/crypto-currency-development.svg
                        " />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/crypto-currency-development.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Cryptocurrency Development"
                        title="Cryptocurrency Development" />
                    </picture>
                    <span class="d-lg-block d-none">
                      Cryptocurrency Development
                    </span>
                  </div>
                  <p class="tab-card-para pb-4">
                    We provide a solid feature-rich cryptocurrency development
                    that is enterprise-grade currencies, unlocking new avenues
                    of business and enhancing the value of your projects in the
                    market.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/crypto-currency-development']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="blockchainconsult">
          <div class="accordion-header d-lg-none" id="headingEighth">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEighth"
              aria-expanded="true"
              aria-controls="collapseEighth">
              Smart Contract Development
            </button>
          </div>
          <div
            id="collapseEighth"
            class="accordion-collapse collapse"
            aria-labelledby="headingEighth"
            data-bs-parent="#myTabContent">
            <div class="accordion-body">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-10">
                  <div class="tab-card-info">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/smart-contract-development.webp
                        " />
                      <source
                        type="image/svg"
                        srcset="
                          assets-new/images/home-v2/smart-contract-development.svg
                        " />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/smart-contract-development.svg"
                        width="44"
                        height="44"
                        class="img-fluid"
                        alt="Smart Contract Developmen"
                        title="Smart Contract Developmen" />
                    </picture>
                    <span class="d-lg-block d-none">
                      Smart Contract Development
                    </span>
                  </div>
                  <p class="tab-card-para pb-4">
                    As a part of our blockchain software development service,
                    our smart contracts are guaranteed to be error-free,
                    efficient, and aptly functioning based on your requirements,
                    easing your operations thoroughly.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['/smart-contract-development']">
                      <span class="p-0">Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Metavers Starts -->
<section class="section-service-alt meta-detail">
  <div class="green-vector"></div>
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row services-listing pb-sm-5 pb-4 mb-sm-0 mb-2 text-center">
      <div class="col-lg-12">
        <div class="common-sub-heading sub-title">
          We Deploy<span class="dots">.</span>
        </div>
        <div class="whys_section mb-0">
          <h2 class="pb-0 text-center">
            <span>Tailored solutions</span> for your projects
          </h2>
        </div>
      </div>
    </div>
    <div class="row text-center row-gap-4 justify-content-center">
      <div class="col-md-4 col-sm-6">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/home-v2/web3-bx.webp" />
          <source
            type="image/png"
            srcset="assets-new/images/home-v2/web3-bx.png" />
          <img loading="lazy"
            data-src="assets-new/images/home-v2/web3-bx.png"
            width="350"
            height="268"
            class="img-fluid"
            alt="web3 Development"
            title="web3 Development" />
        </picture>
        <div class="erc-20-head pt-md-4 pt-3"><a href="https://www.blockchainx.finance/" target="_blank" rel="noopener noreferrer">Web3 Development</a></div>
      </div>
      <div class="col-md-4 col-sm-6">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/home-v2/blockchain-nft-staking.webp" />
          <source
            type="image/png"
            srcset="assets-new/images/home-v2/blockchain-nft-staking.png" />
          <img loading="lazy"
            data-src="assets-new/images/home-v2/blockchain-nft-staking.png"
            width="350"
            height="268"
            class="img-fluid"
            alt="NFT Staking"
            title="NFT Staking" />
        </picture>
        <div class="erc-20-head pt-md-4 pt-3"><a href="https://nftstaking.blockchainx.tech/" target="_blank" rel="noopener noreferrer">NFT Staking Platform</a></div>
      </div>
      <div class="col-md-4 col-sm-6">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/home-v2/blockchain-nft-marketplace.webp" />
          <source
            type="image/png"
            srcset="assets-new/images/home-v2/blockchain-nft-marketplace.png" />
          <img loading="lazy"
            data-src="assets-new/images/home-v2/blockchain-nft-marketplace.png"
            width="350"
            height="268"
            class="img-fluid"
            alt="NFT Marketplace"
            title="NFT Marketplace" />
        </picture>
        <div class="erc-20-head pt-md-4 pt-3"><a href="https://nftmarketplace.blockchainx.tech/home" target="_blank" rel="noopener noreferrer">NFT Marketplace</a></div>
      </div>
    </div>
    <!-- <div class="meta-description">
      <div class="row meta-description-space">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="meta-alt-card">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home-v2/web3.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home-v2/web3.png" />
              <img loading="lazy"
                data-src="assets-new/images/home-v2/web3.png"
                width="406"
                height="580"
                class="img-fluid"
                alt="web3 Development"
                title="web3 Development" />
            </picture>
            <div class="meta-card">
              <div class="common-sub-heading-alt">web3 Development</div>
              <p>
                In a completely decentralized space, Web3 development brings all
                your requirements to ease your business operations, all taken
                care of by evolving technological trends.
              </p>
              <div class="contact-link">
                <a
                  [routerLink]="['/web3-development-company']"
                  class="home-link">Know More</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="meta-alt-card">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home-v2/nft-staking-platform.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home-v2/nft-staking-platform.png" />
              <img loading="lazy"
                data-src="assets-new/images/home-v2/nft-staking-platform.png"
                width="406"
                height="580"
                class="img-fluid"
                alt="NFT Staking"
                title="NFT Staking" />
            </picture>
            <div class="meta-card">
              <div class="common-sub-heading-alt">
                NFT Staking Platform Development
              </div>
              <p>
                As a blockchain development company, the NFT Staking platform we
                curate brings you a fully functioning space with the ability to
                create and run your NFT staking campaigns.
              </p>
              <div class="contact-link">
                <a
                  [routerLink]="['/nft-staking-platform-development']"
                  class="home-link">Know More</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="meta-alt-card">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home-v2/nft-marketplace.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home-v2/nft-marketplace.png" />
              <img loading="lazy"
                data-src="assets-new/images/home-v2/nft-marketplace.png"
                width="406"
                height="580"
                class="img-fluid"
                alt="NFT Marketplace"
                title="NFT Marketplace" />
            </picture>
            <div class="meta-card">
              <div class="common-sub-heading-alt">NFT Marketplace</div>
              <p>
                One-stop platform for your creatives to monetize effectively and
                efficiently in the world of crypto and Web3. Opt for our NFT
                marketplace blockchain development services.
              </p>
              <div class="contact-link">
                <a
                  [routerLink]="['/nft-marketplace-development']"
                  class="home-link">Know More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</section>
<!-- Metaverse Ends -->

<!-- Industry Start -->
<section class="section-service-main industry-main">
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-10 metaverse_heading mx-auto">
        <h3><span>Blockchain Development Services </span> for Every Sector</h3>
      </div>
    </div>
    <ul
      class="nav nav-tabs align-items-center justify-content-evenly"
      id="myTab">
      <li class="nav-item">
        <a href="#healthcare" class="nav-link active"
          data-bs-toggle="tab">Healthcare</a>
      </li>
      <li class="nav-item">
        <a href="#insurance" class="nav-link" data-bs-toggle="tab">Insurance</a>
      </li>
      <li class="nav-item">
        <a href="#supplychain" class="nav-link" data-bs-toggle="tab">Supply
          Chain</a>
      </li>
      <li class="nav-item">
        <a href="#agriculture" class="nav-link"
          data-bs-toggle="tab">Agriculutre</a>
      </li>
      <li class="nav-item">
        <a href="#fianance" class="nav-link"
          data-bs-toggle="tab">Finance/Investment</a>
      </li>
      <li class="nav-item">
        <a href="#rental" class="nav-link" data-bs-toggle="tab">Rental</a>
      </li>
      <li class="nav-item">
        <a href="#gamesdevelopment" class="nav-link" data-bs-toggle="tab">Games
          Development</a>
      </li>
      <li class="nav-item">
        <a href="#retail" class="nav-link" data-bs-toggle="tab">Retail</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade show active" id="healthcare">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a
                [routerLink]="['/blockchain-application-in-healthcare']"
                class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/healthcare.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/healthcare.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/healthcare.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Healthcare icon"
                    title="Healthcare" />
                </picture>
                <h4>Healthcare</h4>
              </a>
            </div>
            <p class="industry-card-para">
              Eradicating and addressing real-world issues in the healthcare
              industry, Blockchain app development in healthcare is going to
              provide transparency, traceability, and accountability among the
              stakeholders and operations.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="insurance">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a
                [routerLink]="['/blockchain-in-insurance']"
                class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/insurance.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/insurance.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/insurance.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Insurance icon"
                    title="Insurance" />
                </picture>
                <h4>Insurance</h4>
              </a>
            </div>
            <p class="industry-card-para">
              Blockchain revolutionizes the insurance industry by extending
              security, transparency, and efficient solutions in terms of policy
              management, processing claims, and preventing fraudulent actions.
              It also streamlines operations and cuts down costs through
              immutable records.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="supplychain">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a
                [routerLink]="['/what-is-supply-chain-management']"
                class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/supply-chain.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/supply-chain.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/supply-chain.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Supplychain icon"
                    title="Supplychain" />
                </picture>
                <h4>Supplychain</h4>
              </a>
            </div>
            <p class="industry-card-para">
              The supply chain network greatly benefits from the involvement of
              blockchain development solutions whose accountability,
              transparency, and smart contract audited transactions ensure
              security and meet regulations. From the sellers to the
              manufacturers, it supports everyone.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="agriculture">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a
                [routerLink]="['/blockchain-applications-agriculture']"
                class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/agriculture.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/agriculture.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/agriculture.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Agriculture icon"
                    title="Agriculture" />
                </picture>
                <h4>Agriculture</h4>
              </a>
            </div>
            <p class="industry-card-para">
              Another promising industry expanding with blockchain is
              agriculture, finding new opportunities, streamlining operations,
              analyzing productions, and contributing to the process of meeting
              demand. The transparent nature of blockchain authenticates,
              certifies, verifies, and maximizes the overall sustainability
              ratio of the sector.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="fianance">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a
                [routerLink]="['/blockchain-technology-in-banking-and-finance']"
                class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/finance-investment.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/finance-investment.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/finance-investment.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Finance/Investment"
                    title="Finance/Investment" />
                </picture>
                <h4>Finance/Investment</h4>
              </a>
            </div>
            <p class="industry-card-para">
              Our blockchain development solution encompasses the creation and
              maintenance of a decentralized application. Blockchain services
              add value to the finance and investment sectors, are highly
              contributing in terms of transparency and security, and manage
              asset transactions seamlessly.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="rental">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a [routerLink]="['/blockchain-in-rental']" class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/rental.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/rental.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/rental.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Rental icon"
                    title="Rental" />
                </picture>
                <h4>Rental</h4>
              </a>
            </div>
            <p class="industry-card-para">
              One added advantage that rentals seek with blockchain software
              solutions is efficiency and better management power over the
              property, and its listing is simply managed. From agreements to
              payments and checkout, everything is monitored and managed by the
              blockchain platform, contributing to saving time and effort for
              landlords and tenants.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="gamesdevelopment">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a
                [routerLink]="['/blockchain-game-development']"
                class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/games-development.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/games-development.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/games-development.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Games Development"
                    title="Games Development" />
                </picture>
                <h4>Games Development</h4>
              </a>
            </div>
            <p class="industry-card-para">
              The most creative and innovative sector, game development in the
              blockchain, is a process involving the utmost dynamic and creative
              approach in developing the decentralized platform with in-game
              purchases, trading, a reward system, and much more than just
              gaming.
            </p>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="retail">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8">
            <div class="industry-card-info d-flex justify-content-center align-items-center">
              <a [routerLink]="['/blockchain-in-retail']" class="sector-link d-flex justify-content-center align-items-center">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/retail.webp" />
                  <source
                    type="image/svg"
                    srcset="assets-new/images/home-v2/retail.svg" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/retail.svg"
                    width="44"
                    height="44"
                    class="img-fluid"
                    alt="Retail icon"
                    title="Retail" />
                </picture>
                <h4>Retail</h4>
              </a>
            </div>
            <p class="industry-card-para">
              Rental businesses will eventually benefit from blockchain app
              development services, not restricting their contribution just to
              manufacturers and customers. Blockchain benefits dealers and
              retailers in tracking and tracing their orders, which is more
              transparent and efficient in blockchain.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Client Success Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            Client <span> Success Stories </span>
          </h2>
          <p>
            Catch up on our client's success stories at BlockchainX. We have
            worked with various clients and on various projects in blockchain
            platform development. And here are some of the top projects in which
            we were involved.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="client-success">
          <owl-carousel-o [options]="clientSuccess">
            <ng-template carouselSlide>
              <div class="row pb-lg-3 pb-0">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Farmsent</h3>
                    <p class="pb-lg-3 pb-0">
                      Agriculture-based blockchain business solution. Connecting
                      farmers and customers directly benefits both in terms of
                      quality, price, and profit. Farmsent drives the
                      agriculture sector to the forefront with transparency and
                      agility.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <!-- <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/farmsent.webp"
                    />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/farmsent.png"
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/farmsent.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Farmsent"
                      title="Farmsent"

                    />
                  </picture> -->

                  <picture>
                    <source
                      srcset="
                      assets-new/images/home-v2/farmsent-400.webp 400w,
                      assets-new/images/home-v2/farmsent-600.webp 600w,
                      assets-new/images/home-v2/farmsent-800.webp 800w,
                      assets-new/images/home-v2/farmsent-1000.webp 1000w,
                      assets-new/images/home-v2/farmsent-1200.webp 1200w,
                      assets-new/images/home-v2/farmsent-1400.webp 1400w,
                      assets-new/images/home-v2/farmsent-1600.webp 1600w,
                      assets-new/images/home-v2/farmsent-1800.webp 1800w,
                      assets-new/images/home-v2/farmsent-2000.webp 2000w"
                      sizes="(min-width: 800px) 400px, 95vw"
                      type="image/webp" />
                    <source
                      srcset="
                      assets-new/images/home-v2/farmsent-400.png 400w,
                      assets-new/images/home-v2/farmsent-600.png 600w,
                      assets-new/images/home-v2/farmsent-800.png 800w,
                      assets-new/images/home-v2/farmsent-1000.png 1000w,
                      assets-new/images/home-v2/farmsent-1200.png 1200w,
                      assets-new/images/home-v2/farmsent-1400.png 1400w,
                      assets-new/images/home-v2/farmsent-1600.png 1600w,
                      assets-new/images/home-v2/farmsent-1800.png 1800w,
                      assets-new/images/home-v2/farmsent-2000.png 2000w"
                      sizes="(min-width: 800px) 400px, 95vw"
                      type="image/png" />
                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/farmsent-400.png"
                      class="img-fluid pb-md-0 pb-4"
                      width="400"
                      height="237"
                      alt="Farmsent"
                      title="Farmsent" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Real World NFT</h3>
                    <p class="pb-lg-3 pb-0">
                      Tokenizing real-world assets into NFTs is a trending
                      project. With the perks of transparency, fractional
                      ownership, and multiple revenue streaming options, there
                      is high traction in the market among investors and owners.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/real-world.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/real-world.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/real-world.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Real world"
                      title="Real world" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Layer One X</h3>
                    <p class="pb-lg-3 pb-0">
                      A community for developers to interact with fellow
                      developers, project holders, and investors. The platform's
                      ability to actively connect and interact drives the
                      community with consistent engagement.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/l1x.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/l1x.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/l1x.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Layer One X"
                      title="Layer One X" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Elfin Games</h3>
                    <p class="pb-lg-3 pb-0">
                      A hub of web3 games for users to explore and expand the
                      Play to Earn economy.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/elfin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/elfin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/elfin.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Elfin Games"
                      title="Elfin Games" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">BOBNet</h3>
                    <p class="pb-lg-3 pb-0">
                      Meme coin market intelligence helps users identify trading
                      trends and maximize their profits eventually.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/bobnet.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/bobnet.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/bobnet.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="BOBNet"
                      title="BOBNet" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Big Time Coin</h3>
                    <p class="pb-lg-3 pb-0">
                      The real estate tokenization platform benefits investors,
                      rentals, users, and the entire community with different
                      modules of operations.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/big-time.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/big-time.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/big-time.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Big Time Coin"
                      title="Big Time Coin" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Ekta</h3>
                    <p class="pb-lg-3 pb-0">
                      A decentralized ecosystem built with the ability to
                      perform everything and anything. Being a blockchain
                      itself, it renders and supports DAO and has its own
                      marketplace and token to interact with.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/ekta.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/ekta.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/ekta.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Ekta"
                      title="Ekta" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">StarCard Sports Games</h3>
                    <p class="pb-lg-3 pb-0">
                      A play-to-earn decentralized gaming arena was developed to
                      connect real-world players through digital gaming. This
                      game is based on football, along with metaverse abilities
                      that draw income.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/starcard.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/starcard.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/starcard.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="StarCard Sports Games"
                      title="StarCard Sports Games" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Bounty Temple</h3>
                    <p class="pb-lg-3 pb-0">
                      The play-to-earn gaming community for gaming enthusiasts
                      to explore the endless possibilities of the web3 arena,
                      providing opportunities to monetize and maximize benefits.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/bounty.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/bounty.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/bounty.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Bounty Temple"
                      title="Bounty Temple" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Zukiverse</h3>
                    <p class="pb-lg-3 pb-0">
                      Zuki is a P2E gaming platform that is a community-oriented
                      space that builds characters, virtual goods, puzzle games,
                      quizzes, and metaverse structures all in one.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/zuki.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/zuki.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/zuki.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Zukiverse"
                      title="Zukiverse" />
                  </picture>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Tech overall expert Start -->
<section class="section-service-main">
  <div class="container">
    <div class="expert-overall">
      <div class="expert-detail d-flex align-items-center justify-content-sm-between justify-content-around flex-wrap">
        <div class="expert-digit">
          <span class="position-relative d-flex justify-content-center pb-0">250+ </span>
          <p class="d-block text-center fw-normal">Projects</p>
        </div>
        <div class="expert-digit">
          <span class="position-relative d-flex justify-content-center pb-0">99% </span>
          <p class="d-block text-center fw-normal">Customer Satisfaction</p>
        </div>
        <div class="expert-digit">
          <span class="position-relative d-flex justify-content-center pb-0">80% </span>
          <p class="d-block text-center fw-normal">Retention Rate</p>
        </div>
        <div class="expert-digit">
          <span class="position-relative d-flex justify-content-center pb-0">80%</span>
          <p class="d-block text-center fw-normal">Repeat Business</p>
        </div>
      </div>
      <div class="discuss-link text-center">
        <a [routerLink]="['/contact']">
          <span>Contact Us</span>
          <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
        </a>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Develoment process Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            <span>Blockchain App </span> Development Process Includes
          </h2>
          <p>
            End-to-end practice, that is more keen on quality, efficiency, and
            exploring maximized ability. BlockchainX follows this regime
            religiously in every project that's pitched in.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/blockchain-app-development-process-includes.png"
              width="500"
              height="500"
              class="img-fluid pb-md-0 pb-3"
              alt="Blockchain App Development Process Includes"
              title="Blockchain App Development Process Includes" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Requirements Gathering</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  When a client pitches in with a requirement and a project, as
                  a renowned blockchain development service company, we hear
                  through and discuss their timeline, budgets, and requirements.
                  Basically, gather the information and legally agree to work
                  together with each other.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Specification and customization</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  We have the next level of discussion on working out the
                  possibilities and practicalities of the projects. We suggest
                  additional specifications and put them from our side in
                  blockchain app development; eventually, the client requests
                  their customization expectations on the same.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Market Trends</h3>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p>
                With all that in hand, the next step in blockchain development
                services is to do a round of market analysis to identify trends
                and user expectations to make the blockchain app developed to be
                convenient and friendly.
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Building the architecture</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Going further, we go ahead with building the structure of the
                  platform, from infrastructure to screen. We curate the
                  platform based on the requirements given based on the
                  blockchain network.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Integrations and Compliance</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  We then integrate the coded smart contracts and the platform
                  along with the other compliances, which bring the bits and
                  patches all together to form the solid structure. Here, we
                  also ensure the platform meets the legal regulations and that
                  its functions are not violating the norms.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Testing</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  The next important step in Blockchain platform solution
                  development is testing. After rigors and on-point
                  scrutinization, corrections, and rework, We ensure the
                  platform is meeting the client's requirements and performing
                  the assigned tasks.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-lg-4 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Launching</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Going ahead, the grand launch of the blockchain platform we
                  curated is open for users and critics to share their
                  experiences and suggestions on the blockchain app developed.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Upgradation</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Then, in need of any additional support, we provide the same,
                  and as the platform gets easier, there will be an eventual
                  release of updates and integrations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Development Platform Start -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading">
          <h3>
            We Built <span>Enterprise Blockchain</span> On Different Networks
            Such As
          </h3>
        </div>
      </div>
    </div>
    <div
      class="row row-cols-3 row-cols-sm-4 row-cols-md-6 row-cols-lg-7 justify-content-center tron-token-images">
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
            type="image/webp"
            srcset="assets-new/images/home-v2/ethereum-v2.webp" />
            <source
            type="image/svg"
            srcset="assets-new/images/home-v2/ethereum-v2.svg" />
            <img 
            loading="lazy"
              data-src="assets-new/images/home-v2/ethereum-v2.svg"
              class="img-fluid"
              alt="Ethereum"
              title="Ethereum"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
            <img 
            loading="lazy"
              src="assets-new/images/home-v2/light-theme/ethereum-v2.svg"
              class="img-fluid"
              alt="Ethereum"
              title="Ethereum"
              width="50"
              height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/polygon-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/polygon-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/polygon-v2.svg"
              class="img-fluid"
              alt="Polygon"
              title="Polygon"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/polygon-v2.svg"
            class="img-fluid"
            alt="Polygon"
            title="Polygon"
            width="50"
            height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/binance-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/binance-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/binance-v2.svg"
              class="img-fluid"
              alt="Binance"
              title="Binance"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/binance-v2.svg"
            class="img-fluid"
            alt="Binance"
            title="Binance"
            width="50"
            height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/hyperledger-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/hyperledger-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/hyperledger-v2.svg"
              class="img-fluid"
              alt="Hyperledger"
              title="Hyperledger"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/hyperledger-v2.svg"
            class="img-fluid"
            alt="Hyperledger"
              title="Hyperledger"
              width="50"
              height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/corda-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/corda-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/corda-v2.svg"
              class="img-fluid"
              alt="Corda"
              title="Corda"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/corda-v2.svg"
            class="img-fluid"
            alt="Corda"
              title="Corda"
              width="50"
              height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
              <picture>
                <source
                type="image/webp"
                srcset="assets-new/images/home-v2/substrate-v2.webp" />
              <source
                type="image/svg"
                srcset="assets-new/images/home-v2/substrate-v2.svg" />
              <img loading="lazy"
                data-src="assets-new/images/home-v2/substrate-v2.svg"
                class="img-fluid"
                alt="Substrate"
                title="Substrate"
                width="70"
                height="70"
                  *ngIf="!isDarkmode" />
              </picture>
              <img loading="lazy"
                src="assets-new/images/home-v2/light-theme/substrate-v2.svg"
                class="img-fluid"
                alt="Substrate"
                title="Substrate"
                width="70"
                height="70"
                  *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/avalanche-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/avalanche-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/avalanche-v2.svg"
              class="img-fluid"
              alt="Avalanche"
              title="Avalanche"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/avalanche-v2.svg"
            class="img-fluid"
            alt="Avalanche"
            title="Avalanche"
            width="50"
              height="50"
              *ngIf="isDarkmode" />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/polkadot-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/polkadot-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/polkadot-v2.svg"
              class="img-fluid"
              alt="Polkadot"
              title="Polkadot"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/polkadot-v2.svg"
            class="img-fluid"
            alt="Polkadot"
            title="Polkadot"
            width="50"
              height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/protocol-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/protocol-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/protocol-v2.svg"
              class="img-fluid"
              alt="NearProtocol"
              title="NearProtocol"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/protocol-v2.svg"
            class="img-fluid"
            alt="NearProtocol"
            title="NearProtocol"
            width="50"
              height="50"
              *ngIf="isDarkmode" />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/hedera-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/hedera-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/hedera-v2.svg"
              class="img-fluid"
              alt="Hedera"
              title="Hedera"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/hedera-v2.svg"
            class="img-fluid"
            alt="Hedera"
            title="Hedera"
            width="50"
              height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
            type="image/webp"
            srcset="assets-new/images/home-v2/tezos-v2.webp" />
          <source
            type="image/svg"
            srcset="assets-new/images/home-v2/tezos-v2.svg" />
          <img loading="lazy"
            data-src="assets-new/images/home-v2/tezos-v2.svg"
            class="img-fluid"
            alt="Tezos"
            title="Tezos"
            width="50"
            height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/tezos-v2.svg"
            class="img-fluid"
            alt="Tezos"
            title="Tezos"
            width="50"
            height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/eos-v2.webp" />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/eos-v2.svg" />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/eos-v2.svg"
              class="img-fluid"
              alt="eos"
              title="eos"
              width="50"
              height="50"
              *ngIf="!isDarkmode" />
          </picture>
          <img loading="lazy"
            src="assets-new/images/home-v2/light-theme/eos-v2.svg"
            class="img-fluid"
            alt="eos"
            title="eos"
            width="50"
            height="50"
              *ngIf="isDarkmode" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Custom Blockchain Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-10 mx-auto text-center">
        <div class="metaverse_heading">
          <h3 class="mb-3">
            <span>As Your Blockchain</span> App Development Company
          </h3>
          <p>
            At BlockchainX, we connect the world of possibilities! Over the
            years, we have provided custom blockchain app development services
            for blockchain-based start-ups. We have also evolved as one of the
            leading blockchain development company, and together as a team of
            experienced developers, we progress as we explore.
          </p>
        </div>
      </div>
    </div>
    <div class="custom-tab-main">
      <ul class="nav nav-tabs d-none d-lg-flex" id="myTab">
        <li class="nav-item">
          <a
            href="#certifiedexperts"
            class="nav-link active"
            data-bs-toggle="tab">Experts in the field</a>
        </li>
        <li class="nav-item">
          <a href="#clientfocused" class="nav-link" data-bs-toggle="tab">Assured
            security and compliance</a>
        </li>
        <li class="nav-item">
          <a href="#complianceassured" class="nav-link"
            data-bs-toggle="tab">Clients are the center</a>
        </li>
        <li class="nav-item">
          <a href="#support" class="nav-link" data-bs-toggle="tab">Advancing
            technology</a>
        </li>
        <li class="nav-item">
          <a href="#upgrading" class="nav-link" data-bs-toggle="tab">Open to
            upgrading</a>
        </li>
        <li class="nav-item">
          <a href="#clock" class="nav-link" data-bs-toggle="tab">Round-the-clock
            support</a>
        </li>
      </ul>
      <div class="tab-content accordion" id="myTabTwo">
        <div
          class="tab-pane fade show active accordion-item"
          id="certifiedexperts">
          <h2 class="accordion-header d-lg-none" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne">
              Experts in the field
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#myTabTwo">
            <div class="accordion-body">
              <div class="row d-flex align-items-center">
                <div class="col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-lg-center justify-content-start custom-tab-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/experts-in-the-field.webp
                        " />

                      <source
                        type="image/png"
                        srcset="
                          assets-new/images/home-v2/experts-in-the-field.png
                        " />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/experts-in-the-field.png"
                        class="img-fluid"
                        alt="Experts in the field"
                        title="Experts in the field"
                        width="250"
                        height="250" />
                    </picture>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p class="pb-lg-4 pb-1 pt-lg-0 pt-2">
                    Our blockchain development services are handled by
                    experienced professionals who have hands-on experience in
                    curating business-class solutions
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['']">
                      <span>Book Free</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="clientfocused">
          <h2 class="accordion-header d-lg-none" id="assureTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo">
              Assured security and compliance
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="assureTwo"
            data-bs-parent="#myTabTwo">
            <div class="accordion-body">
              <div class="row d-flex align-items-center">
                <div class="col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-lg-center justify-content-start custom-tab-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/certified-experts.webp
                        " />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/certified-experts.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/certified-experts.png"
                        class="img-fluid"
                        alt="Assured security and compliance"
                        title="Assured security and compliance"
                        width="250"
                        height="250" />
                    </picture>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p class="pb-lg-4 pb-1 pt-lg-0 pt-2">
                    Be built with 100% quality solutions, never compromising on
                    standards, and ensure they meet all the legal compliance
                    regulations and are secured.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['']">
                      <span>Book Free</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="complianceassured">
          <h2 class="accordion-header d-lg-none" id="headingThree">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#clientsThird"
              aria-expanded="true"
              aria-controls="clientsThird">
              Clients are the center
            </button>
          </h2>
          <div
            id="clientsThird"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#myTabTwo">
            <div class="accordion-body">
              <div class="row d-flex align-items-center">
                <div class="col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-lg-center justify-content-start custom-tab-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/compliance-assured.webp
                        " />

                      <source
                        type="image/png"
                        srcset="
                          assets-new/images/home-v2/compliance-assured.png
                        " />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/compliance-assured.png"
                        class="img-fluid"
                        alt="Clients are the center"
                        title="Clients are the center"
                        width="250"
                        height="250" />
                    </picture>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p class="pb-lg-4 pb-1 pt-lg-0 pt-2">
                    Our blockchain app development solutions are centered around
                    the client's requirements, and we ensure that we meet their
                    recruitment needs on a par scale.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['']">
                      <span>Book Free</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="support">
          <h2 class="accordion-header d-lg-none" id="advanceFour">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#advanceFourth"
              aria-expanded="true"
              aria-controls="advanceFourth">
              Advancing technology
            </button>
          </h2>
          <div
            id="advanceFourth"
            class="accordion-collapse collapse"
            aria-labelledby="advanceFour"
            data-bs-parent="#myTabTwo">
            <div class="accordion-body">
              <div class="row d-flex align-items-center">
                <div class="col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-lg-center justify-content-start custom-tab-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/24-7-support.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/24-7-support.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/24-7-support.png"
                        class="img-fluid"
                        alt="Advancing technology"
                        title="Advancing technology"
                        width="250"
                        height="250" />
                    </picture>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p class="pb-lg-4 pb-1 pt-lg-0 pt-4">
                    We employ advanced, avant-garde technologies in the process
                    of blockchain development that stand out unlike others and
                    keep you on top among your business competitors.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['']">
                      <span>Book Free</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="upgrading">
          <h2 class="accordion-header d-lg-none" id="headingFive">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive">
              Open to upgrading
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#myTabTwo">
            <div class="accordion-body">
              <div class="row d-flex align-items-center">
                <div class="col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-lg-center justify-content-start custom-tab-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/open-to-upgrading.webp
                        " />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/open-to-upgrading.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/open-to-upgrading.png"
                        class="img-fluid"
                        alt=" Open to upgrading"
                        title=" Open to upgrading"
                        width="250"
                        height="250" />
                    </picture>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p class="pb-lg-4 pb-1 pt-lg-0 pt-4">
                    The team and our vision are more aligned to upgrade and
                    evolve with the active digital and technological revolution
                    that is happening.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['']">
                      <span>Book Free</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade accordion-item" id="clock">
          <h2 class="accordion-header d-lg-none" id="roundSix">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix">
              Round-the-clock support
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="roundSix"
            data-bs-parent="#myTabTwo">
            <div class="accordion-body">
              <div class="row d-flex align-items-center">
                <div class="col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-lg-center justify-content-start custom-tab-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="
                          assets-new/images/home-v2/round-the-clock-support.webp
                        " />

                      <source
                        type="image/png"
                        srcset="
                          assets-new/images/home-v2/round-the-clock-support.png
                        " />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/round-the-clock-support.png"
                        class="img-fluid"
                        alt="Round-the-clock support"
                        title="Round-the-clock support"
                        width="250"
                        height="250" />
                    </picture>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p class="pb-lg-4 pb-1 pt-lg-0 pt-4">
                    We provide round-the-clock support and service actively for
                    the project we are engaged with and ensure to meet the
                    deadlines promptly.
                  </p>
                  <div class="deploy-link">
                    <a [routerLink]="['']">
                      <span>Book Free</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Contact Our Experts -->
<section class="section-service-main">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6">
        <div class="contact-our-experts">
          <h3 class="common-sub-heading-alt">
            Advance Your Traditions With Advanced Tractions Pulling Solutions
            Provided With Blockchain!
          </h3>
          <div class="discuss-link">
            <a [routerLink]="['/contact']">
              <span>Tap to Book your call.</span>
              <img loading="lazy"
                src="assets-new/images/right-arrow.png"
                alt="link-arrow"
                class="img-fluid"
                width="50"
                height="15"
                *ngIf="!isDarkmode" />
              <img loading="lazy"
                src="assets-new/images/right-arrow-dark.png"
                alt="link-arrow"
                class="img-fluid"
                width="50"
                height="15"
                *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 d-lg-block d-none">
        <picture *ngIf="!isDarkmode">
          <source
            type="image/webp"
            srcset="assets-new/images/home-v2/blockchain-structure.webp" />
            <source
            type="image/png"
            srcset="assets-new/images/home-v2/blockchain-structure.png" />
          <img loading="lazy"
            data-src="assets-new/images/home-v2/blockchain-structure.webp"
            alt="contact-our-experts-image"
            width="584"
            height="373"
            class="img-fluid" />
        </picture>

        <picture *ngIf="isDarkmode">
          <source
            type="image/webp"
            srcset="assets-new/images/home-v2/light-theme/blockchain-structure.webp" />
            <source
            type="image/png"
            srcset="assets-new/images/home-v2/light-theme/blockchain-structure.png" />
          <img loading="lazy"
            data-src="assets-new/images/home-v2/light-theme/blockchain-structure.png"
            alt="contact-our-experts-image"
            width="584"
            height="373"
            class="img-fluid" />
        </picture>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-alt text-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="title-wrap d-flex flex-column align-items-center">
          <div class="row services-listing pb-sm-5 pb-4 mb-sm-0 mb-2 text-center">
            <div class="col-lg-12">
              <div class="common-sub-heading sub-title">
                Our Achievements<span class="dots">.</span>
              </div>
              <div class="whys_section mb-0">
                <h2 class="pb-0 text-center">
                  <span>Taking innovation</span> beyond borders to the hands <br />
                  of happy customers globally
                </h2>
              </div>
            </div>
          </div>
          <!-- <a [routerLink]="['/contact-us']" class="btn btn-primary"
            >Talk to our team
            <picture>
              <source
                type="image/webp"
                srcset="assets/images/home-new/icon-arrow-up.webp"
              />
              <source
                type="image/png"
                srcset="assets/images/home-new/icon-arrow-up.png"
              />
              <img
                loading="lazy"
                data-src="assets/images/home-new/icon-arrow-up.svg"
                alt="arrow-icon"
                title="arrow-icon"
                width="21"
                height="21"
                class="img-fluid"
              />
            </picture>
          </a> -->
        </div>
        <owl-carousel-o [options]="achievementsOptions">
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home/award/clutch.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/home/award/clutch.png"
                  />

                  <img
                    loading="lazy"
                    data-src="assets-new/images/award/clutch.png"
                    alt="clutch"
                    class="img-fluid"
                    title="clutch"
                    width="120"
                    height="120"
                  />
                </picture>
              </div>
              <div class="erc-20-head pb-2">Clutch</div>
              <p>Top 1000 Companies <br />Global 2020</p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home/award/futura.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/home/award/futura.png"
                  />

                  <img
                    loading="lazy"
                    data-src="assets-new/images/award/futura.png"
                    alt="futura"
                    class="img-fluid"
                    title="futura"
                    width="120"
                    height="120"
                  />
                </picture>
              </div>
              <div class="erc-20-head pb-2">App Futura</div>
              <p>Top Custom Software <br />Development Company</p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home/award/wadline.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/home/award/wadline.png"
                  />

                  <img
                    loading="lazy"
                    data-src="assets-new/images/award/wadline.png"
                    alt="BX Finance"
                    class="img-fluid"
                    title="BX Finance"
                    width="120"
                    height="120"
                  />
                </picture>
              </div>
              <div class="erc-20-head pb-2">Wadline</div>
              <p>Top Web Developers <br />2020</p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home/award/clutch2.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/home/award/clutch2.png"
                  />

                  <img
                    loading="lazy"
                    data-src="assets-new/images/award/clutch2.png"
                    alt="cyphershieldClutch"
                    class="img-fluid"
                    title="cyphershieldClutch"
                    width="120"
                    height="120"
                  />
                </picture>
              </div>
              <div class="erc-20-head pb-2">Clutch</div>
              <p>Top B2B Companies <br />United States 2019</p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home/award/goodfirms.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/home/award/goodfirms.png"
                  />

                  <img
                    loading="lazy"
                    data-src="assets-new/images/award/goodfirms.png"
                    alt="Goodfirms"
                    class="img-fluid"
                    title="Goodfirms"
                    width="120"
                    height="120"
                  />
                </picture>
              </div>
              <div class="erc-20-head pb-2">Goodfirms</div>
              <p>Top Software <br />Development Company</p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <a href="https://www.designrush.com/" target="_blank" rel="nofollow noopener">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home/award/designrush.webp"
                    />
  
                    <source
                      type="image/png"
                      srcset="assets-new/images/home/award/designrush.png"
                    />
  
                    <img
                      loading="lazy"
                      data-src="assets-new/images/home/award/designrush.png"
                      alt="designrush"
                      class="img-fluid"
                      title="designrush"
                      width="120"
                      height="120"
                    />
                  </picture>
                </a>
              </div>
              <div class="erc-20-head pb-2">DesignRush</div>
              <p>Top outsourcing <br />companies in USA</p>
            </div>  
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home/award/selected-firms.webp"
                    />
  
                    <source
                      type="image/png"
                      srcset="assets-new/images/home/award/selected-firms.png"
                    />
  
                    <img
                      loading="lazy"
                      data-src="assets-new/images/home/award/selected-firms.png"
                      alt="selected-firms"
                      class="img-fluid"
                      title="selected-firms"
                      width="120"
                      height="120"
                    />
                  </picture>
              </div>
              <div class="erc-20-head pb-2">SelectedFirms</div>
              <p>Top IT Consulting <br />Firms in USA</p>
            </div>  
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                <a href="https://topsoftwarecompanies.co/software-development/agencies/india" target="_blank" rel="nofollow noopener">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home/award/top-software-developers.webp"
                    />
  
                    <source
                      type="image/png"
                      srcset="assets-new/images/home/award/top-software-developers.png"
                    />
  
                    <img
                      loading="lazy"
                      data-src="assets-new/images/home/award/top-software-developers.png"
                      alt="top-software-developers"
                      class="img-fluid"
                      title="top-software-developers"
                      width="120"
                      height="120"
                    />
                  </picture>
                </a>
              </div>
              <div class="erc-20-head pb-2">Top Software Companies</div>
              <p>Best software development <br />company in india.</p>
            </div>  
          </ng-template>
          <ng-template carouselSlide>
            <div class="achievement">
              <div class="achievement-logo">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home/award/featured-on-business-app.webp"
                    />
  
                    <source
                      type="image/png"
                      srcset="assets-new/images/home/award/featured-on-business-app.png"
                    />
  
                    <img
                      loading="lazy"
                      data-src="assets-new/images/home/award/featured-on-business-app.png"
                      alt="Featured on business of app"
                      class="img-fluid"
                      title="Featured on business of app"
                      width="120"
                      height="120"
                    />
                  </picture>
              </div>
              <div class="erc-20-head pb-2">Business of Apps</div>
              <p>Top App Development Company</p>
            </div>  
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<!-- FAQ Section -->
<section class="section-service-main">
  <div class="container">
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingFirst">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFirst"
                  aria-expanded="true"
                  aria-controls="collapseFirst">
                  What are blockchain development services?
                </button>
              </div>
              <div
                id="collapseFirst"
                class="accordion-collapse collapse show"
                aria-labelledby="headingFirst"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Blockchain development services include curating a
                    full-fledged ecosystem, or decentralized platforms that are
                    immutable, transparent, and secured with the ability of
                    blockchain backing them in terms of operations.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSecond">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSecond"
                  aria-expanded="false"
                  aria-controls="collapseSecond">
                  What is the time taken for blockchain app development?
                </button>
              </div>
              <div
                id="collapseSecond"
                class="accordion-collapse collapse"
                aria-labelledby="headingSecond"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The time involved in blockchain app development is very
                    subjective, based on the requirements, technology, and
                    features or functions that are incorporated, and most rely
                    on the client's timeframe for launching the solution.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThre">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThird"
                  aria-expanded="false"
                  aria-controls="collapseThird">
                  Can we include customization in blockchain services?
                </button>
              </div>
              <div
                id="collapseThird"
                class="accordion-collapse collapse"
                aria-labelledby="headingThre"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Opting for Blockchain services with BlockchainX means you
                    are on a full scale open to customizing your Blockchain app
                    for you and your business, which enables operations,
                    automates functions, and does much more.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFourth">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourth"
                  aria-expanded="false"
                  aria-controls="collapseFourth">
                  Who is an ideal Blockchain development provider?
                </button>
              </div>
              <div
                id="collapseFourth"
                class="accordion-collapse collapse"
                aria-labelledby="headingFourth"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    While choosing your blockchain app development service
                    providers, it's important to be keen on their tier
                    experience, their transparency, and the level of
                    customization they provide. BlockchainX, over the years, has
                    proved to contribute consistently and sustainably to the
                    growth and progress of the evolving global market.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFiffth">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifth"
                  aria-expanded="false"
                  aria-controls="collapseFifth">
                  What services are included with blockchain app development?
                </button>
              </div>
              <div
                id="collapseFifth"
                class="accordion-collapse collapse"
                aria-labelledby="headingFiffth"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    At BlockchainX, we render end-to-end blockchain development
                    as your blockchain development company. From ideating to
                    customizing, preparing smart contracts, auditing them, and
                    deploying solutions, we are also very involved in terms of
                    DeFi, DEX, tokenizations, Metaverse, and many other
                    development services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FAQ Section -->

<!-- Blog Start -->
<section class="section-service-alt">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="common-sub-heading sub-title">
          Blog<span class="dots">.</span>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']" aria-label="blog-readmore">
              <!-- <span>Read More</span> -->
                <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/blockchain-development-cost']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-360.webp 320w,
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-480.webp 640w,
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-696.webp 960w,
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-966.webp 1280w
                    "
                  />
                  <source
                    type="image/jpg"
                    srcset="
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-360.jpg 320w,
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-480.jpg 640w,
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-696.jpg 960w,
                    assets-new/images/blog/blockchain-development-cost/blockchain-development-966.jpg 1280w
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/blockchain-development-cost/blockchain-development-360.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="Real Estate Tokenization"
                    title="How Much Does Blockchain Development Cost in 2024?"
                  />
                </picture>
                <h3>
                  How Much Does Blockchain Development Cost in 2024?
                </h3>
                <p>
                  If you are looking for secure and transparent transactions, Blockchain technology can be your safest companion. Apart ...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/blockchain-development-cost']">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/what-is-depin']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/depin/depin-extravaganza-360.webp 320w,
                    assets-new/images/blog/depin/depin-extravaganza-480.webp 640w,
                    assets-new/images/blog/depin/depin-extravaganza-696.webp 960w,
                    assets-new/images/blog/depin/depin-extravaganza-966.webp 1280w
                    "
                  />
                  <source
                    type="image/jpeg"
                    srcset="
                    assets-new/images/blog/depin/depin-extravaganza-360.jpg 320w,
                    assets-new/images/blog/depin/depin-extravaganza-480.jpg 640w,
                    assets-new/images/blog/depin/depin-extravaganza-696.jpg 960w,
                    assets-new/images/blog/depin/depin-extravaganza-966.jpg 1280w
                    "
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/blog/depin/depin-extravaganza-360.jpg"
                    width="404"
                    height="246"
                    alt="DePIN Extravaganza"
                    title="DePIN Extravaganza"
                    class="img-fluid"
                  />
                </picture>
              </a>
              <h3>
                <a [routerLink]="['/what-is-depin']">DePIN - What Is
                  Decentralized Physical Infrastructure
                  Network?</a>
              </h3>
              <p>
                <a [routerLink]="['/what-is-depin']">DePIN, or the Decentralized
                  Physical Infrastructure Network,
                  is a decentralized protocol using tokens to incentivize...</a>
              </p>

              <div class="content-link">
                <a [routerLink]="['/what-is-depin']" aria-label="what-is-depin">
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/ai-token-definition']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-360.webp 320w,
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-480.webp 640w,
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-696.webp 960w,
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-966.webp 1280w
                    "
                  />
                  <source
                    type="image/jpeg"
                    srcset="
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-360.jpg 320w,
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-480.jpg 640w,
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-696.jpg 960w,
                    assets-new/images/blog/all-about-ai-token/all-about-ai-token-966.jpg 1280w
                    "
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/blog/all-about-ai-token/all-about-ai-token-360.jpg"
                    width="404"
                    height="246"
                    alt="AI Token"
                    title="AI Token"
                    class="img-fluid"
                  />
                </picture>
              </a>
              <h3>
                <a [routerLink]="['/ai-token-definition']">AI Token - The Whats
                  Of It!</a>
              </h3>
              <p>
                <a [routerLink]="['/ai-token-definition']">AI tokens are the new
                  revolution in the crypto market.
                  Artificial intelligence is leveraging every sector, and
                  eventually...</a>
              </p>

              <div class="content-link">
                <a
                  [routerLink]="['/ai-token-definition']"
                  aria-label="ai-token-definition">
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/real-estate-tokenization-guide']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-360.webp 320w,
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-480.webp 640w,
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-696.webp 960w,
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-966.webp 1280w
                    "
                  />
                  <source
                    type="image/jpeg"
                    srcset="
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-360.jpg 320w,
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-480.jpg 640w,
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-696.jpg 960w,
                    assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-966.jpg 1280w
                    "
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide-360.jpg"
                    width="404"
                    height="246"
                    alt="Real Estate Tokenization"
                    title="Real Estate Tokenization Enabling Endless Investment Opportunites"
                    class="img-fluid"
                  />
                </picture>
              </a>
              <h3>
                <a [routerLink]="['/real-estate-tokenization-guide']">How does
                  Real Estate Tokenization Revolutionize
                  Investments?</a>
              </h3>
              <p>
                <a [routerLink]="['/real-estate-tokenization-guide']">Over the
                  years, investment scales have been tremendously
                  improving, and new avenues are blooming now and then with
                  blockchain...</a>
              </p>

              <div class="content-link">
                <a [routerLink]="['/real-estate-tokenization-guide']">
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Most Trusted Start -->
<section class="section-service-main">
  <div class="metaverse-fact-section">
    <div class="violet-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="common-sub-heading sub-title">
            BlockchainX Overview<span class="dots">.</span>
          </div>
          <div class="commontext_wrap">
            <h2 class="text-start pb-3">
              <a href="https://www.sparkouttech.com/" target="_blank">Sparkout
                Tech Solutions Inc</a>
            </h2>
            <a href="https://www.sparkouttech.com/" target="_blank">
              <picture *ngIf="isDarkmode">
                <source
                  type="image/png"
                  srcset="assets-new/images/sparkout-logo.svg"
                />
                <img
                  loading="lazy"
                  src="assets-new/images/sparkout-logo.svg"
                  class="img-fluid"
                  alt="Sparkout logo"
                  width="220"
                  height="51"

                />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source
                  type="image/webp"
                  srcset="assets-new/images/sparkout-inc-logo.webp"
                />
                <source
                  type="image/png"
                  srcset="assets-new/images/sparkout-inc-logo.svg"
                />
                <img
                  loading="lazy"
                  src="assets-new/images/sparkout-inc-logo.svg"
                  class="img-fluid"
                  alt="Sparkout logo"
                  width="220"
                  height="51"
                />
              </picture>
            </a>
          </div>
        </div>
        <!-- <div class="col-lg-8">
            <div class="trust-badge">
                <img loading="lazy" data-src="assets-new/images/home-v2/clutch.png" alt="clutch" class="img-fluid me-2" width="40" height="40">
                <h4>
                    Most trusted Mobile App Consulting Company on Clutch
                  </h4>
            </div>
          </div> -->
      </div>
    </div>
    <div class="container mt-3">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-lg-5">
          <div>
            <p class="pb-lg-0 pb-2">
              BlockchainX is a leading Blockchain development company, the
              subsidiary company of Sparkout Tech curating upgrade business
              solutions since 2017. Meeting industry requirements in the global
              market, our advanced tech approaches revolutionize almost every
              sector.
            </p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="nfts-fact text-center">
                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/india.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/india.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/india.png"
                    alt="india map"
                    title="india map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <picture *ngIf="isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/light-theme/india.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/light-theme/india.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/light-theme/india.png"
                    alt="india map"
                    title="india map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    <!-- <span>C.M.Nagar, Ramakrishnapuram,</span> -->
                    <span class="d-md-block d-inline-block">No. 3 Gem Colony,</span>
                    <!-- <span>Coimbatore, Tamil Nadu,</span> -->
                    <span class="d-md-block d-inline-block">Near Siva Hospital, Ganapathy,</span>
                    <span class="d-md-block d-inline-block">Coimbatore, Tamil Nadu,</span>
                    <span class="d-md-block d-inline-block">India - 641 006.</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="nfts-fact text-center">
                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/asia.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/asia.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/asia.png"
                    alt="asia"
                    title="asia map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <picture *ngIf="isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/light-theme/asia.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/light-theme/asia.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/light-theme/asia.png"
                    alt="asia"
                    title="asia map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    <span class="d-md-block d-inline-block">Branch Office - US</span>
                    <span class="d-md-block d-inline-block">8 The Green, STE A</span>
                    <span class="d-md-block d-inline-block">Dover, De, Kent</span>
                    <span class="d-md-block d-inline-block">United States - 19901.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End-->

<section class="section-service-alt">
  <div class="container">
    <div class="contact-form">
      <div class="row">
        <div class="col-lg-6 col-md-12 contact-social-links">
          <h2>Get In Touch</h2>
          <p>
            Our expert team is professionally well-equipped with hands-on
            experience as a blockchain development company. Connect with us; at
            BlockchainX, we curate your desired sustainable solution!
          </p>
          <div class="contact-main-list">
            <div class="social-links">
              <a
                href="mailto:contact@blockchainx.tech"
                target="_blank"
                rel="noopener noreferrer"
                class="contact-mail"
                aria-label="contact-mail">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="currentColor">
                  <rect
                    x="2.5"
                    y="3.75"
                    width="25"
                    height="22.5"
                    rx="5"
                    stroke="#b56000"
                    stroke-width="1.875" />
                  <path
                    d="M2.5 8.75L11.8765 16.2512C13.7026 17.7121 16.2974 17.7121 18.1235 16.2512L27.5 8.75"
                    stroke="#b56000"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <!-- <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/mail.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/mail.png"
                  />

                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/mail.png"
                    alt="mail"
                    width="98"
                    height="49"
                  />
                </picture> -->
              </a>

              <a
                href="https://wa.me/7708889555"
                target="_blank"
                rel="noopener noreferrer"
                class="contact-mail"
                aria-label="contact-whatsapp">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor">
                  <path
                    d="M12 22C17.5229 22 22 17.5228 22 12C22 6.47715 17.5229 2 12 2C6.47718 2 2.00003 6.47715 2.00003 12C2.00003 13.5114 2.33535 14.9446 2.93565 16.229L2.22301 20.6245C2.1135 21.3 2.69954 21.8834 3.37452 21.7709L7.72984 21.045C9.02483 21.6575 10.4724 22 12 22Z"
                    stroke="#25d366"
                    stroke-width="1.5"
                    stroke-linecap="square"
                    stroke-linejoin="round" />
                  <path
                    d="M16 15.1111V14.6749C16 14.1331 15.6701 13.6458 15.167 13.4446L14.7005 13.258C14.1789 13.0494 13.5845 13.2753 13.3333 13.7778C13.3333 13.7778 12.2222 13.5556 11.3333 12.6667C10.4444 11.7778 10.2222 10.6667 10.2222 10.6667C10.7247 10.4155 10.9506 9.82105 10.742 9.29949L10.5554 8.83296C10.3542 8.32988 9.86693 8 9.32509 8H8.88889C8.39797 8 8 8.39797 8 8.88889C8 12.8162 11.1838 16 15.1111 16C15.602 16 16 15.602 16 15.1111Z"
                    fill="#25d366" />
                </svg>
              </a>

              <a
                href="https://t.me/sparkouttechblockchainx"
                target="_blank"
                rel="noopener noreferrer"
                class="contact-mail"
                aria-label="contact-twitter">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="currentColor">
                  <path
                    d="M5.21171 14.7936L25.7299 5.92611C26.6948 5.50911 27.7403 6.32055 27.5758 7.35874L24.7704 25.0601C24.5971 26.1534 23.2439 26.5716 22.484 25.7666L18.0391 21.0265C17.1363 20.0702 17.0655 18.5984 17.8725 17.5598L20.9641 13.2396C21.1499 13.0004 20.8565 12.6858 20.6049 12.8545L14.1418 17.1903C13.0451 17.926 11.7143 18.2287 10.4072 18.0396L5.54981 17.3371C4.21916 17.1447 3.97754 15.327 5.21171 14.7936Z"
                    stroke="#03a9f4"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 contact-inputs">
          <div class="getintouch getintochform bitrix-snippet-form">
            <!-- <div #crmForm></div> -->
            <div [innerHTML]="bitrixSnippetScript"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
