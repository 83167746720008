<section class="banners-section" #about>
  
    <div class="banner_wrapper">
      <div class="container" id="common-relative">
        <div class="green-vector"></div>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div class="banner_contentWrapper">
                <div class="caption">
                  <h1>
                    <span><strong> Web3 SocialFi </strong></span> Platform Development
                  </h1>
                  <p>
                    Our robust SocialFi development service is the perfect solution for high-growth startups in the web3 space. We provide in-house blockchain experts for founders who want to build their projects rapidly. Access our experienced teams with our SocialFi development service.
                  </p>

                  <div class="contact-link">
                    <a [routerLink]="['/contact']" class="home-link"
                      >Talk To Our Experts</a
                    >
                    <a [routerLink]="['/case-studies']" class="home-link-alt"
                      >View Case Study</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 text-center">
              <div class="banner-right-section">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/launch-your-token.webp" />
                  <source type="image/png" srcset="assets-new/images/token-images/launch-your-token.png" />
                  <img loading="lazy"  data-src="assets-new/images/token-images/launch-your-token.png" width="500" height="500" class="img-fluid" alt="Socialfi Platform Development" title="Socialfi Platform Development" />
                </picture>
              </div>
            </div>

        </div>
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img loading="lazy"

                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- Why consider AI Meme start-->
<section class="section-service-alt">
    <div class="container">
      <div class="whys_section mb-0 text-center">
        <h2 class="pb-lg-5 pb-4"><span>Why SocialFi </span>Development?</h2>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
          <div class="bep-work-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/why-should-you-choose.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/why-should-you-choose.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/why-should-you-choose.png"
                width="350" height="350" alt="Why SocialFi Development"
                title="Why SocialFi Development" class="BSC-Tokens-image img-fluid">
            </picture>
          </div>
        </div>
        <div
          class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <p>
                The success of any Web3 project lies in the community. SocialFi platforms are the perfect stage to create superior user engagement by incentivizing users to connect and interact with each other. As a SocialFi Development Company, we provide project founders with everything they need to succeed in their web3 startup journey, from tokenomics consulting to core platform development.
            </p>
          </div>

          <!-- <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Customized Tokens
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Trading Bot Integration
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> MEV Bot Protection
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Audited Codes
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>

    </div>
</section>
<!-- End -->

  <!-- Trending ERC20 Token List Start -->
<section class="section-service-alt">
    <div class="tending-bep-20 pb-xl-4">
      <div class="container">
        <div class="row">
          <div class="metaverse_heading mx-auto">
            <h3 class="popular-ai">
              <span>Top SocialFi</span>
              Projects in Web3
            </h3>
          </div>
        </div>
        <div class="row client-carousel">
          <div class="col-lg-12 mx-auto">
              <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                <div class="popular-ai-meme">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/ai-meme-coin/bytecoin.webp" />
                    <source type="image/png" srcset="assets-new/images/ai-meme-coin/bytecoin.png" />
                    <img loading="lazy"  data-src="assets-new/images/ai-meme-coin/bytecoin.png" width="auto" height="auto" class="img-fluid" alt="Steem" title="Steem" />
                  </picture>
                  <h4>Steem</h4>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="popular-ai-meme">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/ai-meme-coin/corgiai.webp" />
                    <source type="image/png" srcset="assets-new/images/ai-meme-coin/corgiai.png" />
                    <img loading="lazy"  data-src="assets-new/images/ai-meme-coin/corgiai.png" width="auto" height="auto" class="img-fluid" alt="Galaxe" title="Galaxe" />
                  </picture>
                  <h4>Galaxe</h4>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="popular-ai-meme">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/ai-meme-coin/grokai.webp" />
                    <source type="image/png" srcset="assets-new/images/ai-meme-coin/grokai.png" />
                    <img loading="lazy"  data-src="assets-new/images/ai-meme-coin/grokai.png" width="auto" height="auto" class="img-fluid" alt="Lukso" title="Lukso" />
                  </picture>
                  <h4>Lukso</h4>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="popular-ai-meme">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/ai-meme-coin/kizuna.webp" />
                    <source type="image/png" srcset="assets-new/images/ai-meme-coin/kizuna.png" />
                    <img loading="lazy"  data-src="assets-new/images/ai-meme-coin/kizuna.png" width="auto" height="auto" class="img-fluid" alt="Xcad" title="Xcad" />
                  </picture>
                  <h4>Xcad</h4>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="popular-ai-meme">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/ai-meme-coin/nyxia.webp" />
                    <source type="image/png" srcset="assets-new/images/ai-meme-coin/nyxia.png" />
                    <img loading="lazy"  data-src="assets-new/images/ai-meme-coin/nyxia.png" width="auto" height="auto" class="img-fluid" alt="Galaxy" title="Galaxy" />
                  </picture>
                  <h4>Galaxy</h4>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="popular-ai-meme">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/ai-meme-coin/turbo.webp" />
                    <source type="image/png" srcset="assets-new/images/ai-meme-coin/turbo.png" />
                    <img loading="lazy"  data-src="assets-new/images/ai-meme-coin/turbo.png" width="auto" height="auto" class="img-fluid" alt="Myriad Social" title="Myriad Social" />
                  </picture>
                  <h4>Myriad Social</h4>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>What You Get With<span> SocialFi Development Service</span></h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                <source type="image/png" srcset="assets-new/images/token-images/project-consulting.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="SocialFi Consulting" title="SocialFi Consulting" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">SocialFi Consulting</h4>
            <p>
                Our experts in Web3 SocialFi development help you curate your platform with the right features and technology solutions.
            </p>
            <div class="content-link">
                <a [routerLink]="['/blockchain-consulting-services']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="NFT Marketplace" title="NFT Marketplace" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                NFT Marketplace
            </h4>
            <p>
                Let your users create, trade, and earn NFTs and leverage the power of gamification for enhanced user experience.
            </p>
            <div class="content-link">
                <a [routerLink]="['/nft-marketplace-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="DeFi Wallet" title="DeFi Wallet" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">DeFi Wallet </h4>
            <p>
                Create a dedicated crypto wallet, branded to your project that allows users to connect and interact with your social platform.
            </p>
            <div class="content-link">
                <a [routerLink]="['/cryptocurrency-wallet-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Token Sale Platform" title="Token Sale Platform" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Token Sale Platform</h4>
            <p>
                Our SocialFi development service includes our prebuilt token sale platform that you can use to fundraise for your project.
            </p>
            <div class="content-link">
                <a [routerLink]="['/ready-made-ico-software']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="Staking Platform" title="Staking Platform" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Staking Platform</h4>
            <p>
                Launch Token and NFT Staking campaigns to entice users and build your user base with our SocialFi development service.
            </p>
            <div class="content-link">
                <a [routerLink]="['/nft-staking-platform-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Launchpad Platform" title="Launchpad Platform" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Launchpad Platform
            </h4>
            <p>
                Allow users to create their own economies and monetize their content by launching a Token or NFT Launchpad platform.
            </p>
            <div class="content-link">
                <a [routerLink]="['/white-label-launchpad-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!-- Related Services -->

  <!-- why-choose-section -->
  <section class="section-service-main">
      <div class="green-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 metmb-3">
            <div class="whys_section mb-3">
              <h2>
                Why Choose BlockchainX for  <span>SocialFi Platform Development?</span>
              </h2>
              <p>
                We are an end-to-end SocialFi Development Company with a team of industry-leading experts who can help you take the next step in your startup journey. Our value-driven offerings provide you with everything you need to build and launch a superior social platform, completely decentralized and running on secure smart contracts. Your next moonshot project is just a conversation away.
              </p>
              <div class="content-link my-3">
                <a [routerLink]="['/contact']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                    <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy"
                        data-src="assets-new/images/right-arrow-dark.png"
                        class="img-fluid"
                        width="50" height="15" alt="Link-arrow"
                        title="Link-arrow" *ngIf="isDarkmode" />
                      </picture>
                </a>
                <hr />
              </div>
            </div>
          </div>
          <div class="col-lg-6 metmb-3 Why-section">
            <div class="why_right_side">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                <img loading="lazy"
                  data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                  width="624"
                  height="521"
                  class="img-fluid"
                  alt="SocialFi Platform Development"
                  title="SocialFi Platform Development"
                   />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    How long does SocialFi Platform Development take?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The timelines for developing a SocialFi platform depend on the complexity of the project. Typically, a project takes anywhere from 2- 4 months to complete and launch on the Mainnet.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How can I choose the right blockchain for my project?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Choosing the right blockchain platform is crucial to increasing your user base and building a scalable platform in the long run. Our expert consultants guide you through the whole process.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Will I get after-sales maintenance and support?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Our SocialFi development service includes 4 months of free after-sales maintenance and support. This includes bug fixes, content changes, and minor iterations. For extensive maintenance support, we offer curated paid AMC plans that suit your project.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                How much does it cost to build a SocialFi platform
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    SocialFi platform development costs depend largely on the complexity of the project. Typically, an MVP would cost you $25,000. Complex projects with multiple modules and smart contracts can cost more.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                Do I need a token for my SocialFi project?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    While it is not necessary to launch a token for every project, most SocialFi projects would benefit from creating a token economy. It allows for seamless fundraising, user engagement and incentivization.
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

    <!-- Blog Start -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="container">
      <div class="row blog-list">
        <div class="col-lg-12">
          <div class="commontext_subhead">
            <h3>Blog<span class="dots">.</span></h3>
          </div>
          <div class="feature_wrap">
            <div class="commontext_wrap">
              <h2>Our Latest Compilation On The Market</h2>
            </div>
            <div class="deploy-link">
              <a [routerLink]="['/blog']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 left-vector">
          <owl-carousel-o [options]="blogs_details">
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a [routerLink]="['/what-is-erc20']">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/what-is-erc20/erc20-token-standard.webp
                      "
                    />
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="erc20-token-standard"
                      title="erc20-token-standard"
                    />
                  </picture>
                  <h3>
                    What is ERC20? A Guide to the Pinnacles of Ethereum Token
                    Standard
                  </h3>
                  <p>
                    While this crypto revolution continues to change the virtual
                    and the real world, the ERC20 token standards...
                  </p>
                </a>
                <div class="content-link">
                  <a [routerLink]="['/what-is-erc20']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                    '/erc1155-token-standard'
                  ]"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/erc-1155.webp
                      "
                    />
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/erc-1155.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/erc-1155.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="erc-1155"
                      title="erc-1155"
                    />
                  </picture>
                  <h3>
                    ERC1155 – Explore the ERC-1155 Token Standard
                  </h3>
                  <p>
                    If you understand what an non-fungible-token-development NFT
                     is and that the abbreviation...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                      '/erc1155-token-standard'
                    ]"
                  >
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                    '/how-to-create-a-private-blockchain'
                  ]"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.webp
                      "
                    />
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="private-blockchain-development"
                      title="private-blockchain-development"
                    />
                  </picture>
                  <h3>
                    How to Create a Private Blockchain For Intensify Business Security?
                  </h3>
                  <p>
                    With blockchain dominating the entire digital ecosystem, and finance
                     and technology taking over the world...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                      '/how-to-create-a-private-blockchain'
                    ]"
                  >
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                    '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                  ]"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/crypto-launchpad-development-steps.webp
                      "
                    />
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/crypto-launchpad-development-steps.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/crypto-launchpad-development-steps.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="crypto-launchpad-development-steps"
                      title="crypto-launchpad-development-steps"
                    />
                  </picture>
                  <h3>
                    Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad
                  </h3>
                  <p>
                    Crypto launchpad development is a great opportunity to keep up
                     and stay one step ahead in this booming...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                      '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                    ]"
                  >
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog End -->

  <!-- Contact Start -->
  <section class="section-service-alt">
    <div class="app-get-in-touch-form">
      <div class="container">
          <div class="contact-form">
              <div class="row reverse-colum">
                <div class="col-lg-5 col-md-12 contact-social-links">
                  <h3 class="main-sub-heading"><span>Launch Your SocialFi Web3 Startup Today!</span></h3>
                  <p>Connect with our SocialFi platform development experts today and disrupt the Web3 space with your world-changing ideas.</p>
                  <div class="contact-main-list">
                    <div class="social-links">
                      <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            alt="linkedin"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            alt="contact-Whatsapp"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            alt="twitter"
                            width="40"
                            height="40"
                          />
                        </picture>
                      </a>
                    </div>
                    <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                  </div>
                  <div class="contact-links">
                    <h4>Follow us on</h4>
                    <div class="social-links">
                      <a
                        href="https://www.linkedin.com/company/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="linkdin"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/linkdin.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/linkdin.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/linkdin.png"
                            alt="linkedin"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://www.instagram.com/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="instagram"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/instagram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/instagram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/instagram.png"
                            alt="instagram"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://twitter.com/Blockchainxtech"
                        target="_blank"
                rel="noopener noreferrer"
                        class="twitter"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/twitterx.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/twitterx.svg"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/twitterx.svg"
                            alt="twitter"
                            width="25"
                            height="25"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-md-12 contact-inputs">
                  <div class="getintouch getintochform bitrix-snippet-form">
                    <!-- <div #crmForm></div> -->
                    <div [innerHTML]="bitrixSnippetScript"></div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  <!-- Contact End -->
