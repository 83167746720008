import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { blogData } from './blog-data';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../shared/canonical.service';
import { FooterServiceService } from '../shared/service/footer-service.service';

@Component({
  selector: 'app-blog-main',
  templateUrl: './blog-main.component.html',
  styleUrls: ['./blog-main.component.css'],
})
export class BlogMainComponent implements OnInit {
  title = 'Blog | Business with Technology insights - BlockchainX';
  public blogDetails: Array<{ [key: string]: any }> = [];
  // public itemsToShow = 6;
  // public itemsToLoad = 3;
  blogcatgory=["All","BlockChain","NFT","Crypto","Metaverse","DEFI","Token","Crypto Token development","Ethereum Token Development","Asset Tokenization","Meme coin","BEP20"];
  blogmenulist:any;
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.blogmenulist= this.blogcatgory[0];
    this.blogDetails = blogData;
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Blockchain technology is a groundbreaking innovation that has resulted in numerous societal advantages, and this section contains all of the blogs on the topic.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: '',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Blog | Business with Technology insights - BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/blog/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Blockchain technology is a groundbreaking innovation that has resulted in numerous societal advantages, and this section contains all of the blogs on the topic.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/blockchainx-og.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/blog/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Blog | Business with Technology insights - BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Blockchain technology is a groundbreaking innovation that has resulted in numerous societal advantages, and this section contains all of the blogs on the topic.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/blockchainx-og.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL('https://www.blockchainx.tech/blog/');
    
  }

  customOptions: OwlOptions = {
    loop: true,
    margin: 30,
    autoplay: true,
    center: false,
    dots: true,
    autoWidth:false,
    autoHeight:false,
    // autoplayTimeout:6000,
    items: 1,
  };
  // showMore() {
  //   this.itemsToShow += this.itemsToLoad;
  // }
  
  blogCategory(menu:any){
    console.log('menu', menu)
    this.blogmenulist=menu;
  }
}
