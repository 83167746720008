import {
  Component,
  Inject,
  OnInit,
  Renderer2
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
} from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css']
})
export class GetInTouchComponent implements OnInit {

  bitrixSnippetScript: SafeScript;

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private doc: Document
  ) {
    const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      // Perform a null check before calling parentNode.insertBefore
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
        // You can append the script to the <body> element or take another appropriate action.
        document.body.appendChild(s);
      }
    })(
      window,
      document,
      'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    )};</script>`;
    this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(
      `${scriptUrl}`
    );
  }
  ngOnInit(): void {
    
  }

 

}
