import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-avoid-mistake-marketplace',
  templateUrl: './avoid-mistake-marketplace.component.html',
  styleUrls: ['./avoid-mistake-marketplace.component.css'],
})
export class AvoidMistakeMarketplaceComponent implements OnInit {
  title =
    'Mistakes you should not do but know about it in the NFT Marketplace | BlockchainX';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'If you have the desire to create an NFT marketplace, you will most likely need adequate study, preparation, and some initial investment to make your project more successful. Here we point out some mistakes you should avoid when building your NFT marketplace.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'top 5 mistakes to avoid built marketplace, building nft marketplace, create nft marketplace',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Mistakes you should not do but know about it in the NFT Marketplace | BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/6-mistakes-you-should-avoid-developing-nft-marketplace/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'If you have the desire to create an NFT marketplace, you will most likely need adequate study, preparation, and some initial investment to make your project more successful. Here we point out some mistakes you should avoid when building your NFT marketplace.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/major-mistakes-to-cuttoff-in-nft-marketplace.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/6-mistakes-you-should-avoid-developing-nft-marketplace/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Mistakes you should not do but know about it in the NFT Marketplace | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'If you have the desire to create an NFT marketplace, you will most likely need adequate study, preparation, and some initial investment to make your project more successful. Here we point out some mistakes you should avoid when building your NFT marketplace.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/major-mistakes-to-cuttoff-in-nft-marketplace.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/6-mistakes-you-should-avoid-developing-nft-marketplace/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/6-mistakes-you-should-avoid-developing-nft-marketplace.html'
    );
    this.doc.head.appendChild(link);
  }
}
