import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-ai-token-definition',
  templateUrl: './ai-token-definition.component.html',
  styleUrls: ['./ai-token-definition.component.css']
})
export class AiTokenDefinitionComponent implements OnInit {

  selectedLink:number;
  title =
    'AI Token - All That You Need To Know';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'AI tokens are new rewarding tokens in the crypto market that have a massive role in the regulation of a crypto project. Know its deep essence here in the blog. Tap to learn more!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'ai token, ai crypto token',
    });
              

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'AI Token - All That You Need To Know',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/ai-token-definition/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'AI tokens are new rewarding tokens in the crypto market that have a massive role in the regulation of a crypto project. Know its deep essence here in the blog. Tap to learn more!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/ai-token-definition/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'AI Token - All That You Need To Know',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'AI tokens are new rewarding tokens in the crypto market that have a massive role in the regulation of a crypto project. Know its deep essence here in the blog. Tap to learn more!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/ai-token-definition/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/ai-token-definition.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
