<!-- Metavers Section Starts -->
<div class="meta-detail">
  <div class="green-vector"></div>
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row services-listing">
      <div class="col-lg-12">
        <div class="nft-heading-section">
          <div class="nft-heading-section-alt">
            <div class="common-sub-heading">
              <h4>We Carve<span class="dots">.</span></h4>
            </div>
            <h3 class="main-sub-heading">
              Your World In Metaverse Development
            </h3>
          </div>
          <div class="nft-heading-section-alt">
            <div class="deploy-link">
              <a [routerLink]="['metaverse-development-company']">
                <span>Read More</span>
                <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="meta-description">
        <div class="row meta-description-space">
          <!-- <div class="col-lg-5 col-md-5 col-sm-12 col-12 mb-3 text-center meta-game order-1">
            <img
              src="{{
                hoveredImageOne
                  ? hoveredImageOne
                  : 'assets-new/images/home/meta/mataverse-main.png'
              }}"
              class="img-fluid"
              alt="Metaverse Development"
            />
            <div class="orange-vector">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/orange-gradient-1.webp" />
                <source type="image/png" srcset="assets-new/images/home/orange-gradient-1.png" />
                <img loading="lazy" data-src="assets-new/images/home/orange-gradient-1.png" width="100%" height="100%" class="img-fluid" alt="Metaverse-Gradient" title="Metaverse-Gradient" />
              </picture>
            </div>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 order-2">
            <h3 class="meta-heading">{{ currentlyhoveredItem.heading }}</h3>
            <p>
              {{ currentlyhoveredItem.content }}
            </p>
            <div class="row meta-picture-section">
              <div class="col-lg-4 col-md-4 col-sm-4 col-4 meta-image-list">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/meta/meta-1.webp" />
                  <source type="image/png" srcset="assets-new/images/home/meta/meta-1.png" />
                  <img loading="lazy" data-src="assets-new/images/home/meta/meta-1.png" width="100%" height="100%" class="img-fluid" alt="Metaverse Development" title="Metaverse Development"  (mouseenter)="onMouseEnter('assets-new/images/home/meta/meta-1.png', 1, 1)" (mouseleave)="onMouseLeave('assets-new/images/home/meta/mataverse-main.png',1)" />
                </picture>

                <div class="meta-hover text-center">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/metaverse/meta-hover.webp" />
                    <source type="image/png" srcset="assets-new/images/metaverse/meta-hover.png" />
                    <img loading="lazy" data-src="assets-new/images/metaverse/meta-hover.png" width="100%" height="100%" class="img-fluid" alt="metaverse" title="metaverse" />
                  </picture>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-4 meta-image-list">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/meta/meta-2.webp" />
                  <source type="image/png" srcset="assets-new/images/home/meta/meta-2.png" />
                  <img loading="lazy" data-src="assets-new/images/home/meta/meta-2.png" width="100%" height="100%" class="img-fluid" alt="Metaverse Development" title="Metaverse Development" (mouseenter)="onMouseEnter('assets-new/images/home/meta/meta-2.png', 1, 2)" (mouseleave)="onMouseLeave('assets-new/images/home/meta/mataverse-main.png',1)" />
                </picture>

                <div class="meta-hover text-center">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/metaverse/meta-hover.webp" />
                    <source type="image/png" srcset="assets-new/images/metaverse/meta-hover.png" />
                    <img loading="lazy" data-src="assets-new/images/metaverse/meta-hover.png" width="100%" height="100%" class="img-fluid" alt="metaverse" title="metaverse" />
                  </picture>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-4 meta-image-list">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/meta/meta-3.webp" />
                  <source type="image/png" srcset="assets-new/images/home/meta/meta-3.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-hover.png" width="100%" height="100%" class="img-fluid" alt="Metaverse Development" title="Metaverse Development" (mouseenter)="onMouseEnter('assets-new/images/home/meta/meta-3.png', 1, 3)" (mouseleave)="onMouseLeave('assets-new/images/home/meta/mataverse-main.png',1)" />
                </picture>
                <div class="meta-hover text-center">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/metaverse/meta-hover.webp" />
                    <source type="image/png" srcset="assets-new/images/metaverse/meta-hover.png" />
                    <img loading="lazy" data-src="assets-new/images/metaverse/meta-hover.png" width="100%" height="100%" class="img-fluid" alt="metaverse" title="metaverse" />
                  </picture>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-4 col-md-4 col-12">
            <div class="meta-alt-card">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/meta/gaming-entertain.webp" />
                <source type="image/png" srcset="assets-new/images/home/meta/gaming-entertain.png" />
                <img loading="lazy" data-src="assets-new/images/home/meta/gaming-entertain.png" width="403" height="576" class="img-fluid" alt="Metaverse Development" title="Metaverse Development" />
              </picture>
              <div class="meta-card">
                  <h4 class="common-sub-heading-alt">Gaming &
                    Entertainment</h4>
                    <p>
                      In a world of endless possibilities, our fine hand's code magic that extends and imparts more fun-filled showcases through an immersive environment. This time-effective and revenue-generating space are of high interest among users, there are wider openings to explore in the genre.
                    </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="meta-alt-card">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/meta/ar-vr-develop.webp" />
                <source type="image/png" srcset="assets-new/images/home/meta/ar-vr-develop.png" />
                <img loading="lazy" data-src="assets-new/images/home/meta/ar-vr-develop.png" width="403" height="576" class="img-fluid" alt="Metaverse Development" title="Metaverse Development" />
              </picture>
              <div class="meta-card">
                  <h4 class="common-sub-heading-alt">AR & VR
                    Development</h4>
                    <p>
                      Wanna dive into a world of unexplored possibilities, get your joysticks ready to dwell into such amazing experiences with our AR VR development services. Our creative side explores developing AR VR applications that take you into a different reality and empower your outcomes more effectively.
                    </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="meta-alt-card">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/meta/more-than-existence.webp" />
                <source type="image/png" srcset="assets-new/images/home/meta/more-than-existence.png" />
                <img loading="lazy" data-src="assets-new/images/home/meta/more-than-existence.png" width="403" height="576" class="img-fluid" alt="Metaverse Development" title="Metaverse Development" />
              </picture>
              <div class="meta-card">
                  <h4 class="common-sub-heading-alt">More
                    Than Existence</h4>
                    <p>
                      Buckle up and witness your ideas and business transforming in the digitally equipped platform with us. We crave futuristic and technologically aided solutions that are far from the present, indeed that shall be the sprout transforming big in the future. Let's together ideate and curate the new age!
                    </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Metaverse Section Ends -->
