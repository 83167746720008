import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-solana-blockchain-development',
  templateUrl: './solana-blockchain-development.component.html',
  styleUrls: ['./solana-blockchain-development.component.css']
})
export class SolanaBlockchainDevelopmentComponent implements OnInit {

  bitrixSnippetScript: SafeScript;

  title = 'Solana Blockchain Development Solutions';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document,
    private sanitizer: DomSanitizer,
  ) {
    const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      // Perform a null check before calling parentNode.insertBefore
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
        // You can append the script to the <body> element or take another appropriate action.
        document.body.appendChild(s);
      }
    })(
      window,
      document,
      'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    )};</script>`;
    this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(
      `${scriptUrl}`
    );
  }
  
  
  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'With its extended features, the Solana network contributes towards safety, security and speed. And if that is something you are looking for in your ecosystem, tap to learn more on Solana Blockchain Development solutions.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Solana Blockchain Development Company, Solana Blockchain Development services, Solana Blockchain Development, Solana Blockchain Development solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Solana Blockchain Development Solutions',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://blockchainx.tech/solana-blockchain-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'With its extended features, the Solana network contributes towards safety, security and speed. And if that is something you are looking for in your ecosystem, tap to learn more on Solana Blockchain Development solutions.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/solana-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://blockchainx.tech/solana-blockchain-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Solana Blockchain Development Solutions',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'With its extended features, the Solana network contributes towards safety, security and speed. And if that is something you are looking for in your ecosystem, tap to learn more on Solana Blockchain Development solutions.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/solana-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://blockchainx.tech/solana-blockchain-development/'
    );
  }
}
