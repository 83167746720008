<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Politifi </strong></span> Token Development
              </h1>
              <p>
                Fuel your crypto game with Politifi tokenization entrusting on
                the Political significances and tapping to new avenues for these
                engagements in the web3 space.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/launch-your-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-images/launch-your-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-images/launch-your-token.png"
                width="500" height="500" class="img-fluid"
                alt="GameFi Development" title="GameFi Development" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Defining PolitiFi </span> Token
        Development</h2>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Defining PolitiFi Token Development"
              title="Defining PolitiFi Token Development"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            PolitiFi Token is a new avenue for enthusiasts involved in
            tokenizing political campaigns effortlessly with the advent of
            Blockchain technology. The PolitiFi token functions similarly in
            cases of fundraising, campaign promotions and others effectively. In
            a transparent, secured, and safe manner, political funding and
            management is done effectively with PolitiFi Tokens. And bringing up
            an innovative financial fundraising approach to the table enabling
            the frontline of the world of politics. Therefore making political
            fundraising more accessible to a wider range of candidates.
          </p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Popular PolitiFi Tokens"
              title="Popular PolitiFi Tokens" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular </span> PolitiFi
            Tokens</h3>
          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> $TURDEAU
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> $ZELENSKIY
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> $TRUMP
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> $BODEN
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Donald Trump
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Trumpcoin
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Highlighting Features </span>Of PolitiFi Token</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Politically strengthening"
                title="Politically strengthening" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Politically strengthening</h4>
          <p>
            There are mutifold opportunity to highlight in gaining political
            strength with PolitiFi Tokens are they are visible, evident, safe,
            secured.
          </p>
          <!-- <div class="content-link">
                <a [routerLink]="['/blockchain-consulting-services']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow"
                       />
                  </picture>
                </a>
                <hr />
              </div> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Community Building"
                title="Community Building" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Community Building
          </h4>
          <p>
            Like other crypto projects having communities, don't you think your
            campaigns and political presence require the support of community?
            Here you get it!
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Token Information"
                title="Token Information" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Information</h4>
          <p>
            The tokens have lifetime opportunity to create a bull run with
            colossal political approach and the informations are securely stored
            in the digital space.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Impactful Funding"
                title="Impactful Funding" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Impactful Funding</h4>
          <p>
            With PolitiFi tokens the campaigns are able to scrutinize and
            receive impactful funds directly received and accounted for with no
            hassle in the blockchain network.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Choose BlockchainX </span>To Develop Your PoliticFi Token
          </h2>
          <p class="pb-3">
            To develop your PoliticFI token, reach us out at BlockchainX a
            leading blockchain development company, has hands-on experience with
            over 7 seven years in the sector. We provide customized services for
            your tokens from choice of color theme to actually developing your
            token we cater the best. With 7 years of experience and learning our
            developers and tech team are very much supportive, open to learning
            and progress.
          </p>
          <div class="whysection_text_wrap">
            <ul>
              <li>50plus successful live project</li>
              <li>Efficient experienced resources</li>
              <li>On-time delivery and support</li>
              <li>Open to learn and implement</li>
            </ul>
          </div>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png"
                  class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="GameFi Development Company"
              title="ameFi Development Company" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is a PolitiFi Token?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    A political token exclusively created to meet funding part
                    of the political campaign, get popularity and effectively
                    succeed the campaign.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What is the cost of developing a Token?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The ideal cost would vary based on the requirements and
                    advances you want to explore. The cost seems to alter based
                    on quality, blockchain choice, internal features and much
                    more.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Whom to choose to develop a PolitifFi Token?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Blockchain with experience and hand off training we provide
                    effective services with on time deliveries and in the
                    customization you ask for.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
                src="assets-new/images/right-arrow.png"
                alt="link-arrow"
                class="img-fluid"
                width="50"
                height="15" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/what-is-depin']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/depin/depin-extravaganza.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/depin/depin-extravaganza.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/depin/depin-extravaganza.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="DePIN Extravaganza"
                    title="DePIN Extravaganza" />
                </picture>
                <h3>
                  DePIN - What Is Decentralized Physical Infrastructure Network?
                </h3>
                <p>
                  DePIN, or the Decentralized Physical Infrastructure Network,
                  is a decentralized protocol using tokens to incentivize...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/what-is-depin']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/ai-token-definition']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/all-about-ai-token/all-about-ai-token.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/all-about-ai-token/all-about-ai-token.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="AI Token"
                    title="AI Token" />
                </picture>
                <h3>
                  AI Token - The Whats Of It!
                </h3>
                <p>
                  AI tokens are the new revolution in the crypto market.
                  Artificial intelligence is leveraging every sector, and
                  eventually...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/ai-token-definition']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/real-estate-tokenization-guide']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Real Estate Tokenization"
                    title="Real Estate Tokenization Enabling Endless Investment Opportunites" />
                </picture>
                <h3>
                  How does Real Estate Tokenization Revolutionize Investments?
                </h3>
                <p>
                  Over the years, investment scales have been tremendously
                  improving, and new avenues are blooming now and then with
                  blockchain...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/real-estate-tokenization-guide']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/top-real-world-asset-tokenization-companies']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/top-real-world-asset-tokenization-companies/banner.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Top 10 Real World Asset Tokenization Companies 2024"
                    title="Top 10 Real World Asset Tokenization Companies 2024" />
                </picture>
                <h3>
                  Top 10 Real World Asset Tokenization Companies 2024
                </h3>
                <p>
                  The very modern monetizing process, real-world asset
                  tokenization, involves curating digital presence and value for
                  real-world assets...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/top-real-world-asset-tokenization-companies']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/erc20-token-development-guide']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                        assets-new/images/blog/erc20-token-development-guide/erc20-token-development.webp
                        " />
                  <source
                    type="image/jpg"
                    srcset="
                        assets-new/images/blog/erc20-token-development-guide/erc20-token-development.jpg
                        " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/erc20-token-development-guide/erc20-token-development.jpg"
                    width="404"
                    height="245"
                    class="img-fluid"
                    alt="ERC20 Token development With BlockchainX"
                    title="ERC20 Token development With BlockchainX" />
                </picture>
                <h3>
                  Complete Guide For ERC20 Token Development
                </h3>
                <p>
                  In 2015, Fabian Vogelstellar created the ERC20 token standard
                  which was a quick pick and gained instant popularity...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/erc20-token-development-guide']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Let the world know about
                Political Campaign Success is</span></h3>
            <p>With our developers and For more such content connect today!</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->
