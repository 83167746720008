import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-smart-contract-development',
  templateUrl: './smart-contract-development.component.html',
  styleUrls: ['./smart-contract-development.component.css'],
})
export class SmartContractDevelopmentComponent implements OnInit {
  title =
    'Blockchain Smart Contract Development Company';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

   isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'With utmost endurance and efficiency, we develop blockchain Smart contracts that back your processes, executions, and operations more effectively. Here is a detailed run-through on our smart contract development service, take a look at it.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'blockchain smart contracts development, blockchain smart contract development services, blockchain smart contract developer, smart contract development services, smart contracts development company, smart contract development, smart contract developer',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Blockchain Smart Contract Development Company',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://blockchainx.tech/smart-contract-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'With utmost endurance and efficiency, we develop blockchain Smart contracts that back your processes, executions, and operations more effectively. Here is a detailed run-through on our smart contract development service, take a look at it.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/smart-contract-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://blockchainx.tech/smart-contract-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Blockchain Smart Contract Development Company',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'With utmost endurance and efficiency, we develop blockchain Smart contracts that back your processes, executions, and operations more effectively. Here is a detailed run-through on our smart contract development service, take a look at it.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/smart-contract-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://blockchainx.tech/smart-contract-development/'
    );
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  } 
}
