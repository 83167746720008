<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Blockchain Smart </strong>Contract Development</span>
              </h1>
              <h3 class="metaverse_subheading">Unlock the Potential of Transparency</h3>

              <p>
                Custom crafted unique blockchain smart contracts development backing your operation through decentralization, enabling security, safety, and more
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/Smart-Contract-Development-Services.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/Smart-Contract-Development-Services.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/Smart-Contract-Development-Services.png"
                width="350"
                height="350"
                class="img-fluid"
                alt="smart-Contract-Development-services"
                title="smart-Contract-Development-services" />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!-- Why Choose DAO Platform Development start -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row reverse dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/smart-contract/Smart-Contracts-are-the-Future.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/smart-contract/Smart-Contracts-are-the-Future.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/Smart-Contracts-are-the-Future.png"
              width="400"
              height="400"
              class="img-fluid"
              alt="smart-Contracts-are-the-future"
              title="smart-Contracts-are-the-future" />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading text-start">
            Blockchain-Based Smart Contract Development
          </h3>
          <p>
            With the ability to self-execute, these smart contracts act as blockchain programs that validate, secure, and protect transactions from non-lenient behaviors.  Smart contracts being a predefined set of actions that executed on meeting the requirement criteria, the involvement of third parties is completely removed. Ensuring the right actions are proceeded for execution, our blockchain smart contract development service takes you to the top of excellence, and assurance. Smart contract development is a base for transactions, commonly known as the blockchain protocol that can be developed based on your operation flow and request, and blockchain-based validation norms can be enforced. Our customized services back your activities on the blockchain, securely access and strive through the entire process.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose DAO Platform Development end -->

<!-- Our Smart Contract Development Services -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Various<span> Smart Contract Development services </span> At BlockchainX</h3>
      </div>
    </div>
    <div class="row erc-about-content justify-content-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/smart-contract/smart-contract-development.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/smart-contract/smart-contract-development.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/smart-contract-development.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Smart Contract Development"
              title="Smart Contract Development" />
          </picture>
          <h4 class="erc-20-head">Smart Contract Development</h4>
          <p class="text-center">
            We assure you to create customized contracts for your company to automate your agreements. To meet your specific needs, we use several private, hybrid & public blockchains.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/smart-contract/smart-contract-audit.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/smart-contract/smart-contract-audit.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/smart-contract-audit.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Smart Contract Audit"
              title="Smart Contract Audit" />
          </picture>
          <h4 class="erc-20-head">Smart Contract Audit</h4>
          <p class="text-center">
            We provide meticulous smart contract audit services to assist businesses in rectifying smart contract codes & to perform security audits from the ground up.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/smart-contract/smart-contract-optimization.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/smart-contract/smart-contract-optimization.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/smart-contract-optimization.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Smart Contracts Optimization"
              title="Smart Contracts Optimization" />
          </picture>
          <h4 class="erc-20-head">Smart Contracts Optimization</h4>
          <p class="text-center">
            Smart contract optimization can help you save money on gas. It will also improve token performance and secure the management of private keys.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/smart-contract/smart-contract-architecture.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/smart-contract/smart-contract-architecture.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/smart-contract-architecture.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Smart Contract Architecture"
              title="Smart Contract Architecture" />
          </picture>
          <h4 class="erc-20-head">Smart Contract Architecture</h4>
          <p class="text-center">
            Our expert team ensures a smooth workflow & adheres to the most acceptable smart contract architecture practices by ensuring that there are no programming bugs.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/smart-contract/smart-contract-consultation.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/smart-contract/smart-contract-consultation.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/smart-contract-consultation.png"
              width="100"
              height="100"
              class="img-fluid"
              alt="Smart Contract Consultation"
              title="Smart Contract Consultation" />
          </picture>
          <h4 class="erc-20-head">Smart Contract Consultation</h4>
          <p class="text-center">
            We also extend our blockchain development service with consultation, helping clients and web3 enthusiasts to navigate in the right stream.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Our Smart Contract Development Services -->

<!-- Difference b/w Web2 and Web3 starts -->
<section class="section-service-alt">
  <div class="types-of-erc text-center">
      <div class="container">
          <div class="build-web3-solution">
              <div class="row justify-content-center mb-3">
                  <div class="col-lg-10">
                      <h3 class="main-sub-heading text-center">Looking for 
                          <span>Smart contract developers?</span>
                      </h3>
                  </div>
              </div>
              <p>
                You can avail our smart contract developers on project bases, and get your task done with accuracy and standard. We smart contract developers ensure to provide high quality error free codes for your projects that can  execute well.
              </p>
              <div class="discuss-link">
                  <a href="/contact"><span>Connect with us to avail our Smart contract developers</span>
                      <img loading="lazy"
                          src="assets-new/images/right-arrow.png"
                            alt="link-arrow" class="img-fluid"
                            width="50" height="15" *ngIf="!isDarkmode" />
                      <img loading="lazy"
                          src="assets-new/images/right-arrow-dark.png"
                            alt="link-arrow" class="img-fluid"
                            width="50" height="15" *ngIf="isDarkmode" />
                  </a>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- Ends -->

<!-- Our Blockchain Smart Contracts Focused Industries -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center pb-4">
          <h3 class="mb-2">
            Varied Industries Using <span> The Power Smart Contract</span> Use Case Development
          </h3>
          <p>
            This is a prolonged list of sectors as blockchain-based adaptation backed by robust blockchain smart contracts, adoptions in other sectors get rigorous and progressive. Blockchain Smart contracts development has the power to expand its robust capabilities to help in executing the process and transactions seamlessly.
          </p>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/banking.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/banking.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/banking.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Banking & Finances"
                title="Banking & Finances" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Banking & Finances</h4>
          <p>
            Through smart contracts the payments can be automated, including loan management, insurance claims, etc, without third party interventions.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/gaming.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/gaming.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/gaming.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="gaming"
                title="gaming" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Gaming spaces</h4>
          <p>
            Here smart contracts are predefined to state the clarity of winning, while target, previous achievement, asset placements, etc can be included to make things simpler in flow.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/supply-chain-logistics.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/supply-chain-logistics.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/supply-chain-logistics.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Supply Chain Logistics"
                title="Supply Chain Logistics" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Supply Chain Logistics</h4>
          <p>
            Smart contracts with the ability to be precisely executed, on laying certain conditions the location and progress can be clearly tracked.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/media-and-entertainment.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/media-and-entertainment.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/media-and-entertainment.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Media and Entertainment"
                title="Media and Entertainment" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Media and Entertainment</h4>
          <p>
            Smart contracts in media and entertainment, provide authenticity and back content ownership, ensuring the royalties, claims and remunerations are reaching the creator. 
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/identity-management.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/identity-management.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/identity-management.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Identity Management"
                title="Identity Management" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Identity Management</h4>
          <p>
            Through smart contract it makes identity management more precise, secured and accountable with its automated pre-defined set of rules followed as protocols.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/tokenization.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/tokenization.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Tokenization"
                title="Tokenization" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Tokenization</h4>
          <p>
            Smart contract development in tokenization defines the actions and executables of the token, distinguishes from different tokens and their use case.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/agriculture.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/agriculture.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/agriculture.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Agriculture"
                title="Agriculture" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Agriculture</h4>
          <p>
            From crop productions, to yielding and distribution, through embedded smart contract technology, it's easy to understand and analyze the price surge, brand identity, user purchase interest and more.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/real-estate-investment.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/real-estate-investment.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/real-estate-investment.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Real estate investment"
                title="Real estate investment" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Real estate investment</h4>
          <p>
            Using smart contracts in real estate investments, the authority, is easily viewable, the abilities and the utility of the asset is also provided, while smart contract define the potential of users too.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Blockchain Smart Contracts Focused Industries -->

<!-- Lifecycle of a Tokenized Security starts -->
<section class="section-service-alt lifecycle-sec">
  <div class="container">
    <div class="row mb-sm-5 pb-4">
      <div class="metaverse_heading mx-auto">
        <h3>Working Of <span>Smart Contract </span>Development Process
        </h3>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <div class="token-wrap first-four">
        <div class="token-wrap-inner">

          <div
            class="column token-column one-second  token-upper-iconbox first">
            <div class="token-column-inner-one">
              <div class="icon-wrapper">
                <img loading="lazy"
                src="assets-new/images/real-world-asset-tokenization/requirement-gathering.svg"
                class="img-fluid" alt="Requirement Gathering" width="44"
                height="44" *ngIf="!isDarkmode">
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/requirement-gathering-lite.svg"
                  class="img-fluid" alt="Requirement Gathering" width="44"
                  height="44" *ngIf="isDarkmode">
              </div>
              <h4>Requirement Gathering</h4>
              <p>
                For your blockchain smart contract development solution, we start off by gathering your requirements and understanding your needs. 
              </p>
            </div>
          </div>
          <!-- End -->
          <div
            class="column token-column one-second  token-upper-iconbox second">
            <div class="token-column-inner-two">
              <div class="icon-wrapper">
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/design-and-planning.svg"
                  class="img-fluid" alt="Design and Planning" width="44" height="44" *ngIf="!isDarkmode" >
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/design-and-planning-lite.svg"
                  class="img-fluid" alt="Design and Planning" width="44" height="44" *ngIf="isDarkmode" >
              </div>
              <h4>Design and Planning</h4>
              <p>
                We then proceed with designing the plan of action and the architecture of your smart contracts
              </p>
            </div>
          </div>
          <!-- End -->
          <div class="column token-column one-second token-lower-iconbox first">
            <div class="token-column-inner-three ">
              <div class="icon-wrapper">
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/coding-on-testnet.svg"
                  class="imd-fluid" alt="Coding on Testnet" width="44"
                  height="44" *ngIf="!isDarkmode" >
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/coding-on-testnet-lite.svg"
                  class="imd-fluid" alt="Coding on Testnet" width="44"
                  height="44" *ngIf="isDarkmode" >
              </div>
              <h4>Coding on Testnet</h4>
              <p>
                Code the smart contract error-free and ensure it slides easily in the right flow on the test net.
              </p>
            </div>
          </div>
          <!-- End -->
          <div
            class="column token-column one-second token-lower-iconbox second">
            <div class="token-column-inner-four ">
              <div class="icon-wrapper">
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/testing-on-testnet.svg"
                  class="img-fluid" alt="Testing on Testnet" width="44"
                  height="44" *ngIf="!isDarkmode" >
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/testing-on-testnet-lite.svg"
                  class="img-fluid" alt="Testing on Testnet" width="44"
                  height="44" *ngIf="isDarkmode" >
              </div>
              <h4>Testing on Testnet</h4>
              <p>
                We then take the smart contract solution tested on the testnet, until it is passed out error-free.
              </p>
            </div>
          </div>
          <!-- End -->
        </div>
      </div>
      <!-- End -->

      <div class="token-wrap last-fifth">
        <div class="token-wrap-inner">
          <div class="column token-column one middle-iconbox">
            <div class="token-column-inner-five">
              <div class="icon-wrapper">
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/deployment-on-mainnet.svg"
                  class="img-fluid" alt="Deployment on Mainnet" width="44" height="44" *ngIf="!isDarkmode" >
                <img loading="lazy"
                  src="assets-new/images/real-world-asset-tokenization/deployment-on-mainnet-lite.svg"
                  class="img-fluid" alt="Deployment on Mainnet" width="44" height="44" *ngIf="isDarkmode" >
              </div>
              <h4>Deployment on Mainnet</h4>
              <p>
                After that, we get the approval and deploy the smart contract on the mainnet integrated with your operation for use. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End -->

    </div>

  </div>
</section>
<!-- Lifecycle of a Tokenized Security ends -->

<!-- Trending ERC20 Token List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/smart-contract/stable-coin-development-solution.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/smart-contract/stable-coin-development-solution.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/smart-contract/stable-coin-development-solution.png"
              width="405" height="405" alt="Stablecoin Development Solution"
              title="Stablecoin Development Solution" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Blockchain Customization For </span> Smart Contract Development</h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Ethereum
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Tron Blockchain
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Hyperledger
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Polkadot Network
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> EOS
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> Binance Network
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Business need Smart Contract -->
<div class="section-service-alt">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>
              Benefits Of <span>Smart Contract Development</span> Services For Business
            </h3>
          </div>
        </div>
      </div>
      <div class="row erc-processing justify-content-center">
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process cmb-5 process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/trust-and-transparency.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/trust-and-transparency.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/trust-and-transparency.png"
                class="img-fluid pb-2"
                alt="Trust and transparency"
                title="Trust and transparency"
                width="100"
                height="100"
                />
            </picture>
            <h4>Trust and transparency</h4>
            <p>
              The records in smart contracts are visible to all parties, which provides trust & transparency.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/efficiency.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/efficiency.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/efficiency.png"
                class="img-fluid pb-2"
                alt="Efficiency"
                title="Efficiency"
                width="100"
                height="100"
                 />
            </picture>
            <h4>Efficiency</h4>
            <p>
              Smart contracts standardize & automate recurring procedures, therefore reducing manual errors.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process cmb-5 process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/security.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/security.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/security.png"
                class="img-fluid pb-2"
                alt="Security"
                title="Security"
                width="100"
                height="100"
                 />
            </picture>
            <h4>Security</h4>
            <p>
              Blockchain-based encryption provides high-end security for sensitive data through their secured methodical operation.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/accuracy.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/accuracy.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/accuracy.png"
                class="img-fluid pb-2"
                alt="Accuracy"
                title="Accuracy"
                width="100"
                height="100" />
            </picture>
            <h4>Accuracy</h4>
            <p>
              Smart contracts record & execute agreements precisely and they remove data issues like inadequacy & so on.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/final-savings.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/final-savings.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/final-savings.png"
                class="img-fluid pb-2"
                alt="Financial Saving"
                title="Financial Saving"
                width="100"
                height="100"
                />
            </picture>
            <h4>Financial Saving</h4>
            <p>
              Human functions are rendered obsolete by automated operations, reducing risks and costs.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/disintermediation.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/disintermediation.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/disintermediation.png"
                class="img-fluid pb-2"
                alt="Disintermediation"
                title="Disintermediation"
                width="100"
                height="100"
                />
            </picture>
            <h4>Disintermediation</h4>
            <p>
              Smart contracts allow parties to enter into agreements without the involvement of a third party.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract/real-time-execution.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/smart-contract/real-time-execution.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract/real-time-execution.png"
                class="img-fluid pb-2"
                alt="Real-Time Execution"
                title="Real-Time Execution"
                width="100"
                height="100"
                />
            </picture>
            <h4>Real-Time Execution</h4>
            <p>
              Once the necessary criteria are met, smart contracts take place & are executed concurrently across participating computers.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Business need Smart Contract -->

<!-- Difference b/w Web2 and Web3 starts -->
<section class="section-service-main">
  <div class="types-of-erc text-center">
      <div class="container">
          <div class="build-web3-solution">
              <div class="row justify-content-center mb-3">
                  <div class="col-lg-10">
                      <h3 class="main-sub-heading text-center">
                          <span>Code your seamlessly executing Blockchain Smart Contracts with us!</span>
                      </h3>
                  </div>
              </div>
              <p>
                Smartly securely your operations and transactions with Smart contracts development. 
              </p>
              <div class="discuss-link">
                  <a href="/contact"><span>Build Your Project</span>
                      <img loading="lazy"
                          src="assets-new/images/right-arrow.png"
                            alt="link-arrow" class="img-fluid"
                            width="50" height="15" *ngIf="!isDarkmode" />
                      <img loading="lazy"
                          src="assets-new/images/right-arrow-dark.png"
                            alt="link-arrow" class="img-fluid"
                            width="50" height="15" *ngIf="isDarkmode" />
                  </a>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- Ends -->

<!-- Development Platform Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading">
          <h3>
            We employ the <span>following Tech Stacks in Blockchain</span> Smart Contracts Development
          </h3>
        </div>
      </div>
    </div>
    <div class="row row-cols-3 row-cols-sm-4 row-cols-md-6 row-cols-lg-7 justify-content-center align-items-center tron-token-images">
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/ethereum.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/ethereum.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/ethereum.svg"

              class="img-fluid"
              alt="Ethereum"
              title="Ethereum"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/ethereum-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/ethereum-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/ethereum-lite.svg"

              class="img-fluid"
              alt="Ethereum"
              title="Ethereum"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/polygon.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/polygon.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/polygon.svg"

              class="img-fluid"
              alt="Polygon"
              title="Polygon"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/polygon-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/polygon-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/polygon-lite.svg"

              class="img-fluid"
              alt="Polygon"
              title="Polygon"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/binance.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/binance.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/binance.svg"

              class="img-fluid"
              alt="Binance"
              title="Binance"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/binance-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/binance-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/binance-lite.svg"

              class="img-fluid"
              alt="Binance"
              title="Binance"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/hyperledger.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/hyperledger.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/hyperledger.svg"

              class="img-fluid"
              alt="Hyperledger"
              title="Hyperledger"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/hyperledger-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/hyperledger-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/hyperledger-lite.svg"

              class="img-fluid"
              alt="Hyperledger"
              title="Hyperledger"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/corda.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/corda.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/corda.svg"

              class="img-fluid"
              alt="Corda"
              title="Corda"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/corda-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/corda-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/corda-lite.svg"

              class="img-fluid"
              alt="Corda"
              title="Corda"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/substrate.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/substrate.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/substrate.svg"

              class="img-fluid"
              alt="Substrate"
              title="Substrate"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/substrate-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/substrate-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/substrate-lite.svg"

              class="img-fluid"
              alt="Substrate"
              title="Substrate"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/avalanche.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/avalanche.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/avalanche.svg"

              class="img-fluid"
              alt="Avalanche"
              title="Avalanche"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/avalanche-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/avalanche-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/avalanche-lite.svg"

              class="img-fluid"
              alt="Avalanche"
              title="Avalanche"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/polkadot.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/polkadot.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/polkadot.svg"

              class="img-fluid"
              alt="Polkadot"
              title="Polkadot"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/polkadat-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/polkadat-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/polkadat-lite.svg"

              class="img-fluid"
              alt="Polkadot"
              title="Polkadot"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/near.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/near.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/near.svg"

              class="img-fluid"
              alt="NearProtocol"
              title="NearProtocol"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/near-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/near-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/near-lite.svg"

              class="img-fluid"
              alt="NearProtocol"
              title="NearProtocol"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/hedera.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/hedera.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/hedera.svg"

              class="img-fluid"
              alt="Hedera"
              title="Hedera"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/hedera-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/hedera-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/hedera-lite.svg"

              class="img-fluid"
              alt="Hedera"
              title="Hedera"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/tezos.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/tezos.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/tezos.svg"

              class="img-fluid"
              alt="Tezos"
              title="Tezos"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/tezos-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/tezos-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/tezos-lite.svg"

              class="img-fluid"
              alt="Tezos"
              title="Tezos"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/eos.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/eos.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/eos.svg"

              class="img-fluid"
              alt="eos"
              title="eos"
              width="113"
              height="70"
              *ngIf="!isDarkmode"
            />
          </picture>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/light-theme/eos-lite.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/light-theme/eos-lite.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/light-theme/eos-lite.svg"

              class="img-fluid"
              alt="eos"
              title="eos"
              width="113"
              height="70"
              *ngIf="isDarkmode"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Why Choose Blockchainx -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 nft-service-list">
        <div class="casetudies_common">
          <h3 class="main-sub-heading">
            BlockchainX - Your Smart Contract Development Company
          </h3>
          <p>
            BlcockahinX with years of experience has built a dedicated team of well-nurtured blockchain developers, with hands-on expertise - well-versed Smart contract developers who have worked on different successful projects and are curious about tech and advancement. Together we are building efficient solutions for the world exploring web3 and blockchain, of course, robust first-hand smart contracts development services. 
          </p>
        </div>
      </div>
      <div class="col-lg-7 case-list">
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Technical Prowess</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  We work with blockchain technologies and we are specialized in one and excel at it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Expert Team</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  Our experts assist you with refining, suggesting the best approach, setting up communities, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Rapid Development</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  We specifically tailor products to our audience & our well-planned roadmap ensures smooth & rapid development.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Complete Support</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  Our services extend after launch, we provide post-delivery service focusing on your growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Meaningful Outcomes</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  We make a visible difference and we make it certain that you see value in your investment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose Blockchainx -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            Frequently Asked Question <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is smart contract development?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    These digital contracts operate on blockchain and are validated through a decentralized distributed ledger system, automatically. Smart contracts serve as a digital protocol and excuse action under certain conditions.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Whom to reach for smart contract consulting?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    To develop your smart contracts, you must always reach the right team, which serves smart contract development, audits, and provides report analysis. At BlockchainX we provide the complete service altogether.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  What norms are there when choosing a smart contract developer?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    It's important to understand your smart contract developer has hands-on experience, is trustworthy, and has good knowledge and understanding in working with smart contracts. At BlockchainX we also have well-experienced developers keen to learn new insights.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  What is a smart contract audit?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Smart contract auditing is a full-fledged scrutinization process that can be done either manually or automated, ensuring the codes are error-free and right, which ensures the flow of the action.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<section class="section-service-main">
  <app-blog></app-blog>
</section>

<section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section>
