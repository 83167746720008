import { Component, Inject, OnInit, ViewChild, Renderer2, ElementRef, AfterViewInit, RendererFactory2  } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta, DomSanitizer, SafeScript } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../shared/canonical.service';
import { FooterServiceService } from '../shared/service/footer-service.service';

@Component({
  selector: 'app-home-v2',
  templateUrl: './home-v2.component.html',
  styleUrls: ['./home-v2.component.css']
})
export class HomeV2Component implements OnInit, AfterViewInit {

  private renderer: Renderer2;

  title = 'Blockchain Development Company | BlockchainX';
  menulist = [
    'Real Estate',
    'Travel',
    'Sports',
    'Social Networking',
    'Fintech',
    'Media & Entertainment',
    'Logistics & Distribution',
    'Healthcare Solutions',
  ];
  selectedlisted: any;
  isDarkmode: boolean = false;

  //@ViewChild('crmForm', { static: false }) div1!: ElementRef;

  bitrixSnippetScript: SafeScript;

  constructor(
    private viewportScroller: ViewportScroller,
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    //private renderer: Renderer2,
    rendererFactory: RendererFactory2,
    private sanitizer: DomSanitizer,
    private canonicalService: CanonicalService,
    private footerservice: FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    // const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
    //   w,
    //   d,
    //   u
    // ) {
    //   var s = d.createElement('script');
    //   s.async = true;
    //   s.src = u + '?' + ((Date.now() / 180000) | 0);

    //   var h = d.getElementsByTagName('script')[0];
    //   if (h && h.parentNode) {
    //     h.parentNode.insertBefore(s, h);
    //   } else {
    //     document.body.appendChild(s);
    //   }
    // })(
    //   window,
    //   document,
    //   'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    // )};</script>`;
    // this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(`${scriptUrl}`);
  }

  blogPost: OwlOptions = {
    loop: true,
    margin: 30,
    autoplay: true,
    center: false,
    dots: true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1600: {
        items: 3,
        margin: 40,
      },
    },
  };

  achievementsOptions: OwlOptions = {
    loop: false,
    margin:50,
    autoplay:true,
    center: false,
    dots: false,
    items:5,
    responsive: {
      0: {
        margin:20,
        items:2,
      },
      480: {
        margin:20,
        items:3,
      },
      767: {
        margin:20,
        items:5,
      },
      1199: {
        margin:30,
        items:5,
      },
      1350: {
        margin:34,
        items:5,
      }
    },
  };

  ngOnInit(): void {
    this.addPreloadLink();

    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      this.isDarkmode = response;
    });
    this.selectedlisted = this.menulist[0];
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content: 'As a Blockchain development company, we provide decentralized, web3 solutions curated by expert developers for start-ups, businesses, and entrepreneurs. BlockchainX emphasizes on creativity and security, whose solutions meet global market expectations.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'blockchain development, blockchain development company, blockchain development services, blockchain app development company, blockchain app development services, blockchain software development, blockchain software development company, blockchain application development company, blockchain development solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    this.meta.updateTag({
      property: 'og:title',
      content: 'Blockchain Development Company | BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content: 'As a Blockchain development company, we provide decentralized, web3 solutions curated by expert developers for start-ups, businesses, and entrepreneurs. BlockchainX emphasizes on creativity and security, whose solutions meet global market expectations.',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/blockchainx.jpg',
    });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Blockchain Development Company | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: 'As a Blockchain development company, we provide decentralized, web3 solutions curated by expert developers for start-ups, businesses, and entrepreneurs. BlockchainX emphasizes on creativity and security, whose solutions meet global market expectations.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/blockchainx.jpg',
    });

    this.canonicalService.setCanonicalURL('https://www.blockchainx.tech/');


    setTimeout(() => {
      this.loadBitrixScript();
    }, 5000);
  }

  addPreloadLink(): void {
    const link: HTMLLinkElement = this.renderer.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = 'assets-new/images/home/eclipse-circle-2.webp';
    link.type = 'image/webp';
    link.setAttribute('fetchpriority', 'high');
    this.renderer.appendChild(document.head, link);
  }


  private loadBitrixScript(): void {
    const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);

      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        document.body.appendChild(s);
      }
    })(
      window,
      document,
      'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    )};</script>`;
    this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(`${scriptUrl}`);
  }

  openmenu(menulist: any) {
    this.selectedlisted = menulist;
  }

  show = true;
  scroll() {
    this.viewportScroller.scrollToAnchor('travel');
    console.log('test top');
  }

  blogs_details: OwlOptions = {
    loop: false,
    autoplay: false,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

  clientSuccess: OwlOptions = {
    loop: false,
    margin: 34,
    autoplay: true,
    center: false,
    dots: true,
    autoplaySpeed: 4000,
    nav: false,
    items: 1
  };

  ngAfterViewInit(): void {
    // if (this.div1 && this.div1.nativeElement) {
    //   const script = this.renderer.createElement('script');
    //   script.src = `https://formcrm.sparkouttech.com/admin/web-forms/forms/qx35MKWYM6u1FbU2R2OIdlEertt6C8Bj8Gg6RKGnexFFGRg2ur/form.js`;
    //   this.renderer.appendChild(this.div1.nativeElement, script);
    // } else {
    //   console.error('div1 is undefined');
    // }
  }
}
