<section class="banners-section" #about>
  
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Rebase Token</strong></span>Development Services
              </h1>
              <p>
                Stabilize your token value and worth with Rebase Token
                development services, customized to meet your unique
                requirements and scale your projects globally.
              </p>

              <div class="contact-link">
                <a href="https://www.blockchainx.tech/contact/" class="home-link">Talk To Our
                  Experts</a>
                <a href="https://www.blockchainx.tech/case-studies/" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/rebase-token/rebase-token.webp" />
              <img loading="lazy"  data-src="assets-new/images/rebase-token/rebase-token.png" width="500" height="500" class="img-fluid"  alt="Rebase Token Developement Services" title="Rebase Token Developement Services" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/rebase-token/light-theme/rebase-token.webp" />
              <img loading="lazy"  data-src="assets-new/images/rebase-token/light-theme/rebase-token.png" width="500" height="500" class="img-fluid"  alt="Rebase Token Developement Services" title="Rebase Token Developement Services" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="Scroll"
              title="Scroll" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="whys_section mb-0 text-center">
      <h2 class="pb-lg-5 pb-4"><span>Rebase Token Development -</span>What Is It
        Up To?</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Rebase Token Development"
              title="Rebase Token Development"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            The rebase token is developed to yield similar functionalities of a
            stable coin but with a twist. Stablecoin depends on assets to attain
            their value, while Rebase in turn adjusts itself for the existing
            value. Rebase tokens stabilize the value either by increasing or
            decreasing the supply of tokens to meet the fluctuation.
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Dynamic supply adjustment
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Elastic
                supply mechanism
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Reduce
                price volatility
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Market
                adoptation
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Trending ERC20 Token List Start -->
<section class="section-service-alt">
  <div class="tending-bep-20 pb-xl-4">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3 class="popular-ai">
            <span>Popular Live
            </span>
            Rebase Tokens
          </h3>
        </div>
      </div>
      <div class="row client-carousel">
        <div class="col-lg-12 mx-auto">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/spartacus-dark.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/spartacus-dark.png"
                    width="92" height="92" class="img-fluid" alt="Spartacus"
                    title="Spartacus" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/spartacus-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/spartacus-lite.png"
                    width="92" height="92" class="img-fluid" alt="Spartacus"
                    title="Spartacus" *ngIf="isDarkmode" />
                </picture>
                <h4>Spartacus</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/base-protocol.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/base-protocol.png"
                    width="auto" height="auto" class="img-fluid"
                    alt="Base Protocol" title="Base Protocol" />
                </picture>
                <h4>Base Protocol</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/olympus.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/olympus.png"
                    width="auto" height="auto" class="img-fluid"
                    alt="Olympus V1" title="Olympus V1" />
                </picture>
                <h4>Olympus V1</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/snowbank.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/snowbank.png"
                    width="auto" height="auto" class="img-fluid" alt="Snowbank"
                    title="Snowbank" />
                </picture>
                <h4>Snowbank</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/spartacus.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/spartacus.png"
                    width="auto" height="auto" class="img-fluid"
                    alt="Templar DAO" title="Templar DAO" />
                </picture>
                <h4>Templar DAO</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/templer-dao.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/templer-dao.png"
                    width="auto" height="auto" class="img-fluid"
                    alt="Ampleforth" title="Ampleforth" />
                </picture>
                <h4>Ampleforth</h4>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="popular-ai-meme">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/rebase-token-development/wonderland.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/rebase-token-development/wonderland.png"
                    width="auto" height="auto" class="img-fluid"
                    alt="Wonderland" title="Wonderland" />
                </picture>
                <h4>Wonderland</h4>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Rebase Token Development</span> Service Package</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="Token Development"
                title="Token Development" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Development</h4>
          <p>
            Curate well-audited and highly compatible rebase tokens for your
            project with our Rebase token development services.
          </p>
          <div class="content-link">
            <a href="https://www.blockchainx.tech/token-development-company/">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Token Bridging"
                title="Token Bridging" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Token Bridging
          </h4>
          <p>
            Promoting cross-network compatibility and exploring your project
            vicinity with Token Bridging with your rebase token.
          </p>
          <div class="content-link">
            <a [routerLink]="['/']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
                  </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="Token Migrators"
                title="Token Migrators" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Migrators</h4>
          <p>
            Improve your current rebase token with our token migrator provides
            to option to entirely swap it for a new one.
          </p>
          <div class="content-link">
            <a href="https://www.blockchainx.tech/token-migration">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
                  </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Tokenomics Consulting"
                title="Tokenomics Consulting" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Tokenomics Consulting</h4>
          <p>
            We provide end-to-end tokenomics consultation to create, launch, and
            distribute strong tokens for your projects.
          </p>
          <div class="content-link">
            <a href="https://www.blockchainx.tech/blockchain-consulting-services/">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
                  </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Decentralized Exchanges"
                title="Decentralized Exchanges" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Decentralized Exchanges</h4>
          <p>
            Developing customized and efficient Decentralized exchange platform
            to seamlessly transact and exchange crypto tokens.
          </p>
          <div class="content-link">
            <a href="https://www.blockchainx.tech/cryptocurrency-exchange-software-development/">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
                  </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Token Sale Platform"
                title="Token Sale Platform" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Token Sale Platform
          </h4>
          <p>
            A vision-driven Token sale platform for your project to scale up its
            market bandwidth and contribute to maximizing sales.
          </p>
          <div class="content-link">
            <a href="https://www.blockchainx.tech/ready-made-ico-software/">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
                  </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-3">
          <h2>
            Why Choose BlockchainX for <span>Rebase Token Development</span>
          </h2>
          <p>
            Rebase Token development Services at BlockchainX are a very sincere
            and seamless process with customized integrations. We as a Rebase
            token development company ensure to develop regulated tokens that
            drive your project with stability. With 7 years of experience in
            blockchain-based development, we have hands-on experience in
            developing with expert teams involved in the same. Kickstart your
            elastic journey with BlockchainX.
          </p>
          <div class="content-link my-3">
            <a href="https://www.blockchainx.tech/contact/">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="!isDarkmode" />
            </picture>
            <picture>
                <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                    data-src="assets-new/images/right-arrow-dark.png"
                    class="img-fluid"
                    width="50" height="15" alt="Arrow"
                    title="Arrow" *ngIf="isDarkmode" />
                  </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Rebase Token Development Services"
              title="Rebase Token Development Services" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is a Rebase token?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Rebase or elastic token is developed to auto-manage the
                    price fluctuation by either increasing or decreasing the
                    token supply in the market.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Is the rebase token a substitute for the stable token?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    No, but a rebase token is similar to a stable coin but not
                    the same. The stable token value is based on an asset and in
                    the case of a rebased token it adjusts based on the market.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  How does rebase token development benefit the project?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The Rebase token is decentralized, has the potential to
                    adopt price fluctuations, and eventually manage price
                    stability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a href="https://www.blockchainx.tech/blog/">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/what-is-erc20/">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/blog/what-is-erc20/erc20-token-standard.webp
                    "
                  />
                  <img loading="lazy"
                    
                    data-src="assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="ERC20 Token Standard"
                    title="ERC20 Token Standard"
                  />
                </picture>
                <h3>
                  What is ERC20? A Guide to the Pinnacles of Ethereum Token
                  Standard
                </h3>
                <p>
                  While this crypto revolution continues to change the virtual
                  and the real world, the ERC20 token standards...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/what-is-erc20/">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                href="https://www.blockchainx.tech/erc1155-token-standard/"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/erc-1155.webp
                    "
                  />
                  <img loading="lazy"
                    
                    data-src="assets-new/images/blog/erc-1155.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="ERC 1155"
                    title="ERC 1155"
                  />
                </picture>
                <h3>
                  ERC1155 – Explore the ERC-1155 Token Standard
                </h3>
                <p>
                  If you understand what an non-fungible-token-development NFT
                   is and that the abbreviation... 
                </p>
              </a>
              <div class="content-link">
                <a
                  href="https://www.blockchainx.tech/erc1155-token-standard/"
                >
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.webp
                    "
                  />
                  <img loading="lazy"
                    
                    data-src="assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Private Blockchain Development"
                    title="Private Blockchain Development"
                  />
                </picture>
                <h3>
                  How to Create a Private Blockchain For Intensify Business Security?
                </h3>
                <p>
                  With blockchain dominating the entire digital ecosystem, and finance
                   and technology taking over the world...
                </p>
              </a>
              <div class="content-link">
                <a
                 href="https://www.blockchainx.tech/how-to-create-a-private-blockchain/"
                >
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                href="https://www.blockchainx.tech/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/crypto-launchpad-development-steps.webp
                    "
                  />
                  <img loading="lazy"
                    
                    data-src="assets-new/images/blog/crypto-launchpad-development-steps.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Crypto Launchpad Development Steps"
                    title="Crypto Launchpad Development Steps"
                  />
                </picture>
                <h3>
                  Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad 
                </h3>
                <p>
                  Crypto launchpad development is a great opportunity to keep up
                   and stay one step ahead in this booming...
                </p>
              </a>
              <div class="content-link">
                <a
                  href="https://www.blockchainx.tech/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad/"
                >
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="Arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Stabilize your Project With
                Rebase Token</span></h3>
            <p>Developed to automatically have control over the supply pool and
              manage price fluctuation eventfully, Connect now!</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>
          
                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div> 
          
                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />
          
                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />
          
                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />


                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->