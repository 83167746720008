import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-real-world-asset-tokenization',
  templateUrl: './real-world-asset-tokenization.component.html',
  styleUrls: ['./real-world-asset-tokenization.component.css']
})
export class RealWorldAssetTokenizationComponent implements OnInit {

  title = 'Real World Asset Tokenization Services | BlockchainX ';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Transform assets into digital tokens with our Real World Asset Tokenization Services. Secure, custom solutions for creating and trading tokenized assets.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'real world asset tokenization services,real world asset token development, real world asset token development company, real world asset token development solutions, rwa token development, rwa token development company, rwa tokenization solutions, rwa tokenization services, real world asset tokenization solutions',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Real World Asset Tokenization Services | BlockchainX ',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/real-world-asset-tokenization/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Transform assets into digital tokens with our Real World Asset Tokenization Services. Secure, custom solutions for creating and trading tokenized assets.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-world-asset.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/real-world-asset-tokenization/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Real World Asset Tokenization Services | BlockchainX ',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Transform assets into digital tokens with our Real World Asset Tokenization Services. Secure, custom solutions for creating and trading tokenized assets.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-world-asset.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/real-world-asset-tokenization/'
    );
  }

  fieldOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    slideBy: 1,
    dots: false,
    // autoHeight: true,
    margin: 20,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1024: {
        items: 3,
      },

      1366: {
        items: 3,
      },
    },
    nav: false,
  };

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };

  
}
