import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';
@Component({
  selector: 'app-ourprocess',
  templateUrl: './ourprocess.component.html',
  styleUrls: ['./ourprocess.component.css'],
})
export class OurprocessComponent implements OnInit {
  isDarkmode: Boolean=false;
  constructor(private footerservice: FooterServiceService) {}

  ngOnInit(): void {
    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      // 640: {
      // items: 2,
      // },
      // 740: {
      //   items: 3,
      // },
      // 1000: {
      //   items: 5,
      // },
    },
    nav: false,
  };
}
