import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';

@Directive({
  selector: '[appLazyLoad]'
})
export class LazyLoadDirective implements OnInit {
  @Input() appLazyLoad!: string;

  constructor(private el: ElementRef<HTMLImageElement>) {}

  ngOnInit(): void {
    const img = this.el.nativeElement;

    const loadImage = () => {
      img.src = this.appLazyLoad;
    };

    if (IntersectionObserver) {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          loadImage();
          observer.disconnect();
        }
      });

      observer.observe(img);
    } else {
      // Fallback for browsers without IntersectionObserver
      fromEvent(window, 'scroll').pipe(
        debounceTime(100),
        switchMap(() => {
          const rect = img.getBoundingClientRect();
          return merge(
            fromEvent(window, 'scroll'),
            fromEvent(window, 'resize')
          ).pipe(
            map(() => rect.top < window.innerHeight),
            take(1)
          );
        })
      ).subscribe(visible => {
        if (visible) {
          loadImage();
        }
      });
    }
  }
}
