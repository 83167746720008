import {
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
  SafeHtml,
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-pump-fun-clone-script',
  templateUrl: './pump-fun-clone-script.component.html',
  styleUrls: ['./pump-fun-clone-script.component.css']
})
export class PumpFunCloneScriptComponent implements OnInit {


  selectedLink:number;
  bitrixSnippetScript: SafeScript;
  viewportScroller: any;
  bitrixSnippetScript1: SafeHtml;
  bitrixSnippetScript2: SafeHtml;


  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private doc: Document
  ) {
    // const scriptUrl = `<script data-b24-form="inline/34/a10l11" data-skip-moving="true">${
    //   (function (w, d, u) {
    //     var s = d.createElement('script');
    //     s.async = true;
    //     s.src = u + '?' + (Date.now() / 180000 | 0);

    //     // Perform a null check before calling parentNode.insertBefore
    //     var h = d.getElementsByTagName('script')[0];
    //     if (h && h.parentNode) {
    //       h.parentNode.insertBefore(s, h);
    //     } else {
    //       // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
    //       // You can append the script to the <body> element or take another appropriate action.
    //       document.body.appendChild(s);
    //     }
    //   })(window, document, 'https://cdn.bitrix24.in/b26760065/crm/form/loader_34.js')
    // };</script>`;
    // this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(`${scriptUrl}`);

    const scriptUrl1 = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        document.body.appendChild(s);
      }
    })(window, document, 'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js')}</script>`;
  
    // Form 2 Script
    const scriptUrl2 = `<script data-b24-form="inline/44/ltbs0y" data-skip-moving="true">${(function (
      w,
      d,
      u
    ) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);
      var h = d.getElementsByTagName('script')[0];
      if (h && h.parentNode) {
        h.parentNode.insertBefore(s, h);
      } else {
        document.body.appendChild(s);
      }
    })(window, document, 'https://cdn.bitrix24.in/b26760065/crm/form/loader_44.js')}</script>`;
  
    // Sanitize and trust the form scripts
    this.bitrixSnippetScript1 = this.sanitizer.bypassSecurityTrustHtml(scriptUrl1);
    this.bitrixSnippetScript2 = this.sanitizer.bypassSecurityTrustHtml(scriptUrl2);
  
}

  isDarkmode:boolean=false;
  title = 'Pump Fun Clone Script | Build A Platform Like Pump Fun';


  ngOnInit(): void {
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Host incredibly engaging meme coins on your Pump Fun Clone script,  exclusively customized to meet your requirements with BlockchainX. Connect to learn more. ',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Pump fun clone script, Pump fun clone, Pump fun clone script development, Pump fun clone software, Ready-made pump fun clone script, Ready-made pump fun clone, White label pump fun clone script, White label pump fun clone, Pump fun clone app development',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Pump Fun Clone Script | Build A Platform Like Pump Fun',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/pump-fun-clone-script',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Host incredibly engaging meme coins on your Pump Fun Clone script,  exclusively customized to meet your requirements with BlockchainX. Connect to learn more. ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/pump-fun/pump-fun-development.png',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/pump-fun-clone-script',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Pump Fun Clone Script | Build A Platform Like Pump Fun',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Host incredibly engaging meme coins on your Pump Fun Clone script,  exclusively customized to meet your requirements with BlockchainX. Connect to learn more. ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/pump-fun/pump-fun-development.png',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/pump-fun-clone-script'
    );

  }
  show = true;
  scroll() {
    this.viewportScroller.scrollToAnchor('travel');

    console.log('test top');
  }
  addClass(id:number){
    this.selectedLink=id;
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}
