<section class="blog-detail">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 mb-4">
        <div class="blog-single-wrap">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/industries/blockchain-in-agriculture.webp" />
            <source type="image/png"
              srcset="assets-new/images/industries/blockchain-in-agriculture.png" />
            <img loading="lazy"
              data-src="assets-new/images/industries/blockchain-in-agriculture.png"
              alt="Blockchain-in-agriculture" title="Blockchain-in-agriculture"
              class="img-fluid" width="1024" height="624" />
          </picture>
          <h3 id="item-1" [ngClass]="selectedLink===1?'scrollHeading':'scrollheadingone'">Overview</h3>
          <p>
            Globally, around 40% of people are employed in the agriculture
            sector, which contributes $5,084,800 million of total worldwide
            production, making it contribute 6.4% to the global economy. But it
            remains one of the most outdated sectors in terms of technical
            development.
          </p>
          <p>
            After the crop leaves the farm, it follows various levels of the
            supply chain to reach the final consumer. Ensuring trust,
            transparency, and efficiency from farm to grocery store is a tough
            task and is one of the major backlogs that this sector faces. This
            industry is a little less explored, but revolutionary blockchain
            technology can help to combat these problems in the first place.
            This technology has drastically changed many industries like Retail,
            Automobiles, and Healthcare. This technology of a ledger system that
            allows peer-to-peer transactions of any commodity and is
            tamper-proof has the potential to bring a much-needed change in the
            agricultural sector.
          </p>
          <h3 id="item-2" [ngClass]="selectedLink===2?'scrollHeading':'scrollheadingone'">Application of Blockchain in Agriculture</h3>
          <p>
            The various things that could be improved in this sector through
            blockchain are:
          </p>
          <h4>Procurement Tracking</h4>
          <p>
            One of the biggest challenges that this sector faces is tracking and
            paying for the delivery of food. Both sellers and buyers have agents
            that increase the size of the chain, making the product more
            expensive than its original value and being time-consuming.
          </p>
          <p>
            With the existence of blockchain technology, this problem can be
            easily resolved. Since there would be a single distributed ledger,
            the communication between the buyer and seller would improve,
            meaning there would be a decrease in the number of agents. With
            features like traceability and suitability, farmers can directly
            contact restaurants or individual buyers and get a larger share of
            their sales. It will also remove the exploitation done by
            intermediaries to both parties.
          </p>
          <h4>Crop and Food Production</h4>
          <p>
            Using the concept of smart farming, IoT sensors can fetch important
            information like soil quality, water level, details of fertilizers,
            etc. It will help in increasing both the quality of the farming
            process and the production of crops.
          </p>
          <h4>Weather Crisis control</h4>
          <p>
            The issue of unpredictable weather causes a lot of problems in the
            field. Crops find it difficult to tolerate flooding in the event of
            excessive rain. Crops become parched when there is no rain.
            Furthermore, excessive heat kills crops. And consumers are never
            made aware of these situations, so they never know the true cause of
            a price increase. Blockchain can help farmers get easy claims
            through smart contracts.
          </p>
          <h4>Managing Agricultural Finance</h4>
          <p>
            Lack of transparency in credit histories and agreements are some
            common problems faced by farmers. They are generally paid less than
            their share, With blockchain, agribusiness will become more
            transparent, which will enable shared control.
          </p>
          <p>
            This industry needs to maintain customer trust in food quality, and
            blockchain has the power to ensure transparency in the system.
          </p>

          <h3 id="item-3" [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">How will this system work?</h3>
          <p>The blockchain system in agriculture is easy to understand.</p>
          <h4>Step1: Data Generation</h4>
          <p>The data will be generated by three major categories:</p>
          <ul class="industry-list">
            <li>
              <strong>Crop and soil monitoring :</strong> Temperature, pH level
              of soil,pesticides and water spray from IoT sensors and weather
              and wind monitoring data.
            </li>
            <li>
              <strong>Warehousing and distribution :</strong> Quality control,
              tracking transportation and food safety control.
            </li>
            <li>
              <strong>Retail and marketing :</strong> Bill data, taxes and
              compliance.
            </li>
          </ul>
          <h4>Step2: Data Processing</h4>
          <p>
            This data will be processed in the form of smart contracts to keep
            the chain transparent to investors.
          </p>
          <h4>Step3 : Blockchain Consumptions</h4>
          <p>
            This data will be hashed and saved on blockchains. Information on
            land usage, crop efficiency, financial audit, and flood mitigation
            can be collected from these sources.
          </p>
          <h3 id="item-3" [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">Summing Up</h3>
          <p>
            According to UN estimates, food fraud costs the global economy $40
            billion per year due to illegal trade. Blockchain will help in the
            monitoring of abundant commodities and food fraud. Better harvesting
            and trade practices can be expected with blockchain agribusiness.
          </p>
        </div>
        <app-social></app-social>
        <!-- <div class="social-share">
          <div>
            <h3 class="pb-0">Share</h3>
          </div>
          <div class="d-flex align-items-center gap-3">
            <ul>
              <li>
                <a href="#" 
                  >
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/blog/fb.webp" />
                    <source type="image/png" srcset="assets-new/images/blog/fb.png" />
                    <img loading="lazy" data-src="assets-new/images/blog/fb.png"  width="32" height="32" alt="facebook" title="facebook" />
                  </picture>
                </a>
              </li>
              <li>
                <a href="#"
                  >
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/blog/linkedin.webp" />
                    <source type="image/png" srcset="assets-new/images/blog/linkedin.png" />
                    <img loading="lazy" data-src="assets-new/images/blog/linkedin.png"  width="32" height="32" alt="linkedin" title="linkedin" />
                  </picture>
                </a>
              </li>
              <li>
                <a href="#"
                  >
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/blog/twitter.webp" />
                    <source type="image/png" srcset="assets-new/images/blog/twitter.png" />
                    <img loading="lazy" data-src="assets-new/images/blog/twitter.png"  width="32" height="32" alt="twitter" title="twitter" />
                  </picture>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 px-0">
            <app-comments></app-comments>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <!-- <app-blog-sidebar></app-blog-sidebar> -->
        <div class="blog-right-side table-content">
          <div class="table-content-main">
              <div class="metaverse_heading">
                <h4 class="pb-0"><span>Table of Contents</span></h4>
            </div>
            <div class="categories_list_view table-contens">
                <ul>
                    <li>
                        <a
                            href="https://www.blockchainx.tech/blockchain-applications-agriculture/#item-1"
                            (click)="addClass(1)">Overview</a>
                    </li>
                    <li>
                        <a
                            href="https://www.blockchainx.tech/blockchain-applications-agriculture/#item-2"
                            (click)="addClass(2)">Application of Blockchain in Agriculture</a>
                    </li>
                    <li>
                        <a
                            href="https://www.blockchainx.tech/blockchain-applications-agriculture/#item-3"
                            (click)="addClass(3)">How will this system work?</a>
                    </li>
                    <li>
                        <a
                            href="https://www.blockchainx.tech/blockchain-applications-agriculture/#item-4"
                            (click)="addClass(4)">Summing Up</a>
                    </li>
                </ul>
            </div>
            </div>
            <!-- <div class="categories_list">
                <div class="metaverse_heading">
                  <h4 class="pb-0"><span>Categories</span></h4>
                </div>
                <div class="categories_list_view">
                  <ul>
                    <li class="pt-0">
                      <a>NFT</a>
                    </li>
            
                    <li>
                      <a>Metaverse</a>
                    </li>
            
                    <li>
                      <a>NFT Marketplace</a>
                    </li>
            
                    <li>
                      <a>DEFI</a>
                    </li>
            
                    <li>
                      <a>DAO</a>
                    </li>
            
                    <li>
                      <a>Cryptocurrency</a>
                    </li>
            
                    <li>
                      <a>Wallet</a>
                    </li>
                    <li>
                      <a>Crypto Exchange</a>
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="blog-author-wrap">
                <div class="blog-author-detail">
                  <div>
                    <img
                      src="assets-new/images/blog/yokesh-shankar.svg"
                      alt="blog author"
                      width="150"
                      height="150"
                    />
                  </div>
                  <div>
                    <div class="metaverse_heading">
                      <h4><span>About Author</span></h4>
                    </div>
                    <h5>Yokesh Sankar</h5>
                    <div class="author-social-connect">
                      <ul>
                        <li>
                          <a
                            href="https://www.instagram.com/yokesh.sankar/"
                            target="_blank"
                            class="fs-5"
                            rel="noopener noreferrer"
                            alt="instagram"
                            aria-label="Instagram"><i class="bi bi-instagram"></i></a>
                        </li>
                        <li>
                          <a
                            href="https://in.linkedin.com/in/yokeshsankar"
                            target="_blank"
                            class="fs-5"
                            rel="noopener noreferrer"
                            alt="Linkedin"
                            aria-label="Linkedin"><i class="bi bi-linkedin"></i></a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/yokesh.shankar"
                            target="_blank"
                            class="fs-5"
                            rel="noopener noreferrer"
                            alt="facebook"
                            aria-label="facebook"><i class="bi bi-facebook"></i></a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/yokesh_sankar_v"
                            target="_blank"
                            class="fs-5"
                            rel="noopener noreferrer"
                            alt="Twitter"
                            aria-label="twitter">
                            <i class="bi bi-twitter-x"></i>
                          </a>
                        </li>
          
                      </ul>
                    </div>
                  </div>
                </div>
                <p>
                  I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time to
                  offer the best products for our clients without apprehensions at
                  economic rates.
                </p>
              </div>
              <div class="social-shares">
                <div>
                  <a href="https://twitter.com/Blockchainxtech" target="_blank"
                          rel="noopener noreferrer"
                    >Follow us on Twitter
                    <span class="mx-2 cyan-300"
                      ><i class="bi bi-twitter-x color cyan-300"></i></span
                  ></a>
                </div>
              </div>
        </div>
      </div>
  </div>
  </div>
</section>
<section class="section-service-main">
  <app-blog></app-blog>
</section>
<!-- <section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section> -->
<section class="section-service-alt-padd">
  <app-get-in-touch></app-get-in-touch>
</section>
