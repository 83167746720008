<section class="banners-section" #about>
    
      <div class="banner_wrapper">
        <div class="container" id="common-relative">
          <div class="green-vector"></div>
          <div class="row align-items-center">
              <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                <div class="banner_contentWrapper">
                  <div class="caption">
                    <h1>
                      <span><strong>Liquid Restaking </strong></span> Governance Token Platform
                    </h1>
                    <p> 
                        Double the power of governing tokens with multiplied revenue streaming opportunities with Liquid Restaking tokens.
                    </p>
    
                    <div class="contact-link">
                      <a [routerLink]="['/contact']" class="home-link"
                        >Talk To Our Experts</a
                      >
                      <a [routerLink]="['/case-studies']" class="home-link-alt"
                        >View Case Study</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                <div class="banner-right-section">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/launch-your-token.webp" />
                    <source type="image/png" srcset="assets-new/images/token-images/launch-your-token.png" />
                    <img loading="lazy" data-src="assets-new/images/token-images/launch-your-token.png" width="500" height="500" class="img-fluid" alt="liquid-restaking-governance-token-image" title="Liquid Restaking Governance Token Platform" />
                  </picture>
                </div>
              </div>
            
          </div>
          <div class="row d-lg-block d-none">
            <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/scroll.webp"
                />
                <source
                  type="image/png"
                  srcset="assets-new/images/home/scroll.png"
                />
                <img
                  loading="lazy"
                  data-src="assets-new/images/home/scroll.png"
                  width="25"
                  height="48"
                  class="img-fluid"
                  alt="scroll-icon"
                  title="scroll-icon"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>
  
  <!-- What is ERC20 start-->
  <section class="section-service-alt">
      <div class="container">
        <div class="whys_section mb-0 text-center">
          <h2 class="pb-lg-5 pb-4"><span>Liquid Restaking Governance Token </span>Development</h2>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
            <div class="bep-work-section">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/token-images/why-should-you-choose.webp">
                <source type="image/png"
                  srcset="assets-new/images/token-images/why-should-you-choose.png">
                <img loading="lazy"
                  data-src="assets-new/images/token-images/why-should-you-choose.png"
                  width="350" height="350" alt="liquid-restaking-governance-token-development-image"
                  title="Liquid Restaking Governance Token Development" class="BSC-Tokens-image img-fluid">
              </picture>
            </div>
          </div>
          <div
            class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <p>
                The mechanism involves a decentralized governance token that can be staked in the platform under the DeFi protocol, which in return earns rewards and ensures it holds up to its nominal functionality by allowing the token holders to take part in the governing activities, decision-making processes, and others are Liquid staking governance token development. This concept brings together the benefits of liquidity pool by staking tokens and at the same time allows holders to enjoy the benefit of governance tokens, which maximizes the potential and utility of the token and shifts its engagement in the ecosystem. 
              </p>
            </div>
    
            <!-- <div class="row token d-flex">
              <div class="col-12 projects">
                <ul>
                  <li>
                    <span><i class="bi bi-check-circle-fill"></i></span>
                    Shared security model ensuring robust integrity across parachains
                  </li>
                  <li>
                    <span><i class="bi bi-check-circle-fill"></i></span> Accommodating growing network with scaling possibility
                  </li>
                  <li>
                    <span><i class="bi bi-check-circle-fill"></i></span> Seamlessly connect and collaborate with diverse blockchain 
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
       
      </div>
  </section>
  <!-- End -->
  
  <!-- Popular Ganming List Start -->
  <div class="section-service-main">
      <div class="tending-bep-20">
        <div class="container">
          <div class="row align-items-center trending-token-list">
            <div class="col-lg-6 text-center order-lg-first order-last">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
                <source type="image/png"
                  srcset="assets-new/images/erc/trending-erc20-token-list.png" />
                <img loading="lazy"
                  data-src="assets-new/images/erc/trending-erc20-token-list.png"
                  width="405" height="405" alt="popular-liquid-restaking-image"
                  title="Popular Liquid Restaking" class="img-fluid mx-auto">
              </picture>
            </div>
            <div class="col-lg-6 mb-lg-0 mb-4">
              <h3 class="main-sub-heading text-start"><span>Popular Liquid Restaking </span>  Governance Token</h3>
    
              <div class="row trending-bep">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                  <ul>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Jito
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Ankr Network
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> BENQI 
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                  <ul>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Stader
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> pSTAKE Finance
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Lido DAO
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex pt-3">
                <a [routerLink]="['/contact']" class="home-link-alt">Connect With
                  Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End -->
  
  <!-- Related Services -->
  <section class="section-service-alt-padd">
      <div class="container">
        <div class="row">
          <div class="metaverse_heading mx-auto">
            <h3><span>Importance Of Liquid Restaking</span> Governance Tokens</h3>
          </div>
        </div>
        <div class="row types-of-token-detail">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                  <source type="image/png" srcset="assets-new/images/token images/project-consulting.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="improved-liquidity-image" title="Improved Liquidity" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Improved Liquidity</h4>
              <p>
                Liquid Restaking Governance provides the stakers with increased liquidity when tokenizing staked assets, which are free to trade, transfer, or sell. 
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="financial flexibility-image" title="Financial Flexibility" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">
                Financial Flexibility 
              </h4>
              <p>
                It provides liquidity while staking for rewards also continues. This makes the holder maintain balance and enjoy the financial flexibility simultaneously. 
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="optimized-capital-utilization-image" title="Optimized Capital Utilization" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Optimized Capital Utilization</h4>
              <p>
                Thye optimize capital utilization where users earn by staking rewards and assets, the liquidity of the token is active for DeFi activities which include borrowing, yield farming, and lending.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Security" title="Security" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Improved Efficiency </h4>
              <p>
                Increased fungibility and tradeability in staked assets, the LRGT makes the market more efficient in terms of facilitating prices, and others to improve the overall market dynamics. 
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="Decentralization" title="Decentralization" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Accessible Staking</h4>
              <p>
                By choosing liquid restaking governance tokens development there is a broad spectrum of market, and investors where the barriers are lowered encouraging maximum participation of all. 
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Innovation" title="Innovation" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">
                Incentivizing Participation
              </h4>
              <p>
                Provided with staking rewards, and liquidity users can stake their assets,which contribute to security, and decentralizing networks. 
              </p>
            </div>
          </div>
        </div>
        
      </div>
  </section>
  <!-- Related Services -->
  
    <!-- why-choose-section -->
    <section class="section-service-main">
        <div class="green-vector"></div>
        <div class="orange-vector"></div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 metmb-3">
              <div class="whys_section mb-0">
                <h2>
                  <span>Why Choose BlockchainX ?</span>
                </h2>
                <p class="pb-3">
                    As a liquid restaking governance tokens platform development company we at BlockchainX ensure to be creative, and active in your suggestions, curate effective solutions that are strong, resilient and robust ensuring, security, safety, transparency, and accessibility. We work on advanced new gen technologies that thrive to excel in the market, and meet your demand. We have hands- on decentralized platform development experience and we are keen about upgrading and rejuvenating. Connect to get to know us and our service better. 
                </p>
                <!-- <div class="whysection_text_wrap">
                <ul>
                  <li>50plus successful projects</li>
                  <li>24/7 project support</li>
                  <li>Advanced technology</li>
                  <li>Relatable solution</li>
                  <li>Tailored Experience</li>
                </ul>
              </div> -->
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <!-- <span>Read More</span> -->
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                  <hr />
                </div>
              </div>
            </div>
            <div class="col-lg-6 metmb-3 Why-section">
              <div class="why_right_side">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />
    
                  <source
                    type="image/png"
                    srcset="assets-new/images/token-images/why-choose-blockchainx.png" />
    
                  <img
                    src="assets-new/images/token-images/why-choose-blockchainx.png"
                    width="624"
                    height="521"
                    class="img-fluid"
                    alt="why-choose-blockchainX-image"
                    title="Why Choose BlockchainX ?"
                    loading="lazy" />
                </picture>
              </div>
            </div>
          </div>
        </div>
    </section>
    <!-- End -->
  
  <!-- FAQ Start -->
  <section class="section-service-alt">
      <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
          <div class="green-vector"></div>
          <div class="violet-vector"></div>
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-10 col-md-12 col-sm-12 col-12">
              <div class="common-sub-heading sub-title">
                FAQ <span class="dots">.</span>
              </div>
              <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
            </div>
          </div>
          <div class="row align-items-center justify-content-center pt-md-5 pt-4">
            <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      Can LRGT meet its basics and stay tangible with new qualities?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>
                        The tokens are given an additional level of training and security that ensures to gain liquidity in the market and eventually take assets for reward. Here their basics and leveled up version are secured.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      What are the significance of Liquid Restaking Governance token developments?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>
                        Financial flexibility, incentivization, Governance participation, and liquidity provision are the significant abilities unlocked with LRGT. 
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      Will BlockchainX provide customization?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>
                        Yes, we do provide customization in all our niches of development. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Accordion Section -->
      </div>
    </section>
    <!-- FAQ End -->
  
      <!-- Blog Start -->
  <section class="section-service-main">
    <app-blog></app-blog>
    </section>
    <!-- Blog End -->
    
    <!-- Contact Start -->
    <section class="section-service-alt">
      <div class="app-get-in-touch-form">
        <div class="container">
            <div class="contact-form">
                <div class="row reverse-colum">
                  <div class="col-lg-5 col-md-12 contact-social-links">
                    <h3 class="main-sub-heading"><span>Maximize the abilities of governance token with </span></h3>
                    <p>Liquid Restaking Governance Token Development. </p>
                    <div class="contact-main-list">
                      <div class="social-links">
                        <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/mail.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/mail.png"
                            />
            
                            <img
                              src="assets-new/images/contact/mail.png"
                              alt="linkedin"
                              width="32"
                              height="32"
                            />
                          </picture>
                        </a>
            
                        <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/whatsapp-icon.png"
                            />
            
                            <img
                              src="assets-new/images/contact/whatsapp-icon.png"
                              alt="contact-Whatsapp"
                              width="32"
                              height="32"
                            />
                          </picture>
                        </a>
            
                        <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/telegram.webp"
                            />
            
                            <source
                              type="image/svg"
                              srcset="assets-new/images/contact/telegram.png"
                            />
            
                            <img
                              src="assets-new/images/contact/telegram.png"
                              alt="twitter"
                              width="40"
                              height="40"
                            />
                          </picture>
                        </a>
                      </div>
                      <!-- <div class="contact-list">
                        <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/mail.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/mail.png"
                            />
            
                            <img
                              src="assets-new/images/contact/mail.png"
                              loading="lazy"
                              alt="contact-mail"
                              width="50"
                              height="50"
                              class="contact-logo img-fluid"
                            />
                          </picture>
                          Contact@blockchainx.tech</a
                        >
                      </div>
            
                     <div class="contact-list">
                        <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/whatsapp-icon.png"
                            />
            
                            <img
                              src="assets-new/images/contact/whatsapp-icon.png"
                              loading="lazy"
                              alt="contact-Whatsapp"
                              width="50"
                              height="50"
                              class="contact-logo img-fluid"
                            />
                          </picture>
                          Connect on Whatsapp</a
                        >
                      </div> 
            
                      <div class="contact-list">
                        <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/telegram.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/telegram.png"
                            />
            
                            <img
                              src="assets-new/images/contact/telegram.png"
                              loading="lazy"
                              alt="contact-skype"
                              width="50"
                              height="50"
                              class="contact-logo1 img-fluid"
                            />
                          </picture>
                          Connect on Telegram</a
                        >
                      </div> -->
                    </div>
                    <div class="contact-links">
                      <h4>Follow us on</h4>
                      <div class="social-links">
                        <a
                          href="https://www.linkedin.com/company/blockchainxtech/"
                          target="_blank"
                rel="noopener noreferrer"
                          class="linkdin"
                        >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/linkdin.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/linkdin.png"
                            />
            
                            <img
                              src="assets-new/images/contact/linkdin.png"
                              alt="linkedin"
                              width="22"
                              height="22"
                            />
                          </picture>
                          <!-- <img
                            src="assets-new/images/contact/linkdin.png"
                            alt="linkedin"
                            width="22"
                            height="22"
                        /> -->
                        </a>
            
                        <a
                          href="https://www.instagram.com/blockchainxtech/"
                          target="_blank"
                rel="noopener noreferrer"
                          class="instagram"
                        >
                          <!-- <img
                            src="assets-new/images/contact/instagram.png"
                            alt="instagram"
                            width="22"
                            height="22"
                          /> -->
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/instagram.webp"
                            />
            
                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/instagram.png"
                            />
            
                            <img
                              src="assets-new/images/contact/instagram.png"
                              alt="instagram"
                              width="22"
                              height="22"
                            />
                          </picture>
                        </a>
            
                        <a
                          href="https://twitter.com/Blockchainxtech"
                          target="_blank"
                rel="noopener noreferrer"
                          class="twitter"
                        >
                          <!-- <img
                            src="assets-new/images/contact/twitter.png"
                            alt="twitter"
                            width="22"
                            height="22"
                          /> -->
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/twitterx.webp"
                            />
            
                            <source
                              type="image/svg"
                              srcset="assets-new/images/contact/twitterx.svg"
                            />
            
                            <img
                              src="assets-new/images/contact/twitterx.svg"
                              alt="twitter"
                              width="25"
                              height="25"
                            />
                          </picture>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-12 contact-inputs">
                    <div class="getintouch getintochform bitrix-snippet-form">
                      <!-- <div #crmForm></div> -->
                      <div [innerHTML]="bitrixSnippetScript"></div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    <!-- Contact End -->