import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import './polyfills';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { PathLocationStrategy, Location } from '@angular/common';

const _orig_prepareExternalUrl = PathLocationStrategy.prototype.prepareExternalUrl;

PathLocationStrategy.prototype.prepareExternalUrl = function(internal: string) {
  const url = _orig_prepareExternalUrl.call(this, internal);

  if (url === '') {
    return url;
  }

  console.log('For ' + internal + ' we generated ' + url);
  if (url.endsWith('.html')) {
    return url;
  }

  if (url.endsWith('/')) {
    return url;
  }

  return url + '/';
};

Location.stripTrailingSlash = function (url: string) {
  const match = url.match(/#|\?|$/);
  const pathEndIdx = match && match.index || url.length;
  const droppedSlashIdx = pathEndIdx - (url[pathEndIdx - 1] === '/' ? 1 : 0);
  const first = url.slice(0, droppedSlashIdx);
  const last = url.slice(pathEndIdx);

  if (first.endsWith('.html')) {
    return first + last;
  }

  return first + '/' + last;
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.then((ref:any) => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
})
.catch(err => console.error(err));
