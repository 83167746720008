<!-- Banner Section -->
<div class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <h1 class="text-center">
        <span> About BlockchainX </span>
        <small>
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/about/webp/hand.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/about/hand.png"
            />

            <img
              loading="lazy"
              data-src="assets-new/images/about/hand.png"
              alt="hand"
              height="50"
              width="50"
              class="img-fluid"
            />
          </picture>
        </small>
      </h1>
      <div class="row align-items-center mb-3">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner-so-content">
            <div class="common-sub-heading mb-3">
              <h4>Why Us <span class="dots">.</span></h4>
            </div>
            <h4>
              BlockchainX is a subsidiary company of Sparkout Tech Solutions
            </h4>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-so-logo">
            <a href="https://www.sparkouttech.com/" target="_blank"
                rel="noopener noreferrer">
                <picture *ngIf="isDarkmode">
                  <source
                    type="image/png"
                    srcset="assets-new/images/sparkout-logo.svg"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/sparkout-logo.svg"
                    class="img-fluid"
                    alt="Sparkout logo"
                    width="261"
                    height="58"

                  />
                </picture>
                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/sparkout-inc-logo.webp"
                  />
                  <source
                    type="image/png"
                    srcset="assets-new/images/sparkout-inc-logo.svg"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/sparkout-inc-logo.svg"
                    class="img-fluid"
                    alt="Sparkout logo"
                    width="261"
                    height="58"
                  />
                </picture>
            </a>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <p>
                BlockchainX is a leading company, founded in 2017, that
                specializes in providing blockchain and cryptocurrency
                development services. We deliver excellent services that give
                businesses a competitive edge & help clients grow by taking a
                unique approach & focusing on quality. Our blockchainX team
                brings strong project management skills as well as a track
                record of successful projects in this emerging industry.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center">
          <a
            (click)="
              about.scrollIntoView({ behavior: 'smooth', block: 'start' })
            "
          >
          <picture>
            <source type="image/webp" srcset="assets-new/images/home/scroll.webp" />
            <source type="image/png" srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy" data-src="assets-new/images/home/scroll.png" width="25" height="48" class="img-fluid" alt="scroll-icon" title="scroll-icon" />
          </picture>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section ends -->
<!-- About Main -->
<section class="about-main-about" #about>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          <span
            >Our Passion will pave your way into the world of infinite
            possibilities</span
          >
        </h3>
      </div>
      <div class="about-people">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/about/webp/about-us-banner.webp"
          />

          <source
            type="image/png"
            srcset="assets-new/images/about/about-us-banner.png"
          />

          <img
            loading="lazy"
            data-src="assets-new/images/about/about-us-banner.png"
            class="img-fluid"
            width="1272"
            height="583"
            alt="About Blockchainx"
            title="About Blockchainx"
          />
        </picture>
      </div>
    </div>
  </div>
</section>
<!-- About Main  -->
<!-- About Products -->
<section class="about-products">
  <div class="green-vector"></div>
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>
        <span>BlockchainX</span>
        expertise in
      </h3>
    </div>

    <div class="about-product-detail">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mb-4 about-details">
          <div class="about-product-services">
            <h3>Service</h3>
            <p>
              We have provided a wide range of end-to-end services to our global
              clients over the years. You can find all our capabilities related
              to blockchains, smart contracts, DeFi, etc. We've got it all under
              one roof for you.
            </p>
            <div class="content-link">
              <a
                href="assets-new/images/about/blockchainx-services-list.pdf"
                download
              >
                <span>Download Brochure</span>

                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/about/webp/download.webp"
                  />
                  <source
                    type="image/png"
                    srcset="assets-new/images/about/download.png"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/about/download.png"
                    class="img-fluid"
                    alt="Download"
                    width="50"
                    height="26"
                  />
                </picture>

                <picture *ngIf="isDarkmode">
                  <source
                    type="image/png"
                    srcset="assets-new/images/about/download-dark.png"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/about/download-dark.png"
                    class="img-fluid"
                    alt="Download"
                    width="50"
                    height="26"
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mb-4 about-details">
          <div class="about-product-services">
            <h3>Product</h3>
            <p>
              We help you launch a future-ready Metaverse by supporting the
              technical & development needs of your project. With our expertise
              in Web3, AR/VR, and AI, you can reap the benefits of the 3D
              Internet.
            </p>
            <div class="content-link">
              <a
                href="assets-new/images/about/blockchainx-Products.pdf"
                download
              >
                <span>Download Brochure</span>

                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/about/webp/download.webp"
                  />
                  <source
                    type="image/png"
                    srcset="assets-new/images/about/download.png"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/about/download.png"
                    class="img-fluid"
                    alt="Download"
                    width="50"
                    height="26"
                  />
                </picture>

                <picture *ngIf="isDarkmode">
                  <source
                    type="image/png"
                    srcset="assets-new/images/about/download-dark.png"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/about/download-dark.png"
                    class="img-fluid"
                    alt="Download"
                    width="50"
                    height="26"
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mb-4 about-details">
          <div class="about-product-services">
            <h3>NFT</h3>
            <p>
              We provide NFT marketplace services that help users in launching a
              feature-rich NFT marketplace on various blockchain networks. Our
              expert team provides end-to-end solutions to create a fully
              functional and customized NFT marketplace.
            </p>
            <div class="content-link">
              <a
                href="assets-new/images/about/nft-marketplace-pitchdeck.pdf"
                download
              >
                <span>Download Brochure</span>

                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/about/webp/download.webp"
                  />
                  <source
                    type="image/png"
                    srcset="assets-new/images/about/download.png"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/about/download.png"
                    class="img-fluid"
                    alt="Download"
                    width="50"
                    height="26"
                  />
                </picture>

                <picture *ngIf="isDarkmode">
                  <source
                    type="image/png"
                    srcset="assets-new/images/about/download-dark.png"
                  />
                  <img
                    loading="lazy"
                    src="assets-new/images/about/download-dark.png"
                    class="img-fluid"
                    alt="Download"
                    width="50"
                    height="26"
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- About Products -->
<!-- About Clients -->
<section class="about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          <span>BlockchainX</span>
          Clients
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <source type="image/png" srcset="assets-new/images/home/clients/ekta.png" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <source type="image/png" srcset="assets-new/images/home/clients/wafercoin.png" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <source type="image/png" srcset="assets-new/images/home/clients/reflex-finance.png" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <source type="image/png" srcset="assets-new/images/home/clients/zuki.png" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <source type="image/png" srcset="assets-new/images/home/clients/polkadog.png" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- About Clients -->
<!-- About Service -->
<section class="service-detail">
  <div class="container">
    <div class="row">
      <owl-carousel-o [options]="clientOptions">
        <ng-template carouselSlide>
          <div class="service-detail-item">
            <h4>6 Years of experience</h4>
            <p>
              We are industry leaders with over 6 years of experience serving
              customers around the globe & have a lot of expertise in developing
              successful blockchain products.
            </p>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="service-detail-item">
            <h4>Blockchain experts</h4>
            <p>
              We have a team of highly skilled experts who have mastered the art
              over the years by working with clients from all over the world &
              achieving higher success rates.
            </p>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="service-detail-item">
            <h4>Team & Culture</h4>
            <p>
              Our team members share a passion for achieving a fixed outcome and
              make every effort to keep our culture fresh, innovative, and
              dynamic.
            </p>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="service-detail-item">
            <h4>Cost effective</h4>
            <p>
              We understand the client's expectations, achieve exceptional
              results, & offer strong IT technical support for entire innovative
              projects at an affordable cost.
            </p>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="service-detail-item">
            <h4>Customized solutions</h4>
            <p>
              We deliver best-suited and customized solutions to meet your
              advanced business needs, with expertise in both emerging and
              legacy technologies.
            </p>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
<!-- About Service -->
<!-- About Platform -->
<section class="about-platform">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          <span>BlockchainX</span>
          Platforms
        </h3>
      </div>
    </div>
    <div class="logo-carousel-list">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/ethereum.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/about/ethereum.png"
              />
              <img
              loading="lazy"
              data-src="assets-new/images/about/ethereum.png"
                class="img-fluid"
                alt="Ethereum"
                title="Ethereum"
                width="32"
                height="32"
              />
            </picture>
            <h5>ETHEREUM</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/binance.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/binance.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/binance.png"
                class="img-fluid"
                alt="Binance"
                title="Binance"
                width="32"
                height="32"
              />
            </picture>
            <h5>BINANCE</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/polygon.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/about/polygon.png"
              />
              <img
              loading="lazy"
              data-src="assets-new/images/about/polygon.png"
                class="img-fluid"
                alt="Polygon"
                title="Polygon"
                width="32"
                height="32"
              />
            </picture>
            <h5>POLYGON</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/avalanche.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/avalanche.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/avalanche.png"
                class="img-fluid"
                alt="Avalanche"
                title="Avalanche"
                width="32"
                height="32"
              />
            </picture>
            <h5>AVALANCHE</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/fantom.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/fantom.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/fantom.png"
                class="img-fluid"
                alt="Fantom"
                title="Fantom"
                width="32"
                height="32"
              />
            </picture>
            <h5>FANTOM</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/moonbeam.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/about/moonbeam.png"
              />
              <img
              loading="lazy"
              data-src="assets-new/images/about/moonbeam.png"
                class="img-fluid"
                alt="Moonbeam"
                title="Moonbeam"
                width="32"
                height="32"
              />
            </picture>
            <h5>MOONBEAM</h5>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/moonriver.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/moonriver.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/moonriver.png"
                class="img-fluid"
                alt="Moonriver"
                title="Moonriver"
                width="32"
                height="32"
              />
            </picture>
            <h5>MOONRIVER</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/cronos.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/cronos.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/cronos.png"
                class="img-fluid"
                alt="Cronos"
                title="Cronos"
                width="32"
                height="32"
              />
            </picture>
            <h5>CRONOS</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/harmony.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/harmony.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/harmony.png"
                class="img-fluid"
                alt="Harmony"
                title="Harmony"
                width="32"
                height="32"
              />
            </picture>
            <h5>HARMONY</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/arbitrum.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/arbitrum.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/arbitrum.png"
                class="img-fluid"
                alt="Arbitrum"
                title="Arbitrum"
                width="32"
                height="32"
              />
            </picture>
            <h5>ARBITRUM</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/optimism.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/optimism.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/optimism.png"
                class="img-fluid"
                alt="Optimism"
                title="Optimism"
                width="32"
                height="32"
              />
            </picture>
            <h5>OPTIMISM</h5>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/klaytn.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/klaytn.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/klaytn.png"
                class="img-fluid"
                alt="Klaytn"
                title="Klaytn"
                width="32"
                height="32"
              />
            </picture>
            <h5>KLAYTN</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/celo.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/celo.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/celo.png"
                class="img-fluid"
                alt="Celo"
                title="Celo"
                width="32"
                height="32"
              />
            </picture>
            <h5>CELO</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/kucoin.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/kucoin.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/kucoin.png"
                class="img-fluid"
                alt="Kucoin"
                title="Kucoin"
                width="32"
                height="32"
              />
            </picture>
            <h5>KUCOIN</h5>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-6">
          <div class="logo-item">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/tron.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/about/tron.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/tron.png"
                class="img-fluid"
                alt="Tron"
                title="Tron"
                width="32"
                height="32"
              />
            </picture>
            <h5>TRON</h5>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>
<!-- About Platform -->
<!-- About Gallery -->
<section class="main-about-carousel">
  <div class="violet-vector"></div>
  <div class="row">
    <div class="logo-carousel-list-alt">
      <owl-carousel-o [options]="aboutOptions">
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel1">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/1.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/1.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/1.jpg"
                alt="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/2.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/2.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/2.jpg"
                alt="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/3.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/3.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/3.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel2">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/4.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/4.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/4.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/5.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/5.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/5.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/6.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/6.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/6.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel3">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/7.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/7.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/7.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/8.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/8.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/8.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/9.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/9.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/9.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel4">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/10.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/10.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/10.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/11.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/11.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/11.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/12.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/12.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/12.jpg"
                alt="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel5">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/16.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/16.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/16.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/14.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/14.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/14.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/15.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/15.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/15.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel6">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/1.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/1.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/1.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/17.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/17.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/17.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/18.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/18.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/18.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel1">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/6.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/6.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/6.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/9.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/9.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/9.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/5.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/5.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/5.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel2">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/7.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/7.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/7.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/11.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/11.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/11.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/3.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/3.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/3.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="main-list-item" id="carousel3">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/5.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/5.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/5.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/9.webp"
              />

              <source type="image/jpg" srcset="assets-new/images/about/9.jpg" />

              <img
              loading="lazy"
              data-src="assets-new/images/about/9.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/about/webp/15.webp"
              />

              <source
                type="image/jpg"
                srcset="assets-new/images/about/15.jpg"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/about/15.jpg"
                alt="avalnche"
                title="avalnche"
                class="img-fluid"
                width="230"
                height="140"
              />
            </picture>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>

<!-- <section class="about-gallery">
  <div class="container">
    <div class="row">
      <div class="row metmb-3 video-section-top">
        <div class="col-lg-5">
          <div class="metaverse_subheading">
            <h3 class="video-heading">
              Equip your business for success in the ever-growing tech landscape
            </h3>

            <div class="content-link">
              <a href="#">
                <span>View Demo</span>
                <img
                loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  class="img-fluid"
                  width="50"
                  height="15"
                  alt="Link-arrow"
                />
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-7 orders-1 video-meta-right">
          <div class="video_right_side">
            <img
            loading="lazy"
              src="assets-new/images/nft/nft-right.png"
              alt="Blockchainx-NFT-Demo"
              class="img-fluid"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-lg-7 orders-2 video-meta-left">
          <div class="video_left_side">
            <img
            loading="lazy"
              src="assets-new/images/nft/nft-left.png"
              alt="Blockchainx-NFT-Demo"
              class="img-fluid"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div class="col-lg-5 orders-1 video-left">
          <div class="metaverse_subheading">
            <h3 class="video-heading">
              Experience a faster path with blockchain innovation
            </h3>
            <div class="content-link">
              <a href="#">
                <span>View Demo</span>
                <img
                loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  class="img-fluid"
                  width="50"
                  height="15"
                  alt="Link-arrow"
                />
              </a>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- About Gallery -->
<!-- About Hyperledger -->
<section class="about-hyperledger">
  <div class="container">
    <div class="row">
      <div class="about_heading">
        <h3>
          We Spotlighted In

          <span><strong>Hyperledger Blockchain</strong> Showcase</span>
        </h3>
      </div>
    </div>
    <div class="row about-hyper">
      <div class="green-vector"></div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <p>
          BlockchainX is a leading hyperledger development company that offers
          the best hyperledger blockchain solutions and services to its clients
          in a highly secure and trustworthy environment. We built Eatzilla, the
          world's first blockchain-powered food delivery solution on Hyperledger
          Sawtooth. Eatzilla is a platform for online food ordering and delivery
          services. Our Hyperledger blockchain services are user-friendly, and
          we thoroughly build separate applications before integrating with the
          network whenever necessary.
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <p>
          We have a team of blockchain consultants and blockchain services
          experts to guide you through the best ways to use Hyperledger for
          business purposes. Irrespective of the size of your organization or
          the industry you are in, our blockchain development experts are
          experienced enough to see you through the hurdles. You should try it
          out since it's now a shining star on the market.
        </p>
        <div class="content-link">
          <a
            href="https://wiki.hyperledger.org/pages/viewpage.action?pageId=31198556"
            target="blank"
          >
            <span>Check us listed on Official Hyperledger site</span>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow-dark.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
          </a>
          <hr />
        </div>
      </div>
    </div>
    <div class="hyper-platform-list">
      <div class="orange-vector"></div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/hyperledger.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/hyperledger.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger.png" alt="Hyperledger" title="Hyperledger" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/caliber.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/caliber.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/caliber.png" alt="caliber" title="caliber" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/hyperledger-composer.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/hyperledger-composer.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-composer.png" alt="hyperledger composer" title="hyperledger composer" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/hyperledger-explorer.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/hyperledger-explorer.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/hyperledger-explorer.png" alt="hyperledger explorer" title="hyperledger explorer" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/sawtooth.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/sawtooth.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/sawtooth.png" alt="sawtooth" title="sawtooth" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/iroha.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/iroha.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/iroha.png" alt="iroha" title="iroha" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
          <picture>
            <source srcset="assets-new/images/home/stak/fabric.webp" type="image/webp" />
            <source srcset="assets-new/images/home/stak/fabric.png" type="image/png" />
            <img loading="lazy" data-src="assets-new/images/home/stak/fabric.png" alt="fabric" title="fabric" class="img-fluid py-3" width="auto" height="auto" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- About Hyperledger -->
<!-- <section class="discuss">
  <div class="container">
    <app-let-discuss></app-let-discuss>
  </div>
</section> -->
<!-- why_choose_us starts -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>Why BlockchainX ?</h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              We are a full-fledged development company extending top business
              solutions to our clients to mend their commercial setbacks. We
              deliver successful results at a faster pace with a cost-effective
              approach. Engage with us to build a scalable and secured smooth
              sector.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Read More</span>
                <picture *ngIf="isDarkmode">
                  <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png" srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
                </picture>
                <picture *ngIf="!isDarkmode">
                  <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png" srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png"
              />

              <img
              loading="lazy"
              data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="why-blockchainx"
                title="why-blockchainx"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- why_choose_us ends-->

<section class="section-service-main">
<app-blog></app-blog>
</section>

<section class="section-service-alt-padd">
<app-contactform></app-contactform>
</section>
