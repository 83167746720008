import { Component, OnInit } from '@angular/core';

interface Snowflake {
entity: any;
  size: number;
  style: {
    fontFamily: string;
    fontSize: string;
    color: string;
    zIndex: number;
    left: string;
    top: string;
  };
  sink: number; // Add this property
  posX: number; // Add this property
  posY: number; // Add this property
}

@Component({
  selector: 'app-snowfall',
  template: `
     <span *ngFor="let flake of snow" [style]="flake.style">
      {{ flake.entity }}
    </span>
  `,
  styleUrls: ['./snowfall.component.css']
})

export class SnowfallComponent implements OnInit {
  snowMax = 450;
  snowColor = ['#DDD', '#EEE'];
  snowEntity = '\u2022';
  snowSpeed = 0.75;
  snowMinSize = 8;
  snowMaxSize = 24;
  snowRefresh = 50;
  snowStyles =
    'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none; user-select: none;';
  snow: Snowflake[] = [];
  pos: number[] = [];
  coords: number[] = [];
  lefr: number[] = [];
  marginBottom: number;
  marginRight: number;

  ngOnInit() {
    this.initSnow();
  }

  initSnow() {
    const snowSize = this.snowMaxSize - this.snowMinSize;
    this.marginBottom = document.body.scrollHeight - 5;
    this.marginRight = document.body.clientWidth - 15;

    for (let i = 0; i <= this.snowMax; i++) {
      this.coords[i] = 0;
      this.lefr[i] = Math.random() * 15;
      this.pos[i] = 0.03 + Math.random() / 10;

      //this.snow[i] = {
      this.snowMax[i] = {
        size: this.randomise(snowSize) + this.snowMinSize,
        style: {
          fontFamily: 'inherit',
          fontSize: this.randomise(snowSize) + 'px',
          color: this.snowColor[this.randomise(this.snowColor.length)],
          zIndex: 1000,
          left: this.randomise(this.marginRight - this.snowMinSize) + 'px',
          top: this.randomise(2 * this.marginBottom - this.marginBottom - 2 * this.snowMinSize) + 'px',
        },
        sink: this.snowSpeed * (this.randomise(snowSize) + this.snowMinSize) / 5,
        posX: this.randomise(this.marginRight - this.snowMinSize),
        posY: this.randomise(2 * this.marginBottom - this.marginBottom - 2 * this.snowMinSize),
      };
    }

    this.moveSnow();
  }

  resize() {
    this.marginBottom = document.body.scrollHeight - 5;
    this.marginRight = document.body.clientWidth - 15;
  }

  moveSnow() {
    for (let i = 0; i <= this.snowMax; i++) {
      this.coords[i] += this.pos[i];
      this.snow[i].posY += this.snow[i].sink;
      this.snow[i].style.left = this.snow[i].posX + this.lefr[i] * Math.sin(this.coords[i]) + 'px';
      this.snow[i].style.top = this.snow[i].posY + 'px';

      if (
        this.snow[i].posY >= this.marginBottom - 2 * this.snow[i].size ||
        parseInt(this.snow[i].style.left) > this.marginRight - 3 * this.lefr[i]
      ) {
        this.snow[i].posX = this.randomise(this.marginRight - this.snow[i].size);
        this.snow[i].posY = 0;
      }
    }

    setTimeout(() => this.moveSnow(), this.snowRefresh);
  }

  randomise(range: number) {
    return Math.floor(range * Math.random());
  }
}
