import { Component, OnInit } from '@angular/core';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';


@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.css']
})
export class FooterNewComponent implements OnInit {
  isDarkmode: Boolean=false;
  constructor(private footerservice: FooterServiceService,) {}

  ngOnInit(): void {
    
    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    })
   
  }
  backtotop() {
    window.scrollTo(0, 0);
  }
}
