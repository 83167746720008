<section class="banners-section banners-section-new">
    <div class="banner_wrapper banner-new">
      <div class="container" id="common-relative">
        <div class="green-vector"></div>
        <div class="row text-center justify-content-center">
          <div class="col-lg-12">
            <div class="banner_contentWrapper justify-content-center">
              <div class="caption">
                <h1>
                    Explore a new realm <br> of decentralized innovation
                </h1>
                <p class="pe-0 fw-normal">
                    Our creative blockchain expertise equips businesses in the most reliable manner. <br> It’s secured, alongside withholds supremacy among other contenders.
                </p>
  
                <div class="contact-link justify-content-center mt-2">
                  <a [routerLink]="['/contact']" class="home-link fw-semibold d-flex align-items-center justify-content-center">Talk to our
                    Experts</a>
                  <a [routerLink]="['/contact']" class="home-link-alt fw-semibold d-flex align-items-center justify-content-center">Connect
                    with us!</a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-5 text-center d-lg-block d-none">
            <div class="banner-right-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                  assets-new/images/home-v2/blockchain-development-376.webp 1199w,
                  assets-new/images/home-v2/blockchain-development-451.webp 1399w,
                  assets-new/images/home-v2/blockchain-development.webp 1920w
                  "
                />
                <source
                  type="image/jpg"
                  srcset="
                  assets-new/images/home-v2/blockchain-development-376.jpg 1199w,
                  assets-new/images/home-v2/blockchain-development-451.jpg 1399w,
                  assets-new/images/home-v2/blockchain-development.jpg 1920w
                  "
                />
                <img 
                loading="lazy"
                src="assets-new/images/home-v2/blockchain-development.jpg"
                width="500"
                height="500"
                alt="BlockchainX"
                title="BlockchainX"
                class="img-fluid"
                  *ngIf="!isDarkmode" />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset=" assets-new/images/home-v2/light-theme/blockchain-development-dark.webp">
                <source type="image/jpg"
                  srcset=" assets-new/images/home-v2/light-theme/blockchain-development-dark.jpg">
                <img loading="lazy"
                  data-src=" assets-new/images/home-v2/light-theme/blockchain-development-dark.jpg"
                  width="500"
                height="500"
                alt="BlockchainX"
                title="BlockchainX"
                class="img-fluid"
                  *ngIf="isDarkmode" />
              </picture>
            </div>
          </div> -->
        </div>
        <!-- <div class="row d-lg-block d-none" #about>
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon" />
            </picture>
          </div>
        </div> -->
      </div>
    </div>
  </section>