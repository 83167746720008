import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';

@Component({
  selector: 'app-blockchain-development-cost',
  templateUrl: './blockchain-development-cost.component.html',
  styleUrls: ['./blockchain-development-cost.component.css']
})
export class BlockchainDevelopmentCostComponent implements OnInit {

  selectedLink:number;
  title =
    'Cost of Blockchain Development In 2024';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'As blockchain is landing on every sector, curiosity is high here, but before diving deep, check this guide on Blockchain development costs to get more clarity.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'blockchain development cost, cost of blockchain development, blockchain game development cost, Blockchain app development cost, smart contract development cost, cost of implementing blockchain, dapp development cost',
    });
              

    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Cost of Blockchain Development In 2024',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/blockchain-development-cost/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'As blockchain is landing on every sector, curiosity is high here, but before diving deep, check this guide on Blockchain development costs to get more clarity.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/blockchain-development-cost/blockchain-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/blockchain-development-cost/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Cost of Blockchain Development In 2024',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'As blockchain is landing on every sector, curiosity is high here, but before diving deep, check this guide on Blockchain development costs to get more clarity.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://blockchainx.tech/assets-new/images/blog/blockchain-development-cost/blockchain-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/blockchain-development-cost/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/blockchain-development-cost.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
