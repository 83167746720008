<!-- Why Choose Us -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section">
            <h4>Why</h4>
            <h3>
              Block<span class="d-block"
                >ChainX<span class="dots">.</span></span
              >
            </h3>
          </div>
          <div class="whysection_text_wrap">
            <p>
              We extend our metaverse development services to almost every
              sector. Our expertise in decentralized development with our
              hands-on experience in field knowledge keeps us going further. The
              ability to learn takes us close to the market and its operations.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Learn More</span>
                <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="why-blockchainX"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Us -->
