<!-- Banner Section -->
<section class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1>
                  <span><strong>DePIN </strong> Development Company</span
                  >
                </h1>

                <p>
                    Blockchain reward systems and decentralized physical infrastructure networks interlock structural formation in the real world with the advent of traceability, efficiency, and performance.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                      <span>Telegram</span>
                      <picture>
                        <source type="image/webp"
                          srcset="assets-new/images/right-arrow.webp">
                        <source type="image/png"
                          srcset="assets-new/images/right-arrow.png">
                        <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                          width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                           />
                      </picture>
                      <picture>
                        <source type="image/webp"
                          srcset="assets-new/images/right-arrow-dark.webp">
                        <source type="image/png"
                          srcset="assets-new/images/right-arrow-dark.png">
                        <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                          width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                           />
                      </picture>
                    </a>
                  </div>
                  <div class="content-link">
                    <a [routerLink]="['/contact']">
                      <span>Contact Us</span>
                      <picture>
                        <source type="image/webp"
                          srcset="assets-new/images/right-arrow.webp">
                        <source type="image/png"
                          srcset="assets-new/images/right-arrow.png">
                        <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                          width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                           />
                      </picture>
                      <picture>
                        <source type="image/webp"
                          srcset="assets-new/images/right-arrow-dark.webp">
                        <source type="image/png"
                          srcset="assets-new/images/right-arrow-dark.png">
                        <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                          width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                           />
                      </picture>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 text-center">
            <div class="banner-right-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/depin-development-company/depin-development-company.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/depin-development-company/depin-development-company.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/depin-development-company/depin-development-company.png"
                  width="500"
                  height="500"
                  class="img-fluid"
                  alt="DePIN Development Company"
                  title="DePIN Development Company"

                />
              </picture>
            </div>
          </div>
        </div>

        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img loading="lazy"

                data-data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>

      </div>
    </div>
</section>
<!-- Banner Section ends -->

<!-- About Start -->
<section class="section-service-alt" #about>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 mx-auto col-12 order-lg-first order-last">
          <div class="bep-work-section text-center pb-0">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/depin-development-company/depln-solution-company.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/depin-development-company/depln-solution-company.png" />

              <img loading="lazy"
                data-src="assets-new/images/depin-development-company/depln-solution-company.png"
                class="img-fluid"
                title="DePIN Solution Company Pitch In"
                alt="DePIN Solution Company Pitch In"
                width="500"
                height="500"
                 />
            </picture>
          </div>
        </div>
        <div class="col-lg-6 col-12 about-content">
          <div class="common-sub-heading">
            <h2 class="main-sub-heading"><span>Different Sectors </span> DePIN Solution Company Pitch In</h2>
            <p>
                DePIN development aims at maximizing the efficiency of real-world physical infrastructures backed by decentralization and defined smart contracts that could track, trace, and maintain transparency. An efficient technologically backed environment decentralized Physical infrastructure network amplifies on streamlining resources to distribution all in one.
            </p>
          </div>
          <div class="row mt-lg-4 mt-3">
            <div class="col-md-6">
              <ul class="investment-para">
                <li>
                    Wireless
                </li>
                <li>
                    Geospatial
                </li>
                <li>
                    Mobility
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="investment-para">
                <li>
                    Healthcare Innovation
                </li>
                <li>
                    Educational Access
                </li>
                <li>
                    Energy Distribution
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- About End -->

<!-- Decentralized Physical Start -->
<section class="section-service-alt-main">
    <div class="types-of-erc">
      <div class="container">
        <div class="row sm-pb-4 pb-2">
          <div class="metaverse_heading mx-auto">
            <h3> <span>Decentralized Physical </span> Infrastructure Network Services
            </h3>
          </div>
        </div>
        <div class="row types-of-token-detail justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/consulting-services.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/consulting-services.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/consulting-services.png"
                    class="img-fluid"
                    title="DePIN Consulting Services"
                    alt="DePIN Consulting Services"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">DePIN Consulting Services</h4>
              <p>
                Providing end-to-end consulting service to better and breed your idea.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/depln-tokenization-services.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/depln-tokenization-services.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/depln-tokenization-services.png"
                    class="img-fluid"
                    title="DePIN Tokenization  Services"
                    alt="DePIN Tokenization  Services"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">DePIN Tokenization  Services</h4>
              <p>
                Tokenizing DePIN services and infrastructure in the blockchain space.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/native-token-development.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/native-token-development.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/native-token-development.png"
                    class="img-fluid"
                    title="Native Token Development"
                    alt="Native Token Development"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Native Token Development</h4>
              <p>
                Develop a unique native token for the DePIN services you extend.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/smart-contract.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/smart-contract.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/smart-contract.png"
                    class="img-fluid"
                    title="Smart Contract"
                    alt="Smart Contract"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Smart Contract</h4>
              <p>
                Supportive and well-defined smart contracts backing your business operations.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/depin-dapp.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/depin-dapp.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/depin-dapp.png"
                    class="img-fluid"
                    title="DePIN Dapp"
                    alt="DePIN Dapp"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">DePIN Dapp</h4>
              <p>
                Render your DePIN services through a completely decentralized platform.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/depln-api-development.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/depln-api-development.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/depln-api-development.png"
                    class="img-fluid"
                    title="DePIN API Development"
                    alt="DePIN API Development"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">DePIN API Development</h4>
              <p>
                Connecting decentralized solutions with IoT devices to embark on true power.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/security-audit.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/security-audit.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/security-audit.png"
                    class="img-fluid"
                    title="Security Audit"
                    alt="Security Audit"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Security Audit</h4>
              <p>
                DePIN security audit ensures the tokens developed are thoroughly checked.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/depin-development-company/performance-optimization.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/depin-development-company/performance-optimization.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/depin-development-company/performance-optimization.png"
                    class="img-fluid"
                    title="Performance Optimization"
                    alt="Performance Optimization"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Performance Optimization</h4>
              <p>
                Track and optimize your operations based on performance and service usage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- End -->

<!-- Pros of investing starts -->
<section class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3><span>Different Categories </span> Of DePIN Services</h3>
          </div>
        </div>
      </div>
      <div class="row erc-about-content">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/depin-development-company/cloud-storage-networks.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/depin-development-company/cloud-storage-networks.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/depin-development-company/cloud-storage-networks.png"
                class="img-fluid"
                alt="Cloud Storage Networks/Server Networks"
                title="Cloud Storage Networks/Server Networks"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Cloud Storage Networks/Server Networks</h4>
            <p class="text-center">
              These networks are secure and efficient means to transfer data. By offering users the ability to store and access data remotely, DPIN Services, through cloud storage or a server, play a vital role.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/depin-development-company/energy-networks.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/depin-development-company/energy-networks.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/depin-development-company/energy-networks.png"
                class="img-fluid"
                alt="Energy Networks"
                title="Energy Networks"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Energy Networks</h4>
            <p class="text-center">
              Focusing on optimizing and managing energy usage, utilizing smart technologies to monitor and control, and facilitating the analysis of energy consumption at home and in business with the most sustainable approach.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/depin-development-company/sensor-networks.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/depin-development-company/sensor-networks.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/depin-development-company/sensor-networks.png"
                class="img-fluid"
                alt="Sensor Networks"
                title="Sensor Networks"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Sensor Networks</h4>
            <p class="text-center">
              Sensor networks' attractive nature is that they collect data on energy consumption patterns and are immune to automated adjustments. Sensor networks optimize energy usage based on occupancy, weather, and other factors.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/depin-development-company/wireless-networks.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/depin-development-company/wireless-networks.png"
              />

              <img loading="lazy"
              data-src="assets-new/images/depin-development-company/wireless-networks.png"
                alt="Wireless Networks"
                title="Wireless Networks"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Wireless Networks</h4>
            <p class="text-center">
              With real-time monitoring systems and energy control usage, harnessing wireless networks operates more efficiently and effectively and saves operational costs as the emission of carbon is reduced.
            </p>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- ends -->

<!-- Platform Features Start -->
<section class="section-service-main">
    <div class="types-of-erc">
      <div class="container">
        <div class="row sm-pb-4 pb-2">
          <div class="metaverse_heading mx-auto">
            <h3> Benefits Of
              <span> Decentralized Physical Infrastructure </span> Network Solutions
            </h3>
          </div>
        </div>
        <div class="row types-of-token-detail feature-space">
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/horizontal-scalability.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/horizontal-scalability.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/horizontal-scalability.png"
                              class="img-fluid"
                              title="Horizontal scalability"
                              alt="Horizontal scalability"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Horizontal scalability</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/experts-in-the-field.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/experts-in-the-field.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/experts-in-the-field.png"
                              class="img-fluid"
                              title="Community control"
                              alt="Community control"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Community control</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/fair-pricing.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/fair-pricing.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/fair-pricing.png"
                              class="img-fluid"
                              title="Fair pricing"
                              alt="Fair pricing"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Fair pricing</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/cost-efficient-operation.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/cost-efficient-operation.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/cost-efficient-operation.png"
                              class="img-fluid"
                              title="Cost-efficient operation"
                              alt="Cost-efficient operation"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Cost-efficient operation</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/development.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/development.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/development.png"
                              class="img-fluid"
                              title="Permissionless participation"
                              alt="Permissionless participation"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Permissionless participation</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/transparency.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/transparency.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/transparency.png"
                              class="img-fluid"
                              title="Transparency"
                              alt="Transparency"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Transparency</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/horizontal-scalability.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/horizontal-scalability.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/horizontal-scalability.png"
                              class="img-fluid"
                              title="Efficiency in Operation"
                              alt="Efficiency in Operation"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Efficiency in Operation</h4>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-6">
                <div class="pro-feature">
                    <div class="pro-feature-img">
                        <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/depin-development-company/track-on-transaction.webp" />

                            <source
                              type="image/png"
                              srcset="assets-new/images/depin-development-company/track-on-transaction.png" />

                            <img loading="lazy"
                              data-src="assets-new/images/depin-development-company/track-on-transaction.png"
                              class="img-fluid"
                              title="Track on transaction"
                              alt="Track on transaction"
                              width="34"
                              height="34"
                               />
                          </picture>
                    </div>
                    <h4 class="erc-20-head-alt">Track on transaction</h4>
                </div>
            </div>
        </div>
      </div>
    </div>
</section>
<!-- End -->

<!-- Working On DePIN starts-->
<section class="section-service-alt working-depin">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>Working On <span> DePIN Development</span></h3>
        </div>
      </div>
      <div class="row acc-sec-list">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-image text-center">
            <div class="casetudies_common">
          <picture>
            <source
              type="image/webp"
              srcset="
              assets-new/images/depin-development-company/decentralized-exchange.webp
              "
            />

            <source
              type="image/png"
              srcset="
              assets-new/images/depin-development-company/decentralized-exchange.png
              "
            />

            <img loading="lazy"
              data-src="assets-new/images/depin-development-company/decentralized-exchange.png"
              width="500"
              height="500"
              class="img-fluid"
              alt="Working On DePIN Development"
              title="Working On DePIN Development"
            />
          </picture>
        </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                Managed through blockchain with high-level automation
                </button>
              </div>
              <!-- <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    In terms of advancing and heading further, we render our support that backs you technically.
                  </p>
                </div>
              </div> -->
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                Reduces manual intervention while all records are automatically noted
                </button>
              </div>
              <!-- <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    We are open to having ideation sessions to get your raw points shaped into a potential project on the market.
                  </p>
                </div>
              </div> -->
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                Based on the contracts the transactions are classified under niche
                </button>
              </div>
              <!-- <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Concerning any sort of integration, from multi-chain to wallet and other additional operations, we are open.
                  </p>
                </div>
              </div> -->
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                DePIN development ensures secure information and saves from breaching
                </button>
              </div>
              <!-- <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Every user has an equal opportunity to buy tokens in an IDO,
                    regardless of whether they are a small-time trader or a huge
                    short.
                  </p>
                </div>
              </div> -->
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                From sourcing, and production to sales and returns everything is automated
                </button>
              </div>
              <!-- <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    It offers a transparent decentralized fundraising approach
                    where anybody can see the results of the IDO token, the
                    startup list, and so on.
                  </p>
                </div>
              </div> -->
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                  Analytical tools foresee the future and manage supply and demand eventually
                  </button>
                </div>
                <!-- <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      It offers a transparent decentralized fundraising approach
                      where anybody can see the results of the IDO token, the
                      startup list, and so on.
                    </p>
                  </div>
                </div> -->
            </div>
            <div class="accordion-item">
                <div class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                  DePIN services also provide listing, trading, and staking opportunities
                  </button>
                </div>
                <!-- <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      It offers a transparent decentralized fundraising approach
                      where anybody can see the results of the IDO token, the
                      startup list, and so on.
                    </p>
                  </div>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- ends -->

<!-- Principles Start -->
<section class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5">
          <div class="c-animation">
            <div class="common-sub-heading">
              <h4>Principles <span class="dots">.</span></h4>
            </div>
            <h3 class="main-sub-heading">
                Various Decentralized Physical Infrastructure Network Solutions
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="green-vector"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>NFT Marketplace</h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                A demand and supply-based operation, the DePIN NFT marketplace goes ahead with its ability to monetize on digital and physical infrastructures by connecting service providers and customers.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Asset Tokenization <span class="d-block">Platform</span> </h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Now Tokenize your DePIN project entirely with the expertise of our Decentralized physical infrastructure network solution that makes real-world asset development more efficient, transparent, and secure.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>DePIN For <span class="d-block">Traceability</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                The advent of IoT in traceability along with blockchain interactions, streamlines production to distribution in every sector. Bridging this with DePIN empowers in shaping the physical infrastructure in the real world.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Private Blockchain <span class="d-block">Development</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Empower your business with private blockchain development to ease and control your operations efficiently and productively in the technologically advanced market and stay ahead of your competitors.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Responsiveness to client’s <span class="d-block">domain
                  requirements</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Our robust solutions from the hands of our experiment Blockchain
                development facilitators, will help you develop any token, in
                the standard that you call out. This includes NFTs, asset
                tokenization, DeFi, and others.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <span>Read More</span>
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15" />
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
</section>
<!-- Principles End -->

<!-- why-choose-section -->
<section class="section-service-alt">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-3">
            <h2>
                BlockchainX -  <span>Your DePIN Development Company</span>
            </h2>
            <p>
                When you choose BlockchainX, we ensure to render the best and most sustainable, technologically aided solution that empowers your operation smoothly and stead. Our DePIN development services mark transparency, and efficiency and connect you with wider stakeholders to bring your decentralized physical infractures live in action.
            </p>
            <!-- <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Read More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy"  src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
          </div>
          <!-- <div class="whysection_text_wrap">
            <ul>
              <li>Experts in creating crypto tokens in various blockchains</li>
              <li>6 Years of experience in token development service</li>
              <li>Our developers are skilled, equipped, and at the same time open to learning</li>
              <li>Provide crypto exchange provide ERC20 tokens with the highest quality standard</li>
            </ul>

            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Contact Us</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy"  src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div> -->
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/depin-development-company/blockchainx-your-depin-development-company.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/depin-development-company/blockchainx-your-depin-development-company.png" />

              <img loading="lazy"
                data-src="assets-new/images/depin-development-company/blockchainx-your-depin-development-company.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="BlockchainX -Your DePIN Development Company"
                title="BlockchainX -Your DePIN Development Company"
                 />
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-main">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionEx">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What is DePIN?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        DePIN is a decentralized Physical infrastructure network that is a blockchain application form of consensus mechanism and decentralization principles to improve the development of physical structures development.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    Which DePIN development company is best in its services?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        Ensure your DePIN development company strives to give you end-to-end support throughout your development phase, while they should also have hands-on experience in developing decentralized platforms. BlockchainX can render you our expertise in DePIN development.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    What are the benefits of DePIN development?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        The major benefits of opting DePIN development service are their ability to provide traceability and enhance transaction and immutable ledger guarding operations. This empowers building physical infrastructure more prominent and strong.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<!-- Contact Start -->
<section class="section-service-alt">
    <app-get-in-touch></app-get-in-touch>
  </section>
  <!-- Contact End -->

  <!-- Blog Start -->
<section class="section-service-main">
    <app-blog></app-blog>
  </section>
  <!-- Blog End -->
