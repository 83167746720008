import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from './../../shared/canonical.service';

@Component({
  selector: 'app-healthcare',
  templateUrl: './healthcare.component.html',
  styleUrls: ['./healthcare.component.css'],
})
export class HealthcareComponent implements OnInit {
  selectedLink:number;
  title =
    'Blockchain Application In Healthcare, The Boom Of The Future';
  constructor( 
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Engaging a versatile sector of users and customers, the healthcare industry interacts with vast data. Blockchain introduction in the Healthcare industry is a boom for the sector in terms of managing accuracy and transparency with utmost efficiency. Give a read to know in detail about blockchain technology in healthcare. ',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 
        'blockchain healthcare, blockchain technology in healthcare, blockchain technology for healthcare, application of blockchain in healthcare, blockchain and healthcare',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Blockchain In Healthcare Transforming Into An Efficiently Functioning Sectore',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/blockchain-application-in-healthcare/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Engaging a versatile sector of users and customers, the healthcare industry interacts with vast data. Blockchain introduction in the Healthcare industry is a boom for the sector in terms of managing accuracy and transparency with utmost efficiency. Give a read to know in detail about blockchain technology in healthcare. ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/industries/blockchain-in-health-care.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/blockchain-application-in-healthcare/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Blockchain In Healthcare Transforming Into An Efficiently Functioning Sector',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Engaging a versatile sector of users and customers, the healthcare industry interacts with vast data. Blockchain introduction in the Healthcare industry is a boom for the sector in terms of managing accuracy and transparency with utmost efficiency. Give a read to know in detail about blockchain technology in healthcare. ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/industries/blockchain-in-health-care.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/blockchain-application-in-healthcare/'
    );
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}
