<section class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                    Populaire<span><strong>Blockchain Development</strong></span> Company In France
                </h1>
                <!-- <div class="common-sub-heading-alt pb-2">Expanding Opportunities
                  For Real Assets</div> -->
                <p class="text-justify">
                    Blockchain leads the dynamic and super explorative market of technology, here as the popular blockchain development company in France we extend A-grade decentralized solutions.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a
                      href="/contact">
                      <span>Connect with our Efficient Team</span>
                      <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="mobile-app-page-form bitrix-snippet-form position-relative" id="consultation-form">
              <div class="form-title">
                <p class="h3">Book a Free <span>Consultation</span></p>
              </div>
              <div [innerHTML]="bitrixSnippetScript"></div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </section>

  <section class="section-service-alt">
    <div class="container">
      <div class="row mb-lg-5 mb-4">
        <div class="col-lg-10 mx-auto">
          <div class="whys_section mb-0 text-center">
            <h2 class="text-center pb-0 mb-3">
                BlockchainX Your Perfect <span>Blockchain Development</span>  Company In France
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 d-lg-block d-none">
          <div class="casetudies_common">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/home-v2/blockchain-app-development-process-includes.webp
                " />
              <source
                type="image/png"
                srcset="
                  assets-new/images/home-v2/blockchain-app-development-process-includes.png
                " />
              <img loading="lazy"
                data-src="assets-new/images/home-v2/blockchain-app-development-process-includes.png"
                width="500"
                height="500"
                class="img-fluid pb-md-0 pb-3"
                alt="Blockchain App Development Process Includes"
                title="Blockchain App Development Process Includes" />
            </picture>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/field.webp" />
  
                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/field.png" />
  
                <img loading="lazy"
                    data-src="assets-new/images/web3-development/field.png"
                    class="img-fluid"
                    alt="field"
                    width="70"
                    height="70" />
              </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Provides Advance Technology Insights</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p class="text-justify">
                    BlockchainX’s core value is to bring our client's imagination and vision to reality. Our in-house tech expert’s proficiency made us proud and earned the name of the leading blockchain development company in France.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/real-estate.webp" />
  
                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/real-estate.png" />
  
                <img loading="lazy"
                    data-src="assets-new/images/web3-development/real-estate.png"
                    class="img-fluid"
                    alt="Real estate"
                    width="70"
                    height="70" />
              </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Large Scale Business Operation</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p class="text-justify">
                    Irrespective of the size of the Industry our concentration lies in the progress of it through the decentralized blockchain-based solution. We provide solutions that address their concerns and make them easier to solve.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/education.webp" />
  
                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/education.png" />
  
                <img loading="lazy"
                    data-src="assets-new/images/web3-development/education.png"
                    class="img-fluid"
                    alt="education"
                    width="70"
                    height="70" />
              </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Contributing to the Growth of the Sector</h3>
                  </div>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    Blockchain has spread all over, our main purpose as the best Blockchain development company in France is to collaborate and work with the maximum sector and contribute to the growth and efficiency of the platform. 
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
                <picture>
                  <source
                      type="image/webp"
                      srcset="assets-new/images/web3-development/fashion.webp" />
    
                  <source
                      type="image/png"
                      srcset="assets-new/images/web3-development/fashion.png" />
    
                  <img loading="lazy"
                      data-src="assets-new/images/web3-development/fashion.png"
                      class="img-fluid"
                      alt="fashion"
                      width="70"
                      height="70" />
                </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Real-World Use Cases</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p class="text-justify">
                    Blockchain technology is branching out almost everywhere with the ability to regulate, secure and be transparent in all possibilities. We help you to better utilize your ideas and expand the utility of real world assets in the digital space with Blockchain development services.  From real time production tracking, to end customer feedback, our blockchain services extend to all sectors.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
                <picture>
                  <source
                      type="image/webp"
                      srcset="assets-new/images/web3-development/game-development.webp" />
    
                  <source
                      type="image/png"
                      srcset="assets-new/images/web3-development/game-development.png" />
    
                  <img loading="lazy"
                      data-src="assets-new/images/web3-development/game-development.png"
                      class="img-fluid"
                      alt="game development"
                      width="70"
                      height="70" />
                </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Innovative Use Cases to Life</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p class="text-justify">
                    From Gaming to education, our services expand in and out with blockchain development, we deploy DeFi solutions, crypto token development services, metaverse development, and almost every decentralized app that benefits you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
                <picture>
                  <source
                      type="image/webp"
                      srcset="assets-new/images/web3-development/marketing.webp" />
    
                  <source
                      type="image/png"
                      srcset="assets-new/images/web3-development/marketing.png" />
    
                  <img loading="lazy"
                      data-src="assets-new/images/web3-development/marketing.png"
                      class="img-fluid"
                      alt="Marketing"
                      width="70"
                      height="70" />
                </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Timely Support and Guidance</h3>
                  </div>
                </div>
                <div class="casestudy_para border-0">
                  <p class="text-justify">
                    At Our blockchain development company in France, we are keen on time and precession, we provide you with timely deployment and Dapp launch. Eventually, our expert consultations navigate you in the right direction. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-sm-2 col-3">
              <div class="d-flex justify-content-end">
                <picture>
                  <source
                      type="image/webp"
                      srcset="assets-new/images/web3-development/field.webp" />
    
                  <source
                      type="image/png"
                      srcset="assets-new/images/web3-development/field.png" />
    
                  <img loading="lazy"
                      data-src="assets-new/images/web3-development/field.png"
                      class="img-fluid"
                      alt="Marketing"
                      width="70"
                      height="70" />
                </picture>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3>Customized Blockchain Solutions</h3>
                  </div>
                </div>
                <div class="casestudy_para border-0">
                  <p class="text-justify">
                    We believe every client's requirements might be somewhere similar, but there is a unique solution they are looking to solve. And we build customized solutions from your unique perspective and help you progress. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </section>

  <section class="section-service-main p-0">
    <div class="meta-expert">
      <div class="violet-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="our_experts">
              <h2><span>Amplify your ROI in crypto with a Blockchain </span> development company in France like us.</h2>
              <div class="text-center my-4">
                <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Take A Look at our Demo platforms.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </section>

  <div class="section-service-alt" #about>
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/interplanetary-file-system-blockchain.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="IPFS Blockchain Development Services"
                title="IPFS Blockchain Development Services" />
            </picture>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">
                Premier Tokenization Services in France
            </h3>
            <p class="text-justify">
                We at BlockchainX, provide customized token development service in France. From NFT tokenization, which includes photos, videos, GIFs, Real estate, gaming attributes, and more the potential of NFT tokenization is massive. Eventually, we also contribute in developing crypto tokens for your exclusive needs, for governing, staking, reward, stable tokens, etc, in our Blockchain development company in France in the choice of network you decide. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-service-main">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Top ICO Services Provider in France</h3>
            <p class="text-justify">
                ICO Services in France has a huge demand, as the coexistence of different sectors takes the initiative to make advancements and progress. Where ICO plays a vital role. As a blockchain development company in France, we provide ICO token development services, ICO platform for selling tokens and bringing investors for your projects. Based on your requirements our development focuses more keen on delivering your needs through our custom-developed blockchain solutions. 
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/why-IPFS.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Why IPFS Development Services"
                title="Why IPFS Development Services" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="section-service-alt">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Cutting-Edge DApps Development Company in France</h3>
            <p class="text-justify">
                The most unique aspect of our Blockchain development company in France is that we extend end-to-end encrypted, robust, and full-fledged development solutions. From token development to Decentralised Application development our services are designed to your unique craft and execute based on your needs. With advanced tech integrations, we can also level up your platform and support high-end operation all in one platform, at ease in a single ecosystem built on decentralized networks. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-service-main">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Expert Smart Contracts Development in France</h3>
            <p class="text-justify">
                Smart contracts the backbone of any decentralized platform, our expert smart contract developer at our blockchain development company in France extends top class professional support. From designing your smart contract, ensuring this workflow, integrating in your Dapp, conducting audit, smart contract report generation, and upgrading your smart contract - we render a complete package of services that backs your operation, brings trust, and builds credibility around your services. 
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/why-IPFS.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Why IPFS Development Services"
                title="Why IPFS Development Services" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section-service-alt">
    <div class="container">
      <div class="call-to-main">
          <div class="orange-vector"></div>
        <div class="row justify-content-center mb-5">
          <div class="col-lg-10">
            <div class="metaverse_heading">
              <h3><span>Lead Your Sector,</span> Avail Our Blockchain Development Services </h3>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-lg-12 text-center">
            <!-- <p class="mb-sm-4 mb-3">
              The process of converting an asset’s ownership rights into digital
              tokens on a blockchain network is Asset tokenization. On the other
              hand, the process of selling tokens in the marketplace to raise funds
              for a particular project or business is a Token sale.
            </p> -->
            <p class="mb-5">
                Take your business hassle-free in Blockchain which is more secure and efficient. 
            </p>
            <div class="token-vs-btn">
              <a href="https://wa.me/7708889555" class="our_expert_talk"
                target="_blank"
                rel="noopener noreferrer">Contact to Upgrade Now</a>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  
  </section>

  <div class="section-service-main">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Trusted Blockchain Development Specialists in France</h3>
            <p class="text-justify">
                We are a trusted Blockchain development company in France with specialized developers, who are skilled and equipped with the required hands-on experience. From writing technically strong effective codes, auditing smart contracts, ensuring features are effectively placed, and making the blockchain solution more effective and seamless, they take complete ownership in delivering the right solution on time and of course, they are enterprise-grade solutions competing with the market demand and expectation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="section-service-alt">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Improved Layer 1 and Layer 2 Blockchain Solutions in France</h3>
            <p class="text-justify">
                Layer 1 and Layer 2 blockchains is introduced to reduce the over crowding happening in the networks. By choosing to develop much-advanced layer 1 and 2 blockchains with us, there is a whole lot of chance to develop and build your ecosystem. As a blockchain development company in France, we provide you with customized support that well fits your market and target audience to captivate attention and investment for the same. With layer 1 and 2 development we ensure to develop a secured, well-encrypted, and efficient solution for your business. 
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/why-IPFS.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Why IPFS Development Services"
                title="Why IPFS Development Services" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-service-main">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />
  
              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />
  
              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Top Cryptocurrency Development Company in France</h3>
            <p class="text-justify">
                Cryptocurrencies redefine the value of money today, and many more different use cases can be employed today. Our Cryptocurrency development services include token development, crypto token integrations, Version 2 upgrades, development based on use and utility, and much more. We provide tailored cryptocurrency development services at our blockchain development company in France, which is customized for your needs and helps in the progress of your business and project eventually. This helps with fundraising, bringing more investors, and increasing the liquidity of your projects. Connect with us to get your crypto token, developed with us now!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section-service-alt">
    <div class="container">
      <div class="build-web3-solution">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="title-wrap text-center">
              <h2 id="item-5"
              [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">
                <span>Tech Stack We Use </span> As Blockchain Development Company In France
                <span class="dots"></span>
              </h2>
            </div>
          </div>
        </div>
        <div class="row mb-3 ">
          <div class="col-lg-12">
            <div class="tech-stack-details ">
              <ul class="techstack d-flex flex-column flex-sm-row justify-content-center align-items-center gap-3">
                <li class="our_expert_talk tech-list" *ngFor="let techmenu of technologystack">
                  <p
                    (click)="opentechnology(techmenu)"
                    [ngClass]="{ 'actives-tech': techlisted === techmenu }"
                    >{{ techmenu }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="techlisted === 'Blockchains Platform'">
              <div class="row d-flex justify-content-center align-items-center g-4">
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>
                    <p>Ethereum</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>BNB Smart Chain</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Polygon</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Arbitrum</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Optimism</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Avalanche</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>MoonBeam</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Solana</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Hyperledger</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Corda</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="techlisted === 'Other Platform'">
              <div class="row d-flex justify-content-center align-items-center g-4">
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>HardHat</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Solidity</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Rust</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Anchor</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Moralis</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="section-service-main">
    <div class="types-of-erc">
        <div class="container">
            <div class="row">
                <h3 class="main-sub-heading text-center pb-0"> Our 
                    <span> Blockchain Solutions</span> Transforming The Key Industries In France
                </h3>
                <!-- <p>We Provide Tailored Web3 Development for Any</p> -->
            </div>
            <div
                class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 types-of-token-detail justify-content-center industry-web">
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/field.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/field.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/field.png"
                                    class="img-fluid"
                                    alt="Field"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Fintech - Digital Payments</h4>
                        <p>
                            Digital payments are getting familiar, and Blockchain interventions make it more functional. Extensive blockchain-based fintech solutions at our blockchain development company in France are provided to make transactions seamless and accurate.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/real-estate.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/real-estate.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/real-estate.png"
                                    class="img-fluid"
                                    alt="Real Estate"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">HealthTech</h4>
                        <p>
                            Healthcare is a complex and critical industry handling data, information, and a whole bunch of records altogether. Bringing Blockchian can solve the complex of data management operations, and its transparency hastens the process effectively. 
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/education.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/education.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/education.png"
                                    class="img-fluid"
                                    alt="Education"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Sustainable Agriculture</h4>
                        <p>
                            Agriculture is the primary sector, which requires keen and close monitoring, with blockchain interventions, this is made easy and simplified. Eventually, its efficiency contributes towards Sustainable growth.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/fashion.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/fashion.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/fashion.png"
                                    class="img-fluid"
                                    alt="Fashion"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Smart Cities and IoT</h4>
                        <p>
                            As a blockchain development company in France, this niche is the most exciting part, as the county's infrastructural development can be better and more exciting with the combination of IoT as Smart city projects. 
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/marketing.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/marketing.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/marketing.png"
                                    class="img-fluid"
                                    alt="Marketing"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Aerospace and Advanced Manufacturing</h4>
                        <p>
                            Blockchain technology has now entered the market of Advanced manufacturing, giving future-based suggestions, and contributing to Aerospace research and execution with its immutable ability. 
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/game-development.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/game-development.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/game-development.png"
                                    class="img-fluid"
                                    alt="Game Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Green Tech</h4>
                        <p>
                            As the rush is around sustainable development growth is more emphasized Green tech as is adopted with blockchain and decentralized norms can make things better in the interim of data tracking and understanding the uses better.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/e-commerce.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/e-commerce.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/e-commerce.png"
                                    class="img-fluid"
                                    alt="E-commerce"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">E-commerce</h4>
                        <p>
                            All around the world, E-commerce is a day-to-day business with a humpty of transactions, and taking that over Blockchain makes it easy and smooth to interact, track and analyze better, in a market like France.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail  text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/fintech.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/fintech.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/fintech.png"
                                    class="img-fluid"
                                    alt="FinTech"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">EdTech</h4>
                        <p>
                            With advancement, the Education industry is getting creatively engaging, while technology plays a vital role. Here blockchain integration shall encompass the enhancement of the learners' experience.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="section-service-alt">
    <div class="types-of-erc text-center">
        <div class="container">
            <div class="build-web3-solution">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-10">
                        <h3 class="main-sub-heading text-center">Embark your Web3 journey with our Customized   
                            <span>Blockchain Development Service in France</span>
                        </h3>
                    </div>
                </div>
                <p>
                    We provide Versatile Web3 Solutions For your varied Operational needs, so all you have to do is,  
                </p>
                <div class="discuss-link">
                    <a href="/contact"><span>Connect with our Blockchain Development Company</span>
                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

  <section class="section-service-alt">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-10 mx-auto">
          <div class="whys_section mb-0 text-center">
            <h2 class="text-center pb-0 mb-3">
              Client <span> Success Stories </span>
            </h2>
            <p>
              Catch up on our client's success stories at BlockchainX. We have
              worked with various clients and on various projects in blockchain
              platform development. And here are some of the top projects in which
              we were involved.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="client-success">
            <owl-carousel-o [options]="clientSuccess">
              <ng-template carouselSlide>
                <div class="row pb-lg-3 pb-0">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Farmsent</h3>
                      <p class="pb-lg-3 pb-0">
                        Agriculture-based blockchain business solution. Connecting
                        farmers and customers directly benefits both in terms of
                        quality, price, and profit. Farmsent drives the
                        agriculture sector to the forefront with transparency and
                        agility.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <!-- <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/farmsent.webp"
                      />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/farmsent.png"
                      />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/farmsent.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Farmsent"
                        title="Farmsent"
  
                      />
                    </picture> -->
  
                    <picture>
                      <source
                        srcset="
                        assets-new/images/home-v2/farmsent-400.webp 400w,
                        assets-new/images/home-v2/farmsent-600.webp 600w,
                        assets-new/images/home-v2/farmsent-800.webp 800w,
                        assets-new/images/home-v2/farmsent-1000.webp 1000w,
                        assets-new/images/home-v2/farmsent-1200.webp 1200w,
                        assets-new/images/home-v2/farmsent-1400.webp 1400w,
                        assets-new/images/home-v2/farmsent-1600.webp 1600w,
                        assets-new/images/home-v2/farmsent-1800.webp 1800w,
                        assets-new/images/home-v2/farmsent-2000.webp 2000w"
                        sizes="(min-width: 800px) 400px, 95vw"
                        type="image/webp" />
                      <source
                        srcset="
                        assets-new/images/home-v2/farmsent-400.png 400w,
                        assets-new/images/home-v2/farmsent-600.png 600w,
                        assets-new/images/home-v2/farmsent-800.png 800w,
                        assets-new/images/home-v2/farmsent-1000.png 1000w,
                        assets-new/images/home-v2/farmsent-1200.png 1200w,
                        assets-new/images/home-v2/farmsent-1400.png 1400w,
                        assets-new/images/home-v2/farmsent-1600.png 1600w,
                        assets-new/images/home-v2/farmsent-1800.png 1800w,
                        assets-new/images/home-v2/farmsent-2000.png 2000w"
                        sizes="(min-width: 800px) 400px, 95vw"
                        type="image/png" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/farmsent-400.png"
                        class="img-fluid pb-md-0 pb-4"
                        width="400"
                        height="237"
                        alt="Farmsent"
                        title="Farmsent" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Real World NFT</h3>
                      <p class="pb-lg-3 pb-0">
                        Tokenizing real-world assets into NFTs is a trending
                        project. With the perks of transparency, fractional
                        ownership, and multiple revenue streaming options, there
                        is high traction in the market among investors and owners.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  data-src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  data-src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/real-world.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/real-world.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/real-world.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Real world"
                        title="Real world" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Layer One X</h3>
                      <p class="pb-lg-3 pb-0">
                        A community for developers to interact with fellow
                        developers, project holders, and investors. The platform's
                        ability to actively connect and interact drives the
                        community with consistent engagement.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  data-src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  data-src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/l1x.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/l1x.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/l1x.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Layer One X"
                        title="Layer One X" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Elfin Games</h3>
                      <p class="pb-lg-3 pb-0">
                        A hub of web3 games for users to explore and expand the
                        Play to Earn economy.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  data-src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  data-src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/elfin.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/elfin.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/elfin.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Elfin Games"
                        title="Elfin Games" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">BOBNet</h3>
                      <p class="pb-lg-3 pb-0">
                        Meme coin market intelligence helps users identify trading
                        trends and maximize their profits eventually.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/bobnet.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/bobnet.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/bobnet.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="BOBNet"
                        title="BOBNet" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Big Time Coin</h3>
                      <p class="pb-lg-3 pb-0">
                        The real estate tokenization platform benefits investors,
                        rentals, users, and the entire community with different
                        modules of operations.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/big-time.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/big-time.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/big-time.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Big Time Coin"
                        title="Big Time Coin" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Ekta</h3>
                      <p class="pb-lg-3 pb-0">
                        A decentralized ecosystem built with the ability to
                        perform everything and anything. Being a blockchain
                        itself, it renders and supports DAO and has its own
                        marketplace and token to interact with.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/ekta.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/ekta.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/ekta.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Ekta"
                        title="Ekta" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">StarCard Sports Games</h3>
                      <p class="pb-lg-3 pb-0">
                        A play-to-earn decentralized gaming arena was developed to
                        connect real-world players through digital gaming. This
                        game is based on football, along with metaverse abilities
                        that draw income.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/starcard.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/starcard.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/starcard.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="StarCard Sports Games"
                        title="StarCard Sports Games" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Bounty Temple</h3>
                      <p class="pb-lg-3 pb-0">
                        The play-to-earn gaming community for gaming enthusiasts
                        to explore the endless possibilities of the web3 arena,
                        providing opportunities to monetize and maximize benefits.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/bounty.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/bounty.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/bounty.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Bounty Temple"
                        title="Bounty Temple" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Zukiverse</h3>
                      <p class="pb-lg-3 pb-0">
                        Zuki is a P2E gaming platform that is a community-oriented
                        space that builds characters, virtual goods, puzzle games,
                        quizzes, and metaverse structures all in one.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/zuki.webp" />
  
                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/zuki.png" />
  
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/zuki.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Zukiverse"
                        title="Zukiverse" />
                    </picture>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5">
          <div class="c-animation">
            <div class="common-sub-heading sub-title">
              Principles <span class="dots">.</span>
            </div>
            <h3 class="main-sub-heading">
                How Do You Process With <span>Blockchain Development Service?</span>
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="green-vector"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Ideation and <span class="d-block"> Requirement Gathering</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                We start with getting your ideas and requirements in our blockchain development company in France while starting with our development process. It's important to clearly get to know your expectations. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Frameworks and <span class="d-block">Roadmaps</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                From the requirements gathered,  we build the frameworks and architecture representation through wireframes and roadmaps that effectively clearly point to your goals and achievements.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Blockchain <span class="d-block"> choices</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Based on your niche, purpose, functionality, and requirements, the choice of Blockchain that you make will have to match your expectations. We help you choose the right blockchain network for your platform.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Platform <span class="d-block">Development</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                With the basics gathered and knowing the expectations of the client and the choices made, we get into the platform development of your blockchain application with precision, ensuring seamless operation. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['dao-development-company']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Integrations <span class="d-block">phase</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                From the basics, let us now upgrade the efficiency of the platform with required integration, from smart contracts, AI integrations, KYC-related, Analytics, and much more.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Testing and <span class="d-block">Trail Launch</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Testing is a crucial phase in the blockchain development process, as that's the best way to ensure the platform can provide all the respected operations, effectively. Beta testing is a must.  
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Mainnet <span class="d-block">Launch </span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>After all the integration and testing phase is done, we get to sort the errors and once it's completely approved from the Beta test, the main launch on the server is done for public use. 
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div
              class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
              <h3>Updates and <span class="d-block">Maintenance</span></h3>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
              <p>
                Post the mainnet launch of your Blockchain application, as a fond blockchain development company in France, we also extend our support in terms of upgradation, and maintenance as per required.
              </p>
              <div class="content-link" id="service-link">
                <a [routerLink]="['/']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="Arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-service-alt">
    <div class="container">
      <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
        <div class="commontext_wrap">
          <h2 class="text-start">Connect with our Expert  - Yokesh Sankar COO</h2>
          <p>For direct consultation, and discussion with our Expert Blockchain specialist kindly book your Call for Blockchain consultation here!</p>
        </div>
  
        <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
          <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
            <div>
              <img loading="lazy"
                src="assets-new/images/blog/yokesh-shankar.svg"
                alt="blog author"
                width="150"
                height="150" class="img-fluid rounded-circle"/>
            </div>
            <div class="para-text">
              <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span>
              <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="py-2"
                    rel="noopener noreferrer">Co - Founder and COO</a></span>
             <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="pb-2"
                    rel="noopener noreferrer">Blockchain Consultant</a></span></p>
              <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">contact@blockchainx.tech</a>
            </div>
          </div>
          <div class="discuss-link pt-0">
            <a [routerLink]="['/contact']">
              <span>Connect with him Now!</span>
              <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
        <div>
          <h2 class="text-start">Looking for Blockchain Developers in France?</h2>
          <p>
            Professional Developers at our Blockchain development company in France, have hands-on experience and efficiency in building versatile decentralized solutions. And you can now hire our blockchain developers for your web3 projects.<a class="hire-link" href="https://www.blockchainx.tech/contact/" target="_blank">Connect Now, to Hire our Blockchain Geeks!</a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="section-service-main">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionEx">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    How Can Blockchain Benefit My Business?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        The qualities of blockchain such as immutability, transparency, automated executions through smart contracts, accuracy and its decentralized nature provide feasibility. At the same time, it is secured and seamless ensuring operations run smoothly and effectively. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How can I start blockchain development?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        To start with your blockchain development journey, connect with us, and that's the simplest step that you will need to take.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Can I create my own blockchain?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        Yes, you can do that, but getting a professional's guidance and help can make things more stable, sustainable, and efficient in terms of Blockchain development.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    What is the cost to build a blockchain? 
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        To understand the cost of developing a blockchain application or network is completely based on what you want to do, the operations, the proficiency of the functions, the choice of blockchain, and so on; which would vary based on your needs and niche.  
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive">
                    What is the Future of Blockchain technology? 
                  </button>
                </div>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        The future of Blockchain technology seems to be highly valuable and I am confident the adaptations and advanced collaborative integration have been revolutionizing, and of course, the future holds a lot to explore. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix">
                    What are the different Industries that benefited from Blockchain?
                  </button>
                </div>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        Industries including healthcare, finance, education, supply chain, manufacturers, agriculture, entertainment, gaming, government institutions, and almost every other sector benefit from Blockchain today. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>

<section class="section-service-alt">
    <app-blog></app-blog>
</section>

<section class="section-service-main">
    <app-get-in-touch></app-get-in-touch>
</section>


  
  
  





  